/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./efile-status-column.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "primeng/components/tooltip/tooltip";
import * as i5 from "./efile-status-column.component";
import * as i6 from "../../../../Shared/Services/http-charge-back.service";
import * as i7 from "../../../../Shared/Services/app.service";
import * as i8 from "primeng/components/common/messageservice";
import * as i9 from "../../../../chargeback/Services/cb.service";
import * as i10 from "../../../../Shared/Services/global-app.service";
import * as i11 from "../../../../Shared/Services/language-selection.service";
var styles_EfileStatusColumnComponent = [i0.styles];
var RenderType_EfileStatusColumnComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EfileStatusColumnComponent, data: {} });
export { RenderType_EfileStatusColumnComponent as RenderType_EfileStatusColumnComponent };
function View_EfileStatusColumnComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.statusValue)); _ck(_v, 1, 0, currVal_0); }); }
function View_EfileStatusColumnComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "i", [["class", "far fa-arrow-alt-circle-right"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "disableSubmit": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "far fa-arrow-alt-circle-right"; var currVal_1 = _ck(_v, 2, 0, (_co.isGlobalUser && ((_co.region == "EMEA") || (_co.region == "LAO")))); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_EfileStatusColumnComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "span", [["class", "statusReadyToSubmit"]], [[8, "innerHTML", 1]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submitEfile() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "statusGeneral": 0, "disableSubmit": 1 }), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EfileStatusColumnComponent_3)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "statusReadyToSubmit"; var currVal_2 = _ck(_v, 3, 0, (_co.isEfileSubmitted || _co.isEfileDeleted), (_co.isGlobalUser && ((_co.region == "EMEA") || (_co.region == "LAO")))); _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_3 = !_co.isFileSubmittedOrDeleted; _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 4).transform(_co.statusValue)); _ck(_v, 1, 0, currVal_0); }); }
function View_EfileStatusColumnComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fas fa-exclamation-circle"]], null, null, null, null, null))], null, null); }
function View_EfileStatusColumnComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "span", [["tooltipPosition", "top"]], null, null, null, null, null)), i1.ɵdid(1, 4341760, null, 0, i4.Tooltip, [i1.ElementRef, i1.NgZone], { tooltipPosition: [0, "tooltipPosition"], escape: [1, "escape"], text: [2, "text"] }, null), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = "top"; var currVal_1 = false; var currVal_2 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 1, 2, i1.ɵnov(_v, 2).transform("chargeback.eFile.errorsFoundTooltip")), ""); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_co.statusValue)); _ck(_v, 4, 0, currVal_3); }); }
function View_EfileStatusColumnComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "span", [["tooltipPosition", "top"]], null, null, null, null, null)), i1.ɵdid(1, 4341760, null, 0, i4.Tooltip, [i1.ElementRef, i1.NgZone], { tooltipPosition: [0, "tooltipPosition"], escape: [1, "escape"], text: [2, "text"] }, null), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = "top"; var currVal_1 = false; var currVal_2 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 1, 2, i1.ɵnov(_v, 2).transform("chargeback.eFile.extensiveErrorsFoundTooltip")), ""); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_co.statusValue)); _ck(_v, 4, 0, currVal_3); }); }
function View_EfileStatusColumnComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "span", [["class", "tooltip-hover tolltipHoverClass statusError ellipsis"]], [[8, "title", 0]], null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "statusGeneral": 0 }), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EfileStatusColumnComponent_6)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EfileStatusColumnComponent_7)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EfileStatusColumnComponent_8)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "tooltip-hover tolltipHoverClass statusError ellipsis"; var currVal_2 = _ck(_v, 2, 0, _co.isEfileDeleted); _ck(_v, 1, 0, currVal_1, currVal_2); var currVal_3 = !_co.isEfileDeleted; _ck(_v, 5, 0, currVal_3); var currVal_4 = _co.statusError; _ck(_v, 7, 0, currVal_4); var currVal_5 = _co.statusExtensiveError; _ck(_v, 9, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 3).transform(_co.statusValue)), ""); _ck(_v, 0, 0, currVal_0); }); }
function View_EfileStatusColumnComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EfileStatusColumnComponent_5)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.statusError || _co.statusExtensiveError); _ck(_v, 2, 0, currVal_0); }, null); }
function View_EfileStatusColumnComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fas fa-exclamation-circle"]], null, null, null, null, null))], null, null); }
function View_EfileStatusColumnComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "span", [["tooltipPosition", "top"]], null, null, null, null, null)), i1.ɵdid(1, 4341760, null, 0, i4.Tooltip, [i1.ElementRef, i1.NgZone], { tooltipPosition: [0, "tooltipPosition"], escape: [1, "escape"], text: [2, "text"] }, null), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = "top"; var currVal_1 = false; var currVal_2 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 1, 2, i1.ɵnov(_v, 2).transform("chargeback.eFile.errorsFoundTooltip")), ""); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_co.statusValue)); _ck(_v, 4, 0, currVal_3); }); }
function View_EfileStatusColumnComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "span", [["tooltipPosition", "top"]], null, null, null, null, null)), i1.ɵdid(1, 4341760, null, 0, i4.Tooltip, [i1.ElementRef, i1.NgZone], { tooltipPosition: [0, "tooltipPosition"], escape: [1, "escape"], text: [2, "text"] }, null), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = "top"; var currVal_1 = false; var currVal_2 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 1, 2, i1.ɵnov(_v, 2).transform("chargeback.eFile.extensiveErrorsFoundTooltip")), ""); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_co.statusValue)); _ck(_v, 4, 0, currVal_3); }); }
function View_EfileStatusColumnComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "span", [["class", "tooltip-hover tolltipHoverClass statusError ellipsis"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "statusGeneral": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EfileStatusColumnComponent_11)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EfileStatusColumnComponent_12)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EfileStatusColumnComponent_13)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "tooltip-hover tolltipHoverClass statusError ellipsis"; var currVal_1 = _ck(_v, 2, 0, _co.isEfileDeleted); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = !_co.isEfileDeleted; _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.statusError; _ck(_v, 6, 0, currVal_3); var currVal_4 = _co.statusExtensiveError; _ck(_v, 8, 0, currVal_4); }, null); }
function View_EfileStatusColumnComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EfileStatusColumnComponent_10)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.statusError || _co.statusExtensiveError); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_EfileStatusColumnComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_EfileStatusColumnComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EfileStatusColumnComponent_2)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EfileStatusColumnComponent_4)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EfileStatusColumnComponent_9)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.statusDefault; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.statusReadyToSubmit; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.showTitleMessage(); _ck(_v, 5, 0, currVal_2); var currVal_3 = !_co.showTitleMessage(); _ck(_v, 7, 0, currVal_3); }, null); }
export function View_EfileStatusColumnComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cb-status-column", [], null, null, null, View_EfileStatusColumnComponent_0, RenderType_EfileStatusColumnComponent)), i1.ɵdid(1, 180224, null, 0, i5.EfileStatusColumnComponent, [i6.HttpChargeBackService, i7.AppService, i8.MessageService, i9.CbService, i10.GlobalAppService, i2.TranslateService, i11.LanguageSelectionService], null, null)], null, null); }
var EfileStatusColumnComponentNgFactory = i1.ɵccf("app-cb-status-column", i5.EfileStatusColumnComponent, View_EfileStatusColumnComponent_Host_0, {}, {}, []);
export { EfileStatusColumnComponentNgFactory as EfileStatusColumnComponentNgFactory };
