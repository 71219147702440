import * as tslib_1 from "tslib";
import { OnDestroy } from '@angular/core';
import { LanguageSelectionService } from '@app/Modules/Shared/Services/language-selection.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import { currency } from '@app/conf/dataConfigurations';
import { TranslateService } from '@ngx-translate/core';
import { GlobalAppService } from '@app/Modules/Shared/Services/global-app.service';
import { CpAgGridService } from '@app/Modules/cp-grid/services/cp-ag-grid-service';
var PriceFormatterComponent = /** @class */ (function () {
    function PriceFormatterComponent(locale, translateServ, languageServ, globalAppServcie, gridService) {
        var _this = this;
        this.locale = locale;
        this.translateServ = translateServ;
        this.languageServ = languageServ;
        this.globalAppServcie = globalAppServcie;
        this.gridService = gridService;
        this.subscription = new Subscription();
        this.noShowCurrencySymbolCols = ["requested", "requestedAmount", "difference", "totalIssueAmount", "issued", "quantity", "invoicePrice", "pAPrice", "rebateAmount", "pATotalLine", "differences", "val"];
        this.translateServ.get(['LCIDString'])
            .subscribe(function (value) {
            if (value)
                _this.LCIDString = value.LCIDString;
        });
    }
    PriceFormatterComponent.prototype.agInit = function (params) {
        var _this = this;
        this.setLanguageListener();
        this.params = params;
        if (this.params && this.params.data) {
            if (this.params.data.currency) {
                var cur = currency.find(function (item) {
                    return item.label == _this.params.data.currency.trim();
                });
                this.currencyCode = cur && cur.value ? cur.value + " " : "";
            }
            // let str1 = parseFloat(this.params.value).toLocaleString("en-US", {
            //   minimumFractionDigits: 2
            // });
            var languageCode = this.globalAppServcie.getDefaultLanguageString();
            var str1 = this.globalAppServcie.getNumberCurrencyFormatted(languageCode, params.value, 'NUMBER', 2);
            if (this.globalAppServcie.getDefaultRegionString() === 'LAO') {
                // CR 1378 - Use . as thousand separator even for Peru and other sales org in LAO
                // let str2 = str1.replace(/\./g, '@');
                // let str3 = str2.replace(/,/g, '.');
                // let str4 = str3.replace(/@/g, ',');
                if (params.colDef.field == 'quantity' && params.value.indexOf(",") > 0) {
                    var paramsValue = params.value.replace(",", "");
                    str1 = this.globalAppServcie.getNumberCurrencyFormatted(languageCode, paramsValue, 'NUMBER', 2);
                }
                if (this.noShowCurrencySymbolCols.indexOf(this.params.colDef.field) > -1) {
                    this.numericValue = ' ' + str1;
                }
                else {
                    this.numericValue = this.currencyCode + ' ' + str1;
                }
                if (params.colDef.field == 'differences' && params.data.lineType == 'C')
                    this.numericValue = '';
            }
            else {
                if ((this.gridService.pageContext && (this.gridService.pageContext.pageName == 'searchResultsCB' ||
                    this.gridService.pageContext.pageName == 'recentActivity' ||
                    this.gridService.pageContext.pageName == 'issuedTileGrid' ||
                    this.gridService.pageContext.pageName == 'deniedTileGrid' ||
                    this.gridService.pageContext.pageName == 'pendingTileGrid' ||
                    this.gridService.pageContext.pageName == 'couponSummaryGrid' ||
                    this.gridService.pageContext.pageName == 'summaryResultsCB' ||
                    this.gridService.pageContext.pageName == 'endUserDetailsGridCB')) ||
                    params.colDef.field == 'invoicePrice' || params.colDef.field == 'pAPrice' ||
                    params.colDef.field == 'rebateAmount' || params.colDef.field == 'pATotalLine' ||
                    params.colDef.field == 'differences' || params.colDef.field == 'val' || params.colDef.field == 'quantity') {
                    if (params.colDef.field == 'differences' && params.data.lineType == 'C')
                        this.numericValue = '';
                    else if ((params.colDef.field == 'tax' || params.colDef.field == 'taxAmount' || params.colDef.field == 'termsRecaptured') && params.value == 0)
                        this.numericValue = '';
                    else if (str1.indexOf('-') > -1 && this.globalAppServcie.getDefaultRegionString() != 'EMEA' && this.globalAppServcie.getDefaultRegionString() != 'APAC')
                        this.numericValue = '(' + str1.substring(1) + ')';
                    else if ((params.colDef.field == 'quantity')) {
                        var fractionSeparator = str1[str1.length - 3];
                        if (str1.split(fractionSeparator)[1] == '00') {
                            this.numericValue = str1.split(fractionSeparator)[0];
                        }
                        else {
                            this.numericValue = str1;
                        }
                    }
                    else
                        this.numericValue = str1;
                }
                else {
                    this.numericValue = this.currencyCode + ' ' + str1;
                }
            }
            this.formattedValue = ((parseFloat(this.params.value) - parseFloat(this.params.value)) === 0 ? true : false);
        }
    };
    PriceFormatterComponent.prototype.refresh = function (params) {
        return false;
    };
    PriceFormatterComponent.prototype.setLanguageListener = function () {
        var _this = this;
        this.subscription.add(this.languageServ.getlanguage().subscribe(function (language) {
            _this.translateServ.get(['LCIDString'])
                .subscribe(function (value) {
                if (value) {
                    _this.LCIDString = value.LCIDString;
                    _this.formatNumber();
                }
            });
        }));
    };
    PriceFormatterComponent.prototype.formatNumber = function () {
        var _this = this;
        if (this.params && this.params.data) {
            var cur = currency.find(function (item) {
                return item.label == _this.params.data.currency.trim();
            });
            this.currencyCode = cur.value + " ";
            // let str1 = parseFloat(this.params.value).toLocaleString("en-US", {
            //   minimumFractionDigits: 2
            // });
            var languageCode = this.globalAppServcie.getDefaultLanguageString();
            var str1 = this.globalAppServcie.getNumberCurrencyFormatted(languageCode, this.params.value, 'NUMBER', 2);
            if (this.globalAppServcie.getDefaultRegionString() === 'LAO') {
                // CR 1378 - Use . as thousand separator even for Peru and other sales org in LAO
                // let str2 = str1.replace(/\./g, '@');
                // let str3 = str2.replace(/,/g, '.');
                // let str4 = str3.replace(/@/g, ',');
                if (this.noShowCurrencySymbolCols.indexOf(this.params.colDef.field) > -1) {
                    this.numericValue = ' ' + str1;
                }
                else {
                    this.numericValue = this.currencyCode + ' ' + str1;
                }
            }
            else {
                if ((this.gridService.pageContext && (this.gridService.pageContext.pageName == 'searchResultsCB' ||
                    this.gridService.pageContext.pageName == 'recentActivity' ||
                    this.gridService.pageContext.pageName == 'issuedTileGrid' ||
                    this.gridService.pageContext.pageName == 'deniedTileGrid' ||
                    this.gridService.pageContext.pageName == 'pendingTileGrid' ||
                    this.gridService.pageContext.pageName == 'couponSummaryGrid' ||
                    this.gridService.pageContext.pageName == 'summaryResultsCB' ||
                    this.gridService.pageContext.pageName == 'endUserDetailsGridCB')) ||
                    this.params.colDef.field == 'invoicePrice' || this.params.colDef.field == 'pAPrice' ||
                    this.params.colDef.field == 'rebateAmount' || this.params.colDef.field == 'pATotalLine' ||
                    this.params.colDef.field == 'differences' || this.params.colDef.field == 'val' || this.params.colDef.field == 'price' || this.params.colDef.field == 'quantity') {
                    if (this.params.colDef.field == 'differences' && this.params.data.lineType == 'KC')
                        this.numericValue = '';
                    else if ((this.params.colDef.field == 'tax' || this.params.colDef.field == 'taxAmount') && this.params.value == 0)
                        this.numericValue = '';
                    else if (str1.indexOf('-') > -1 && this.globalAppServcie.getDefaultRegionString() != 'EMEA' && this.globalAppServcie.getDefaultRegionString() != 'APAC')
                        this.numericValue = '(' + str1.substring(1) + ')';
                    else if ((this.params.colDef.field == 'quantity')) {
                        var fractionSeparator = str1[str1.length - 3];
                        if (str1.split(fractionSeparator)[1] == '00') {
                            this.numericValue = str1.split(fractionSeparator)[0];
                        }
                        else {
                            this.numericValue = str1;
                        }
                    }
                    else
                        this.numericValue = str1;
                }
                else {
                    this.numericValue = this.currencyCode + ' ' + str1;
                }
            }
        }
    };
    PriceFormatterComponent.prototype.ngOnDestroy = function () {
        // Required for unsubscribing and destroying the component  
    };
    PriceFormatterComponent = tslib_1.__decorate([
        AutoUnsubscribe(),
        tslib_1.__metadata("design:paramtypes", [String, TranslateService,
            LanguageSelectionService,
            GlobalAppService,
            CpAgGridService])
    ], PriceFormatterComponent);
    return PriceFormatterComponent;
}());
export { PriceFormatterComponent };
