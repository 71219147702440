export const currencyArray = [
  {
     "country name":"Australia",
     "code":"AU",
     "separator":",",
     "decimal":".",
     "symbol":"$"
  },
  {
     "country name":"Botswana",
     "code":"BW",
     "separator":",",
     "decimal":".",
     "symbol":"P"
  },
  {
     "country name":"Brunei",
     "code":"BN",
     "separator":",",
     "decimal":".",
     "symbol":"$"
  },
  {
     "country name":"Canada",
     "code":"CA-EN",
     "separator":",",
     "decimal":".",
     "symbol":"$"
  },
  {
     "country name":"Dominican Republic",
     "code":"DO",
     "separator":",",
     "decimal":".",
     "symbol":"$"
  },
  {
     "country name":"Guatemala",
     "code":"GT",
     "separator":",",
     "decimal":".",
     "symbol":"Q"
  },
  {
     "country name":"Hong Kong",
     "code":"HK",
     "separator":",",
     "decimal":".",
     "symbol":"$"
  },
  {
     "country name":"India",
     "code":"IN",
     "separator":",",
     "decimal":".",
     "symbol":"₹"
  },
  {
     "country name":"Ireland",
     "code":"IE",
     "separator":",",
     "decimal":".",
     "symbol":"€"
  },
  {
     "country name":"Israel",
     "code":"IL",
     "separator":",",
     "decimal":".",
     "symbol":"₪"
  },
  {
     "country name":"Japan",
     "code":"JP",
     "separator":",",
     "decimal":".",
     "symbol":"¥"
  },
  {
     "country name":"Kenya",
     "code":"KE",
     "separator":",",
     "decimal":".",
     "symbol":"Sh"
  },
  {
     "country name":"North Korea",
     "code":"PRK",
     "separator":",",
     "decimal":".",
     "symbol":"₩"
  },
  {
     "country name":"South Korea",
     "code":"KOR",
     "separator":",",
     "decimal":".",
     "symbol":"₩"
  },
  {
     "country name":"Lebanon",
     "code":"LB",
     "separator":",",
     "decimal":".",
     "symbol":"ل.ل"
  },
  {
     "country name":"Malaysia",
     "code":"MY",
     "separator":",",
     "decimal":".",
     "symbol":"RM"
  },
  {
     "country name":"Malta",
     "code":"MT",
     "separator":",",
     "decimal":".",
     "symbol":"€"
  },
  {
     "country name":"Mexico",
     "code":"MX",
     "separator":",",
     "decimal":".",
     "symbol":"$"
  },
  {
     "country name":"Nepal",
     "code":"NP",
     "separator":",",
     "decimal":".",
     "symbol":"₨"
  },
  {
     "country name":"New Zealand",
     "code":"NZ",
     "separator":",",
     "decimal":".",
     "symbol":"$"
  },
  {
     "country name":"Nicaragua",
     "code":"NI",
     "separator":",",
     "decimal":".",
     "symbol":"C$"
  },
  {
     "country name":"Nigeria",
     "code":"NG",
     "separator":",",
     "decimal":".",
     "symbol":"₦"
  },
  {
     "country name":"Pakistan",
     "code":"PK",
     "separator":",",
     "decimal":".",
     "symbol":"₨"
  },
  {
     "country name":"China",
     "code":"CN",
     "separator":",",
     "decimal":".",
     "symbol":"¥"
  },
  {
     "country name":"Philippines",
     "code":"PH",
     "separator":",",
     "decimal":".",
     "symbol":"₱"
  },
  {
     "country name":"Singapore",
     "code":"SG",
     "separator":",",
     "decimal":".",
     "symbol":"$"
  },
  {
     "country name":"Sri Lanka",
     "code":"LK",
     "separator":",",
     "decimal":".",
     "symbol":"Rs"
  },
  {
     "country name":"Switzerland (only when the amount is in Swiss francs)",
     "code":"CH",
     "separator":",",
     "decimal":".",
     "symbol":"Fr."
  },
  {
     "country name":"Taiwan",
     "code":"TW",
     "separator":",",
     "decimal":".",
     "symbol":"$"
  },
  {
     "country name":"Tanzania",
     "code":"TZ",
     "separator":",",
     "decimal":".",
     "symbol":"Sh"
  },
  {
     "country name":"Thailand",
     "code":"TH",
     "separator":",",
     "decimal":".",
     "symbol":"฿"
  },
  {
     "country name":"Uganda",
     "code":"UG",
     "separator":",",
     "decimal":".",
     "symbol":"Sh"
  },
  {
     "country name":"United Kingdom",
     "code":"GB",
     "separator":",",
     "decimal":".",
     "symbol":"£"
  },
  {
     "country name":"United States",
     "code":"US",
     "separator":",",
     "decimal":".",
     "symbol":"$"
  },
  {
     "country name":"Zimbabwe",
     "code":"ZW",
     "separator":",",
     "decimal":".",
     "symbol":"$"
  },
  {
    "country name":"Albania",
     "code":"AL",
     "separator":".",
     "decimal":",",
     "symbol":"L"
  },
  {
     "country name":"Andorra",
     "code":"AD",
     "separator":".",
     "decimal":",",
     "symbol":"€"
  },
  {
     "country name":"Argentina",
     "code":"AR",
     "separator":".",
     "decimal":",",
     "symbol":"$"
  },
  {
     "country name":"Armenia",
     "code":"AM",
     "separator":".",
     "decimal":",",
     "symbol":"Դ"
  },
  {
     "country name":"Austria",
     "code":"AT",
     "separator":".",
     "decimal":",",
     "symbol":"€"
  },
  {
     "country name":"Azerbaijan",
     "code":"AZ",
     "separator":".",
     "decimal":",",
     "symbol":"ман"
  },
  {
     "country name":"Belarus",
     "code":"BY",
     "separator":".",
     "decimal":",",
     "symbol":"Br"
  },
  {
     "country name":"Belgium",
     "code":"BE",
     "separator":".",
     "decimal":",",
     "symbol":"€"
  },
  {
     "country name":"Bolivia",
     "code":"BO",
     "separator":".",
     "decimal":",",
     "symbol":"Bs."
  },
  {
     "country name":"Bosnia and Herzegovina",
     "code":"BA",
     "separator":".",
     "decimal":",",
     "symbol":"КМ"
  },
  {
     "country name":"Brazil",
     "code":"BR",
     "separator":".",
     "decimal":",",
     "symbol":"R$"
  },
  {
     "country name":"Bulgaria",
     "code":"BG",
     "separator":".",
     "decimal":",",
     "symbol":"лв"
  },
  {
     "country name":"Cameroon",
     "code":"CM",
     "separator":".",
     "decimal":",",
     "symbol":"FCFA"
  },
  {
     "country name":"Canada",
     "code":"CA-FR",
     "separator":".",
     "decimal":",",
    "symbol":"$"
  },
  {
     "country name":"Chile",
     "code":"CL",
     "separator":".",
     "decimal":",",
     "symbol":"$"
  },
  {
     "country name":"Colombia",
     "code":"CO",
     "separator":".",
     "decimal":",",
     "symbol":"$"
  },
  {
     "country name":"Costa Rica",
     "code":"CR",
     "separator":".",
     "decimal":",",
     "symbol":"₡"
  },
  {
     "country name":"Croatia",
     "code":"HR",
     "separator":".",
     "decimal":",",
     "symbol":"Kn"
  },
  {
     "country name":"Cuba",
     "code":"CU",
     "separator":".",
     "decimal":",",
     "symbol":"$"
  },
  {
     "country name":"Cyprus",
     "code":"CY",
     "separator":".",
     "decimal":",",
     "symbol":"€"
  },
  {
     "country name":"Czech Republic",
     "code":"CZ",
     "separator":".",
     "decimal":",",
     "symbol":"Kč"
  },
  {
     "country name":"Denmark",
     "code":"DK",
     "separator":".",
     "decimal":",",
     "symbol":"kr"
  },
  {
     "country name":"Dominican Republic",
     "code":"DO",
     "separator":".",
     "decimal":",",
     "symbol":"$"
  },
  {
     "country name":"Estonia",
     "code":"EE",
     "separator":".",
     "decimal":",",
     "symbol":"€"
  },
  {
     "country name":"Faroes",
     "code":"FO",
     "separator":".",
     "decimal":",",
     "symbol":"Kr."
  },
  {
     "country name":"Finland",
     "code":"FI",
     "separator":".",
     "decimal":",",
     "symbol":"€"
  },
  {
     "country name":"France",
     "code":"FR",
     "separator":".",
     "decimal":",",
     "symbol":"€"
  },
  {
     "country name":"Germany",
     "code":"DE",
     "separator":".",
     "decimal":",",
     "symbol":"€"
  },
  {
     "country name":"Georgia",
     "code":"GE",
     "separator":".",
     "decimal":",",
     "symbol":"ლ"
  },
  {
     "country name":"Greece",
     "code":"GR",
     "separator":".",
     "decimal":",",
     "symbol":"€"
  },
  {
     "country name":"Greenland",
     "code":"GL",
     "separator":".",
     "decimal":",",
     "symbol":"Kr."
  },
  {
     "country name":"Honduras",
     "code":"HN",
     "separator":".",
     "decimal":",",
     "symbol":"L"
  },
  {
     "country name":"Hungary",
     "code":"HU",
     "separator":".",
     "decimal":",",
     "symbol":"Ft"
  },
  {
     "country name":"Iceland",
     "code":"IS",
     "separator":".",
     "decimal":",",
     "symbol":"Kr"
  },
  {
     "country name":"Indonesia",
     "code":"ID",
     "separator":".",
     "decimal":",",
     "symbol":"Rp"
  },
  {
     "country name":"Italy",
     "code":"IT",
     "separator":".",
     "decimal":",",
     "symbol":"€"
  },
  {
     "country name":"Kazakhstan",
     "code":"KZ",
     "separator":".",
     "decimal":",",
     "symbol":"〒"
  },
  {
     "country name":"Kirgistan",
     "code":"KG",
     "separator":".",
     "decimal":",",
     "symbol":"Лв"
  },
  {
     "country name":"Latvia",
     "code":"LV",
     "separator":".",
     "decimal":",",
     "symbol":"€"
  },
  {
     "country name":"Lebanon",
     "code":"LB",
     "separator":".",
     "decimal":",",
     "symbol":"ل.ل"
  },
  {
     "country name":"Lithuania",
     "code":"LT",
     "separator":".",
     "decimal":",",
     "symbol":"€"
  },
  {
     "country name":"Luxembourg",
     "code":"LU",
     "separator":".",
     "decimal":",",
     "symbol":"€"
  },
  {
     "country name":"Macau (in Portuguese text)",
     "code":"MO",
     "separator":".",
     "decimal":",",
     "symbol":"MOP$"
  },
  {
     "country name":"Macedonia",
     "code":"MK",
     "separator":".",
     "decimal":",",
     "symbol":"ден"
  },
  {
     "country name":"Moldova",
     "code":"MD",
     "separator":".",
     "decimal":",",
     "symbol":"L"
  },
  {
     "country name":"Mongolia",
     "code":"MN",
     "separator":".",
     "decimal":",",
     "symbol":"₮"
  },
  {
     "country name":"Morocco",
     "code":"MA",
     "separator":".",
     "decimal":",",
     "symbol":"د.م."
  },
  {
     "country name":"Netherlands",
     "code":"NL",
     "separator":".",
     "decimal":",",
     "symbol":"€"
  },
  {
     "country name":"Norway",
     "code":"NO",
     "separator":".",
     "decimal":",",
     "symbol":"kr"
  },
  {
     "country name":"Panama",
     "code":"PA",
     "separator":".",
     "decimal":",",
     "symbol":"B/."
  },
  {
     "country name":"Paraguay",
     "code":"PY",
     "separator":".",
     "decimal":",",
     "symbol":"₲"
  },
  {
     "country name":"Peru",
     "code":"PE",
     "separator":".",
     "decimal":",",
     "symbol":"S/."
  },
  {
     "country name":"Poland",
     "code":"PL",
     "separator":".",
     "decimal":",",
     "symbol":"zł"
  },
  {
     "country name":"Portugal",
     "code":"PT",
     "separator":".",
     "decimal":",",
     "symbol":"€"
  },
  {
     "country name":"Romania",
     "code":"RO",
     "separator":".",
     "decimal":",",
     "symbol":"L"
  },
  {
     "country name":"Russia",
     "code":"RU",
     "separator":".",
     "decimal":",",
     "symbol":"р."
  },
  {
     "country name":"Serbia",
     "code":"RS",
     "separator":".",
     "decimal":",",
     "symbol":"din"
  },
  {
     "country name":"Slovakia",
     "code":"SK",
     "separator":".",
     "decimal":",",
     "symbol":"€"
  },
  {
     "country name":"Slovenia",
     "code":"SI",
     "separator":".",
     "decimal":",",
     "symbol":"€"
  },
  {
     "country name":"South Africa",
     "code":"ZA",
     "separator":".",
     "decimal":",",
     "symbol":"R"
  },
  {
     "country name":"Spain",
     "code":"ES",
     "separator":".",
     "decimal":",",
     "symbol":"€"
  },
  {
     "country name":"Sweden",
     "code":"SE",
     "separator":".",
     "decimal":",",
     "symbol":"kr"
  },
  {
     "country name":"Tunisia",
     "code":"TN",
     "separator":".",
     "decimal":",",
     "symbol":"د.ت"
  },
  {
     "country name":"Turkey",
     "code":"TR",
     "separator":".",
     "decimal":",",
     "symbol":"₤"
  },
  {
     "country name":"Ukraine",
     "code":"UA",
     "separator":".",
     "decimal":",",
     "symbol":"₴"
  },
  {
     "country name":"Uruguay",
     "code":"UY",
     "separator":".",
     "decimal":",",
     "symbol":"$"
  },
  {
     "country name":"Uzbekistan",
     "code":"UZ",
     "separator":".",
     "decimal":",",
     "symbol":"so\\'m"
  },
  {
     "country name":"Venezuela",
     "code":"VE",
     "separator":".",
     "decimal":",",
     "symbol":"Bs."
  },
  {
     "country name":"Vietnam",
     "code":"VN",
     "separator":".",
     "decimal":",",
     "symbol":"₫"
  },
  {
     "country name":"Canary Islands",
     "code":"IC",
     "separator":".",
     "decimal":",",
     "symbol":"€"
  },
  {
     "country name":"Monaco",
     "code":"MC",
     "separator":".",
     "decimal":",",
     "symbol":"€"
  },
  {
     "country name":"San Marino",
     "code":"SM",
     "separator":".",
     "decimal":",",
     "symbol":"€"
  }
]




