import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AppService } from '../../Services/app.service';

@Component({
  selector: 'app-download-link-popup',
  templateUrl: './download-link-popup.component.html',
  styleUrls: ['./download-link-popup.component.scss']
})
export class DownloadLinkPopupComponent implements OnInit {

  @Input()url: string;
  @Input()title: string;

  constructor(public activeModal: NgbActiveModal,
    public modalService: NgbModal,
    private appServ: AppService) { }

  ngOnInit() {
    let opener = window.open(this.url, '_blank');
    this.appServ.setLoadingStatus(false);
  }

  close(){
    this.activeModal.close();
  }

}
