import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { footerLinks } from "@app/conf/appLink";
import { LanguageSelectionService } from '@app/Modules/Shared/Services/language-selection.service';
import { HttpService } from '@app/Modules/Shared/Services/http.service';
import { KCPContent } from '@app/Modules/Shared/DataModels/SharedDataModel';
import { GlobalAppService } from '@app/Modules/Shared/Services/global-app.service';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { AppService } from '../../Services/app.service';
import { TranslateService } from '@ngx-translate/core';
var FooterComponent = /** @class */ (function () {
    function FooterComponent(languageServ, httpServ, translateService, _globalAppService, appService) {
        this.languageServ = languageServ;
        this.httpServ = httpServ;
        this.translateService = translateService;
        this._globalAppService = _globalAppService;
        this.appService = appService;
        this.imgPath = "/assets/images/international-flags/";
        this.defaultLangIcon = "";
        this.width = '90';
        this.countryLangSelctionHidden = false;
        this.subscription = new Subscription();
    }
    FooterComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.getInternalUserFlag();
        this.applink = footerLinks;
        this.httpServ.getUserDetails().subscribe(function (userDetails) {
            _this._globalAppService.setUserDetails(userDetails.result);
            var userDetailsResult = userDetails.result;
            _this.preferredCountry = userDetailsResult.preferredCountry;
        });
        this.languageServ.getlanguage().subscribe(function (data) {
            var selectedCountryLang = _this._globalAppService.getSelectedCountryLang();
            _this.country = selectedCountryLang.country;
            _this.language = selectedCountryLang.language;
            _this.salesOrg = selectedCountryLang.salesOrg;
            _this.createFooterLinks(_this.region);
        });
        this._globalAppService.getDefaultRegion().subscribe(function (region) {
            if (region) {
                _this.region = region;
                _this._globalAppService.getDefaultCountry().subscribe(function (country) {
                    if (country) {
                        _this.country = country;
                        _this._globalAppService.getDefaultLanguage().subscribe(function (lang) {
                            if (lang) {
                                var selectedCountryLang = _this._globalAppService.getSelectedCountryLang();
                                if (selectedCountryLang && selectedCountryLang.language) {
                                    _this.language = selectedCountryLang.language;
                                }
                                else {
                                    _this.language = lang;
                                }
                                /**Added for PADetails not showing the footer */
                                _this.translateService.setDefaultLang(_this.region + '/' + _this.languageServ.getLanguageCode(_this.region, _this.language).toLowerCase());
                                _this.createFooterLinks(_this.region);
                            }
                        });
                    }
                });
            }
        });
        this.subscription.add(this._globalAppService.getUserDetailsLoaded().subscribe(function (isUpdated) {
            if (isUpdated) {
                var selectedLocation = _this._globalAppService.getSelectedCountryLang();
                _this.language = selectedLocation && selectedLocation.language ? selectedLocation.language : _this._globalAppService.getDefaultLanguageString();
                _this.country = selectedLocation && selectedLocation.country ? selectedLocation.country : _this._globalAppService.getDefaultCountryString();
                // console.log("Footer 3", this.language);
                _this.createFooterLinks(_this.region);
            }
        }));
        this._globalAppService.getIsCountryLangSelctionHidden().subscribe(function (value) {
            _this.countryLangSelctionHidden = value;
        });
        /** When the Region or Country or Language gets change from Login/Registration page change the footer also*/
        this.subscription.add(this._globalAppService.getLocationLanguage().subscribe(function (selectedRegionCountrLang) {
            if (selectedRegionCountrLang) {
                _this.country = selectedRegionCountrLang.country;
                _this.language = selectedRegionCountrLang.language;
                _this.region = selectedRegionCountrLang.region;
                _this.createFooterLinks(selectedRegionCountrLang.region);
            }
        }));
    };
    FooterComponent.prototype.getInternalUserFlag = function () {
        var _this = this;
        if (this.appService.AvailableOKtaProfile)
            this.isInternalUser = this.appService.isInternalUser;
        else
            this.appService.getOktaProfile().subscribe(function (data) {
                if (data) {
                    _this.isInternalUser = _this.appService.isInternalUser;
                }
            });
    };
    FooterComponent.prototype.createFooterRequest = function (region) {
        this.footerRequest = new KCPContent();
        this.footerRequest.regionId = region;
        this.footerRequest.countryId = this.country;
        this.footerRequest.languageCode = this.language;
        // this.footerRequest.salesOrgId = +this._globalAppService.getSalesOrg();
        // this.footerRequest.salesOrgId = this.salesOrg;
        this.footerRequest.contentTypes = this.contentTypeRequest();
        return this.footerRequest;
    };
    FooterComponent.prototype.createFooterLinks = function (region) {
        var _this = this;
        var footerRequest = this.createFooterRequest(region);
        this.httpServ.getKCPContent(footerRequest).subscribe(function (data) {
            if (data && data.result && data.result.length > 0) {
                var dataResult = JSON.parse(data.result[0].data);
                _this.privacyPolicy = String(dataResult.privacypolicy);
                _this.legalStatement = String(dataResult.legalstatement);
                _this.legalDisclaimers = String(dataResult.legalDisclaimers);
            }
        });
    };
    FooterComponent.prototype.contentTypeRequest = function () {
        var contentTypeArr = [];
        var myObj = Object.assign({});
        myObj.contentType = 'GlobalFooter';
        contentTypeArr.push(myObj);
        return contentTypeArr;
    };
    FooterComponent.prototype.updateImgSRC = function (value) {
        this.imgSource = this.imgPath + value.imagename;
    };
    FooterComponent.prototype.onChargebackLinkClick = function (event) {
        this.appService.setAcceptancePolicyModalVisibility({ forceShowModal: true, action: "footerLinkClick" });
    };
    FooterComponent.prototype.ngOnDestroy = function () {
    };
    FooterComponent = tslib_1.__decorate([
        AutoUnsubscribe(),
        tslib_1.__metadata("design:paramtypes", [LanguageSelectionService,
            HttpService,
            TranslateService,
            GlobalAppService,
            AppService])
    ], FooterComponent);
    return FooterComponent;
}());
export { FooterComponent };
