import * as tslib_1 from "tslib";
import { OnDestroy } from '@angular/core';
import { HttpService } from '@app/Modules/Shared/Services/http.service';
import { PaService } from '@app/Modules/PriceAgreement/Services/pa.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { CpAgGridService } from '@app/Modules/cp-grid/services/cp-ag-grid-service';
import { DownloadCustomerRequest, DownloadCustomerFilter } from '@app/Modules/Shared/DataModels/SharedDataModel';
import { formatDate } from '@angular/common';
import { cbStatus, cbRvRb, cbClaimRequestType, cbClaimRequestTypeAPAC } from '@app/conf/dataConfigurations';
import { TranslateService } from '@ngx-translate/core';
import { GlobalAppService } from '@app/Modules/Shared/Services/global-app.service';
var TooltipAndFavoriteComponent = /** @class */ (function () {
    function TooltipAndFavoriteComponent(httpServ, _paServ, appServ, gridService, locale, translateServ, _globalAppService) {
        this.httpServ = httpServ;
        this._paServ = _paServ;
        this.appServ = appServ;
        this.gridService = gridService;
        this.locale = locale;
        this.translateServ = translateServ;
        this._globalAppService = _globalAppService;
        this.contractId = "";
        this.isCustIdFavorite = false;
        this.custIdfavoriteCSS = "";
        this.showTooltip = false;
        this.subscription = new Subscription();
        this.showModal = false;
        this.searchResultsCBToolTip = "";
        this.showTooltipForCB = false;
    }
    TooltipAndFavoriteComponent.prototype.agInit = function (params) {
        this.params = params;
        this.region = this._globalAppService.getDefaultRegionString();
        var custFavPAList = this.appServ.getFavoritePA();
        if (params) {
            this.showTooltipForCB = this.gridService.pageContext.pageName == 'recentActivity' ||
                this.gridService.pageContext.pageName == 'pendingTileGrid' ||
                this.gridService.pageContext.pageName == 'issuedTileGrid' ||
                this.gridService.pageContext.pageName == 'deniedTileGrid' ||
                this.gridService.pageContext.pageName == 'unclaimedTileGrid' ||
                this.gridService.pageContext.pageName == 'searchResultsCB' ? true : false;
            if (custFavPAList)
                this.custIdalreadyfavorite = custFavPAList.find(function (item) {
                    return item.customerId === params.value;
                });
            if (this.custIdalreadyfavorite) {
                this.custIdfavoriteCSS = "fa fa-heart favbtn";
                this.isCustIdFavorite = true;
            }
            else {
                this.custIdfavoriteCSS = "";
                this.isCustIdFavorite = false;
            }
            this.statusCBvalueToShow = null;
            switch (this.params.colDef.headerName) {
                case "agGrid.agGridColumns.statusCbLabel":
                    this.statusCBvalueToShow = cbStatus.find(function (el) {
                        return el.value == params.value;
                    });
                    break;
                case "agGrid.agGridColumns.rvRbLabel":
                    this.statusCBvalueToShow = cbRvRb.find(function (el) {
                        return el.value == params.value;
                    });
                    break;
                case "agGrid.agGridColumns.claimRequestTypeLabel":
                    this.statusCBvalueToShow = cbClaimRequestType.find(function (el) {
                        return el.value == params.value;
                    });
                    if (this.region == "APAC") {
                        this.statusCBvalueToShow = cbClaimRequestTypeAPAC.find(function (el) {
                            return el.value == params.value;
                        });
                    }
                    break;
            }
        }
        this.loadTranslatedContent();
    };
    TooltipAndFavoriteComponent.prototype.refresh = function (params) {
        return false;
    };
    TooltipAndFavoriteComponent.prototype.onCustomerFavClick = function (params) {
        this.isCustIdFavorite = !this.isCustIdFavorite;
        if (this.isCustIdFavorite) {
            // make service call to mark fav
            this.custIdMarkedToday = this.getTodaysDate();
            this.subscription.add(this.httpServ.markFavorite(this.contractId, params.value, this.custIdMarkedToday).subscribe(function (data) {
            }));
        }
        else {
            // make service call to mark unfav
            this.custIdMarkedToday = this.getTodaysDate();
            this.subscription.add(this.httpServ.markUnFavorite(this.contractId, params.value, this.custIdMarkedToday).subscribe(function (data) {
            }));
        }
    };
    TooltipAndFavoriteComponent.prototype.onCustomerLinkHover = function (event, overlaypanel) {
        var _this = this;
        if (this.showTooltipForCB) {
            switch (this.params.colDef.headerName) {
                case "agGrid.agGridColumns.customerNumberLabel":
                    var custNameAndAddress = this.params.data.customerName;
                    if (custNameAndAddress) {
                        if (this.params.data.address1) {
                            custNameAndAddress += "<br/>" + this.params.data.address1;
                        }
                        if (this.params.data.address2) {
                            custNameAndAddress += " <br/>" + this.params.data.address2;
                        }
                        if (this.params.data.city) {
                            custNameAndAddress += "<br/>" + this.params.data.city;
                        }
                        if (this.params.data.state) {
                            custNameAndAddress += ", " + this.params.data.state;
                        }
                        if (this.params.data.postalCode) {
                            custNameAndAddress += ", " + this.params.data.postalCode;
                        }
                    }
                    this.searchResultsCBToolTip = custNameAndAddress || this.params.data.customerNumber;
                    break;
                case "agGrid.agGridColumns.statusCbLabel":
                    // if ((this.params.value != "Pending" && this.params.value != "PD") || !this.params.data.receivedDate || !this.params.data.availableDate) return;
                    if (this.region == "EMEA" || this.region == "LAO" || this.region == 'APAC') {
                        //Fixed Defect 24298
                        return;
                    }
                    var receivedDate = void 0;
                    var availableDate = void 0;
                    if (this.params.data.receivedDate != null) {
                        receivedDate = this.region == 'EMEA' || this.region == 'APAC' ? formatDate(this.params.data.receivedDate, 'dd/MM/yyyy', this.locale) : formatDate(this.params.data.receivedDate, 'MM/dd/yyyy', this.locale);
                    }
                    else {
                        receivedDate = null;
                    }
                    if (this.params.data.availableDate != null) {
                        availableDate = this.region == 'EMEA' || this.region == 'APAC' ? formatDate(this.params.data.availableDate, 'dd/MM/yyyy', this.locale) : formatDate(this.params.data.availableDate, 'MM/dd/yyyy', this.locale);
                    }
                    else {
                        availableDate = null;
                    }
                    var availableSection = availableDate ? this.headerNames.availableDate + " - " + availableDate : "";
                    this.searchResultsCBToolTip = this.headerNames.receivedDate + " - " + receivedDate + "<br/>" + availableSection;
                    break;
                default:
                    return;
            }
            overlaypanel.hide();
            overlaypanel.show(event);
        }
        else {
            if (!this.isCustIdFavorite) {
                this.custIdfavoriteCSS = "far fa-heart favbtn";
            }
            this.customerNumber = this.params.data.customerNumber;
            this.overlaySubscription = this.httpServ.getCustomerInfo(this.customerNumber)
                .subscribe(function (data) {
                if (data['customerInformation'] && data['customerInformation'].length > 0 && data['customerInformation'][0]) {
                    var fullNameList = [];
                    fullNameList.push(data['customerInformation'][0]['customer_Name_1'] || '');
                    fullNameList.push(data['customerInformation'][0]['customer_Name_2'] || '');
                    fullNameList.push(data['customerInformation'][0]['customer_Name_3'] || '');
                    fullNameList.push(data['customerInformation'][0]['customer_Name_4'] || '');
                    _this.customerName = fullNameList.join(' ');
                    _this.street = data['customerInformation'][0]['street1'];
                    _this.city = data['customerInformation'][0]['city'];
                    _this.district = data['customerInformation'][0]['district_1'];
                    _this.country = data['customerInformation'][0]['country'];
                    _this.zipcode = data['customerInformation'][0]['zip_Code'];
                    overlaypanel.show(event);
                }
            }, function (err) {
                console.log("Error");
            });
            this.subscription.add(this.overlaySubscription);
        }
    };
    TooltipAndFavoriteComponent.prototype.onCustomerLinkMouseout = function (overlaypanel) {
        // if (this.gridService.pageContext.pageName !== 'recentDownload') {
        if (!this.isCustIdFavorite) {
            this.custIdfavoriteCSS = "";
        }
        else {
            this.custIdfavoriteCSS = "fa fa-heart favbtn";
        }
        overlaypanel.hide();
        if (this.overlaySubscription)
            this.overlaySubscription.unsubscribe();
    };
    // }
    TooltipAndFavoriteComponent.prototype.getTodaysDate = function () {
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1;
        var yyyy = today.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        today = dd + '/' + mm + '/' + yyyy;
        return (today);
    };
    TooltipAndFavoriteComponent.prototype.onLinkClick = function (event) {
        if (this.gridService.pageContext.pageName === 'recentDownload' && this.params.value === 'Multiple') {
            var request = new DownloadCustomerRequest();
            request.query = new Object();
            request.query.downloadRequestBatchId = this.params.data.downloadRequestBatchId;
            request.query.fileId = this.params.data.id;
            request.filter = new DownloadCustomerFilter();
            request.filter.customerNumber = "";
            request.filter.customerName = "";
            this.downloadCustomerListRequest = request;
            this.downloadType = this.params.data.downloadType;
            this.showModal = true;
        }
    };
    TooltipAndFavoriteComponent.prototype.closedCustModal = function (event) {
        this.showModal = (event.close === 'true');
    };
    TooltipAndFavoriteComponent.prototype.loadTranslatedContent = function () {
        var _this = this;
        this.translateServ.get(['agGrid']).subscribe(function (translations) {
            _this.headerNames = translations['agGrid']['agGridCellTooltip'];
        });
    };
    TooltipAndFavoriteComponent.prototype.ngOnDestroy = function () {
        // Required for unsubscribing and destroying the component  
    };
    TooltipAndFavoriteComponent = tslib_1.__decorate([
        AutoUnsubscribe(),
        tslib_1.__metadata("design:paramtypes", [HttpService,
            PaService,
            AppService,
            CpAgGridService, String, TranslateService, GlobalAppService])
    ], TooltipAndFavoriteComponent);
    return TooltipAndFavoriteComponent;
}());
export { TooltipAndFavoriteComponent };
