import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { of } from 'rxjs';
import { GlobalAppService } from './global-app.service';
import { timeout, catchError, map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./global-app.service";
var HttpChargeBackService = /** @class */ (function () {
    function HttpChargeBackService(http, _globalAppService) {
        this.http = http;
        this._globalAppService = _globalAppService;
    }
    //fetch region specific url from environment.ts
    HttpChargeBackService.prototype.getRegionSpecificURL = function (item) {
        var regionURL = "";
        var region = localStorage.getItem('default-region') || this._globalAppService.getDefaultRegionString() || this._globalAppService.selectedRegion;
        if (region) {
            regionURL = "url_" + region.toUpperCase();
        }
        else {
            regionURL = "url_NA";
        }
        return item[regionURL];
    };
    HttpChargeBackService.prototype.makeHTTPCall = function (method, endPoint, additionalHeaders, requestParams) {
        if (additionalHeaders === void 0) { additionalHeaders = null; }
        if (requestParams === void 0) { requestParams = null; }
        var requestBody = {};
        if (additionalHeaders) {
            requestBody = Object.assign({}, requestBody, { headers: additionalHeaders });
        }
        if (requestParams) {
            requestBody = Object.assign({}, requestBody, { body: requestParams });
        }
        return this.http.request(method, endPoint, requestBody);
    };
    HttpChargeBackService.prototype.getTilesCount = function () {
        return this.makeHTTPCall(environment.tilesCountCB.method, this.getRegionSpecificURL(environment.tilesCountCB), null, null);
    };
    HttpChargeBackService.prototype.getTilesPendingCount = function () {
        return this.makeHTTPCall(environment.tilesPendingCountCB.method, this.getRegionSpecificURL(environment.tilesPendingCountCB), null, null);
    };
    HttpChargeBackService.prototype.getTilesUnclaimedCount = function () {
        return this.makeHTTPCall(environment.tilesUnclaimedCountCB.method, this.getRegionSpecificURL(environment.tilesUnclaimedCountCB), null, null);
    };
    HttpChargeBackService.prototype.getTilesResult = function () {
        return this.makeHTTPCall(environment.tilesResultCB.method, this.getRegionSpecificURL(environment.tilesResultCB), null, null);
    };
    HttpChargeBackService.prototype.getTilesPendingResult = function () {
        return this.makeHTTPCall(environment.tilesPendingResultCB.method, this.getRegionSpecificURL(environment.tilesPendingResultCB), null, null);
    };
    HttpChargeBackService.prototype.exportTilesResult = function (searchRequestObj) {
        return this.makeHTTPCall(environment.exportTilesResultCB.method, this.getRegionSpecificURL(environment.exportTilesResultCB), null, searchRequestObj);
    };
    HttpChargeBackService.prototype.exportTilesPendingResult = function (searchRequestObj) {
        return this.makeHTTPCall(environment.exportTilesPendingResultCB.method, this.getRegionSpecificURL(environment.exportTilesPendingResultCB), null, searchRequestObj);
    };
    HttpChargeBackService.prototype.exportUnclaimedTilesResult = function (searchRequestObj) {
        return this.makeHTTPCall(environment.tilesUnclaimedResultExportCB.method, this.getRegionSpecificURL(environment.tilesUnclaimedResultExportCB), null, searchRequestObj);
    };
    HttpChargeBackService.prototype.getSavedFavouriteSearchTiles = function () {
        return this.makeHTTPCall(environment.getSavedFavoriteSearchCB.method, this.getRegionSpecificURL(environment.getSavedFavoriteSearchCB), null, null);
    };
    HttpChargeBackService.prototype.removeSavedFavouriteSearchTiles = function (reqParams) {
        return this.makeHTTPCall(environment.removeSavedFavoriteSearchCB.method, this.getRegionSpecificURL(environment.removeSavedFavoriteSearchCB), null, reqParams);
    };
    HttpChargeBackService.prototype.getRecentSearchTiles = function () {
        return this.makeHTTPCall(environment.getRecentSearchCB.method, this.getRegionSpecificURL(environment.getRecentSearchCB), null, null);
    };
    /**
     * Method to call Search API, based on provided search fields entries.
     * @param searchObj
     */
    HttpChargeBackService.prototype.CBSearch = function (searchObj) {
        return this.makeHTTPCall(environment.searchByFieldsCB.method, this.getRegionSpecificURL(environment.searchByFieldsCB), null, searchObj);
    };
    HttpChargeBackService.prototype.CBRecentSearchSave = function (searchObj) {
        return this.makeHTTPCall(environment.saveRecentSearchCB.method, this.getRegionSpecificURL(environment.saveRecentSearchCB), null, searchObj);
    };
    HttpChargeBackService.prototype.getLocation = function (userid) {
        return this.makeHTTPCall(environment.getLocationData.method, this.getRegionSpecificURL(environment.getLocationData) + "?userID=" + userid, null, null);
    };
    HttpChargeBackService.prototype.getStates = function (request) {
        return this.makeHTTPCall(environment.getStates.method, this.getRegionSpecificURL(environment.getStates), null, request);
    };
    HttpChargeBackService.prototype.getStateList = function (request) {
        return this.makeHTTPCall(environment.getStateList.method, this.getRegionSpecificURL(environment.getStateList), null, {
            "CP_Country_Code": (request ? request : "")
        });
    };
    HttpChargeBackService.prototype.exportChargeBackSearch = function (searchRequestObj) {
        return this.makeHTTPCall(environment.exportBySearchResultCB.method, this.getRegionSpecificURL(environment.exportBySearchResultCB), null, searchRequestObj);
    };
    HttpChargeBackService.prototype.markSearchFavrouite = function (searchId) {
        return this.makeHTTPCall(environment.markSearchFavrouiteCB.method, this.getRegionSpecificURL(environment.markSearchFavrouiteCB), null, {
            id: searchId
        });
    };
    HttpChargeBackService.prototype.removeProfileSearch = function (searchId) {
        return this.makeHTTPCall(environment.removeSearchCB.method, this.getRegionSpecificURL(environment.removeSearchCB), null, {
            id: searchId,
            name: "",
            moduleType: "chargeback"
        });
    };
    HttpChargeBackService.prototype.saveSearchName = function (searchId, name) {
        return this.makeHTTPCall(environment.saveSearchNameCB.method, this.getRegionSpecificURL(environment.saveSearchNameCB), null, {
            id: searchId,
            name: name,
            moduleType: "chargeback"
        });
    };
    HttpChargeBackService.prototype.getCouponSummaryDetails = function (request) {
        return this.makeHTTPCall(environment.summaryResultsCB.method, this.getRegionSpecificURL(environment.summaryResultsCB), null, request);
    };
    HttpChargeBackService.prototype.getCouponSummaryCB = function (summaryReqObj) {
        return this.makeHTTPCall(environment.getCouponSummaryCB.method, this.getRegionSpecificURL(environment.getCouponSummaryCB), null, summaryReqObj);
    };
    HttpChargeBackService.prototype.getCustomerEU = function (request) {
        return this.makeHTTPCall(environment.getCustomerEuCB.method, this.getRegionSpecificURL(environment.getCustomerEuCB), null, request);
    };
    HttpChargeBackService.prototype.getCustEUDetails = function (request) {
        return this.makeHTTPCall(environment.getEndUserDetailsCB.method, this.getRegionSpecificURL(environment.getEndUserDetailsCB), null, request);
    };
    HttpChargeBackService.prototype.exportCBSummaryResult = function (requestObj) {
        return this.makeHTTPCall(environment.exportCBSummaryResult.method, this.getRegionSpecificURL(environment.exportCBSummaryResult), null, requestObj);
    };
    HttpChargeBackService.prototype.getUploadedByEmailIds = function (requestObj) {
        return this.makeHTTPCall(environment.getUploadedByEmailIds.method, this.getRegionSpecificURL(environment.getUploadedByEmailIds), null, requestObj);
    };
    HttpChargeBackService.prototype.exportCBEfileResult = function (requestObj) {
        return this.makeHTTPCall(environment.exportCBEfileResult.method, this.getRegionSpecificURL(environment.exportCBEfileResult), null, requestObj);
    };
    HttpChargeBackService.prototype.searchCBEfileResult = function (requestObj) {
        return this.makeHTTPCall(environment.searchCBEfileResult.method, this.getRegionSpecificURL(environment.searchCBEfileResult), null, requestObj);
    };
    HttpChargeBackService.prototype.downloadDataCB = function (request, CBGridType) {
        // return this.http.post(environment.download.url, request).pipe(timeout(300000),
        var apiUrl;
        switch (CBGridType) {
            case 'CBsearchResult':
                apiUrl = environment.downloadCB;
                break;
            case 'CouponDetailsEU':
                apiUrl = environment.downloadEUDetails;
                break;
            case '':
                apiUrl = environment.downloadTileCB;
                break;
        }
        return this.makeHTTPCall(apiUrl.method, this.getRegionSpecificURL(apiUrl), null, request).pipe(timeout(300000), catchError(function (err) {
            return of(null);
        }), map(function (response) {
            if (response) {
                return response;
            }
        }));
    };
    // efileDownload(claimFileId, salesOrgId, fileversion) {
    HttpChargeBackService.prototype.efileDownload = function (request) {
        return this.makeHTTPCall(environment.efileDownload.method, this.getRegionSpecificURL(environment.efileDownload), null, request);
    };
    HttpChargeBackService.prototype.eFileLinesWithErrors = function (request) {
        return this.makeHTTPCall(environment.eFileLinesWithErrors.method, this.getRegionSpecificURL(environment.eFileLinesWithErrors), null, request);
    };
    HttpChargeBackService.prototype.updateCBEfileStatus = function (requestObj) {
        return this.makeHTTPCall(environment.updateCBEfileStatus.method, this.getRegionSpecificURL(environment.updateCBEfileStatus), null, requestObj);
    };
    HttpChargeBackService.prototype.cbGetDiscrepancies = function (requestObj) {
        return this.makeHTTPCall(environment.cbGetDiscrepancies.method, this.getRegionSpecificURL(environment.cbGetDiscrepancies), null, requestObj);
    };
    HttpChargeBackService.prototype.uploadEfile = function (requestObject) {
        return this.makeHTTPCall(environment.uploadEfile.method, this.getRegionSpecificURL(environment.uploadEfile), { 'Content-Type': 'multipart/form-data' }, requestObject);
    };
    HttpChargeBackService.prototype.downloadEFileTemplate = function (typeOfFile) {
        if (typeOfFile === void 0) { typeOfFile = 1; }
        return this.makeHTTPCall(environment.downloadEFileTemplate.method, this.getRegionSpecificURL(environment.downloadEFileTemplate) + "?fileType=" + typeOfFile, null, null);
    };
    HttpChargeBackService.prototype.downloadKCFileTemplate = function (request) {
        return this.makeHTTPCall(environment.KCFileTemplateDownload.method, this.getRegionSpecificURL(environment.KCFileTemplateDownload), null, request);
    };
    HttpChargeBackService.prototype.getEFileErrorSummary = function (requestObj) {
        return this.makeHTTPCall(environment.eFileErrorSummary.method, this.getRegionSpecificURL(environment.eFileErrorSummary), null, requestObj);
    };
    HttpChargeBackService.prototype.exportCBEFileErrorSummaryResult = function (searchRequestObj) {
        return this.makeHTTPCall(environment.exportCBEFileErrorSummaryResult.method, this.getRegionSpecificURL(environment.exportCBEFileErrorSummaryResult), null, searchRequestObj);
    };
    HttpChargeBackService.prototype.deleteEFileLineWithErrors = function (request) {
        return this.makeHTTPCall(environment.deleteEFileLineWithErrors.method, this.getRegionSpecificURL(environment.deleteEFileLineWithErrors), null, request);
    };
    HttpChargeBackService.prototype.efileHistoryLog = function (requestObj) {
        return this.makeHTTPCall(environment.efileHistoryLog.method, this.getRegionSpecificURL(environment.efileHistoryLog), null, requestObj);
    };
    HttpChargeBackService.prototype.exportEfileHistory = function (requestObj) {
        return this.makeHTTPCall(environment.exportEfileHistory.method, this.getRegionSpecificURL(environment.exportEfileHistory), null, requestObj);
    };
    HttpChargeBackService.prototype.getKCPContent = function (request, fromFileUpload) {
        if (fromFileUpload === void 0) { fromFileUpload = false; }
        if (fromFileUpload) {
            return this.makeHTTPCall(environment.EfileUploadKCPContent.method, this.getRegionSpecificURL(environment.EfileUploadKCPContent), null, request);
        }
        else {
            return this.makeHTTPCall(environment.KCPContent.method, this.getRegionSpecificURL(environment.KCPContent), null, request);
        }
    };
    HttpChargeBackService.prototype.getAccptancePolicyStatus = function (request, fromFileUpload) {
        if (fromFileUpload === void 0) { fromFileUpload = false; }
        if (fromFileUpload) {
            return this.makeHTTPCall(environment.EfileUploadgetAccptancePolicyStatus.method, this.getRegionSpecificURL(environment.EfileUploadgetAccptancePolicyStatus), null, request);
        }
        else {
            return this.makeHTTPCall(environment.getAccptancePolicyStatus.method, this.getRegionSpecificURL(environment.getAccptancePolicyStatus), null, request);
        }
    };
    HttpChargeBackService.prototype.setAccptancePolicyStatus = function (request, fromFileUpload) {
        if (fromFileUpload === void 0) { fromFileUpload = false; }
        if (fromFileUpload) {
            return this.makeHTTPCall(environment.EfileUploadsetAccptancePolicyStatus.method, this.getRegionSpecificURL(environment.EfileUploadsetAccptancePolicyStatus), null, request);
        }
        else {
            return this.makeHTTPCall(environment.setAccptancePolicyStatus.method, this.getRegionSpecificURL(environment.setAccptancePolicyStatus), null, request);
        }
    };
    HttpChargeBackService.prototype.cbGetDistinctFilterSearchGrid = function (requestObj, gridName) {
        switch (gridName) {
            case "searchResultsCB":
                return this.makeHTTPCall(environment.cbGetDistinctFilterSearchGrid.method, this.getRegionSpecificURL(environment.cbGetDistinctFilterSearchGrid), null, requestObj);
            case "recentActivity":
            case "issuedTileGrid":
            case "deniedTileGrid":
                return this.makeHTTPCall(environment.cbGetDistinctFilterTilesGrid.method, this.getRegionSpecificURL(environment.cbGetDistinctFilterTilesGrid), null, requestObj);
            case "pendingTileGrid":
                return this.makeHTTPCall(environment.cbGetDistinctFilterTilesPending.method, this.getRegionSpecificURL(environment.cbGetDistinctFilterTilesPending), null, requestObj);
            case "summaryResultsCB":
                return this.makeHTTPCall(environment.cbGetDistinctFilterSummaryGrid.method, this.getRegionSpecificURL(environment.cbGetDistinctFilterSummaryGrid), null, requestObj);
            case "efileSearchResult":
                return this.makeHTTPCall(environment.cbGetDistinctFilterEFileGrid.method, this.getRegionSpecificURL(environment.cbGetDistinctFilterEFileGrid), null, requestObj);
            case "eFileErrorSummary":
                return this.makeHTTPCall(environment.cbGetDistinctFilterEFileErrorSummaryGrid.method, this.getRegionSpecificURL(environment.cbGetDistinctFilterEFileErrorSummaryGrid), null, requestObj);
            case "unclaimedTileGrid":
                return this.makeHTTPCall(environment.tilesUnclaimedDistinctFilterCB.method, this.getRegionSpecificURL(environment.tilesUnclaimedDistinctFilterCB), null, requestObj);
            case "kcfileSearchResult":
                return this.makeHTTPCall(environment.cbGetDistinctFilterKcFileGrid.method, this.getRegionSpecificURL(environment.cbGetDistinctFilterKcFileGrid), null, requestObj);
            case "kcFileErrorSummary":
                return this.makeHTTPCall(environment.cbGetDistinctFilterKcFileErrorSummaryGrid.method, this.getRegionSpecificURL(environment.cbGetDistinctFilterKcFileErrorSummaryGrid), null, requestObj);
            default:
                return of(false);
        }
    };
    HttpChargeBackService.prototype.correctEfileErrors = function (requestObj) {
        return this.makeHTTPCall(environment.correctEfileErrors.method, this.getRegionSpecificURL(environment.correctEfileErrors), null, requestObj);
    };
    //----- Added For KC-File ---///
    HttpChargeBackService.prototype.searchCBKcfileResult = function (requestObj) {
        return this.makeHTTPCall(environment.searchCBKcfileResult.method, this.getRegionSpecificURL(environment.searchCBKcfileResult), null, requestObj);
    };
    HttpChargeBackService.prototype.exportCBKcfileResult = function (requestObj) {
        return this.makeHTTPCall(environment.exportCBKcfileResult.method, this.getRegionSpecificURL(environment.exportCBKcfileResult), null, requestObj);
    };
    HttpChargeBackService.prototype.downloadKcFileTemplate = function () {
        return this.makeHTTPCall(environment.downloadKcFileTemplate.method, this.getRegionSpecificURL(environment.downloadKcFileTemplate), null, null);
    };
    HttpChargeBackService.prototype.uploadKcfile = function (requestObject) {
        return this.makeHTTPCall(environment.uploadKcfile.method, this.getRegionSpecificURL(environment.uploadKcfile), { 'Content-Type': 'multipart/form-data' }, requestObject);
    };
    HttpChargeBackService.prototype.exportCBKcFileErrorSummaryResult = function (searchRequestObj) {
        return this.makeHTTPCall(environment.exportCBKcFileErrorSummaryResult.method, this.getRegionSpecificURL(environment.exportCBKcFileErrorSummaryResult), null, searchRequestObj);
    };
    HttpChargeBackService.prototype.kcfileHistoryLog = function (requestObj) {
        return this.makeHTTPCall(environment.kcfileHistoryLog.method, this.getRegionSpecificURL(environment.kcfileHistoryLog), null, requestObj);
    };
    HttpChargeBackService.prototype.exportKcfileHistory = function (requestObj) {
        return this.makeHTTPCall(environment.exportKcfileHistory.method, this.getRegionSpecificURL(environment.exportKcfileHistory), null, requestObj);
    };
    HttpChargeBackService.prototype.kcfileDownload = function (request) {
        return this.makeHTTPCall(environment.kcfileDownload.method, this.getRegionSpecificURL(environment.kcfileDownload), null, request);
    };
    HttpChargeBackService.prototype.updateCBKcfileStatus = function (requestObj) {
        return this.makeHTTPCall(environment.updateCBKcfileStatus.method, this.getRegionSpecificURL(environment.updateCBKcfileStatus), null, requestObj);
    };
    HttpChargeBackService.prototype.correctKcfileErrors = function (requestObj) {
        return this.makeHTTPCall(environment.correctKcfileErrors.method, this.getRegionSpecificURL(environment.correctKcfileErrors), null, requestObj);
    };
    HttpChargeBackService.prototype.deleteKcFileLineWithErrors = function (request) {
        return this.makeHTTPCall(environment.deleteKcFileLineWithErrors.method, this.getRegionSpecificURL(environment.deleteKcFileLineWithErrors), null, request);
    };
    HttpChargeBackService.prototype.KCFilePriceAgreementSearch = function (searchRequestObj) {
        return this.makeHTTPCall(environment.KCFilePriceAgreementSearch.method, this.getRegionSpecificURL(environment.KCFilePriceAgreementSearch), null, searchRequestObj);
    };
    HttpChargeBackService.prototype.eFileNotification = function () {
        return this.makeHTTPCall(environment.EFileNotification.method, this.getRegionSpecificURL(environment.EFileNotification), null, null);
    };
    HttpChargeBackService.prototype.unclaimedCBNotification = function () {
        return this.makeHTTPCall(environment.UnclaimedCBNotification.method, this.getRegionSpecificURL(environment.UnclaimedCBNotification), null, null);
    };
    HttpChargeBackService.prototype.KCFileNotification = function () {
        return this.makeHTTPCall(environment.KCFileNotification.method, this.getRegionSpecificURL(environment.KCFileNotification), null, null);
    };
    HttpChargeBackService.prototype.getEFileKCFileGroupValidationStatus = function () {
        return this.makeHTTPCall(environment.EFileKCFileGroupValidationStatus.method, this.getRegionSpecificURL(environment.EFileKCFileGroupValidationStatus), null, null);
    };
    HttpChargeBackService.ngInjectableDef = i0.defineInjectable({ factory: function HttpChargeBackService_Factory() { return new HttpChargeBackService(i0.inject(i1.HttpClient), i0.inject(i2.GlobalAppService)); }, token: HttpChargeBackService, providedIn: "root" });
    return HttpChargeBackService;
}());
export { HttpChargeBackService };
