import { HyperlinkAndFavoriteComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/hyperlink-and-favorite/hyperlink-and-favorite.component';
import { TooltipAndFavoriteComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/tooltip-and-favorite/tooltip-and-favorite.component';
import { TooltipComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/tooltip/tooltip.component';
import { ContextmenuComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/contextmenu/contextmenu.component';
import { HyperlinkComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/hyperlink/hyperlink.component';
import { CustomcheckboxComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/customcheckbox/customcheckbox.component';
import { ProductcodeHyperlinkComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/productcode-hyperlink/productcode-hyperlink.component';
import { PadetailsHyperLinkComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/padetails-hyper-link/padetails-hyper-link.component';
import { PriceFormatterComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/price-formatter/price-formatter.component';
import { RecentDownloadDocumentComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/recent-download-document/recent-download-document.component';
import { OverflowEllipsestextComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/overflow-ellipsestext/overflow-ellipsestext.component';
import { DigitFormatterComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/digit-formatter/digit-formatter.component';
import { CommentformatterComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/commentformatter/commentformatter.component';
import { PaIdHyperlinkOnlyComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/pa-id-hyperlink-only/pa-id-hyperlink-only.component';
import { HyperlinkAndLegendComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/hyperlink-and-legend/hyperlink-and-legend.component';
import { MyprofileAddtoPreferenceComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/myprofile-addto-preference/myprofile-addto-preference.component';
import { MyprofilePriceagreementComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/myprofile-priceagreement/myprofile-priceagreement.component';
import { MyprofileInternalPAComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/myprofile-Internal-pa/myprofile-Internal-pa.component';
import { NumberFormatterComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/number-formatter/number-formatter.component';
import { SfdcPaDetailsComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/sfdc-pa-details/sfdc-pa-details.component';
import { EndUserNumberComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/end-user-number/end-user-number.component';
import { StatusColumnComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/status-column/status-column.component';
import { ReportsDownloadComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/reports-download/reports-download.component';
import { EaDropdownComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/ea-dropdown/ea-dropdown.component';
import { DelegatedUserHyperlinkComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/delegated-user-hyperlink/delegated-user-hyperlink.component';

let CustomCheckbox = {
    headerName: '',
    field: '_cpCheckbox',
    cellRendererFramework: CustomcheckboxComponent,
    checkboxSelection: true,
    headerCheckboxSelection: '',
    suppressSizeToFit: true,
    width: 30,
    autoHeight: true,
    suppressMenu: true,
    cellClass: "",
    suppressMovable: true,
    colId: '_cpCheckbox'
}

let radioButton = {
    headerName: '',
    field: '',
    cellRendererFramework: '',
    checkboxSelection: true,
    headerCheckboxSelection: '',
    suppressSizeToFit: true,
    width: 60,
    minWidth: 50,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMenu: true,
    suppressMovable: true,
    cellClassRules:
    {
        "disableRadio": function (params) {
            if (params && params.data)
                return params.data.statusId === 2 ? true : false
            else
                return false;
        }
    }
    // cellClassRules: {
    //   "disableRadio": "x == 'Expired'"
    // },
}

let PA_ID = {
    headerName: 'agGrid.agGridColumns.contractIdLabel',
    field: 'contractId',
    filter: "freeTextFilter",
    cellRendererFramework: HyperlinkAndFavoriteComponent,
    // suppressSizeToFit: true,
    width: 100,
    minWidth: 50,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: 'contractId',
    suppressColumnVirtualisation: true
}

let PA_ID_CVT = {
    headerName: 'agGrid.agGridColumns.contractIdLabel',
    field: 'contractId',
    filter: "freeTextFilter",
    cellRendererFramework: PaIdHyperlinkOnlyComponent,
    suppressSizeToFit: true,
    width: 110,
    minWidth: 50,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true
}

let PAName = {
    headerName: 'agGrid.agGridColumns.contractNameLabel',
    field: 'contractName',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: '',
    width: 120,
    minWidth: 50,
    headerCheckboxSelection: '',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: 'contractName',
    suppressColumnVirtualisation: true

}
let EUPAName = {
    headerName: 'agGrid.agGridColumns.contractNameLabel',
    field: 'contractName',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: '',
    width: 115,
    minWidth: 50,
    suppressSizeToFit: true,
    headerCheckboxSelection: '',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: 'contractName',
}

let Customer = {
    headerName: 'agGrid.agGridColumns.customerIdLabel',
    field: 'customerNumber',
    filter: "freeTextFilter",
    cellRendererFramework: TooltipAndFavoriteComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 115,
    minWidth: 50,
    suppressSizeToFit: true,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true
}

let customerSoldToSfdc = {
    headerName: 'agGrid.agGridColumns.customerIdLabel',
    field: 'customerNumber',
    filter: "freeTextFilter",
    cellRendererFramework: SfdcPaDetailsComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    autoHeight: true,
    minWidth: 50,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true
}

let CustomerSoldToId = {
    headerName: 'agGrid.agGridColumns.customerSoldToId',
    field: 'customerNumber',
    filter: "freeTextFilter",
    cellRendererFramework: PadetailsHyperLinkComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 200,
    minWidth: 50,
    suppressSizeToFit: true,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true
}

let CustomerId = {
    headerName: 'agGrid.agGridColumns.customerId',
    field: 'customerNumber',
    filter: "freeTextFilter",
    cellRendererFramework: TooltipAndFavoriteComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 130,
    minWidth: 50,
    suppressSizeToFit: true,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: 'customerNumber',
}

let recentDldCustomerId = {
    headerName: 'agGrid.agGridColumns.customerId',
    field: 'customerNumber',
    filter: "freeTextFilter",
    cellRendererFramework: TooltipAndFavoriteComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 60,
    // suppressSizeToFit: true,
    autoHeight: true,
    cellClass: "cell-wrap-text",
    cellClassRules: {
        'cellLeftAlignment': params => {
            return params.value;
        },
    },
    menuTabs: ["filterMenuTab"],
    suppressMovable: true
}

let customerSoldToName = {
    headerName: 'agGrid.agGridColumns.customerSoldToName',
    field: 'customerName',
    filter: "freeTextFilter",
    cellRenderer: function (params) {
        let fullname = [];
        if (params.data && params.data.customerName) {
            fullname.push(params.data.customerName.name1 || '');
            // fullname.push(params.data.customerName.name2 || '');
            // fullname.push(params.data.customerName.name3 || '');
            // fullname.push(params.data.customerName.name4 || '');
        }
        // "cell-wrap-text ellipsis",
        if (fullname && fullname.length) {
            let joinText = fullname.join(' ');
            return "<span title='" + joinText + "'>" + joinText + "</span>"
        }
        else {
            return '';
        }
    },
    // suppressSizeToFit: true,
    autoHeight: true,
    minWidth: 50,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    // suppressMenu: true,
    suppressMovable: true,
    sort: "asc"
}

let customerSoldToAddress = {
    headerName: 'agGrid.agGridColumns.customerSoldToAddress',
    field: 'address',
    filter: "freeTextFilter",
    cellRenderer: function (params) {
        let addressList = [];
        if (params.data && params.data.address) {
            (params.data.address.address1 && params.data.address.address1.length > 0 ? addressList.push(params.data.address.address1 + ", ") : '');
            (params.data.address.address2 && params.data.address.address2.length > 0 ? addressList.push(params.data.address.address2 + ", ") : '');
            (params.data.address.address3 && params.data.address.address3.length > 0 ? addressList.push(params.data.address.address3 + ", ") : '');
            (params.data.address.address4 && params.data.address.address4.length > 0 ? addressList.push(params.data.address.address4 + ", ") : '');
            (params.data.address.address5 && params.data.address.address5.length > 0 ? addressList.push(params.data.address.address5 + ", ") : '');
            (params.data.address.city && params.data.address.city.length > 0 ? addressList.push(params.data.address.city + ", ") : '');
            (params.data.address.state && params.data.address.state.length > 0 ? addressList.push(params.data.address.state + ", ") : '');
            (params.data.address.postalCode && params.data.address.postalCode.length > 0 ? addressList.push(params.data.address.postalCode) : '');
        }
        if (addressList && addressList.length) {
            let joinText = addressList.join('');
            return "<span title='" + joinText + "'>" + joinText + "</span>"
        }
        else {
            return '';
        }
    },
    cellRendererFramework: '',
    menuTabs: ["filterMenuTab"], //Since we are seggregating data on UI - filter is not applied
    autoHeight: true,
    minWidth: 50,
    cellClass: "cell-wrap-text ellipsis",
    // suppressMenu: true,
    suppressMovable: true
}

let CustomerName = {
    headerName: 'agGrid.agGridColumns.customerNameLabel',
    field: 'customerName',
    filter: "freeTextFilter",
    cellRendererFramework: TooltipComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    autoHeight: true,
    cellClass: "",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    width: 160,
    minWidth: 50,
    colId: 'customerName',
    suppressColumnVirtualisation: true
}

let CustomerName1 = {
    headerName: 'agGrid.agGridColumns.customerNameLabel',
    field: 'name1',
    filter: "freeTextFilter",
    cellRendererFramework: '',
    checkboxSelection: '',
    headerCheckboxSelection: '',
    autoHeight: true,
    cellClass: "",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    sort: 'asc'
}

let customerName_Download = {
    headerName: 'agGrid.agGridColumns.customerNameLabel',
    field: 'customerName',
    filter: "freeTextFilter",
    cellRendererFramework: '',
    checkboxSelection: '',
    headerCheckboxSelection: '',
    autoHeight: true,
    cellClass: "",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    sort: 'asc'
}

let EuCustomerName = {
    headerName: 'agGrid.agGridColumns.eucustomerNameLabel',
    field: 'customerName',
    filter: "freeTextFilter",
    cellRendererFramework: TooltipComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    suppressSizeToFit: true,
    width: 100,
    minWidth: 50,
    colId: 'customerName',
    // suppressMenu: true
}

let EuEstimatedUnit = {
    headerName: 'agGrid.agGridColumns.euEstimatedUnitLabel',
    field: 'estimatedUnitCount',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    suppressSizeToFit: true,
    width: 100,
    minWidth: 50,
    colId: 'estimatedUnitCount',
    // suppressMenu: true
}

let EuDistributor = {
    headerName: 'agGrid.agGridColumns.euDistributorLabel',
    field: 'distEuNumber',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    suppressSizeToFit: true,
    width: 100,
    minWidth: 50,
    colId: 'distEuNumber',
    // suppressMenu: true
}

let RecentDldCustomerName = {
    headerName: 'agGrid.agGridColumns.customerNameLabel',
    field: 'customerName',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    width: 150,
    minWidth: 50
}

let TypeName = {
    headerName: 'agGrid.agGridColumns.typeLabel',
    field: 'typeName',
    filter: "multiSelectFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 100,
    minWidth: 50,
    // suppressSizeToFit: true,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: 'typeName',
    suppressColumnVirtualisation: true
}

let euType = {
    headerName: 'agGrid.agGridColumns.euTypeLabel',
    field: 'typeName',
    filter: "multiSelectFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 80,
    minWidth: 50,
    suppressSizeToFit: true,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: 'typeName',
}

let ValidFrom = {
    headerName: 'agGrid.agGridColumns.validFromLabel',
    field: 'startDate',
    colId: 'startDate',
    filter: "dateSelector",
    cellRendererFramework: '',
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 80,
    minWidth: 50,
    // suppressSizeToFit: true,
    autoHeight: true,
    menuTabs: ["filterMenuTab"],
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true
}

let PADetailsValidFrom = {
    headerName: 'agGrid.agGridColumns.validFromLabel',
    field: 'startDate',
    filter: "dateSelector",
    cellRendererFramework: '',
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 150,
    minWidth:50,
    // suppressSizeToFit: true,
    autoHeight: true,
    menuTabs: ["filterMenuTab"],
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true
}
let ValidSoldtoFrom = {
    headerName: 'agGrid.agGridColumns.validFromLabel',
    field: 'validFrom',
    filter: "dateSelector",
    cellRendererFramework: '',
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 110,
    minWidth: 50,
    // suppressSizeToFit: true,
    autoHeight: true,
    menuTabs: ["filterMenuTab"],
    cellClass: "cell-wrap-text ellipsis",
    // suppressMenu: true,
    suppressMovable: true
}

let ValidSoldtoToUFN = {
    headerName: 'agGrid.agGridColumns.validToLabel',
    field: 'validTo',
    filter: "dateSelectorUFN",
    cellRendererFramework: '',
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 100,
    minWidth: 50,
    // suppressSizeToFit: true,
    autoHeight: true,
    menuTabs: ["filterMenuTab"],
    cellClass: "cell-wrap-text ellipsis",
    // suppressMenu: true,
    suppressMovable: true
}

let ValidPAFrom = {
    headerName: 'agGrid.agGridColumns.validFromLabel',
    field: 'startDate',
    filter: "dateSelector",
    cellRendererFramework: '',
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 80,
    minWidth: 50,
    // suppressSizeToFit: true,
    autoHeight: true,
    menuTabs: ["filterMenuTab"],
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true,
    colId: 'startDate',
    suppressColumnVirtualisation: true
}

let ValidToUFN = {
    headerName: 'agGrid.agGridColumns.validToLabel',
    field: 'endDate',
    colId: 'endDate',
    filter: "dateSelectorUFN",
    cellRendererFramework: '',
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 80,
    minWidth: 50,
    // suppressSizeToFit: true,
    autoHeight: true,
    menuTabs: ["filterMenuTab"],
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true,
    suppressColumnVirtualisation: true
}

let PADetailsValidTo = {
    headerName: 'agGrid.agGridColumns.validToLabel',
    field: 'endDate',
    filter: "dateSelector",
    cellRendererFramework: '',
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 150,
    minWidth: 50,
    // suppressSizeToFit: true,
    autoHeight: true,
    menuTabs: ["filterMenuTab"],
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true
}
let ValidPATo = {
    headerName: 'agGrid.agGridColumns.validToLabel',
    field: 'endDate',
    filter: "dateSelector",
    cellRendererFramework: '',
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 80,
    minWidth: 50,
    // suppressSizeToFit: true,
    autoHeight: true,
    menuTabs: ["filterMenuTab"],
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true,
    colId: 'endDate',
    suppressColumnVirtualisation: true
}

let LastChanged = {
    headerName: 'agGrid.agGridColumns.lastChangedLabel',
    field: 'lastChanged',
    filter: "dateRangeSelector",
    cellRendererFramework: '',
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 100,
    // suppressSizeToFit: true,
    autoHeight: true,
    cellClass: "cell-wrap-text",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    sort: 'desc'
}

let PASearchLastChanged = {
    headerName: 'agGrid.agGridColumns.lastChangedLabel',
    field: 'lastChanged',
    filter: "dateRangeSelector",
    cellRendererFramework: '',
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 90,
    minWidth: 50,
    // suppressSizeToFit: true,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    sort: 'desc',
    colId: 'lastChanged',
    suppressColumnVirtualisation: true
}

let Status = {
    headerName: 'agGrid.agGridColumns.statusLabel',
    field: 'status',
    filter: "multiSelectFilter",
    cellRendererFramework: StatusColumnComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    // cellClassRules: {
    //   "statusExpired": "x == 'Expireds'" || "x == 'Denied'",
    //   "statusFuture": "x == 'Futuree'",
    //   "statusComplete": "x == 'Complete'",
    //   // "statusPending": "x == 'Pending'"
    // },
    width: 90,
    minWidth: 50,
    // suppressSizeToFit: true,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis statusColor",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: 'status',
    suppressColumnVirtualisation: true
}

let PADetailsStatus = {
    headerName: 'agGrid.agGridColumns.statusLabel',
    field: 'status',
    filter: "multiSelectFilter",
    cellRendererFramework: StatusColumnComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 100,
    autoHeight: true,
    cellClass: "cell-wrap-text statusColor",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true
}

let StatusText = {
    headerName: 'agGrid.agGridColumns.statusLabel',
    field: 'statusText',
    colId: 'statusText',
    filter: "multiSelectFilter",
    cellRendererFramework: StatusColumnComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    // cellClassRules: {
    //   "statusExpired": "x == 'Expireds'" || "x == 'Denied'",
    //   "statusFuture": "x == 'Futuree'",
    //   "statusComplete": "x == 'Complete'",
    //   // "statusPending": "x == 'Pending'"
    // },
    width: 100,
    minWidth: 50,
    // suppressSizeToFit: true,
    autoHeight: true,
    cellClass: "cell-wrap-text statusColor ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true
}

let StatusDesc = {
    headerName: 'agGrid.agGridColumns.statusLabel',
    // field: 'StatusDesc',
    field: 'status',
    filter: "multiSelectFilter",
    cellRendererFramework: StatusColumnComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 100,
    suppressSizeToFit: true,
    autoHeight: true,
    cellClass: "cell-wrap-text statusColor",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    // cellClassRules: {
    //   "statusComplete": "x == 'Completed'",
    //   // "statusPending": "x == 'Pending'"
    // },
}

let LastDownload = {
    headerName: 'agGrid.agGridColumns.lastDownloadLabel',
    field: 'lastDownload',
    filter: "dateSelector",
    cellRendererFramework: '',
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 150,
    minWidth: 50,
    // suppressSizeToFit: true,
    autoHeight: true,
    menuTabs: ["filterMenuTab"],
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true,
    colId: 'lastDownload'
}


let lastDownloadedDate = {
    headerName: 'agGrid.agGridColumns.lastDownloadLabel',
    field: 'lastDownloadedDate',
    filter: "dateSelector",
    cellRendererFramework: '',
    checkboxSelection: '',
    width: 150,
    suppressSizeToFit: true,
    autoHeight: true,
    cellClass: "cell-wrap-text",
    headerCheckboxSelection: '',
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    sort: 'desc'
}

let Change = {
    headerName: 'agGrid.agGridColumns.changeLabel',
    field: 'customerComments',
    filter: "multiSelectFilter",
    cellRendererFramework: '',
    checkboxSelection: '',
    width: 120,
    // suppressSizeToFit: true,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    headerCheckboxSelection: '',
    menuTabs: ["filterMenuTab"],
    suppressMovable: true
}

let LastUpdated = {
    headerName: 'agGrid.agGridColumns.lastUpdatedPAFavouriteLabel',
    // field: 'updatedDate',
    field: 'lastChanged',
    filter: "dateRangeSelector",
    cellRendererFramework: '',
    checkboxSelection: '',
    width: 130,
    suppressSizeToFit: true,
    autoHeight: true,
    headerCheckboxSelection: '',
    menuTabs: ["filterMenuTab"],
    cellClass: "cell-wrap-text",
    suppressMovable: true,
    sort: 'desc'
}

let PaDetailLastUpdated = {
    headerName: 'agGrid.agGridColumns.lastUpdatedLabel',
    field: 'lastChangedDate',
    filter: "dateRangeSelector",
    cellRendererFramework: '',
    checkboxSelection: '',
    width: 140,
    minWidth: 50,
    suppressSizeToFit: true,
    autoHeight: true,
    headerCheckboxSelection: '',
    menuTabs: ["filterMenuTab"],
    cellClass: "cell-wrap-text",
    suppressMovable: true,
    sort: 'desc'
}

let PaDetailsLastUpdated = {
    headerName: 'agGrid.agGridColumns.lastUpdatedLabel',
    field: 'lastChangedDate',
    filter: "dateRangeSelector",
    cellRendererFramework: '',
    checkboxSelection: '',
    width: 130,
    // suppressSizeToFit: true,
    autoHeight: true,
    headerCheckboxSelection: '',
    menuTabs: ["filterMenuTab"],
    cellClass: "cell-wrap-text",
    suppressMovable: true,
    sort: 'desc'
}

let updateDate = {
    headerName: 'agGrid.agGridColumns.lastUpdatedLabel',
    field: 'lastUpdatedDate',
    filter: "dateRangeSelector",
    cellRendererFramework: '',
    checkboxSelection: '',
    width: 100,
    minWidth: 50,
    // suppressSizeToFit: true,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    headerCheckboxSelection: '',
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    sort: 'desc'
}


let Territory = {
    headerName: 'agGrid.agGridColumns.territoryLabel',
    field: 'territoryNumber',
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: '',
    filter: "freeTextFilter",
    headerCheckboxSelection: '',
    autoHeight: true,
    menuTabs: ["filterMenuTab"],
    width: 120,
    minWidth: 50,
    cellClass: "cell-wrap-text ellipsis",
    suppressSizeToFit: true,
    suppressMovable: true,
    colId: 'territoryNumber',
}

let TerritoryName = {
    headerName: 'agGrid.agGridColumns.TerritoryNameLabel',
    field: 'territoryName',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: '',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    headerCheckboxSelection: '',
    menuTabs: ["filterMenuTab"],
    width: 180,
    minWidth: 50,
    // suppressSizeToFit: true,
    suppressMovable: true,
    colId: 'territoryName',
}

let ModifyPA = {
    headerName: 'agGrid.agGridColumns.modifyPALabel',
    field: "",
    cellRendererFramework: HyperlinkComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 120,
    minWidth: 50,
    suppressSizeToFit: true,
    suppressMenu: true,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true
}

let ContextMenu = {
    headerName: '',
    field: '',
    cellRendererFramework: ContextmenuComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    suppressSizeToFit: true,
    width: 80,
    minWidth: 50,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMenu: true,
    suppressMovable: true
}

let EUSearchContextMenu = {
    headerName: '',
    field: 'status',
    cellRendererFramework: ContextmenuComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    suppressSizeToFit: true,
    width: 50,
    minWidth: 50,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMenu: true,
    suppressMovable: true,
    cellClassRules: {
        "disableRadio": "x == 'Expired'"
    }
}

let tradeOrgNumber = {
    headerName: 'agGrid.agGridColumns.tradeOrgNumber',
    field: 'groupNumber',
    filter: "freeTextFilter",
    //suppressSizeToFit: true,
    width: 200,
    minWidth: 50,
    hide: true,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true
}

let tradeOrgName = {
    headerName: 'agGrid.agGridColumns.tradeOrgName',
    field: 'fullName',
    filter: "freeTextFilter",
    //suppressSizeToFit: true,
    width: 200,
    minWidth: 50,
    menuTabs: ["filterMenuTab"],
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true,
    cellRenderer: function (params) {
        let fullNameList = [];
        if (params.data && params.data.fullName) {
            fullNameList.push(params.data.fullName.name1 || '');
            // fullNameList.push(params.data.fullName.name2 || '');
            // fullNameList.push(params.data.fullName.name3 || '');
            // fullNameList.push(params.data.fullName.name4 || '');
        }
        // return fullNameList && fullNameList.length ? fullNameList.join(' ') : '';
        if (fullNameList && fullNameList.length) {
            let joinText = fullNameList.join(' ');
            return "<span title='" + joinText + "'>" + joinText + "</span>"
        }
        else {
            return '';
        }
    }
}

let tradeOrgAddress = {
    headerName: 'agGrid.agGridColumns.tradeOrgAddress',
    field: 'address',
    filter: "freeTextFilter",
    cellRenderer: function (params) {
        let addressList = [];
        if (params.data && params.data.address) {
            (params.data.address.address1 && params.data.address.address1.length > 0 ? addressList.push(params.data.address.address1 + ", ") : '');
            (params.data.address.address2 && params.data.address.address2.length > 0 ? addressList.push(params.data.address.address2 + ", ") : '');
            (params.data.address.address3 && params.data.address.address3.length > 0 ? addressList.push(params.data.address.address3 + ", ") : '');
            (params.data.address.address4 && params.data.address.address4.length > 0 ? addressList.push(params.data.address.address4 + ", ") : '');
            (params.data.address.address5 && params.data.address.address5.length > 0 ? addressList.push(params.data.address.address5 + ", ") : '');
            (params.data.address.city && params.data.address.city.length > 0 ? addressList.push(params.data.address.city + ", ") : '');
            (params.data.address.state && params.data.address.state.length > 0 ? addressList.push(params.data.address.state + ", ") : '');
            (params.data.address.zipCode && params.data.address.zipCode.length > 0 ? addressList.push(params.data.address.zipCode + ",") : '');
            (params.data.address.postalCode && params.data.address.postalCode.length > 0 ? addressList.push(params.data.address.postalCode) : '');
        }
        if (addressList && addressList.length) {
            let joinText = addressList.join('');
            return "<span title='" + joinText + "'>" + joinText + "</span>"
        }
        else {
            return '';
        }
    },
    //suppressSizeToFit: true,
    width: 350,
    minWidth: 50,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    // suppressMenu: true,
    suppressMovable: true,
    menuTabs: ["filterMenuTab"] // Since we are seggregating data on UI - filter is not applied
}

let EuRequestAddress = {
    headerName: 'agGrid.agGridColumns.addressLabel',
    field: 'addressLine1',
    filter: "freeTextFilter",
    cellRenderer: function (params) {
        let addressList = [];
        if (params.data) {
            (params.data.addressLine1 && params.data.addressLine1.length > 0 ? addressList.push(params.data.addressLine1 + ", ") : '');
            (params.data.addressLine2 && params.data.addressLine2.length > 0 ? addressList.push(params.data.addressLine2 + ", ") : '');
            (params.data.city && params.data.city.length > 0 ? addressList.push(params.data.city + ", ") : '');
            (params.data.state && params.data.state.length > 0 ? addressList.push(params.data.state + ", ") : '');
            (params.data.zipCode && params.data.zipCode.length > 0 ? addressList.push(params.data.zipCode) : '');
        }
        if (addressList && addressList.length) {
            let joinText = addressList.join('');
            //This has been assigned back to addressline1 because copy gives  param.data.addressLine1 value only not the joined Text
            params.data.addressLine1 = joinText;
            return "<span title='" + joinText + "'>" + joinText + "</span>"
        }
        else {
            return '';
        }
    },
    width: 180,
    minWidth: 50,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    // suppressMenu: true,
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    suppressSizeToFit: true,
    colId: 'addressLine1',
}

let PADetailProductCode = {
    headerName: 'agGrid.agGridColumns.productcode',
    field: 'shortProductCode',
    filter: "freeTextFilter",
    // cellRendererFramework: ProductcodeHyperlinkComponent,
    menuTabs: ["filterMenuTab"],
    width: 100,
    minWidth: 50,
    // suppressSizeToFit: true,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true
}

let PADetailsProductCode = {
    headerName: 'agGrid.agGridColumns.productcode',
    field: 'shortProductCode',
    filter: "freeTextFilter",
    cellRendererFramework: ProductcodeHyperlinkComponent,
    menuTabs: ["filterMenuTab"],
    width: 90,
    // suppressSizeToFit: true,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true
}

let PADetailProductCodeCVT = {
    headerName: 'agGrid.agGridColumns.productcode',
    field: 'shortProductCode',
    filter: "freeTextFilter",
    // cellRendererFramework: ProductcodeHyperlinkComponent,
    menuTabs: ["filterMenuTab"],
    width: 110,
    minWidth: 50,
    suppressSizeToFit: true,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true,
    sort: 'asc',
    colId: 'shortProductCode'
}

let description = {
    headerName: 'agGrid.agGridColumns.description',
    field: 'productDescription',
    colId: 'productDescription',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    menuTabs: ["filterMenuTab"],
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    width: 270,
    minWidth: 50,
    suppressMovable: true
}

let PADetailsdescription = {
    headerName: 'agGrid.agGridColumns.description',
    field: 'productDescription',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    menuTabs: ["filterMenuTab"],
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    width: 220,
    suppressMovable: true
}

let uom = {
    headerName: 'agGrid.agGridColumns.uom',
    field: 'uom',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    menuTabs: ["filterMenuTab"],
    width: 60,
    minWidth: 50,
    // suppressSizeToFit: true,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true
}

let PADetailsuom = {
    headerName: 'agGrid.agGridColumns.uom',
    field: 'uom',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    menuTabs: ["filterMenuTab"],
    width: 90,
    // suppressSizeToFit: true,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true
}

let itemsPerUom = {
    headerName: 'agGrid.agGridColumns.itemsperuom',
    field: 'itemsPerUOM',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    menuTabs: ["filterMenuTab"],
    width: 115,
    minWidth: 50,
    // suppressSizeToFit: true,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true
}

let PADetailsitemsPerUom = {
    headerName: 'agGrid.agGridColumns.itemsperuom',
    field: 'itemsPerUOM',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    menuTabs: ["filterMenuTab"],
    width: 120,
    // suppressSizeToFit: true,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true
}

let PADetailsprice = {
    headerName: 'agGrid.agGridColumns.price',
    field: 'price',
    filter: "freeTextFilter",
    cellRendererFramework: PriceFormatterComponent,
    menuTabs: ["filterMenuTab"],
    width: 110,
    // autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressSizeToFit: true,
    suppressMovable: true
}

let comments = {
    headerName: 'agGrid.agGridColumns.comments',
    field: 'comments',
    colId: 'comments',
    filter: "multiSelectFilter",
    cellRendererFramework: CommentformatterComponent,
    menuTabs: ["filterMenuTab"],
    width: 130,
    minWidth: 50,
    //suppressSizeToFit: true,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true
}

let PADetailscomments = {
    headerName: 'agGrid.agGridColumns.comments',
    field: 'comments',
    filter: "multiSelectFilter",
    cellRendererFramework: CommentformatterComponent,
    menuTabs: ["filterMenuTab"],
    width: 150,
    // suppressSizeToFit: true,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true
}
let paDetailEuComments = {
    headerName: 'agGrid.agGridColumns.paDetailEuComments',
    field: 'CommentsDescription',
    colId: 'CommentsDescription',
    filter: "multiSelectFilter",
    cellRendererFramework: CommentformatterComponent,
    menuTabs: ["filterMenuTab"],
    width: 180,
    minWidth: 50,
    // suppressSizeToFit: true,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true
}

let tradeOrgComment = {
    headerName: 'agGrid.agGridColumns.paDetailEuComments',
    field: 'CommentsDescription',
    // filter: "multiSelectFilter",
    filter: "freeTextFilter",
    cellRendererFramework: CommentformatterComponent,
    menuTabs: ["filterMenuTab"],
    width: 155,
    suppressSizeToFit: true,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true,
    // suppressMenu: true
}

let SoldToComment = {
    headerName: 'agGrid.agGridColumns.soldToComment',
    field: 'comment',
    filter: "multiSelectFilter",
    cellRendererFramework: CommentformatterComponent,
    menuTabs: ["filterMenuTab"],
    width: 180,
    minWidth:50,
    suppressSizeToFit: true,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true
}

let euComments = {
    headerName: 'agGrid.agGridColumns.euComments',
    field: 'comments',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    menuTabs: ["filterMenuTab"],
    width: 120,
    minWidth: 50,
    suppressSizeToFit: true,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true,
    colId: 'comments',
}
let caseBreakdownUOM = {
    headerName: 'agGrid.agGridColumns.caseBreakdownUOM',
    field: 'caseBreakDownUom',
    colId: 'caseBreakDownUom',
    filter: "freeTextFilter",
    cellRendererFramework: '',
    menuTabs: ["filterMenuTab"],
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true,
    // suppressMenu: true,
    width: 100,
    minWidth: 50,
    suppressSizeToFit: true
}
let caseBreakdown = {
    headerName: 'agGrid.agGridColumns.caseBreakdown',
    field: 'caseBreakDown',
    colId: 'caseBreakDown',
    filter: "freeTextFilter",
    cellRendererFramework: '',
    menuTabs: ["filterMenuTab"],
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true,
    // suppressMenu: true,
    suppressSizeToFit: true,
    minWidth: 50,
    width: 100
}
let rollingSalies = {
    headerName: 'agGrid.agGridColumns.rollingSalies',
    field: 'twelveMonthSales',
    colId: 'twelveMonthSales',
    filter: "freeTextFilter",
    cellRendererFramework: PriceFormatterComponent,
    menuTabs: ["filterMenuTab"],
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis textRight",
    width: 105,
    minWidth: 50,
    suppressSizeToFit: true,
    suppressMovable: true
}

let rollingVolume = {
    headerName: 'agGrid.agGridColumns.rollingVolume',
    field: 'twelveMonthVolume',
    colId: 'twelveMonthVolume',
    filter: "freeTextFilter",
    cellRendererFramework: DigitFormatterComponent,
    menuTabs: ["filterMenuTab"],
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis textRight",
    suppressMovable: true,
    suppressSizeToFit: true,
    minWidth: 50,
    width: 105
}
let pricePerCase = {
    headerName: 'agGrid.agGridColumns.pricePerCase',
    field: 'casePrice',
    filter: "freeTextFilter",
    cellRendererFramework: PriceFormatterComponent,
    menuTabs: ["filterMenuTab"],
    width: 80,
    suppressSizeToFit: true,
    autoHeight: true,
    cellClass: "cell-wrap-text textRight",
    suppressMovable: true
}
let pricePerCaseForREandFD = {
    headerName: 'agGrid.agGridColumns.pricePerCase',
    field: 'casePrice',//this is hardcoded
    colId: 'casePrice',//this is hardcoded
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    width: 70,
    minWidth: 50,
    suppressSizeToFit: true,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true,
    suppressMenu: true,
}
let priceChargeback = {
    headerName: 'agGrid.agGridColumns.priceChargeback',
    field: '',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    menuTabs: ["filterMenuTab"],
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true,
    width: 130,
    minWidth: 50,
    suppressSizeToFit: true
}
let FDRate = {
    headerName: 'agGrid.agGridColumns.FDRate',
    field: 'price',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    menuTabs: ["filterMenuTab"],
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true,
    width: 90,
    minWidth: 50
    // suppressSizeToFit: true
}
let FDstartDate = {
    headerName: 'agGrid.agGridColumns.FDstartDate',
    field: 'startDate',
    filter: "freeTextFilter",
    cellRendererFramework: '',
    menuTabs: ["filterMenuTab"],
    autoHeight: true,
    cellClass: "cell-wrap-text",
    suppressMovable: true,
    width: 120,
    minWidth: 50
    // suppressSizeToFit: true
}
let FDEndDate = {
    headerName: 'agGrid.agGridColumns.FDEndDate',
    field: 'endDate',
    filter: "freeTextFilter",
    cellRendererFramework: '',
    menuTabs: ["filterMenuTab"],
    autoHeight: true,
    cellClass: "cell-wrap-text",
    suppressMovable: true,
    width: 100,
    suppressSizeToFit: true
}
let EU_ID = {
    headerName: 'agGrid.agGridColumns.EU_ID',
    field: 'endUserNumber',
    filter: "freeTextFilter",
    cellRendererFramework: '',
    menuTabs: ["filterMenuTab"],
    autoHeight: true,
    width: 120,
    minWidth: 50,
    // suppressSizeToFit: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true
}


let EuRequestsNumber = {
    headerName: 'agGrid.agGridColumns.EuRequestsNumber',
    field: 'euNumber',
    filter: "freeTextFilter",
    cellRendererFramework: EndUserNumberComponent,
    menuTabs: ["filterMenuTab"],
    autoHeight: true,
    width: 120,
    minWidth: 50,
    suppressSizeToFit: true,
    cellClass: "cell-wrap-text",
    suppressMovable: true,
    colId: 'euNumber',
}


let EUName = {
    headerName: 'agGrid.agGridColumns.EUModalName',
    field: 'fullName',
    colId: 'fullName',
    filter: "freeTextFilter",
    cellRendererFramework: '',
    menuTabs: ["filterMenuTab"],
    width: 120,
    minWidth: 50,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true,
    cellRenderer: function (params) {
        let fullNameList = [];
        if (params.data && params.data.fullName) {
            fullNameList.push(params.data.fullName.name1 || '');
            // fullNameList.push(params.data.fullName.name2 || '');
            // fullNameList.push(params.data.fullName.name3 || '');
            // fullNameList.push(params.data.fullName.name4 || '');
        }
        // return fullNameList && fullNameList.length ? fullNameList.join(' ') : '';
        if (fullNameList && fullNameList.length) {
            let joinText = fullNameList.join(' ');
            return "<span title='" + joinText + "'>" + joinText + "</span>"
        }
        else {
            return '';
        }
    }
}




let EUAddress = {
    headerName: 'agGrid.agGridColumns.EUAddress',
    field: 'address',
    colId: 'address',
    width: 200,
    minWidth: 50,
    cellRenderer: function (params) {
        let addressList = [];
        if (params.data && params.data.address) {
            (params.data.address.address1 && params.data.address.address1.length > 0 ? addressList.push(params.data.address.address1 + ", ") : '');
            (params.data.address.address2 && params.data.address.address2.length > 0 ? addressList.push(params.data.address.address2 + ", ") : '');
            (params.data.address.address3 && params.data.address.address3.length > 0 ? addressList.push(params.data.address.address3 + ", ") : '');
            (params.data.address.address4 && params.data.address.address4.length > 0 ? addressList.push(params.data.address.address4 + ", ") : '');
            (params.data.address.address5 && params.data.address.address5.length > 0 ? addressList.push(params.data.address.address5 + ", ") : '');
            (params.data.address.city && params.data.address.city.length > 0 ? addressList.push(params.data.address.city + ", ") : '');
            (params.data.address.state && params.data.address.state.length > 0 ? addressList.push(params.data.address.state + ", ") : '');
            (params.data.address.zipCode && params.data.address.zipCode.length > 0 ? addressList.push(params.data.address.zipCode) : '');
            (params.data.address.postalCode && params.data.address.postalCode.length > 0 ? addressList.push(params.data.address.postalCode) : '');
        }
        if (addressList && addressList.length) {
            let joinText = addressList.join('');
            return "<span title='" + joinText + "'>" + joinText + "</span>"
        }
        else {
            return '';
        }
    },
    filter: "freeTextFilter",
    cellRendererFramework: '',
    menuTabs: ["filterMenuTab"], //Since we are seggregating data on UI - filter is not applied
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    // suppressMenu: true,
    suppressMovable: true
}

let modalProductCode = {
    headerName: 'agGrid.agGridColumns.ColProductCode',
    field: 'shortProductCode',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: true,
    headerCheckboxSelection: '',
    menuTabs: ["filterMenuTab"],
    autoHeight: true,
    width: 100,
    minWidth: 50,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true,
    sort: 'asc',
    region: ['NA', 'LAO'],
    colId: 'shortProductCode'
}

let ColAltProduct = {
    headerName: 'agGrid.agGridColumns.ColAltProduct',
    field: 'altProductCode',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    menuTabs: ["filterMenuTab"],
    autoHeight: true,
    width: 80,
    minWidth: 50,
    cellClass: "cell-wrap-text ellipsis",
    suppressSizeToFit: true,
    suppressMovable: true
}
let PADetailsColAltProduct = {
    headerName: 'agGrid.agGridColumns.ColAltProduct',
    field: 'altProductCode',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    menuTabs: ["filterMenuTab"],
    autoHeight: true,
    width: 80,
    cellClass: "cell-wrap-text ellipsis",
    // suppressSizeToFit: true,
    suppressMovable: true,
    colId: 'altProductCode'
}
let altProduct = {
    headerName: 'agGrid.agGridColumns.ColAltProduct',
    field: 'altProductCode',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    menuTabs: ["filterMenuTab"],
    autoHeight: true,
    width: 80,
    minWidth: 50,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true,
    region: ['NA'],
    colId: 'altProductCode'
}
let ColProductCat = {
    headerName: 'agGrid.agGridColumns.ColProductCat',
    field: 'categoryDescription',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    autoHeight: true,
    width: 150,
    minWidth: 50,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: 'categoryDescription',
    region: ['NA']
}

let ColProductDesc = {
    headerName: 'agGrid.agGridColumns.ColProductDesc',
    field: 'productDescription',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    region: ['NA', 'LAO'],
    colId: 'productDescription',
    minWidth: 50
}

let PANumber = {
    headerName: 'agGrid.agGridColumns.contractIdLabel',
    field: 'contractNumber',
    filter: "freeTextFilter",
    //cellRendererFramework: HyperlinkAndFavoriteComponent,
    suppressSizeToFit: true,
    width: 120,
    minWidth: 50,
    menuTabs: ["filterMenuTab"],
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true,
    colId: 'contractNumber',
}

let EURequestedDate = {
    headerName: 'agGrid.agGridColumns.requestedLabel',
    field: 'requestedDate',
    filter: "dateSelector",
    cellRendererFramework: '',
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 120,
    minWidth: 50,
    suppressSizeToFit: true,
    menuTabs: ["filterMenuTab"],
    sort: 'desc',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true,
    colId: 'requestedDate',
}

let companyName = {
    headerName: 'agGrid.agGridColumns.EUName',
    field: 'companyName',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    menuTabs: ["filterMenuTab"],
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true,
    width: 150,
    minWidth: 50,
    suppressSizeToFit: true,
    colId: 'companyName',
}

let EUcompanyName = {
    headerName: 'agGrid.agGridColumns.EUName',
    field: 'companyName',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    menuTabs: ["filterMenuTab"],
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true
}



let EUFullAddress = {
    headerName: 'agGrid.agGridColumns.euFullAddress',
    field: 'address',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    menuTabs: ["filterMenuTab"],
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMenu: true,
    suppressMovable: true
}

let EUCity = {
    headerName: 'agGrid.agGridColumns.cityLabel',
    field: 'city',
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMenu: true,
    suppressMovable: true
}

let EUState = {
    headerName: 'agGrid.agGridColumns.stateLabel',
    field: 'state',
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMenu: true,
    width: 100,
    suppressSizeToFit: true,
    suppressMovable: true
}

let EUZipCode = {
    headerName: 'agGrid.agGridColumns.zipCodeLabel',
    field: 'zipCode',
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMenu: true,
    width: 100,
    suppressSizeToFit: true,
    suppressMovable: true
}

let EUPhone = {
    headerName: 'agGrid.agGridColumns.businessPhoneLabel',
    field: 'businessPhone',
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMenu: true,
    suppressMovable: true
}

let EUStatus = {
    headerName: 'agGrid.agGridColumns.euStatusLabel',
    field: 'status',
    filter: "multiSelectFilter",
    // cellRendererFramework: '',
    cellRenderer: function (params) {
        if (params && params.data && params.data.status) {
            if (params.data.status === 'Completed' || params.data.status === 'Complete' || params.data.status === 'COM')
                return "<span class='statusComplete'>" + params.data.status + "</span>"
            // else if (params.data.status === 'Future' || params.data.status === 'FUT')
            //   return "<span class='statusFuture'>" + params.data.status + "</span>"
            else if (params.data.status === 'Denied' || params.data.status === 'Expired' || params.data.status === 'DEN')
                return "<span class='statusDeclined'>" + params.data.status + "</span>"
            else
                return params.data.status
        }
        else
            return '';
    },
    checkboxSelection: '',
    headerCheckboxSelection: '',
    cellClassRules: {
        "statusDeclined": "x == 'Declined'",
        "statusApproved": "x == 'Approved'",
        "statusComplete": "x == 'Complete'"
        // "statusPending": "x == 'Pending'"
    },
    width: 100,
    minWidth: 50,
    suppressSizeToFit: true,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: 'status',
}

let EUModalNum = {
    headerName: 'agGrid.agGridColumns.EUModalID',
    field: 'endUserNumber',
    // filter: 'sortonly',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: true,
    headerCheckboxSelection: '',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    sort: 'desc',
    colId: 'endUserNumber',
    minWidth: 50
}
let EUModalName = {
    headerName: 'agGrid.agGridColumns.EUModalName',
    field: 'endUserName',
    // filter: "sortonly",
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 200,
    minWidth: 50,
    // suppressSizeToFit: true,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: 'endUserName'
}

let EUModalAddress = {
    headerName: 'agGrid.agGridColumns.EUModalAddress',
    field: 'address',
    // filter: "sortonly",
    filter: "freeTextFilter",
    checkboxSelection: '',
    headerCheckboxSelection: '',
    cellRendererFramework: OverflowEllipsestextComponent,
    width: 250,
    minWidth: 50,
    suppressSizeToFit: true,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: 'address'
}
let EUModalCity = {
    headerName: 'agGrid.agGridColumns.cityLabel',
    field: 'city',
    // filter: "sortonly",
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    width: 130,
    minWidth: 50,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: 'city'
}
let EUModalState = {
    headerName: 'agGrid.agGridColumns.stateLabel',
    field: 'state',
    // filter: "sortonly",
    filter: "freeTextFilter",
    checkboxSelection: '',
    headerCheckboxSelection: '',
    cellRendererFramework: OverflowEllipsestextComponent,
    width: 120,
    minWidth: 50,
    // suppressSizeToFit: true,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: 'state'
}
let EUModalZipcode = {
    headerName: 'agGrid.agGridColumns.zipCodeLabel',
    field: 'postalCode',
    // filter: "sortonly",
    filter: "freeTextFilter",
    checkboxSelection: '',
    headerCheckboxSelection: '',
    cellRendererFramework: OverflowEllipsestextComponent,
    width: 150,
    minWidth: 50,
    // suppressSizeToFit: true,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: 'postalCode'
}
let EUModalPhone = {
    headerName: 'agGrid.agGridColumns.phoneLabel',
    field: 'phone',
    // filter: "sortonly",
    filter: "freeTextFilter",
    checkboxSelection: '',
    headerCheckboxSelection: '',
    cellRendererFramework: OverflowEllipsestextComponent,
    width: 150,
    minWidth: 50,
    // suppressSizeToFit: true,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: 'phone'
}

let FileName = {
    headerName: 'agGrid.agGridColumns.fileNameLabel',
    // field: 'fileName',
    width: 75,
    cellRendererFramework: RecentDownloadDocumentComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    suppressMenu: true
}
let FileSize = {
    headerName: 'agGrid.agGridColumns.fileSize',
    field: 'size',
    filter: "freeTextFilter",
    cellRendererFramework: NumberFormatterComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    suppressSizeToFit: true,
    autoHeight: true,
    cellClass: "cell-wrap-text",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    width: 120
}
let AvaliableUnit = {
    headerName: 'agGrid.agGridColumns.avaliableUnit',
    field: 'availableUntil',
    filter: "dateSelector",
    cellRendererFramework: '',
    checkboxSelection: '',
    headerCheckboxSelection: '',
    suppressSizeToFit: true,
    autoHeight: true,
    cellClass: "cell-wrap-text",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    width: 150
}

let HQModalHQID = {
    headerName: 'agGrid.agGridColumns.tradeOrgNumber',
    field: 'tradeOrgNumber',
    // filter: "sortonly",
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: true,
    headerCheckboxSelection: '',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    width: 120,
    minWidth: 50,
    sort: 'asc'
}

let HQModalHQName = {
    headerName: 'agGrid.agGridColumns.tradeOrgName',
    field: 'tradeOrgName',
    // filter: "sortonly",
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    width: 120,
    minWidth: 50,
    suppressMovable: true
}

let HQModalAddress = {
    headerName: 'agGrid.agGridColumns.addressLabel',
    field: 'address',
    // filter: "sortonly",
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    width: 120,
    minWidth: 50,
    suppressMovable: true
}

let HQModalCity = {
    headerName: 'agGrid.agGridColumns.cityLabel',
    field: 'city',
    // filter: "sortonly",
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    width: 120,
    minWidth: 50,
    suppressSizeToFit: true,
    suppressMovable: true
}
let HQModalState = {
    headerName: 'agGrid.agGridColumns.stateLabel',
    field: 'state',
    // filter: "sortonly",
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    width: 120,
    minWidth: 50,
    suppressSizeToFit: true,
    suppressMovable: true
}
let HQModalZip = {
    headerName: 'agGrid.agGridColumns.zipCodeLabel',
    field: 'postalCode',
    // filter: "sortonly",
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    width: 120,
    minWidth: 50,
    suppressSizeToFit: true,
    suppressMovable: true
}


let CP_Customer_Number = {
    headerName: 'agGrid.agGridColumns.customerIdLabel',
    field: 'customerNumber',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    width: 120,
    minWidth: 50,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: 'customerNumber'
    // suppressMenu: true //remove it later when azure done with their filter/sorting implementation
}
let CP_Customer_Name = {
    headerName: 'agGrid.agGridColumns.customerNameLabel',
    field: 'customerName',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    width: 250,
    minWidth: 50,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: 'customerName'
    // suppressMenu: true //remove it later when azure done with their filter/sorting implementation
}
let CP_Street = {
    headerName: 'agGrid.agGridColumns.addressLabel',
    field: 'address',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    width: 200,
    minWidth: 50,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: 'address'
    // suppressMenu: true //remove it later when azure done with their filter/sorting implementation
}
let CP_CP_City = {
    headerName: 'agGrid.agGridColumns.cityLabel',
    field: 'city',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    width: 130,
    minWidth: 50,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: 'city'
    // suppressMenu: true //remove it later when azure done with their filter/sorting implementation
}
let CP_State_Name = {
    headerName: 'myProfile.MyLocation.addLocationpopUp.stateLabel',
    field: 'state',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    width: 100,
    minWidth: 50,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: 'state'
    // suppressMenu: true //remove it later when azure done with their filter/sorting implementation
}
let PA_CP_State_Name = {
    headerName: 'agGrid.agGridColumns.stateLabel',
    field: 'state',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    width: 150,
    minWidth: 50,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: 'state'
    // suppressMenu: true //remove it later when azure done with their filter/sorting implementation
}
let CP_Zip_Code = {
    headerName: 'agGrid.agGridColumns.zipCodeLabel',
    field: 'postalCode',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    width: 120,
    minWidth: 50,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: 'postalCode'
    // suppressMenu: true //remove it later when azure done with their filter/sorting implementation
}
let CP_Customer_Type = {
    headerName: 'agGrid.agGridColumns.typeLabel',
    field: 'customerType',
    filter: "multiSelectFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    width: 120,
    minWidth: 50,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: 'customerType'
    // suppressMenu: true //remove it later when azure done with their filter/sorting implementation
}
let Notification = {
    headerName: 'agGrid.agGridColumns.Notification',
    field: 'changeReason',
    filter: 'freeTextFilter',
    cellRendererFramework: '',
    // suppressSizeToFit: true,
    cellClass: "cell-wrap-text",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true
}

let Sent = {
    headerName: 'agGrid.agGridColumns.Sent',
    field: "updatedDate",
    filter: 'dateSelector',
    cellRendererFramework: '',
    // suppressSizeToFit: true,
    cellClass: "cell-wrap-text",
    autoHeight: true,
    width: 70,
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    sort: 'desc'
}

let PA_Notification = {
    headerName: 'agGrid.agGridColumns.contractIdLabel',
    field: 'contractNumber',
    filter: "freeTextFilter",
    // cellRendererFramework: OverflowEllipsestextComponent,
    cellRendererFramework: HyperlinkAndFavoriteComponent,
    // suppressSizeToFit: true,
    cellClass: "cell-wrap-text ellipsis",
    autoHeight: true,
    width: 80,
    menuTabs: ["filterMenuTab"],
    suppressMovable: true
}


let PA_Name = {
    headerName: 'agGrid.agGridColumns.contractNameLabel',
    field: 'contractName',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    width: 120,
    // suppressSizeToFit: true,
    cellClass: "cell-wrap-text ellipsis",
    autoHeight: true,
    menuTabs: ["filterMenuTab"],
    suppressMovable: true
}

let Customer_Notification = {
    headerName: 'agGrid.agGridColumns.customerIdLabel',
    field: 'customerNumber',
    filter: "freeTextFilter",
    cellRendererFramework: TooltipAndFavoriteComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    cellClass: "cell-wrap-text ellipsis",
    // suppressSizeToFit: true,
    autoHeight: true,
    width: 100,
    menuTabs: ["filterMenuTab"],
    suppressMovable: true
}

let Customer_Name = {
    headerName: 'agGrid.agGridColumns.customerNameLabel',
    field: 'customerName',
    filter: "freeTextFilter",
    cellRendererFramework: TooltipAndFavoriteComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    cellClass: "cell-wrap-text ellipsis",
    width: 120,
    // suppressSizeToFit: true,
    autoHeight: true,
    menuTabs: ["filterMenuTab"],
    suppressMovable: true
}

let userId = {
    headerName: 'agGrid.agGridColumns.userIdLabel', //User ID
    field: 'userId',
    filter: "freeTextFilter",
    cellRendererFramework: HyperlinkAndLegendComponent,
    // suppressSizeToFit: true,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    width: 120,
    minWidth: 50
}

let pu_userId = {
    headerName: 'agGrid.agGridColumns.userIdLabel', //User ID
    field: 'userId',
    filter: "freeTextFilter",
    // suppressSizeToFit: true,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    minWidth: 50

}

let delegatedUserId = {
    headerName: 'agGrid.agGridColumns.userIdLabel',
    field: 'userId',
    filter: "freeTextFilter",
    cellRendererFramework: DelegatedUserHyperlinkComponent,
    // suppressSizeToFit: true,
    checkboxSelection: true,
    headerCheckboxSelection: '',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    width: 160,
    minWidth: 50,
    suppressSizeToFit: true
}

let lastLoginFrom = {
    headerName: 'agGrid.agGridColumns.loginFromLabel',
    field: 'lastLoginDate',
    // filter: "freeTextFilter",
    filter: 'dateSelector',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true
}

let passwordExpiration = {
    headerName: 'agGrid.agGridColumns.passwordExpirationLabel',
    field: 'passwordExpiryDate',
    filter: 'dateSelector',
    // filter: "freeTextFilter",
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true
}

let pu_passwordExpiration = {
    headerName: 'agGrid.agGridColumns.passwordExpirationLabel',
    field: 'passwordExpiryDate',
    filter: 'dateSelector',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    width: 160,
    suppressSizeToFit: true
}

let totalVisits = {
    headerName: 'agGrid.agGridColumns.totalVisitslabel',
    field: 'loginCount',
    filter: "freeTextFilter",
    cellRendererFramework: NumberFormatterComponent,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    type: 'numericColumn',
    width: 120,
    suppressSizeToFit: true
}

let lastLogin = {
    headerName: 'agGrid.agGridColumns.lastLoginLabel',
    field: 'lastLoginDate',
    filter: "dateSelector",
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    sort: 'desc'
}

let lastLoginTime = {
    headerName: 'agGrid.agGridColumns.lastLoginTimeLabel',
    field: 'lastLoginDate__1',
    filter: "freeTextFilter",
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    suppressMenu: true,
    width: 100,
    minWidth: 50
}

let firstName = {
    headerName: 'agGrid.agGridColumns.firstNameLabel', //First Name
    field: 'firstName',
    filter: "freeTextFilter",
    // suppressSizeToFit: true,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    minWidth: 50
}

let lastName = {
    headerName: 'agGrid.agGridColumns.lastNameLabel',//Last Name 
    field: 'lastName',
    filter: "freeTextFilter",
    // suppressSizeToFit: true,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    minWidth: 50
}

let email = {
    headerName: 'agGrid.agGridColumns.emailLabel', //Email
    field: 'email',
    filter: "freeTextFilter",
    // suppressSizeToFit: true,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    width: 180,
    minWidth: 50,
    colId: 'email',
    cellRendererFramework: OverflowEllipsestextComponent
}

let customerNumber = {
    headerName: 'agGrid.agGridColumns.customerNumber',
    field: 'customerNumber',
    filter: "freeTextFilter",
    suppressSizeToFit: true,
    cellRendererFramework: HyperlinkAndLegendComponent,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true
}

let customerName = {
    headerName: 'agGrid.agGridColumns.customerName',
    field: 'customerName',
    filter: "freeTextFilter",
    suppressSizeToFit: true,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true
}

let customerAddress = {
    headerName: 'agGrid.agGridColumns.customerAddress',
    field: 'address',
    filter: "freeTextFilter",
    suppressSizeToFit: true,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true
}

let city = {
    headerName: 'agGrid.agGridColumns.cityLabel',
    field: 'city',
    filter: "freeTextFilter",
    suppressSizeToFit: true,
    width: 80,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true
}

let state = {
    headerName: 'agGrid.agGridColumns.stateLabel',
    field: 'state',
    filter: "freeTextFilter",
    suppressSizeToFit: true,
    width: 100,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true
}

let zipCode = {
    headerName: 'agGrid.agGridColumns.zipCodeLabel',
    field: 'postalCode',
    filter: "freeTextFilter",
    suppressSizeToFit: true,
    width: 130,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true
}

let country = {
    headerName: 'agGrid.agGridColumns.countryLabel',
    field: 'country',
    filter: "multiSelectFilter",
    suppressSizeToFit: true,
    autoHeight: true,
    width: 100,
    minWidth: 50,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: 'country'
}


let customerType = {
    headerName: 'agGrid.agGridColumns.customerTypeLabel',
    field: 'customerType',
    width: 125,
    minWidth: 50,
    filter: "multiSelectFilter",
    suppressSizeToFit: true,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: 'customerType'
}

let customerLocation = {
    headerName: 'siteAdmin.agGridColumns.customerLocation',
    field: 'customerNumber',
    suppressMenu: true,
    suppressSizeToFit: true,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true,
    hide: true
}

let addToPreference = {
    pinned: 'left',
    headerName: "agGrid.agGridColumns.addToPreferenceLabel",
    field: '_addtopref',
    filter: "",
    cellRendererFramework: MyprofileAddtoPreferenceComponent,
    suppressSizeToFit: true,
    width: 210,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis mylocationexternal",
    menuTabs: [],
    suppressMenu: true,
    suppressMovable: true,
    colId: '_addtopref'
}


let priceAgreementExternalNA = {
    headerName: "agGrid.agGridColumns.priceAgreementLabel",
    field: '_myprofilePA',
    filter: "",
    cellRendererFramework: MyprofilePriceagreementComponent,
    suppressSizeToFit: true,
    width: 140,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis mylocationexternal",
    menuTabs: [],
    suppressMenu: true,
    suppressMovable: true,
    appFeatureId: 1,
    colId:'_myprofilePA'
}

let meetCompExternal = {
    headerName: "agGrid.agGridColumns.meetCompLabel",
    field: '_myprofileMC',
    filter: "",
    cellRendererFramework: MyprofilePriceagreementComponent,
    suppressSizeToFit: true,
    width: 120,
    pinned: 'right',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: [],
    suppressMenu: true,
    suppressMovable: true,
    appFeatureId: 2,
    colId: '_myprofileMC'
}

let chargeBackExternal = {
    headerName: "agGrid.agGridColumns.chargeBackLabel",
    field: '_myprofileCB',
    filter: "",
    cellRendererFramework: MyprofilePriceagreementComponent,
    suppressSizeToFit: true,
    width: 120,
    pinned: 'right',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: [],
    suppressMenu: true,
    suppressMovable: true,
    appFeatureId: 3,
    colId: '_myprofileCB',
    headerClass: 'wrapText'
}

let invoicesExternal = {
    headerName: "agGrid.agGridColumns.invoicesLabel",
    field: '_myprofileIN',
    filter: "",
    cellRendererFramework: MyprofilePriceagreementComponent,
    suppressSizeToFit: true,
    width: 120,
    pinned: 'right',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: [],
    suppressMenu: true,
    suppressMovable: true,
    appFeatureId: 4,
    colId: '_myprofileIN'
}

let ordersExternal = {
    headerName: "agGrid.agGridColumns.ordersLabel",
    field: '_myprofileOD',
    filter: "",
    cellRendererFramework: MyprofilePriceagreementComponent,
    suppressSizeToFit: true,
    width: 120,
    pinned: 'right',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: [],
    suppressMenu: true,
    suppressMovable: true,
    appFeatureId: 5,
    colID: '_myprofileOD'
}


let priceAgreementInternalNA = {
    headerName: "agGrid.agGridColumns.priceAgreementLabel",
    field: '_myprofilePA',
    filter: "",
    cellRendererFramework: MyprofileInternalPAComponent,
    suppressSizeToFit: true,
    width: 120,
    pinned: 'right',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: [],
    suppressMenu: true,
    suppressMovable: true,
    appFeatureId: 1,
    colId: '_myprofilePA'
}

let meetCompInternal = {
    headerName: "agGrid.agGridColumns.meetCompLabel",
    field: '_myprofileMC',
    filter: "",
    cellRendererFramework: MyprofileInternalPAComponent,
    suppressSizeToFit: true,
    width: 120,
    pinned: 'right',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: [],
    suppressMenu: true,
    suppressMovable: true,
    appFeatureId: 2,
    colId: '_myprofileMC',
}

let chargeBackInternal = {
    headerName: "agGrid.agGridColumns.chargeBackLabel",
    field: '_myprofileCB',
    filter: "",
    cellRendererFramework: MyprofileInternalPAComponent,
    suppressSizeToFit: true,
    width: 120,
    pinned: 'right',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: [],
    suppressMenu: true,
    suppressMovable: true,
    appFeatureId: 3,
    colId: '_myprofileCB',
}

let invoicesInternal = {
    headerName: "agGrid.agGridColumns.invoicesLabel",
    field: '_myprofileIN',
    filter: "",
    cellRendererFramework: MyprofileInternalPAComponent,
    suppressSizeToFit: true,
    width: 120,
    pinned: 'right',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: [],
    suppressMenu: true,
    suppressMovable: true,
    appFeatureId: 4,
    colId: '_myprofileIN'
}

let ordersInternal = {
    headerName: "agGrid.agGridColumns.ordersLabel",
    field: '_myprofileOD',
    filter: "",
    cellRendererFramework: MyprofileInternalPAComponent,
    suppressSizeToFit: true,
    width: 120,
    pinned: 'right',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: [],
    suppressMenu: true,
    suppressMovable: true,
    appFeatureId: 5,
    colId: '_myprofileOD'
}

let locationName = {
    headerName: "XYZ",
    field: 'name',
    hide: true,
    suppressMovable: true,
}

let parentCust = {
    headerName: "PQR",
    field: "parentCustomer",
    rowGroup: true,
    hide: true,
    suppressMovable: true,
}

let mylocation = {
    headerName: 'agGrid.agGridColumns.myLocationLabel',
    field: "name",
    cellClass: "mylocationclass",
    suppressMenu: true,
    showRowGroup: true,
    suppressMovable: true,
    cellRenderer: 'agGroupCellRenderer',
    cellRendererParams: {
        suppressCount: true,
        checkbox: true,
        innerRenderer: 'simpleCellRenderer',
        //suppressDoubleClickExpand: true,
        //suppressEnterExpand: true,
    },
    colId: 'name'
}

let reportTitle = {
    headerName: 'agGrid.agGridColumns.reportTitle',
    field: 'reportName',
    filter: false,
    menuTabs: [],
    cellRendererFramework: ReportsDownloadComponent,
    checkboxSelection: '',
    width: 200,
    autoHeight: true,
    headerCheckboxSelection: '',
    cellClass: "cell-wrap-text",
    suppressMovable: true,
    colId: 'reportName'
}

let reportsDescription = {
    headerName: 'agGrid.agGridColumns.reportsDescription',
    field: 'reportDescription',
    filter: false,
    menuTabs: [],
    cellRendererFramework: '',
    checkboxSelection: '',
    width: 200,
    autoHeight: true,
    headerCheckboxSelection: '',
    cellClass: "cell-wrap-text",
    suppressMovable: true,
    colId: 'reportDescription'
}

let reportsCategory = {
    headerName: 'agGrid.agGridColumns.reportsCategory',
    field: 'moduleName',
    filter: false,
    menuTabs: [],
    cellRendererFramework: '',
    checkboxSelection: '',
    width: 200,
    autoHeight: true,
    headerCheckboxSelection: '',
    cellClass: "cell-wrap-text",
    suppressMovable: true,
    colId: 'moduleName'
}

let reportsType = {
    headerName: 'agGrid.agGridColumns.reportsType',
    field: 'type',
    filter: false,
    menuTabs: [],
    cellRendererFramework: '',
    checkboxSelection: '',
    width: 200,
    autoHeight: true,
    headerCheckboxSelection: '',
    cellClass: "cell-wrap-text",
    suppressMovable: true,
    colId: 'type'
}

let reportsPeriod = {
    headerName: 'agGrid.agGridColumns.reportsPeriod',
    field: 'period',
    filter: false,
    menuTabs: [],
    cellRendererFramework: '',
    checkboxSelection: '',
    width: 200,
    autoHeight: true,
    headerCheckboxSelection: '',
    cellClass: "cell-wrap-text",
    suppressMovable: true,
    colId: 'period'
}

let reportsLastGenerated = {
    headerName: 'agGrid.agGridColumns.reportsLastGenerated',
    field: 'generatedDate',
    filter: false,
    menuTabs: [],
    cellRendererFramework: '',
    checkboxSelection: '',
    width: 200,
    autoHeight: true,
    headerCheckboxSelection: '',
    cellClass: "cell-wrap-text",
    suppressMovable: true,
    colId: 'generatedDate'
}

let manageMappingsColDef = [customerLocation];

let customerMappingsColDef = [customerLocation];

let ucMappingCustomerSearchColDef = [
    customerNumber,
    customerName,
    customerAddress,
    city,
    state,
    zipCode,
    country,
    customerType,
];

let userSearchColDef = [userId, firstName, lastName, email];

let puSearchInternalColDef = [
    pu_userId,
    firstName,
    lastName,
    email,
    pu_passwordExpiration,
    totalVisits,
    lastLogin,
    lastLoginTime,
];

let puSearchExternalColDef = [
    pu_userId,
    firstName,
    lastName,
    email,
    lastLogin,
    lastLoginTime,
];

let delegatedUserSearchColDef = [
    delegatedUserId,
    firstName,
    lastName,
    email,
    lastLoginFrom,
    passwordExpiration,
];

let ExternallocationandMailsColDef = [
    addToPreference,
    priceAgreementExternalNA,
    meetCompExternal,
    chargeBackExternal,
    invoicesExternal,
    ordersExternal,
];

let InternallocationandMailsColDef = [
    mylocation,
    parentCust,
    locationName,
    priceAgreementInternalNA,
    meetCompInternal,
    chargeBackInternal,
    invoicesInternal,
    ordersInternal,
];

let customerSearchColDef = [
    CustomCheckbox,
    CP_Customer_Number,
    CP_Customer_Name,
    CP_Street,
    CP_CP_City,
    PA_CP_State_Name,
    CP_Zip_Code,
    CP_Customer_Type,
];

let myProfileAddLocationColDef = [
    CustomCheckbox,
    CP_Customer_Number,
    CP_Customer_Name,
    CP_Street,
    CP_CP_City,
    CP_State_Name,
    CP_Zip_Code,
    country,
    customerType
];

let HQModalDef = [
    HQModalHQID,
    HQModalHQName,
    HQModalAddress,
    HQModalCity,
    HQModalState,
    HQModalZip,
];

let endUserSearchModalDef = [
    EUModalNum,
    EUModalName,
    EUModalAddress,
    EUModalCity,
    EUModalState,
    EUModalZipcode,
    EUModalPhone,
];

let productSearchModalDef = [
    modalProductCode,
    altProduct,
    ColProductDesc,
    ColProductCat,
];

let custListModalDef = [CP_Customer_Number, CustomerName1];

let activeFutureColDef = [
    CustomCheckbox,
    PA_ID,
    PAName,
    Customer,
    CustomerName,
    TypeName,
    ValidFrom,
    ValidToUFN,
    LastChanged,
    Status,
    LastDownload,
    ContextMenu,
];

let recentlyChangedColDef = [
    CustomCheckbox,
    PA_ID,
    PAName,
    Customer,
    CustomerName,
    TypeName,
    ValidFrom,
    ValidToUFN,
    Change,
    LastChanged,
    ContextMenu,
];

let expiringSoonColDef = [
    CustomCheckbox,
    PA_ID,
    PAName,
    Customer,
    CustomerName,
    TypeName,
    ValidFrom,
    ValidToUFN,
    LastChanged,
    Status,
    ContextMenu,
];

let recentlyExpiredColDef = [
    CustomCheckbox,
    PA_ID,
    PAName,
    Customer,
    CustomerName,
    TypeName,
    ValidFrom,
    ValidToUFN,
    Status,
    LastChanged,
    ContextMenu,
];

let searchResultColDef = [
    CustomCheckbox,
    PA_ID,
    PAName,
    Customer,
    CustomerName,
    TypeName,
    ValidPAFrom,
    ValidToUFN,
    PASearchLastChanged,
    Status,
    LastDownload,
    ContextMenu,
];

let searchResultExternalColDef = [
    CustomCheckbox,
    PA_ID,
    PAName,
    Customer,
    CustomerName,
    TypeName,
    ValidPAFrom,
    ValidToUFN,
    PASearchLastChanged,
    Status,
    ContextMenu,
];

let CVTResultColDef = [
    PA_ID_CVT,
    PAName,
    TypeName,
    Territory,
    TerritoryName,
    ValidFrom,
    ValidToUFN,
    Status,
    ModifyPA,
    ContextMenu,
];

let endUserAdminSearchResultColDef = [
    radioButton,
    PA_ID,
    PAName,
    CustomerId,
    CustomerName,
    euType,
    ValidPAFrom,
    ValidPATo,
    PASearchLastChanged,
    Status,
    EUSearchContextMenu,
];

let myPAFavoritesColDef = [
    CustomCheckbox,
    PA_ID,
    PAName,
    Customer,
    CustomerName,
    TypeName,
    LastUpdated,
    ContextMenu,
];

let tradeOrgColDef = [
    tradeOrgNumber,
    tradeOrgName,
    tradeOrgAddress,
    ValidFrom,
    ValidToUFN,
    tradeOrgComment,
];

let tradeOrgCvtColDef = [
    tradeOrgNumber,
    tradeOrgName,
    tradeOrgAddress,
    ValidFrom,
    ValidToUFN,
    tradeOrgComment,
];

let productColDefOtherThanREandFD = [
    PADetailsProductCode,
    PADetailsColAltProduct,
    PADetailsdescription,
    PADetailsuom,
    PADetailsitemsPerUom,
    PADetailsprice,
    PADetailsValidFrom,
    PADetailsValidTo,
    PADetailsStatus,
    PaDetailsLastUpdated,
    PADetailscomments,
];

let productColDefForREandFD = [
    PADetailProductCode,
    ColAltProduct,
    description,
    uom,
    itemsPerUom,
    priceChargeback,
    FDRate,
    FDstartDate,
    FDEndDate,
    Status,
    PaDetailLastUpdated,
    comments,
];

let productCvtColDefOtherThanREandFD = [
    PADetailProductCodeCVT,
    description,
    pricePerCase,
    caseBreakdownUOM,
    caseBreakdown,
    rollingSalies,
    rollingVolume,
    ValidFrom,
    ValidToUFN,
    Status,
    comments,
];

let productCvtColDefForREandFD = [
    PADetailProductCodeCVT,
    description,
    pricePerCaseForREandFD,
    caseBreakdownUOM,
    caseBreakdown,
    rollingSalies,
    rollingVolume,
    ValidFrom,
    ValidToUFN,
    Status,
    comments,
];

let endUserColDef = [
    EU_ID,
    EUName,
    EUAddress,
    ValidFrom,
    ValidToUFN,
    StatusText,
    updateDate,
    paDetailEuComments,
];

let endUserCVTColDef = [
    EU_ID,
    EUName,
    EUAddress,
    ValidFrom,
    ValidToUFN,
    StatusText,
    updateDate,
    paDetailEuComments,
];

let endUserRequestsColDef = [
    PANumber,
    EUPAName,
    CustomerId,
    EuCustomerName,
    EuDistributor,
    EuRequestsNumber,
    companyName,
    EuRequestAddress,
    EURequestedDate,
    EUStatus,
    euComments,
    EuEstimatedUnit,
];

let endUserSubmittedColDef = [
    EUcompanyName,
    EUFullAddress,
    EUCity,
    EUState,
    EUZipCode,
    EUPhone,
    EuDistributor,
    EuEstimatedUnit,
];

let recentDownloadColDef = [
    CustomCheckbox,
    FileName,
    recentDldCustomerId,
    RecentDldCustomerName,
    FileSize,
    StatusDesc,
    AvaliableUnit,
    lastDownloadedDate,
];

let myNotificationsColDef = [
    PA_Notification,
    PA_Name,
    Customer_Notification,
    Customer_Name,
    Notification,
    Sent,
];

let customerSoldToColDef = [
    CustomerSoldToId,
    customerSoldToName,
    customerSoldToAddress,
    ValidSoldtoFrom,
    ValidSoldtoToUFN,
    SoldToComment,
];

//Added for E-File Kc-File Access
let customerNumberAccess = {
    headerName: 'agGrid.agGridColumns.customerNumberLabel',
    field: 'customerNumber',
    filter: "freeTextFilter",
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: "customerNumber_AC"
}

let customerNameAccess = {
    headerName: 'agGrid.agGridColumns.customerNameLabel',
    field: 'customerName',
    filter: "freeTextFilter",
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: "customerName_AC"
}
let fileAccess = {
    headerName: 'agGrid.agGridColumns.customerAccessLabel',
    field: 'access',
    filter: "multiSelectFilter",
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis ea-dropdown-container",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: "access_AC",
    cellRendererFramework: EaDropdownComponent
}
let efileKcFileAccessColDef = [
    CustomCheckbox,
    customerNumberAccess,
    customerNameAccess,
    fileAccess
];

//Added for E-File Kc-File Access - END

let sfdcColDef = [customerSoldToSfdc];

let dowloadCustomerListColDef = [CP_Customer_Number, customerName_Download];

let reportsPageColumnsGrid = [reportTitle, reportsDescription, reportsCategory, reportsType, reportsPeriod, reportsLastGenerated];

let multipleChangedModalDef = [];

const NA_COL_DEF = {
    customerSoldToColDef,
    sfdcColDef,
    activeFutureColDef,
    recentlyChangedColDef,
    expiringSoonColDef,
    recentlyExpiredColDef,
    searchResultColDef,
    CVTResultColDef,
    myPAFavoritesColDef,
    tradeOrgColDef,
    tradeOrgCvtColDef,
    productColDefOtherThanREandFD,
    productColDefForREandFD,
    productCvtColDefOtherThanREandFD,
    productCvtColDefForREandFD,
    endUserColDef,
    endUserCVTColDef,
    productSearchModalDef,
    custListModalDef,
    endUserSubmittedColDef,
    endUserRequestsColDef,
    endUserAdminSearchResultColDef,
    endUserSearchModalDef,
    recentDownloadColDef,
    HQModalDef,
    customerSearchColDef,
    myProfileAddLocationColDef,
    myNotificationsColDef,
    searchResultExternalColDef,
    userSearchColDef,
    delegatedUserSearchColDef,
    manageMappingsColDef,
    customerMappingsColDef,
    ucMappingCustomerSearchColDef,
    ExternallocationandMailsColDef,
    InternallocationandMailsColDef,
    dowloadCustomerListColDef,
    puSearchInternalColDef,
    puSearchExternalColDef,
    efileKcFileAccessColDef,
    reportsPageColumnsGrid,
    multipleChangedModalDef,
}

export { NA_COL_DEF };