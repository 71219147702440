<span class="multiple-link" *ngIf="params.value == 'Multiple'"
    (click)="onLinkClicked()">
    {{ "chargeback.multipleLabel" | translate }}
</span>
<span *ngIf="params.value != 'Multiple'">
    {{params.value}}
</span>

<ng-container *ngIf="showModal">
    <multiple-ref-number-list [visibleCustModal]="showModal" [searchObj]="multipleCustomerRequest" 
    (closedCustModal)="closedCustModal($event)">
    </multiple-ref-number-list>
</ng-container>