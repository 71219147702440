<header>

    <div class="header__top-bar" *ngIf="showTopHeader">
        <ul class="unlisted inline" *ngIf="visibleSearch === false">

            <li class="header__top-bar__item" id="language" #regionTab>
                <span [ngClass]="{'header-top-bar-trigger': visibleRegion == false, 'header-top-bar-triggered': visibleRegion}" data-top-bar="language" (click)="toggleSidebar('region')" *ngIf="selecteCountry != null">
                    <img src="assets/images/international-flags/{{selecteCountry}}.svg">
                    {{selecteCountryLabel}} - {{preferredLanguageLabel}}</span>
            </li>

            <li class="header__top-bar__link" *ngIf="showGlobalUserTag == true" #externalUsrTab><a><i
                        class="fas fa-user-friends"></i> {{"header.loggedInAs" | translate}}
                    <span>{{_userId}}</span></a></li>

            <li class="header__top-bar__item" id="account" #accountTab>
                <span [ngClass]="{'header-top-bar-trigger': visibleAccount == false, 'header-top-bar-triggered': visibleAccount}" data-top-bar="account" (click)="toggleSidebar('account')"><i class="fas fa-user"></i>
                    {{_username}} <span *ngIf="showGlobalUserTag == true">| {{user?.name1}}</span> </span>
            </li>
        </ul>
    </div>

    <div class="container-fluid">
        <div class="row row-header">
            <div class="col-12">
                <div class="header" [ngClass]="{'header-overlay': visibleRegion || visibleAccount}">


                    <div class="header__left" *ngIf="showTopHeader == false">
                        <a class="header__left__logo" [ngClass]="{'disabled-prefernce':!defaultCountry}" href="javascript:void(0)" (click)="redirectHome()"><img src="/assets/images/KCP-logo.png"></a>
                        <h1 class="header__left__heading">
                            <span class="bold-heading" *ngIf="headerTextIndex == 'primaryHeader' || headerTextIndex == 'myProfileHeader'">{{
                                "header.pageHeader."+headerTextIndex+".part1"| translate}}</span>
                            <span *ngIf="headerTextIndex == 'primaryHeader' || headerTextIndex == 'myProfileHeader'" [hidden]="!defaultCountry && !countryLangSelctionHidden" class="header__left__heading__divider"></span>
                            <span [hidden]="!defaultCountry && !countryLangSelctionHidden">{{
                                "header.pageHeader."+headerTextIndex+".part2"| translate}}</span>

                            <span class="internalViewGrey" [ngClass]="{'internalView': visibleRegion === false && visibleAccount === false, 'internalViewGrey': visibleRegion || visibleAccount}" *ngIf="isInternalUser && !showGlobalUserTag">{{
                                "header.pageHeader.Internal" | translate}}</span>

                            <span class="cvtView" *ngIf="showGlobalUserTag; else cvtTag">{{
                                "header.pageHeader.Global" | translate}}</span>
                            <ng-template #cvtTag>
                                <span class="cvtView" *ngIf="isCVT && !isMyProfilePage">{{
                                    "header.pageHeader.CVT" |
                                    translate}}</span>
                            </ng-template>

                        </h1>
                        <ng-container *ngIf="!hideIfDelegatedSearchPage">
                            <div class="header__left__hamburger no-select" (click)="toggleSidebar('menu')" [hidden]="!defaultCountry">
                                <button type="button" class="tcon tcon-menu--xcross" [ngClass]="{'tcon-transform' :showHamburger}" aria-label="toggle menu">

                                    <span class="tcon-menu__lines" aria-hidden="true"></span>

                                    <span class="tcon-visuallyhidden">{{ "header.toggleMenu" |
                                        translate}}</span>
                                </button>
                                <span class="d-none d-md-inline-block" [translate]="'header.menuTitle'"></span>
                            </div>
                        </ng-container>
                        <!-- <p-tieredMenu #menu [model]="items" [popup]="true" ></p-tieredMenu> -->
                    </div>



                    <div class="header__left" *ngIf="showTopHeader">

                        <a class="header__left__logo" [ngClass]="{'disabled-prefernce':!defaultCountry}" href="javascript:void(0)" (click)="redirectHome()">

                            <div class="header-logo-left">
                                <img src="/assets/images/KCP-logo.png">
                            </div>

                        </a>

                        <h1 class="header__left__heading">
                            <span class="bold-heading" *ngIf="headerTextIndex == 'primaryHeader' || headerTextIndex == 'myProfileHeader'">{{
                                "header.pageHeader."+headerTextIndex+".part1"| translate}}</span>
                            <span *ngIf="headerTextIndex == 'primaryHeader' || headerTextIndex == 'myProfileHeader'" [hidden]="!defaultCountry && !countryLangSelctionHidden" class="header__left__heading__divider"></span>
                            <span [hidden]="!defaultCountry && !countryLangSelctionHidden">{{
                                "header.pageHeader."+headerTextIndex+".part2"| translate}}</span>

                            <span class="internalViewGrey" [ngClass]="{'internalView': visibleRegion === false && visibleAccount === false, 'internalViewGrey': visibleRegion || visibleAccount}" *ngIf="isInternalUser && !showGlobalUserTag">{{
                                "header.pageHeader.Internal" | translate}}</span>

                            <span class="cvtView" *ngIf="showGlobalUserTag; else cvtTag">{{
                                "header.pageHeader.Global" | translate}}</span>
                            <ng-template #cvtTag>
                                <span class="cvtView" *ngIf="isCVT && !isMyProfilePage">{{
                                    "header.pageHeader.CVT" |
                                    translate}}</span>
                            </ng-template>

                        </h1>
                        <ng-container *ngIf="!hideIfDelegatedSearchPage">
                            <div class="header__left__hamburger no-select" (click)="toggleSidebar('menu')" [hidden]="!defaultCountry">
                                <button type="button" class="tcon tcon-menu--xcross" [ngClass]="{'tcon-transform' :showHamburger}" aria-label="toggle menu">

                                    <span class="tcon-menu__lines" aria-hidden="true"></span>

                                    <span class="tcon-visuallyhidden">{{ "header.toggleMenu" |
                                        translate}}</span>
                                </button>
                                <span class="d-none d-md-inline-block" [translate]="'header.menuTitle'"></span>
                            </div>
                        </ng-container>


                        <a class="header__left__logo d-md-none" href="#"><img class="logo-small" src="/assets/images/KCP-logo-small.png"></a>
                    </div>

                    <div class="header__right" *ngIf="showTopHeader">

                        <i *ngIf="visibleSearch" class="fas fa-times header-search-close d-lg-none" (click)="toggleSidebar('search')"></i>

                        <ul class="header__right__utilities unlisted inline no-select">

                            <li class="header__right__utilities__user">

                                <i *ngIf="isGlobalRoleAssigned && defaultCountry" class="fas fa-sign-in-alt"></i>
                                <a *ngIf="isGlobalRoleAssigned && defaultCountry" class="d-none d-lg-inline-block changeGlobalUserRight" href="javascript:void(0)" (click)="actionBtnMenu.toggle($event)">
                                    {{'delegatedUser.changeUser' |
                                    translate}} </a>

                                <p-overlayPanel appendTo="body" styleClass="contextMenu actionBtnMenuOverlay change-user-menu" #actionBtnMenu hideEvent="mousedown">
                                    <span class="custom-table__popup__info custom-table__row-menu">
                                        <ul class="custom-table__row-menu__content actionBtnWrapper">
                                            <li class="action-items" (click)="loginExistingUser()">
                                                {{globalUserName}}
                                            </li>
                                            <hr class="global-menu-line">
                                            <li class="action-items" [ngClass]="{'disabled' : isDelegatedPageOpen}"
                                                (click)="changeGlobalUser()">{{'delegatedUser.selectUser' |
                                                translate}}
                                            </li>
                                        </ul>
                                    </span>
                                </p-overlayPanel>
                            </li>

                            <!-- deactivating product search 20035 -->
                            <li class="header__right__utilities__search" (click)="onSearchProducClicked()">
                                <i class="fas fa-search"></i>
                                <a class="d-none d-lg-inline-block changeGlobalUserRight" href="javascript:void(0)">{{
                                    "header.pageHeader.searchProduct" | translate}}</a>
                            </li>

                            <li class="header__right__utilities__cart" *ngIf="!isInternalUser && region=='NA'">
                                <a href="javascript:void(0)" (click)="openCart()">
                                    <span>{{cartCount}}</span>
                                    <i class="fas fa-shopping-cart"></i>
                                </a>
                            </li>

                        </ul>
                    </div>

                    <!-- <div class="header__right">
                        <div class="header__right__utilities unlisted inline no-select">
                            <ul>
                                <li class="header__right__utilities__settings d-none d-md-inline-block">
                                    <i class="fas fa-user-circle" [ngClass]="{'menuactive': visibleSettingsGlobalHomePage}" (click)="toggleSidebar('settingsGlobalHomePage')"></i>
                                </li>
                            </ul>
                        </div>
                    </div> -->

                    <!--right nav starts-->
                    <div class="header__right" *ngIf="showTopHeader === false">

                        <div class="header__right__utilities unlisted inline no-select" [ngClass]="{ 'header__right__utilities-globalUser' : isGlobalRoleAssigned }">
                            <a *ngIf="isGlobalRoleAssigned && defaultCountry" class="changeGlobalUser" href="javascript:void(0)" (click)="actionBtnMenu.toggle($event)">{{'delegatedUser.changeUser' |
                                translate}} <i class="fa sign-in-alt"></i></a>
                            <p-overlayPanel appendTo="body" styleClass="contextMenu actionBtnMenuOverlay change-user-menu" #actionBtnMenu hideEvent="mousedown">
                                <span class="custom-table__popup__info custom-table__row-menu">
                                    <ul class="custom-table__row-menu__content actionBtnWrapper">
                                        <li class="action-items" (click)="loginExistingUser()">
                                            {{globalUserName}}
                                        </li>
                                        <hr class="global-menu-line">
                                        <li class="action-items" [ngClass]="{'disabled' : isDelegatedPageOpen}"
                                            (click)="changeGlobalUser()">{{'delegatedUser.selectUser' |
                                            translate}}
                                        </li>
                                    </ul>
                                </span>
                            </p-overlayPanel>
                            <ul class="">
                                <li class="header__right__utilities__account d-md-none">
                                    <i class="fas fa-times"></i>
                                    <i class="fas fa-user" [ngClass]="{'menuactive': visibleLoggedPanel}" (click)="toggleSidebar('loggedPanel')"></i>
                                </li>
                                <li class="header__right__utilities__support d-none d-md-inline-block">
                                    <i [hidden]="!defaultCountry" class="far fa-question-circle" [ngClass]="{'menuactive': visibleSupport}" (click)="toggleSidebar('support')"></i>
                                </li>
                                <li class="header__right__utilities__settings d-none d-md-inline-block">
                                    <i [hidden]="!defaultCountry" class="fas fa-user-circle" [ngClass]="{'menuactive': visibleSettings}" (click)="toggleSidebar('settings')"></i>
                                </li>
                                <li class="header__right__utilities__settings d-none d-md-inline-block">
                                    <img [hidden]="!defaultCountry && !countryLangSelctionHidden" width="18" height="18" src="assets/images/international-flags/{{selecteCountry}}.svg" [ngClass]="{'menuactive': visiblePreferenceMenu, 'disabled-prefernce': !defaultCountry}" (click)="toggleSidebar('preferenceMenu')"
                                    />
                                </li>
                            </ul>
                        </div>
                        <div class="header__right__account" [hidden]="!defaultCountry && !countryLangSelctionHidden">
                            <div class="header__right__account__heading d-md-none">
                                <h2>{{'header.loggedInAs' | translate}}</h2>
                            </div>
                            <div class="tooltip-hover" [class.tooltip-hover--open]="customerToolTip">
                                <ul class="unlisted">
                                    <li class="header__right__account__username" (mouseover)="customerToolTip=true" (mouseout)="customerToolTip=false">
                                        {{_username }}</li>
                                    <ng-container *ngIf="(region == 'EMEA' || region == 'APAC') && isInternalUser && !isGlobalUser; else userDetail">
                                        <li *ngIf="region == 'EMEA'">EMEA KCP</li>
                                        <li *ngIf="region == 'APAC'">APAC KCP</li>
                                    </ng-container>

                                    <ng-template #userDetail>
                                        <li>{{user?.name1}}</li>
                                        <li>{{user?.address1}}</li>
                                        <li>{{user?.city}}, {{user?.state}} {{user?.postalCode}}</li>
                                    </ng-template>
                                    <li *ngIf="salesOrgLabel && !showAllTagForEMEA" class="saleorg-ellises" [title]="salesOrgLabel.value">
                                        {{ salesOrgLabel.value }}
                                    </li>
                                    <li *ngIf="salesOrgLabel && showAllTagForEMEA" class="saleorg-ellises">
                                        {{ salesOrgLabel.value }}
                                    </li>

                                    <!-- <li class="header__right__account-type">
                              <p-dropdown [options]="profession" [style]="{'border':'none', 'width':'150px'}"
                                 dropdownIcon="select__Arrow" [disabled]="true">
                                 <ng-template let-item pTemplate="selectedItem">
                                    {{item.label | translate}}
                                 </ng-template>
                                 <ng-template let-item pTemplate="item">
                                    {{item.label| translate}}
                                 </ng-template>
                              </p-dropdown>
                           </li> -->
                                </ul>
                                <div class="tooltip-hover__popup">
                                    <span>{{ "header.customer" | translate }}{{user?.customerNumber}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--right nav ends-->

                </div>
            </div>
        </div>
    </div>

    <!-- <p-sidebar [(visible)]="visibleSearch" position="top" 
        styleClass="menuSidebar" (onHide)="hideSidebar('search');" (onShow)="showSidebar();">
    </p-sidebar> -->

    <p-sidebar [(visible)]="visibleHamburger" position="left" class="header__utility-sidebar header__hamburger" styleClass="menuSidebar" (onHide)="hideSidebar('menu');" (onShow)="showSidebar();" *ngIf="showTopHeader === false">

        <div class="header__nav__professional-link d-none d-md-block">
            <!-- <a href="#">KCProfessional.com <i class="far fa-arrow-alt-circle-right"></i></a> -->
        </div>

        <app-hamburger (clickedValue)="clickedValue($event)"></app-hamburger>


    </p-sidebar>

    <p-sidebar [(visible)]="visibleHamburger" position="left" class="header__utility-sidebar header__hamburger" styleClass="menuSidebar-topheader" (onHide)="hideSidebar('menu');" (onShow)="showSidebar();" *ngIf="showTopHeader">

        <!-- <div class="header__nav__professional-link d-none d-md-block">
        </div> -->

        <app-hamburger [selecteCountry]="selecteCountry" [selecteCountryLabel]="selecteCountryLabel" [preferredLanguageLabel]="preferredLanguageLabel" [_username]="_username" [_simUserId]="_simUserId" (clickedValue)="clickedValue($event)"></app-hamburger>

    </p-sidebar>



    <p-sidebar [(visible)]="visibleSupport" position="right" class="header__utility-sidebar" (onHide)="hideSidebar();" (onShow)="showSidebar();" [style]="{top: '100px',padding:'0'}">
        <div class="header__nav  d-md-none">
            <div class="header__nav__professional-link">
                <a href="#">{{ "humburger.kcpTitle"| translate}} <i class="far fa-arrow-alt-circle-right"></i></a>
            </div>
        </div>
        <div class="header__utility-pullout__heading-mobile d-md-none" [translate]="'supportNav.title'"></div>
        <div class="header__utility-pullout__heading d-none d-md-block">
            <h2 [translate]="'supportNav.title'"></h2>
        </div>
        <ul class="unlisted">
            <!-- <li *ngIf="isInternalUser; else external">
            <a href={{applink.helpLink}} [translate]="'supportNav.link1'"></a>
         </li>
         <ng-template #external>
            <li><a style="color: grey; cursor: default;" [translate]="'supportNav.link1'"></a></li>
         </ng-template> -->
            <!--<li *ngIf="region !== 'LAO'">
                <a (click)="walkmeSetUp($event)" [translate]="'supportNav.link1'"></a>
            </li>-->
            <li>
                <a (click)="contactUs()" [translate]="'supportNav.link2'"></a>
            </li>
        </ul>
    </p-sidebar>

    <p-sidebar [(visible)]="visibleSettings" position="right" class="header__utility-sidebar" (onHide)="hideSidebar();" (onShow)="showSidebar();" [style]="{top: '100px',padding:'0'}">
        <div class="header__nav  d-md-none">
            <div class="header__nav__professional-link">
                <a href="http://www.kcprofessional.com/" target="_blank">{{"humburger.kcpTitle"| translate}} <i
                        class="far fa-arrow-alt-circle-right"></i></a>
            </div>
        </div>
        <div class="header__utility-pullout__heading-mobile d-md-none" [translate]="'settingsNav.title'"></div>
        <div class="header__utility-pullout__heading d-none d-md-block">
            <h2 [translate]="'settingsNav.title'"></h2>
        </div>

        <ul class="unlisted" *ngIf="headerTextIndex !== 'delegatedHeader'; else delegatedPageSettingMenu">
            <li *ngIf="isInternalUser && region == 'NA'">
                <a href={{applink.contentMangementTool}} [translate]="'settingsNav.contentMang'"></a>
            </li>
            <li>
                <a [class.disableLink]="isDisabled" (click)="profileLink($event)" [translate]="'settingsNav.profile'"></a>
            </li>
            <!-- Change Password -->
            <li *ngIf="!isInternalUser && !isExternalAdminUser">
                <a target="_blank" rel="noopener noreferrer" href={{changePassowrdLink}} [translate]="'settingsNav.subheading1Link1'"></a>
            </li>
            <li *ngIf="isInternalUser && isAdminRole" class="sub-list">
                <!--<li *ngIf="isInternalUser" class="sub-list">-->
                <div [translate]="'settingsNav.subheading1'"></div>
                <ul class="unlisted">
                    <li *ngIf="region === 'LAO'">
                        <a href="javascript:void(0)" (click)="openAccessManagementLink()" [translate]="'settingsNav.subheading1Link3'"></a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" (click)="portalUsage()" [translate]="'settingsNav.subheading1Link4'"></a>
                    </li>
                    <li *ngIf="region === 'NA'">
                        <a href="javascript:void(0)" (click)="reportsLink()" [translate]="'settingsNav.reports'"></a>
                    </li>
                    <li *ngIf="region === 'NA'">
                        <a href="javascript:void(0)" (click)="cmtReportsLink()" [translate]="'settingsNav.cmtReports'"></a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" (click)="ucMapping()" [translate]="'settingsNav.subheading1Link6'"></a>
                    </li>
                    <!-- <li *ngIf="region != 'NA'">
                        <a href="javascript:void(0)" (click)="openAccessManagementLink()" [translate]="'settingsNav.subheading1Link7'"></a>
                    </li> -->
                    <li *ngIf="!isEfileAccessBlocked">
                        <a href="javascript:void(0)" (click)="efileKcfileAccesslink()" [translate]="'settingsNav.eFileAccess'"></a>
                    </li>
                </ul>
            </li>

            <li #isExternalAdminUserElm class="sub-list">
                <div [translate]="'settingsNav.subheading1'"></div>
                <ul class="unlisted">
                    <li *ngIf="region != 'LAO' && region != 'APAC'">
                        <a href="javascript:void(0)" (click)="openAccessManagementLink()" [translate]="'settingsNav.subheading1Link3'"></a>
                    </li>
                    <!-- Change Password -->
                    <li>
                        <a target="_blank" rel="noopener noreferrer" href={{changePassowrdLink}} [translate]="'settingsNav.subheading1Link1'"></a>
                    </li>
                    <!-- manage users -->
                    <li>
                        <a href="javascript:void(0)" [translate]="'settingsNav.subheading1Link4'" (click)="portalUsage()"></a>
                    </li>
                    <!-- Portal Usage Report -->
                    <li *ngIf="region === 'NA'">
                        <a target="_blank" href={{applink.adminManualLink}} [translate]="'settingsNav.subheading1Link5'"></a>
                    </li>
                    <!-- <li *ngIf="region === 'LAO'">
                        <a target="_blank" href={{applink.adminManualLinkLAO}} [translate]="'settingsNav.subheading1Link5'"></a>
                    </li> -->
                    <!-- Removed for EMEA - Change Request - 17965 
                    <li *ngIf="region === 'EMEA'">
                        <a target="_blank" href={{applink.adminManualLinkLAO}} [translate]="'settingsNav.subheading1Link5'"></a>
                    </li>-->
                    <!-- Site Administrator Manual -->
                </ul>
            </li>
            <!--<li *ngIf="isInternalUser">
                <a href="{{applink.kcProfessionalLink}}" target="_blank">EFile/K-C File Access</a>
            </li>-->

            <li>
                <a href="javascript:void(0)" (click)="logout()" [translate]="'settingsNav.logout'"></a>
            </li>
        </ul>
        <ng-template #delegatedPageSettingMenu>
            <ul class="unlisted">
                <li>
                    <a href="javascript:void(0)" (click)="logout()" [translate]="'settingsNav.logout'"></a>
                </li>
            </ul>
        </ng-template>
    </p-sidebar>


    <p-sidebar [(visible)]="visibleAccount" position="right" styleClass="sidebar-account" (onHide)="hideSidebar();" (onShow)="showSidebar();" [showCloseIcon]="false">

        <div class="backArrow" (click)="toggleSidebar('account')">{{'header.backBtn' | translate }}</div>

        <div class="header__top-bar__content__section" id="header-section-top">
            <div class="account-user">
                <p class="account-user__name"> {{_username }}</p>

                <ul class="unlisted account-user__address">
                    <li>{{user?.name1}}</li>
                    <li>{{user?.address1 | titlecase}}</li>
                    <li>{{user?.city | titlecase }}, {{user?.state}} {{user?.postalCode}}</li>
                </ul>
                <p class="account-user__company">{{region}} - KC PROFESSIONAL</p>
            </div>
        </div>

        <div class="header__top-bar__content__section" *ngIf="headerTextIndex !== 'delegatedHeader' && isInternalUser && region == 'NA'">

            <li class="sub-list sidebar-li">

                <ul class="unlisted_account_admin_links">
                    <a class="sidebar-a-bold" href={{applink.contentMangementTool}} [translate]="'settingsNav.contentMang'"></a>
                </ul>
            </li>
        </div>

        <div class="header__top-bar__content__section" *ngIf="headerTextIndex !== 'delegatedHeader' && isInternalUser && isAdminRole">
            <li *ngIf="isInternalUser && isAdminRole" class="sub-list sidebar-li">
                <ul class="unlisted_account_admin_links">
                    <li class="sidebar-li" [translate]="'settingsNav.subheading1'"></li>
                    <a *ngIf="region === 'LAO'"  href="javascript:void(0)" (click)="openAccessManagementLink()" [translate]="'settingsNav.subheading1Link3'"></a><br>
                    <a href="javascript:void(0)" (click)="portalUsage()" [translate]="'settingsNav.subheading1Link4'"></a><br>

                    <a *ngIf="region === 'NA'" href="javascript:void(0)" (click)="reportsLink()" [translate]="'settingsNav.reports'"></a><br>


                    <a *ngIf="region === 'NA'" href="javascript:void(0)" (click)="cmtReportsLink()" [translate]="'settingsNav.cmtReports'"></a><br>


                    <a href="javascript:void(0)" (click)="ucMapping()" [translate]="'settingsNav.subheading1Link6'"></a><br>

                    <a *ngIf="(region === 'NA' || region === 'EMEA' || region === 'LAO' || region === 'APAC') && !isEfileAccessBlocked" href="javascript:void(0)" (click)="efileKcfileAccesslink()" [translate]="'settingsNav.eFileAccess'"></a>

                </ul>
            </li>

        </div>

        <div class="header__top-bar__content__section" *ngIf="showExternalAdminUser == true">
            <ul class="unlisted_account_admin_links">

                <li class="sidebar-li" [translate]="'settingsNav.subheading1'"></li>

                <a *ngIf="region !='LAO'" href=" javascript:void(0)" (click)="openAccessManagementLink()" [translate]="'settingsNav.subheading1Link3'"></a><br>

                    <a href="javascript:void(0)" [translate]="'settingsNav.subheading1Link4'" (click)="portalUsage()"></a><br>
                    <!-- Change Password -->
                    <a target="_blank" rel="noopener noreferrer" href={{changePassowrdLink}} [translate]="'settingsNav.subheading1Link1'"></a>
                    <a *ngIf="region === 'NA'" target="_blank" href={{applink.adminManualLink}} [translate]="'settingsNav.subheading1Link5'"></a><br>

            </ul>

        </div>

        <div class="header__top-bar__content__section">
            <ul class="unlisted_account_admin_links">

                <li *ngIf="headerTextIndex !== 'delegatedHeader'" [class.disableLink]="isDisabled" (click)="profileLink($event)">
                    <a class="sidebar-a">
                        <span class="sidebar-a"> {{ "settingsNav.profile" | translate}} - {{_userIdSide}}</span>
                    </a>
                </li>

                <li class="sidebar-a" (click)="contactUs()" [translate]="'supportNav.link2'"></li>

                <li class="sidebar-a" (click)="logout()" [translate]="'settingsNav.logout'"></li>

            </ul>
        </div>
    </p-sidebar>


    <p-sidebar [(visible)]="visiblePreferenceMenu" position="right" class="header__utility-sidebar" (onHide)="hideSidebar();" (onShow)="showSidebar();" [style]="{top: '100px',padding:'0'}">
        <div class="header__nav  d-md-none">
            <div class="header__nav__professional-link">
                <a href="http://www.kcprofessional.com/" target="_blank">{{ " header.regionsLabel "| translate}} <i
                        class="far fa-arrow-alt-circle-right"></i></a>
            </div>
        </div>
        <div class="header__utility-pullout__heading-mobile d-md-none" [translate]="'regionNav.region'"></div>
        <div class="header__utility-pullout__heading d-none d-md-block">
            <h2 [translate]="'settingsNav.region'"></h2>
        </div>

        <div class="form-group">
            <label for="access1" class="hidden-text-for-accessibility">{{ " header.accessLabel "|
                translate}}</label>

            <p-dropdown [options]="displayCountry" [(ngModel)]="defaultCountry" [autoWidth]="true" styleClass="form-control countrySelect" dropdownIcon="select__Arrow" (onChange)="onCountryChange($event)">

                <ng-template let-item pTemplate="selectedItem">
                    <img width="18" height="18" src="assets/images/international-flags/{{item.value | lowercase }}.svg" />
                    <span>{{item.label}}</span>
                </ng-template>
                <ng-template let-item pTemplate="item">
                    <img width="18" height="18" src="assets/images/international-flags/{{item.value | lowercase}}.svg" />
                    <span>{{item.label}}</span>
                </ng-template>
            </p-dropdown>
        </div>
        <div class="form-group">
            <p-dropdown [options]="displayLanguage" [(ngModel)]="preferredLanguage" [autoWidth]="true" styleClass="form-control" dropdownIcon="select__Arrow" (onChange)="onLanguageChange($event)">
            </p-dropdown>
        </div>
    </p-sidebar>

    <p-sidebar [(visible)]="visibleSettingsGlobalHomePage" position="right" class="header__utility-sidebar" (onHide)="hideSidebar();" (onShow)="showSidebar();" [style]="{top: '100px',padding:'0'}">
        <div class="header__utility-pullout__heading d-none d-md-block">
            <h2 [translate]="'settingsNav.title'"></h2>
        </div>
        <ul class="unlisted">
            <li>
                <a href="javascript:void(0)" (click)="logout()" [translate]="'settingsNav.logout'"></a>
            </li>
        </ul>
    </p-sidebar>


    <p-sidebar [(visible)]="visibleRegion" position="right" styleClass="sidebar-region" (onHide)="hideSidebar();" (onShow)="showSidebar();" [showCloseIcon]="false">
        <div class="backArrow" (click)="toggleSidebar('region')">{{'header.backBtn' | translate }}</div>
        <div class="form-group sidebar-select">
            <label for="access1" class="hidden-text-for-accessibility">{{ "
                header.accessLabel "|
                translate}}</label>
            <p-dropdown [options]="displayCountry" [(ngModel)]="defaultCountry" [autoWidth]="true" styleClass="form-control countrySelect" dropdownIcon="select__Arrow" (onChange)="onCountryChange($event)">

                <ng-template let-item pTemplate="selectedItem">
                    <img width="18" height="18" class="select-image" src="assets/images/international-flags/{{item.value | lowercase }}.svg" />
                    <span class="select-label">{{item.label}}</span>
                </ng-template>
                <ng-template let-item pTemplate="item">
                    <img width="18" height="18" src="assets/images/international-flags/{{item.value | lowercase}}.svg" />
                    <span>{{item.label}}</span>
                </ng-template>
            </p-dropdown>
        </div>

        <div class="form-group sidebar-select">
            <p-dropdown [options]="displayLanguage" [(ngModel)]="preferredLanguage" [autoWidth]="true" styleClass="form-control countrySelect" dropdownIcon="select__Arrow" (onChange)="onLanguageChange($event)">
            </p-dropdown>
        </div>
    </p-sidebar>


    <p-sidebar [(visible)]="visibleAccount" position="right" styleClass="sidebar-account" (onHide)="hideSidebar();" (onShow)="showSidebar();" [showCloseIcon]="false">

        <div class="backArrow" (click)="toggleSidebar('account')">{{'header.backBtn' | translate }}</div>

        <div class="header__top-bar__content__section" id="header-section-top">
            <div class="account-user">
                <p class="account-user__name"> {{_username }}</p>

                <ul class="unlisted account-user__address">
                    <li>{{user?.name1}}</li>
                    <li># {{user?.customerNumber}}</li>
                    <li>{{user?.address1 | titlecase}}</li>
                    <li>{{user?.city | titlecase }}, {{user?.state}} {{user?.postalCode}}</li>
                </ul>
                <p *ngIf="salesOrgLabel && !isInternalUser" class="account-user__company saleorg-ellises" [title]="salesOrgLabel.value">
                    {{ salesOrgLabel.value }}
                </p>
                <p *ngIf="isInternalUser" class="account-user__company">
                    {{region}} - KC PROFESSIONAL
                </p>
            </div>
        </div>


        <div class="header__top-bar__content__section" *ngIf="isInternalUser && region == 'NA'">
            <li class="sub-list sidebar-li">
                <ul class="unlisted_account_admin_links">

                    <li>
                        <a class="sidebar-a-bold" href={{applink.contentMangementTool}} [translate]="'settingsNav.contentMang'"></a>
                    </li>

                </ul>
            </li>
        </div>


        <div *ngIf="isInternalUser && isAdminRole" class="header__top-bar__content__section">

            <li class="sub-list sidebar-li">
                <ul class="unlisted_account_admin_links">
                    <li class="sidebar-li" [translate]="'settingsNav.subheading1'"></li>

                    <!-- manage users -->
                    <li class="site-admin-menu-items" *ngIf="region === 'NA'" (click)="openAccessManagementLink()" [translate]="'settingsNav.subheading1Link3'"></li>

                    <!-- Portal Usage Report -->
                    <li class="site-admin-menu-items" [translate]="'settingsNav.subheading1Link4'" (click)="portalUsage()"></li>

                    <li *ngIf="region === 'NA'" class="site-admin-menu-items" (click)="reportsLink()" [translate]="'settingsNav.reports'"></li>

                    <li *ngIf="region === 'NA'" class="site-admin-menu-items" (click)="cmtReportsLink()" [translate]="'settingsNav.cmtReports'"></li>

                    <li class="site-admin-menu-items" (click)="ucMapping()" [translate]="'settingsNav.subheading1Link6'"></li>

                    <li *ngIf="!isEfileAccessBlocked" class="site-admin-menu-items" (click)="efileKcfileAccesslink()" [translate]="'settingsNav.eFileAccess'"></li>
                    <!-- ends added links -->

                </ul>
            </li>
        </div>


        <div *ngIf="isExternalAdminUser" class="header__top-bar__content__section">

            <li class="sub-list sidebar-li">
                <ul class="unlisted_account_admin_links">
                    <li class="sidebar-li" [translate]="'settingsNav.subheading1'"></li>

                    <!-- manage users -->
                    <a *ngIf="region !='LAO'" href=" javascript:void(0)" (click)="openAccessManagementLink()" [translate]="'settingsNav.subheading1Link3'"></a><br>


                        <!-- Change Password -->
                        <a target="_blank" rel="noopener noreferrer" href={{changePassowrdLink}} [translate]="'settingsNav.subheading1Link1'"></a><br>

                        <!-- Portal Usage Report -->
                        <a href="javascript:void(0)" [translate]="'settingsNav.subheading1Link4'" (click)="portalUsage()"></a><br>

                        <!-- 34839 -->

                        <!--Manual NA manual should be avail for external admin -->
                        <a *ngIf="region === 'NA'  && !isInternalUser && isExternalAdminUser" target=" _blank" href={{applink.adminManualLink}} [translate]="'settingsNav.subheading1Link5'"></a>

                        <div *ngIf="isInternalUser && isAdminRole">


                            <!-- <a *ngIf="region === 'EMEA'" target="_blank" href={{applink.adminManualLinkLAO}}
                        [translate]="'settingsNav.subheading1Link5'"></a> -->

                            <!-- starts added links -->

                            <a *ngIf="region === 'NA'" href="javascript:void(0)" (click)="cmtReportsLink()" [translate]="'settingsNav.cmtReports'"></a><br>

                            <a href="javascript:void(0)" (click)="ucMapping()" [translate]="'settingsNav.subheading1Link6'"></a><br>


                            <a *ngIf="(region === 'NA' || region === 'EMEA' || region === 'LAO' || region === 'APAC') && !isEfileAccessBlocked" href="javascript:void(0)" (click)="efileKcfileAccesslink()" [translate]="'settingsNav.eFileAccess'"></a>

                        </div>

                        <!-- ends added links -->

                </ul>
            </li>
        </div>

        <div class="header__top-bar__content__section">
            <ul class="unlisted_account_admin_links">

                <li class="sidebar-a" [class.disableLink]="isDisabled" (click)="profileLink($event)">
                    <span class="sidebar-a"> {{ "settingsNav.profile" | translate}} - {{_userIdSide}}</span>
                </li>

                <!-- Change Password -->
                <li class="sidebar-a" *ngIf="!isInternalUser && !isExternalAdminUser">
                    <a target="_blank" rel="noopener noreferrer" style="font-weight: 400;font-size: 14px;" href={{changePassowrdLink}} [translate]="'settingsNav.subheading1Link1'"></a>
                </li>
                <li class="sidebar-a" (click)="contactUs()" [translate]="'supportNav.link2'"></li>

                <li class="sidebar-a" (click)="logout()" [translate]="'settingsNav.logout'"></li>

            </ul>
        </div>

    </p-sidebar>

    <!-- deactivating product search 20035 -->

    <div *ngIf="showTopHeader">

        <p-sidebar [(visible)]="visibleSearch" position="right" (onHide)="hideSidebar();" (onShow)="showSidebar();" [showCloseIcon]="false" styleClass="sidebar-search-top">

            <div class="search-body">
                <div class="row">
                    <div class="form-group">
                        <input type="text" class="form-control" styleClass="control-search" id="search" autocomplete="off" [(ngModel)]="searchText" (ngModelChange)="onSearchChange($event)" placeholder="{{ 'header.pageHeader.searchProductbyKey' | translate}}">
                    </div>
                    <i class="fas fa-times header-search-close d-lg-inline-block" (click)="toggleSidebar('search')"></i>
                </div>

            </div>

        </p-sidebar>

        <p-sidebar [(visible)]="visibleSearch" position="right" (onHide)="hideSidebar('search');" (onShow)="showSidebar();" [showCloseIcon]="false" styleClass="sidebar-search">
            <app-search-result [searchResult]="searchResult" [categoryResults]="categoryResults" [searchText]="searchText" [resultCount]="resultCount" *ngIf="visibleSearch">
            </app-search-result>
        </p-sidebar>

    </div>




</header>