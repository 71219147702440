import { Component, OnDestroy } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import { GlobalAppService } from '@app/Modules/Shared/Services/global-app.service';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { HttpSiteadminService } from '@app/Modules/Shared/Services/http-siteadmin.service';
import { MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';

@AutoUnsubscribe()

@Component({
  selector: 'reports-download',
  templateUrl: './reports-download.component.html',
  styleUrls: ['./reports-download.component.scss']
})
export class ReportsDownloadComponent implements AgRendererComponent, OnDestroy {

  params: any;
  documentId: any;
  subscription: Subscription;
  language: string;
  region: string;
  paramsValue: string;
  LCIDString: any;
  downloadLink: any;
  showNotification: boolean = false;

  constructor(
    private httpServ: HttpSiteadminService,
    private _globalAppService: GlobalAppService,
    private translateServ: TranslateService,
    private appServ: AppService,
    private messageService: MessageService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  agInit(params: any): void {
    this.params = params;
    this.paramsValue = params.value;
    let reportId = this.activatedRoute.snapshot.queryParams.ReportId;  
    if(reportId){
      // This download is now moved to home module
      // this.autoDownloadFile(reportId);
    }
    this.prepareDownloadLinkToCopy(); 
  }

  prepareDownloadLinkToCopy(){
    let url = window.location.href;    
    let queryParam = 'ReportId=' + this.params.data.reportId;        
    if(url.indexOf('ReportId=') > -1){
      let index1 = url.indexOf('ReportId=');
      let index2 = url.indexOf('&', index1);
      if(index2 > -1 && index2 > index1){
        this.downloadLink = url.replace(url.substring(index1, index2), queryParam);
      } else {
        this.downloadLink = url.replace(url.substring(index1), queryParam);
      }
    } else {
      let separator = (url.indexOf("?")===-1)?"?":"&";
      this.downloadLink = url.trim() + separator + queryParam;
    }    
  }

  autoDownloadFile(reportId) {
    if (this.params && this.params.data && this.params.data.reportId && this.params.data.reportId == reportId) {
      this.makeAPICall(this.params.data.reportId);
    }
  }

  notify(e) {
    this.showNotification = true;
    setTimeout(() => {
      this.showNotification = false;
    }, 2000);
  }

  setLanguageListener() {
    this.translateServ.get(['LCIDString'])
      .subscribe(value => {
        if (value) {
          this.LCIDString = value.LCIDString;
        }
      });
  }

  refresh(params: any): boolean {
    return false;
  }

  onLinkClick(event) {
    if (this.params && this.params.data && this.params.data.reportId) {
      this.makeAPICall(this.params.data.reportId);
    }
  }

  makeAPICall(reportId: number) {
    this.setLanguageListener();
    let region = this._globalAppService.getDefaultRegionString();
    let request: any = {
      "regionId": region,
      "languageCode": this.LCIDString,
      "reportType": ""
    };
    switch (reportId) {
      case 1:
        request.reportType = "KCPEmailNotifications";
        break;
      case 2:
        request.reportType = "UserCustomerMapping";       
        break;
      case 8:
        request.reportType = "ViewPAcomment";
        break;
      case 10:
        request.reportType = "ExpectedVolumeReporting";
        break;
      case 17:
        request.reportType = "PAEmailReporting";
        break;
      default:
        request = this.LCIDString;
    }
    
    this.appServ.setLoadingStatus(true);
    this.subscription = this.httpServ.makeReportDownloadCall(request, reportId).subscribe(res => {
      if (res && res.reference) {
        let downloadResource = res.reference;
        this.router.navigate([{ outlets: { dialogs: null } }]).then(() => {
          this.router.navigate([{ outlets: { dialogs: ['downloadLinkModal'] } }], { relativeTo: this.activatedRoute.parent, state: { url: downloadResource, title: 'priceagreement.title' } });
        });
      }
      else if (res && res.fileUrl) {
        let downloadResource = res.fileUrl;
        this.router.navigate([{ outlets: { dialogs: null } }]).then(() => {
          this.router.navigate([{ outlets: { dialogs: ['downloadLinkModal'] } }], { relativeTo: this.activatedRoute.parent, state: { url: downloadResource, title: 'priceagreement.title' } });
        });
      }
      else{
        this.appServ.setLoadingStatus(false)
        this.messageService.add({ key: 'downloadError', summary: 'errorLabel', detail: '0000.errorMessage', severity: 'error' });
      }
    },
      (e) => {
        this.appServ.setLoadingStatus(false)
        this.messageService.add({ key: 'downloadError', summary: 'errorLabel', detail: '0000.errorMessage', severity: 'error' });
      },
      () => {
        this.appServ.setLoadingStatus(false);
        if(this.subscription){
          this.subscription.unsubscribe();
        }
      }
    )
  }

  ngOnDestroy() {
    // Required for unsubscribing and destroying the component  
    if(this.subscription){
      this.subscription.unsubscribe();
    }
  }

}
