<ng-container>
    <span class="custom-table__row-menu">
    <i class="fas fa-ellipsis-h ellipsisbtn" [ngClass]="{'ellipseSelect' : ellipseSelect}" (click)="contextmenuToggle(contextmenu, $event)"></i>
  </span>
</ng-container>
<p-overlayPanel appendTo="body" styleClass="contextMenu" #contextmenu (onHide)="onContextMenuHide()" (onShow)="onContextMenuShow()">
    <span class="custom-table__popup__info custom-table__row-menu">
    <ul class="custom-table__row-menu__content">
      <li *ngIf="params.data?.refreshStatus == 1">
        <a href="javascript:void(0)" (click)="notifyMe()">
          <i class="far fa-envelope"></i>
          {{'invoicePrice.contextMenu.notifyMeLabel' | translate}}
        </a>
      </li>
      <li *ngIf="params.data?.refreshStatus == 2 || (params.data?.refreshStatus == 4 && region== 'APAC')">
        <a href="javascript:void(0)" (click)="downloadInvoice()">
          <i class="far fa-arrow-alt-circle-down"></i>
          {{'invoicePrice.contextMenu.downloadInvoiceLabel' | translate}}
        </a>
      </li>
      <li *ngIf="params.data?.refreshStatus == 3 || params.data?.refreshStatus == 0 || (params.data?.refreshStatus == 4 && region== 'APAC')" >
        <a href="javascript:void(0)" (click)="isRefreshAllowed()" [ngClass]="{'disabled': isGlobalUser && (region == 'EMEA' || region == 'LAO')}">
          <i class="fa fa-refresh"></i>
          {{'invoicePrice.contextMenu.requestRefreshLabel' | translate}}
        </a>
      </li>
      <li *ngIf="region == 'NA' && !isPageViewByCategory">
        <a href="javascript:void(0)"  (click)="viewByCategory()">
          <i class="far fa-arrow-alt-circle-right"></i>
          {{'invoicePrice.contextMenu.viewByCategoryLabel' | translate}}
        </a>
      </li>
    </ul>
  </span>
</p-overlayPanel>