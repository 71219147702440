<ng-container>
    <span class="custom-table__row-menu">
    <i class="fas fa-ellipsis-h ellipsisbtn" [ngClass]="{'ellipseSelect' : ellipseSelect}" (click)="contextmenuToggle(contextmenu, $event)"></i>
  </span>
</ng-container>
<p-overlayPanel appendTo="body" styleClass="contextMenu" #contextmenu (onHide)="onContextMenuHide()" (onShow)="onContextMenuShow()">
    <span class="custom-table__popup__info custom-table__row-menu">
    <ul class="custom-table__row-menu__content" *ngIf="errorFoundTestContextMenu">
      <li>
        <a href="javascript:void(0)" (click)="downloadEfile($event, contextmenu)">
          <i class="far fa-arrow-alt-circle-down"></i>
          {{'chargeback.eFile.contextMenu.downloadEfile' | translate}}
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" (click)="errorSummaryClick()">
          <i class="far fa-list-alt"></i>
          {{'chargeback.eFile.contextMenu.errorSummary' | translate}}
        </a>
      </li>
      <li *ngIf="isInternalUser || (isGlobalUser && (region == 'EMEA' || region == 'LAO'))">
        <a href="javascript:void(0)" (click)="manageErrorsOnly($event, contextmenu)">
          <i class="far fa-arrow-alt-circle-right"></i>
          {{'chargeback.eFile.contextMenu.viewErrors' | translate}}
        </a>
      </li>
      <li *ngIf="!isInternalUser && !(isGlobalUser && (region == 'EMEA' || region == 'LAO'))" [ngClass]="[ params.data.errors > 50 ? 'disabledOption' : '']">
        <a href="javascript:void(0)" (click)="manageErrorsOnly($event, contextmenu)">
          <i class="far fa-edit"></i>
          {{'chargeback.eFile.contextMenu.correctErrors' | translate}}
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" (click)="efileHistoryPopUp($event, contextmenu)">
          <i class="fa fa-history"></i>
          {{'chargeback.eFile.contextMenu.historyLog' | translate}}
        </a>
      </li>
      <li *ngIf="!isInternalUser && !(isGlobalUser && (region == 'EMEA' || region == 'LAO'))">
        <a href="javascript:void(0)" (click)="openDeletePopup($event, contextmenu)">
          <i class="fa fa-times-circle-o textRed"></i>
          {{'chargeback.eFile.contextMenu.deleteFile' | translate}}
        </a>
      </li>
    </ul>


    <ul class="custom-table__row-menu__content" *ngIf="errorFoundActiveContextMenu">
      <li>
        <a href="javascript:void(0)" (click)="downloadEfile($event, contextmenu)">
          <i class="far fa-arrow-alt-circle-down"></i>
          {{'chargeback.eFile.contextMenu.downloadEfile' | translate}}
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" (click)="errorSummaryClick()">
          <i class="far fa-list-alt"></i>
          {{'chargeback.eFile.contextMenu.errorSummary' | translate}}
        </a>
      </li>
      <li *ngIf="isInternalUser">
        <a href="javascript:void(0)" (click)="manageErrorsOnly($event, contextmenu)">
          <i class="far fa-arrow-alt-circle-right"></i>
          {{'chargeback.eFile.contextMenu.viewErrors' | translate}}
        </a>
      </li>
      <li *ngIf="!isInternalUser"  [ngClass]="[ params.data.errors > 50 ? 'disabledOption' : '']" (click)="manageErrorsOnly($event, contextmenu)">
        <a href="javascript:void(0)">
          <i class="far fa-edit"></i>
          {{'chargeback.eFile.contextMenu.correctErrors' | translate}}
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" (click)="efileHistoryPopUp($event, contextmenu)">
          <i class="fa fa-history"></i>
          {{'chargeback.eFile.contextMenu.historyLog' | translate}}
        </a>
      </li>
      <li *ngIf="!isInternalUser && !(isGlobalUser && (region == 'EMEA' || region == 'LAO'))">
        <a href="javascript:void(0)" (click)="openDeletePopup($event, contextmenu)">
          <i class="fa fa-times-circle-o textRed"></i>
          {{'chargeback.eFile.contextMenu.deleteFile' | translate}}
        </a>
      </li>
    </ul>


    <ul class="custom-table__row-menu__content" *ngIf="validatedContextMenu">
      <li>
        <a href="javascript:void(0)" (click)="downloadEfile($event, contextmenu)">
          <i class="far fa-arrow-alt-circle-down"></i>
          {{'chargeback.eFile.contextMenu.downloadEfile' | translate}}
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" (click)="efileHistoryPopUp($event, contextmenu)">
          <i class="fa fa-history"></i>
          {{'chargeback.eFile.contextMenu.historyLog' | translate}}
        </a>
      </li>
      <li *ngIf="!isInternalUser && !(isGlobalUser && (region == 'EMEA' || region == 'LAO'))">
        <a href="javascript:void(0)" (click)="openDeletePopup($event, contextmenu)">
          <i class="fa fa-times-circle-o textRed"></i>
          {{'chargeback.eFile.contextMenu.deleteFile' | translate}}
        </a>
      </li>
    </ul>
    <ul class="custom-table__row-menu__content" *ngIf="processingTestContextMenu">
      <li>
        <a href="javascript:void(0)" (click)="downloadEfile($event, contextmenu)">
          <i class="far fa-arrow-alt-circle-down"></i>
          {{'chargeback.eFile.contextMenu.downloadEfile' | translate}}
      </a
        ></li>
      <li>
        <a href="javascript:void(0)" (click)="efileHistoryPopUp($event, contextmenu)">
          <i class="fa fa-history"></i>
          {{'chargeback.eFile.contextMenu.historyLog' | translate}}
      </a>
      </li>
    </ul>


    <ul class="custom-table__row-menu__content" *ngIf="processingActiveContextMenu">
      <li>
        <a href="javascript:void(0)" (click)="downloadEfile($event, contextmenu)">
          <i class="far fa-arrow-alt-circle-down"></i>
          {{'chargeback.eFile.contextMenu.downloadEfile' | translate}}
      </a>
      </li>
      <li>
        <a href="javascript:void(0)" (click)="efileHistoryPopUp($event, contextmenu)">
          <i class="fa fa-history"></i>
          {{'chargeback.eFile.contextMenu.historyLog' | translate}}
      </a>
      </li>
    </ul>


    <ul class="custom-table__row-menu__content" *ngIf="readyToSubmitContextMenu">
      <li *ngIf="!isInternalUser && !isEfileSubmitted && !(isGlobalUser && (region == 'EMEA' || region == 'LAO'))">
        <a href="javascript:void(0)" (click)="submitEfile($event, contextmenu)">
          <i class="far fa-arrow-alt-circle-right"></i>
          {{'chargeback.eFile.contextMenu.submit' | translate}}
      </a></li>
      <li>
        <a href="javascript:void(0)" (click)="downloadEfile($event, contextmenu)">
          <i class="far fa-arrow-alt-circle-down"></i>
          {{'chargeback.eFile.contextMenu.downloadEfile' | translate}}
      </a>
      </li>
      <li>
        <a href="javascript:void(0)" (click)="efileHistoryPopUp($event, contextmenu)">
          <i class="fa fa-history"></i>
          {{'chargeback.eFile.contextMenu.historyLog' | translate}}
      </a>
      </li>
      <li *ngIf="!isInternalUser && !isEfileSubmitted  && !(isGlobalUser && (region == 'EMEA' || region == 'LAO'))">
        <a href="javascript:void(0)" (click)="openDeletePopup($event, contextmenu)">
          <i class="fa fa-times-circle-o textRed"></i>
          {{'chargeback.eFile.contextMenu.deleteFile' | translate}}
      </a>
      </li>
    </ul>
    
    
    
    
    
    
    <ul class="custom-table__row-menu__content" *ngIf="submittedContextMenu">
      <li>
        <a href="javascript:void(0)" (click)="downloadEfile($event, contextmenu)">
          <i class="far fa-arrow-alt-circle-down"></i>
          {{'chargeback.eFile.contextMenu.downloadEfile' | translate}}
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" (click)="efileHistoryPopUp($event, contextmenu)">
          <i class="fa fa-history"></i>
          {{'chargeback.eFile.contextMenu.historyLog' | translate}}
        </a>
      </li>
    </ul>
    <ul class="custom-table__row-menu__content" *ngIf="deletedTestContextMenu" #deletedTestContextMenu>
      <li [ngClass]="[ params.data.lines == 0 ? 'disabledOption' : '']">
        <a href="javascript:void(0)" (click)="downloadEfile($event, contextmenu)">
          <i class="far fa-arrow-alt-circle-down"></i>
          {{'chargeback.eFile.contextMenu.downloadEfile' | translate}}
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" (click)="efileHistoryPopUp($event, contextmenu)">
          <i class="fa fa-history"></i>
          {{'chargeback.eFile.contextMenu.historyLog' | translate}}
      </a>
      </li>
    </ul>
    <ul class="custom-table__row-menu__content" *ngIf="deletedActiveContextMenu" #deletedActiveContextMenu>
      <li [ngClass]="[ params.data.lines == 0 ? 'disabledOption' : '']">
        <a href="javascript:void(0)" (click)="downloadEfile($event, contextmenu)">
          <i class="far fa-arrow-alt-circle-down"></i>
          {{'chargeback.eFile.contextMenu.downloadEfile' | translate}}
      </a>
      </li>
      <li>
        <a href="javascript:void(0)" (click)="efileHistoryPopUp($event, contextmenu)">
          <i class="fa fa-history"></i>
          {{'chargeback.eFile.contextMenu.historyLog' | translate}}
      </a>
      </li>
    </ul>
  </span>
</p-overlayPanel>


<p-toast key="efileDownload" position="center" [style]="{ marginTop : '100px', width: '400px' }" [closable]="true" [baseZIndex]="500" [autoZIndex]="100" [hideTransitionOptions]="'250ms'">
    <ng-template let-message pTemplate="message">
        <div style="text-align: center">
            <h3>{{message.summary | translate}}</h3>
            <p>{{message.detail | translate}}</p>
        </div>
    </ng-template>
</p-toast>