import { Component, Input, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { HttpService } from '../../Services/http.service';

@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss']
})
export class SearchResultComponent implements OnInit {

  resultsLoaded: boolean = false;
  categoriesLoaded: boolean = false;
  allProductsURL:string;
  private _resultList: any;
  private _categoryList: any;
  
  @Input() resultCount:any;

  @Input() set searchText(value: string) {
    this.allProductsURL = this.httpServ.getRegionSpecificURL(environment.allProductsLink) + '?q=' + value;
  }

  @Input() set categoryResults(value: string) {    
    this._categoryList = value;
    this.validateCategories(this._categoryList);
  }

  @Input() set searchResult(value: string) {
    this._resultList = value;
    this.validateResults(this._resultList);
  }

  get categoryResults(): string {
    return this._categoryList;
  }

  get searchResult(): string {
    return this._resultList;
  }

  constructor(private httpServ: HttpService) { }

  ngOnInit() {
  }

  validateCategories(_resultList: any) {
    if (this.categoryResults != undefined) {
      this.categoriesLoaded = this._categoryList.length > 0;
    }
  }

  validateResults(_resultList: any) {
    if (this.searchResult != undefined) {
      this.resultsLoaded = this._resultList.length > 0;
    }
  }
}
