/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./text-translation.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./text-translation.component";
import * as i3 from "@ngx-translate/core";
var styles_TextTranslationComponent = [i0.styles];
var RenderType_TextTranslationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TextTranslationComponent, data: {} });
export { RenderType_TextTranslationComponent as RenderType_TextTranslationComponent };
export function View_TextTranslationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.params; _ck(_v, 0, 0, currVal_0); }); }
export function View_TextTranslationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "text-translation", [], null, null, null, View_TextTranslationComponent_0, RenderType_TextTranslationComponent)), i1.ɵdid(1, 180224, null, 0, i2.TextTranslationComponent, [i3.TranslateService], null, null)], null, null); }
var TextTranslationComponentNgFactory = i1.ɵccf("text-translation", i2.TextTranslationComponent, View_TextTranslationComponent_Host_0, {}, {}, []);
export { TextTranslationComponentNgFactory as TextTranslationComponentNgFactory };
