<span class="custom-table__popup custom-table__favorite ellipsisWrap">
  <span class="custom-table__customer custom-table__popup__trigger ellipsis"
    (mouseenter)="showCustomerInfoTooltip($event, op)" (mouseleave)="hideCustomerInfoTooltip(op)">
    {{ params.value }}
</span>
</span>
<p-overlayPanel appendTo="body" [hideTransitionOptions]="'0ms'" [showTransitionOptions]="'500ms'" #op>
    <span class="custom-table__popup__info">
    <p *ngFor="let category of categoryList">{{ category.label | translate }}</p>
  </span>
</p-overlayPanel>