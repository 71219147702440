import { Component } from '@angular/core';
import { CBMultipleClaimNumber, CBMultipleClaimNumberFilter, EfileKcfileMultipleRefNumber, EfileKcfileMultipleRefNumberFilter } from '@app/Modules/Shared/DataModels/SharedDataModel';
import { AgRendererComponent } from 'ag-grid-angular';

@Component({
  selector: 'claimnumber-multiple-hyperlink',
  templateUrl: './claimnumber-multiple-hyperlink.component.html',
  styleUrls: ['./claimnumber-multiple-hyperlink.component.scss']
})
export class ClaimNumberMultipleHyperlink implements AgRendererComponent {

  constructor() { }
  params: any;
  multipleClaimNumberRequest: any;
  showModal: boolean = false;
  
  agInit(params: any): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    return false;
  }

  onLinkClicked(){
    let request: CBMultipleClaimNumber = new CBMultipleClaimNumber();
    request.query = new Object();
    if(this.params && this.params.data){
      request.query.couponNumber = this.params.data.couponNumber;
      request.query.companyCode = this.params.data.companyCode;
      request.query.fiscalYear = this.params.data.fiscalYear;
      request.query.salesOrgId = this.params.data.salesOrgId;
    }
    request.filter = new CBMultipleClaimNumberFilter();
    this.multipleClaimNumberRequest = request;
    this.showModal = true;
  }

  closedCustModal(event) {
    this.showModal = (event.close === 'true');
  }


}
