import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FooterComponent } from './Components/footer/footer.component';
import { HeaderComponent } from './Components/header/header.component';
import { HamburgerComponent } from './Components/hamburger/hamburger.component';
import { DropdownModule } from 'primeng/dropdown';
import { SidebarModule } from 'primeng/sidebar';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { InputSwitchModule } from 'primeng/inputswitch';
import { LogoutComponent } from './Components/logout/logout.component';
import { SelectRegionComponent } from './Components/select-region/select-region.component';
import { AuthInterceptorService } from './Services/auth-interceptor.service';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ErrorComponent } from './Components/error/error.component';
import { ToastModule } from 'primeng/toast';
import { TreeModule } from 'primeng/tree';
import { LocationLanguageSelectorComponent } from './Components/location-language-selector/location-language-selector.component';
import { ContactInfoComponent } from './Components/contact-info/contact-info.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgFileUploadContolComponent } from './Components/file-upload/ng-file-upload-contol/ng-file-upload-contol.component';
import { NgFileUploadAreaComponent } from './Components/file-upload/ng-file-upload-area/ng-file-upload-area.component';
import { NgFileStagedItemComponent } from './Components/file-upload/ng-file-staged-item/ng-file-staged-item.component';
import { DownloadallComponent } from './Components/downloadall/downloadall.component';
import { AcceptancepolicyComponent } from './Components/acceptancepolicy/acceptancepolicy.component';
import { FinalLogoutComponent } from './Components/final-logout/final-logout.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { AgGridModule } from 'ag-grid-angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { SubnavComponent } from './Components/subnav/subnav.component';
import { RouterModule } from '@angular/router';
import { CopyToClipboardDirective } from './directives/copy-to-clipboard.directive';
import { CBPricingTermsComponent } from './Components/cb-pricing-terms/cb-pricing-terms.component';
import { NumbersOnlyDirective } from './directives/number-only-input.directive';
import {TooltipModule} from 'primeng/tooltip';
import { SearchResultComponent } from './Components/search-result/search-result.component';


import { DownloadLinkPopupComponent } from '@app/Modules/Shared/Components/download-link-popup/download-link-popup.component';

import { ModalModule } from 'ngb-modal';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ApacPricingTearmsConditionsComponent } from './Components/apac-pricing-tearms-conditions/apac-pricing-tearms-conditions.component';
import { APACPricingTermsPageComponent } from './Components/apac-pricing-terms-page/apac-pricing-terms-page.component';


export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/app-modules/shared/', '.json');
}

@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    HamburgerComponent,
    LogoutComponent,
    FinalLogoutComponent,
    SelectRegionComponent,
    ErrorComponent,
    LocationLanguageSelectorComponent,
    ContactInfoComponent,
    NgFileUploadContolComponent,
    NgFileUploadAreaComponent,
    NgFileStagedItemComponent,
    DownloadallComponent,
    AcceptancepolicyComponent,
    SubnavComponent,
    CopyToClipboardDirective,
    CBPricingTermsComponent,
    NumbersOnlyDirective,
    SearchResultComponent,
    DownloadLinkPopupComponent,
    ApacPricingTearmsConditionsComponent,
    APACPricingTermsPageComponent
  ],
  imports: [
    FormsModule,
    DropdownModule,
    SidebarModule,
    ButtonModule,
    DialogModule,
    InputSwitchModule,
    HttpClientModule,
    AgGridModule,
    CommonModule,
    NgSelectModule,
    ReactiveFormsModule,
    OverlayPanelModule,
    TooltipModule,
    ModalModule,
    NgbModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    ToastModule,
    TreeModule,
    RouterModule
  ],
  exports: [
    FormsModule,
    FooterComponent,
    HeaderComponent,
    HamburgerComponent,
    LogoutComponent,
    FinalLogoutComponent,
    SelectRegionComponent,
    AcceptancepolicyComponent,
    DropdownModule,
    SidebarModule,
    ButtonModule,
    DialogModule,
    InputSwitchModule,
    TranslateModule,
    CommonModule,
    HttpClientModule,
    ToastModule,
    OverlayPanelModule,
    TreeModule,
    ContactInfoComponent,
    LocationLanguageSelectorComponent,
    ReactiveFormsModule,
    NgFileUploadContolComponent,
    DownloadallComponent,
    NgSelectModule,
    SubnavComponent,
    CopyToClipboardDirective,
    NumbersOnlyDirective,
    TooltipModule,
    ApacPricingTearmsConditionsComponent
  ],
  entryComponents: [DownloadLinkPopupComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
    //AppService
  ],
})
export class SharedModule { }
