

class RegionConf {
  imagename: string;
  regionCode: string;
}
export class AppRegions {
  label: string;
  country: string;
  value: RegionConf;
}

export class PropConf {
  label: string;
  value: string;
}

export class InfoCenter {
  id: string;
  label: string;
  icon: string;
}

export class InfoCenterControl {
  id: string;
  display: boolean;
}

export class ProductSrBrowse {
  Category_ID_Encrypt: string;
  Category_Desc: string;
  Category_ID: string;
}

export class MenuItem {
  id: string;
  label: string;
  enabled: boolean;
  disabled: boolean;
  hasChild: boolean;
  expanded: false;
  uri: string;
  menu: [];
}

export class Menu {
  menu: MenuItem[];
}

export class KCPConnections {
  CP_Business_Area: string;
  CP_Dist_Ann_ID: string;
  CP_RecipientID: string;
  CP_EffectiveDate: string;
  CP_Title: string;
  RowIndex: number;
}

export class ManageErrorRequest {
  pageSize: Number = 100;
  currentPage: Number = 1;
  query: ManageErrorRequestQuery = new ManageErrorRequestQuery();
}

export class ManageErrorRequestQuery {
  claimFileId: string = "";
  salesOrgId: number = 0;
}

export class PASearch {
  pageSize: Number = 100;
  currentPage: Number = 1;
  orderBy: Array<string> = [""];
  query: PASearchQuery = new PASearchQuery();
  filter: PASearchQuery = new PASearchQuery();
  exportView: string = "";
}
export class PASearchQuery {
  contractIds: Array<string> = [""];
  legacyPANumber: Array<string> = [""];
  endUserIds: Array<string> = [""];
  salesOrgId: string = "";
  customerIds: Array<string> = [""];
  validDate: FromToDate = new FromToDate();
  firmValidDate: FromToDate = new FromToDate();
  lastChanged: FromToDate = new FromToDate();
  productIds: Array<string> = [""];
  territoryIds: Array<string> = [""];
  territoryName: string = "";
  territoryRegion: string = "";
  display: string = null;
  status: Array<string> = [""];
  contractName: string = "";
  contractType: Array<string> = [""];
  salesorgName: Array<string> = [""];
  salesorgDescription: Array<string> = [""];
  groupIds: Array<string> = [""];
  isGPOGroupIncluded: boolean = true;
  isMeetCompIncluded: boolean = false;
  searchView: string = "";
  includeEndUser: string = "";
  changeOptions: number = 0;
  changeDate: string = "";
  customerName: string = "";
  dashboardTilesType: number = null;
  dataDurationType: number = 0;
  regionId: string = "";
  exportView: string = "";
  customerComments: number = 0;
  lastDownloaded: string = "";
  taxNumber: string = '';
  ownerID: string = "";
  ownerName: string = "";
  filterOwnerName: string = "";
  kcOwner: Array<string> = [""];
  includeExpiryItem: boolean = false;
}
export class invoicePriceSearch {
  pageSize: Number = 100;
  currentPage: Number = 1;
  orderBy: Array<string> = [""];
  query: invoicePriceSearchQuery = new invoicePriceSearchQuery();
  filter: invoicePriceSearchQuery = new invoicePriceSearchQuery();
  exportView: string = "";
}
export class invoicePriceSearchQuery {
  contractIds: Array<string> = [""];
  LegacyPANumber: Array<string> = [""];
  endUserIds: Array<string> = [""];
  salesOrgId: string = "";
  customerIds: Array<string> = [""];
  validDate: FromToDate = new FromToDate();
  firmValidDate: FromToDate = new FromToDate();
  lastChanged: FromToDate = new FromToDate();
  productIds: Array<string> = [""];
  territoryIds: Array<string> = [""];
  territoryName: string = "";
  territoryRegion: string = "";
  display: string = null;
  status: Array<string> = [""];
  contractName: string = "";
  contractType: Array<string> = [""];
  salesorgName: Array<string> = [""];
  salesorgDescription: Array<string> = [""];
  groupIds: Array<string> = [""];
  isGPOGroupIncluded: boolean = true;
  isMeetCompIncluded: boolean = false;
  searchView: string = "";
  includeEndUser: string = "";
  changeOptions: number = 0;
  changeDate: string = "";
  customerName: string = "";
  dashboardTilesType: number = null;
  dataDurationType: number = 0;
  regionId: string = "";
  exportView: string = "";
  customerComments: number = 0;
  lastDownloaded: string = "";
  taxNumber: string = '';
  ownerID: string = "";
  ownerName: string = "";
  filterOwnerName: string = "";
  kcOwner: Array<string> = [""];
  includeExpiryItem: boolean = false;
}
export class PASaveSearch {
  searchDate: string = "";
  isFavorite: boolean = false;
  favoriteDate: string = "";
  name: string = "";
  recentRearchItemId: Number = 0;
  id: Number = 0;
  pageSize: Number = 100;
  currentPage: Number = 1;
  orderBy: Array<string> = [""];
  query: PASaveSearchQuery = new PASaveSearchQuery();
}

export class PASaveSearchQuery {
  contractIds: Array<string> = [""];
  endUserIds: Array<string> = [""];
  customerIds: Array<string> = [""];
  validDate: FromToDate = new FromToDate();
  lastChanged: FromToDate = new FromToDate();
  productIds: Array<string> = [""];
  territoryIds: Array<string> = [""];
  territoryName: string = "";
  territoryRegion: string = "";
  display: string = null;
  status: Array<string> = null;
  contractName: string = "";
  contractType: Array<string> = [""];
  groupIds: Array<string> = [""];
  isGPOGroupIncluded: boolean = true;
  isMeetCompIncluded: boolean = false;
  searchView: string = "";
  includeEndUser: string = "";
  changeOptions: number = 0;
  changeDate: string = "";
  regionId: string = "";
  customerName: string = "";
  exportView: string = "";
  taxNumber: string = '';
  ownerID: string = "";
  filterOwnerName: string = "";
}

export class recentDownload {
  pageSize: Number = 100;
  currentPage: Number = 1;
  orderBy: Array<string> = [""];
  filter: recentDownloadQuery = new recentDownloadQuery();
}
export class recentDownloadQuery {
  fileName: string = "";
  customerNumber: string = "";
  customerName: string = "";
  size: number;
  status: string = "";
  availableUntil: string = "";
  lastDownloadedDate: string = "";
}

export class PADetailsProductGridRequest {
  pageSize: Number;
  currentPage: Number = 1;
  orderBy: Array<string> = [""];
  query: PAProductQuery = new PAProductQuery();
  filter: PAProductQuery = new PAProductQuery();
}

export class PAProductQuery {
  contractNumber: string = "";
  dataDurationType: number = 0;
  customerNumber: string = "";
  includeExpiryItem: boolean = true;
  lastChangedFrom: string = "";
  lastChangedTo: string = "";
  daysToDisplay: number = 0;
  outputColumns: Array<string> = [""];
  productCode: string = "";
  productIds: Array<string> = [""];
  description: string = "";
  price: string = "";
  startDate: string = "";
  endDate: string = "";
  status: Array<string> = [""];
  comments: Array<string> = [""];
  uom: string = "";
  itemsPerCase: string = "";
  casePrice: string = "";
  lastChanged: string = "";
  itemsPerUom: string = "";
  alternateProductCode: string = "";
  twelveMonthSales: string = "";
  twelveMonthVolume: string = "";
  searchView: string = "";
  filterView: string = "";
  regionId: string = "";
  shortProductCode: string = "";
  futureProduct: boolean = true;
  ContractProductCombination: ContractProductCombination = new ContractProductCombination();
  dontConsiderDaysToDisplay: boolean = true;
  considerFutureItem: boolean = true;
  downloadFilter: downloadFilter = new downloadFilter();
  contractDaysToDisplay: ContractDaysToDisplay = new ContractDaysToDisplay();
  paStatus: number = 0;
  caseBreakDownUom: string = "";
  caseBreakDown: string = "";
  euPrice: string = '';
  por: number = null;
  firmEndDate: string = '';
  volume: string = '';
  display: number = null;
}

export class ContractProductCombination {
  contractNumber: string = "";
  shortProductCode: string = "";
}

export class ContractDaysToDisplay {
  contractNumber: string = "";
  daysToDisplay: number;
  dashboardType: number;
  days: number;
  recentlyExpiredSinceDay: number;
  expringSoonTillDay: number;
}

export class CustomerSoldTo {
  pageSize: number = 100;
  currentPage: number = 1;
  orderBy: Array<string> = ["customerName asc"];
  query: SoldToQuery = new SoldToQuery();
  filter: SoldToQuery = new SoldToQuery();
  exportView: string = ""
}

export class CustomerSoldToDropdown {
  pageSize: number = 1000;
  currentPage: number = 1;
  orderBy: Array<string> = ["customerName asc"];
  query: SoldToQuery = new SoldToQuery();
  filter: SoldToQuery = new SoldToQuery();
  exportView: string = ""
}

export class SoldToQuery {
  contractNumber: string = "";
  customerNumber: string = "";
  customerName: string = "";
  address: string = "";
  startDate: string = "";
  endDate: string = "";
  comments: Array<string> = [""];
}

export class PADetailsEndUserGridRequest {
  pageSize: Number = 10;
  currentPage: Number = 1;
  orderBy: Array<string> = [""];
  query: PAEndUserQuery = new PAEndUserQuery();
  filter: PAEndUserQuery = new PAEndUserQuery();
  exportView: string = "";
}

export class PAEndUserQuery {
  dataDurationType: number = 0;
  parentGroupFlag: string = "";
  endUserNumber: string = "";
  contractNumber: string = "";
  customerNumber: string = "";
  groupNumber: string = "";
  groupNumbers: Array<string> = [""];
  startDate: string = "";
  endDate: string = "";
  lastChangedFrom: string = "";
  lastChangedTo: string = "";
  startDateFrom: string = "";
  startDateTo: string = "";
  endDateFrom: string = "";
  endDateTo: string = "";
  fullName: string = "";
  comments: Array<string> = [""];
  contractStartDate: string = "";
  contractEndDate: string = "";
  exclusionList: Array<string> = [""];
  searchView: string = "";
  filterView: string = "";
  includeExpiryItem: boolean = true;
  address: string = "";
  isDirectEndUser: string = "";
  downloadFilter: downloadFilter = new downloadFilter();
  paStatus: number = 0;
  display: number = 0;
  firmEndDate: string = '';
}
class downloadFilter {
  downloadPAStatus: Array<string> = [""];
  downloadOptions: number = 0;
  downloadOptionsDate: string = "";
}
class FromToDate {
  fromDate: string = "";
  toDate: string = "";
}

export class DashboardTilesPARequest {
  pageSize: Number;
  currentPage: Number = 1;
  orderBy: Array<string> = [""];
  // query: any = new Object();
  query: PASearchQuery = new PASearchQuery();
  filter: PASearchQuery = new PASearchQuery();
}

export class activeFutureRequest {
  pageSize: Number;
  currentPage: Number = 1;
  orderBy: Array<string> = [""];
  query: any = new Object();
}

export class recentDownloadRequest {
  // pageSize: Number;
  // currentPage: Number = 1;
  // orderBy: Array<string> = [''];
  // query: any = new Object();
}

export class CustomerInfomation {
  Direct_Customer_Ind: string;
  Postal_Code: string;
  Attri_8: string;
  Customer_Number: string;
  Phone_Number: string;
  Tax_Jurisdiction_Code: string;
  Customer_Name_3: string;
  Sub_Region: string;
  street3: string;
  City_1: string;
  City: string;
  Customer_CO_Name: string;
  State: string;
  County: string;
  street2: string;
  Customer_Name_2: string;
  Email: string;
  KC_Id: string;
  District_1: string;
  Cust_Hier_Lvl: string;
  Customer_Name_1: string;
  isGPO: string;
  street4: string;
  Postal_City: string;
  Customer_Name_4: string;
  IsMasterRedistributor: string;
  Customer_Type: string;
  street5: string;
  Customer_Account_Group: string;
  BackOrderFlag: string;
  Customer_classification: string;
  POBox_No: string;
  Fax: string;
  District: string;
  street1: string;
  ChangInd: string;
  Update_Date: string;
  Region: string;
  GLN: string;
  Zip_Code: string;
  Country: string;
  Insert_Date: string;
  Delete_Indicator: string;
}

export class OktaProfile {
  approles: Array<string>;
  email: string;
  email_verified: boolean;
  family_name: string;
  given_name: string;
  locale: string;
  name: string;
  preferred_username: string;
  sAMAccountName: string;
  sub: string;
  updated_at: number;
  userType: string;
  zoneinfo: string;
  phone: number;
  appregions: Array<any>;
}

export class UserPrefrence {
  userId: string;
  isActive: boolean;
  lastUpdatedDate: string;
  isInternalUser: boolean;
  userName: string;
  lastLogin: string;
  previousLogin: string;
  regionId: string;
  salesOrg: number;
  defaultLanguage: string;
  country: string;
  preferredCountry: string;
  preferredRegion: string;
  preferredSalesOrg: number;
  preferredLanguage: string;
}

export class Searches {
  Title: string;
  End_User: string;
  Last_Changed: string;
  Type: string;
  Last_Updated: string;
  Saved: string;
  Liked: boolean;
}

export class DashBoardQuickTiles {
  Title: string;
  URL: string;
  Numberic_Details: string;
  Description: string;
  QuestionMark: boolean;
  Tooltip: string;
}

export class DashBoardTilesCount {
  activeCount: string = "0";
  recentlyChangedCount: string = "0";
  expiringSoonCount: string = "0";
  recentlyExpiredCount: string = "0";
  endUserCount: string = "0";
  paFavoritesCount: string = "0";
}

export interface PriceAgreement {
  PA: string;
  PAName: string;
  Customer: string;
  CustomerName: string;
  Type: string;
  status: string;
}

export interface Message {
  CP_Bussiness_Area: string;
  CP_ExpiryDate: string;
  CP_FilePath: string;
  CP_ImageURL: string;
  CP_Isdefault: number;
  CP_NewsMessageBody: string;
  CP_NewsMessageID: string;
  CP_StartDate: string;
  CP_Title: string;
  CP_URL: string;
  CP_Update_Date: string;
  RowIndex: number;
}

export interface Announcement {
  AnnouncementMsgFlag: number;
  CP_Body: string;
  CP_Business_Area: string;
  CP_ExpiryDate: string;
  CP_FilePath: string;
  CP_ImageURL: string;
  CP_MsgAnnID: string;
  CP_StartDate: string;
  CP_Title: string;
  CP_URL: string;
  CP_Update_Date: string;
}

export class TradeOrgRequest {
  pageSize: Number;
  currentPage: Number = 1;
  orderBy: Array<string> = [""];
  query: TradeOrgQuery = new TradeOrgQuery();
  filter: TradeOrgQuery = new TradeOrgQuery();
  exportView: string = "";
}

export class TradeOrgQuery {
  dataDurationType: number = 0;
  contractNumber: string = "";
  customerNumber: string = "";
  groupNumber: string = "";
  fullName: string = "";
  startDate: string = "";
  endDate: string = "";
  searchView: string = '';
  filterView: string = '';
  address: string = "";
  comments: Array<Number> = [];
  paStatus: number = 0;
}

export class TradeOrgEndUser {
  pageSize: Number;
  currentPage: Number = 1;
  orderBy: Array<string> = [""];
  query: TradeOrgEndUserRequest = new TradeOrgEndUserRequest();
  filter: TradeOrgEndUserRequest = new TradeOrgEndUserRequest();
  exportView: string = "";
}

export class TradeOrgEndUserRequest {
  contractNumber: string = "";
  customerNumber: string = "";
  groupNumber: string = "";
  searchView: string = '';
  filterView: string = '';
  parentNumber: string = '';
  paStatus: number = 0;
  sortColumn: string = "";
  sortOrder: string = "";
  address: string = "";
  startDate: string = "";
  endDate: string = "";
  endUserId: string = "";
  name: string = "";
  display: number = 0;
  firmEndDate: string = "";
}


export class ProductSearchRequest {
  query: ProductSearchQuery = new ProductSearchQuery();
  currentPage: number = 1;
  pageSize: number = 100;
  orderBy: Array<string> = [""];
  filter: ProductSearchFilter = new ProductSearchFilter();
}

export class ProductSearchQuery {
  salesOrg: string = "2811";
  searchText: string = "";
  searchTerms: Array<number> = [0];
}
export class ProductSearchFilter {
  salesOrg: number = 0;
  shortProductCode: string = "";
  altProductCode: string = "";
  productDescription: string = "";
  categoryDescription: string = "";
}

export class DownloadRequest {
  isDownloadAll: boolean;
  selectedTileType: number = null;
  priceAgreementSearchRequest: any;
  contractCustomers: Array<any> = [];
  selectedContractNumbers: Array<any>;
  selectedCustomerNumbers: Array<any>;
  paStatus: Array<any>;
  includeEndusers: boolean;
  dates: number;
  dateSince: string;
  priceIncreaseDate: string;
  fileType: Number;
  mailTo: string;
  isRecentlyChanged: boolean;
  emailType: number;
  searchView: string;
  SelectedSearchCount: number;
  downloadFilePrefix: string = null;
  IsTilesFilteredView: boolean = false;
  includeExpiryItem: boolean;
}

export class DownloadRequestCB {
  download: downloadCB = new downloadCB();
  currentPage: number = 1;
  pageSize: number = 100;
  orderBy: Array<string> = [""];
  query: any;
  filter: any;
}

export class downloadCB {
  downloadType: number;
  fileType: number;
  couponNumbers: Array<couponNumberDetails>;
  isDownloadAll: boolean;
}

export class couponNumberDetails {
  couponNumber: string;
  companyCode: string;
  fiscalYear: string;
  salesOrgId: number;
}

export class DownloadRequestSignalRCB {
  Id: string;
  userId: string;
  regionId: string;
  isInternalUser: boolean;
  language: string;
  languageLocaleCode: string;
  isGlobalUser: boolean;
  accessToken: string;
  userEmailId: string;
  cNs: Array<couponNumberDetailsSignalR>;
  dwldOrigin: number;
  isDwldAll: boolean;
  cbSearchQuery: any;
  cbSearchFilter: any;
  dashboardSearch: any;
  orderBy: Array<string> = [""];
  DwldTyp: number;
  CountryCode: String;
}

export class DownloadRequestSignalRRebate {
  UserName: string;
  IntegratedUserid: string;
  Token: string;
  Region: string;
  requestObj: any;
  ModuleType: string;
  Reconnect: boolean;
  Languagecode: string;
}

export class couponNumberDetailsSignalR {
  cn: string;
  cc: string;
  fy: string;
  so: number;
}









export class DownloadRequestCBEUDetail {
  download: downloadEUDetailsCB = new downloadEUDetailsCB();
  currentPage: number = 1;
  pageSize: number = 100;
  orderBy: Array<string> = [""];
  query: any;
  filter: any;
}

export class downloadEUDetailsCB {
  downloadType: number;
  fileType: number;
  isDownloadAll: boolean;
  euNumbers: Array<EUNumber>;
}

export class EUNumber {
  couponNumber: string;
  companyCode: string;
  fiscalYear: string;
  salesOrgId: number;
  euNumber: string;
  kceuNumber: string;
  kceuDetails: string;
}















export class DownloadPADetailsRequest {
  contractNumber: string;
  customerNumber: string;
  searchView: number;
  paStatus: number;
  fileType: number;
  country: string;
  salesOrg: Array<any>;
  display: number;
  filterType: number;
  paProductFilter: any;
  paEnduserFilter: any;
  paGroupFilter: any;
  paProductOrderBy: any;
  paEnduserOrderBy: any;
  paGroupOrderBy: any;
  version: number;
  userName: string;
  mailTo: string;
  emailType: number;
}

export class EndUserRequests {
  pageSize: Number;
  currentPage: Number = 1;
  orderBy: Array<string> = [""];
  filter: EndUserRequestsQuery = new EndUserRequestsQuery();
}

export class EndUser {
  id: string;
  companyName: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zipCode: string;
  businessEmail: string;
  businessPhone: string;
  reasonForNewEndUser: string;
  distEuNumber: string;
  estimatedUnitCount: string;
  requestedUserName: string;
}

export class EndUserRequestsQuery {
  contractNumber: string = null;
  customerNumber: string = null;
  euNumber: string = null;
  companyName: string = null;
  requestedDate: Date = null;
  requestedDateFrom: Date = null;
  requestedDateTo: Date = null;
  status: Array<string> = [];
  comments: string = null;
  contractName: string = null;
  customerName: string = null;
  address: string = null;
  estimatedUnitCount: string = null;
}

export class ValidateAddressRequest {
  AccountId: string = "";
  ContactId: string = "";
  First_Name: string = "";
  Last_Name: string = "";
  Company: string;
  Address: string;
  City: string;
  State: string;
  PostalCode: string;
  Phone: string;
  Country: string;
  RecordType: string;
  ObjectType: string;
}

export class EndUserSubmitRequest {
  contractNumber: string;
  customerNumber: string;
  contractName: string = null;
  customerName: string = null;
  endUserInputList: Array<EndUser>;
  contractSubType: string = null;
  salesOrg: number = 0;
  paStatus: number = 0;
}

export interface UserNote {
  contractNumber: string;
  customerNumber: string;
  id: string;
  isInternalUser: boolean;
  noteDate: string;
  notes: string;
  userName: string;
}

export class endusersearchModal {
  salesOrg: string = "2811";
  pageSize: number = 100;
  filter: endusersearchFilter = new endusersearchFilter();
  currentPage: number = 1;
  orderBy: Array<string> = [""];
  query: endusersearchQuery = new endusersearchQuery();
}

export class endusersearchQuery {
  endUserName: string = "";
  address: string = "";
  city: string = "";
  state: string = "";
  postalCode: string = "";
  endUserNumber: string = "";
  phone: string = "";
  ForDisplayEndUserName: boolean = false;
  location: Array<any> = [];
  taxNumber: string = "";
  country: string = "";
}

export class endusersearchFilter {
  endUserName: string = "";
  address: string = "";
  city: string = "";
  state: string = "";
  postalCode: string = "";
  endUserNumber: string = "";
  phone: string = "";
  ForDisplayEndUserName: boolean = false;
  location: Array<any> = [];
  taxNumber: string = "";
  country: Array<any> = [];
}

export class customersearchModal {
  query: CustomerSearchQuery = new CustomerSearchQuery();
  currentPage: number = 1;
  salesOrg: number = 2811;
  pageSize: number = 100;
  orderBy: Array<string> = [""];
  filter: CustomerSearchFilter = new CustomerSearchFilter();
}
export class CustomerSearchQuery {
  customerName: string = "";
  customerNumber: string = "";
  city: string = "";
  state: string = "";
  postalCode: string = "";
  customerType: string = "";
  country: string = "";
  tax: string = "";
}
export class CustomerSearchFilter {
  customerName: string = "";
  customerNumber: string = "";
  city: string = "";
  state: string = "";
  postalCode: string = "";
  customerType: Array<string> = [];
  country: Array<string> = [];
  tax: string = "";
}

export class hQModalRequest {
  salesOrg: string = "2811";
  currentPage: number = 1;
  pageSize: number = 100;
  orderBy: Array<string> = [""];
  query: hqModalQuery = new hqModalQuery();
  filter: hqModalFilter = new hqModalFilter();
}

export class hqModalQuery {
  tradeOrgName: string = "";
  tradeOrgNumber: string = "";
  address: string = "";
  city: string = "";
  state: string = "";
  postalCode: string = "";
  country: string = "";
}

export class hqModalFilter {
  tradeOrgName: string = "";
  tradeOrgNumber: string = "";
  address: string = "";
  city: string = "";
  state: string = "";
  postalCode: string = "";
  country: Array<any> = [];
}

export class MyNotificationRequest {
  pageSize: Number = 100;
  currentPage: Number = 1;
  orderBy: Array<string> = [""];
  query: MyNotificationQuery = new MyNotificationQuery();
  filter: MyNotificationQuery = new MyNotificationQuery();
  exportView: string = "";
}

export class GetCustomerInfo {
  pageSize: Number = 100;
  currentPage: Number = 1;
  orderBy: Array<string> = [""];
  query: GetCustomerInfoQuery = new GetCustomerInfoQuery();
  filter: GetCustomerInfoFilter = new GetCustomerInfoFilter();
  exportView: string = "";
}

export class GetCustomerInfoQuery {
  customerNumber: Array<string> = [""];
}
export class GetCustomerInfoFilter {
  customerNumber: string = "";
  name: string = "";
  address: string = "";
}
export class MyNotificationQuery {
  contractNumber: string = "";
  customerNumber: string = "";
  changeReason: string = "";
  updatedDate: string = null;
  contractName: string = "";
  customerName: string = "";
  updatedLineItemNumber: string = "";
  lastChanged: FromToDate = new FromToDate();
  PAOwnerName: string = "";
}

export class SearchModalData {
  message: string;
  value: string;
}

export class RegisterRequest {
  registrationId: number;
  firstName: string;
  lastName: string;
  jobTitle: string;
  email: string;
  telephone: string;
  extension: string;
  mobileNumber: number;
  distributorName: string;
  distributorNumber: string;
  distributorAddressLine1: string;
  distributorAddressLine2: string;
  distributorTelephone: string;
  distributorExt: string;
  distributorCity: string;
  distributorState: string;
  distributorZipCode: string;
  distributorCountryId: string;
  distributorCountry: string;
  type: string;
  countryId: string;
  regionId: string;
  salesOrg: number;
  languageId: string;
  locationList: Array<DistributorLocation> = new Array<DistributorLocation>();
  accessManagement: Array<AccessManagement> = new Array<AccessManagement>();
  captchaToken: string;
}

export class DistributorLocation {
  distributorName: string;
  address: string;
  city: string;
  state: string;
  countryID: string;
  country: string;
  zipCode: string
}

export class AccessManagement {
  featureId: number;
  featureName: string
}

export class KCPContent {
  regionId: string = "";
  salesOrgId: number;
  countryId: string = "";
  languageCode: string = "";
  contentTypes: Array<ContentType> = new Array<ContentType>();
  contentSubTypes: Array<ContentSubType> = new Array<ContentSubType>();
}
export class ContentType {
  contentType: string = "";
}
export class ContentSubType {
  contentSubType: string = "";
}

export class saveUpdateUserProfile {
  country: string = "";
  salesOrg: 0;
  language: string = "";
  userName: string = "";
}

export class UserSearch {
  pageSize: number;
  currentPage: number;
  orderBy: Array<string> = [''];
  filter: UserSearchFilter = new UserSearchFilter();
  query: UserSearchQuery = new UserSearchQuery();
}

export class PortalUsageInternalSearch {
  pageSize: number;
  currentPage: number;
  orderBy: Array<string> = [''];
  filter: PuSearchFilter = new PuSearchFilter();
  query: PuSearchQuery = new PuSearchQuery();
}

export class PuSearchFilter {
  firstName: string = "";
  lastName: string = "";
  email: string = "";
  userId: string = "";
  lastLoginDate: string = null;
  passwordExpiryDate: string = null;
  loginCount: number = 0;
}

export class PuSearchQuery {
  userId: string;
  customerNumber: [];
  customerName: string;
  firstName: string;
  lastName: string;
  email: string;
  lastLoginFrom: string;
  lastLoginTo: string
}

export class DelegatedUserSearch {
  pageSize: number;
  currentPage: number;
  orderBy: Array<string> = [''];
  filter: DelegatedUserSearchFilter = new DelegatedUserSearchFilter();
  query: DelegatedUserSearchQuery = new DelegatedUserSearchQuery();
}

export class UserSearchQuery {
  userId: string;
  customerNumber: string;
  customerName: string;
  firstName: string;
  lastName: string;
  groupAdministration: boolean;
}

export class DelegatedUserSearchQuery {
  firstName: string;
  customerName: string;
  userId: string;
  lastName: string;
  customerNumber: Array<string>;
  email: string;
}

export class DelegatedUserSearchFilter {
  firstName: string = '';
  customerName: string = '';
  userId: string = '';
  lastName: string = '';
  customerNumber: Array<string> = [''];
  email: string = '';
}

export class UserSearchFilter {
  userId: string = '';
  firstName: string = '';
  lastName: string = '';
  email: string = '';
}

export class UsersAssociatedRequest {
  pageSize: number;
  currentPage: number;
  orderBy: Array<string> = [''];
  filter: UserSearchFilter = new UserSearchFilter();
  query: UserSearchQuery = new UserSearchQuery();
}

export class UcMappingsCustomerSearch {
  pageSize: number;
  currentPage: number;
  orderBy: Array<string> = [''];
  filter: UcMappingCustomerSearchFilter = new UcMappingCustomerSearchFilter();
  query: UcMappingCustomerSearchQuery = new UcMappingCustomerSearchQuery();
}

export class UcMappingCustomerSearchQuery {
  customerName: string;
  customerNumber: string;
  address: string;
  city: string;
  state: string;
  postalCode: string;
  customerType: string;
  country: string;
  tax: string;
}

export class UcMappingCustomerSearchFilter {
  customerName: string = '';
  customerNumber: string = '';
  address: string = '';
  city: string = '';
  state: string = '';
  postalCode: string = '';
  customerType: Array<string> = [];
  country: Array<string> = [];
  tax: string = '';
}

export class SaveUcMappingsRequest {
  userId: string;
  groupCode: string;
  isGroupAdmin: boolean;
  isNonAssociatedUser: boolean;
  customerNumber: string;
  parentCustomer: string;
  salesOrg: 0;
  regionId: string;
  mappedCustomers: Array<UcMapping>;
  isChangeGroup: boolean
}

export class RemoveUcMappingsRequest {
  userId: string;
  groupCode: string;
  isGroupAdmin: boolean;
  isNonAssociatedUser: boolean;
  salesOrg: 0;
  regionId: string;
  removeCustomers: Array<UcMapping>;
}

export class UcMapping {
  parentCustomerNumber: string;
  childCustomerNumber: string;
  parentCustomer: string;
  customerNumber: string;
  salesOrg: number = 0;
}

export class DownloadCustomerRequest {
  pageSize: Number = 100;
  currentPage: Number = 1;
  orderBy: Array<string> = [""];
  query: any
  filter: DownloadCustomerFilter = new DownloadCustomerFilter();
}

export class DownloadCustomerFilter {
  customerNumber: string = "";
  customerName: string = "";
  contractNumber: string = "";
}

export class ContactUsRequest {
  customerNumber: string;
  customerCompany: string;
  customerCountry: string;
  customerAddress: string;
  userContact: string;
  userPhoneNumber: string;
  userCountry: string;
  contactBy: string;
  comments: string;
}

export class PardotRequest {
  userFirstName: string;
  lastName: string;
  emailAddress: string;
  defaultCustomerName: string;
  customerCountry: string
}


/* ChargeBack Search Related */

export class CBSearch {
  pageSize: Number = 100;
  currentPage: Number = 1;
  orderBy: Array<string> = [""];
  query: CBSearchQuery = new CBSearchQuery();
  filter: CBSearchFilter = new CBSearchFilter();
  exportView: string = "";
}
export class CBSearchQuery {
  couponNumbers: Array<string> = [""];
  referenceNumbers: Array<string> = [""];
  status: Array<string> = [""];
  customerNumber: Array<string> = [""];
  claimNumbers: Array<string> = [""];
  errors: string = "";
  issuePeriod: FromToDate = new FromToDate();
  submittedPeriod: FromToDate = new FromToDate();
  claimPeriod: FromToDate = new FromToDate();
  requestType: Array<string> = [""];
  reverseBillBack: Array<string> = [""];
  currency: string = "";
  claimPeriodMonthAndYear: Array<any> = [];
  claimFileID: string = "";
}
export class CBSearchFilter {
  couponNumber: string = "";
  referenceNumber: string = "";
  status: Array<string> = [""];
  customerNumber: Array<string> = [""];
  claimNo: string = "";
  errors: string = "";
  issuePeriod: FromToDate = new FromToDate();
  submittedPeriod: FromToDate = new FromToDate();
  claimPeriod: FromToDate = new FromToDate();
  requestType: Array<string> = [""];
  reverseBillBack: Array<string> = [""];
  currency: Array<string> = [""];
  claimPeriodMonthAndYear: Array<string> = [];
}

export class MCSearch {
  pageSize: Number = 100;
  currentPage: Number = 1;
  orderBy: Array<string> = [""];
  query: MCSearchQuery = new MCSearchQuery();
  filter: MCSearchFilter = new MCSearchFilter();
  exportView: string = "";
}
export class MCSearchQuery {
  meetCompNumber: Array<string> = [""];
  customerNumber: Array<string> = [""];
  status: Array<string> = [""];
  salesTerritoryNumber: Array<string> = [""];
  product: Array<string> = [""];
  display: Number = 1;
  meetCompName: string = "";
  salesTerritoryName: string = "";
  lastDownloaded: string = "";
  salesOrgId: string = "";
  territoryRegion: string = "";
  lastChanged: FromToDate = new FromToDate();
  active: FromToDate = new FromToDate();
}
export class MCSearchFilter {
  meetCompNumber: Array<string> = [""];
  customerNumber: Array<string> = [""];
  status: Array<string> = [""];
  salesTerritoryNumber: Array<string> = [""];
  product: Array<string> = [""];
  display: Number = 1;
  meetCompName: string = "";
  salesTerritoryName: string = "";
  lastDownloaded: string = "";
  salesOrgId: string = "";
  territoryRegion: string = "";
  lastChanged: FromToDate = new FromToDate();
  active: FromToDate = new FromToDate();
}

export class getReports {
  pageSize: Number = 100;
  currentPage: Number = 1;
  orderBy: Array<string> = [""];
  query: getReportsQuery = new getReportsQuery();
  filter: getReportsFilter = new getReportsFilter();
}
export class getReportsQuery {
  regionId: string = "";
  languageId: string = "";
  reportType: string = "";
}
export class getReportsFilter {
  regionId: string = "";
  languageId: string = "";
  reportType: string = "";
}


export class CBSaveSearch {
  searchDate: string = "";
  isFavorite: boolean = false;
  favoriteDate: string = "";
  name: string = "";
  recentRearchItemId: Number = 0;
  id: Number = 0;
  pageSize: Number = 100;
  currentPage: Number = 1;
  orderBy: Array<string> = [""];
  query: CBSaveSearchQuery = new CBSaveSearchQuery();
}
export class CBSaveSearchQuery {
  couponNumbers: Array<string> = [""];
  referenceNumbers: Array<string> = [""];
  status: Array<string> = [""];
  customerNumber: Array<string> = [""];
  claimNumbers: Array<string> = [""];
  errors: string = "";
  issuePeriod: FromToDate = new FromToDate();
  claimPeriod: FromToDate = new FromToDate();
  submittedPeriod: FromToDate = new FromToDate();
  requestType: Array<string> = [""];
  reverseBillBack: Array<string> = [""];
  currency: string = "";
  searchView: string = "";
  claimPeriodMonthAndYear: Array<any> = [];
  claimFileID: string = "";
}


export class CBCouponSummaryResult {
  pageSize: Number = 100;
  currentPage: Number = 1;
  orderBy: Array<string> = [""];
  query: CBCouponSummaryResultQuery = new CBCouponSummaryResultQuery();
  filter: CBCouponSummaryResultFilter = new CBCouponSummaryResultFilter();
  download: CBCouponSummaryResultDownload = new CBCouponSummaryResultDownload();
}

export class CBCouponSummaryResultQuery {
  couponNumber: string = "";
  redirectFrom: string = "";
  companyCode: string = "";
  fiscalYear: string = "";
  salesOrgId: number = 0;
}


export class CBCouponSummaryResultFilter {
  custEU: Array<string> = [""];
  kCEU: string = "";
  kCEUDetails: string = "";
  requested: string = "";
  issued: string = "";
  lines: string = "";
  discrepancies: Array<string> = [""];
}

export class CBCouponSummaryResultDownload {
  download: number = 1;
  fileType: number = 1;
  couponNumbers: Array<CBCouponSummaryResultDownloadCouponNumbers> = [new CBCouponSummaryResultDownloadCouponNumbers()];
  downloadAll: boolean = true;
}

export class CBCouponSummaryResultDownloadCouponNumbers {
  claimNumber: string = "";
  companyCode: string = "";
  fiscalYear: string = "";
  salesOrgId: number = 0;
}

export class CBCouponEndUserResult {
  customerEUNumber: string = "";
  couponNumber: string = "";
  companyCode: string = "";
  fiscalYear: string = "";
  salesOrgId: number = 0;
  redirectFrom: string = "";
  customerEuDetail: string = "";
  kceuNumber: string = "";
  kceuDetails: string = "";
}

export class CBEfileErrorSummaryResult {
  pageSize: Number = 100;
  currentPage: Number = 1;
  orderBy: Array<string> = [""];
  query: CBEfileErrorSummaryResultQuery = new CBEfileErrorSummaryResultQuery();
  filter: CBEfileErrorSummaryResultFilter = new CBEfileErrorSummaryResultFilter();
  download: CBEfileErrorSummaryResultDownload = new CBEfileErrorSummaryResultDownload();
}

export class CBEfileErrorSummaryResultQuery {
  claimFileId: string = "";
  customerNumber: string = "";
  salesOrgId: number = 0;
}

export class CBEfileErrorSummaryResultFilter {
  row: string = "";
  field: string = "";
  error: string = "";
  fieldName: string = "";
}

export class CBEfileErrorSummaryResultDownload {
  download: number = 1;
  fileType: number = 1;
  couponNumbers: Array<CBEfileErrorSummaryResultDownloadCouponNumbers> = [new CBEfileErrorSummaryResultDownloadCouponNumbers()];
  downloadAll: boolean = true;
}

export class CBEfileErrorSummaryResultDownloadCouponNumbers {
  claimNumber: string = "";
  companyCode: string = "";
  fiscalYear: string = "";
  salesOrgId: number = 0;
}
/* -----------------------------------------------------------*/

//Added for chargeback

export class DashBoardTilesCountCB {
  recentActivityCount: string = "0";
  issuedCount: string = "0";
  deniedCount: string = "0";
  pendingCount: string = "0";
  unclaimedCount: string = "0";
}

export class CBTilesSearch {
  pageSize: Number = 100;
  currentPage: Number = 1;
  orderBy: Array<string> = [""];
  query: CBTilesSearchQuery = new CBTilesSearchQuery();
  filter: CBTilesFilter = new CBTilesFilter();
  exportView: string = "";
}

export class CBTilesSearchQuery {
  selectedTileType: number;
  pastDays: number = 0;
  selectedPeriod: number = 0;
}

export class CBTilesFilter {
  couponNumber: string = "";
  referenceNumber: string = "";
  status: Array<string> = [""];
  customerNumber: Array<string> = [""];
  errors: string = "";
  issuePeriod: FromToDate = new FromToDate();
  claimPeriod: FromToDate = new FromToDate();
  submittedPeriod: FromToDate = new FromToDate();
  requestType: Array<string> = [""];
  reverseBillBack: Array<string> = [""];
  currency: Array<string> = [""];
  received: string = "";
  available: string = "";
  claimPeriodMonthAndYear: Array<string> = [];
}

export class CBEfileSearch {
  pageSize: Number = 100;
  currentPage: Number = 1;
  orderBy: Array<string> = [""];
  query: CBEfileSearchQuery = new CBEfileSearchQuery();
  filter: CBEfileSearchFilter = new CBEfileSearchFilter();
  download: CBSearchDownload = new CBSearchDownload();
}
export class CBEfileSearchQuery {
  uploaded: FromToDate = new FromToDate();
  uploadedBy: string = "";
  customerNumber: Array<string> = [""];
  eFileID: string = "";
  eFileName: string = "";
  referenceNumber: string = "";
  status: Array<string> = [""];
  type: Array<string> = [""];
}
export class CBEfileSearchFilter {
  uploaded: string = "";
  uploadedBy: string = "";
  eFileID: string = "";
  eFileName: string = "";
  referenceNumber: string = "";
  lastChanged: string = "";
  submitted: string = "";
  lines: number = 0;
  errors: number = -1;
  customerNumber: Array<string> = [""];
  customerName: Array<string> = [""];
  status: Array<string> = [""];
  type: Array<string> = [""];
}

export class CBSearchDownload {
  downloadType: number = 1;
  fileType: number = 1;
  couponNumbers: Array<CBSearchDownloadCouponNumbers> = [new CBSearchDownloadCouponNumbers()];
  isDownloadAll: boolean = true;
}

export class CBSearchDownloadCouponNumbers {
  couponNumber: string = "";
  companyCode: string = "";
  fiscalYear: string = "";
  salesOrgId: number = 0;
}

export class CBDownloadEfile {
  claimFileId: number = 0;
  salesOrgId: number = 0;
}

export class EfileHistoryLog {
  claimFileId: string = "0";
  salesOrgId: number = 0;
}

export class CBUpdateEfileStatus {
  claimFileId: string = "";
  salesOrgId: string = "";
  isDeleted: boolean = true;
  eFileStatus: string = "";
}

export class CBGetDiscrepancies {
  couponNumber: string = "";
  companyCode: string = "";
  fiscalYear: string = "";
  salesOrgId: number = 0;
  redirectFrom: string = "";
}


//Added for chargeback---end

//Added For MeetComp

export class MCCustomerDetails {
  pageSize: Number = 100;
  currentPage: Number = 1;
  orderBy: Array<string> = [""];
  query: MCCustomerDetailsQuery = new MCCustomerDetailsQuery();
  filter: MCCustomerDetailsFilter = new MCCustomerDetailsFilter();
  exportView: string = "";
}


export class MCCustomerDetailsQuery {
  mcNumber: string = "";
  customerNumber: string = "";
  address: string = "";
  city: string = "";
  zipcode: string = "";
  state: Array<string> = [""];
  customerNumberCBFilter: Array<string> = [""];
}

export class MCCustomerDetailsFilter {
  mcNumber: string = "";
  customerNumber: string = "";
  customerName: string = "";
  address: string = "";
  city: string = "";
  zipcode: string = "";
  state: Array<string> = [""];
  customerNumberCBFilter: Array<string> = [""];
  orderBy: Array<string> = [""];
}




export class MCProductsDetails {
  pageSize: Number = 100;
  currentPage: Number = 1;
  orderBy: Array<string> = [""];
  query: MCProductsDetailsQuery = new MCProductsDetailsQuery();
  filter: MCProductsDetailsFilter = new MCProductsDetailsFilter();
  exportView: string = "";
}


export class MCProductsDetailsQuery {
  mcNumber: string = "";
  customerNumber: string = "";
  includeExpiryItem: boolean = true;
  lastChangedFrom: string = "";
  lastChangedTo: string = "";
  productCode: string = "";
  description: string = "";
  price: any = "";
  startDate: string = "";
  endDate: string = "";
  status: Array<string> = [""];
  comments: Array<string> = [""];
  uom: string = "";
  lastChanged: string = "";
  itemsPerUom: string = "";
  alternateProductCode: string = "";
  itemsPerCase: string = "";
  casePrice: string = "";
  filterView: string = "";
  regionId: string = "";
  shortProductCode: string = "";
  dashboardType: number = 0;
  mcStatus: number = 0;
  caseBreakDownUom: string = "";
  caseBreakDown: string = "";
}

export class MCProductsDetailsFilter {
  mcNumber: string = "";
  customerNumber: string = "";
  includeExpiryItem: boolean = true;
  lastChangedFrom: string = "";
  lastChangedTo: string = "";
  productCode: string = "";
  description: string = "";
  price: any = "";
  startDate: string = "";
  endDate: string = "";
  status: Array<string> = [""];
  comments: Array<string> = [""];
  uom: string = "";
  lastChanged: string = "";
  itemsPerUom: string = "";
  alternateProductCode: string = "";
  itemsPerCase: string = "";
  casePrice: string = "";
  filterView: string = "";
  regionId: string = "";
  shortProductCode: string = "";
  dashboardType: number = 0;
  mcStatus: number = 0;
  caseBreakDownUom: string = "";
  caseBreakDown: string = "";
  orderBy: Array<string> = [""];
}

export class DownloadMCDetailsRequest {
  filterType: number = 1;
  fileType: number = 3;
  detailsRequest: MCDetailsRequest = new MCDetailsRequest();
  productsFilter: MCProductsDetailsFilter = new MCProductsDetailsFilter();
  customerFilter: MCCustomerDetailsFilter = new MCCustomerDetailsFilter();
}
export class MCDetailsRequest {
  mcNumber: string = "";
  customerNumber: string = "";
  mcStatus: number = 0;
}

export class DownloadRequestMC {
  meetCompNoList: Array<meetCompNoList>;
  isDownloadAll: boolean;
  fileType: number = 1;
  downloadOptions: number;
  downloadOptionsDate: string;
  meetCompSearchRequest: meetCompSearchRequest = new meetCompSearchRequest();
  downloadFilePrefix: string = null;
}

export class meetCompNoList {
  mcNumber: string = "";
  customerNumber: string = "";
  mcStatus: number = 0;
  lastDownloadDate: string = "";
}

export class meetCompSearchRequest {
  pageSize: 0;
  currentPage: 0;
  orderBy: Array<string> = [""];
  query: MCSearchQuery = new MCSearchQuery();
  filter: MCSearchFilter = new MCSearchFilter();
  exportView: string = '';
}

//Added for MeetComp---end


export class EfileKcfileAccessSearch {
  pageSize: number = 10;
  currentPage: number = 1;
  orderBy: Array<string> = [""];
  query: EfileKcfileAccessSearchQuery = new EfileKcfileAccessSearchQuery();
  filter: EfileKcfileAccessSearchFilter = new EfileKcfileAccessSearchFilter();
}
export class EfileKcfileAccessSearchQuery {
  customerNumber: string = "";
  customerName: string = "";
}
export class EfileKcfileAccessSearchFilter {
  customerNumber: string = "";
  customerName: string = "";
  access: string = "";
}

export class EfileKcfileMultipleCustomers {
  pageSize: number = 10;
  currentPage: number = 1;
  orderBy: Array<string> = [""];
  query: any;
  filter: EfileKcfileMultipleCustomersFilter = new EfileKcfileMultipleCustomersFilter();
}
export class EfileKcfileMultipleCustomersFilter {
  customerNumber: string = "";
  customerName: string = "";
}

export class EfileKcfileMultipleRefNumber {
  pageSize: number = 100;
  currentPage: number = 1;
  orderBy: Array<string> = [""];
  query: any;
  filter: EfileKcfileMultipleRefNumberFilter = new EfileKcfileMultipleRefNumberFilter();
}
export class EfileKcfileMultipleRefNumberFilter {
  claimFileId: string = "";
  referenceNumber: string = "";
}

export class CBMultipleClaimNumber {
  pageSize: number = 100;
  currentPage: number = 1;
  orderBy: Array<string> = [""];
  query: any;
  filter: CBMultipleClaimNumberFilter = new CBMultipleClaimNumberFilter();
}
export class CBMultipleClaimNumberFilter {
  claimNumber: string = "";
}

export class UpdateEfileKcfileAccess {
  updateAccessRequestItems: Array<UpdateEfileKcfileAccessElement> = [new UpdateEfileKcfileAccessElement()];
}
export class UpdateEfileKcfileAccessElement {
  customerNumber: string = "";
  access: boolean = true;
}

//Added for Invoice
export class InvoiceCustomerSearch {
  pageSize: Number = 100;
  currentPage: Number = 1;
  orderBy: Array<string> = [""];
  query: InvoiceCustomerSearchQuery = new InvoiceCustomerSearchQuery();
  filter: InvoiceCustomerSearchFilter = new InvoiceCustomerSearchFilter();
}
export class InvoiceCustomerSearchQuery {
  customerNumber: string = "";
  customerName: string = "";
  myFavoritesOnly: Boolean = false;
  address: string = "";
  city: string = "";
  country: Array<string> = [];
  state: Array<string> = [];
  postalCode: string = "";
  taxNumber: string = "";
}

export class InvoiceCustomerSearchFilter {
  customerNumber: string = "";
  customerName: string = "";
  address: string = "";
  city: string = "";
  country: Array<string> = [];
  state: Array<string> = [];
  postalCode: string = "";
  lastRefresh: string = "";
  nextAvailable: string = "";
  refreshStatus: Array<number> = [];
  categoryList: Array<string> = [];
  requestedDateFrom: string = "";
  requestedDateTo: string = "";
  taxNumber: string = "";
}

export class InvoiceSaveSearch {
  searchDate: string = "";
  isFavorite: boolean = false;
  favoriteDate: string = "";
  name: string = "";
  recentRearchItemId: Number = 0;
  id: Number = 0;
  pageSize: Number = 100;
  currentPage: Number = 1;
  orderBy: Array<string> = [""];
  query: InvoiceSaveSearchQuery = new InvoiceSaveSearchQuery();
}

export class InvoiceSaveSearchQuery {
  customerNumber: string = "";
  customerName: string = "";
  address: string = "";
  city: string = "";
  state: string = "";
  country: string = "";
  postalCode: string = "";
  myFavoritesOnly: Boolean = false;
}

export class UnclaimedTileSearch {
  pageSize: Number = 100;
  currentPage: Number = 1;
  orderBy: Array<string> = [""];
  query: UnclaimedTileQuery = new UnclaimedTileQuery();
  filter: UnclaimedTileFilter = new UnclaimedTileFilter();
  download: DownloadUnclaimedTile = new DownloadUnclaimedTile();
}

export class UnclaimedTileQuery {
  selectedTileType: number = 0;
  pastDays: number = 0;
  selectedPeriod: number = 0;
}

export class UnclaimedTileFilter {
  customerNumber: string = '';
  unclaimedMonth: Array<string> = [""];
  customerName: string = '';
  address: string = '';
  city: string = '';
  postalCode: string = '';
  country: Array<string> = [""];
}

export class DownloadUnclaimedTile {
  downloadType: number = 0;
  fileType: number = 0;
  couponNumbers: Array<DownloadUnclaimedTileCouponNumbers> = [];
  isDownloadAll: boolean = false;
}

export class DownloadUnclaimedTileCouponNumbers {
  couponNumber: string = '';
  companyCode: string = '';
  fiscalYear: string = '';
  salesOrgId: number = 0;
}

//------------- Added For KC- FILES----------------- //
export class CBKcfileSearch {
  pageSize: Number = 100;
  currentPage: Number = 1;
  orderBy: Array<string> = [""];
  query: CBKcfileSearchQuery = new CBKcfileSearchQuery();
  filter: CBKcfileSearchFilter = new CBKcfileSearchFilter();
  download: CBSearchDownload = new CBKcSearchDownload();
}
export class CBKcfileSearchQuery {
  uploaded: FromToDate = new FromToDate();
  uploadedBy: string = "";
  customerNumber: Array<string> = [""];
  kcFileID: string = "";
  kcFileName: string = "";
  status: Array<string> = [""];
  referenceNumber: string = "";
  claimPeriod: FromToDate = new FromToDate();
}
export class CBKcfileSearchFilter {
  uploaded: string = "";
  uploadedBy: string = "";
  kcFileID: string = "";
  kcFileName: string = "";
  lastChanged: string = "";
  submitted: string = "";
  lines: number = 0;
  errors: number = -1;
  customerNumber: Array<string> = [""];
  customerName: Array<string> = [""];
  status: Array<string> = [""];
  claimPeriodMonthAndYear: Array<string> = [];
  claimPeriod: FromToDate = new FromToDate();
}

export class CBKcSearchDownload {
  downloadType: number = 1;
  fileType: number = 1;
  couponNumbers: Array<CBKcSearchDownloadCouponNumbers> = [new CBKcSearchDownloadCouponNumbers()];
  isDownloadAll: boolean = true;
}

export class CBKcSearchDownloadCouponNumbers {
  couponNumber: string = "";
  companyCode: string = "";
  fiscalYear: string = "";
  salesOrgId: number = 0;
}


export class CBKcfileErrorSummaryResult {
  pageSize: Number = 100;
  currentPage: Number = 1;
  orderBy: Array<string> = [""];
  query: CBEfileErrorSummaryResultQuery = new CBKcfileErrorSummaryResultQuery();
  filter: CBEfileErrorSummaryResultFilter = new CBKcfileErrorSummaryResultFilter();
  download: CBEfileErrorSummaryResultDownload = new CBKcfileErrorSummaryResultDownload();
}
export class CBKcfileErrorSummaryResultQuery {
  claimFileId: string = "";
  customerNumber: string = "";
  salesOrgId: number = 0;
}

export class CBKcfileErrorSummaryResultFilter {
  row: string = "";
  field: string = "";
  error: string = "";
  fieldName: string = "";
}

export class CBKcfileErrorSummaryResultDownload {
  download: number = 1;
  fileType: number = 1;
  couponNumbers: Array<CBKcfileErrorSummaryResultDownloadCouponNumbers> = [new CBKcfileErrorSummaryResultDownloadCouponNumbers()];
  downloadAll: boolean = true;
}

export class CBKcfileErrorSummaryResultDownloadCouponNumbers {
  claimNumber: string = "";
  companyCode: string = "";
  fiscalYear: string = "";
  salesOrgId: number = 0;
}

export class KcfileHistoryLog {
  claimFileId: string = "0";
  salesOrgId: number = 0;
}

export class KcfilePASearch {
  pageSize: Number = 100;
  currentPage: Number = 1;
  orderBy: Array<string> = [""];
  query: KcfilePASearchQuery = new KcfilePASearchQuery();
  filter: KcfilePASearchQuery = new KcfilePASearchQuery();

}
export class KcfilePASearchQuery {
  contractIds: Array<string> = [""];
  endUserIds: Array<string> = [""];
  salesOrgId: string = "";
  customerIds: Array<string> = [""];
  validDate: FromToDate = new FromToDate();
  display: number = 1;
  status: Array<string> = [""];
  contractName: string = "";
  includeEndUser: string = "";
  customerName: string = "";
  regionId: string = "";
  legacyPANumber: Array<string> = [""];
  firmValidDate: FromToDate = new FromToDate();
  claimPeriod: FromToDate = new FromToDate();
  filterActiveLines: number = null;
}

export class KcfileDownload {
  paList: Array<PAListNumbers> = [new PAListNumbers()];
  refNo: string = "";
  salesOrg: Array<number> = [];
}
export class PAListNumbers {
  paNumber: string = "";
  customerNmber: string = "";
  status: number = 0;
}

export class KcfilePASearchQueryLAO {
  endUserIds: Array<string> = [""];
  customerIds: Array<string> = [""];
  salesOrg: Array<number> = [];
  monthToDownload: number = 1;
  allCustomers: Boolean = true;
  allEndUsers: Boolean = true;
  refNo: string = "";
}

//----------------  KC-FILES End  -------------------//