import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { GlobalAppService } from './global-app.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./global-app.service";
var HttpSiteadminService = /** @class */ (function () {
    function HttpSiteadminService(http, _globalAppService) {
        this.http = http;
        this._globalAppService = _globalAppService;
    }
    HttpSiteadminService.prototype.getReports = function (request) {
        return this.makeHTTPCall(environment.getReports.method, this.getRegionSpecificURL(environment.getReports), null, request);
    };
    HttpSiteadminService.prototype.makeReportDownloadCall = function (request, reportId) {
        var apiUrl;
        switch (reportId) {
            case 1:
            case 2:
            case 8:
            case 10:
            case 17:
                //apiUrl = environment.getEmailNotificationReports;
                //apiUrl = environment.getUserMappingListReports;
                //apiUrl = environment.getViewPAcomment;
                //apiUrl = environment.getExpectedVolumeReport;        
                apiUrl = environment.getReportGeneric;
                break;
            case 3:
                apiUrl = environment.getCustomerPriceHistory;
                break;
            case 4:
                apiUrl = environment.getEfileCGBCompany;
                break;
            case 5:
                apiUrl = environment.getExpectedDateToPay;
                break;
            case 6:
                apiUrl = environment.getCustomerHistory;
                break;
            case 7:
                apiUrl = environment.getDaysToDisplayList;
                break;
            case 9:
                apiUrl = environment.getCBAcceptancePolicyList;
                break;
        }
        return this.makeHTTPCall(apiUrl.method, this.getRegionSpecificURL(apiUrl), null, request);
    };
    HttpSiteadminService.prototype.removeUcmappings = function (request) {
        return this.makeHTTPCall(environment.removeUcMappings.method, this.getRegionSpecificURL(environment.removeUcMappings), null, request);
    };
    HttpSiteadminService.prototype.saveUcMappings = function (request) {
        return this.makeHTTPCall(environment.addUcMappings.method, this.getRegionSpecificURL(environment.addUcMappings), null, request);
    };
    HttpSiteadminService.prototype.triggerUCMSync = function (request) {
        return this.makeHTTPCall(environment.UCMSync.method, this.getRegionSpecificURL(environment.UCMSync), null, request);
    };
    HttpSiteadminService.prototype.getStates = function (request) {
        return this.makeHTTPCall(environment.getStates.method, this.getRegionSpecificURL(environment.getStates), null, request);
    };
    HttpSiteadminService.prototype.getUcMappings = function (request) {
        return this.makeHTTPCall(environment.ucMappings.method, this.getRegionSpecificURL(environment.ucMappings), null, request);
    };
    HttpSiteadminService.prototype.markAsAdmin = function (request) {
        return this.makeHTTPCall(environment.markAsAdmin.method, this.getRegionSpecificURL(environment.markAsAdmin), null, request);
    };
    HttpSiteadminService.prototype.getCustomerHierarchy = function (request) {
        return this.makeHTTPCall(environment.customerMappings.method, this.getRegionSpecificURL(environment.customerMappings), null, request);
    };
    HttpSiteadminService.prototype.exportPortalUsage = function (request) {
        return this.makeHTTPCall(environment.exportPortalUsageInternal.method, this.getRegionSpecificURL(environment.exportPortalUsageInternal), null, request);
    };
    HttpSiteadminService.prototype.exportPortalUsageExternal = function (request) {
        return this.makeHTTPCall(environment.exportPortalUsageExternal.method, this.getRegionSpecificURL(environment.exportPortalUsageExternal), null, request);
    };
    HttpSiteadminService.prototype.getAccessManagementList = function (request) {
        var httpHeaders = new HttpHeaders({ 'Accept-Language': request.languageCode });
        delete request.languageCode;
        return this.makeHTTPCall(environment.getAccessManagementList.method, this.getRegionSpecificURL(environment.getAccessManagementList), httpHeaders, request);
    };
    HttpSiteadminService.prototype.manageUserFeatures = function (request) {
        return this.makeHTTPCall(environment.getAccessManagementList.method, this.getRegionSpecificURL(environment.getAccessManagementList), null, request);
    };
    HttpSiteadminService.prototype.manageUsersSubmit = function (request) {
        return this.makeHTTPCall(environment.manageUsersSubmit.method, this.getRegionSpecificURL(environment.manageUsersSubmit), null, request);
    };
    HttpSiteadminService.prototype.makeHTTPCall = function (method, endPoint, additionalHeaders, requestParams) {
        if (additionalHeaders === void 0) { additionalHeaders = null; }
        if (requestParams === void 0) { requestParams = null; }
        var requestBody = {};
        if (additionalHeaders) {
            requestBody = Object.assign({}, requestBody, { headers: additionalHeaders });
        }
        if (requestParams) {
            requestBody = Object.assign({}, requestBody, { body: requestParams });
        }
        return this.http.request(method, endPoint, requestBody);
    };
    HttpSiteadminService.prototype.getRegionSpecificURL = function (item) {
        var regionURL = "";
        var region = localStorage.getItem('default-region') || this._globalAppService.getDefaultRegionString() || this._globalAppService.selectedRegion;
        if (region) {
            regionURL = "url_" + region.toUpperCase();
        }
        else {
            regionURL = "url_LAO";
        }
        return item[regionURL];
    };
    HttpSiteadminService.prototype.validateCustomerGroupMapping = function (request) {
        return this.makeHTTPCall(environment.validateCustomerGroupMapping.method, this.getRegionSpecificURL(environment.validateCustomerGroupMapping), null, request);
    };
    HttpSiteadminService.ngInjectableDef = i0.defineInjectable({ factory: function HttpSiteadminService_Factory() { return new HttpSiteadminService(i0.inject(i1.HttpClient), i0.inject(i2.GlobalAppService)); }, token: HttpSiteadminService, providedIn: "root" });
    return HttpSiteadminService;
}());
export { HttpSiteadminService };
