/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./footer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./footer.component";
import * as i5 from "../../Services/language-selection.service";
import * as i6 from "../../Services/http.service";
import * as i7 from "../../Services/global-app.service";
import * as i8 from "../../Services/app.service";
var styles_FooterComponent = [i0.styles];
var RenderType_FooterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FooterComponent, data: {} });
export { RenderType_FooterComponent as RenderType_FooterComponent };
function View_FooterComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [["style", " display: inline-block;"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpod(1, { legalStatement: 0, privacyPolicy: 1 }), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 2).transform("footer.copyrightFull", _ck(_v, 1, 0, _co.legalStatement, _co.privacyPolicy))); _ck(_v, 0, 0, currVal_0); }); }
function View_FooterComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [["style", " display: inline-block;"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpod(1, { legalDisclaimers: 0, privacyPolicy: 1 }), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 2).transform("footer.copyrightFull", _ck(_v, 1, 0, _co.legalDisclaimers, _co.privacyPolicy))); _ck(_v, 0, 0, currVal_0); }); }
function View_FooterComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterComponent_2)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterComponent_3)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.region == "LAO") || (_co.region == "EMEA")) || (_co.region == "APAC")); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.region == "NA"); _ck(_v, 4, 0, currVal_1); }, null); }
function View_FooterComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [["style", "display: inline-block;"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpod(2, { legalStatement: 0, privacyPolicy: 1 }), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, ["\u00A0 "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 3).transform("footer.copyrightFull", _ck(_v, 2, 0, _co.legalStatement, _co.privacyPolicy))); _ck(_v, 1, 0, currVal_0); }); }
function View_FooterComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [["style", "display: inline-block;"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpod(1, { legalDisclaimers: 0, privacyPolicy: 1 }), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 2).transform("footer.copyrightFull", _ck(_v, 1, 0, _co.legalDisclaimers, _co.privacyPolicy))); _ck(_v, 0, 0, currVal_0); }); }
function View_FooterComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [["style", " display: inline-block;"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpod(1, { legalStatement: 0, privacyPolicy: 1 }), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 2).transform("footer.copyrightFull", _ck(_v, 1, 0, _co.legalStatement, _co.privacyPolicy))); _ck(_v, 0, 0, currVal_0); }); }
function View_FooterComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterComponent_7)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterComponent_8)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.region != "EMEA") && (_co.region != "APAC")); _ck(_v, 1, 0, currVal_0); var currVal_1 = ((_co.region == "EMEA") || (_co.region == "APAC")); _ck(_v, 3, 0, currVal_1); }, null); }
function View_FooterComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterComponent_5)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["noCbFooter", 2]], null, 0, null, View_FooterComponent_6))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.region == "LAO"); var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_FooterComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { elementView: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 6, "footer", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterComponent_1)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["externalUserFooter", 2]], null, 0, null, View_FooterComponent_4))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isInternalUser; var currVal_1 = i1.ɵnov(_v, 7); _ck(_v, 6, 0, currVal_0, currVal_1); }, null); }
export function View_FooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-footer", [], null, null, null, View_FooterComponent_0, RenderType_FooterComponent)), i1.ɵdid(1, 245760, null, 0, i4.FooterComponent, [i5.LanguageSelectionService, i6.HttpService, i2.TranslateService, i7.GlobalAppService, i8.AppService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FooterComponentNgFactory = i1.ɵccf("app-footer", i4.FooterComponent, View_FooterComponent_Host_0, {}, {}, []);
export { FooterComponentNgFactory as FooterComponentNgFactory };
