import { Component, OnInit } from '@angular/core';
import { OktaAuthService } from '@okta/okta-angular';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LanguageSelectionService } from '../../Services/language-selection.service';
import { GlobalAppService } from '../../Services/global-app.service';
import { HttpService } from '../../Services/http.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
  isGlobalUser: any;
  region: any;
  lang: any;

  constructor(public _oktaAuth: OktaAuthService, private router: Router,
    private translateService: TranslateService,
    private httpService: HttpService,
    private languageServ: LanguageSelectionService,
    private _globalAppService: GlobalAppService) { }

  ngOnInit() {
    this._globalAppService.getDefaultRegion().subscribe(region => {
      if (region) {
        this.region = region;
        this._globalAppService.getDefaultLanguage().subscribe(lang => {
          if (region && lang) {
            this.lang = lang;
            this.translateService.setDefaultLang(this.region + '/' + this.languageServ.getLanguageCode(this.region, this.lang).toLowerCase());
            sessionStorage.setItem("KCC.region", this.region);
            sessionStorage.setItem("KCC.language", lang);
            /** Call Audit Log API For EMEA */
            if(this.region == 'EMEA'){
              this.httpService.makeAuditLogoutRequest().subscribe(
                ()=>{},
                (err: any)=>{
                  if(err.status == 401){
                    this.clearcookie();
                  }
                },
                ()=>{
                  this.clearcookie();
                }
              );
            } else {
              this.clearcookie();
            }
          }
        })
      }
    });
  }

  login() {
    this.router.navigateByUrl("/");
  }

  clearcookie() {
    let cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }

    if (localStorage) {
      localStorage.clear();
    }

    this._oktaAuth.logout('/finallogout');
  }

}
