import { formatDate } from '@angular/common';
import { HttpService } from '@app/Modules/Shared/Services/http.service';
import { BehaviorSubject, Observable, Subject, forkJoin } from 'rxjs';
import { TradeOrgEndUserRequest, PASearchQuery, PAEndUserQuery, PAProductQuery, EndUserRequestsQuery, MyNotificationQuery, ProductSearchFilter, TradeOrgEndUser, GetCustomerInfoFilter, SoldToQuery, CustomerSearchFilter, UserSearchFilter, UcMappingCustomerSearchFilter, DelegatedUserSearchFilter, DownloadCustomerFilter, PuSearchFilter, CBTilesFilter, CBSearchFilter, CBCouponSummaryResultFilter, CBEfileSearchFilter, CBEfileErrorSummaryResultFilter, MCCustomerDetailsFilter, MCProductsDetailsFilter, MCSearchFilter, endusersearchFilter, hqModalFilter, InvoiceCustomerSearchFilter, UnclaimedTileFilter, CBKcfileSearchFilter, CBKcfileErrorSummaryResultFilter, KcfilePASearchQuery, EfileKcfileMultipleRefNumberFilter, CBMultipleClaimNumberFilter } from '@app/Modules/Shared/DataModels/SharedDataModel';
import { kcpUpdates, customerComments, customerSoldToComments, configuredWidth } from '@app/conf/dataConfigurations';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { GlobalAppService } from '@app/Modules/Shared/Services/global-app.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpChargeBackService } from '@app/Modules/Shared/Services/http-charge-back.service';
import { HttpMeetCompService } from '@app/Modules/Shared/Services/http-meet-comp.service';
import { HttpInvoicePriceService } from '@app/Modules/Shared/Services/http-invoice-price.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EfileKcfileAccessSearchFilter } from './../../Shared/DataModels/SharedDataModel';
import { HttpInvoicePriceNewService } from '@app/Modules/Shared/Services/http-invoice-price-new.service';
import * as i0 from "@angular/core";
import * as i1 from "../../Shared/Services/http.service";
import * as i2 from "../../Shared/Services/global-app.service";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../../Shared/Services/app.service";
import * as i5 from "../../Shared/Services/http-charge-back.service";
import * as i6 from "../../Shared/Services/http-meet-comp.service";
import * as i7 from "../../Shared/Services/http-invoice-price.service";
import * as i8 from "../../Shared/Services/http-invoice-price-new.service";
import * as i9 from "@angular/router";
var CpAgGridService = /** @class */ (function () {
    function CpAgGridService(httpService, locale, _globalAppService, translateService, appService, httpServiceCB, httpServiceMC, httpServiceIP, httpServiceEA, httpServiceIPNew, router, activateRoute) {
        var _this = this;
        this.httpService = httpService;
        this.locale = locale;
        this._globalAppService = _globalAppService;
        this.translateService = translateService;
        this.appService = appService;
        this.httpServiceCB = httpServiceCB;
        this.httpServiceMC = httpServiceMC;
        this.httpServiceIP = httpServiceIP;
        this.httpServiceEA = httpServiceEA;
        this.httpServiceIPNew = httpServiceIPNew;
        this.router = router;
        this.activateRoute = activateRoute;
        this.isMultiSelect = false;
        this.multiSelectDistinctFilter = {};
        // Grid Attributes constants/readonly
        this.cacheOverflowSize = 0;
        this.maxConcurrentDataSourceRequests = 2;
        this.infiniteInitialRowCount = 0;
        this.maxBlocksInCache = 0;
        this.rowModelType = 'infinite';
        this.serverSideRowModelType = 'serverSide';
        this.rowSelection = 'multiple';
        this.gridName = '';
        // Subjects - Pls note to unsubscribe them from your component when not needed or on ngOnDestroy life cycle hook
        this.recordCount = new BehaviorSubject(undefined);
        this.recordCount$ = this.recordCount.asObservable();
        this.rowCheckboxSelection = new BehaviorSubject("SelectNone");
        this._selectedRows = [];
        this.menustate = new Subject();
        this.recentDownloadSubject = new Subject();
        this.menustate$ = this.menustate.asObservable();
        this.reloadGridSubject = new Subject();
        this.gridLoaded = new Subject();
        this.downloadMessageSubject = new Subject();
        this.addToPrefernceCheckBox = new Subject();
        this.myProfilePACheckBox = new Subject();
        this.myProfileMCCheckBox = new Subject();
        this.myProfileCBCheckBox = new Subject();
        this.myProfileINCheckBox = new Subject();
        this.myProfileODCheckBox = new Subject();
        this.dataContext = new Subject();
        this.stateList = new BehaviorSubject(null);
        this.couponSummaryData = new Subject();
        this.isLocationObsolete = new Subject();
        this.nodeAddToPrefSelectAll = new Subject();
        this.nodeMyProfilePASelectAll = new Subject();
        this.nodeMyProfileMCSelectAll = new Subject();
        this.nodeMyProfileCBSelectAll = new Subject();
        this.nodeMyProfileINSelectAll = new Subject();
        this.nodeMyProfileODSelectAll = new Subject();
        this.removeSelected = new Subject();
        this.changeGroup = new Subject();
        this.columnMultiselectFilter = new BehaviorSubject([]);
        // Configure the page sizes here
        this.serverPageSize = 100;
        this.clientPageSize = 10;
        this.tradeOrgMaxSize = 1000;
        this.translateService.get(['calender'])
            .subscribe(function (translations) {
            var calenderData = translations['calender'];
            _this.dateFormat = calenderData.gridDateFormat;
        });
    }
    CpAgGridService.prototype.getDataContext = function () {
        return this.dataContext.asObservable();
    };
    CpAgGridService.prototype.setRemoveSelected = function (status) {
        this.removeSelected.next(status);
    };
    CpAgGridService.prototype.getRemoveSelected = function () {
        return this.nodeMyProfilePASelectAll.asObservable();
    };
    CpAgGridService.prototype.setChangeGroup = function (status) {
        this.changeGroup.next(status);
    };
    CpAgGridService.prototype.getChangeGroup = function () {
        return this.changeGroup.asObservable();
    };
    CpAgGridService.prototype.setnodeMyProfilePASelectAll = function (status) {
        this.nodeMyProfilePASelectAll.next(status);
    };
    CpAgGridService.prototype.getnodeMyProfilePASelectAll = function () {
        return this.nodeMyProfilePASelectAll.asObservable();
    };
    CpAgGridService.prototype.setnodeMyProfileMCSelectAll = function (status) {
        this.nodeMyProfileMCSelectAll.next(status);
    };
    CpAgGridService.prototype.getnodeMyProfileMCSelectAll = function () {
        return this.nodeMyProfileMCSelectAll.asObservable();
    };
    CpAgGridService.prototype.setnodeMyProfileCBSelectAll = function (status) {
        this.nodeMyProfileCBSelectAll.next(status);
    };
    CpAgGridService.prototype.getnodeMyProfileCBSelectAll = function () {
        return this.nodeMyProfileCBSelectAll.asObservable();
    };
    CpAgGridService.prototype.setnodeMyProfileINSelectAll = function (status) {
        this.nodeMyProfileINSelectAll.next(status);
    };
    CpAgGridService.prototype.getnodeMyProfileINSelectAll = function () {
        return this.nodeMyProfileINSelectAll.asObservable();
    };
    CpAgGridService.prototype.setnodeMyProfileODSelectAll = function (status) {
        this.nodeMyProfileODSelectAll.next(status);
    };
    CpAgGridService.prototype.getnodeMyProfileODSelectAll = function () {
        return this.nodeMyProfileODSelectAll.asObservable();
    };
    CpAgGridService.prototype.setnodeAddToPrefSelectAll = function (status) {
        this.nodeAddToPrefSelectAll.next(status);
    };
    CpAgGridService.prototype.getnodeAddToPrefSelectAll = function () {
        return this.nodeAddToPrefSelectAll.asObservable();
    };
    CpAgGridService.prototype.setCheckBoxStatusAddToPrefSelectAll = function (status) {
        this.addToPrefernceCheckBox.next(status);
    };
    CpAgGridService.prototype.getCheckBoxStatusAddToPrefSelectAll = function () {
        return this.addToPrefernceCheckBox.asObservable();
    };
    CpAgGridService.prototype.setMyProfilePASelectAll = function (status) {
        this.myProfilePACheckBox.next(status);
    };
    CpAgGridService.prototype.getMyProfilePASelectAll = function () {
        return this.myProfilePACheckBox.asObservable();
    };
    CpAgGridService.prototype.setMyProfileMCSelecAll = function (status) {
        this.myProfileMCCheckBox.next(status);
    };
    CpAgGridService.prototype.getMyProfileMCSelectAll = function () {
        return this.myProfileMCCheckBox.asObservable();
    };
    CpAgGridService.prototype.setMyProfileCBSelecAll = function (status) {
        this.myProfileCBCheckBox.next(status);
    };
    CpAgGridService.prototype.getMyProfileCBSelectAll = function () {
        return this.myProfileCBCheckBox.asObservable();
    };
    CpAgGridService.prototype.setMyProfileINSelecAll = function (status) {
        this.myProfileINCheckBox.next(status);
    };
    CpAgGridService.prototype.getMyProfileINSelectAll = function () {
        return this.myProfileINCheckBox.asObservable();
    };
    CpAgGridService.prototype.setMyProfileODSelecAll = function (status) {
        this.myProfileODCheckBox.next(status);
    };
    CpAgGridService.prototype.getMyProfileODSelectAll = function () {
        return this.myProfileODCheckBox.asObservable();
    };
    CpAgGridService.prototype.setDownloadMessage = function (value) {
        this.downloadMessageSubject.next(value);
    };
    CpAgGridService.prototype.getDownloadMessage = function () {
        return this.downloadMessageSubject.asObservable();
    };
    CpAgGridService.prototype.setReloadGrid = function (value) {
        this.reloadGridSubject.next(true);
    };
    CpAgGridService.prototype.getReloadGrid = function () {
        return this.reloadGridSubject.asObservable();
    };
    CpAgGridService.prototype.setGridLoaded = function (value) {
        this.gridLoadedValue = value;
        return this.gridLoaded.next(value);
    };
    CpAgGridService.prototype.getGridLoaded = function () {
        return this.gridLoaded.asObservable();
    };
    CpAgGridService.prototype.setmenustate = function (value) {
        this.menustate.next(value);
    };
    CpAgGridService.prototype.setRecentDownloadPageReady = function (value) {
        this.recentDownloadSubject.next(value);
    };
    CpAgGridService.prototype.getRecentDownloadPageReady = function () {
        return this.recentDownloadSubject.asObservable();
    };
    CpAgGridService.prototype.getRowCheckboxSelection = function () {
        return this.rowCheckboxSelection;
    };
    CpAgGridService.prototype.setRowCheckboxSelection = function (status) {
        this.rowCheckboxSelection.next(status);
    };
    CpAgGridService.prototype.getCouponSummaryData = function () {
        return this.couponSummaryData.asObservable();
    };
    CpAgGridService.prototype.getLocationObseleteStatus = function () {
        return this.isLocationObsolete.asObservable();
    };
    CpAgGridService.prototype.setEFileErrorData = function (errorData, fileDetails) {
        var data = {
            "errorData": errorData,
            "fileDetails": fileDetails
        };
        this.eFileErrorData = Object.assign({}, data);
    };
    CpAgGridService.prototype.setKcFileErrorData = function (errorData, fileDetails) {
        var data = {
            "errorData": errorData,
            "fileDetails": fileDetails
        };
        this.kcFileErrorData = Object.assign({}, data);
    };
    CpAgGridService.prototype.getEFileErrorData = function () {
        return this.eFileErrorData;
    };
    CpAgGridService.prototype.getKcFileErrorData = function () {
        return this.kcFileErrorData;
    };
    Object.defineProperty(CpAgGridService.prototype, "gridRowModelType", {
        // Use this getter/setter only when you want to different the rowModel Type by default it would be infinite
        get: function () {
            return this._gridRowModelType;
        },
        set: function (rowModelType) {
            this._gridRowModelType = rowModelType;
        },
        enumerable: true,
        configurable: true
    });
    CpAgGridService.prototype.getPageSizes = function () {
        // Configure page sizes here
        return [
            { label: '10', value: '10' },
            { label: '25', value: '25' },
            { label: '50', value: '50' },
            { label: '100', value: '100' }
        ];
    };
    Object.defineProperty(CpAgGridService.prototype, "sortColumnContext", {
        get: function () {
            return this._sortColumnContext;
        },
        set: function (sortColumnContext) {
            this._sortColumnContext = sortColumnContext;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CpAgGridService.prototype, "gridApi", {
        get: function () {
            return this._gridApi;
        },
        set: function (gridApi) {
            this._gridApi = gridApi;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CpAgGridService.prototype, "allFilters", {
        get: function () {
            return this._allFilters;
        },
        set: function (allFilters) {
            this._allFilters = allFilters;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CpAgGridService.prototype, "columnDefs", {
        get: function () {
            return this._columnDefs;
        },
        set: function (columnDefs) {
            this._columnDefs = columnDefs;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CpAgGridService.prototype, "recentDownloadAPICall", {
        get: function () {
            return this._initialCall;
        },
        set: function (initialCall) {
            this._initialCall = initialCall;
        },
        enumerable: true,
        configurable: true
    });
    CpAgGridService.prototype.getRecordCount = function () {
        return this.recordCount$;
    };
    CpAgGridService.prototype.getGridName = function () {
        return this.gridName;
    };
    CpAgGridService.prototype.onGridReady = function (params) {
        this.gridApi = params.api;
    };
    CpAgGridService.prototype.showLoadingOverlay = function () {
        this.gridApi.showLoadingOverlay();
    };
    CpAgGridService.prototype.showNoRowsOverlay = function () {
        this.gridApi.showNoRowsOverlay();
    };
    CpAgGridService.prototype.sizeColumnsToFit = function () {
        this.gridApi.sizeColumnsToFit();
    };
    CpAgGridService.prototype.clearFilterState = function () {
        this.removeFilters('all');
        this.allFilters = null;
    };
    CpAgGridService.prototype.clearSortState = function () {
        this.isSorted = false;
        this.gridApi.setSortModel(null);
    };
    Object.defineProperty(CpAgGridService.prototype, "pageContext", {
        get: function () {
            return this._pageContext;
        },
        set: function (pageContext) {
            this._pageContext = pageContext;
        },
        enumerable: true,
        configurable: true
    });
    CpAgGridService.prototype.getDataSource = function () {
        var _this = this;
        var me = this;
        if (!this.dataSource) {
            this.dataSource = {
                getRows: function (params) {
                    _this.params = params;
                    me.showLoadingOverlay();
                    if (_this.pageContext.pageName == 'recentDownload') {
                        if (_this.recentDownloadAPICall == true) {
                            me.registerDataSource();
                        }
                        else {
                            me.registerDataSource();
                            var subscription_1 = _this.getGridLoaded().subscribe(function () {
                                _this.handleSortAndFilter();
                                subscription_1.unsubscribe();
                            });
                        }
                    }
                    else {
                        me.registerDataSource();
                    }
                    // me.gridApi.sizeColumnsToFit();
                },
            };
        }
        return this.dataSource;
    };
    CpAgGridService.prototype.getServerSideDatasource = function () {
        var _this = this;
        var me = this;
        if (!this.dataSource) {
            this.dataSource = {
                getRows: function (params) {
                    _this.params = params;
                    if (me.isRowExpanded()) {
                        me.handleRowExpansion();
                    }
                    else {
                        // me.showLoadingOverlay();
                        me.registerDataSource();
                    }
                },
            };
        }
        return this.dataSource;
    };
    CpAgGridService.prototype.getServerSideTreedataSource = function () {
        var _this = this;
        var me = this;
        if (!this.dataSource) {
            this.dataSource = {
                getRows: function (params) {
                    _this.params = params;
                    if (me.isRowExpanded()) {
                        me.handleTreeNodeExpansion();
                    }
                    else {
                        // me.showLoadingOverlay();
                        me.registerDataSource();
                    }
                },
            };
        }
        return this.dataSource;
    };
    CpAgGridService.prototype.registerDataSource = function () {
        var _this = this;
        this.currentPage = this.getCurrentPage();
        this.oRequest = this.generateSvcRequest();
        this.makeHttpSvcCall()
            .subscribe(function (res) {
            if ((_this.gridRowModelType === 'serverSide')) {
                _this.svcTreeResponseHandler(res);
            }
            else {
                /** Added timeout to load the grid rows properly after changing the filters #13346 */
                setTimeout(function () {
                    _this.svcResponseHandler(res);
                });
            }
            _this.gridLoadedValue = true;
            setTimeout(function () {
                _this.setGridLoaded(true);
                // this.makePageReady();
                // this.hideLoadingOverlay();
            });
        }, function (error) {
            _this.setGridLoaded(true);
            _this.gridLoadedValue = true;
            _this.emitTotalRecordCount(0);
        });
    };
    CpAgGridService.prototype.svcResponseHandler = function (res) {
        var _this = this;
        this.showLoadingOverlay();
        if (res && res.StatusCode === 401) {
            if (this.pageContext.pageName === 'ManageError') {
                localStorage.removeItem('manageErrorDownloadUrl');
                this.router.navigate(['efile'], { relativeTo: this.activateRoute.parent.parent });
                return;
            }
            else if (this.pageContext.pageName === 'ManageErrorKcFile') {
                localStorage.removeItem('KCFileManageErrorDownloadUrl');
                this.router.navigate(['kcfile'], { relativeTo: this.activateRoute.parent.parent });
                return;
            }
        }
        if (res) {
            var results = res.searchResult || res.result || res.ProductDetails || res.UserDetails || res.resulst;
            if (this.pageContext.pageName == 'couponSummaryGrid') {
                results = res;
                this.couponSummaryData.next(res);
                setTimeout(function () {
                    if (window.innerWidth > configuredWidth) {
                        _this.sizeColumnsToFit();
                    }
                });
            }
            else if (this.pageContext.pageName == 'ManageError') {
                if (res.result.eFileRecordsWithError && res.result.eFileRecordsWithError.length && res.result.errors && res.result.errors.length && res.result.eFileDetail) {
                    this.correctErrorPageHeaderData = res.result.eFileDetail;
                    var resObjectsMerged = res.result.eFileRecordsWithError.map(function (item, index) {
                        var resErrorsObj = res.result.errors.find(function (errorItem) {
                            return item.line === errorItem.lineNumber;
                        });
                        var mergedObj = Object.assign({}, item, resErrorsObj);
                        return mergedObj;
                    });
                    results = resObjectsMerged;
                    this.setEFileErrorData(resObjectsMerged, res.result.eFileDetail);
                }
                else if (res.result.eFileDetail) {
                    this.correctErrorPageHeaderData = res.result.eFileDetail;
                }
                else {
                    console.log('some error occured.');
                }
            }
            else if (this.pageContext.pageName == 'ManageErrorKcFile') {
                if (res.result.kcFileRecordsWithError && res.result.kcFileRecordsWithError.length && res.result.errors && res.result.errors.length && res.result.kcFileDetail) {
                    this.correctErrorPageHeaderData = res.result.kcFileDetail;
                    var resObjectsMerged = res.result.kcFileRecordsWithError.map(function (item, index) {
                        var resErrorsObj = res.result.errors.find(function (errorItem) {
                            return item.line === errorItem.lineNumber;
                        });
                        var mergedObj = Object.assign({}, item, resErrorsObj);
                        return mergedObj;
                    });
                    results = resObjectsMerged;
                    this.setKcFileErrorData(resObjectsMerged, res.result.kcFileDetail);
                }
                else if (res.result.kcFileDetail) {
                    this.correctErrorPageHeaderData = res.result.kcFileDetail;
                }
                else {
                    console.log('some error occured.');
                }
            }
            else if (this.pageContext.pageName == 'efileHistoryLog' || this.pageContext.pageName == 'kcfileHistoryLog') {
                results = res;
            }
            else if (this.pageContext.pageName == 'advancedInvoice' || this.pageContext.pageName == 'advancedInvoiceNew') {
                if (res[0].isObsolete) {
                    this.isLocationObsolete.next(true);
                }
                else {
                    this.isLocationObsolete.next(false);
                }
                results = res[0].categoryList.map(function (category) {
                    var favObj = res[1].find(function (fav) {
                        return fav.categoryCode == category.categoryCode;
                    });
                    if (favObj) {
                        category.isFavorite = favObj.isFavorite;
                    }
                    else {
                        category.isFavorite = false;
                    }
                    category.customerNumber = _this.oRequest.customerNumber;
                    return category;
                });
                res.totalRecCount = res[0].categoryList.length;
            }
            else if (this.pageContext.pageName == 'efileSearchResult' || this.pageContext.pageName == 'kcfileSearchResult') {
                setTimeout(function () {
                    if (window.innerWidth > configuredWidth) {
                        _this.sizeColumnsToFit();
                    }
                });
            }
            if (results && results.length > 0) {
                var lastRow = this.getLastRowIndex(this.currentPage, res.count || res.TotalRecCount || res.totalCount);
                if (this.pageContext.pageName == 'recentDownload') {
                    lastRow = res.count;
                }
                else if (this.pageContext.pageName == 'couponSummaryGrid') {
                    lastRow = res.length;
                }
                else if (this.pageContext.pageName == 'efileHistoryLog' || this.pageContext.pageName == 'kcfileHistoryLog') {
                    lastRow = res.length;
                }
                else if (this.pageContext.pageName == 'advancedInvoice' || this.pageContext.pageName == 'advancedInvoiceNew') {
                    lastRow = res[0].categoryList.length;
                }
                this.allData = results;
                this.params.successCallback(results, lastRow);
                if (this.recentDownloadAPICall == true) {
                    this.recentDownloadAPICall = !this.recentDownloadAPICall;
                }
                this.makePageReady();
                if (this.pageContext.pageName == 'recentDownload') {
                    this.setRecentDownloadPageReady(true);
                }
            }
            else {
                this.showNoRowsOverlay();
            }
            this.gridLoadedValue = true;
            this.emitTotalRecordCount(res.count || res.totalRecCount || res.TotalRecCount || res.totalCount || res.length);
        }
        else {
            this.showNoRowsOverlay();
        }
        this.setGridLoaded(true);
    };
    CpAgGridService.prototype.svcTreeResponseHandler = function (res) {
        // this.showLoadingOverlay();
        if (res) {
            var results = void 0;
            var count = 0;
            switch (this.pageContext.pageName) {
                case 'ucMappings':
                    if (res && res.result) {
                        results = res.result.customerMapping;
                        count = res.result.customerMapping ? res.result.customerMapping.length : 0;
                        this.dataContext.next(res.result.user);
                    }
                    break;
                case 'customerMappings':
                    if (res && res.result && res.result.length && res.result[0]) {
                        results = res.result[0].customerMapping;
                        count = res.result[0].customerMapping ? res.result[0].customerMapping.length : 0;
                    }
                    break;
                case 'myProfile':
                    if (res && res.result) {
                        results = res.result.customerMapping;
                        count = res.result.customerMapping ? res.result.customerMapping.length : 0;
                        console.log("@@ Results: ", results);
                        console.log("@@ count: ", count);
                    }
                    break;
                case 'tradeOrgLazyLoading':
                    if (res && res.result) {
                        results = res.result;
                        count = res.result ? res.result.length : 0;
                        console.log("@@ Results: ", results);
                        console.log("@@ count: ", count);
                    }
                    break;
                default:
                    results = res.result;
                    count = res.count;
                    break;
            }
            if (results && results.length > 0) {
                var lastRow = this.getLastRowIndex(this.currentPage, count);
                this.allData = results;
                var treeData = this.getTreeStructure(results.slice());
                console.log("@ Treedata: ", treeData);
                this.treeData = treeData;
                this.params.successCallback(treeData, this.treeData.length);
                if (this.pageContext.pageName != 'tradeOrgLazyLoading') {
                    this.gridApi.forEachNode(function (node) {
                        node.setExpanded(true);
                    });
                }
                this.makePageReady();
                this.emitTotalRecordCount(res.count);
            }
            else {
                this.params.successCallback([], 0);
                this.emitTotalRecordCount(0);
                this.showNoRowsOverlay();
            }
        }
        else {
            this.params.successCallback([], 0);
            this.emitTotalRecordCount(0);
            this.showNoRowsOverlay();
        }
        this.setGridLoaded(true);
    };
    CpAgGridService.prototype.setExpanded = function (treeData) {
        var _this = this;
        if (treeData)
            treeData.forEach(function (item) {
                var node = _this.gridApi.getRowNode(item.customerNumber);
                if (node)
                    node.setExpanded(true);
                // if (item && item.children && item.children.length) {
                //   // this.setExpanded(item.children);
                //   item.children.forEach(element => {
                //     let node: any = this.gridApi.getRowNode(element.customerNumber);
                //     if (node)
                //       node.setExpanded(true);
                //     // if (element && element.children && element.children.length) {
                //     //   this.setExpanded(item);
                //     // }
                //   });
                // }
            });
    };
    Object.defineProperty(CpAgGridService.prototype, "treeData", {
        get: function () {
            return this._treeData;
        },
        set: function (value) {
            this._treeData = value;
        },
        enumerable: true,
        configurable: true
    });
    CpAgGridService.prototype.getTreeStructure = function (DataToProcess) {
        var _this = this;
        console.log("@@ DataToProcess: ", DataToProcess);
        for (var ctr = 0; ctr <= DataToProcess.length; ctr++) {
            this.recursiveProcessNode(DataToProcess[ctr], ctr, DataToProcess);
        }
        var temp = DataToProcess.filter(function (n) {
            return n.tree;
        });
        console.log("@@ Temo: ", temp);
        return temp.map(function (d) {
            d.comparerGroupKey = d[_this.pageContext.referenceKeyName];
            return d;
        });
    };
    CpAgGridService.prototype.recursiveProcessNode = function (CURRENT_NODE, CURRENT_NODE_INDEX, ALL_DATA) {
        if (CURRENT_NODE && CURRENT_NODE[this.pageContext.groupKeyName]) {
            var PREV_NODE_INDEX = CURRENT_NODE_INDEX === 0 ? -1 : CURRENT_NODE_INDEX - 1;
            if (PREV_NODE_INDEX >= 0) {
                var PREV_NODE = ALL_DATA[PREV_NODE_INDEX];
                if (PREV_NODE[this.pageContext.referenceKeyName] === CURRENT_NODE[this.pageContext.groupKeyName]) {
                    if (!PREV_NODE.children) {
                        Object.assign(PREV_NODE, { 'children': [] });
                    }
                    PREV_NODE.children.push(CURRENT_NODE);
                }
                else {
                    this.recursiveProcessNode(CURRENT_NODE, PREV_NODE_INDEX, ALL_DATA);
                }
            }
        }
        else {
            if (CURRENT_NODE && !CURRENT_NODE.hasOwnProperty('tree')) {
                Object.assign(CURRENT_NODE, { 'tree': true });
            }
        }
    };
    CpAgGridService.prototype.doesTextFilterPass = function (key, filter, item) {
        var doesFilterPass = false;
        if (key && filter.value && filter.value !== "") {
            if (item[key]) {
                if (item[key].toLowerCase().indexOf(filter.value.toLowerCase()) < 0) {
                    doesFilterPass = false;
                }
                else {
                    doesFilterPass = true;
                }
            }
        }
        else {
            doesFilterPass = true;
        }
        return doesFilterPass;
    };
    CpAgGridService.prototype.doesSizeFilterPass = function (key, filter, item) {
        var doesFilterPass = false;
        if (key && filter.value && filter.value !== "") {
            if (item[key] !== null && item[key] !== undefined) {
                var itemKey = parseInt(item[key], 10) >= 1024 ? (parseInt(item[key], 10) / 1024).toString().split('.')[0] : item[key];
                if (parseInt(itemKey, 10) !== parseInt(filter.value, 10)) {
                    doesFilterPass = false;
                }
                else {
                    doesFilterPass = true;
                }
            }
        }
        else {
            doesFilterPass = true;
        }
        return doesFilterPass;
    };
    CpAgGridService.prototype.doesDateFilterPass = function (key, filter, item) {
        var doesFilterPass = false;
        if (key && filter.value && filter.value !== "") {
            if (item[key]) {
                var dateObject = new Date(item[key]).setHours(0, 0, 0, 0);
                var filterDate = filter.value.setHours(0, 0, 0, 0);
                if (filterDate === dateObject) {
                    doesFilterPass = true;
                }
            }
        }
        else {
            doesFilterPass = true;
        }
        return doesFilterPass;
    };
    CpAgGridService.prototype.doesDateRangeFilterPass = function (key, filter, item) {
        var doesFilterPass = false;
        if (key && filter.value && filter.value.from && filter.value.to && filter.value.from !== "" && filter.value.to !== "") {
            if (item[key]) {
                var lastChanged = new Date(item.lastChanged).setHours(0, 0, 0, 0);
                var filterFromDate = filter.value.from.setHours(0, 0, 0, 0);
                var filterToDate = filter.value.to.setHours(0, 0, 0, 0);
                if (lastChanged >= filterFromDate && lastChanged <= filterToDate) {
                    doesFilterPass = true;
                }
            }
            else {
                doesFilterPass = false;
                ;
            }
        }
        return doesFilterPass;
    };
    CpAgGridService.prototype.doesMultiSelectFilterPass = function (key, filter, item) {
        var doesFilterPass = false;
        if (key) {
            var selectedTypes = filter.value.filter(function (filter) {
                return filter.selected;
            });
            if (selectedTypes && selectedTypes.length > 0) {
                doesFilterPass = selectedTypes.some(function (f) {
                    return (item[key].toString().toLowerCase() === f.value.toLowerCase());
                });
            }
            else {
                doesFilterPass = true;
            }
        }
        else {
            doesFilterPass = true;
        }
        return doesFilterPass;
    };
    CpAgGridService.prototype.doesRecentDownloadsMultiSelectFilterPass = function (key, filter, item) {
        var doesFilterPass = false;
        if (key) {
            var selectedTypes = filter.value.filter(function (filter) {
                return filter.selected;
            });
            if (selectedTypes && selectedTypes.length > 0) {
                doesFilterPass = selectedTypes.some(function (f) {
                    return (parseInt(item[key], 10) === parseInt(f.value, 10));
                });
            }
            else {
                doesFilterPass = true;
            }
        }
        else {
            doesFilterPass = true;
        }
        return doesFilterPass;
    };
    CpAgGridService.prototype.handleSortAndFilter = function () {
        this.showLoadingOverlay();
        var dataAfterSortingAndFiltering = this.sortAndFilter(this.allData, this.params.sortModel, this.params.filterModel);
        var rowsThisPage = dataAfterSortingAndFiltering.slice(this.params.startRow, this.params.endRow);
        var lastRow = -1;
        if (dataAfterSortingAndFiltering.length <= this.params.endRow) {
            lastRow = dataAfterSortingAndFiltering.length;
        }
        // if (this.params.filterModel && Object.entries(this.params.filterModel).every(fm => fm[1]["value"] === null)) {
        //   lastRow = -1;
        //   // this.setFilterOn(false);
        // }
        if (dataAfterSortingAndFiltering && dataAfterSortingAndFiltering.length) {
            this.params.successCallback(rowsThisPage, lastRow);
            this.makePageReady();
        }
        else {
            this.showNoRowsOverlay();
        }
        this.emitTotalRecordCount(dataAfterSortingAndFiltering.length);
    };
    CpAgGridService.prototype.sortAndFilter = function (allOfTheData, sortModel, filterModel) {
        return this.sortData(sortModel, this.filterData(filterModel, allOfTheData));
    };
    CpAgGridService.prototype.filterData = function (filterModel, data) {
        var filterPresent = filterModel && Object.keys(filterModel).length > 0;
        if (!filterPresent) {
            return data;
        }
        var resultOfFilter = [];
        for (var i = 0; i < data.length; i++) {
            var item = data[i];
            var hasFilterPass = true;
            // Add your field names here if as per category
            for (var _i = 0, _a = Object.entries(filterModel); _i < _a.length; _i++) {
                var _b = _a[_i], key = _b[0], filter_1 = _b[1];
                switch (key) {
                    case 'contractNumber': // remove once all SQL API integration is done
                    case 'contractId':
                    case 'legacyPANumber':
                    case 'contractName':
                    case 'customerNumber':
                    case 'customerName':
                    case 'fileName':
                        hasFilterPass = hasFilterPass && this.doesTextFilterPass(key, filter_1, item);
                        break;
                    case 'size':
                        hasFilterPass = hasFilterPass && this.doesSizeFilterPass(key, filter_1, item);
                        break;
                    case 'startDate':
                    case 'endDate':
                    case 'firmEndDate':
                    case 'validFrom':
                    case 'validTo':
                    case 'lastDownloadedDate':
                    case 'availableUntil':
                        hasFilterPass = hasFilterPass && this.doesDateFilterPass(key, filter_1, item);
                        break;
                    // case 'lastDownloadedDate':
                    //   hasFilterPass = hasFilterPass && this.doesDateRangeFilterPass(key, filter, item);
                    //   break;
                    case 'status':
                    case 'moduleType':
                        hasFilterPass = hasFilterPass && this.doesRecentDownloadsMultiSelectFilterPass(key, filter_1, item);
                        break;
                    case 'Status':
                        hasFilterPass = hasFilterPass && this.doesMultiSelectFilterPass(key, filter_1, item);
                        break;
                }
            }
            if (!hasFilterPass)
                continue;
            resultOfFilter.push(item);
        }
        return resultOfFilter;
    };
    CpAgGridService.prototype.sortData = function (sortModel, data) {
        var sortPresent = sortModel && sortModel.length > 0;
        if (!sortPresent) {
            return data;
        }
        var resultOfSort = data.slice();
        resultOfSort.sort(function (a, b) {
            for (var k = 0; k < sortModel.length; k++) {
                var sortColModel = sortModel[k];
                var valueA = a[sortColModel.colId];
                var valueB = b[sortColModel.colId];
                if (valueA == valueB) {
                    continue;
                }
                var sortDirection = sortColModel.sort === 'asc' ? 1 : -1;
                if (valueA > valueB) {
                    return sortDirection;
                }
                else {
                    return sortDirection * -1;
                }
            }
            return 0;
        });
        return resultOfSort;
    };
    CpAgGridService.prototype.isRowExpanded = function () {
        return this.params.request && this.params.request.groupKeys && this.params.request.groupKeys.length > 0;
    };
    CpAgGridService.prototype.handleRowExpansion = function () {
        // Imp Note: Put only those pageNames here which have tree structure
        var request = this.generateChildNodesRequest();
        switch (this.pageContext.pageName) {
            case 'tradeOrg':
            case 'tradeOrgLazyLoading': {
                // this.httpService.getTradeOrgChildResults(request).subscribe(res => {
                //   if (res) {
                //     let results: any = res.searchResult || res.result;
                //     if (results && results.length > 0) {
                //       let lastRow: number = results.length;
                //       results = results.map(row => {
                //         let newRow: any = Object.assign({}, row);
                //         newRow.groupNumber = row.endUserId;
                //         return newRow;
                //       })
                //       this.params.successCallback(results, lastRow);
                //     }
                //     else {
                //       this.params.successCallback([], 0);
                //     }
                //   }
                //   else {
                //     this.params.successCallback([], 0);
                //   }
                // });
                break;
            }
        }
    };
    CpAgGridService.prototype.handleTreeNodeExpansion = function () {
        if (this.pageContext.pageName == 'tradeOrgLazyLoading') {
            this.appService.setLoadingStatus(true);
        }
        var results = [];
        results = this.retrieveChildren(this.params.request.groupKeys, this.treeData);
        this.params.successCallback(results, results.length);
        if (this.pageContext.pageName != 'tradeOrgLazyLoading') {
            this.gridApi.forEachNode(function (node) {
                node.setExpanded(true);
            });
        }
    };
    CpAgGridService.prototype.retrieveChildren = function (groupKeys, data) {
        var _this = this;
        if (groupKeys.length === 0) {
            return data.map(function (d) {
                d.comparerGroupKey = _this.params.request.groupKeys[0];
                return d;
            });
        }
        var key = groupKeys[0];
        for (var i = 0; i < data.length; i++) {
            if (data[i][this.pageContext.referenceKeyName] === key) {
                return this.retrieveChildren(groupKeys.slice(1), data[i].children.slice());
            }
        }
    };
    CpAgGridService.prototype.generateChildNodesRequest = function () {
        // TK To Do: Prep request as per groupkeys
        var request = new TradeOrgEndUser();
        request.pageSize = this.serverPageSize;
        // request.query = Object.assign({}, this.oRequest.query);
        request.query.searchView = this.oRequest && this.oRequest.query ? this.oRequest.query.searchView : '';
        request.query.groupNumber = this.params.request.groupKeys[0];
        request.query.customerNumber = this.oRequest && this.oRequest.query ? this.oRequest.query.customerNumber : '';
        request.query.contractNumber = this.oRequest && this.oRequest.query ? this.oRequest.query.contractNumber : '';
        return request;
    };
    CpAgGridService.prototype.getCurrentPage = function () {
        var startRow;
        if (this.gridRowModelType === 'serverSide')
            startRow = this.params.request ? this.params.request.startRow : 0;
        else
            startRow = this.params.startRow;
        return (((startRow / this.serverPageSize) + 1) || 1);
    };
    CpAgGridService.prototype.getRowModelType = function () {
        return this.rowModelType;
    };
    CpAgGridService.prototype.getServerSideRowModelType = function () {
        return this.serverSideRowModelType;
    };
    CpAgGridService.prototype.getRowSelection = function () {
        return this.rowSelection;
    };
    Object.defineProperty(CpAgGridService.prototype, "clientPageSize", {
        get: function () {
            return this._clientPageSize;
        },
        set: function (pageSize) {
            this._clientPageSize = pageSize;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CpAgGridService.prototype, "serverPageSize", {
        get: function () {
            return this._serverPageSize;
        },
        set: function (pageSize) {
            this._serverPageSize = pageSize;
        },
        enumerable: true,
        configurable: true
    });
    CpAgGridService.prototype.getCacheOverflowSize = function () {
        return this.cacheOverflowSize;
    };
    CpAgGridService.prototype.getMaxConcurrentDatasourceRequests = function () {
        return this.maxConcurrentDataSourceRequests;
    };
    CpAgGridService.prototype.getInfiniteInitialRowCount = function () {
        return this.infiniteInitialRowCount;
    };
    CpAgGridService.prototype.getMaxBlocksInCache = function () {
        return this.maxBlocksInCache;
    };
    CpAgGridService.prototype.getRowNodeId = function () {
        return function (item) {
            return item.line ? item.line : 0;
        };
    };
    CpAgGridService.prototype.getAdvancedGridRowId = function () {
        return function (item) {
            return item.categoryCode ? item.categoryCode : 0;
        };
    };
    CpAgGridService.prototype.getTooltipHoverClass = function (event) {
        return (event.type == 'mouseover' ? 'tooltip-hover tooltip-hover--open' : 'tooltip-hover');
    };
    CpAgGridService.prototype.getEndUserTooltipHoverClass = function (event) {
        return (event.type == 'mouseover' ? 'endUser-tooltip-hover tooltip-hover--open' : 'endUser-tooltip-hover');
    };
    CpAgGridService.prototype.getProductSearchTooltipHoverClass = function (event) {
        return (event.type == 'mouseover' ? 'product-search-tooltip-hover tooltip-hover--open' : 'product-search-tooltip-hover');
    };
    CpAgGridService.prototype.getMultipleLocationTooltipHoverClass = function (event) {
        return (event.type == 'mouseover' ? 'multiple-location-tooltip-hover tooltip-hover--open' : 'multiple-location-tooltip-hover');
    };
    CpAgGridService.prototype.get5andMoreTooltipHoverClass = function (event) {
        return (event.type == 'mouseover' ? 'fiveandmore-tooltip-hover tooltip-hover--open' : 'fiveandmore-tooltip-hover');
    };
    CpAgGridService.prototype.getHoldingSearchTooltipHoverClass = function (event) {
        return (event.type == 'mouseover' ? 'holding-search-tooltip-hover tooltip-hover--open' : 'holding-search-tooltip-hover');
    };
    CpAgGridService.prototype.getTradeTooltipHoverClass = function (event) {
        return (event.type == 'mouseover' ? 'trade-search-tooltip-hover tooltip-hover--open' : 'trade-search-tooltip-hover');
    };
    CpAgGridService.prototype.getDefaultColDef = function () {
        return { resizable: true, sortable: true };
    };
    CpAgGridService.prototype.onFilterChanged = function () {
        this.setReloadGrid(true);
        this.getAllFilters();
    };
    CpAgGridService.prototype.getAllFilters = function () {
        this.allFilters = new Array();
        var filterModel = this.gridApi.getFilterModel();
        var _loop_1 = function (key, filter_2) {
            if (this_1.pageContext.pageName === "tradeOrg" && key === "ag-Grid-AutoColumn") {
                key = "groupNumber";
            }
            var currentCd = this_1._columnDefs.find(function (cd) {
                var actualKey = key.indexOf('_') !== -1 ? key.substring(0, key.indexOf('_')) : key;
                return cd.field === key || cd.field === actualKey;
            });
            var oFilter = new Object();
            oFilter.header = currentCd ? currentCd.headerName : key;
            oFilter.key = key;
            switch (filter_2["type"]) {
                case "free-text":
                    {
                        oFilter.text = filter_2["value"];
                        if (oFilter.text == "0") {
                            oFilter.text = '0 ';
                        }
                        else if (oFilter.text == "0000") {
                            oFilter.text = '0000 ';
                        }
                        break;
                    }
                case "multi-select":
                    {
                        // oFilter.text = filter["value"].filter(f => f.selected).map(f => { return f.text }).join(', ');
                        oFilter.text = filter_2["value"].filter(function (f) { return f.selected; }).map(function (f) { return f.text; });
                        oFilter.type = "multi-select";
                        break;
                    }
                case "date-range-selector":
                    {
                        if (filter_2["value"]) {
                            if (filter_2["value"].from && filter_2["value"].to)
                                oFilter.text = (formatDate(filter_2["value"].from, this_1.dateFormat || this_1.pageContext.dateFormat, this_1.locale) + "-" + formatDate(filter_2["value"].to, this_1.dateFormat || this_1.pageContext.dateFormat, this_1.locale));
                            else if (filter_2["value"].from) {
                                oFilter.rangeType = "filterResult.fromLabel";
                                oFilter.text = formatDate(filter_2["value"].from, this_1.dateFormat || this_1.pageContext.dateFormat, this_1.locale);
                            }
                            else if (filter_2["value"].to) {
                                oFilter.rangeType = "filterResult.toLabel";
                                oFilter.text = formatDate(filter_2["value"].to, this_1.dateFormat || this_1.pageContext.dateFormat, this_1.locale);
                            }
                            oFilter.type = "date-range-selector";
                        }
                        break;
                    }
                case "date-selector":
                    oFilter.text = filter_2["value"] ? formatDate(filter_2["value"], this_1.dateFormat || this_1.pageContext.dateFormat, this_1.locale) : "";
                    break;
                case "date-selectorufn":
                    {
                        var dateFormatted = formatDate(filter_2["value"], this_1.dateFormat || this_1.pageContext.dateFormat, this_1.locale);
                        if (dateFormatted == '12/31/9999' || dateFormatted == "31/12/9999") {
                            oFilter.text = "UFN";
                        }
                        else
                            oFilter.text = filter_2["value"] ? formatDate(filter_2["value"], this_1.dateFormat || this_1.pageContext.dateFormat, this_1.locale) : "";
                        break;
                    }
                case "type-ahead":
                    {
                        oFilter.text = filter_2["value"];
                        oFilter.type = "type-ahead";
                        break;
                    }
            }
            if (oFilter.text && (!oFilter.type && oFilter.text !== '') || (oFilter.type === 'multi-select' && oFilter.text.length) ||
                (oFilter.type === 'date-range-selector' && oFilter.text) || (oFilter.type === 'type-ahead' && oFilter.text.length)) {
                this_1.allFilters.push(oFilter);
            }
        };
        var this_1 = this;
        for (var _i = 0, _a = Object.entries(filterModel); _i < _a.length; _i++) {
            var _b = _a[_i], key = _b[0], filter_2 = _b[1];
            _loop_1(key, filter_2);
        }
    };
    CpAgGridService.prototype.removeFilters = function (filterKey) {
        var filterModel = this.gridApi.getFilterModel();
        if (filterKey === 'all') {
            for (var _i = 0, _a = Object.entries(filterModel); _i < _a.length; _i++) {
                var _b = _a[_i], key = _b[0], filter_3 = _b[1];
                if (filterModel[key])
                    filterModel[key].value = null;
            }
            this.gridApi.setFilterModel(filterModel);
        }
        else {
            if (Object.keys(filterModel).indexOf(filterKey) !== -1) {
                filterModel[filterKey].value = null;
                this.gridApi.setFilterModel(filterModel);
            }
        }
    };
    CpAgGridService.prototype.setColumnDefs = function (colDefs) {
        this._columnDefs = colDefs;
    };
    CpAgGridService.prototype.mapColDefs = function (colDefs) {
        var _this = this;
        var me = this;
        var modifiedColDefs = colDefs.filter(function (i) { return true; });
        // Add any value formatters here
        // return colDefs.map(cd => {
        //   let mappedCd = Object.assign({}, cd);
        colDefs = colDefs.filter(function (i) { return true; });
        modifiedColDefs.forEach(function (mappedCd) {
            // Add any value formatters here
            // return colDefs.map(cd => {
            // let mappedCd = Object.assign({}, cd);
            switch (mappedCd.field) {
                case 'startDate':
                    mappedCd.valueFormatter = function (data) {
                        if (data.colDef.headerName === "agGrid.agGridColumns.claimPeriodLabel") {
                            var startDate = void 0, endDate = void 0;
                            if (data && data.data) {
                                startDate = _this.filterInvalidDate(data.data.startDate);
                                endDate = _this.filterInvalidDate(data.data.endDate);
                                return (startDate && endDate) ? startDate + " - " + endDate : "";
                            }
                            else {
                                return "";
                            }
                        }
                        else {
                            if (data && data.value) {
                                if (data.value.indexOf('9999') > -1) {
                                    return "UFN";
                                }
                                else if (data.value.indexOf('0001') > -1) {
                                    return '';
                                }
                                else {
                                    if (data.value.length > 10)
                                        return formatDate(data.value.substring(0, 10), _this.dateFormat || _this.pageContext.dateFormat, _this.locale);
                                    else
                                        return data.value;
                                }
                            }
                            else
                                return "";
                        }
                    };
                    return mappedCd;
                // break;
                case 'endDate':
                case 'firmEndDate':
                case 'lastChanged':
                case 'updatedDate':
                case 'updateDate':
                case 'expiring':
                case 'customerStartDate':
                case 'customerEndDate':
                case 'lastDownloadedDate':
                case 'availableUntil':
                case 'lastUpdatedDate':
                case 'lastChangedDate':
                case 'validFrom':
                case 'validTo':
                case 'lastDownload':
                case 'passwordExpiryDate':
                case 'lastLoginDate':
                case 'passwordExpiryDate':
                case 'receivedDate':
                case 'availableDate':
                case 'issueDate':
                case 'submittedDate':
                case 'invoiceDate':
                case 'uploaded':
                case 'submitted':
                case 'updated':
                case 'lastRefreshDate':
                case 'nextAvailableDate':
                    mappedCd.valueFormatter = function (data) {
                        if (data && data.value) {
                            if (data.value.indexOf('9999') > -1) {
                                return "UFN";
                            }
                            else if (data.value.indexOf('0001') > -1) {
                                if (_this.pageContext.pageName == 'invoiceSearchResult') {
                                    return '-';
                                }
                                else {
                                    return '';
                                }
                            }
                            else {
                                if (data.value.length > 10) {
                                    return formatDate(data.value.substring(0, 10), _this.dateFormat || _this.pageContext.dateFormat, _this.locale);
                                }
                                else {
                                    return data.value;
                                }
                            }
                        }
                        else {
                            if (_this.pageContext && (_this.pageContext.pageName == 'advancedInvoice' || _this.pageContext.pageName == 'advancedInvoiceNew')) {
                                return "--";
                            }
                            else {
                                return "";
                            }
                        }
                    };
                    return mappedCd;
                case 'lastLoginDate__1':
                    mappedCd.valueFormatter = function (data) {
                        if (data && data.data && data.data.lastLoginDate) {
                            if (data.data.lastLoginDate.length > 18)
                                return data.data.lastLoginDate.substring(11, 19);
                            else
                                return "";
                        }
                        else
                            return "";
                    };
                    return mappedCd;
                case 'address':
                    mappedCd.valueFormatter = function (data) {
                        if (data.colDef.headerName === "priceagreement.agGridColumns.euFullAddress") {
                            return data.value.address;
                        }
                        else
                            return data.value;
                    };
                    return mappedCd;
                case 'status':
                    mappedCd.valueFormatter = function (data) {
                        if (data.colDef.headerName === "priceagreement.agGridColumns.euStatusLabel") {
                            return (data && data.data && data.data.status ? data.data.status : '');
                        }
                        else if (data.colDef.headerName === "")
                            return '';
                        else
                            return (data && data.data ? data.data.status : '');
                    };
                    return mappedCd;
                case 'comments':
                    mappedCd.valueFormatter = function (data) {
                        if (data && data.data && data.data.comments) {
                            if (data.colDef.headerName !== "priceagreement.agGridColumns.euComments") {
                                if (Number.isInteger(+data.data.comments))
                                    return _this.mapComments(data.data.comments, kcpUpdates);
                                else
                                    return (data.data.comments != null ? data.data.comments : '');
                            }
                            else {
                                return (data.data.comments != null ? data.data.comments : '');
                            }
                        }
                        else
                            return '';
                    };
                    return mappedCd;
                case 'comment':
                    mappedCd.valueFormatter = function (data) {
                        if (data && data.data && data.data.comment) {
                            if (data.colDef.headerName !== "priceagreement.agGridColumns.soldToComment") {
                                if (Number.isInteger(+data.data.comment))
                                    return _this.mapComments(data.data.comment, customerSoldToComments);
                                else
                                    return (data.data.comment != null ? data.data.comment : '');
                            }
                            else {
                                return (data.data.comment != null ? data.data.comment : '');
                            }
                        }
                        else
                            return '';
                    };
                    return mappedCd;
                case 'changeReason':
                    mappedCd.valueFormatter = function (data) {
                        if (data && data.data && data.data.changeReason && !data.colDef.feature) {
                            if (data && data.data && data.data.trackingType && data.data.updatedLineItemNumber) {
                                if (data.data.trackingType.toLowerCase() == "product") {
                                    return (data.data.changeReason + " - " + data.data.updatedLineItemNumber);
                                }
                                else if (data.data.trackingType.toLowerCase() == "enduser") {
                                    return (data.data.changeReason + " - " + data.data.updatedLineItemNumber);
                                }
                                else if (data.data.trackingType.toLowerCase() == "customer") {
                                    return (data.data.changeReason + " - " + data.data.updatedLineItemNumber);
                                }
                                else {
                                    return (_this.mapComments(data.data.changeReason, customerComments) + " - " + data.data.updatedLineItemNumber);
                                }
                            }
                        }
                        else if (data.colDef.feature && data.colDef.feature == 'emeaNotifications') {
                            return (data.data.changeReason);
                        }
                        else {
                            return '';
                        }
                    };
                    return mappedCd;
                //azure team will directly send the data in api now
                // case 'customerComments':
                //   mappedCd.valueFormatter = (data) => {
                //     if (data && data.data && data.data.customerComments) {
                //       let fieldValue = data.data.customerComments;
                //       let fieldValueArray = fieldValue.split('_');
                //       let changeType = fieldValueArray[0];
                //       let code = fieldValueArray[1];
                //       switch (changeType) {
                //         case 'Customer':
                //           return this.mapComments(code, customerComments);
                //         case 'EndUser':
                //           return this.mapComments(code, endUserComments);
                //         case 'Product':
                //           return this.mapComments(code, kcpUpdates);
                //         case 'Multiple':
                //           return 'Multiple Changes Done'
                //         default:
                //           return ''
                //       }
                //     }
                //     else
                //       return '';
                //   }
                //   return mappedCd;
                case 'pricePerCaseForREandFD':
                    mappedCd.valueFormatter = function (data) {
                        return '%';
                    };
                    return mappedCd;
                case 'itemsPerUOM':
                    mappedCd.valueFormatter = function (data) {
                        if (_this.pageContext.pageName !== 'mcProductsDetailsSection') {
                            var fieldValue = data && data.data ? data.data.itemsPerUOM : '';
                            if (fieldValue && fieldValue.indexOf(' ') !== -1) {
                                return fieldValue.substring(0, fieldValue.indexOf(' '));
                            }
                            else {
                                return '';
                            }
                        }
                    };
                    return mappedCd;
                case 'price':
                    mappedCd.valueFormatter = function (data) {
                        if (data.colDef.headerName === "priceagreement.agGridColumns.FDRate") {
                            if (data && data.value) {
                                return data.value + " %";
                            }
                        }
                    };
                    return mappedCd;
                // case 'twelveMonthVolume':
                //   mappedCd.valueFormatter = (data) => {
                //     if (data) {
                //       if (data.value) {
                //         let n = new Number(data.value);
                //         return n.toLocaleString("en-US", {
                //           minimumFractionDigits: 2,
                //           maximumFractionDigits: 2
                //         });
                //       }
                //       else {
                //         return '0';
                //       }
                //     }
                //   }
                //   return mappedCd;
                case 'size':
                    mappedCd.valueFormatter = function (data) {
                        if (data && data.value) {
                            var fieldValue = data.value / 1024;
                            var fieldValueArray = fieldValue.toString().split('.');
                            if (data.value >= 1024) {
                                return fieldValueArray[0] + " KB";
                            }
                            else {
                                return data.value + " Bytes";
                            }
                        }
                    };
                    return mappedCd;
                case 'endUserNumber':
                    mappedCd.valueFormatter = function (data) {
                        if (data.colDef.headerName === "priceagreement.agGridColumns.EuRequestsNumber") {
                            if (data && data.data) {
                                if (data.data.status === 'COM')
                                    return data.data.endUserNumber;
                                else
                                    return '';
                            }
                            else
                                return '';
                        }
                        else
                            return data.value;
                    };
                    return mappedCd;
                case 'claimStartDate':
                    mappedCd.valueFormatter = function (data) {
                        if (data.colDef.headerName === "agGrid.agGridColumns.claimPeriodLabel") {
                            var startDate = void 0, endDate = void 0;
                            if (data && data.data && data.data.claimStartDate && data.data.claimEndDate) {
                                startDate = _this.filterInvalidDate(data.data.claimStartDate);
                                endDate = _this.filterInvalidDate(data.data.claimEndDate);
                                return (startDate && endDate) ? startDate + " - " + endDate : "";
                            }
                            else {
                                return "";
                            }
                        }
                    };
                    return mappedCd;
                case 'errors':
                case 'netIssued':
                    mappedCd.valueFormatter = function (data) {
                        if (_this.pageContext.pageName != 'efileHistoryLog' || _this.pageContext.pageName != 'kcfileHistoryLog') {
                            if (data && (data.value || data.value == 0) && data.value != null && data.value !== "") {
                                var formattedValue = parseFloat(data.value).toLocaleString("en-US", {
                                    minimumFractionDigits: 2
                                });
                                if (formattedValue.indexOf('-') > -1 && _this._globalAppService.getDefaultRegionString() != 'EMEA') {
                                    var returnValue = '(' + formattedValue.substring(1) + ')';
                                    return returnValue;
                                }
                                else
                                    return formattedValue;
                            }
                        }
                    };
                    return mappedCd;
                case 'requestedDate':
                    mappedCd.valueFormatter = function (data) {
                        if (data && data.value) {
                            if (_this.pageContext.pageName == "endUserRequests") {
                                var formattedDate = formatDate(data["value"], 'MM/dd/yyyy', _this.locale);
                                return formattedDate;
                            }
                            else {
                                return data.value;
                            }
                        }
                        else {
                            return '';
                        }
                    };
                    return mappedCd;
                default:
                    return mappedCd;
            }
        });
        return modifiedColDefs;
    };
    CpAgGridService.prototype.filterInvalidDate = function (date) {
        if (date.indexOf('9999') > -1) {
            return "UFN";
        }
        else if (date.indexOf('0001') > -1) {
            return '';
        }
        else {
            return date.length > 10 ? formatDate(date.substring(0, 10), this.dateFormat || this.pageContext.dateFormat, this.locale) : date;
        }
    };
    CpAgGridService.prototype.mapComments = function (commentCode, referenceList) {
        var comments = referenceList.filter(function (ku) {
            return parseInt(ku.changeReason, 10) === parseInt(commentCode, 10);
        });
        if (comments && comments.length) {
            return comments[0].value;
        }
        else {
            return '';
        }
    };
    CpAgGridService.prototype.mapEuStatuses = function (status) {
        switch (status) {
            case 'NEW':
                return 'New';
            case 'PEN':
                return 'Pending';
            case 'DEN':
                return 'Denied';
            case 'COM':
                return 'Complete';
            case 'Submitted':
                return 'Submitted';
            default:
                return '';
        }
    };
    CpAgGridService.prototype.onColumnResized = function (event) {
        this.gridApi.resetRowHeights();
    };
    CpAgGridService.prototype.generateSvcRequest = function () {
        var oRequest = Object.assign({}, this.pageContext.request);
        switch (this.pageContext.pageName) {
            case 'ucMappings':
            case 'customerMappings':
                break;
            case 'couponSummaryGrid':
                oRequest = this.pageContext.request;
                break;
            case 'tradeOrgLazyLoading':
                oRequest.pageSize = this.tradeOrgMaxSize;
                break;
            // case 'ManageError':
            case 'efileHistoryLog':
                oRequest = this.pageContext.request;
                break;
            case 'advancedInvoice':
            case 'advancedInvoiceNew':
                oRequest = this.pageContext.request;
                break;
            case 'kcfileHistoryLog':
                oRequest = this.pageContext.request;
                break;
            default:
                oRequest.pageSize = this.serverPageSize;
                if (this.pageContext.pageName === 'tradeOrgLazyLoading')
                    oRequest.pageSize = this.tradeOrgMaxSize;
                oRequest.currentPage = this.getCurrentPage();
                break;
        }
        var tileType = this.getTileType();
        if (tileType) {
            if (!oRequest.query)
                oRequest.query = {};
            oRequest.query.dashboardTilesType = tileType;
        }
        if (oRequest.filter)
            oRequest.filter = this.getFilterRequestParams(oRequest.filter);
        if (this.getSortRequestParams().length > 0)
            oRequest.orderBy = this.getSortRequestParams();
        // if (this.pageContext.pageName === 'padetailproduct') {
        //   if (!(oRequest.orderBy && oRequest.orderBy.length > 0 && !!oRequest.orderBy[0] && oRequest.orderBy[0] !== '')) {
        //     oRequest.orderBy = ['lastChanged desc'];
        //   }
        // }
        // if (this.pageContext.pageName === 'downloadCustListModal') {
        //   oRequest.filter = this.pageContext.request.filter;
        // }
        this.oRequest = oRequest;
        return this.oRequest;
    };
    CpAgGridService.prototype.getTileType = function () {
        var tileType;
        switch (this.pageContext.pageName) {
            case 'activeFuture':
                tileType = 1;
                break;
            case 'recentlyChanged':
                tileType = 2;
                break;
            case 'expiringSoon':
                tileType = 3;
                break;
            case 'recentlyExpired':
                tileType = 4;
                break;
            case 'paFavourite':
                tileType = 5;
                break;
        }
        return tileType;
    };
    CpAgGridService.prototype.getFilterRequestParams = function (oFilter) {
        var filterParams = this.getFilterRequestObject();
        var filterModel = this.getFilterModel();
        if (filterModel) {
            for (var _i = 0, _a = Object.entries(filterModel); _i < _a.length; _i++) {
                var _b = _a[_i], key = _b[0], filter_4 = _b[1];
                if (filter_4 && filter_4["value"]) {
                    key = key.indexOf('_') !== -1 ? key.substring(0, key.indexOf('_')) : key;
                    console.log("key : ", key);
                    switch (key) {
                        case 'contractNumber': // remove once all SQL API integration is done
                        case 'contractId':
                            if (this.pageContext.pageName === "endUserRequests" || this.pageContext.pageName === "myNotifications" || this.pageContext.pageName === "downloadCustListModal") {
                                filterParams.contractNumber = filter_4["value"];
                            }
                            else {
                                filterParams.contractIds = [];
                                filterParams.contractIds.push(filter_4["value"]);
                            }
                            break;
                        case 'customerId':
                            filterParams.customerIds = [];
                            filterParams.customerIds.push(filter_4["value"]);
                            break;
                        case 'legacyPANumber':
                            if (this.pageContext.pageName === "endUserRequests" || this.pageContext.pageName === "myNotifications") {
                                filterParams.legacyPANumber = filter_4["value"];
                            }
                            else {
                                filterParams.legacyPANumber = [];
                                filterParams.legacyPANumber.push(filter_4["value"]);
                            }
                            break;
                        case 'contractName':
                            filterParams.contractName = filter_4["value"];
                            break;
                        case 'eFileID':
                            filterParams.eFileID = filter_4["value"];
                            break;
                        case 'claimFileId':
                            filterParams.claimFileId = filter_4["value"];
                            break;
                        case 'eFileName':
                            filterParams.eFileName = filter_4["value"];
                            break;
                        case 'meetCompNumber':
                            filterParams.meetCompNumber = [];
                            filterParams.meetCompNumber.push(filter_4["value"]);
                            break;
                        case 'salesTerritoryNumber':
                            filterParams.salesTerritoryNumber = [];
                            filterParams.salesTerritoryNumber.push(filter_4["value"]);
                            break;
                        case 'uploadedBy':
                            filterParams.uploadedBy = filter_4["value"];
                            break;
                        case 'meetCompName':
                            filterParams.meetCompName = filter_4["value"];
                            break;
                        case 'salesTerritoryName':
                            filterParams.salesTerritoryName = filter_4["value"];
                            break;
                        case 'customerNumber':
                            if (this.pageContext.pageName === "endUserRequests" || this.pageContext.pageName === "myNotifications" || this.pageContext.pageName === "padetailsSoldTo" || this.pageContext.pageName === "custListModal" || this.pageContext.pageName === "customerSearchModal" || this.pageContext.pageName === 'ucMappingCustomerSearch' || this.pageContext.pageName === 'downloadCustListModal' || this.pageContext.pageName === "mcCustomerDetails" || this.pageContext.pageName === "invoiceSearchResult" || this.pageContext.pageName === "invoiceFavourite" || this.pageContext.pageName === "recentRequests" || this.pageContext.pageName === "unclaimedTileGrid") {
                                filterParams.customerNumber = filter_4["value"];
                            }
                            else if (this.pageContext.pageName === "recentActivity" || this.pageContext.pageName === "pendingTileGrid" || this.pageContext.pageName === "issuedTileGrid" || this.pageContext.pageName === "deniedTileGrid" || this.pageContext.pageName === "searchResultsCB" || this.pageContext.pageName === "mcSearchResult" || this.pageContext.pageName === "efileSearchResult" || this.pageContext.pageName === "kcfileSearchResult") {
                                filterParams.customerNumber = [];
                                filterParams.customerNumber.push(filter_4["value"]);
                            }
                            else if (this.pageContext.pageName === "mcCustomerDetailsSection") {
                                filterParams.customerNumberCBFilter = [];
                                filterParams.customerNumberCBFilter = filter_4["value"];
                            }
                            else if (this.pageContext.pageName === "efileAccessPage") {
                                filterParams.customerNumber = "";
                                filterParams.customerNumber = filter_4["value"];
                            }
                            else {
                                filterParams.customerIds = [];
                                filterParams.customerIds.push(filter_4["value"]);
                            }
                            break;
                        case 'customerName':
                        case 'name1':
                            if (this.pageContext.pageName === "custListModal")
                                filterParams.name = filter_4["value"];
                            else {
                                if (this.pageContext.pageName === "efileSearchResult" || this.pageContext.pageName === "kcfileSearchResult") {
                                    filterParams.customerName = [];
                                    filterParams.customerName.push(filter_4["value"]);
                                }
                                else {
                                    filterParams.customerName = filter_4["value"];
                                }
                            }
                            break;
                        case 'territoryNumber':
                            filterParams.territoryIds = [];
                            filterParams.territoryIds.push(filter_4["value"]);
                            break;
                        case 'territoryName':
                            filterParams.territoryName = filter_4["value"];
                            break;
                        case 'productDescription':
                        case 'ProductDescription':
                        case 'productDesc':
                            if (this.pageContext.pageName === "prodSearchModal")
                                filterParams.productDescription = filter_4["value"];
                            else
                                filterParams.description = filter_4["value"];
                            break;
                        case 'AltProductCode':
                        case 'altProductCode':
                            if (this.pageContext.pageName === "prodSearchModal")
                                filterParams.altProductCode = filter_4["value"];
                            else
                                filterParams.alternateProductCode = filter_4["value"];
                            break;
                        case 'customerStartDate':
                        case 'startDate':
                        case 'validFrom':
                            if (this.pageContext.pageName === "tradeOrgLazyLoading" || this.pageContext.pageName === "padetailendusers" ||
                                this.pageContext.pageName === "padetailproduct" || this.pageContext.pageName === "padetailsSoldTo" || this.pageContext.pageName === "mcProductsDetailsSection") {
                                filterParams.startDate = formatDate(filter_4["value"], 'MM/dd/yyyy', this.locale);
                            }
                            else if (this.pageContext.pageName === "mcSearchResult") {
                                filterParams.active.fromDate = formatDate(filter_4["value"], 'MM/dd/yyyy', this.locale);
                            }
                            else {
                                if (!filterParams.validDate) {
                                    filterParams.validDate = {};
                                }
                                var region = this._globalAppService.getDefaultRegionString();
                                if (region == 'EMEA') {
                                    filterParams.firmValidDate.fromDate = formatDate(filter_4["value"], 'MM/dd/yyyy', this.locale);
                                }
                                else {
                                    filterParams.validDate.fromDate = formatDate(filter_4["value"], 'MM/dd/yyyy', this.locale);
                                }
                            }
                            break;
                        case 'customerEndDate':
                        case 'endDate':
                        case 'validTo':
                            if (this.pageContext.pageName === "tradeOrgLazyLoading" || this.pageContext.pageName === "padetailendusers" ||
                                this.pageContext.pageName === "padetailproduct" || this.pageContext.pageName === "padetailsSoldTo" || this.pageContext.pageName === "mcProductsDetailsSection") {
                                filterParams.endDate = formatDate(filter_4["value"], 'MM/dd/yyyy', this.locale);
                            }
                            else if (this.pageContext.pageName === "mcSearchResult") {
                                filterParams.active.toDate = formatDate(filter_4["value"], 'MM/dd/yyyy', this.locale);
                            }
                            else {
                                if (!filterParams.validDate) {
                                    filterParams.validDate = {};
                                }
                                filterParams.validDate.toDate = formatDate(filter_4["value"], 'MM/dd/yyyy', this.locale);
                            }
                            break;
                        //below field is for EMEA
                        case 'firmEndDate':
                            if (this.pageContext.pageName === 'padetailproduct' || this.pageContext.pageName === 'padetailendusers') {
                                filterParams.firmEndDate = formatDate(filter_4["value"], 'MM/dd/yyyy', this.locale);
                            }
                            else {
                                if (!filterParams.firmValidDate) {
                                    filterParams.firmValidDate = {};
                                }
                                filterParams.firmValidDate.toDate = formatDate(filter_4["value"], 'MM/dd/yyyy', this.locale);
                            }
                            break;
                        case 'lastDownload':
                            filterParams.lastDownloaded = formatDate(filter_4["value"], 'MM/dd/yyyy', this.locale);
                            break;
                        case 'lastChanged':
                        case 'lastChangedDate':
                        case 'lastUpdatedDate':
                            if (this.pageContext.pageName == "padetailproduct" || this.pageContext.pageName == "padetailendusers" || this.pageContext.pageName == 'mcProductsDetailsSection') {
                                filterParams.lastChangedFrom = filter_4["value"].from ? formatDate(filter_4["value"].from, 'MM/dd/yyyy', this.locale) : "";
                                filterParams.lastChangedTo = filter_4["value"].to ? formatDate(filter_4["value"].to, 'MM/dd/yyyy', this.locale) : "";
                            }
                            else if (this.pageContext.pageName == "efileSearchResult" || this.pageContext.pageName == "kcfileSearchResult") {
                                filterParams.lastChanged = formatDate(filter_4["value"], 'MM/dd/yyyy', this.locale);
                            }
                            else if (this.pageContext.pageName == "myNotifications") {
                                filterParams.lastChanged.fromDate = filter_4["value"] ? formatDate(filter_4["value"], 'MM/dd/yyyy', this.locale) : "";
                            }
                            else {
                                if (!filterParams.lastChanged) {
                                    filterParams.lastChanged = {};
                                }
                                filterParams.lastChanged.fromDate = filter_4["value"].from ? formatDate(filter_4["value"].from, 'MM/dd/yyyy', this.locale) : "";
                                filterParams.lastChanged.toDate = filter_4["value"].to ? formatDate(filter_4["value"].to, 'MM/dd/yyyy', this.locale) : "";
                            }
                            break;
                        case 'submitted':
                            filterParams.submitted = formatDate(filter_4["value"], 'MM/dd/yyyy', this.locale);
                            break;
                        case 'typedescription':
                        case 'typeName':
                            filterParams.contractType = [];
                            filter_4["value"].filter(function (f) { return f.selected; }).forEach(function (item) {
                                filterParams.contractType.push(item.value);
                            });
                            break;
                        case 'type':
                            if (this.pageContext.pageName === 'efileSearchResult' || this.pageContext.pageName === 'kcfileSearchResult') {
                                filterParams.type = [];
                                filter_4["value"].filter(function (f) { return f.selected; }).forEach(function (item) {
                                    filterParams.type.push(item.value);
                                });
                            }
                            else {
                                filterParams.contractType = [];
                                filter_4["value"].filter(function (f) { return f.selected; }).forEach(function (item) {
                                    filterParams.contractType.push(item.value);
                                });
                            }
                            break;
                        case 'salesorgName':
                            filterParams.SalesorgName = [];
                            filter_4["value"].filter(function (f) { return f.selected; }).forEach(function (item) {
                                filterParams.SalesorgName.push(item.value);
                            });
                            break;
                        /* TODO pass the Key when avaiable from Dinakaran*/
                        case 'kcOwner':
                            filterParams.kcOwner = [];
                            filter_4["value"].filter(function (f) { return f.selected; }).forEach(function (item) {
                                filterParams.kcOwner.push(item.value);
                            });
                            break;
                        case 'customerType':
                            filterParams.customerType = [];
                            filter_4["value"].filter(function (f) { return f.selected; }).forEach(function (item) {
                                filterParams.customerType.push(item.value);
                            });
                            break;
                        case 'statusText':
                        case 'status':
                        case 'status_Efile':
                            filterParams.status = [];
                            var filteredStatuses = filter_4["value"].filter(function (f) { return f.selected; });
                            filteredStatuses.forEach(function (item) {
                                filterParams.status.push(item.value);
                            });
                            if ((filter_4["value"].length === filteredStatuses.length) && this.pageContext.pageName != 'searchResultsCB')
                                filterParams.status = [];
                            break;
                        // case 'lastDownload':
                        //   filterParams.lastDownload = filter["value"];
                        //   break;
                        case 'change':
                            filterParams.change = [];
                            filter_4["value"].filter(function (f) { return f.selected; }).forEach(function (item) {
                                filterParams.change.push(item.value);
                            });
                            break;
                        case 'moduleType':
                            filterParams.moduleType = [];
                            filter_4["value"].filter(function (f) { return f.selected; }).forEach(function (item) {
                                filterParams.moduleType.push(item.value);
                            });
                            break;
                        case 'lastUpdated':
                        case 'lastChangedDate':
                            if (!filterParams.lastUpdated) {
                                filterParams.lastUpdated = {};
                            }
                            filterParams.lastUpdated.fromDate = filter_4["value"].from ? formatDate(filter_4["value"].from, 'MM/dd/yyyy', this.locale) : "";
                            filterParams.lastUpdated.toDate = filter_4["value"].to ? formatDate(filter_4["value"].to, 'MM/dd/yyyy', this.locale) : "";
                            break;
                        case 'expiring':
                            if (!filterParams.expiring) {
                                filterParams.expiring = {};
                            }
                            filterParams.expiring.fromDate = filter_4["value"].from ? formatDate(filter_4["value"].from, 'MM/dd/yyyy', this.locale) : "";
                            filterParams.expiring.toDate = filter_4["value"].to ? formatDate(filter_4["value"].to, 'MM/dd/yyyy', this.locale) : "";
                            break;
                        case 'endUserId':
                        case 'ag-Grid-AutoColumn':
                            filterParams.endUserId = filter_4["value"];
                            break;
                        case 'ownerName':
                            filterParams.filterOwnerName = filter_4["value"];
                            break;
                        case 'shortProductCode':
                            if (this.pageContext.pageName === "prodSearchModal")
                                filterParams.shortProductCode = filter_4["value"];
                            if (this.pageContext.pageName == 'mcProductsDetailsSection')
                                filterParams.productCode = (filter_4["value"]).join(",");
                            else
                                filterParams.productCode = filter_4["value"];
                            break;
                        case 'itemsPerCase':
                            filterParams.itemsPerCase = filter_4["value"];
                            break;
                        case 'altProd':
                            filterParams.altProd = [];
                            filterParams.altProd.push(filter_4["value"]);
                            break;
                        case 'uom':
                            // filterParams.uom = [];
                            // filter["value"].filter(f => { return f.selected }).forEach(item => {
                            //   filterParams.uom.push(item.value);
                            // });
                            filterParams.uom = filter_4["value"];
                            break;
                        case 'euPrice':
                            filterParams.euPrice = filter_4["value"];
                            break;
                        case 'por':
                            filterParams.por = filter_4["value"];
                            break;
                        case 'volume':
                            filterParams.volume = filter_4["value"];
                            break;
                        case 'ItemsPerUOM':
                        case 'itemsPerUom':
                        case 'itemsPerUOM':
                            filterParams.itemsPerUom = filter_4["value"];
                            break;
                        case 'caseBreakDownUom':
                            filterParams.caseBreakDownUom = filter_4["value"];
                            break;
                        case 'caseBreakDown':
                            filterParams.caseBreakDown = filter_4["value"];
                            break;
                        case 'price':
                            filterParams.price = this.reverseFormatter(filter_4["value"]);
                            break;
                        case 'CommentsDescription':
                        case 'comments':
                        case 'CommentsDescription':
                        case 'comment':
                            if (this.pageContext.pageName === 'padetailproduct' || this.pageContext.pageName === 'padetailsSoldTo' ||
                                this.pageContext.pageName === 'padetailendusers') {
                                filterParams.comments = [];
                                var filteredComments_1 = filter_4["value"].filter(function (f) { return f.selected; });
                                filteredComments_1.forEach(function (item) {
                                    filterParams.comments.push(item.value);
                                });
                                if (filter_4["value"].length === filteredComments_1.length)
                                    filterParams.comments = [];
                            }
                            else if (this.pageContext.pageName === 'mcProductsDetailsSection') {
                                filterParams.comments = [];
                                // filterParams.comments.push(filter["value"])
                                var filteredComments_2 = filter_4["value"].filter(function (f) { return f.selected; });
                                filteredComments_2.forEach(function (item) {
                                    filterParams.comments.push(item.value);
                                });
                            }
                            else {
                                filterParams.comments = filter_4["value"];
                            }
                            break;
                        case 'priceChargeback':
                            filterParams.priceChargeback = filter_4["value"];
                            break;
                        case 'pricePerCase':
                            filterParams.pricePerCase = filter_4["value"];
                            break;
                        case 'caseBreakdownUOM':
                            filterParams.caseBreakdownUOM = filter_4["value"];
                            break;
                        case 'casePrice':
                            filterParams.casePrice = filter_4["value"];
                            break;
                        case 'twelveMonthSales':
                            filterParams.twelveMonthSales = filter_4["value"];
                            break;
                        case 'twelveMonthVolume':
                            filterParams.twelveMonthVolume = filter_4["value"];
                            break;
                        case 'endUserNumber':
                            filterParams.endUserNumber = filter_4["value"];
                            break;
                        case 'fullName':
                            if (this.pageContext.pageName === 'tradeOrgLazyLoading')
                                filterParams.name = filter_4["value"];
                            else
                                filterParams.fullName = filter_4["value"];
                            break;
                        case 'addressLine1':
                        case 'address':
                            filterParams.address = filter_4["value"];
                            break;
                        case 'euNumber':
                            filterParams.euNumber = filter_4["value"];
                            break;
                        case 'companyName':
                            filterParams.companyName = filter_4["value"];
                            break;
                        case 'estimatedUnitCount':
                            filterParams.estimatedUnitCount = filter_4["value"];
                            break;
                        case 'requestedDate':
                            if (this.pageContext.pageName == 'recentRequests') {
                                var formattedDate = formatDate(filter_4["value"], 'yyyy-MM-dd', this.locale);
                                formattedDate = formattedDate + "T00:00:00.000Z";
                                filterParams.requestedDateFrom = formattedDate;
                                filterParams.requestedDateTo = formattedDate;
                            }
                            else {
                                var formattedDate = formatDate(filter_4["value"], 'yyyy-MM-dd', this.locale);
                                formattedDate = formattedDate + "T00:00:00.000Z";
                                filterParams.requestedDate = formattedDate;
                            }
                            break;
                        case 'updatedDate':
                            if (filter_4["type"] === 'date-range-selector') {
                                filterParams.updatedDate = filter_4["value"].from ? formatDate(filter_4["value"].from, 'MM/dd/yyyy', this.locale) : '';
                                // filterParams.updatedDate = filter["value"].from ? formatDate(filter["value"].from, 'MM/dd/yyyy', this.locale) : '';
                            }
                            else if (filter_4['type'] === 'date-selector' || filter_4['type'] === 'date-selectorufn') {
                                filterParams.updatedDate = filter_4["value"] ? formatDate(filter_4["value"], 'MM/dd/yyyy', this.locale) : '';
                            }
                            else
                                filterParams.updatedDate = formatDate(filter_4["value"], 'MM/dd/yyyy', this.locale);
                            break;
                        case 'lastRefresh':
                            filterParams.lastRefresh = formatDate(filter_4["value"], 'yyyy-MM-dd', this.locale);
                            break;
                        case 'lastRefreshDate':
                            filterParams.lastRefresh = formatDate(filter_4["value"], 'yyyy-MM-dd', this.locale);
                            break;
                        case 'updateDate':
                            if (filter_4["type"] === 'date-range-selector') {
                                filterParams.updateDate = filter_4["value"].from ? formatDate(filter_4["value"].from, 'MM/dd/yyyy', this.locale) : '';
                                // filterParams.updatedDate = filter["value"].from ? formatDate(filter["value"].from, 'MM/dd/yyyy', this.locale) : '';
                            }
                            else
                                filterParams.updateDate = formatDate(filter_4["value"], 'MM/dd/yyyy', this.locale);
                            break;
                        case 'changeReason':
                            filterParams.changeReason = filter_4["value"];
                            break;
                        case 'endUserName':
                            filterParams.endUserName = filter_4["value"];
                            break;
                        case 'taxNumber':
                            filterParams.taxNumber = filter_4["value"];
                            break;
                        case 'city':
                            filterParams.city = filter_4["value"];
                            break;
                        case 'state':
                            if (this.pageContext.pageName === 'mcCustomerDetails' || this.pageContext.pageName === 'mcCustomerDetailsSection'
                                || this.pageContext.pageName === 'invoiceSearchResult' || this.pageContext.pageName === 'invoiceFavourite'
                                || this.pageContext.pageName === 'recentRequests') {
                                filterParams.state = [];
                                var filteredItems = filter_4["value"].filter(function (f) { return f.selected; });
                                filteredItems.forEach(function (item) {
                                    filterParams.state.push(item.value);
                                });
                            }
                            else {
                                filterParams.state = filter_4["value"];
                            }
                            break;
                        case 'country':
                            if (this.pageContext.pageName === 'ucMappingCustomerSearch' || this.pageContext.pageName === 'customerSearchModal' || this.pageContext.pageName === 'endUserSearchModal' || this.pageContext.pageName === 'tradeHQModal' || this.pageContext.pageName === 'invoiceFavourite' || this.pageContext.pageName === 'invoiceSearchResult' || this.pageContext.pageName === 'unclaimedTileGrid' || this.pageContext.pageName === 'recentRequests') {
                                filterParams.country = [];
                                var filteredItems = filter_4["value"].filter(function (f) { return f.selected; });
                                filteredItems.forEach(function (item) {
                                    filterParams.country.push(item.value);
                                });
                            }
                            break;
                        case 'phone':
                            filterParams.phone = filter_4["value"];
                            break;
                        case 'postalCode':
                            filterParams.postalCode = filter_4["value"];
                            break;
                        case 'zipCode':
                            filterParams.zipCode = filter_4["value"];
                            break;
                        case 'zipcode':
                            filterParams.zipcode = filter_4["value"];
                            break;
                        case 'tax':
                            filterParams.tax = filter_4["value"];
                            break;
                        case 'customerType':
                            if (this.pageContext.pageName === 'ucMappingCustomerSearch') {
                                filterParams.customerType = [];
                                var filteredItems = filter_4["value"].filter(function (f) { return f.selected; });
                                filteredItems.forEach(function (item) {
                                    filterParams.customerType.push(item.value);
                                });
                            }
                            else
                                filterParams.customerType = filter_4["value"];
                            break;
                        case 'tradeOrgNumber':
                            filterParams.tradeOrgNumber = filter_4["value"];
                            break;
                        case 'tradeOrgName':
                            filterParams.tradeOrgName = filter_4["value"];
                            break;
                        case 'categoryDescription':
                            if (this.pageContext.pageName === "prodSearchModal")
                                filterParams.categoryDescription = filter_4["value"];
                            break;
                        case 'userId':
                            filterParams.userId = filter_4["value"];
                            break;
                        case 'firstName':
                            filterParams.firstName = filter_4["value"];
                            break;
                        case 'lastName':
                            filterParams.lastName = filter_4["value"];
                            break;
                        case 'email':
                            filterParams.email = filter_4["value"];
                            break;
                        case 'passwordExpiryDate':
                            filterParams.passwordExpiryDate = formatDate(filter_4["value"], 'MM/dd/yyyy', this.locale);
                            break;
                        case 'lastLoginDate':
                            filterParams.lastLoginDate = formatDate(filter_4["value"], 'MM/dd/yyyy', this.locale);
                            break;
                        case 'loginCount':
                            filterParams.loginCount = this.reverseFormatter(filter_4["value"]);
                            break;
                        case 'committedVolume':
                            filterParams.committedVolume = this.reverseFormatter(filter_4["value"]);
                            break;
                        case 'couponNumber':
                            filterParams.couponNumber = filter_4["value"];
                            break;
                        case 'referenceNumber':
                            filterParams.referenceNumber = filter_4["value"];
                            break;
                        case 'claimNumber':
                            if (this.pageContext.pageName === 'searchResultsCB') {
                                filterParams.claimNumbers = [];
                                filterParams.claimNumbers.push(filter_4["value"]);
                            }
                            else {
                                filterParams.claimNumber = filter_4["value"];
                            }
                            break;
                        case 'claimPeriod':
                        case 'claimStartDate':
                            if (filter_4["type"] === 'date-range-selector') {
                                filterParams.claimPeriod.fromDate = filter_4["value"].from ? formatDate(filter_4["value"].from, 'MM/dd/yyyy', this.locale) : '';
                                filterParams.claimPeriod.toDate = filter_4["value"].to ? formatDate(filter_4["value"].to, 'MM/dd/yyyy', this.locale) : '';
                            }
                            else if (filter_4['type'] === 'date-selector' || filter_4['type'] === 'date-selectorufn') {
                                filterParams.claimPeriod = filter_4["value"] ? formatDate(filter_4["value"], 'MM/dd/yyyy', this.locale) : '';
                            }
                            else
                                filterParams.claimPeriod = formatDate(filter_4["value"], 'MM/dd/yyyy', this.locale);
                            break;
                        case 'issueDate':
                        case 'issuePeriod':
                            if (filter_4["type"] === 'date-range-selector') {
                                console.log("issuePeriod", filter_4["value"]);
                                filterParams.issuePeriod.fromDate = filter_4["value"].from ? formatDate(filter_4["value"].from, 'MM/dd/yyyy', this.locale) : '';
                                filterParams.issuePeriod.toDate = filter_4["value"].to ? formatDate(filter_4["value"].to, 'MM/dd/yyyy', this.locale) : '';
                            }
                            else if (filter_4['type'] === 'date-selector' || filter_4['type'] === 'date-selectorufn') {
                                filterParams.issuePeriod.fromDate = filter_4["value"] ? formatDate(filter_4["value"], 'MM/dd/yyyy', this.locale) : '';
                            }
                            else
                                filterParams.issuePeriod = formatDate(filter_4["value"], 'MM/dd/yyyy', this.locale);
                            break;
                        case 'submittedDate':
                            filterParams.submittedPeriod.fromDate = filter_4["value"] ? formatDate(filter_4["value"], 'MM/dd/yyyy', this.locale) : '';
                            break;
                        case 'receivedDate':
                            filterParams.received = formatDate(filter_4["value"], 'MM/dd/yyyy', this.locale);
                            break;
                        case 'availableDate':
                            filterParams.available = formatDate(filter_4["value"], 'MM/dd/yyyy', this.locale);
                            break;
                        case 'uploaded':
                            filterParams.uploaded = formatDate(filter_4["value"], 'MM/dd/yyyy', this.locale);
                            break;
                        case 'claimRequestType':
                            filterParams.requestType = [];
                            filter_4["value"].filter(function (f) { return f.selected; }).forEach(function (item) {
                                filterParams.requestType.push(item.value);
                            });
                            break;
                        case 'rvRb':
                            filterParams.reverseBillBack = [];
                            filter_4["value"].filter(function (f) { return f.selected; }).forEach(function (item) {
                                filterParams.reverseBillBack.push(item.value);
                            });
                            break;
                        case 'claimPeriodMonthAndYear':
                            filterParams.claimPeriodMonthAndYear = [];
                            filter_4["value"].filter(function (f) { return f.selected; }).forEach(function (item) {
                                filterParams.claimPeriodMonthAndYear.push(item.value);
                            });
                            break;
                        case 'currency':
                            filterParams.currency = [];
                            filter_4["value"].filter(function (f) { return f.selected; }).forEach(function (item) {
                                filterParams.currency.push(item.value);
                            });
                            break;
                        case 'custEU':
                            filterParams.custEU = filter_4["value"];
                            break;
                        case 'custEuDetails':
                            filterParams.custEuDetails = filter_4["value"];
                            break;
                        case 'kCEU':
                            filterParams.kCEU = filter_4["value"];
                            break;
                        case 'kCEUDetails':
                            filterParams.kCEUDetails = filter_4["value"];
                            break;
                        case 'requested':
                            filterParams.requested = filter_4["value"];
                            break;
                        case 'ActiveLines':
                            filterParams.ActiveLines = parseInt(filter_4["value"], 10);
                            break;
                        case 'issued':
                            filterParams.issued = filter_4["value"];
                            break;
                        case 'discrepancies':
                            filterParams.discrepancies = [];
                            filter_4["value"].filter(function (f) { return f.selected; }).forEach(function (item) {
                                filterParams.discrepancies.push(item.value);
                            });
                            break;
                        case 'lines':
                            if (this.pageContext.pageName === 'efileSearchResult' || this.pageContext.pageName === 'kcfileSearchResult') {
                                filterParams.lines = parseInt(filter_4["value"], 10);
                            }
                            else {
                                filterParams.lines = filter_4["value"];
                            }
                            filterParams.lines = filter_4["value"];
                            break;
                        case 'errors':
                            filterParams.errors = parseInt(filter_4["value"], 10);
                            ;
                            break;
                        case 'row':
                            // filterParams.row = filter["value"];
                            filterParams.row = [];
                            var filteredComments = filter_4["value"].filter(function (f) { return f.selected; });
                            filteredComments.forEach(function (item) {
                                filterParams.row.push(item.value);
                            });
                            break;
                        case 'field':
                            filterParams.field = filter_4["value"];
                            break;
                        case 'fieldName':
                            filterParams.fieldName = filter_4["value"];
                            break;
                        case 'error':
                            filterParams.error = filter_4["value"];
                            break;
                        case 'shortProductCode':
                            filterParams.shortProductCode = filter_4["value"];
                            break;
                        case 'productCode':
                            filterParams.productCode = filter_4["value"];
                            break;
                        case 'access':
                            var selectedItem = filter_4["value"].filter(function (item) {
                                return item.selected;
                            });
                            if (selectedItem.length) {
                                var selected = selectedItem.map(function (selection) {
                                    return selection.value;
                                });
                                filterParams.access = selected.join();
                            }
                            else
                                filterParams.access = "";
                            break;
                        case 'refreshStatus':
                            filterParams.refreshStatus = [];
                            filter_4["value"].filter(function (f) { return f.selected; }).forEach(function (item) {
                                filterParams.refreshStatus.push(item.value);
                            });
                            break;
                        case 'unclaimedMonth':
                            filterParams.unclaimedMonth = [];
                            filter_4["value"].filter(function (f) { return f.selected; }).forEach(function (item) {
                                filterParams.unclaimedMonth.push(item.value);
                            });
                            break;
                        case 'kcFileID':
                            filterParams.kcFileID = filter_4["value"];
                            break;
                        case 'kcFileName':
                            filterParams.kcFileName = filter_4["value"];
                            break;
                        case 'nextAvailableDate':
                            filterParams.nextAvailable = formatDate(filter_4["value"], 'yyyy-MM-dd', this.locale);
                            break;
                        case 'categoryList':
                            filterParams.categoryList = [];
                            filter_4["value"].filter(function (f) { return f.selected; }).forEach(function (item) {
                                filterParams.categoryList.push(item.value);
                            });
                            break;
                    }
                }
            }
        }
        return filterParams;
    };
    CpAgGridService.prototype.getFilterRequestObject = function () {
        switch (this.pageContext.pageName) {
            case 'searchResults':
                {
                    var filterRequest = Object.assign({}, new PASearchQuery(), { searchView: (this.pageContext.request && this.pageContext.request.query ? this.pageContext.request.query.searchView : "") });
                    return filterRequest;
                }
            case 'myNotifications':
                return new MyNotificationQuery();
            case 'activeFuture':
            case 'recentlyChanged':
            case 'recentlyExpired':
            case 'expiringSoon':
            case 'paFavourite':
                {
                    var filterRequest = Object.assign({}, new PASearchQuery(), {
                        dashboardTilesType: this.getTileType(),
                        searchView: (this.pageContext.request && this.pageContext.request.query ? this.pageContext.request.query.searchView : ""),
                        dataDurationType: (this.pageContext.request && this.pageContext.request.query ? this.pageContext.request.query.dataDurationType : "")
                    });
                    return filterRequest;
                }
            case 'tradeOrgLazyLoading':
            case 'tradeOrg':
                {
                    var filterRequest = Object.assign({}, new TradeOrgEndUserRequest(), {
                        contractNumber: (this.pageContext.request && this.pageContext.request.query ? this.pageContext.request.query.contractNumber : ""),
                        customerNumber: (this.pageContext.request && this.pageContext.request.query ? this.pageContext.request.query.customerNumber : ""),
                        paStatus: (this.pageContext.request && this.pageContext.request.query ? this.pageContext.request.query.paStatus : ""),
                        searchView: (this.pageContext.request && this.pageContext.request.query ? this.pageContext.request.query.searchView : ""),
                        dataDurationType: (this.pageContext.request && this.pageContext.request.query ? this.pageContext.request.query.dataDurationType : "")
                    });
                    return filterRequest;
                }
            case 'padetailproduct':
                {
                    // let filterRequest = Object.assign({}, new PAProductQuery(), { searchView: (this.pageContext.request && this.pageContext.request.query ? this.pageContext.request.query.searchView : "") });;
                    // To Do : Uncomment below lines once api changes are done, comment above line
                    var filterRequest = Object.assign({}, new PAProductQuery(), {
                        filterView: (this.pageContext.request && this.pageContext.request.query ? this.pageContext.request.query.filterView : ""),
                        dashboardType: (this.pageContext.request && this.pageContext.request.query ? parseInt(this.pageContext.request.query.dashboardType, 10) : 0),
                        searchView: (this.pageContext.request && this.pageContext.request.query ? this.pageContext.request.query.searchView : ""),
                        dataDurationType: (this.pageContext.request && this.pageContext.request.query ? this.pageContext.request.query.dataDurationType : "")
                    });
                    return filterRequest;
                }
            case 'padetailendusers':
                {
                    // let filterRequest = Object.assign({}, new PAEndUserQuery(), { searchView: (this.pageContext.request && this.pageContext.request.query ? this.pageContext.request.query.searchView : "") });;
                    // To Do : Uncomment below lines once api changes are done, comment above line
                    var filterRequest = Object.assign({}, new PAEndUserQuery(), {
                        filterView: (this.pageContext.request && this.pageContext.request.query ? this.pageContext.request.query.filterView : ""),
                        dashboardType: (this.pageContext.request && this.pageContext.request.query ? parseInt(this.pageContext.request.query.dashboardType, 10) : 0),
                        searchView: (this.pageContext.request && this.pageContext.request.query ? this.pageContext.request.query.searchView : ""),
                        dataDurationType: (this.pageContext.request && this.pageContext.request.query ? this.pageContext.request.query.dataDurationType : "")
                    });
                    return filterRequest;
                }
            case 'custListModal':
                {
                    var filterRequest = new GetCustomerInfoFilter();
                    return filterRequest;
                }
            case 'prodSearchModal':
                {
                    var filterRequest = new ProductSearchFilter();
                    return filterRequest;
                }
            case 'endUserSearchModal':
                {
                    var filterRequest = new endusersearchFilter();
                    return filterRequest;
                }
            case 'customerSearchModal':
                {
                    var filterRequest = new CustomerSearchFilter();
                    return filterRequest;
                }
                ;
            case 'tradeHQModal':
                {
                    var filterRequest = new hqModalFilter();
                    return filterRequest;
                }
                ;
            case 'padetailsSoldTo':
                {
                    var filterRequest = new SoldToQuery();
                    return filterRequest;
                }
                ;
            case 'endUserRequests':
                return new EndUserRequestsQuery();
            case 'ucMappingSearchUsers':
                return new UserSearchFilter();
            case 'delegatedSearchUsers':
                return new DelegatedUserSearchFilter();
            case 'ucMappingCustomerSearch':
                return new UcMappingCustomerSearchFilter();
            case 'downloadCustListModal':
                return new DownloadCustomerFilter();
            case 'multipleRefNumber':
                return new EfileKcfileMultipleRefNumberFilter();
            case 'multipleClaimNumber':
                return new CBMultipleClaimNumberFilter();
            case 'portalUsageInternal':
                return new PuSearchFilter();
            case 'searchResultsCB':
                {
                    var filterRequest = Object.assign({}, new CBSearchFilter());
                    return filterRequest;
                }
            case 'kcfileSearchResult':
                {
                    var filterRequest = Object.assign({}, new CBKcfileSearchFilter(), { searchView: (this.pageContext.request && this.pageContext.request.query ? this.pageContext.request.query.searchView : "") });
                    return filterRequest;
                }
            case 'efileSearchResult':
                {
                    var filterRequest = Object.assign({}, new CBEfileSearchFilter(), { searchView: (this.pageContext.request && this.pageContext.request.query ? this.pageContext.request.query.searchView : "") });
                    return filterRequest;
                }
            case 'mcSearchResult':
                {
                    var filterRequest = Object.assign({}, new MCSearchFilter());
                    return filterRequest;
                }
            case 'recentActivity':
            case 'pendingTileGrid':
            case 'issuedTileGrid':
            case 'deniedTileGrid':
                {
                    var filterRequest = Object.assign({}, new CBTilesFilter());
                    return filterRequest;
                }
            case 'summaryResultsCB':
                {
                    var filterRequest = Object.assign({}, new CBCouponSummaryResultFilter());
                    return filterRequest;
                }
            case 'eFileErrorSummary':
                {
                    var filterRequest = Object.assign({}, new CBEfileErrorSummaryResultFilter());
                    return filterRequest;
                }
            case 'mcCustomerDetails':
                {
                    var filterRequest = Object.assign({}, new MCCustomerDetailsFilter());
                    return filterRequest;
                }
            case 'mcCustomerDetailsSection':
                {
                    var filterRequest = Object.assign({}, new MCCustomerDetailsFilter());
                    return filterRequest;
                }
            case 'mcProductsDetailsSection':
                {
                    var filterRequest = Object.assign({}, new MCProductsDetailsFilter());
                    return filterRequest;
                }
            case 'efileAccessPage':
                {
                    var filterRequest = Object.assign({}, new EfileKcfileAccessSearchFilter());
                    return filterRequest;
                }
            case 'defaultCustomer':
                {
                    var filterRequest = Object.assign({}, new InvoiceCustomerSearchFilter());
                    return filterRequest;
                }
            case 'invoiceSearchResult':
                {
                    var filterRequest = Object.assign({}, new InvoiceCustomerSearchFilter());
                    return filterRequest;
                }
            case 'invoiceFavourite':
                {
                    var filterRequest = Object.assign({}, new InvoiceCustomerSearchFilter());
                    return filterRequest;
                }
            case 'unclaimedTileGrid': {
                var filterRequest = Object.assign({}, new UnclaimedTileFilter());
                filterRequest.unclaimedMonth = this.pageContext.request.filter.unclaimedMonth;
                return filterRequest;
            }
            case 'recentRequests': {
                var filterRequest = Object.assign({}, new InvoiceCustomerSearchFilter());
                return filterRequest;
            }
            case 'kcFileErrorSummary':
                {
                    var filterRequest = Object.assign({}, new CBKcfileErrorSummaryResultFilter());
                    return filterRequest;
                }
            case 'kcfilePASearchResults':
                {
                    var filterRequest = Object.assign({}, new KcfilePASearchQuery());
                    return filterRequest;
                }
        }
    };
    CpAgGridService.prototype.getSortRequestParams = function () {
        var _this = this;
        var sortParams;
        var sortModel = this.getSortModel();
        if (sortModel && sortModel.length > 0) {
            sortParams = [];
            sortModel.forEach(function (sm) {
                var colId = sm.colId;
                if (_this.pageContext.pageName != "endUserSearchModal" && _this.pageContext.pageName != "" && _this.pageContext.pageName != "tradeHQModal") {
                    if (sm.colId.indexOf('_') !== -1) {
                        colId = sm.colId.substring(0, sm.colId.indexOf('_'));
                    }
                }
                if (colId == 'ag-Grid-AutoColumn') {
                    colId = 'endUserId';
                }
                sortParams.push(colId + ' ' + sm.sort);
            });
        }
        else {
            sortParams = [];
        }
        return sortParams;
    };
    CpAgGridService.prototype.getFilterModel = function () {
        var filterModel;
        if (this.gridRowModelType === 'serverSide')
            filterModel = this.params && this.params.request ? this.params.request.filterModel : null;
        else
            filterModel = this.params ? this.params.filterModel : null;
        return filterModel;
    };
    CpAgGridService.prototype.getSortModel = function () {
        var sortModel;
        if (this.gridRowModelType === 'serverSide')
            sortModel = this.params && this.params.request ? this.params.request.sortModel : null;
        else
            sortModel = this.params ? this.params.sortModel : null;
        return sortModel;
    };
    CpAgGridService.prototype.makeHttpSvcCall = function () {
        // TK To Do: Add page specific URLs, Page Names here
        // Pls use the same pageName when you set the pageContext
        switch (this.pageContext.pageName) {
            case 'searchResults':
                {
                    this.gridName = "searchResults";
                    return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
                }
            case 'activeFuture':
            case 'recentlyChanged':
            case 'recentlyExpired':
            case 'expiringSoon':
            case 'paFavourite': {
                this.gridName = "activeFuture";
                return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
            }
            case 'tradeOrg': {
                this.gridName = "tradeOrg";
                return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
            }
            case 'tradeOrgLazyLoading': {
                this.gridName = "tradeOrgLazyLoading";
                return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
            }
            case 'padetailproduct': {
                this.gridName = "padetailproduct";
                return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
            }
            case 'padetailendusers': {
                this.gridName = "padetailendusers";
                return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
            }
            case 'prodSearchModal': {
                this.gridName = "prodSearchModal";
                return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
            }
            case 'custListModal': {
                this.gridName = "custListModal";
                return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
            }
            case 'endUserSearchModal': {
                this.gridName = "endUserSearchModal";
                return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
            }
            case 'endUserRequests': {
                this.gridName = "endUserRequests";
                return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
            }
            case 'tradeHQModal': {
                this.gridName = "tradeHQModal";
                return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
            }
            case 'customerSearchModal': {
                this.gridName = "customerSearchModal";
                return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
            }
            case 'recentDownload': {
                this.gridName = "recentDownload";
                return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.pageContext.request);
            }
            case 'myNotifications': {
                this.gridName = "myNotifications";
                return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
            }
            case 'padetailsSoldTo': {
                this.gridName = "padetailsSoldTo";
                return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
            }
            case 'ucMappingSearchUsers': {
                this.gridName = "ucMappingSearchUsers";
                return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
            }
            case 'delegatedSearchUsers': {
                this.gridName = "delegatedSearchUsers";
                return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
            }
            case 'ucMappingCustomerSearch': {
                this.gridName = "ucMappingCustomerSearch";
                return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
            }
            case 'myProfile': {
                this.gridName = "myProfile";
                //below code is to set preferences from featurelist api in grid api when not available in response
            }
            case 'myProfileInternal': {
                this.gridName = "myProfileInternal";
            }
            case 'ucMappings': {
                this.gridName = "ucMappings";
                return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
            }
            case 'customerMappings': {
                this.gridName = 'customerMappings';
                return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
            }
            case 'downloadCustListModal': {
                this.gridName = 'downloadCustomerList';
                return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
            }
            case 'multipleRefNumber': {
                this.gridName = 'multipleRefNumber';
                return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
            }
            case 'multipleClaimNumber': {
                this.gridName = 'multipleClaimNumber';
                return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
            }
            case 'portalUsageInternal':
                {
                    this.gridName = 'portalUsageInternal';
                    return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
                }
            case 'searchResultsCB':
                {
                    this.gridName = "searchResultsCB";
                    this.getMultiselectDistinctFilterOptions(this.oRequest, this.gridName, "CB");
                    return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
                }
            case 'recentActivity':
                {
                    this.gridName = "recentActivity";
                    this.getMultiselectDistinctFilterOptions(this.oRequest, this.gridName, "CB");
                    return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
                }
            case 'pendingTileGrid':
                {
                    this.gridName = "pendingTileGrid";
                    this.getMultiselectDistinctFilterOptions(this.oRequest, this.gridName, "CB");
                    return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
                }
            case 'issuedTileGrid':
                {
                    this.gridName = "issuedTileGrid";
                    this.getMultiselectDistinctFilterOptions(this.oRequest, this.gridName, "CB");
                    return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
                }
            case 'deniedTileGrid':
                {
                    this.gridName = "deniedTileGrid";
                    this.getMultiselectDistinctFilterOptions(this.oRequest, this.gridName, "CB");
                    return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
                }
            case 'summaryResultsCB':
                {
                    this.gridName = "summaryResultsCB";
                    this.getMultiselectDistinctFilterOptions(this.oRequest, this.gridName, "CB");
                    return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
                }
            case 'couponSummaryGrid':
                {
                    this.gridName = "couponSummaryGrid";
                    return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
                }
            case 'endUserDetailsGridCB':
                {
                    this.gridName = "endUserDetailsGridCB";
                    //return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
                }
            case 'efileSearchResult':
                {
                    this.gridName = "efileSearchResult";
                    this.getMultiselectDistinctFilterOptions(this.oRequest, this.gridName, "CB");
                    return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
                }
            case 'kcfileSearchResult':
                {
                    this.gridName = "kcfileSearchResult";
                    this.getMultiselectDistinctFilterOptions(this.oRequest, this.gridName, "CB");
                    return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
                }
            case 'mcSearchResult':
                {
                    this.gridName = "mcSearchResult";
                    this.getMultiselectDistinctFilterOptions(this.oRequest, this.gridName, "MC");
                    return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
                }
            case 'eFileErrorSummary':
                {
                    this.gridName = "eFileErrorSummary";
                    this.getMultiselectDistinctFilterOptions(this.oRequest, this.gridName, "CB");
                    return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
                }
            case 'ManageError':
                {
                    this.gridName = "ManageError";
                    return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
                }
            case 'efileHistoryLog':
                {
                    this.gridName = "efileHistoryLog";
                    return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
                }
            case 'mcCustomerDetails':
                {
                    this.gridName = "mcCustomerDetails";
                    this.getMultiselectDistinctFilterOptions(this.oRequest, this.gridName, "MC");
                    return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
                }
            case 'mcCustomerDetailsSection':
                {
                    this.gridName = "mcCustomerDetailsSection";
                    this.getMultiselectDistinctFilterOptions(this.oRequest, this.gridName, "MC");
                    return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
                }
            case 'mcProductsDetailsSection':
                {
                    this.gridName = "mcProductsDetailsSection";
                    this.getMultiselectDistinctFilterOptions(this.oRequest, this.gridName, "MC");
                    return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
                }
            case 'advancedInvoice':
                {
                    this.gridName = "advancedInvoice";
                    var advancedInvoiceGridData = this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
                    var requestObj = {
                        userId: this.appService.getuserId(false)
                    };
                    var getFavoriteCategories = this.httpServiceIP.getFavoriteInvoiceCategory(requestObj);
                    return forkJoin([advancedInvoiceGridData, getFavoriteCategories]);
                    //return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
                }
            case 'advancedInvoiceNew':
                {
                    this.gridName = "advancedInvoiceNew";
                    var advancedInvoiceGridData = this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
                    var requestObj = {
                        userId: this.appService.getuserId(false)
                    };
                    var getFavoriteCategories = this.httpServiceIPNew.getFavoriteInvoiceCategory(requestObj);
                    return forkJoin([advancedInvoiceGridData, getFavoriteCategories]);
                }
            case 'getReports':
                {
                    this.gridName = "getReports";
                    return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
                }
            case 'efileAccessPage':
                {
                    this.gridName = "efileAccessPage";
                    this.getMultiselectDistinctFilterOptions(this.oRequest, this.gridName, "EA");
                    return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
                }
            case 'defaultCustomer': {
                this.gridName = "defaultCustomer";
                return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
            }
            case 'invoiceSearchResult': {
                this.gridName = "invoiceSearchResult";
                this.getMultiselectDistinctFilterOptions(this.oRequest, this.gridName, "InvoicePriceNew");
                return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
            }
            case 'invoiceFavourite': {
                this.gridName = "invoiceFavourite";
                this.getMultiselectDistinctFilterOptions(this.oRequest, this.gridName, "InvoicePriceNew");
                return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
            }
            case 'unclaimedTileGrid': {
                this.gridName = "unclaimedTileGrid";
                this.getMultiselectDistinctFilterOptions(this.oRequest, this.gridName, "CB");
                return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
            }
            case 'recentRequests': {
                this.gridName = "recentRequests";
                this.getMultiselectDistinctFilterOptions(this.oRequest, this.gridName, "InvoicePriceRecentRequests");
                return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
            }
            case 'kcFileErrorSummary':
                {
                    this.gridName = "kcFileErrorSummary";
                    this.getMultiselectDistinctFilterOptions(this.oRequest, this.gridName, "CB");
                    return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
                }
            case 'kcfileHistoryLog':
                {
                    this.gridName = "kcfileHistoryLog";
                    return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
                }
            case 'ManageErrorKcFile':
                {
                    this.gridName = "ManageErrorKcFile";
                    return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
                }
            case 'kcfilePASearchResults':
                {
                    this.gridName = "kcfilePASearchResults";
                    return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
                }
        }
    };
    CpAgGridService.prototype.getMultiselectDistinctFilterOptions = function (searchRequest, gridName, moduleName) {
        var _this = this;
        if (moduleName === void 0) { moduleName = "CB"; }
        if (moduleName == "CB") {
            this.httpServiceCB.cbGetDistinctFilterSearchGrid(searchRequest, gridName).subscribe(function (data) {
                _this.updateDistinctFilters(gridName, data);
            });
        }
        else if (moduleName == "MC") {
            this.httpServiceMC.mcGetDistinctFilterForGrid(searchRequest, gridName).subscribe(function (data) {
                _this.updateDistinctFilters(gridName, data);
            });
        }
        else if (moduleName == "EA") {
            this.httpServiceEA.eaGetDistinctFilterForGrid(searchRequest, gridName).subscribe(function (data) {
                _this.updateDistinctFilters(gridName, data);
            });
        }
        else if (moduleName == "InvoicePriceNew") {
            this.httpServiceIPNew.invoiceGetDistinctFilterForGrid(searchRequest, gridName).subscribe(function (data) {
                _this.updateDistinctFilters(gridName, data);
            });
        }
        else if (moduleName == "InvoicePriceRecentRequests") {
            this.httpServiceIPNew.recentRequestGetDistinctFilterForGrid(searchRequest, gridName).subscribe(function (data) {
                _this.updateDistinctFilters(gridName, data);
            });
        }
    };
    CpAgGridService.prototype.updateDistinctFilters = function (gridName, data) {
        if (data) {
            this.multiSelectDistinctFilter[gridName] = {};
            for (var colName in data) {
                this.multiSelectDistinctFilter[gridName][colName] = data[colName];
            }
            this.setColumnMultiselectFilter("filterUpdated");
        }
    };
    CpAgGridService.prototype.getMultiSelectDistinctFilter = function () {
        return this.multiSelectDistinctFilter;
    };
    CpAgGridService.prototype.setColumnMultiselectFilter = function (value) {
        this.columnMultiselectFilter.next(value);
    };
    CpAgGridService.prototype.getColumnMultiselectFilter = function () {
        return this.columnMultiselectFilter.asObservable();
    };
    CpAgGridService.prototype.getLastRowIndex = function (currentPage, totalCount) {
        var lastRow = -1;
        if (totalCount <= this.serverPageSize)
            lastRow = totalCount;
        else {
            if (currentPage * this.serverPageSize < totalCount)
                lastRow = -1;
            else
                lastRow = totalCount;
        }
        return lastRow;
    };
    CpAgGridService.prototype.emitTotalRecordCount = function (count) {
        this.recordCount.next(count);
    };
    CpAgGridService.prototype.hideLoadingOverlay = function () {
        this.gridApi.hideOverlay();
    };
    CpAgGridService.prototype.makePageReady = function () {
        this.gridApi.hideOverlay();
    };
    CpAgGridService.prototype.sortHandler = function (colId, sortOrder) {
        if (sortOrder) {
            this.isSorted = true;
            this.gridApi.deselectAll();
            this.gridApi.setSortModel([{ sort: sortOrder, colId: colId }]);
        }
        else {
            this.isSorted = false;
            this.gridApi.setSortModel(null);
        }
        this.setReloadGrid(true);
    };
    CpAgGridService.prototype.rowSelectionChanged = function (row) {
        if (row.data) {
            var index = this._selectedRows && this._selectedRows.length > 0 ? this._selectedRows.map(function (r) { return r.id; }).indexOf(row.data.id) : -1;
            if (row.node.selected) {
                if (index === -1)
                    this._selectedRows.push({ id: row.data.id, contractNumber: row.data.contractNumber, customerNumber: row.data.customerNumber });
            }
            else {
                this._selectedRows.splice(index, 1);
            }
        }
    };
    CpAgGridService.prototype.getSelectedRows = function () {
        return this._selectedRows;
    };
    CpAgGridService.prototype.getSvcRequest = function () {
        return Object.assign({}, this.oRequest);
    };
    //checkbox selection code
    CpAgGridService.prototype.mapSelectedRowToArray = function (rowData, _selectedRowData) {
        var startRowIndex = rowData.api.getFirstDisplayedRow();
        var lastRowIndex = rowData.api.getLastDisplayedRow();
        if (rowData.node.selected) {
            _selectedRowData.push(rowData.rowIndex);
            this.setHeaderCheckboxStatus(startRowIndex, lastRowIndex, _selectedRowData);
        }
        else {
            _selectedRowData.splice(_selectedRowData.indexOf(rowData.rowIndex), 1);
            this.setHeaderCheckboxStatus(startRowIndex, lastRowIndex, _selectedRowData);
        }
    };
    CpAgGridService.prototype.setHeaderCheckboxStatus = function (start, end, _selectedRowData) {
        var maxRowsInPage = end - start;
        var init = -1;
        for (var ctr = start; ctr <= end; ctr++) {
            if (_selectedRowData.indexOf(ctr) > -1) {
                init++;
            }
        }
        if (init === maxRowsInPage) {
            this.setRowCheckboxSelection("SelectAll");
        }
        else if (init > -1) {
            this.setRowCheckboxSelection("SelectPartial");
        }
        else {
            this.setRowCheckboxSelection("SelectNone");
        }
    };
    CpAgGridService.prototype.onPaginationChanged = function (event, _selectedRowData) {
        var startRowIndex = event.api.getFirstDisplayedRow();
        var lastRowIndex = event.api.getLastDisplayedRow();
        if (lastRowIndex > 0) {
            this.setHeaderCheckboxStatus(startRowIndex, lastRowIndex, _selectedRowData);
        }
    };
    //checkbox selection ends
    CpAgGridService.prototype.gridRowClass = function () {
        var region = this._globalAppService.getDefaultRegionString();
        return {
            "statusExpiredRow": function (rowNode) {
                if (rowNode.data && (rowNode.data.status || rowNode.data.statusText || rowNode.data.statusId)) {
                    return rowNode.data.status === 'DEN' || rowNode.data.status === 'Expired' || rowNode.data.status === 'Denied' || rowNode.data.statusText === 'Expired' || rowNode.data.statusId === 2 || rowNode.data.status === 3 || rowNode.data.efileStatusId === 2;
                }
            },
            "statusFutureRow": function (rowNode) {
                if (rowNode.data && (rowNode.data.status || rowNode.data.statusText || rowNode.data.statusId)) {
                    return rowNode.data.status === 'Future' || rowNode.data.statusText === 'Future' || rowNode.data.statusId === 3;
                }
            },
            "statusCompleteRow": function (rowNode) {
                if (rowNode.data && (rowNode.data.status || rowNode.data.statusText || rowNode.data.StatusDesc || rowNode.data.status)) {
                    return rowNode.data.status === 'COM' || rowNode.data.status === 'Completed' || rowNode.data.status === 'Complete' || rowNode.data.statusText === 'COM' || rowNode.data.statusText === 'Completed' || rowNode.data.statusText === 'Complete' || rowNode.data.StatusDesc === 'Completed' || rowNode.data.status === 2 || rowNode.data.efileStatusId === 4;
                }
            },
            "statusGlobalUserLastLoginRow": function (rowNode) {
                if (rowNode.data && rowNode.data.lastLoginDate) {
                    return rowNode.data.lastLoginDate;
                }
            },
            "globalUserDisableRadio": function (rowNode) {
                switch (region.toUpperCase()) {
                    case 'LAO':
                        if (rowNode.data && !rowNode.data.lastLoginDate) {
                            return rowNode.data && !rowNode.data.lastLoginDate;
                        }
                        break;
                    case 'NA':
                    case 'EMEA':
                        if (rowNode.data && !rowNode.data.lastLoginDate && !rowNode.data.isPolicyAccepted) {
                            return rowNode.data && !rowNode.data.lastLoginDate && !rowNode.data.isPolicyAccepted;
                        }
                        break;
                }
            },
            "statusDeniedRow": function (rowNode) {
                if (rowNode.data && (rowNode.data.status || rowNode.data.statusText)) {
                    return rowNode.data.status === 'B' || rowNode.data.status === 'Denied';
                }
            },
            "statusPendingRowCB": function (rowNode) {
                if (rowNode.data && (rowNode.data.status || rowNode.data.statusText)) {
                    return rowNode.data.status === 'PD' || rowNode.data.status === 'Pending';
                }
            }
        };
    };
    CpAgGridService.prototype.saveStateList = function (states) {
        this.stateList.next(states);
        this.stateListArray = states;
    };
    CpAgGridService.prototype.getStateList = function () {
        return this.stateList.asObservable();
    };
    CpAgGridService.prototype.setCountries = function (value) {
        this._countries = value;
    };
    CpAgGridService.prototype.getCountries = function () {
        return this._countries;
    };
    CpAgGridService.prototype.reverseFormatter = function (filterValue) {
        var valueToFilter = filterValue;
        if (filterValue) {
            switch (this._globalAppService.getDefaultRegionString()) {
                case 'LAO':
                    {
                        var regexToMatch = /^(\d{1,3}(.?\d{3})*?(\,\d{1,})?)$|^(\d{1,}\,?\d{1,2})$/g;
                        // const found = filterValue.match(regexToMatch);
                        if (filterValue.match(regexToMatch)) {
                            var str2 = filterValue.replace(/\./g, '@');
                            var str3 = str2.replace(/,/g, '.');
                            var str4 = str3.replace(/@/g, '');
                            valueToFilter = str4;
                        }
                        else {
                            valueToFilter = filterValue;
                        }
                    }
                    break;
                case 'NA':
                    {
                        valueToFilter = filterValue;
                    }
                    break;
            }
        }
        return valueToFilter;
    };
    CpAgGridService.ngInjectableDef = i0.defineInjectable({ factory: function CpAgGridService_Factory() { return new CpAgGridService(i0.inject(i1.HttpService), i0.inject(i0.LOCALE_ID), i0.inject(i2.GlobalAppService), i0.inject(i3.TranslateService), i0.inject(i4.AppService), i0.inject(i5.HttpChargeBackService), i0.inject(i6.HttpMeetCompService), i0.inject(i7.HttpInvoicePriceService), i0.inject(i1.HttpService), i0.inject(i8.HttpInvoicePriceNewService), i0.inject(i9.Router), i0.inject(i9.ActivatedRoute)); }, token: CpAgGridService, providedIn: "root" });
    return CpAgGridService;
}());
export { CpAgGridService };
