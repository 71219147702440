import { Component } from '@angular/core';
import { EfileKcfileMultipleCustomers, EfileKcfileMultipleCustomersFilter } from '@app/Modules/Shared/DataModels/SharedDataModel';
import { AgRendererComponent } from 'ag-grid-angular';

@Component({
  selector: 'app-kcfile-multiple-popup',
  templateUrl: './kcfile-multiple-popup.component.html',
  styleUrls: ['./kcfile-multiple-popup.component.scss']
})
export class KCFileMultiplePopup implements AgRendererComponent {

  constructor() { }
  params: any;
  customerDetails;
  multipleCustomerRequest: any;
  moduleName: string;
  showModal: boolean = false;
  
  agInit(params: any): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    return false;
  }

  onLinkClicked(){
    let request: EfileKcfileMultipleCustomers = new EfileKcfileMultipleCustomers();
    request.query = new Object();
    this.moduleName = "CB-FILE";
    if(this.params && this.params.data && this.params.data.eFileID){
      request.query.eFileID = this.params.data.eFileID;
      
    } else if(this.params && this.params.data && this.params.data.kcFileID){
      request.query.eFileID = this.params.data.kcFileID;
      request.query.isKCFile = true;
    }
    request.filter = new EfileKcfileMultipleCustomersFilter();
    this.multipleCustomerRequest = request;
    this.showModal = true;
  }

  closedCustModal(event) {
    this.showModal = (event.close === 'true');
  }


}
