/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./hamburger.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./hamburger.component";
import * as i5 from "../../Services/language-selection.service";
import * as i6 from "../../Services/menu-toggler.service";
import * as i7 from "../../Services/http.service";
import * as i8 from "@angular/router";
import * as i9 from "../../Services/global-app.service";
import * as i10 from "../../Services/location-language.service";
import * as i11 from "../../Services/app.service";
var styles_HamburgerComponent = [i0.styles];
var RenderType_HamburgerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HamburgerComponent, data: {} });
export { RenderType_HamburgerComponent as RenderType_HamburgerComponent };
function View_HamburgerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "header__nav__professional-link"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "a", [["rel", "noopener noreferrer"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "far fa-arrow-alt-circle-right"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.kcProfessionalLink, ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("humburger.kcpTitle")); _ck(_v, 2, 0, currVal_1); }); }
function View_HamburgerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "loadingImage"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u00A0 "]))], null, null); }
function View_HamburgerComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "side-menu-flag"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "assets/images/international-flags/", _co.selecteCountry, ".svg"); _ck(_v, 0, 0, currVal_0); }); }
function View_HamburgerComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [["class", "header__nav__has-sub-nav"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.passclicked("region") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HamburgerComponent_4)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(4, null, [" ", " - ", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showTopHeader; _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.selecteCountryLabel; var currVal_2 = _co.preferredLanguageLabel; _ck(_v, 4, 0, currVal_1, currVal_2); }); }
function View_HamburgerComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fas fa-user side-menu-i"]], null, null, null, null, null))], null, null); }
function View_HamburgerComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [["class", "header__nav__has-sub-nav"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.passclicked("account") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HamburgerComponent_6)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(4, null, [" ", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showTopHeader; _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co._username; _ck(_v, 4, 0, currVal_1); }); }
export function View_HamburgerComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { mainElementRef: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 26, "div", [["class", "header__nav"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HamburgerComponent_1)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, [[1, 0], ["mainTag", 1]], null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HamburgerComponent_2)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 20, "div", [["class", "d-md-none"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 19, "ul", [["class", "unlisted"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HamburgerComponent_3)), i1.ɵdid(10, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 4, "li", [["class", "header__nav__external_usr"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 3, "span", [["class", "hamburger_external_user"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "i", [["class", "fas fa-user-friends"]], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, [" ", " ", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HamburgerComponent_5)), i1.ɵdid(17, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(18, 0, null, null, 4, "li", [["class", "header__nav__settings"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.passclicked("settings") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 0, "i", [["class", "fas fa-cog"]], null, null, null, null, null)), (_l()(), i1.ɵted(21, null, [" ", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(23, 0, null, null, 4, "li", [["class", "header__nav__support"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.passclicked("support") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 0, "i", [["class", "fas fa-question"]], null, null, null, null, null)), (_l()(), i1.ɵted(26, null, [" ", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.showTopHeader == false); _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.menu; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.showTopHeader; _ck(_v, 10, 0, currVal_2); var currVal_5 = _co.showTopHeader; _ck(_v, 17, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 15).transform("header.loggedInAs")); var currVal_4 = _co._simUserId; _ck(_v, 14, 0, currVal_3, currVal_4); var currVal_6 = i1.ɵunv(_v, 21, 0, i1.ɵnov(_v, 22).transform("humburger.settingsLabel")); _ck(_v, 21, 0, currVal_6); var currVal_7 = i1.ɵunv(_v, 26, 0, i1.ɵnov(_v, 27).transform("humburger.supportLabel")); _ck(_v, 26, 0, currVal_7); }); }
export function View_HamburgerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-hamburger", [], null, null, null, View_HamburgerComponent_0, RenderType_HamburgerComponent)), i1.ɵdid(1, 245760, null, 0, i4.HamburgerComponent, [i1.Renderer2, i1.ElementRef, i5.LanguageSelectionService, i6.MenuTogglerService, i7.HttpService, i8.Router, i9.GlobalAppService, i10.LocationLanguageService, i11.AppService, i2.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HamburgerComponentNgFactory = i1.ɵccf("app-hamburger", i4.HamburgerComponent, View_HamburgerComponent_Host_0, { selecteCountry: "selecteCountry", selecteCountryLabel: "selecteCountryLabel", preferredLanguageLabel: "preferredLanguageLabel", _username: "_username", _simUserId: "_simUserId" }, { clickedValue: "clickedValue" }, []);
export { HamburgerComponentNgFactory as HamburgerComponentNgFactory };
