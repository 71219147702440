<a *ngIf="params && params.data && params.data.lines > 0 || (params.colDef.headerName == 'agGrid.agGridColumns.claimFileIDLabel' && params.data && (params.data.claimRequestType == '6' || params.data.claimRequestType == '2'))" href="javascript:void(0)"
    (click)="onKcfileNameClick()" title={{params.value}}>{{params.value}}</a>

<span *ngIf="this.params.colDef.headerName == 'agGrid.agGridColumns.claimFileIDLabel' && params.data && params.data.claimRequestType != '6' && params.data.claimRequestType != '2'">{{params.value}}</span>

<span *ngIf="params && params.data && params.data.lines == 0" title={{params.value}}>{{params.value}}</span>
<p-toast key="kcfileDownload" position="center" [style]="{ marginTop : '100px', width: '400px' }" [closable]="true" [baseZIndex]="500" [autoZIndex]="100" [hideTransitionOptions]="'250ms'">
    <ng-template let-message pTemplate="message">
        <div style="text-align: center; padding-left: 20px;">
            <h3>{{message.summary | translate}}</h3>
            <p>{{message.detail | translate}}</p>
        </div>
    </ng-template>
</p-toast>