import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { OktaAuthService } from '@okta/okta-angular';
import { GlobalAppService } from '@app/Modules/Shared/Services/global-app.service';
import { blockedModules } from '@env/environment';

@Injectable()
export class BlockModules implements CanActivate {
    constructor(private globalAppService: GlobalAppService) { }
    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        let isBlocked = false;
        const blockModules = new Observable<boolean>((observer) => {
            switch (route.routeConfig.path) {
                case "chargeback":
                    isBlocked = this.globalAppService.blockModules("CB");
                    break;
                case "invoice":
                    isBlocked = this.globalAppService.blockModules("IN");
                    break;
                case "efilekcfileaccess":
                    isBlocked = this.globalAppService.blockModules("EA");
                    break;
                default:

            }
            return observer.next(!isBlocked);
        });
        return blockModules;
    }
}

