let countryOBJ = [
  //NA
  {
    'countryID': 'ca',
    'url': 'https://www.kcprofessional.com/'
  },
  {
    'countryID': 'us',
    'url': 'https://www.kcprofessional.com/'
  },
  //EMEA
  {
    'countryID': 'ad',
    'url': 'https://www.kcprofessional.es/'
  },
  {
    'countryID': 'al',
    'url': 'https://www.kcprofessional.eu/'
  },
  {
    'countryID': 'am',
    'url': 'https://www.kcprofessional.ru/'
  },
  {
    'countryID': 'at',
    'url': 'https://www.kcprofessional.de/'
  },
  {
    'countryID': 'az',
    'url': 'https://www.kcprofessional.eu/'
  },
  {
    'countryID': 'ba',
    'url': 'https://www.kcprofessional.eu/'
  },
  {
    'countryID': 'be',
    'url': 'https://www.kcprofessional.nl/'
  },
  {
    'countryID': 'bg',
    'url': 'https://www.kcprofessional.eu/'
  },
  {
    'countryID': 'by',
    'url': 'https://www.kcprofessional.ru/'
  },
  {
    'countryID': 'ch',
    'url': 'https://www.kcprofessional.de/'
  },
  {
    'countryID': 'cy',
    'url': 'https://www.kcprofessional.eu/'
  },
  {
    'countryID': 'cz',
    'url': 'https://www.kcprofessional.cz/'
  },
  {
    'countryID': 'de',
    'url': 'https://www.kcprofessional.de/'
  },
  {
    'countryID': 'dk',
    'url': 'https://www.kcprofessional.no/'
  },
  {
    'countryID': 'ee',
    'url': 'https://www.kcprofessional.eu/'
  },
  {
    'countryID': 'es',
    'url': 'https://www.kcprofessional.es/'
  },
  {
    'countryID': 'fi',
    'url': 'https://www.kcprofessional.no/'
  },
  {
    'countryID': 'fr',
    'url': 'https://www.kcprofessional.fr/'
  },
  {
    'countryID': 'gb',
    'url': 'https://www.kcprofessional.co.uk/'
  },
  {
    'countryID': 'ge',
    'url': 'https://www.kcprofessional.eu/'
  },
  {
    'countryID': 'gh',
    'url': ''//NA in Catalog
  },
  {
    'countryID': 'gr',
    'url': 'https://www.kcprofessional.eu/'
  },
  {
    'countryID': 'hr',
    'url': 'https://www.kcprofessional.eu/'
  },
  {
    'countryID': 'hu',
    'url': 'https://www.kcprofessional.eu/'
  },
  {
    'countryID': 'ic',
    'url': '' //NA in catalog
  },
  {
    'countryID': 'is',
    'url': 'https://www.kcprofessional.no/'
  },
  {
    'countryID': 'it',
    'url': 'https://www.kcprofessional.it/'
  },
  {
    'countryID': 'kz',
    'url': 'https://www.kcprofessional.ru/'
  },
  {
    'countryID': 'lt',
    'url': 'https://www.kcprofessional.eu/'
  },
  {
    'countryID': 'lu',
    'url': '"https://www.kcprofessional.fr/'
  },
  {
    'countryID': 'lv',
    'url': 'https://www.kcprofessional.eu/'
  },
  {
    'countryID': 'ma',
    'url': '' //NA in catalog
  },
  {
    'countryID': 'mc',
    'url': '"https://www.kcprofessional.fr/'
  },
  {
    'countryID': 'md',
    'url': 'https://www.kcprofessional.eu/'
  },
  {
    'countryID': 'mk',
    'url': 'https://www.kcprofessional.eu/'
  },
  {
    'countryID': 'mt',
    'url': 'https://www.kcprofessional.eu/'
  },
  {
    'countryID': 'nl',
    'url': 'https://www.kcprofessional.nl/'
  },
  {
    'countryID': 'no',
    'url': 'https://www.kcprofessional.no/'
  },
  {
    'countryID': 'pl',
    'url': 'https://www.kcprofessional.pl/'
  },
  {
    'countryID': 'pt',
    'url': 'https://www.kcprofessional.pt/'
  },
  {
    'countryID': 'ro',
    'url': 'https://www.kcprofessional.eu/'
  },
  {
    'countryID': 'rs',
    'url': 'https://www.kcprofessional.eu/'
  },
  {
    'countryID': 'ru',
    'url': 'https://www.kcprofessional.ru/'
  },
  {
    'countryID': 'se',
    'url': 'https://www.kcprofessional.no/'
  },
  {
    'countryID': 'si',
    'url': 'https://www.kcprofessional.eu/'
  },
  {
    'countryID': 'sk',
    'url': 'https://www.kcprofessional.eu/'
  },
  {
    'countryID': 'sm',
    'url': 'https://www.kcprofessional.it/'
  },
  {
    'countryID': 'tn',
    'url': ''//NA in catalog
  },
  {
    'countryID': 'tr',
    'url': 'https://www.kcprofessional.eu/'
  },
  {
    'countryID': 'ua',
    'url': '' //NA in catalog
  },
  //LAO
  {
    'countryID': 'ar',
    'url': 'https://www.kcprofessional.com.ar/'
  },
  {
    'countryID': 'br',
    'url': 'https://www.kcprofessional.com.br/'
  },
  {
    'countryID': 'co',
    'url': 'https://www.kcprofessional.com.co/'
  },
  {
    'countryID': 'cr',
    'url': 'https://www.kcprofessional.cr/'
  },
  {
    'countryID': 'pe',
    'url': 'https://www.kcprofessional.com.pe/'
  },
  //APAC
  {
    'countryID': 'au',
    'url': 'https://www.kcprofessional.com.au/'
  },
  {
    'countryID': 'bn',
    'url': 'https://www.kcprofessional.com.my/'
  },
  {
    'countryID': 'kh',
    'url': 'https://www.th.kcprofessional.com/'
  },
  {
    'countryID': 'gu',
    'url': 'https://www.kcprofessional.com.ph/'
  },
  {
    'countryID': 'hk',
    'url': 'https://www.kcprofessional.com.hk/'
  },
  {
    'countryID': 'in',
    'url': 'https://www.kcprofessional.co.in/'
  },
  {
    'countryID': 'id',
    'url': 'https://www.kcprofessional.co.id/'
  },
  {
    'countryID': 'mo',
    'url': 'https://www.kcprofessional.com.hk/'
  },
  {
    'countryID': 'my',
    'url': 'https://www.kcprofessional.com.my/'
  },
  {
    'countryID': 'mv',
    'url': 'https://www.kcprofessional.com.sg/'
  },
  {
    'countryID': 'mm',
    'url': 'https://www.th.kcprofessional.com/'
  },
  {
    'countryID': 'nz',
    'url': 'https://www.kcprofessional.com.au/'
  },
  {
    'countryID': 'ph',
    'url': 'https://www.kcprofessional.com.ph/'
  },
  {
    'countryID': 'sg',
    'url': 'https://www.kcprofessional.com.sg/'
  },
  {
    'countryID': 'tw',
    'url': 'https://www.kcprofessional.com.tw/'
  },
  {
    'countryID': 'vn',
    'url': 'https://www.th.kcprofessional.com/'
  },
  {
    'countryID': 'th',
    'url': 'https://www.th.kcprofessional.com/'
  },
  {
    'countryID': 'la',
    'url': 'https://www.th.kcprofessional.com/'
  }
];


export {
  countryOBJ
};
