import { Component, OnDestroy } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { cbEfileStatusForActive, cbEfileStatusForTest, cbKcfileStatusExternalUser, cbKcfileStatusInternalUser, submitText } from '@app/conf/dataConfigurations';
import { HttpChargeBackService } from '@app/Modules/Shared/Services/http-charge-back.service';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { MessageService } from 'primeng/api';
import { CbService } from '@app/Modules/chargeback/Services/cb.service';
import { GlobalAppService } from '@app/Modules/Shared/Services/global-app.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageSelectionService } from '@app/Modules/Shared/Services/language-selection.service';

@Component({
  selector: 'app-kcfile-cb-status-column',
  templateUrl: './kcfile-status-column.component.html',
  styleUrls: ['./kcfile-status-column.component.scss']
})

export class KcfileStatusColumnComponent implements AgRendererComponent, OnDestroy {

  params: any;
  statusDefault: boolean = false;
  statusError: boolean = false;
  statusExtensiveError: boolean = false;
  statusReadyToSubmit: boolean = false;
  
  cbKcfileStatus: any;
  statusValue: any;
  isKcfileSubmitted: boolean = false;
  isKcfileDeleted: boolean = false;
  isFileSubmittedOrDeleted: boolean = false;
  submitText: any;
  isGlobalUser: boolean = false;
  region: string = '';
  statusText: string = '';
  constructor(
    private httpServ: HttpChargeBackService,
    private appServ: AppService,
    private messageService: MessageService,
    private CbServ: CbService,
    private globalAppService: GlobalAppService,
    private translateService: TranslateService,
    private languageService: LanguageSelectionService
  ) {
    this.cbKcfileStatus = [ ...cbKcfileStatusInternalUser, ...cbKcfileStatusExternalUser ];
  }


  agInit(params: any) {
    this.isGlobalUser = this.appServ.isGlobalUser;
    this.region = this.globalAppService.getDefaultRegionString().toUpperCase();
    this.params = params;
    if (params.data && params.data.status) {
      this.status();
      switch (params.data.status) {
        case "1"://Validated
          if(this.params.data.errors && this.params.data.errors > 50){
            this.extensiveErrorsFound();
          }
          else{
            this.errorsFound();
          }
          break;
        case "4"://Ready to submit
          this.readyToSubmit();
          break;
        default:
          this.commonStyling();
      }
    }
    //below code changes status column text to 'deleted' after kcfile has been deleted clicking on contextmenu link.
    this.CbServ.getDeletedStatusText().subscribe(data => {
      if (data) {
        if(this.params.rowIndex === data.rowIndex){
          this.statusValue = 'dataConfigData.cbKcfileStatus.Deleted_key';
          this.isKcfileDeleted = true;
          this.isFileSubmittedOrDeleted = true;
        }
      }
    })

    //below code changes status column text to 'submitted' after kcfile has been submitted clicking on contextmenu link.
    this.CbServ.getSubmitStatusText().subscribe(data => {
      if (data) {
        if (this.params.rowIndex === data.rowIndex) {
          this.statusValue = 'dataConfigData.cbKcfileStatus.Submitted_key';
          this.isKcfileSubmitted = true;
          this.isFileSubmittedOrDeleted = true;
          this.CbServ.setSubmitContextMenu(true);
        }
      }
    });

    this.languageService.getlanguage().subscribe(language => {
      if(language){
        this.updateTranslatedValue(); 
      }      
    });
  }

  refresh(params: any): boolean {
    return false;
  }

  errorsFound() {
    this.statusError = true;
  }

  extensiveErrorsFound() {
    this.statusExtensiveError = true;
  }

  readyToSubmit() {
    this.statusReadyToSubmit = true;
  }

  commonStyling() {
    this.statusDefault = true;
  }

  submitKcfile(){
    this.appServ.setLoadingStatus(true);
    let request = {
      "claimFileId": this.params.data.kcFileID,
      "salesOrgId": this.params.data.salesOrgId,
      "isDeleted": false, //for submit this should be false for delete this should be true
      "kcFileStatus": 5 //this is enum for "Submitted" status and should always be passed as 5 only, for delete this parameter can be skipped
    }; 
    this.httpServ.updateCBKcfileStatus(request).subscribe(data => 
      {
        if(data){ 
          this.isKcfileSubmitted = true;
          this.isFileSubmittedOrDeleted = true;
          this.statusValue = 'dataConfigData.cbKcfileStatus.Submitted_key';
          this.CbServ.setSubmitContextMenu(true);// this is to change 'readyToSubmit' context menu to 'submit' context menu
          this.CbServ.initiateFileSubmit(request);
        }
      },
      (e) => {
        this.messageService.add({ detail: 'An unexpected error occurred. Please try again.', summary: 'Error', severity: 'error' })
      },
      () => {
        this.appServ.setLoadingStatus(false);
      });    
  }

  status() {
    let status = this.cbKcfileStatus.find((item) => {
      return item.value === this.params.data.status;
    });
    if(status.value === '4'){
      this.statusValue = submitText;
      this.updateTranslatedValue();
    }
    else{
      this.statusValue = status.label;
      this.updateTranslatedValue();
    }
    
  }

  updateTranslatedValue(){
    this.translateService.get([this.statusValue]).subscribe(translations =>{
      this.statusText = translations[this.statusValue];      
    });
  }

  showTitleMessage() {
    if(this.statusText && this.statusText.length > 16){
      return true
    } else {
      return false;
    }  
  }

  ngOnDestroy() {
    // Required for unsubscribing and destroying the component  
  }

}
