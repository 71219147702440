declare global {
  interface Window { Walkme_variables: any; region: string }
}

import { Keepalive } from '@ng-idle/keepalive';
import { EventTargetInterruptSource, Idle } from '@ng-idle/core';
import { headerLinks } from '@app/conf/appLink';
import { cookieSIMID, globalUser, IdleTimeOutConfig, environment, APP_LEGACY_MENU_HOST } from '@env/environment';
import { Component, OnInit, Renderer2, Inject, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageSelectionService } from '@app/Modules/Shared/Services/language-selection.service';
import { ContentAreaService } from '@app/Modules/Shared/Services/content-area.service';
import { Router, RouterEvent, NavigationStart, NavigationEnd, NavigationCancel, NavigationError, ActivatedRoute } from '@angular/router';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { OktaAuthService } from '@okta/okta-angular';
import { DOCUMENT, DomSanitizer } from '@angular/platform-browser';
import { HttpService } from './Modules/Shared/Services/http.service';
import { OktaProfile } from './Modules/Shared/DataModels/SharedDataModel';
import { forkJoin, Observable, Observer, Subscription } from 'rxjs';
import { MonitoringService } from './Modules/Shared/Services/monitoring.service';
import { Guid } from "guid-typescript";
import { RegionCheckService } from '@app/Modules/Shared/Services/region-check.service';
import { GlobalAppService } from './Modules/Shared/Services/global-app.service';
import { MessageService } from 'primeng/api';
import { SignalRMaster } from './Modules/Shared/Services/SingnalR.master.service';


@Component({
  selector: 'app-home',
  templateUrl: './AppHome.component.html',
  styleUrls: ['./AppHome.component.scss'],
  providers: [MonitoringService, MessageService]
})
export class AppHomecomponent implements OnInit {

  response: any[];
  blurActive: boolean;
  title: string = 'internatalizationLanguageSelection';
  loading: boolean = true;
  isAuthenticated: boolean = false;
  showAppHeader: boolean = true;
  showAppFooter: boolean = false;
  oktaProfile: OktaProfile;
  idleState = 'NOT_STARTED';
  timedOut = false;
  region: any;
  lang: string;
  forceHideHeader: boolean;
  forceHideFooter: boolean;
  // visibleDownloadModal = false;
  profileInfo: any;
  isGlobalUSer: boolean = false;
  // policyContent: any;
  // disableNotNow: any;
  // showAcceptancePolicy: boolean;
  // isAccepted: boolean = false;
  country: any;
  acceptancePolicyResponse: any;
  subscription: Subscription = new Subscription();
  language: any;
  showModal: boolean;

  constructor(private translate: TranslateService,
    private languageSelected: LanguageSelectionService,
    private contentAreaServ: ContentAreaService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private httpServ: HttpService,
    private _globalAppService: GlobalAppService,
    private _appService: AppService,
    private _oktaAuth: OktaAuthService,
    private renderer2: Renderer2, private moniterServ: MonitoringService,
    private element: ElementRef, private idle: Idle, private keepalive: Keepalive,
    private regionServ: RegionCheckService,
    @Inject(DOCUMENT) private _document,
    private sanitizer: DomSanitizer,
    private signalRService: SignalRMaster,
    private messageService: MessageService) {
    this.signalRService.getMessageObservable().subscribe((response: any) => {
      if (response == 'start') {
        this.messageService.add({ key: 'information', severity: 'info', detail: 'signalRMessages.dnlStartMsg', life: 10000 });
      } else if (response) {
        let fileURL = response.fileUrl ? response.fileUrl : response.FileUrl;
        if (fileURL) {
          let fileLink = "<a href='" + fileURL + "' target='_blank'>" + response.FileName + "</a>";
          this.messageService.add({ key: 'dnlSuccess', severity: 'success', summary: 'signalRMessages.dnlSuccessMsg', detail: fileLink, sticky: true });
        } else if (response.fileMessage && response.fileMessage.indexOf("File too large to download") > -1) {
          this.messageService.add({ key: 'information', severity: 'error', detail: 'signalRMessages.fileTooLargeMsg', life: 10000 });
        } else {
          this.messageService.add({ key: 'information', severity: 'error', detail: response.statusCode + '.errorMessage', life: 10000 });
        }
      }
    });
    this._oktaAuth.$authenticationState.subscribe((isAuthenticated: boolean) => {
      this.isAuthenticated = isAuthenticated;
      let me = this;
      this.initializeDasboard().subscribe(data => {
        if (data) {
          this.appStartup(isAuthenticated, "CONSTRUCTOR");
        } else {
          me.setRecentDownloadContext();
          this.router.navigate(['select-region']);
        }
      });

      this._globalAppService.getAppStart().subscribe((data) => {
        if (data) {
          this.appStartup(isAuthenticated, "ONINIT");
        }
      })
    });
  }

  setRecentDownloadContext() {
    let currentNavigation: any = this.router.getCurrentNavigation();
    if (currentNavigation && currentNavigation.extractedUrl && currentNavigation.extractedUrl.queryParams && currentNavigation.extractedUrl.root && currentNavigation.extractedUrl.root.children.primary) {
      let path: string = currentNavigation.extractedUrl.root.children.primary.segments[currentNavigation.extractedUrl.root.children.primary.segments.length - 1].path;
      if (path === 'recentDownload') {
        sessionStorage.setItem('recentDownloadUrl', currentNavigation.extractedUrl.root.children.primary.segments[currentNavigation.extractedUrl.root.children.primary.segments.length - 1].path)
        sessionStorage.setItem('recentDownloadParams', JSON.stringify(currentNavigation.extractedUrl.queryParams));
      }
    }
  }

  async isTokenReceived() {
    const accessToken = await this._oktaAuth.getAccessToken();
    const idToken = await this._oktaAuth.getIdToken();
  }
  ngOnInit() {
    this.region = this._globalAppService.getDefaultRegionString();
    this.isTokenReceived();
    this._globalAppService.getDefaultRegion().subscribe(() => {
      setTimeout(() => {
        this.getLocationAndlanguage();
        if (this.region != 'EMEA') {
          this._globalAppService.notifyWalkMeStart().subscribe(data => {
            this.walkmeSetup(this.profileInfo);
          });
        }
      });

    });
    //TODO Add subscription for Delegated User

    this._oktaAuth.isAuthenticated().then((isAuthenticated: boolean) => {
      this.isAuthenticated = isAuthenticated;
      this.initializeDasboard().subscribe(data => {
        if (data) {
          this.appStartup(isAuthenticated, "ONINIT");
        } else {
          this.router.navigate(['select-region']);
        }
      });
      this._globalAppService.getAppStart().subscribe((data) => {
        if (data) {
          this.appStartup(isAuthenticated, "ONINIT");
        }
      })
    })

    this.router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event)
    });

    this.contentAreaServ.currentBlurStatus.subscribe(
      data => this.blurActive = data
    );
    this._appService.getLoadingStatus().subscribe(
      (value) => {
        setTimeout(() => {
          // console.log('6666', value);
          this.loading = value
        });
      }
    )

    // forkJoin([this._appService.getAcceptancePolicyModalVisibility(), this._appService.getPopUpSequenceStatus()]).subscribe(
    //   (response)=>{
    //     let modalVisibilityResponse = response[0];        
    //     let popUpSequenceStatus = response[1];
    //     this.showModal = modalVisibilityResponse.forceShowModal && modalVisibilityResponse.action === "footerLinkClick";
    //     switch (popUpSequenceStatus) {
    //       case 1:
    //         this.makeAcceptancePolicyCall();
    //         break;

    //       default:
    //         break;
    //     }
    //   }
    // )

  }

  getLocationAndlanguage() {
    this.region = this._globalAppService.getDefaultRegionString();

    let selectedLocation = this._globalAppService.getSelectedCountryLang();
    this.language = selectedLocation && selectedLocation.language ? selectedLocation.language : this._globalAppService.getDefaultLanguageString();
    this.country = selectedLocation && selectedLocation.country ? selectedLocation.country : this._globalAppService.getDefaultCountryString()

    this.getGlobalUserFlag();
  }

  appStartup(UserAuthenticated: boolean, StartPoint: String) {
    if (UserAuthenticated) {
      this.httpServ.getUserDetails().subscribe(userDetails => {
        this._globalAppService.setUserDetails(userDetails.result);
        let regionId = (userDetails.result.preferredRegion === null) ?
          (userDetails.result.regionId ? userDetails.result.regionId.toUpperCase() : null) :
          (userDetails.result.preferredRegion ? userDetails.result.preferredRegion.toUpperCase() : null);;
        let country = (userDetails.result.preferredCountry === null) ? userDetails.result.country : userDetails.result.preferredCountry;
        let language = (userDetails.result.preferredLanguage === null) ? userDetails.result.defaultLanguage : userDetails.result.preferredLanguage;

        let defaultRegion = this._globalAppService.getDefaultRegionString();
        defaultRegion = defaultRegion ? defaultRegion.toUpperCase() : null;
        if (regionId === defaultRegion) {
          this._globalAppService.setDefaultCountry(country);
          this._globalAppService.setDefaultLanguage(language);
          // setTimeout(() => this.setMasterDetailsLocations() );
          // this.appPopupModulator(defaultRegion, this._appService.isInternalUser ? 'internalUser' : 'externalUser');
        }
        else {
          // setTimeout(() => this.setMasterDetailsLocations());
          this._globalAppService.getMasterDataDetails().subscribe((data) => {
            if (data && data.response && data.response.length) {
              //below changes are for ticket no 24304
              const getCountryLanguage = localStorage.getItem('country-lang');
              if (getCountryLanguage) {
                const countryId = getCountryLanguage.substring(getCountryLanguage.indexOf('-') + 1, getCountryLanguage.length);
                const languageCode = getCountryLanguage.substring(0, getCountryLanguage.indexOf('-'));
                this._globalAppService.setDefaultCountry(countryId);
                this._globalAppService.setDefaultLanguage(languageCode);
              }
              else {
                let myObj = data.response.find(element => {
                  return element.regionId.toUpperCase() == this._globalAppService.getDefaultRegionString().toUpperCase();
                });
                this._globalAppService.setDefaultCountry(myObj.country[0].countryId);
                this._globalAppService.setDefaultLanguage(myObj.country[0].salesOrg[0].language[0].languageCode);
              }

            }
          });
        }
      });

      this._appService.getOktaProfile().subscribe(data => {
        if (!this._appService.getCookie(cookieSIMID) && !this._appService.isGlobalUser) {
          this.walkmeSetup(data);
        }
      })
      this._oktaAuth.getAccessToken().then((token) => {
        this._appService.navigateToLanding(token);
        this.sessionSetup();
      });
    }
  }

  // appPopupModulator(region: string, userType: string){
  //   this._appService.setPopupSequenceNumber(popupSequence[region.toUpperCase()][userType]);
  //   if(this._appService.popupSequenceNumber[0]){
  //     this._appService.setPopUpSequenceStatus(this._appService.getPopupSequenceNumber());
  //   }
  // }

  sessionSetup() {
    // sets an idle timeout of given time.
    this.idle.setIdle(IdleTimeOutConfig.setIdle);
    // sets a timeout period given time.
    this.idle.setTimeout(IdleTimeOutConfig.setTimeout);
    // sets the interrupts like Keydown, scroll, mouse wheel, mouse down, and etc
    this.idle.setInterrupts([
      new EventTargetInterruptSource(
        this.element.nativeElement, 'keydown DOMMouseScroll mousewheel mousedown touchstart touchmove scroll')]);

    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = 'NO_LONGER_IDLE';
    });

    this.idle.onTimeout.subscribe(() => {
      this.idleState = 'TIMED_OUT';
      this.timedOut = true;
      // location.href = headerLinks.logoutLink;
      this.router.navigateByUrl("/logout");
    });

    this.idle.onIdleStart.subscribe(() => {
      this.idleState = 'IDLE_START';
      // this.visibleDownloadModal = true;
    });

    this.idle.onTimeoutWarning.subscribe((countdown: any) => {
      this.idleState = 'IDLE_TIME_IN_PROGRESS';
      // this.visibleDownloadModal = true
    });

    // sets the ping interval to given time
    this.keepalive.interval(IdleTimeOutConfig.setInterval);

    this.reset();
  }

  walkmeSetup(oktaProfile) {
    // // console.log("walk me Step 1", oktaProfile && oktaProfile.sAMAccountName);
    if (oktaProfile && oktaProfile.sAMAccountName) {
      // // console.log("walk me Step 2");
      let Walkme_variables = { 'appRole': [''], 'userID': '', 'firstTimeLogin': '', userType: '', 'region': '' }
      this.httpServ.getWelcomeFlag(oktaProfile.sAMAccountName).subscribe(d => {
        // // console.log("walk me Step 3 - inside welcome flag call");
        Walkme_variables.appRole = oktaProfile.approles;
        Walkme_variables.userID = oktaProfile.sAMAccountName;
        Walkme_variables.firstTimeLogin = d.show_welcome_message;
        Walkme_variables.userType = (oktaProfile.userType == 'C' || oktaProfile.userType == 'E' ? "internal" : "external")
        Walkme_variables.region = this._globalAppService.getDefaultRegionString();
        window.Walkme_variables = Walkme_variables || {};
        window.region = this._globalAppService.getDefaultRegionString();
        // // console.log("walk me Step 4", window.Walkme_variables);
        // this.walkmeSCript();
      })
    }

  }

  initializeDasboard(): Observable<boolean> {
    const loadFlag = new Observable<boolean>((observer) => {
      this._oktaAuth.getAccessToken().then((token) => {
        if (token) {
          this.httpServ.getUserInfo(token).subscribe((profile: OktaProfile) => {
            if (profile) {
              // this._appService.setOktaProfile(profile);
              //setTimeout(() => this._globalAppService.setApproles(this.filterRoles(profile.approles)));
              this.showAppHeader = true;
              // this.forceHideHeader = this.router.url === '/contactus' ? false : true; // Commented as we are updating the state in navigation interceptor
              // this._globalAppService.setApproles(this.filterRoles(profile.approles));

              //setting approles from cookies for impersonated user
              let impersonatedUserRoles = window.localStorage.getItem('impersonatedUserRoles');
              if (impersonatedUserRoles) {
                let rolesArray = impersonatedUserRoles.split(",");
                let globalRole = globalUser[window.localStorage.getItem('default-region')];

                let impersonatedUserRegions = window.localStorage.getItem('impersonatedUserRegions');
                let regions = impersonatedUserRegions.split(",");
                this._globalAppService.setApproles([...rolesArray, ...globalRole]);
                this._globalAppService.setRegions(regions);
              } else {
                this._globalAppService.setApproles(profile.approles);
                this._globalAppService.setRegions(profile.appregions);
              }

              this._globalAppService.setUserNameString(profile.name);
              this._globalAppService.setUserProfileDetails(profile);
              this.profileInfo = profile;
              if (profile.appregions.length > 1 && !localStorage.getItem('default-region')) {
                //to navigate to region selector page   
                this.setRecentDownloadContext();
                let recentDownloadParams = window.sessionStorage.getItem('recentDownloadParams');
                let region: any = recentDownloadParams ? JSON.parse(recentDownloadParams).RegionId : null;
                if (region) {
                  this._globalAppService.setDefaultRegion(region.toUpperCase());
                  localStorage.setItem('default-region', region.toUpperCase());
                  return observer.next(true);
                }
                return observer.next(false);
              }
              else if (profile.appregions.length == 1) {
                // // console.log('equal 1');
                this._globalAppService.setDefaultRegion(profile.appregions[0]);
                localStorage.setItem('default-region', profile.appregions[0]);
                return observer.next(true);
              }
              else {
                this._globalAppService.setDefaultRegion(localStorage.getItem('default-region'));
                return observer.next(true);
              }
            }
            else {
              return observer.next(false);
            }
          });
        }
      });
    });
    return loadFlag;
  }

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      if (environment.production)
        this.moniterServ.startNavigationEvent(event.url);
      // if (event.url.indexOf('/priceagreement') === -1) {
      //   this.showAcceptancePolicy = false;
      // }
      if (event.navigationTrigger == 'popstate' && (this.router.url == '/welcome' || this.router.url == '/home')) {
        if (this._appService.AvailableOKtaProfile) {
          this.popstateNavigation(this._appService.AvailableOKtaProfile.sAMAccountName);
        }
        else {
          this._appService.getOktaProfile().subscribe((data) => {
            this.popstateNavigation(data.sAMAccountName);
          });
        }
      }
      else if (event.url.indexOf('/delegatedUserSearch') > -1) {
        this._appService.setIsDelegatedUserPage(true);
      }

      if (event.url.indexOf('/efile/manage-errors?eFileID=') > -1 && !this.isAuthenticated) {
        localStorage.setItem('manageErrorDownloadUrl', event.url);
      }

      if (event.url.indexOf('/kcfile/manage-errors?kcFileID=') > -1 && !this.isAuthenticated) {
        localStorage.setItem('KCFileManageErrorDownloadUrl', event.url);
      }

      // if (event.url.indexOf('/login') !== -1 || event.url.indexOf('/register') !== -1 || event.url.indexOf('/select-region') !== -1) {
      //   if (this.isAuthenticated)
      //     this.router.navigate(['home']);
      // }

      /**Adding SetTimeout to avoid expression change error*/
      // setTimeout(() => {
      // console.log('77777', true);
      this.loading = true
      // });
    }
    if (event instanceof NavigationEnd) {
      /** Woraround to load the landing page on IE */
      if (document['documentMode'] || /Edge/.test(navigator.userAgent)) {
        //alert("inIeEdge");
        let isApploaded = sessionStorage.getItem("MS-App-Loaded");
        //alert("inIeEdge" + isApploaded);
        if (!isApploaded && (event.url.indexOf('/implicit/callback') > -1)) {
          sessionStorage.setItem("MS-App-Loaded", "1");
          //alert("inIeEdge" + event.url);
          setTimeout(() => {
            //alert("Navigating to landing");
            this.router.navigateByUrl("/landing");
          }, 100);
        }
      }

      if (event.url === '/home' || event.url === '/delegatedUserSearch') {
        this._appService.setHomeNavigation(true);
      }

      // if (event.url.indexOf('/priceagreement') === -1) {
      //   this.showAcceptancePolicy = false;
      // }

      if (environment.production)
        this.moniterServ.endNavigationEvent(event.url, event.url, Guid.raw());
      // Show different Header logic
      if (event.urlAfterRedirects == "/welcome" || event.urlAfterRedirects.indexOf("/welcome") !== -1 || event.urlAfterRedirects.indexOf('/login') !== -1 || event.urlAfterRedirects.indexOf("/pa/details") > -1 || event.urlAfterRedirects.indexOf("/chargeback/coupon/details") > -1 || event.urlAfterRedirects.indexOf("/meetcomps/meetcompsDetails/details") > -1 || event.urlAfterRedirects == "/logout" || event.urlAfterRedirects == '/landing' || event.urlAfterRedirects == '/register' || event.urlAfterRedirects == '/contactus') {
        this._appService.setIsDelegatedUserPage(false);
        this.showAppHeader = false;
      }
      else {
        this.showAppHeader = true;
      }

      // Show footer logic
      if (event.urlAfterRedirects == "/error" || event.urlAfterRedirects.indexOf("/pa/details") || event.urlAfterRedirects.indexOf("/chargeback/coupon/details") || event.urlAfterRedirects.indexOf("/meetcomps/meetcompsDetails/details")) {
        this.showAppFooter = true;
      }
      else {
        this.showAppFooter = false;
      }

      if (event.urlAfterRedirects.indexOf('/login') !== -1 || event.urlAfterRedirects.indexOf('/register') !== -1 || event.urlAfterRedirects.indexOf('/select-region') !== -1 || event.urlAfterRedirects.indexOf('/contactus') !== -1) {
        this.forceHideHeader = true;
        this.forceHideFooter = true;
        if ((event.urlAfterRedirects.indexOf('/contactus') !== -1)) {
          this._oktaAuth.isAuthenticated().then((isAuthenticated: boolean) => {
            if (isAuthenticated) {
              this.forceHideHeader = false;
              this.forceHideFooter = false;
            } else {
              this.forceHideHeader = true;
              this.forceHideFooter = true;
            }
          });
        }

        if (this.isAuthenticated && !localStorage.getItem('default-region'))
          this.router.navigate(['home'], { queryParams: { region: localStorage.getItem('default-region'), country: localStorage.getItem('country'), language: localStorage.getItem('country-lang') } });
      }
      else {
        this.forceHideHeader = false;
        this.forceHideFooter = false;
      }

      if (event.urlAfterRedirects.indexOf("(recentlychanged//dialogs:downloadmodal)") > -1) {
        this._appService.forceDownloadCSV(true);
      }
      else {
        this._appService.forceDownloadCSV(false);
      }
      // hide session modal logic for login page
      if (event.urlAfterRedirects == "/login" || event.urlAfterRedirects == "/logout") {
        this.resetTimeOut();
      } else {
        this.sessionSetup();
      }

      if (event.urlAfterRedirects.indexOf("/profile/myprofile") > -1) {
        // // console.log('my profile ');
        this._appService.setDisableLink(true);
      }
      else {
        this._appService.setDisableLink(false);
      }

      /* Stop show loading after each navigation end except /home as in /home we are setting it to false after anouncment response completed in home component*/
      // && event.urlAfterRedirects != "/profile/myprofile"
      // if (event.urlAfterRedirects != "/home") {


      if (event.urlAfterRedirects.indexOf('/home') == -1) {
        let currentUrl = location.href;
        const isIdentifier = currentUrl.indexOf('?');
        const targetURL = APP_LEGACY_MENU_HOST + '/chargeback/dashboard';
        let hideSpinner: boolean = true;
        if (isIdentifier > -1) {
          currentUrl = currentUrl.substring(0, isIdentifier);
        }
        const isTargetURLMatch = currentUrl.toUpperCase().replace(targetURL.toUpperCase(), '');
        if (isTargetURLMatch.length > 0) {
          hideSpinner = false;
        }
        else if (this._appService.CBAccetancePolicyLoadingStatus) {
          hideSpinner = false;
        }
        if (event.urlAfterRedirects.indexOf('/chargeback/dashboard') == -1) {
          setTimeout(() => {
            // console.log('111111', false);
            this.loading = false;
            this._appService.setTimeoutLoaderHide(false);
          }, 2000)
        }
        else if (event.urlAfterRedirects.indexOf('/chargeback/dashboard') > -1) {
          // console.log('2222', hideSpinner);
          this.loading = hideSpinner;
        }
      }
      else {
        this.loading = false;
      }
    }

    // loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      setTimeout(() => {
        // console.log('333333', false);
        this.loading = false;
      });
    }
    if (event instanceof NavigationError) {
      setTimeout(() => {
        // console.log('44444', false);
        this.loading = false;
      });
    }
  }

  login() {
    // this._oktaAuth.loginRedirect('/home');
    this._oktaAuth.loginRedirect('/landing');
  }

  logout() {
    this._oktaAuth.logout('/');
  }


  walkmeSCript() {
    // This script is imported through Google Tag Manager

    // // // console.log("walk me Step 5 walkmeSCript");
    // // // console.log("walk me Step 6", this.renderer2);
    // // <script
    // // type="text/javascript">(function () { var walkme = document.createElement('script'); walkme.type = 'text/javascript'; walkme.async = true; walkme.src = 'https://cdn.walkme.com/users/3aa07e499bb9450bbc24c4ccd1905dea/test/walkme_3aa07e499bb9450bbc24c4ccd1905dea_https.js'; var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(walkme, s); window._walkmeConfig = { smartLoad: true }; })();</script>
    // const s = this.renderer2.createElement('script');
    // s.type = 'text/javascript';
    // // s.src = 'https://path/to/your/script';
    // s.text = `(function () { var walkme = document.createElement('script'); walkme.type = 'text/javascript'; walkme.async = true; walkme.src = 'https://cdn.walkme.com/users/3aa07e499bb9450bbc24c4ccd1905dea/test/walkme_3aa07e499bb9450bbc24c4ccd1905dea_https.js'; var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(walkme, s); window._walkmeConfig = { smartLoad: true }; })();`;
    // this.renderer2.appendChild(this._document.body, s);
  }
  popstateNavigation(sAMAccountName) {
    // // console.log('insode popstateNavigation');
    this.httpServ.getWelcomeFlag(sAMAccountName).subscribe(d => {
      if (d.show_welcome_message === true) {
        this.router.navigate(["/welcome"]);
      }
      else {
        this.router.navigate(["/home"], { queryParams: { region: localStorage.getItem('default-region'), country: localStorage.getItem('country'), language: localStorage.getItem('country-lang') } });
      }
    });
  }

  reset() {
    // this.visibleDownloadModal = false;
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  resetTimeOut() {
    // this.visibleDownloadModal = false;
    this.idle.stop();
    // this.idle.onIdleStart.unsubscribe();
    // this.idle.onTimeoutWarning.unsubscribe();
    // this.idle.onIdleEnd.unsubscribe();
    // this.idle.onIdleEnd.unsubscribe();
  }

  setDefaultLocationLanguage() {
    let selectedLocationLanguage: any;
    if (this.isAuthenticated) {

    }
    else {
      if (this.activatedRoute.snapshot.queryParams) {
        selectedLocationLanguage = this.readQueryParams();
      }
      else if (typeof (Storage) !== 'undefined' && localStorage.getItem("KCC.CP.selectedLocationLanguage")) {
        selectedLocationLanguage = this.readLocationFromLocalStorage();
      }
      else {
        selectedLocationLanguage = {
          region: "NA",
          country: "US",
          language: 'en-us'
        }
      }
    }
    this._appService.setLocationLanguage(selectedLocationLanguage);
  }

  readQueryParams(): any {
    let selectedLocationLanguage =
    {
      region: this.activatedRoute.snapshot.queryParamMap.get('region'),
      country: this.activatedRoute.snapshot.queryParamMap.get('country'),
      language: this.activatedRoute.snapshot.queryParamMap.get('language')
    }
    return selectedLocationLanguage;
  }

  readLocationFromLocalStorage() {
    let parsedPreference = JSON.parse(localStorage.getItem("KCC.CP.selectedLocationLanguage"));
    let selectedLocationLanguage = {
      region: parsedPreference.region,
      country: parsedPreference.country,
      language: parsedPreference.language
    }
    return selectedLocationLanguage;
  }

  filterRoles(roles) {
    let adminroles = roles.filter(element => {
      return element.indexOf('SiteAdminModify') > -1 || element.indexOf('SiteAdminReadOnly') > -1;
    });
    return adminroles;
  }

  // makeAcceptancePolicyCall() {
  //   this._appService.setLoadingStatus(true);
  //   if (!this._appService.isInternalUser && this.region === 'LAO') {
  //     const request = {
  //       contentId: 1
  //     };
  //     this.subscription.add(this.httpServ
  //       .getAccptancePolicyStatus(request)
  //       .subscribe(
  //         (data) => {
  //           if (data) {
  //             this.acceptancePolicyResponse = data.result;
  //             this.getLocationAndlanguage();
  //             this.getAccetptancePolicyContent(this.acceptancePolicyResponse, this.region, this.country, this.language);
  //           }
  //         },
  //         (error) => {
  //           this._appService.setPopUpSequenceStatus(this._appService.getPopupSequenceNumber());
  //           this._appService.setLoadingStatus(false);
  //         },
  //         () => {
  //           this._appService.setLoadingStatus(false);
  //         }));
  //   }
  // }

  // onAcceptancePolicyLanguageChanged(languageCode) {
  //   this.showModal = true;
  //   // this.getLocationAndlanguage();
  //   this.getAccetptancePolicyContent(this.acceptancePolicyResponse, this.region, this.country, languageCode);
  // }

  // getAccetptancePolicyContent(statusResponse, region, country, language) {
  //   this._appService.setLoadingStatus(true);
  //   let KCPContentRequest = this.getKCPContentRequest(region, country, language);
  //   this.httpServ.getKCPContent(KCPContentRequest).subscribe(
  //     (data) => {
  //       if (data.result.length > 0) {
  //         this.policyContent = this.sanitizer.bypassSecurityTrustHtml(JSON.parse(data.result[0].data).Content);
  //         this.disableNotNow = statusResponse.forceAcceptance;
  //         this.showAcceptancePolicy = statusResponse.isAccepted == false ? true : false;
  //         this.showAcceptancePolicy = this.showModal || !statusResponse.isAccepted;
  //         if(!this.showAcceptancePolicy){
  //           this._appService.setPopUpSequenceStatus(this._appService.getPopupSequenceNumber());
  //         }
  //         this.isAccepted = statusResponse.isAccepted;
  //         this.showModal = false;
  //       }
  //       this._appService.setLoadingStatus(false);
  //     },
  //     (error) => {
  //       this._appService.setPopUpSequenceStatus(this._appService.getPopupSequenceNumber());
  //       this._appService.setLoadingStatus(false);
  //     },
  //     () => {
  //       this._appService.setLoadingStatus(false);
  //     })
  // }

  getKCPContentRequest(region, country, language) {
    return {
      regionId: region,
      // salesOrgId: this._globalAppService.getSalesOrg(),
      countryId: country,
      languageCode: language,
      contentTypes: [
        {
          contentType: "AcceptancePolicy"
        }
      ],
      contentSubTypes: [
        {
          contentSubType: "Popup"
        }
      ]
    }
  }

  // acceptPolicyModal(event) {
  //   if (event) {
  //     this.makeAPIRequest(true);
  //   }
  // }

  // postPonePolicyModal(event) {
  // if (event) {
  //   if (event.action === 'save&Close')
  //     this.makeAPIRequest(event.accept);
  //   if (event.action === 'close') {
  //     this.showAcceptancePolicy = false;
  //   }
  // }
  // this.showAcceptancePolicy = false;
  // this.translate.use(this.region + '/' + this.language.toLowerCase());
  // }

  // makeAPIRequest(isAggrementAccepted) {
  //   let req = {
  //     contentId: 1, //Pass the content id which received from KCPContent call 
  //     isAccepted: isAggrementAccepted
  //   };

  //   this.httpServ
  //     .setAccptancePolicyStatus(req)
  //     .subscribe(
  //       response => {
  //         if (response.result)
  //           this.isAccepted = isAggrementAccepted;
  //         // this.showAcceptancePolicy = false;
  //         // this.announcementModal();
  //       },
  //       err => {
  //         // console.log("Error Occured");
  //       }
  //     );
  // }

  getGlobalUserFlag() {
    if (this._appService.AvailableOKtaProfile)
      this.isGlobalUSer = this._appService.isGlobalUser;
    else
      this.subscription.add(this._appService.getOktaProfile().subscribe(data => {
        if (data) {
          this.isGlobalUSer = this._appService.isGlobalUser;
        }
      }));
  }

  ngOnDestroy() {
    this.resetTimeOut();
  }

}
