import { Component, OnDestroy } from '@angular/core';
import { CpAgGridService } from '@app/Modules/cp-grid/services/cp-ag-grid-service';
import { GlobalAppService } from '@app/Modules/Shared/Services/global-app.service';
import { AgRendererComponent } from 'ag-grid-angular';

@Component({
  selector: 'app-padetails-decimal-values',
  templateUrl: './padetails-decimal-values.component.html',
  styleUrls: ['./padetails-decimal-values.component.scss']
})
export class PadetailsDecimalValuesComponent implements AgRendererComponent, OnDestroy {

  params: any;
  isErrorExist: boolean;
  isErrorHigh: boolean;
  isPageEfileSearchResult: boolean = false;
  constructor(
    private gridService: CpAgGridService,
    private _globalAppService: GlobalAppService
  ) { }

  
  agInit(params: any) {
    this.isPageEfileSearchResult = (this.gridService.pageContext && this.gridService.pageContext.pageName === 'efileSearchResult') ? true : false;
    let region = this._globalAppService.getDefaultRegionString().toUpperCase();
    this.params = params;
    if (params.data){
      if (params.data.errors){
        this.errorColTooltip(params.data.errors);
      }
      if(this.gridService.pageContext.pageName ==='efileHistoryLog' || this.gridService.pageContext.pageName ==='kcfileHistoryLog'){
        if (params.data.statusId === 3 || params.data.statusId === 7) {// this is because for history log api they are sending 'status' also but with strings like - Processing, uploaded ..
          params.value = '--';
        }
      }
      else{
        // if (params.data.status === '3') {
        //   params.value = '--';
        // }
        if(params.data.por != null){
          const por = parseFloat(params.data.por);
          params.value = por.toFixed(2);
        }
        if(params.data.euPrice != null){
          const euPrice = parseFloat(params.data.euPrice);
          params.euValue = euPrice.toFixed(2);
        }
      }
    }
    //below is for EMEA product grid
    if(region === 'EMEA' && this.gridService.pageContext.pageName === 'padetailproduct'){
      if(params.data && params.data.priceType == 'CP'){
        params.value = '-';
        params.euValue = '-';
      }
    }
  }

  refresh(params: any): boolean {
    return false;
  }

  errorColTooltip(value) {
    if (value > 0) {
      this.isErrorExist = true;
    }
    if (value > 50) {
      this.isErrorHigh = true;
    }
  }

  ngOnDestroy() {
    // Required for unsubscribing and destroying the component  
  }

}
