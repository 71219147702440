import { Component, OnInit, Input, Output, OnDestroy } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { LanguageSelectionService } from "@app/Modules/Shared/Services/language-selection.service";
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { GlobalAppService } from '@app/Modules/Shared/Services/global-app.service';
import { CbAcceptencePolicyService } from '@app/Modules/Shared/Services/cb-acceptence-policy.service';
import { languageListForAPACPolicy, languageNames } from '@app/conf/dataConfigurations'
import { AppService } from "../../Services/app.service";
import { HttpService } from './../../Services/http.service';
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { KCPContent } from "../../DataModels/SharedDataModel";
import { Router } from '@angular/router';
import { TranslateService } from "@ngx-translate/core";

@AutoUnsubscribe()
@Component({
  selector: 'app-apac-pricing-tearms-conditions',
  templateUrl: './apac-pricing-tearms-conditions.component.html',
  styleUrls: ['./apac-pricing-tearms-conditions.component.scss']
})
export class ApacPricingTearmsConditionsComponent implements OnInit, OnDestroy {
  @Input() displayPolicyModal: boolean = true;
  @Output() acceptPolicy = new EventEmitter();
  @Input() isAccepted: boolean;

  country: any;
  isGlobalUser: boolean = false;
  fromFileUpload: boolean = false;
  region: any;
  language: any;
  countryLanguage: any = "en-gb";
  currentSelectedLanguage = "en";
  defaultLanguage = "en"
  userLanguageName: any;
  policyContent: SafeHtml;
  languageListForAPACPolicy: any;

  constructor(private appService: AppService, private cbPolicyService: CbAcceptencePolicyService, private _globalAppService: GlobalAppService, private languageServ: LanguageSelectionService, private httpServ: HttpService, public sanitizer: DomSanitizer, private router: Router, private translateService: TranslateService) { }

  ngOnInit() {
    this.region = this._globalAppService.getDefaultRegionString();
    this.isGlobalUser = this.appService.isGlobalUser;
    let selectedLocation = this._globalAppService.getSelectedCountryLang();
    this.language = selectedLocation && selectedLocation.language ? selectedLocation.language : this._globalAppService.getDefaultLanguageString();
    if ((this.language.split("-")[1]).toLowerCase() == "id") {
      this.language = "id-ID";
    }
    console.log("---------- ", this.language)

    if (this.language)
      this.updateLanguage(this.language);
    this.languageServ.getlanguage().subscribe(data => {
      this.countryLanguage = data;
      if ((this.countryLanguage.split("-")[1]).toLowerCase() == "id") {
        data = "id-ID";
      }
      this.updateLanguage(data);
    });
  }

  getKCPContentRequest(region) {
    let contactRequest = new KCPContent();
    let currentCountryLang = this._globalAppService.getSelectedCountryLang();
    let country = currentCountryLang.country || this._globalAppService.getDefaultCountryString();
    let language = this.language;
    contactRequest.regionId = region;
    contactRequest.countryId = country;
    contactRequest.languageCode = language + "-" + country;
    contactRequest.contentTypes = this.contentTypeRequest();
    contactRequest.contentSubTypes = [
      {
        "contentSubType": "Popup"
      }
    ];
    //contactRequest.salesOrgId = currentCountryLang.salesOrg
    return contactRequest;
    //return { "regionId": "EMEA", "countryId": "IE", "languageCode": "en-IE", "contentTypes": [{ "contentType": "Chargeback Policy Consent" }], "contentSubTypes": [{ "contentSubType": "Popup" }] };
  }

  contentTypeRequest() {
    let contentTypeArr = [];
    let myObj = Object.assign({});
    myObj.contentType = 'Pricing Policy Consent';
    contentTypeArr.push(myObj);
    return contentTypeArr;
  }

  getPolicyContent() {
    this.appService.setLoadingStatus(true);
    let KCPContentRequest = this.getKCPContentRequest(this.region);
    this.httpServ.getKCPContent(KCPContentRequest).subscribe(
      (data) => {
        console.log("DATA :: ", data)
        if (data.result.length > 0) {
          this.policyContent = this.sanitizer.bypassSecurityTrustHtml(JSON.parse(data.result[0].data).Content);
        }
        this.appService.setLoadingStatus(false);
      },
      (error) => {
        this.appService.setLoadingStatus(false);
      },
      () => {
        this.appService.setLoadingStatus(false);
      })
  }

  updateLanguage(data = this.language) {
    let currentCountryLang = this._globalAppService.getSelectedCountryLang();
    let country = currentCountryLang.country || this._globalAppService.getDefaultCountryString();
    this.countryLanguage = currentCountryLang.language;
    this.language = (data.split("-")[0]).toLowerCase();
    this.userLanguageName = languageNames;
    this.languageListForAPACPolicy = languageListForAPACPolicy[country.toLowerCase()];
    this.currentSelectedLanguage = this.language;
  }
  acknowledgePolicy() {
    //this.acceptPolicy.emit(true);
    let req = {
      contentId: 50,
      isAccepted: true
    };
    this.httpServ
      .setAccptancePolicyStatus(req)
      .subscribe(
        response => {
          if (response.result) {
            this.router.navigate([{ outlets: { termsdialogs: null } }]).then(() => {
              this.router.navigate(['home'], { queryParams: { region: localStorage.getItem('default-region'), country: localStorage.getItem('country'), language: localStorage.getItem('country-lang') } });
              this.displayPolicyModal = false;
            });
          }
        },
        err => {
          console.log("Error Occured");
        }
      );

  }

  close() {
    // if (this.isGlobalUser) {
    //   sessionStorage.setItem("showCbPolicy", "false");
    // }
    // this.cbPolicyService.policySubject.next({ isAccepted: this.isAccepted, showAcceptancePolicy: false, fromFileUpload: false });
  }

  onUserLanguageClick(language) {
    this.language = language;
    this.currentSelectedLanguage = this.language;
    this.getPolicyContent();
  }
  onPopupShow() {
    this.getPolicyContent();
  }
  ngOnDestroy() {
    //Destroying component  
  }
}

