import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable, of } from 'rxjs';
import { DashBoardTilesCount } from '../DataModels/SharedDataModel';
import { GlobalAppService } from './global-app.service';
import { timeout, catchError, map } from 'rxjs/operators';
import { SourceMapGenerator } from '@angular/compiler/src/output/source_map';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(
    private http: HttpClient,
    private _globalAppService: GlobalAppService) { }

  // getUserInfo(OKTA_TOKEN: string): Observable<any> {
  //   let httpHeaders: HttpHeaders = new HttpHeaders({ 'Authorization': 'Bearer ' + OKTA_TOKEN }).append('Content-Type', 'application/json');
  //   return this.makeHTTPCall(environment.userInfo.method, environment.userInfo.url, httpHeaders, null)
  // }

  getUserInfo(OKTA_TOKEN: string): Observable<any> {
    let oktaStorage = JSON.parse(localStorage.getItem('okta-token-storage'));
    if ((oktaStorage && (Object.keys(oktaStorage).length === 0))) {
      return new Observable<any>(observer => {
        observer.next(null);
      });
    }
    return new Observable<any>(observer => {

      // console.log("jwt token", jwt.payload);
      let name = oktaStorage.idToken.claims.name;
      let given_name = name.substring(name.indexOf(',') + 1, name.length).trim();
      let family_name = name.substring(0, name.indexOf(',')).trim();
      let appregions = oktaStorage.idToken.claims.appregions;
      let appRoles = oktaStorage.idToken.claims.approles;
      // let appregions = ['LAO'];

      const User_Info = (({ sub, name, email, phone, preferred_username, sAMAccountName, approles, userType, appRoles }) => ({ sub, name, email, phone, preferred_username, sAMAccountName, approles, userType, appRoles }))(oktaStorage.idToken.claims);
      const final_User_Info = Object.assign({}, User_Info, { given_name, family_name, appregions });
      // console.log("User_Info ===> ", final_User_Info)
      observer.next(final_User_Info);
    })
  }

  oktaAuthRenew(OKTA_TOKEN: string): Observable<any> {
    let httpHeaders: HttpHeaders = new HttpHeaders({ 'Authorization': 'Bearer ' + OKTA_TOKEN }).append('Content-Type', 'application/json');
    return this.makeHTTPCall(environment.oktaAuthorize.method, this.getRegionSpecificURL(environment.oktaAuthorize), httpHeaders, null)
  }

  getImpersonatedUserInfo(ebamId): Observable<any> {
    let oktaUrl = environment.getImpersonatedUserInfo.url.replace("XXX_EBAMID_XXX", ebamId);
    return this.makeHTTPCall(environment.getImpersonatedUserInfo.method, oktaUrl, null, null);
  }

  getCategoryList(): Observable<any> {
    return this.makeHTTPCall(environment.productcategory.method, this.getRegionSpecificURL(environment.productcategory), null, null)
  }


  getFavContractList(): Observable<any> {
    return this.makeHTTPCall(environment.getFavorite.method, this.getRegionSpecificURL(environment.getFavorite), null, null);
  }

  // getUserProfile(qid: string): Observable<any> {
  //   return this.makeHTTPCall(environment.userProfile.method, environment.userProfile.url, null, {
  //     "userid": qid
  //   })
  // }

  getUserProfile(qid: string): Observable<any> {
    return this.makeHTTPCall(environment.userProfile.method, this.getRegionSpecificURL(environment.userProfile), null, null)
  }

  getCartItemsAmount(result: any) {

    let oktaStorage = JSON.parse(localStorage.getItem('okta-token-storage'));

    let searchObj = {
      "storeId": environment.magento.storeId
    }

    var http = new XMLHttpRequest();
    http.open(environment.searchCategories.method, this.getRegionSpecificURL(environment.cartCount), true);
    http.setRequestHeader('Content-Type', 'application/json')
    http.setRequestHeader('okta-access-token', oktaStorage.accessToken.accessToken);
    http.onreadystatechange = function () {
      if (http.readyState == 4 && http.status == 200) {
        result(http.responseText)
      }
    }
    http.send(JSON.stringify(searchObj));
  }

  getSearchCategories(result: any) {

    let oktaStorage = JSON.parse(localStorage.getItem('okta-token-storage'));

    let searchObj = {
      "storeId": environment.magento.storeId
    }

    var http = new XMLHttpRequest();
    http.open(environment.searchCategories.method, this.getRegionSpecificURL(environment.searchCategories), true);
    http.setRequestHeader('Content-Type', 'application/json')
    http.setRequestHeader('okta-access-token', oktaStorage.accessToken.accessToken);
    http.onreadystatechange = function () {
      if (http.readyState == 4 && http.status == 200) {
        result(http.responseText)
      }
    }
    http.send(JSON.stringify(searchObj));
  }

  getSearchResult(search: string, result: any) {

    let oktaStorage = JSON.parse(localStorage.getItem('okta-token-storage'));

    let searchObj = {
      "text": search,
      "storeId": environment.magento.storeId
    }

    var http = new XMLHttpRequest();
    http.open(environment.searchCategories.method, this.getRegionSpecificURL(environment.searchProduct), true);
    http.setRequestHeader('Content-Type', 'application/json')
    http.setRequestHeader('okta-access-token', oktaStorage.accessToken.accessToken);
    http.onreadystatechange = function () {
      if (http.readyState == 4 && http.status == 200) {
        result(http.responseText)
      }
    }
    http.send(JSON.stringify(searchObj));
  }


  getdashBoardCount(): Observable<DashBoardTilesCount> {
    return this.makeHTTPCall(environment.dashboardcount.method, this.getRegionSpecificURL(environment.dashboardcount), null, null)
  }

  getEndUserCount(): Observable<DashBoardTilesCount> {
    return this.makeHTTPCall(environment.endUserCount.method, this.getRegionSpecificURL(environment.endUserCount), null, null)
  }

  PASearch(searchObj): Observable<any> {
    return this.makeHTTPCall(environment.SearchByFields.method, this.getRegionSpecificURL(environment.SearchByFields), null, searchObj)
  }
  PARecentSearchSave(searchObj): Observable<any> {
    return this.makeHTTPCall(environment.saveRecentSearch.method, this.getRegionSpecificURL(environment.saveRecentSearch), null, searchObj)
  }

  getRecentSearchTiles(): Observable<any> {
    return this.makeHTTPCall(environment.getRecentSearch.method, this.getRegionSpecificURL(environment.getRecentSearch), null, null)
  }

  getSavedSearchTiles(): Observable<any> {
    return this.makeHTTPCall(environment.getSavedSearch.method, this.getRegionSpecificURL(environment.getSavedSearch), null, null)
  }

  setSavedSearchName(tileId, name): Observable<any> {
    return this.makeHTTPCall(environment.saveSearchName.method, this.getRegionSpecificURL(environment.saveSearchName), null, {
      "id": tileId,
      "name": name
    })
  }

  getHamburgerMenuPhaseIII(language): Observable<any> {
    let httpHeaders: HttpHeaders = new HttpHeaders({ 'Accept-Language': language });
    return this.makeHTTPCall(environment.hamburgerMenuPhaseThree.method, this.getRegionSpecificURL(environment.hamburgerMenuPhaseThree), httpHeaders, null);
  }

  getHamburgerMenu(language): Observable<any> {
    let httpHeaders: HttpHeaders = new HttpHeaders({ 'Accept-Language': language });
    return this.makeHTTPCall(environment.hamburgerMenu.method, this.getRegionSpecificURL(environment.hamburgerMenu), httpHeaders, null);
  }

  removeProfileSearch(searchId): Observable<any> {
    return this.makeHTTPCall(environment.removeSearch.method, this.getRegionSpecificURL(environment.removeSearch), null, {
      id: searchId
    })
  }

  markSearchFavrouite(searchId): Observable<any> {
    return this.makeHTTPCall(environment.markSearchFavrouite.method, this.getRegionSpecificURL(environment.markSearchFavrouite), null, {
      id: searchId
    })
  }


  getTransactionCenterMenu(language): Observable<any> {
    let httpHeaders: HttpHeaders = new HttpHeaders({ 'Accept-Language': language });
    return this.makeHTTPCall(environment.transactionCenterMenu.method, this.getRegionSpecificURL(environment.transactionCenterMenu), httpHeaders, null);
  }

  getTransactionCenterMenuPilotUser(language): Observable<any> {
    let httpHeaders: HttpHeaders = new HttpHeaders({ 'Accept-Language': language });
    return this.makeHTTPCall(environment.transactionCenterMenuPilotUser.method, this.getRegionSpecificURL(environment.transactionCenterMenuPilotUser), httpHeaders, null);
  }

  getLearningCenterMenu(): Observable<any> {
    return this.makeHTTPCall(environment.learningCenterMenu.method, this.getRegionSpecificURL(environment.learningCenterMenu), null, null)
  }

  getWelcomeFlag(userId): Observable<any> {
    let httpHeaders: HttpHeaders = new HttpHeaders({ 'userId': userId });
    return this.makeHTTPCall(environment.getWelcomeFlag.method, this.getRegionSpecificURL(environment.getWelcomeFlag), httpHeaders, null)
  }

  updateWelcomeFlag(userId): Observable<any> {
    let httpHeaders: HttpHeaders = new HttpHeaders({ 'userId': userId });
    return this.makeHTTPCall(environment.updateWelcomeFlag.method, this.getRegionSpecificURL(environment.updateWelcomeFlag), httpHeaders, null)
    // return Observable.of({ status : true});
  }

  markFavorite(contractId, customerId, todayDate): Observable<any> {
    return this.makeHTTPCall(environment.markFavorite.method, this.getRegionSpecificURL(environment.markFavorite), null, [{
      "contractId": "" + contractId,
      "customerId": "" + customerId,
      "favoriteDate": "2019-04-29T22:57:15.413+05:30" //hardcode value as discussed with Azure team as date is been used from server side
    }])
  }

  markUnFavorite(contractId, customerId, todayDate): Observable<any> {
    return this.makeHTTPCall(environment.markUnFavorite.method, this.getRegionSpecificURL(environment.markUnFavorite), null, [{
      "contractId": "" + contractId,
      "customerId": "" + customerId,
      "favoriteDate": "2019-04-29T22:57:15.413+05:30" //hardcode value as discussed with Azure team as date is been used from server side
    }])
  }

  getCustomerInfo(customerId): Observable<any> {
    return this.makeHTTPCall(environment.getCustomerInfo.method, this.getRegionSpecificURL(environment.getCustomerInfo), null, {
      custid: [customerId]
    })
  }

  getContractCustomerList(searchobj): Observable<any> {
    return this.makeHTTPCall(environment.getContractCustomerList.method, this.getRegionSpecificURL(environment.getContractCustomerList), null, searchobj)
  }

  exportRecentDownload(request) {
    return this.makeHTTPCall(environment.recentDownloadExport.method, this.getRegionSpecificURL(environment.recentDownloadExport), null, request);
  }

  exportPriceAgreementSearch(searchRequestObj): Observable<any> {
    return this.makeHTTPCall(environment.exportPriceAgreementSearch.method, this.getRegionSpecificURL(environment.exportPriceAgreementSearch), null, searchRequestObj)
  }

  exportPADetailEndUser(searchRequestObj): Observable<any> {
    return this.makeHTTPCall(environment.exportPADetailEndUser.method, this.getRegionSpecificURL(environment.exportPADetailEndUser), null, searchRequestObj)
  }

  exportPADetailProduct(searchRequestObj): Observable<any> {
    return this.makeHTTPCall(environment.exportPADetailProduct.method, this.getRegionSpecificURL(environment.exportPADetailProduct), null, searchRequestObj)
  }

  exportPADetailTradeOrg(searchRequestObj): Observable<any> {
    return this.makeHTTPCall(environment.exportPADetailTradeOrg.method, this.getRegionSpecificURL(environment.exportPADetailTradeOrg), null, searchRequestObj)
  }

  exportDashboardTiles(searchRequestObj): Observable<any> {
    return this.makeHTTPCall(environment.exportDashboardTiles.method, this.getRegionSpecificURL(environment.exportDashboardTiles), null, searchRequestObj)
  }
  exportMyNotification(searchRequestObj): Observable<any> {
    return this.makeHTTPCall(environment.exportMyNotification.method, this.getRegionSpecificURL(environment.exportMyNotification), null, searchRequestObj)
  }

  getContractDetail(contractNumber, customerNumber, statusId, searchView): Observable<any> {
    return this.makeHTTPCall(environment.getContractDetail.method, this.getRegionSpecificURL(environment.getContractDetail), null, {
      "contractNumber": contractNumber,
      "customerNumber": customerNumber,
      "paStatus": +statusId,
      "searchView": (searchView == false ? "customer" : "cvt")
      // "searchView": 0
    })
  }

  saveUserNotes(note, contractNumber, customerNumber, username): Observable<any> {
    return this.makeHTTPCall(environment.saveUserNotes.method, this.getRegionSpecificURL(environment.saveUserNotes), null, {
      "notes": note,
      "contractNumber": contractNumber,
      "customerNumber": customerNumber,
      "userName": username
    })
  }

  getUserNotes(contractNumber, customerNumber, statusId): Observable<any> {
    return this.makeHTTPCall(environment.getUserNotes.method, this.getRegionSpecificURL(environment.getUserNotes), null, {
      "contractNumber": contractNumber,
      "customerNumber": customerNumber,
      "paStatus": +statusId
    })
  }

  getKCPUserUpdates(contractNumber, customerNumber, statusId): Observable<any> {
    return this.makeHTTPCall(environment.getKCPUserUpdates.method, this.getRegionSpecificURL(environment.getKCPUserUpdates), null, {
      "contractNumber": contractNumber,
      "customerNumber": customerNumber,
      "paStatus": +statusId
    })
  }

  announcementModalDetails(userid, announcementid = []) {
    return this.makeHTTPCall(environment.announcementsModalDetails.method, this.getRegionSpecificURL(environment.announcementsModalDetails), null, {
      "userid": userid,
      "announcementid": announcementid
    })
  }

  messageModalDetails(userid, messagesid = []): Observable<any> {
    return this.makeHTTPCall(environment.messagesModalDetails.method, this.getRegionSpecificURL(environment.messagesModalDetails), null, {
      "userid": userid,
      "messageid": messagesid
    })
  }

  getLocation(userid, sortingField: string = null, sortingOrder: string = null): Observable<any> {
    // return this.makeHTTPCall(environment.getLocationData.method, environment.getLocationData.url + "?userID=" + userid, null, null
    return this.makeHTTPCall(environment.getLocationData.method, this.getRegionSpecificURL(environment.getLocationData) + "?userID=" + userid + '&sortingField=' + sortingField + '&sortingOrder=' + sortingOrder, null, null
    )
  }

  getCustomerList(customerList): Observable<any> {
    return this.makeHTTPCall(environment.getCustomerInfo.method, this.getRegionSpecificURL(environment.getCustomerInfo), null,
      {
        "custid": customerList
      }
    )
  }

  getPATYpe(cvtview): Observable<any> {
    return this.makeHTTPCall(environment.getPAType.method, this.getRegionSpecificURL(environment.getPAType) + "?iscvt=" + cvtview, null, null)
  }

  downloadData(request: any): Observable<any> {
    // return this.http.post(environment.download.url, request).pipe(timeout(300000),
    return this.makeHTTPCall(environment.download.method, this.getRegionSpecificURL(environment.download), null, request).pipe(timeout(300000),
      catchError((err: any) => {
        return of(null);
      }),
      map((response) => {
        if (response) {
          return response;
        }
      }));
  }

  downloadPADetails(request: any, isPDFDownload): Observable<any> {

    const url = isPDFDownload ? this.getRegionSpecificURL(environment.downloadPADetails) : this.getRegionSpecificURL(environment.downloadPADetailsExcelFile);
    return this.makeHTTPCall(environment.downloadPADetails.method, url, null, request).pipe(timeout(300000),
      catchError((err: any) => {
        return of(null);
      }),
      map((response) => {
        if (response) {
          return response;
        }
      }));
  }

  // getEndUserRequests(url, request) {
  //   return this.makeHTTPCall(environment.getEndUserRequests.method, url, null, request);
  // }

  downloadRebateData(request: any): Observable<any> {
    // return this.http.post(environment.download.url, request).pipe(timeout(300000),
    return this.makeHTTPCall(environment.rebateDownload.method, this.getRegionSpecificURL(environment.rebateDownload), null, request).pipe(timeout(300000),
      catchError((err: any) => {
        return of(null);
      }),
      map((response) => {
        if (response) {
          return response;
        }
      }));
  }

  checkenduser(searchObj) {
    return this.makeHTTPCall(environment.endusersearch.method, this.getRegionSpecificURL(environment.endusersearch), null, searchObj);
  }

  getStateList(request) {
    return this.makeHTTPCall(environment.getStateList.method, this.getRegionSpecificURL(environment.getStateList), null, {
      "CP_Country_Code": (request ? request : "")
    });
  }

  validateAddress(request) {
    return this.makeHTTPCall(environment.validateAddress.method, this.getRegionSpecificURL(environment.validateAddress), null, request);
  }

  submitEndUsers(request) {
    return this.makeHTTPCall(environment.submitEndUsers.method, this.getRegionSpecificURL(environment.submitEndUsers), null, request);
  }

  ClearCache() {
    return this.makeHTTPCall(environment.ClearCache.method, this.getRegionSpecificURL(environment.ClearCache), null, { "clearCacheItem": 1 });
  }

  downloadPaDetailsExcel(request) {
    return this.makeHTTPCall(environment.downloadPadetailsExcel.method, this.getRegionSpecificURL(environment.downloadPadetailsExcel), null, request)
  }

  getRegionSpecificURL(item: any) {
    let regionURL = "";
    let region = localStorage.getItem('default-region') || this._globalAppService.getDefaultRegionString() || this._globalAppService.selectedRegion;
    if (region) {
      regionURL = "url_" + region.toUpperCase();
    }
    else {
      regionURL = "url_LAO";
    }
    if (!item[regionURL]) {
      console.log(item)
      console.log(regionURL, item[regionURL])
    }

    return item[regionURL];
  }

  makeHTTPCall(method: string, endPoint: string, additionalHeaders: any = null, requestParams: any = null): Observable<any> {
    let requestBody = {};
    if (additionalHeaders) {
      requestBody = Object.assign({}, requestBody, { headers: additionalHeaders });
    }
    if (requestParams) {
      requestBody = Object.assign({}, requestBody, { body: requestParams });
    }

    return this.http.request(method, endPoint, requestBody);
  }

  downloadSelectedRecentDownload(fileId, isGlobalUser): Observable<any> {
    return this.makeHTTPCall(environment.selectedrecentDownloadDoculent.method,
      this.getRegionSpecificURL(environment.selectedrecentDownloadDoculent), null, {
      "fileId": fileId,
      "isGlobalUser": isGlobalUser
    })
  }

  deleteDownload(deleteObj) {
    return this.makeHTTPCall(environment.recentDownloadDelete.method, this.getRegionSpecificURL(environment.recentDownloadDelete), null, deleteObj);
  }

  deleteUserNotes(id) {
    return this.makeHTTPCall(environment.deleteUserNotes.method, this.getRegionSpecificURL(environment.deleteUserNotes) + id, null, null);
  }

  downloadEndUserRequests(request) {
    return this.makeHTTPCall(environment.endUserRequestsDownload.method, this.getRegionSpecificURL(environment.endUserRequestsDownload), null, request);
  }

  getKCPContent(request): Observable<any> {
    return this.makeHTTPCall(environment.KCPContent.method, this.getRegionSpecificURL(environment.KCPContent), null, request)
  }

  getAccptancePolicyStatus(request): Observable<any> {
    return this.makeHTTPCall(environment.getAccptancePolicyStatus.method, this.getRegionSpecificURL(environment.getAccptancePolicyStatus), null, request)
  }

  setAccptancePolicyStatus(request): Observable<any> {
    return this.makeHTTPCall(environment.setAccptancePolicyStatus.method, this.getRegionSpecificURL(environment.setAccptancePolicyStatus), null, request)
  }

  getLocations() {
    return this.makeHTTPCall(environment.getLocations.method, this.getRegionSpecificURL(environment.getLocations), null, null);
  }

  getLocationsWithDefaultLanguage(language): Observable<any> {
    let httpHeaders: HttpHeaders = new HttpHeaders({ 'Accept-Language': language });
    return this.makeHTTPCall(environment.getLocations.method, this.getRegionSpecificURL(environment.getLocations), httpHeaders, null);
  }

  getContentService(request: any) {
    return this.makeHTTPCall(environment.getContentService.method, this.getRegionSpecificURL(environment.getContentService), null, request);
  }

  getUserDetails() {
    return this.makeHTTPCall(environment.getUserDetails.method, this.getRegionSpecificURL(environment.getUserDetails), null, null);
  }

  getAccessManagementList(request) {
    let httpHeaders: HttpHeaders = new HttpHeaders({ 'Accept-Language': request.languageCode });
    delete request.languageCode;
    return this.makeHTTPCall(environment.getAccessManagementList.method, this.getRegionSpecificURL(environment.getAccessManagementList), httpHeaders, request);
  }

  getAccessManagementListRegisterPage(request) {
    let httpHeaders: HttpHeaders = new HttpHeaders({ 'Accept-Language': request.languageCode });
    return this.makeHTTPCall(environment.getAccessManagementList.method, this.getRegionSpecificURL(environment.getAccessManagementList), httpHeaders, request);
  }

  registerUser(request) {
    return this.makeHTTPCall(environment.registerUser.method, this.getRegionSpecificURL(environment.registerUser), null, request);
  }

  saveUpdateUserProfile(request: any) {
    return this.makeHTTPCall(environment.saveUpdateUserProfile.method, this.getRegionSpecificURL(environment.saveUpdateUserProfile), null, request);
  }

  CheckPAUpdates(): Observable<any> {
    return this.makeHTTPCall(environment.CheckPAUpdates.method, this.getRegionSpecificURL(environment.CheckPAUpdates), null, null);
  }

  getNewlyAddedCustomers(request: any): Observable<any> {
    return this.makeHTTPCall(environment.getNewlyAddedCustomers.method, this.getRegionSpecificURL(environment.getNewlyAddedCustomers), null, request);
  }

  getUserRoles(regionID: string = ''): Observable<any> {
    return this.makeHTTPCall(environment.GetUserRoles.method, this.getRegionSpecificURL(environment.GetUserRoles) + "?regionId=" + regionID, null, null);
  }

  getCountrySalesOrgLanguage(reqHeader = null): Observable<any> {
    return this.makeHTTPCall(environment.GetCountrySalesOrgLanguage.method, this.getRegionSpecificURL(environment.GetCountrySalesOrgLanguage), reqHeader, null);
  }

  getExternalUserCountries(): Observable<any> {
    return this.makeHTTPCall(environment.UserCustomerCountries.method, this.getRegionSpecificURL(environment.UserCustomerCountries), null, null);
  }

  getSortByOptions(request, header): Observable<any> {
    return this.makeHTTPCall(environment.GetSortBy.method, this.getRegionSpecificURL(environment.GetSortBy), header, request);
  }

  saveUserDefaultProfile(request: any): Observable<any> {
    return this.makeHTTPCall(environment.SaveUserDefaultProfile.method, this.getRegionSpecificURL(environment.SaveUserDefaultProfile), null, request);
  }

  getUserLocations(): Observable<any> {
    return this.makeHTTPCall(environment.UserDefaultLocations.method, this.getRegionSpecificURL(environment.UserDefaultLocations), null, null);
  }

  getStates(request: any): Observable<any> {
    return this.makeHTTPCall(environment.getStates.method, this.getRegionSpecificURL(environment.getStates), null, request);
  }

  saveMyLocationData(request): Observable<any> {
    return this.makeHTTPCall(environment.saveLocationData.method, this.getRegionSpecificURL(environment.saveLocationData), null, request);
  }

  removeLocation(request): Observable<any> {
    return this.makeHTTPCall(environment.removeUcMappings.method, this.getRegionSpecificURL(environment.removeUcMappings), null, request);
  }

  addLocatioOrChangeGroup(request): Observable<any> {
    return this.makeHTTPCall(environment.addUcMappings.method, this.getRegionSpecificURL(environment.addUcMappings), null, request);
  }

  triggerUCMSync(request): Observable<any> {
    return this.makeHTTPCall(environment.UCMSync.method, this.getRegionSpecificURL(environment.UCMSync), null, request);
  }

  validateRecaptcha(request): Observable<any> {
    return this.makeHTTPCall(environment.captchaValidationUrl.method, this.getRegionSpecificURL(environment.captchaValidationUrl), null, request);
  }

  getDownloadCustomerList(request): Observable<any> {
    return this.makeHTTPCall(environment.downloadCustomerList.method, this.getRegionSpecificURL(environment.downloadCustomerList), null, request);
  }

  exportPortalUsage(request): Observable<any> {
    return this.makeHTTPCall(environment.exportPortalUsageInternal.method, this.getRegionSpecificURL(environment.exportPortalUsageInternal), null, request);
  }

  saveUserContact(request): Observable<any> {
    return this.makeHTTPCall(environment.saveUserContact.method, this.getRegionSpecificURL(environment.saveUserContact), null, request);
  }

  postToPardot(request) {
    return this.makeHTTPCall(environment.pardot.method, this.getRegionSpecificURL(environment.pardot), null, request);
  }

  globalUserAuditLog(auditRequestObj): Observable<any> {
    return this.makeHTTPCall(environment.GlobalUserAuditLog.method, this.getRegionSpecificURL(environment.GlobalUserAuditLog), null, auditRequestObj)
  }

  getEFileAccessBySearchFields(request): Observable<any> {
    return this.makeHTTPCall(environment.getEFileAccessBySearchFields.method, this.getRegionSpecificURL(environment.getEFileAccessBySearchFields), null, request);
  }

  updateEFileAccess(request): Observable<any> {
    return this.makeHTTPCall(environment.updateEFileAccess.method, this.getRegionSpecificURL(environment.updateEFileAccess), null, request);
  }

  eaGetDistinctFilterForGrid(requestObj, gridName): Observable<any> {
    switch (gridName) {
      case "efileAccessPage":
        return this.makeHTTPCall(environment.eaGetDistinctFilterSearchGrid.method, this.getRegionSpecificURL(environment.eaGetDistinctFilterSearchGrid), null, requestObj);
      default:
        return of(false);
    }
  }

  getSalesOrgNames(): Observable<any> {
    return this.makeHTTPCall(environment.getSalesOrgNames.method, this.getRegionSpecificURL(environment.getSalesOrgNames), null, null)
  }

  getKCOwnerNames(): Observable<any> {
    return this.makeHTTPCall(environment.getKCOwnerNames.method, this.getRegionSpecificURL(environment.getKCOwnerNames), null, null)
  }

  getMultipleChanges(request): Observable<any> {
    return this.makeHTTPCall(environment.multipleChanges.method, this.getRegionSpecificURL(environment.multipleChanges), null, request);
  }

  exportRebatePriceSearch(searchRequestObj): Observable<any> {
    return this.makeHTTPCall(environment.exportRebatePriceSearch.method, this.getRegionSpecificURL(environment.exportRebatePriceSearch), null, searchRequestObj)
  }

  getOrderPopupPreferences(region, country): Observable<any> {
    let queryString = "?RegionId=" + region + "&CountryId=" + country;
    let url = this.getRegionSpecificURL(environment.getOrderPopupPreferences) + queryString;
    return this.makeHTTPCall(environment.getOrderPopupPreferences.method, url, null, null)
  }

  getOrderPreferences(userId): Observable<any> {
    let queryString = "?userId=" + userId;
    let url = this.getRegionSpecificURL(environment.getOrderPreferences) + queryString;
    return this.makeHTTPCall(environment.getOrderPreferences.method, url, null, null)
  }

  saveOrderPreferences(reqObj): Observable<any> {
    return this.makeHTTPCall(environment.saveOrderPreferences.method, this.getRegionSpecificURL(environment.saveOrderPreferences), null, reqObj)
  }

  makeAuditLogoutRequest(): Observable<any> {
    return this.makeHTTPCall(environment.auditLogout.method, this.getRegionSpecificURL(environment.auditLogout), null, null)
  }

  getEndUsers(request): Observable<any> {
    return this.makeHTTPCall(environment.getEndUsersResultData.method, this.getRegionSpecificURL(environment.getEndUsersResultData), null, request)
  }

}