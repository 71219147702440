<div class="header__search-dropdown">
    <div class="header-search-dropdown-top">
        <div class="container-fluid">

            <div class="row">
                <div class="col-12 col-md-6 offset-md-3 col-lg-2 offset-lg-2" >
                    <ul class="unlisted header__search-dropdown__quick-links" *ngIf="categoriesLoaded == true">
                        <li>{{ 'searchResultModule.quickLinks' | translate}}</li>
                        <li *ngFor="let category of categoryResults"><a href="{{category.category_url}}"
                                target="_blank">{{category.name}}</a></li>
                    </ul>
                </div>
                <div class="col-12 col-lg-6 offset-lg-0 d-none d-md-block">

                    <div class="header__search-dropdown__three-up" *ngIf="resultsLoaded == true">

                        <ul class="unlisted inline">

                            <li *ngFor="let result of _resultList">

                                <a href="{{result.product_url}}" target="_blank" class="header__search-dropdown__three-up__image"><img
                                        [src]="result.image"></a>

                                <a href="{{result.product_url}}" target="_blank">{{(result.name.length>60)? (result.name | slice:0:60)+'...':(result.name)}}</a>
                               
                                <table class="info-table" cellspacing="0" cellpadding="0">
                                    <tbody>
                                        <tr *ngIf="result.options.length > 0">
                                            <td><span>{{ 'searchResultModule.options' | translate}}</span></td>
                                            <td>{{(result.options.length>40)? (result.options | slice:0:40)+'...':(result.options)}}</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </li>

                        </ul>
                        
                        <a href="{{allProductsURL}}" target="_blank">{{ 'searchResultModule.viewAllProducts' | translate}} - ({{resultCount}})</a>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>