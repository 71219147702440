let endpoints_PA_DEV_TeamB = {
    oktaAuthorize: {
		url_NA: "https://ebiz.oktapreview.com/oauth2/auslle1jli7NKpqm70h7/v1/authorize",
		url_LAO: "https://ebiz.oktapreview.com/oauth2/auslle1jli7NKpqm70h7/v1/authorize",
		url_EMEA: "https://ebiz.oktapreview.com/oauth2/auslle1jli7NKpqm70h7/v1/authorize",
		method: "GET"
	},
	userInfo: {
		url_NA: "https://ebiz.oktapreview.com/oauth2/auslle1jli7NKpqm70h7/v1/userinfo",
		url_LAO: "https://ebiz.oktapreview.com/oauth2/auslle1jli7NKpqm70h7/v1/userinfo",
		url_EMEA: "https://ebiz.oktapreview.com/oauth2/auslle1jli7NKpqm70h7/v1/userinfo",
		method: "GET"
	},
	productcategory: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-db-ircp-sys-api-tb/v2/na/en-us/productcategory",
		url_LAO: "https://us1.api.kimclark.com/kcc-sls-db-ircp-sys-api-tb/v2/lao/en-us/productcategory",
		url_EMEA: " ",
		url_APAC: " ",
		method: "GET"
	},
	userProfile: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/CustomerV2/GetDefaultCustomerInfo",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/CustomerV2/GetDefaultCustomerInfo",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/v2/CustomerV2/GetDefaultCustomerInfo",	
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/v2/CustomerV2/GetDefaultCustomerInfo",		
		method: "GET"
	},
	dashboardcount: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/Dashboard",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/Dashboard",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/v2/Dashboard",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/v2/Dashboard",
		method: "GET"
	},
	endUserCount: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-enduser-pxy-api-tb/na/v2/EndUserService/EndUserRequestsDashboardCount",
		url_LAO: "https://us1.api.kimclark.com/kcc-sls-enduser-pxy-api-tb/lao/v2/EndUserService/EndUserRequestsDashboardCount",
		method: "GET"
	},
	SearchByFields: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/PriceAgreement/BySearchFields",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/PriceAgreement/BySearchFields",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-db-ircp-sys-api-tb/v2/emea/en-us/Priceagreement/BySearchFields",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/v2/Priceagreement/BySearchFields",
		method: "POST"
	},
	hamburgerMenu: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-tb/na/v2/MenuService/GetHambergMenu",
		url_LAO: "https://kcc-sls-laokcpuser-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/MenuService/GetHambergMenu",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-tb/EMEA/v2/MenuService/GetHambergMenu",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apackcpuser-pxy-api-tb/APAC/v2/MenuService/GetHambergMenu",		
		method: "GET"
	},
	removeSearch: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-tb/na/v2/UserService/RemoveFavoriteSearch",
		url_LAO: "https://kcc-sls-laokcpuser-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserService/RemoveFavoriteSearch",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-tb/EMEA/v2/UserService/RemoveFavoriteSearch",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apackcpuser-pxy-api-tb/APAC/v2/UserService/RemoveFavoriteSearch",
		method: "POST"
	},
	saveRecentSearch: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-tb/na/v2/UserService/SaveSearch",
		url_LAO: "https://kcc-sls-laokcpuser-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserService/SaveSearch",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-tb/EMEA/v2/UserService/SaveSearch",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apackcpuser-pxy-api-tb/APAC/v2/UserService/SaveSearch",
		method: "POST"
	},
	markSearchFavrouite: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-tb/na/v2/UserService/SaveFavoriteSearch",
		url_LAO: "https://kcc-sls-laokcpuser-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserService/SaveFavoriteSearch",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-tb/EMEA/v2/UserService/SaveFavoriteSearch",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apackcpuser-pxy-api-tb/APAC/v2/UserService/SaveFavoriteSearch",
		method: "POST"
	},
	getRecentSearch: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-tb/na/v2/UserService/GetRecentSearch",
		url_LAO: "https://kcc-sls-laokcpuser-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserService/GetRecentSearch",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-tb/EMEA/v2/UserService/GetRecentSearch",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apackcpuser-pxy-api-tb/APAC/v2/UserService/GetRecentSearch",
		method: "GET"
	},
	getSavedSearch: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-tb/na/v2/UserService/GetFavoriteSearch",
		url_LAO: "https://kcc-sls-laokcpuser-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserService/GetFavoriteSearch",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-tb/EMEA/v2/UserService/GetFavoriteSearch",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apackcpuser-pxy-api-tb/APAC/v2/UserService/GetFavoriteSearch",
		method: "GET"
	},
	transactionCenterMenu: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-tb/na/v2/MenuService/GetTransactionCenterMenu",
		url_LAO: "https://kcc-sls-laokcpuser-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/MenuService/GetTransactionCenterMenu",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-tb/EMEA/v2/MenuService/GetTransactionCenterMenu",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apackcpuser-pxy-api-tb/APAC/v2/MenuService/GetTransactionCenterMenu",
		method: "GET"
	},
	learningCenterMenu: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-tb/na/v2/MenuService/GetLearningCenterMenu",
		url_LAO: "https://kcc-sls-laokcpuser-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/MenuService/GetLearningCenterMenu",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-tb/EMEA/V2/MenuService/GetLearningCenterMenu",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apackcpuser-pxy-api-tb/APAC/v2/MenuService/GetLearningCenterMenu",
		method: "GET"
	},
	markFavorite: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-tb/na/v2/UserService/SaveFavoriteContract",
		url_LAO: "https://kcc-sls-laokcpuser-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserService/SaveFavoriteContract",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-tb/EMEA/v2/UserService/SaveFavoriteContract",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apackcpuser-pxy-api-tb/APAC/v2/UserService/SaveFavoriteContract",
		method: "POST"
	},
	markUnFavorite: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-tb/na/v2/UserService/RemoveFavoriteContract",
		url_LAO: "https://kcc-sls-laokcpuser-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserService/RemoveFavoriteContract",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-tb/EMEA/v2/UserService/RemoveFavoriteContract",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apackcpuser-pxy-api-tb/APAC/v2/UserService/RemoveFavoriteContract",
		method: "POST"
	},
	getFavorite: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-tb/na/v2/UserService/GetFavoriteContract",
		url_LAO: "https://kcc-sls-laokcpuser-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserService/GetFavoriteContract",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-tb/EMEA/v2/UserService/GetFavoriteContract",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apackcpuser-pxy-api-tb/APAC/v2/UserService/GetFavoriteContract",		
		method: "GET"
	},
	getCustomerInfo: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/NA/v2/CustomerV2/custinformation",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/CustomerV2/custinformation",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/v2/CustomerV2/custinformation",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/v2/CustomerV2/custinformation",
		method: "POST"
	},
	getDashboardTilesData: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/Dashboard/BySearchFields",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/Dashboard/BySearchFields",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/v2/Dashboard/BySearchFields",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/v2/Dashboard/BySearchFields",
		method: "POST"
	},	
	getContractDetail: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/PriceAgreement/ContractDetail",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/PriceAgreement/ContractDetail",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/V2/PriceAgreement/ContractDetail",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/V2/PriceAgreement/ContractDetail",
		method: "POST"
	},
	getContractCustomerList: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/V2/PriceAgreement/ContractCustomerSoldTo",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/V2/PriceAgreement/ContractCustomerSoldTo",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/V2/PriceAgreement/ContractCustomerSoldTo",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/V2/PriceAgreement/ContractCustomerSoldTo",
		method: "POST"
	},
	getTradeOrgResults: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/ContractGroup/GetContractGroupBy",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/ContractGroup/GetContractGroupBy",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/v2/ContractGroupV2/ContractGroupV2_GetContractGroupBy",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/v2/ContractGroupV2/ContractGroupV2_GetContractGroupBy",
		method: "POST"
	},
	getProductSearchModalResults: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/ContractProduct/GetProductBySearchFields",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/ContractProduct/GetProductBySearchFields",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/V2/ContractProduct/GetProductBySearchFields",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/V2/ContractProduct/GetProductBySearchFields",
		method: "POST"
	},
	geCustomerListResults: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/CustomerV2/GetMappedCustomerInfo",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/CustomerV2/GetMappedCustomerInfo",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/V2/CustomerV2/GetMappedCustomerInfo",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/V2/CustomerV2/GetMappedCustomerInfo",
		method: "POST"
	},
	getProductResultData: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-db-ircp-sys-api-tb/v2/na/en-us/ContractProduct/GetContractProductBy",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/ContractProduct/GetContractProductBy",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/v2/ContractProduct/GetContractProductBy",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/v2/ContractProduct/GetContractProductBy",
		method: "POST"
	},
	getTradeOrgData: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/ContractGroup/GetContractEndUserByGroup",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/ContractGroup/GetContractEndUserByGroup",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/V2/ContractGroup/GetContractEndUserByGroup",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/V2/ContractGroup/GetContractEndUserByGroup",
		method: 'POST'
	},
	getEndUsersResultData: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/ContractEndUser/GetContractEndUserBy",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/ContractEndUser/GetContractEndUserBy",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/v2/ContractEndUser/GetContractEndUserBy",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/v2/ContractEndUser/GetContractEndUserBy",
		method: "POST"
	},
	getUserNotes: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-tb/na/v2/UserService/GetUserNotes",
		url_LAO: "https://kcc-sls-laokcpuser-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserService/GetUserNotes",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-tb/EMEA/v2/UserService/GetUserNotes",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apackcpuser-pxy-api-tb/APAC/v2/UserService/GetUserNotes",
		method: "POST"  
	},
	saveUserNotes: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-tb/na/v2/UserService/SaveUserNotes",
		url_LAO: "https://kcc-sls-laokcpuser-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserService/SaveUserNotes",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-tb/EMEA/v2/UserService/SaveUserNotes",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apackcpuser-pxy-api-tb/APAC/v2/UserService/SaveUserNotes",
		method: "POST"
	},
	deleteUserNotes: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-tb/na/v2/UserService/UserNotes/",
		url_LAO: "https://kcc-sls-laokcpuser-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserService/UserNotes/",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-tb/EMEA/v2/UserService/UserNotes/",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apackcpuser-pxy-api-tb/APAC/v2/UserService/UserNotes/",
		method: "DELETE"
	},
	getKCPUserUpdates: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/ChangeTracking/KCPUserUpdates",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/ChangeTracking/KCPUserUpdates",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/v2/ChangeTracking/KCPUserUpdates",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/v2/ChangeTracking/KCPUserUpdates",
		method: "POST"
	},
	KCPContent: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-tb/na/v2/Content/GetKCPContent",
		url_LAO: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-tb/LAO/v2/Content/GetKCPContent",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-tb/EMEA/v2/Content/GetKCPContent",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-tb/APAC/v2/Content/GetKCPContent",
		method: "POST"
	},
	getAccptancePolicyStatus: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-tb/na/v2/UserService/GetUserAgreementAcceptanceStatus",
		url_LAO: "https://kcc-sls-laokcpuser-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserService/GetUserAgreementAcceptanceStatus",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-tb/EMEA/v2/UserService/GetUserAgreementAcceptanceStatus",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apackcpuser-pxy-api-tb/APAC/v2/UserService/GetUserAgreementAcceptanceStatus",
		method: "POST"
	},
	setAccptancePolicyStatus: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-tb/na/v2/UserService/SaveUserAgreementAcceptanceStatus",
		url_LAO: "https://kcc-sls-laokcpuser-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserService/SaveUserAgreementAcceptanceStatus",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-tb/EMEA/v2/UserService/SaveUserAgreementAcceptanceStatus",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apackcpuser-pxy-api-tb/APAC/v2/UserService/SaveUserAgreementAcceptanceStatus",
		method: "POST"
	},
	announcementsModalDetails: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-db-ircp-sys-api-tb/v2/na/en-us/announcementmessage",
		url_LAO: "https://us1.api.kimclark.com/kcc-sls-db-ircp-sys-api-tb/v2/lao/en-us/announcementmessage",
		url_EMEA: " ",
		method: "POST"
	},
	messagesModalDetails: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-db-ircp-sys-api-tb/v2/na/en-us/messagedetails",
		url_LAO: "https://us1.api.kimclark.com/kcc-sls-db-ircp-sys-api-tb/v2/lao/en-us/messagedetails",
		url_EMEA: " ",
		method: "POST"
	},
	download: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/Download/DownloadPAAsync",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/Download/DownloadPAAsync",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/v2/Download/DownloadPAAsync",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/v2/Download/DownloadPAAsync",
		method: "POST"
	},
	downloadPADetails: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/Download/DownloadPADetailAsync",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/Download/DownloadPADetailAsync",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/v2/Download/DownloadPADetailAsync",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/v2/Download/DownloadPADetailAsync",
		method: "POST"
	},
	downloadPADetailsExcelFile: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/Export/GetContractDetailExcel",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/Export/GetContractDetailExcel",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/v2/Export/GetContractDetailExcel",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/v2/Export/GetContractDetailExcel",
		method: "POST"
	},
	saveSearchName: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-tb/na/v2/UserService/SaveSearchName",
		url_LAO: "https://kcc-sls-laokcpuser-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserService/SaveSearchName",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-tb/EMEA/v2/UserService/SaveSearchName",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apackcpuser-pxy-api-tb/APAC/v2/UserService/SaveSearchName",
		method: "POST"
	},
	getPAType: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/PriceAgreement/patype",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/PriceAgreement/patype",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/V2/PriceAgreement/patype",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/V2/PriceAgreement/patype",
		method: "GET"
	},
	getEndUserRequests: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-enduser-pxy-api-tb/na/v2/EndUserService/GetEndUserRequests",
		url_LAO: "https://us1.api.kimclark.com/kcc-sls-enduser-pxy-api-tb/lao/v2/EndUserService/GetEndUserRequests",
		method: "POST"
	},
	getStateList: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-db-ircp-sys-api-tb/v2/na/en-us/state",
		url_LAO: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-tb/v2/Master/GetState",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-tb/v2/Master/GetState",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-tb/v2/Master/GetState",
		method: "POST"
	},
	endusersearch: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/ContractEndUser/GetEndUsersBySearchFields",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/ContractEndUser/GetEndUsersBySearchFields",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/v2/ContractEndUser/GetEndUsersBySearchFields",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/v2/ContractEndUser/GetEndUsersBySearchFields",
		method: "POST"
	},
	tradeHQSearch: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/ContractGroup/GetTradeOrgBySearchFields",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/ContractGroup/GetTradeOrgBySearchFields",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/V2/ContractGroup/GetTradeOrgBySearchFields",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/V2/ContractGroup/GetTradeOrgBySearchFields",
		method: "POST"
	},
	customerSearch: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/CustomerV2/GetCustomerBySearchFields",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/CustomerV2/GetCustomerBySearchFields",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/v2/CustomerV2/GetCustomerBySearchFields",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/v2/CustomerV2/GetCustomerBySearchFields",
		method: "POST"
	},
	recentDownloadGrid: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-tb/na/v2/RecentDownload?isGlobalUser=false",
		url_LAO: "https://kcc-sls-laokcpuser-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/RecentDownload?isGlobalUser=false",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-tb/EMEA/v2/RecentDownload",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apackcpuser-pxy-api-tb/APAC/v2/RecentDownload",
		method: "GET"
	},
	recentDownloadDelete: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-tb/na/v2/RecentDownload/DeleteRecentDownloads",
		url_LAO: "https://kcc-sls-laokcpuser-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/RecentDownload/DeleteRecentDownloads",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-tb/EMEA/v2/RecentDownload/DeleteRecentDownloads",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apackcpuser-pxy-api-tb/APAC/v2/RecentDownload/DeleteRecentDownloads",
		method: "DELETE"
	},
	downloadCustomerList: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-tb/na/v2/RecentDownload/GetDownloadCustomer",
		url_LAO: "https://kcc-sls-laokcpuser-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/RecentDownload/GetDownloadCustomer",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-tb/EMEA/v2/RecentDownload/GetDownloadCustomer",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apackcpuser-pxy-api-tb/APAC/v2/RecentDownload/GetDownloadCustomer",
		method: "POST"
	},
	validateAddress: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-db-ircp-sys-api-tb/v2/na/en-us/addressvalidation",
		url_LAO: "https://us1.api.kimclark.com/kcc-sls-db-ircp-sys-api-tb/v2/lao/en-us/addressvalidation",
		url_EMEA: " ",
		method: "POST"
	},
	submitEndUsers: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-enduser-pxy-api-tb/na/v2/EndUserService/SaveEndUsers",
		url_LAO: "https://us1.api.kimclark.com/kcc-sls-enduser-pxy-api-tb/lao/v2/EndUserService/SaveEndUsers",
		method: "POST"
	},
	ClearCache: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-tb/v2/Master/ClearCache",
		url_LAO: "https://kcc-sd-kcpuser-ux-api2-tb.us-e1.cloudhub.io/v2/lao/en-us/ClearCache",
		url_EMEA: " ",
		method: "POST"
	},
	downloadPadetailsExcel: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/Export/GetContractDetailExcel",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/Export/GetContractDetailExcel",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/v2/Export/GetContractDetailExcel",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/v2/Export/GetContractDetailExcel",
		method: "POST"
	},
	selectedrecentDownloadDoculent: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-tb/na/v2/RecentDownload/GetDownloadLink",
		url_LAO: "https://kcc-sls-laokcpuser-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/RecentDownload/GetDownloadLink",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-tb/EMEA/v2/RecentDownload/GetDownloadLink",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apackcpuser-pxy-api-tb/APAC/v2/RecentDownload/GetDownloadLink",
		method: "POST"
	},
	endUserRequestsDownload: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-enduser-pxy-api-tb/na/v2/EndUserService/EndUserRequestsExport",
		url_LAO: "https://us1.api.kimclark.com/kcc-sls-enduser-pxy-api-tb/lao/v2/EndUserService/EndUserRequestsExport",
		url_EMEA: " ",
		method: "POST"
	},
	myNotification: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/ChangeTracking/GetMyNotifications",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/ChangeTracking/GetMyNotifications",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/v2/ChangeTracking/GetMyNotifications",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/v2/ChangeTracking/GetMyNotifications",
		method: "POST"
	},
	getWelcomeFlag: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-tb/na/v2/UserService/GetFlag",
		url_LAO: "https://kcc-sls-laokcpuser-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserService/GetFlag",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-tb/EMEA/v2/UserService/GetFlag",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apackcpuser-pxy-api-tb/APAC/v2/UserService/GetFlag",
		method: "GET"
	},
	updateWelcomeFlag: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-tb/na/v2/UserService/UpdateFlag",
		url_LAO: "https://kcc-sls-laokcpuser-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserService/UpdateFlag",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-tb/EMEA/v2/UserService/UpdateFlag",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apackcpuser-pxy-api-tb/APAC/v2/UserService/UpdateFlag",
		method: "GET"
	},
	exportPriceAgreementSearch: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/Export/PriceAgreementSearch",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/Export/PriceAgreementSearch",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/emea/v2/Export/PriceAgreementSearch",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/v2/Export/PriceAgreementSearch",
		method: "POST"
	},
	exportPADetailEndUser: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/Export/ContractEndUsers",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/Export/ContractEndUsers",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/v2/Export/ContractEndUsers",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/v2/Export/ContractEndUsers",
		method: "POST"
	},
	exportPADetailProduct: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/Export/ContractProducts",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/Export/ContractProducts",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/v2/Export/ContractProducts",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/v2/Export/ContractProducts",
		method: "POST"
	},
	exportPADetailTradeOrg: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/Export/ContractGroups",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/Export/ContractGroups",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/v2/Export/ContractGroups",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/v2/Export/ContractGroups",
		method: "POST"
	},
	exportMyNotification: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/Export/GetMyNotification",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/Export/GetMyNotification",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/v2/Export/GetMyNotification",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/v2/Export/GetMyNotification",
		method: "POST"
	},
	exportDashboardTiles: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/Export/DashboardPriceAgreements",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/Export/DashboardPriceAgreements",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/V2/Export/DashboardPriceAgreements",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/V2/Export/DashboardPriceAgreements",
		method: "POST"
	},
	getLocations: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-tb/v2/Master/GetMasterDetails",
		url_LAO: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-tb/v2/Master/GetMasterDetails",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-tb/v2/Master/GetMasterDetails",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-tb/v2/Master/GetMasterDetails",
		method: "GET"
	},
	getContentService: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-tb/na/v2/Content/GetKCPContent",
		url_LAO: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-tb/LAO/v2/Content/GetKCPContent",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-tb/EMEA/v2/Content/GetKCPContent",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-tb/APAC/v2/Content/GetKCPContent",
		method: "POST"
	},
	getAccessManagementList: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-tb/na/v2/Master/FeatureList",
		url_LAO: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-tb/LAO/v2/Master/FeatureList",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-tb/EMEA/v2/Master/FeatureList",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-tb/APAC/v2/Master/FeatureList",
		method: "POST"
	},
	registerUser: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-kcpreg-pxy-api-tb/na/v2/UserRegistration/UserRegistration",
		url_LAO: "https://us1.api.kimclark.com/kcc-sls-kcpreg-pxy-api-tb/LAO/v2/UserRegistration/UserRegistration",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-kcpreg-pxy-api-tb/EMEA/v2/UserRegistration/UserRegistration",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-kcpreg-pxy-api-tb/APAC/v2/UserRegistration/UserRegistration",
		method: "POST"
	},
	getUserDetails: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-tb/na/v2/UserService/GetUserDetails",
		url_LAO: "https://kcc-sls-laokcpuser-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserService/GetUserDetails",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-tb/EMEA/v2/UserService/GetUserDetails",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apackcpuser-pxy-api-tb/APAC/v2/UserService/GetUserDetails",
		method: "GET"
	},
	captchaValidationUrl: {
		url_NA: "https://kcc-sls-ang-kcpreg-exp-api.br-s1.cloudhub.io/ReCaptcha",
		url_LAO: "https://kcc-sls-ang-kcpreg-exp-api.br-s1.cloudhub.io/ReCaptcha",
		url_EMEA: "https://kcc-sls-ang-kcpreg-exp-api.br-s1.cloudhub.io/ReCaptcha",
		url_APAC: "https://kcc-sls-ang-kcpreg-exp-api.br-s1.cloudhub.io/ReCaptcha",
		method: 'POST'
	},
	saveUpdateUserProfile: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-tb/na/v2/UserService/SaveUpdateUserProfile",
		url_LAO: "https://kcc-sls-laokcpuser-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserService/SaveUpdateUserProfile",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-tb/EMEA/v2/UserService/SaveUpdateUserProfile",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apackcpuser-pxy-api-tb/APAC/v2/UserService/SaveUpdateUserProfile",
		method: 'POST'
	},
	CheckPAUpdates: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/Dashboard/CheckPAUpdates",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/Dashboard/CheckPAUpdates",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/v2/Dashboard/CheckPAUpdates",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/v2/Dashboard/CheckPAUpdates",
		method: 'GET'
	},
	/** My Profile Related APIs */
	GetUserRoles: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-tb/v2/Master/GetjobRole",
		url_LAO: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-tb/v2/Master/GetjobRole",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-tb/v2/Master/GetjobRole",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-tb/v2/Master/GetjobRole",
		method: 'GET'
	},
	searchUsers: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/UserCustomerMapping/SearchUser",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserCustomerMapping/SearchUser",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/v2/UserCustomerMapping/SearchUser",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/v2/UserCustomerMapping/SearchUser",
		method: 'GET'
	},
	
	searchDelegatedUsers: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/UserCustomerMapping/SearchUserForInternalAdmin",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserCustomerMapping/SearchUserForInternalAdmin",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/v2/UserCustomerMapping/SearchUserForInternalAdmin",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/v2/UserCustomerMapping/SearchUserForInternalAdmin",
		method: 'POST'
	},
	GetCountrySalesOrgLanguage: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/UserCustomerMapping/UserCustomerCountries/UserCustomerCountries",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserCustomerMapping/UserCustomerCountries/UserCustomerCountries",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/v2/UserCustomerMapping/UserCustomerCountries/UserCustomerCountries",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/v2/UserCustomerMapping/UserCustomerCountries/UserCustomerCountries",
		method: 'GET'
	},
	UserCustomerCountries: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/UserCustomerMapping/UserCustomerCountries/UserCustomerCountries",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserCustomerMapping/UserCustomerCountries/UserCustomerCountries",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/v2/UserCustomerMapping/UserCustomerCountries/UserCustomerCountries",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/v2/UserCustomerMapping/UserCustomerCountries/UserCustomerCountries",
		method: 'GET'
	},
	GetSortBy: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-tb/v2/Master/SortBy",
		url_LAO: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-tb/v2/Master/SortBy",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-tb/v2/Master/SortBy",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-tb/v2/Master/SortBy",
		method: 'POST'
	},
	SaveUserDefaultProfile: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-tb/na/v2/UserService/SaveUserDefaultProfile",
		url_LAO: "https://kcc-sls-laokcpuser-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserService/SaveUserDefaultProfile",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-tb/EMEA/v2/UserService/SaveUserDefaultProfile",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apackcpuser-pxy-api-tb/APAC/v2/UserService/SaveUserDefaultProfile",
		method: 'POST'
	},
	UserDefaultLocations: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/UserCustomerMapping/UserDefaultLocations",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserCustomerMapping/UserDefaultLocations",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/V2/UserCustomerMapping/UserDefaultLocations",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/V2/UserCustomerMapping/UserDefaultLocations",
		method: 'GET'
	},
	getLocationData: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/UserCustomerMapping/GetUserCustomerPreference",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserCustomerMapping/GetUserCustomerPreference",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/V2/UserCustomerMapping/GetUserCustomerPreference",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/V2/UserCustomerMapping/GetUserCustomerPreference",
		method: 'GET'
	},
	saveLocationData: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/UserCustomerMapping/SaveUserCustomerPreference",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserCustomerMapping/SaveUserCustomerPreference",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/V2/UserCustomerMapping/SaveUserCustomerPreference",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/V2/UserCustomerMapping/SaveUserCustomerPreference",
		method: 'POST'
	},
	/** My Profile Related APIs End here */
	/** UC Mapping */
	getUsersAssociated: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/UserCustomerMapping/GetUserAssociatedToMapping",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserCustomerMapping/GetUserAssociatedToMapping",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/v2/UserCustomerMapping/GetUserAssociatedToMapping",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/v2/UserCustomerMapping/GetUserAssociatedToMapping",
		method: "POST"
	},
	
	//below apis are for UCmapping/portalusage and my profile too. Hence kept here too	
	removeUcMappings: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/UserCustomerMapping/DeleteUserCustomerMapping",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserCustomerMapping/DeleteUserCustomerMapping",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/V2/UserCustomerMapping/DeleteUserCustomerMapping",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/V2/UserCustomerMapping/DeleteUserCustomerMapping",
		method: "POST"
	},
	markAsAdmin: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/UserCustomerMapping/SetGroupAdmin",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserCustomerMapping/SetGroupAdmin",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/v2/UserCustomerMapping/SetGroupAdmin",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/v2/UserCustomerMapping/SetGroupAdmin",
		method: "POST"
	},
	addUcMappings: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/UserCustomerMapping/SaveUserCustomerMapping",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserCustomerMapping/SaveUserCustomerMapping",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/V2/UserCustomerMapping/SaveUserCustomerMapping",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/V2/UserCustomerMapping/SaveUserCustomerMapping",
		method: "POST"
	},
	getStates: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-tb/v2/Master/GetState",
		url_LAO: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-tb/v2/Master/GetState",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-tb/v2/Master/GetState",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-tb/v2/Master/GetState",
		method: "POST"
	},
	ucMappingCustomerSearch: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/CustomerV2/GetCustomerBySearchFields",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/CustomerV2/GetCustomerBySearchFields",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/v2/CustomerV2/GetCustomerBySearchFields",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/v2/CustomerV2/GetCustomerBySearchFields",
		method: "POST"
	},
	ucMappings: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/UserCustomerMapping/GetUserCustomerMapping",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserCustomerMapping/GetUserCustomerMapping",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/v2/UserCustomerMapping/GetUserCustomerMapping",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/v2/UserCustomerMapping/GetUserCustomerMapping",
		method: "POST"
	},
	customerMappings: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/CustomerV2/GetCustomerHierarchy",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/CustomerV2/GetCustomerHierarchy",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/v2/CustomerV2/GetCustomerHierarchy",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/v2/CustomerV2/GetCustomerHierarchy",
		method: "POST"
	},
	portalUsageInternal: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/UserCustomerMapping/SearchUserForInternalAdmin",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserCustomerMapping/SearchUserForInternalAdmin",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/v2/UserCustomerMapping/SearchUserForInternalAdmin",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/v2/UserCustomerMapping/SearchUserForInternalAdmin",
		method: "POST"
	},
	portalUsageExternal: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/UserCustomerMapping/SearchUserForExternalAdmin",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserCustomerMapping/SearchUserForExternalAdmin",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/v2/UserCustomerMapping/SearchUserForExternalAdmin",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/v2/UserCustomerMapping/SearchUserForExternalAdmin",
		method: "POST"
	},
	exportPortalUsageInternal: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/Export/SearchUserForInternalAdmin",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/Export/SearchUserForInternalAdmin",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/v2/Export/SearchUserForInternalAdmin",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/v2/Export/SearchUserForInternalAdmin",
		method: "POST"
	},
	exportPortalUsageExternal: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/Export/SearchUserForExternalAdmin",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/Export/SearchUserForExternalAdmin",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/v2/Export/SearchUserForExternalAdmin ",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/v2/Export/SearchUserForExternalAdmin ",
		method: "POST"
	},
	//above apis are for UCmapping/portalusage and my profile too. Hence kept here too
	
	saveUserContact: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-tb/NA/v2/UserService/SaveUserContact",
		url_LAO: "https://kcc-sls-laokcpuser-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserService/SaveUserContact",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-tb/EMEA/v2/UserService/SaveUserContact",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apackcpuser-pxy-api-tb/APAC/v2/UserService/SaveUserContact",
		method: "POST"
	},
	recentDownloadExport: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-tb/na/v1/Export/RecentDownloadsExport",
		url_LAO: "https://kcc-sls-laokcpuser-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/Export/RecentDownloadsExport",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-tb/EMEA/v2/Export/RecentDownloadsExport",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apackcpuser-pxy-api-tb/APAC/v2/Export/RecentDownloadsExport",
		method: "POST"
	},
	pardot: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-tb/na/v2/pardot",
		url_LAO: "https://kcc-sls-laokcpuser-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/pardot",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-tb/EMEA/v2/Pardot",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apackcpuser-pxy-api-tb/APAC/v2/Pardot",
		method: "POST"
	},
	GlobalUserAuditLog: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-tb/na/v2/UserService/GlobalUserAuditLog",
		url_LAO: "https://kcc-sls-laokcpuser-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserService/GlobalUserAuditLog",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-tb/EMEA/v2/UserService/GlobalUserAuditLog",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apackcpuser-pxy-api-tb/APAC/v2/UserService/GlobalUserAuditLog",
		method: "POST"
	},
	getNewlyAddedCustomers: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/UserCustomerMapping/GetNewlyAddedCustomers",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserCustomerMapping/GetNewlyAddedCustomers",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/v2/UserCustomerMapping/GetNewlyAddedCustomers",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/v2/UserCustomerMapping/GetNewlyAddedCustomers",
		method: "POST"
	},
	getSalesOrgNames: {
		url_NA: "",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/SalesOrg/GetSalesOrgDescription",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/v2/SalesOrg/GetSalesOrgDescription",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/v2/SalesOrg/GetSalesOrgDescription",
		method: "GET"
	},
	getKCOwnerNames: {
		url_NA: "",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/PriceAgreement/KCOwner",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/v2/PriceAgreement/KCOwner",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/v2/PriceAgreement/KCOwner",
		method: "GET"
	},
	multipleChanges:{
		url_NA: "",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/ChangeTracking/KCPUserUpdatesByDuration",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/v2/ChangeTracking/KCPUserUpdatesByDuration",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/v2/ChangeTracking/KCPUserUpdatesByDuration",
		method: "POST"
	},
	rebateDownload:{
		url_NA: "",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/Rebate/DownloadRebate",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/emea/v2/Rebate/DownloadRebate",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/v2/Rebate/DownloadRebate",
		method: "POST"
	},
	exportRebatePriceSearch:{
		url_NA: "",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/Export/RebateSearch",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/v2/Export/RebateSearch",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/v2/Export/RebateSearch",
		method: "POST"
	},
	getOrderPopupPreferences: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-tb/v2/Master/GetOrderpopupPreferences",
		url_LAO: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-tb/v2/Master/GetOrderpopupPreferences",
		url_EMEA: "",
		method: "GET"
	},
	getOrderPreferences: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/UserCustomerMapping/GetOrderPreferences",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserCustomerMapping/GetOrderPreferences",
		url_EMEA: "",
		method: "GET"
	},
	saveOrderPreferences: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/UserCustomerMapping/SaveOrderPreferences",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserCustomerMapping/SaveOrderPreferences",
		url_EMEA: "",
		method: "POST"
	},
	signalREndpointPA: {
		url_NA: "https://app-pam-kimclark-com-dev-ncus-2.azurewebsites.net/signalrhub/PAMHubEntryPoint",
		url_LAO: "https://app-lao-pam-kimclark-dev-bzs-1.azurewebsites.net/signalrhub/PAMHubEntryPoint",
		url_EMEA: "https://app-emea-pam-kimclar-dev-weur-2.azurewebsites.net/signalrhub/PAMHubEntryPoint",
		url_APAC: "https://app-apac-pam-kimclar-dev-cia-1.azurewebsites.net/signalrhub/PAMHubEntryPoint"
	},
	auditLogout: {
		url_NA: "",
		url_LAO: "https://kcc-sls-laokcpuser-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserService/Logout",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-tb/emea/v2/UserService/Logout",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apackcpuser-pxy-api-tb/APAC/v2/UserService/Logout",
		method: "GET"
	},
	spartaModifyPAURL: {
		url_NA: "https://qa3.kcsparta.com/createpa/modifypa?contractno=####&SFDC=0",
		url_LAO: "",
		url_EMEA: "https://qa3.kcsparta.com/createpa/modifypa?contractno=####&SFDC=0"
	},
	UCMSync: {
		url_NA: "​https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/UserCustomerMapping/TriggerUCMSync",
		url_LAO: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/UserCustomerMapping/TriggerUCMSync",
		url_EMEA: "",
		method: "POST"
	},
	hamburgerMenuPhaseThree:{
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-tb/na/v3/MenuServiceV3/GetHambergMenu",
		url_LAO: "",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-tb/EMEA/v3/MenuService/GetHambergMenu",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apackcpuser-pxy-api-tb/APAC/v3/MenuService/GetHambergMenu",
		method: "GET"
	},
	searchCategories: {
		url_NA: "https://stage.products.kcprofessional.com/rest/V1/category-list",
		url_LAO: "",
		url_EMEA: "",
		method: "POST"
	},
	searchProduct: {
		url_NA: "https://stage.products.kcprofessional.com/rest/V1/product-search",
		url_LAO: "",
		url_EMEA: "",
		method: "POST"
	},
	allProductsLink: {
		url_NA: "https://stage.products.kcprofessional.com/catalogsearch/result/",
		url_LAO: "",
		url_EMEA: "https://stage.products.kcprofessional.com/catalogsearch/result/",
		method: "GET"
	},
	cartCount: {
		url_NA: "https://stage.products.kcprofessional.com/rest/V1/na-cart-count",
		url_LAO: "",
		url_EMEA: "",
		method: "POST"
	},
	distributorCart: {
		url_NA: "https://stage.products.kcprofessional.com/distributor/cart/",
		url_LAO: "",
		url_EMEA: "",
		method: "GET"
	},
	transactionCenterMenuPilotUser: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-tb/na/v3/MenuServiceV3/GetTransactionCenterMenu",
		url_LAO: "",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-tb/EMEA/v3/MenuService/GetTransactionCenterMenu",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apackcpuser-pxy-api-tb/APAC/v3/MenuService/GetTransactionCenterMenu",
		method: "GET"
	}
}
export { endpoints_PA_DEV_TeamB };