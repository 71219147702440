import { Component } from '@angular/core';
import { cbManageErrorGridColMap } from '@app/conf/dataConfigurations';
import { AgRendererComponent } from 'ag-grid-angular';
import { GlobalAppService } from './../../../../Shared/Services/global-app.service';
import { CpAgGridService } from './../../../services/cp-ag-grid-service';

@Component({
  selector: 'errorcell',
  templateUrl: './efile-errorcell.component.html',
  styleUrls: ['./efile-errorcell.component.scss']
})

export class EfileErrorcellComponent implements AgRendererComponent {

  params: any;
  tooltipText: string = "";
  region: string = "";
  setTwoDecimalPlaces = false;
  constructor(private _globalAppService: GlobalAppService, private gridService: CpAgGridService
  ) { }

  agInit(params: any) {
    this.params = params;
    let colHeaderbasedFieldName;
    this.region = this._globalAppService.getDefaultRegionString();
    if (this.params.colDef && this.params.data && this.params.data.lineErrors && this.params.data.lineErrors.length) {
      this.params.data.lineErrors.forEach(item => {
        let field = this.params.colDef.field;
        colHeaderbasedFieldName = cbManageErrorGridColMap[(item.fieldName).toUpperCase()] || "";
        if ((item.fieldName).toUpperCase() === field.toUpperCase() || (field.toUpperCase() === colHeaderbasedFieldName.toUpperCase() && this.region == "LAO")) {
          this.tooltipText += '<i class="fas fa-exclamation-circle textRed"></i>&nbsp;' + item.errorMessage + '<br/>';
        }
      });
    }
    if (this.region == "LAO" && this.gridService.pageContext.pageName == 'ManageErrorKcFile') {
      let field = this.params.colDef.field;
      
      if ((field == "exT_RBT" || field == "d_EXT_RBT" || field == "diff") && this.params.value) {
        this.params.value = this.params.value.slice(0, this.params.value.indexOf(".") + 3);       
        if (this.params.value.indexOf(".") == -1) {
          this.params.value = this.params.value + ".00";
        }else if(this.params.value.indexOf(".") == this.params.value.length-2){
          this.params.value = this.params.value + "0";
        }
      }
    }
  }

  refresh(params: any): boolean {
    return true;
  }
}
