import { Component, OnInit } from '@angular/core';
import { CpAgGridService } from '@app/Modules/cp-grid/services/cp-ag-grid-service';
import { FreetextSearchComponent } from '@app/Modules/cp-grid/grid-features/filters/freetext-search/freetext-search.component';
import { CustomNoRowsOverlayComponent } from '@app/Modules/cp-grid/components/custom-no-rows-overlay/custom-no-rows-overlay.component';
import { CustomLoadingOverlayComponent } from '@app/Modules/cp-grid/components/custom-loading-overlay/custom-loading-overlay.component';
import { DelegatedUserSearch } from '@app/Modules/Shared/DataModels/SharedDataModel';
import { environment, cookieSIMID, APP_LEGACY_MENU_HOST, globalUserMagentoURL, globalUserMagentoURLEMEA } from '@env/environment';
import { RegionWiseColDefination } from "@app/conf/col-def-price-agreement/region-wise-col-conf";
import { GlobalAppService } from '@app/Modules/Shared/Services/global-app.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe'
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { CpColumnHeaderComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/column-header/cp-column-header.component';
import { HttpService } from '@app/Modules/Shared/Services/http.service';
import { TranslateService } from '@ngx-translate/core';
import { DateSelectorComponent } from '@app/Modules/cp-grid/grid-features/filters/date-selector/date-selector.component';
import { DateSelectorUFNComponent } from '@app/Modules/cp-grid/grid-features/filters/date-selector-ufn/date-selectorufn.component';
import { globalUserLink } from '@app/conf/appLink';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { MessageService } from 'primeng/api';

@AutoUnsubscribe()
@Component({
  selector: 'delegated-user-searchresult',
  templateUrl: './delegated-user-searchresult.component.html',
  styleUrls: ['./delegated-user-searchresult.component.scss'],
  providers: [CpAgGridService, MessageService]
})

export class DelegatedUserSearchResultComponent implements OnInit {
  columnDefs: any;
  defaultColDef: any;
  rowData: any = [];
  rowHeight;
  gridApi: any;
  gridOptions: any = {};
  searchRequestObj: DelegatedUserSearch;
  showGrid: boolean;
  paginationPageSize: number;
  subscription: Subscription = new Subscription();
  recordCount: number;
  allFilters: any;
  frameworkComponents: any
  noRowsOverlayComponent: string;
  rowModelType: string;
  cacheOverflowSize: number;
  maxConcurrentDatasourceRequests: number;
  infiniteInitialRowCount: number;
  maxBlocksInCache: number;
  showRowSelectionMsg: boolean = false;
  gridDateFormat: any;
  rowClassRules: any;
  loadingOverlayComponent: string;
  region: string;
  delegatedUserSearchColDef: any;
  processCellForClipboard: any;

  constructor(
    private gridService: CpAgGridService,
    private globalAppService: GlobalAppService,
    private appService: AppService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private httpServ: HttpService,
    private translateServ: TranslateService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.translateServ.get(['calender'])
      .subscribe(translations => {
        let calenderData = translations['calender'];
        this.gridDateFormat = calenderData.gridDateFormat;
      });
    this.setGridAttributes();
    this.loadRegionWiseColumnDefination();
  }

  async loadRegionWiseColumnDefination(){
    this.region = this.globalAppService.getDefaultRegionString();
    let colDefObj = new RegionWiseColDefination();
    let { delegatedUserSearchColDef } = await colDefObj.getRegionWiseColDef(this.region);
    this.delegatedUserSearchColDef = delegatedUserSearchColDef;
    this.setColumnDefinitions();
  }

  setColumnDefinitions() {
    this.subscription.add(this.globalAppService.getDefaultRegion().subscribe((region) => {
      let columnDefs;
      switch (region) {
        case 'LAO':
        case 'NA':
        case 'EMEA':
          columnDefs = this.delegatedUserSearchColDef;
          break;
      }
      // this.columnDefs = this.gridService.mapColDefs(columnDefs);
      this.columnDefs = this.gridService.mapColDefs(columnDefs).map((cd) => {
        let modifiedColDef: any = Object.assign({}, cd);
        if (modifiedColDef.headerName === 'agGrid.agGridColumns.emailLabel') {
          modifiedColDef.width = 300;
          modifiedColDef.suppressSizeToFit = true;
        }
        return modifiedColDef;
      });
      this.gridService.columnDefs = this.columnDefs;
      this.defaultColDef = this.gridService.getDefaultColDef();
    }));
  }

  validateUserRoles(){
    if (this.gridApi && this.gridApi.getSelectedRows() && this.gridApi.getSelectedRows()[0]) {
      localStorage.setItem("temp-pre-impersonated-user",this.gridApi.getSelectedRows()[0].userId);
      this.httpServ.getUserDetails().subscribe(userDetails => {
        if(userDetails.ErrorType){
          this.messageService.add({ key: 'noEBAM', severity: 'Error', summary: 'No Roles in EBAM', detail: 'User does not have any roles assigned in EBAM' });
        } else {
          this.applySelection();
        }
      },(onError)=>{
        this.messageService.add({ key: 'noEBAM', severity: 'Error', summary: 'No Roles in EBAM', detail: 'User does not have any roles assigned in EBAM' });
      },()=>{
        localStorage.removeItem('temp-pre-impersonated-user');
      });
    } else{
      console.log("No User Selected To Imporsonate");
    }
    
     
  }

  applySelection() {
    this.appService.setLoadingStatus(true);
    let userId, email, name, phone;

    if (this.gridApi && this.gridApi.getSelectedRows() && this.gridApi.getSelectedRows()[0]) {
      userId = this.gridApi.getSelectedRows()[0].userId;
      email = this.gridApi.getSelectedRows()[0].email;
      name = this.gridApi.getSelectedRows()[0].firstName + " " + this.gridApi.getSelectedRows()[0].lastName;
      phone = this.gridApi.getSelectedRows()[0].phone;
    }

    this.httpServ.getImpersonatedUserInfo(userId).subscribe(userData => {
      if(userData && userData.roles && userData.regions){
        window.localStorage.setItem("impersonatedUserRoles", userData.roles.join());
        window.localStorage.setItem("impersonatedUserRegions", userData.regions.join());
      }
      this.appService.setLoadingStatus(false);
      this.region = this.globalAppService.getDefaultRegionString();
      let auditRequest = {
        "globalUserId": this.appService.getuserId(true),
        "externalUserId": userId,
        "action": 1
      };
      if (this.region === 'NA' || this.region === 'EMEA') {
        this.appService.setUserSimulated(true);
        let qsList: Array<string> = [
          'userid=' + userId,
          "useremail=" + email];
        document.cookie = cookieSIMID + "=" + userId + "~^" + name + "~*" + email + "~&" + phone + ";path=/";
        this.httpServ.globalUserAuditLog(auditRequest).subscribe((response)=>{
          if( this.region === 'NA' || (userData && userData.roles && 
             userData.roles.indexOf('EMEA_KCP_Catalog_Pilot_External') > -1)
          ){
            let queryStringList: Array<string> = [
              "email=" + email,
              'customer_number=' + userId
            ];
            let magentoExternalLoginURL = this.region == 'EMEA' ? globalUserMagentoURLEMEA : globalUserMagentoURL;
            window.open(magentoExternalLoginURL+ queryStringList.join("&"), '_self')
          } else {
            window.open(globalUserLink + qsList.join("&"), '_self');
          }
        });
      }
      else {
        document.cookie = cookieSIMID + "=" + userId + "~^" + name + "~*" + email + "~&" + phone + ";path=/";
        // this.router.navigate([{ outlets: { delegatedResults: null } }], { relativeTo: this.activatedRoute }).then(() => {
        let navigateUrl = APP_LEGACY_MENU_HOST;
        this.httpServ.globalUserAuditLog(auditRequest).subscribe((response)=>{
          window.location.href = navigateUrl;
        });
        // });
      }
    },
    () => {
      this.appService.setLoadingStatus(false);
    },
    () => {
      this.appService.setLoadingStatus(false);
    });
        
  }

  onGridReady(params) {
    this.gridService.onGridReady(params);
    this.gridApi = this.gridService.gridApi;
    this.setSearchRequestListener();
    this.setRecordCountListener();
  }

  onFilterChanged(event) {
    let filterModel: any = event.api.getFilterModel();
    this.paginationPageSize = this.gridService.clientPageSize;
    this.gridService.onFilterChanged();
    this.allFilters = this.gridService.allFilters;
  }

  onRemoveFilter(event, key) {
    // this.gridApi.deselectAll();
    this.gridService.removeFilters(key);
  }

  onColumnResized(event) {
  }

  onPageSizeChanged(event) {
    this.paginationPageSize = this.gridService.clientPageSize;
    if (this.paginationPageSize <= this.recordCount) {
      this.reinstatePage(this.globalAppService.getDelegatedUserSearchRequest(), false);
    }
    else {
      this.paginationPageSize = this.recordCount;
      this.gridApi.paginationSetPageSize(this.paginationPageSize);
    }
  }

  setSearchRequestListener() {
    let subscription = this.globalAppService.getDelegatedUserSearch().subscribe(searchRequest => {
      if (searchRequest) {
        this.searchRequestObj = searchRequest;
        this.showGrid = false;
        this.showRowSelectionMsg = false;
        this.paginationPageSize = this.gridService.clientPageSize;
        this.reinstatePage(searchRequest);
        this.gridService.setReloadGrid(true);
      }
    });
    this.subscription.add(subscription);
  }

  setRecordCountListener() {
    this.subscription.add(this.gridService.getRecordCount().subscribe(recordCount => {
      this.recordCount = recordCount;
      if (recordCount) {
        this.paginationPageSize = this.recordCount > this.paginationPageSize ? this.gridService.clientPageSize : this.recordCount;
      }
    }));
  }

  reinstatePage(searchRequest: any, clearState: boolean = true) {
    if (clearState) {
      this.recordCount = 0;
      let isFilterApplied: boolean = this.allFilters && this.allFilters.length > 0;
      if (isFilterApplied)
        this.gridService.clearFilterState();
      this.allFilters = this.gridService.allFilters;
      if (this.gridService.isSorted)
        this.gridService.clearSortState();
      if (!this.gridService.isSorted && !isFilterApplied) {
        this.registerDataSource(searchRequest);
      }
    }
    else {
      this.registerDataSource(searchRequest);
    }
    this.gridService.sizeColumnsToFit();
  }

  registerDataSource(searchRequest: any) {
    this.setPageContext(searchRequest);
    this.gridApi.setDatasource(this.gridService.getDataSource());
  }

  setPageContext(searchRequest: any) {
    let pageContext: any = {
      // url: environment.searchDelegatedUsers.url,
      url: this.httpServ.getRegionSpecificURL(environment.searchDelegatedUsers),
      verb: 'POST',
      pageName: 'delegatedSearchUsers',
      request: searchRequest,
      dateFormat: this.gridDateFormat
    };
    this.gridService.pageContext = pageContext;
  }

  setGridAttributes() {
    this.rowHeight = 36;
    this.gridService.gridRowModelType = this.gridService.getRowModelType();
    this.rowModelType = this.gridService.getRowModelType();
    this.paginationPageSize = this.gridService.clientPageSize;
    this.cacheOverflowSize = this.gridService.getCacheOverflowSize();
    this.maxConcurrentDatasourceRequests = this.gridService.getMaxConcurrentDatasourceRequests();
    this.infiniteInitialRowCount = this.gridService.getInfiniteInitialRowCount();
    this.maxBlocksInCache = this.gridService.getMaxBlocksInCache();
    this.gridOptions.context = this;
    this.gridOptions.getContextMenuItems = function () {
      return ['copy'];
    };
    this.processCellForClipboard = function (params) {      
      if (params.column.colDef.filter == "dateSelector" || params.column.colDef.filter == "dateSelectorUFN" || params.column.colDef.filter == "dateRangeSelector") {
        if (params.value) {
          return (params.value.indexOf('9999') > -1) ? 'UFN' : new Date(params.value).toLocaleDateString('en-US',{year:"numeric",month:"2-digit", day:"2-digit"});
        }
        else return '';
      }
      else {
        return params.value;
      }
    };
    this.frameworkComponents = {
      freeTextFilter: FreetextSearchComponent,
      dateSelector: DateSelectorComponent,
      dateSelectorUFN: DateSelectorUFNComponent,
      customNoRowsOverlay: CustomNoRowsOverlayComponent,
      agColumnHeader: CpColumnHeaderComponent,
      loadingOverlayComponent: CustomLoadingOverlayComponent
    };
    this.noRowsOverlayComponent = "customNoRowsOverlay";
    this.loadingOverlayComponent = 'loadingOverlayComponent';
    this.rowClassRules = this.gridService.gridRowClass();
  }

  onRowSelected(event) {
    if (event.api.getSelectedNodes() && event.api.getSelectedNodes()[0])
      this.showRowSelectionMsg = true;
    else this.showRowSelectionMsg = false;
  }

  exportExcelView() {
    this.appService.setLoadingStatus(true)
    let downloadObj = Object.assign({}, this.gridService.oRequest,{isGlobalSearchPage:"true"});
    this.httpServ.exportPortalUsage(downloadObj).subscribe(res => {
      if (res && res.fileUrl) {
        let opener = window.open(res.fileUrl, '_blank');
        // setTimeout(() => { opener.close() }, 500);
      }
      else
        this.messageService.add({ detail: 'An unexpected error occurred. Please try again.', summary: 'Error', severity: 'error' })
    },
      (e) => {
        this.messageService.add({ detail: 'An unexpected error occurred. Please try again.', summary: 'Error', severity: 'error' })
      },
      () => {
        this.appService.setLoadingStatus(false)
      })
  }

  ngOnDestroy() {

  }

}
