let endpoints_MC_DEV_TeamB = {
    mcGetCustomerDetailsGrid: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v1/MeetCompCustomerSearch/CustomerSoldTo",
		method: "POST"
	},

	exportMCCustomerDetailsGrid: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v1/MeetCompExport/CustomerSoldTo",
		method: "POST"
	},

	mcGetDistinctFilterCustomerDetailsGrid: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v1/MeetCompCustomerSearch/GridHeaderFilters",
		method: "POST"
	},

	mcGetProductsDetailsGrid: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/NA/v1/MeetComp/GetMeetCompProductBy",
		method: "POST"
	},

	exportMCProductsDetailsGrid: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/NA/v1/MeetCompExport/MeetCompProducts",
		method: "POST"
	},

	mcGetDistinctFilterProductsDetailsGrid: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v1/MeetComp/MCProdGridHeaderFilters",
		method: "POST"
	},

	mcGetMeetCompDetail: {
		// url_NA: 'https://kcc-sls-kcpreg-pxy-api-tb.us-e1.cloudhub.io/NA/v1/MeetComp/MeetCompDetail',
		url_NA: 'https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v1/MeetComp/MeetCompDetail',
		method: "POST"
	},
	mcDownloadMCDetails: {
		// url_NA: 'https://kcc-sls-ang-priceagreement-pxy-api-tb.us-e1.cloudhub.io/na/v1/MeetCompDownload/DownloadMCDetailAsync',
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v1/MeetCompDownload/DownloadMCDetailAsync",
		method: "POST"
	},
	mcDownloadMCDetailsExcelFile: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v1/MeetCompDownload/DownloadMCDetailAsync",
		method: "POST"
	},

	mcSearchResult: {
		url_NA: 'https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v1/MeetComp/ByMCSearchField',
		method: "POST"
	},

	mcGetDistinctFilterSearchGrid: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/NA/v1/MeetComp/MCSearchGridHeaderFilters",
		method: "POST"
	},

	mcExportSearchGrid: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/NA/v1/MeetCompExport/ByMCSearchField ",
		method: "POST"
	},

	DownloadMCAsync: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v1/MeetCompDownload/DownloadMCAsync",
		method: "POST"
	},

	signalREndpointMC: {
		url_NA: "https://app-pam-kimclark-com-dev-ncus-2-lane4.azurewebsites.net/signalrhub/MCHubEntryPoint",
		method: "POST"
	}


}
export { endpoints_MC_DEV_TeamB };