import * as tslib_1 from "tslib";
import { OnDestroy, OnInit, EventEmitter } from "@angular/core";
import { RegionWiseColDefination } from "@app/conf/col-def-chargeback/region-wise-col-conf";
import { CustomNoRowsOverlayComponent } from "@app/Modules/cp-grid/components/custom-no-rows-overlay/custom-no-rows-overlay.component";
import { CustomLoadingOverlayComponent } from '@app/Modules/cp-grid/components/custom-loading-overlay/custom-loading-overlay.component';
import { CpColumnHeaderComponent } from "@app/Modules/cp-grid/grid-features/cell-renderer/column-header/cp-column-header.component";
import { DateSelectorComponent } from "@app/Modules/cp-grid/grid-features/filters/date-selector/date-selector.component";
import { DaterangeSelectorComponent } from "@app/Modules/cp-grid/grid-features/filters/daterange-selector/daterange-selector.component";
import { FreetextSearchComponent } from "@app/Modules/cp-grid/grid-features/filters/freetext-search/freetext-search.component";
import { MultiSelectFilterComponent } from "@app/Modules/cp-grid/grid-features/filters/multi-select-filter/multi-select-filter.component";
import { SortonlyComponent } from '@app/Modules/cp-grid/grid-features/filters/sortonly/sortonly.component';
import { CpAgGridService } from "@app/Modules/cp-grid/services/cp-ag-grid-service";
import { AppService } from "@app/Modules/Shared/Services/app.service";
import { environment } from "@env/environment";
import { Subscription } from "rxjs";
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { HttpService } from '@app/Modules/Shared/Services/http.service';
import { GlobalAppService } from '@app/Modules/Shared/Services/global-app.service';
var MultipleClaimNumberListComponent = /** @class */ (function () {
    function MultipleClaimNumberListComponent(gridService, appServ, httpServ, globalAppService) {
        this.gridService = gridService;
        this.appServ = appServ;
        this.httpServ = httpServ;
        this.globalAppService = globalAppService;
        this.rowData = [];
        this.gridOptions = {};
        this.subscription = new Subscription();
        this.tolltipHoverClass = "tooltip-hover";
        this.showRowSelectionMsg = false;
        this.showGrid = false;
        this.filterKey = '';
        this.closedCustModal = new EventEmitter();
        this.visibleCustModal = true;
        this.pageSizes = this.gridService.getPageSizes();
        this.selectedPageSize = this.gridService.clientPageSize;
        this.setGridAttributes();
    }
    ;
    MultipleClaimNumberListComponent.prototype.ngOnInit = function () {
        this.recordCount = 0;
        this.loadRegionWiseColumnDefination();
        this.setGridLoadedListener();
        this.setScreenResizeListener();
    };
    MultipleClaimNumberListComponent.prototype.onGridReady = function (params) {
        this.gridService.onGridReady(params);
        this.gridApi = this.gridService.gridApi;
        this.reinstatePage(this.searchObj);
        this.setRecordCountListener();
    };
    MultipleClaimNumberListComponent.prototype.setScreenResizeListener = function () {
        var _this = this;
        var screenResizeSubscription = this.appServ.getScreenResize().subscribe(function (screenResized) {
            if (screenResized) {
                if (_this.gridApi)
                    _this.gridApi.sizeColumnsToFit();
            }
        });
        this.subscription.add(screenResizeSubscription);
    };
    MultipleClaimNumberListComponent.prototype.setRecordCountListener = function () {
        var _this = this;
        this.subscription.add(this.gridService
            .getRecordCount()
            .subscribe(function (recordCount) {
            _this.recordCount = recordCount;
            if (recordCount) {
                _this.paginationPageSize =
                    _this.recordCount > _this.paginationPageSize
                        ? _this.gridService.clientPageSize
                        : _this.recordCount;
                setTimeout(function () {
                    _this.isCountAvailable = true;
                }, 500);
            }
        }));
    };
    MultipleClaimNumberListComponent.prototype.setPageContext = function (searchRequest) {
        var pageContext = {
            url: this.httpServ.getRegionSpecificURL(environment.GetClaimNumbersByCoupon),
            verb: "POST",
            pageName: "multipleClaimNumber",
            request: this.searchObj
        };
        this.gridService.pageContext = pageContext;
    };
    MultipleClaimNumberListComponent.prototype.reinstatePage = function (searchRequest, clearState) {
        if (clearState === void 0) { clearState = true; }
        if (clearState) {
            this.recordCount = 0;
            var isFilterApplied = this.allFilters && this.allFilters.length > 0;
            if (isFilterApplied)
                this.gridService.clearFilterState();
            this.allFilters = this.gridService.allFilters;
            if (this.gridService.isSorted)
                this.gridService.clearSortState();
            if (!this.gridService.isSorted && !isFilterApplied) {
                this.registerDataSource(searchRequest);
            }
        }
        else {
            this.registerDataSource(searchRequest);
        }
        this.gridService.sizeColumnsToFit();
    };
    MultipleClaimNumberListComponent.prototype.registerDataSource = function (searchRequest) {
        this.setPageContext(this.searchObj);
        this.gridApi.setDatasource(this.gridService.getDataSource());
    };
    MultipleClaimNumberListComponent.prototype.setGridAttributes = function () {
        this.rowHeight = 30;
        this.gridService.gridRowModelType = this.gridService.getRowModelType();
        this.rowModelType = this.gridService.getRowModelType();
        this.paginationPageSize = this.gridService.clientPageSize;
        this.cacheOverflowSize = this.gridService.getCacheOverflowSize();
        this.maxConcurrentDatasourceRequests = this.gridService.getMaxConcurrentDatasourceRequests();
        this.infiniteInitialRowCount = this.gridService.getInfiniteInitialRowCount();
        this.maxBlocksInCache = this.gridService.getMaxBlocksInCache();
        this.getRowNodeId = this.getRowNode();
        this.gridOptions.context = this;
        this.gridOptions.getContextMenuItems = function () {
            return ['copy'];
        };
        this.frameworkComponents = {
            freeTextFilter: FreetextSearchComponent,
            multiSelectFilter: MultiSelectFilterComponent,
            dateSelector: DateSelectorComponent,
            dateRangeSelector: DaterangeSelectorComponent,
            agColumnHeader: CpColumnHeaderComponent,
            customNoRowsOverlay: CustomNoRowsOverlayComponent,
            sortonly: SortonlyComponent,
            loadingOverlayComponent: CustomLoadingOverlayComponent
        };
        this.rowSelection = this.gridService.getRowSelection();
        this.noRowsOverlayComponent = "customNoRowsOverlay";
        this.loadingOverlayComponent = 'loadingOverlayComponent';
    };
    MultipleClaimNumberListComponent.prototype.setGridLoadedListener = function () {
        var me = this;
    };
    MultipleClaimNumberListComponent.prototype.loadRegionWiseColumnDefination = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var colDefObj, multipleClaimNumberColDef;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.region = this.globalAppService.getDefaultRegionString();
                        colDefObj = new RegionWiseColDefination();
                        return [4 /*yield*/, colDefObj.getRegionWiseColDef(this.region)];
                    case 1:
                        multipleClaimNumberColDef = (_a.sent()).multipleClaimNumberColDef;
                        this.multipleClaimNumberColDef = multipleClaimNumberColDef;
                        this.setColumnDefinitions();
                        return [2 /*return*/];
                }
            });
        });
    };
    MultipleClaimNumberListComponent.prototype.setColumnDefinitions = function () {
        var colDefs = this.multipleClaimNumberColDef;
        this.columnDefs = this.gridService.mapColDefs(colDefs);
        this.gridService.columnDefs = this.columnDefs;
        this.defaultColDef = this.gridService.getDefaultColDef();
    };
    MultipleClaimNumberListComponent.prototype.onFilterChanged = function (event) {
        var filterModel = event.api.getFilterModel();
        this.paginationPageSize = this.gridService.clientPageSize;
        var isFilterApplied = Object.entries(filterModel).every(function (fm) { return fm[1]["value"] === null; });
        this.gridService.onFilterChanged();
        this.allFilters = this.gridService.allFilters;
    };
    MultipleClaimNumberListComponent.prototype.showTooltip = function (event) {
        this.tolltipHoverClass = this.gridService.getTooltipHoverClass(event);
    };
    MultipleClaimNumberListComponent.prototype.onRemoveFilter = function (event, key) {
        this.gridApi.deselectAll();
        this.gridService.removeFilters(key);
    };
    MultipleClaimNumberListComponent.prototype.onColumnResized = function (event) {
    };
    MultipleClaimNumberListComponent.prototype.onPageSizeChanged = function (event) {
        this.paginationPageSize = this.gridService.clientPageSize;
        if (this.paginationPageSize <= this.recordCount)
            this.reinstatePage(this.gridService.oRequest, false);
        else {
            this.paginationPageSize = this.recordCount;
            this.gridApi.paginationSetPageSize(this.paginationPageSize);
        }
    };
    MultipleClaimNumberListComponent.prototype.onRowSelected = function (event) {
        if (event.api.getSelectedNodes() && event.api.getSelectedNodes()[0])
            this.showRowSelectionMsg = true;
        else
            this.showRowSelectionMsg = false;
    };
    MultipleClaimNumberListComponent.prototype.getRowNode = function () {
        return function (item) {
            return item.RowIndex ? item.RowIndex : 0;
        };
    };
    MultipleClaimNumberListComponent.prototype.openDialog = function () {
        this.showGrid = true;
        this.isCountAvailable = false;
        this.recordCount = 0;
    };
    MultipleClaimNumberListComponent.prototype.close = function () {
        this.closedCustModal.emit({ close: 'false' });
    };
    MultipleClaimNumberListComponent.prototype.applySelection = function () {
        this.close();
    };
    MultipleClaimNumberListComponent.prototype.ngOnDestroy = function () {
    };
    MultipleClaimNumberListComponent = tslib_1.__decorate([
        AutoUnsubscribe(),
        tslib_1.__metadata("design:paramtypes", [CpAgGridService,
            AppService,
            HttpService,
            GlobalAppService])
    ], MultipleClaimNumberListComponent);
    return MultipleClaimNumberListComponent;
}());
export { MultipleClaimNumberListComponent };
