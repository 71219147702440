<div class="header__nav">
        <div class="header__nav__professional-link" *ngIf="showTopHeader == false">
                <a href="{{kcProfessionalLink}}" target="_blank" rel="noopener noreferrer">{{ "humburger.kcpTitle" |
                        translate }}
                        <i class="far fa-arrow-alt-circle-right"></i></a>
        </div>
        <!-- Added the spinner -->
        <div #mainTag>
                <div class="loadingImage" *ngIf="!menu">
                        &nbsp;
                </div>
        </div>
        <div class="d-md-none">
                <ul class="unlisted">

                        <li class="header__nav__has-sub-nav" (click)="passclicked('region')" *ngIf="showTopHeader">
                                <span><img class="side-menu-flag"
                                                src="assets/images/international-flags/{{selecteCountry}}.svg"
                                                *ngIf="showTopHeader">
                                        {{selecteCountryLabel}} - {{preferredLanguageLabel}}</span>
                        </li>
                        <!-- {{"header.loggedInAs" | translate}} <span>{{_simUserId}} -->
                        <li class="header__nav__external_usr"><span class="hamburger_external_user"><i class="fas fa-user-friends"></i> {{ "header.loggedInAs" |
                                        translate}} {{_simUserId}}</span></li>

                        <li class="header__nav__has-sub-nav" (click)="passclicked('account')" *ngIf="showTopHeader">
                                <span>
                                        <i class="fas fa-user side-menu-i" *ngIf="showTopHeader"></i>
                                        {{_username}}</span>
                        </li>

                        <li class="header__nav__settings" (click)="passclicked('settings')"><span><i
                                                class="fas fa-cog"></i> {{ "humburger.settingsLabel"| translate
                                        }}</span></li>

                        <li class="header__nav__support" (click)="passclicked('support')"><span><i
                                                class="fas fa-question"></i> {{ "humburger.supportLabel"| translate
                                        }}</span></li>

                </ul>
        </div>
</div>