import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OktaCallbackComponent, OktaAuthGuard } from '@okta/okta-angular';
import { LogoutComponent } from '@app/Modules/Shared/Components/logout/logout.component';
import { WelcomeComponent } from './welcome/welcome.component';
// import { LoginComponent } from './login/login.component';
import { ErrorComponent } from './Modules/Shared/Components/error/error.component';
import { CpInitComponent } from './cp-init/cp-init.component';
import { SelectRegionComponent } from './Modules/Shared/Components/select-region/select-region.component';
import { HelpComponent } from '@app/help/help.component';
import { ContactusComponent } from '@app/contactus/contactus.component';
import { DelegatedUserSearchComponent } from './delegated-user/delegated-user-search/delegated-user-search.component';
import { DelegatedUserSearchResultComponent } from './delegated-user/delegated-user-searchresult/delegated-user-searchresult.component';
import { TestPageComponent } from './test/test-page/test-page.component';
import { FinalLogoutComponent } from './Modules/Shared/Components/final-logout/final-logout.component';
import { CBPricingTermsComponent } from '@app/Modules/Shared/Components/cb-pricing-terms/cb-pricing-terms.component';
import { ManageUserRedirect } from './Modules/Shared/Auth-Gaurds/auth-guard.ManageUserRedirect';
import { BlockModules } from './Modules/Shared/Auth-Gaurds/auth-guard.BlockModules';
import { APACPricingTermsPageComponent } from './Modules/Shared/Components/apac-pricing-terms-page/apac-pricing-terms-page.component';
import { ApacPricingTearmsConditionsComponent } from './Modules/Shared/Components/apac-pricing-tearms-conditions/apac-pricing-tearms-conditions.component';

const routes: Routes = [
  { path: 'implicit/callback', component: OktaCallbackComponent },
  {
    path: '',
    redirectTo: 'landing',
    pathMatch: 'full'
  },
  {
    path: 'landing',
    component: CpInitComponent,
    canActivate: [OktaAuthGuard]
  },
  {
    path: 'home',
    loadChildren: ('./Modules/Home/home.module#HomeModule')
  },
  {
    path: 'priceagreement',
    loadChildren: ('./Modules/PriceAgreement/priceagreement.module#PriceagreementModule')
  },
  {
    path: 'manageuser',
    loadChildren: ('./SiteAdmin/Modules/Manage-User/manage-user.module#ManageUserModule')
  },
  {
    path: 'portalusage',
    loadChildren: ('./SiteAdmin/Modules/PortalUsage/portalusage.module#PortalusageModule')
  },
  {
    path: 'profile',
    loadChildren: ('./Modules/my-profile/myprofile.module#MyProfileModule')
  },
  {
    path: 'pa',
    loadChildren: ('./Modules/PADetails/padetails.module#PadetailsModule')
  },
  {
    path: 'chargeback',
    loadChildren: ('./Modules/chargeback/chargeback.module#ChargebackModule'),
    canActivate: [BlockModules]
  },
  {
    path: 'meetcomps',
    loadChildren: ('./Modules/meet-comps/meet-comps.module#MeetCompsModule')
  },
  // {
  //   path: 'invoice',
  //   loadChildren: ('./Modules/in-voice/in-voice.module#InVoiceModule'),
  //   canActivate: [BlockModules]
  // },
  {
    path: 'invoice',
    loadChildren: ('./Modules/in-voice-new/in-voice-new.module#InVoiceNewModule'),
    canActivate: [BlockModules]
  },
  {
    path: 'welcome',
    // component: WelcomeComponent,
    loadChildren: ('./welcome/welcome.module#WelcomeModule'),
    canActivate: [OktaAuthGuard]
  },
  {
    path: 'help',
    component: HelpComponent,
    canActivate: [OktaAuthGuard]
  },
  {
    path: 'contactus',
    component: ContactusComponent
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: 'finallogout',
    component: FinalLogoutComponent
  },
  {
    path: 'select-region',
    component: SelectRegionComponent
  },
  {
    path: 'login',
    loadChildren: ('./Modules/login/login.module#LoginModule')
  },
  {
    path: 'register',
    loadChildren: ('./Modules/register/register.module#RegisterModule')
  },
  {
    path: 'error',
    component: ErrorComponent
  },
  {
    path: 'uc-mapping',
    loadChildren: ('./SiteAdmin/Modules/UCMapping/uc-mapping.module#UcMappingModule')
  },
  {
    path: 'efilekcfileaccess',
    loadChildren: ('./SiteAdmin/Modules/efile-kcfile-access/efile-kcfile-access.module#EfileKcfileAccessModule'),
    canActivate: [BlockModules]
  },
  {
    path: 'delegatedUserSearch',
    component: DelegatedUserSearchComponent,
    canActivate: [OktaAuthGuard],
    children: [
      {
        path: 'delegatedUserSearchResults',
        component: DelegatedUserSearchResultComponent,
        outlet: 'delegatedResults'
      }
    ]
  },
  {
    path: 'test',
    component: TestPageComponent
  },
  // {
  //   path: 'termsCondition',
  //   component: TermsConditionComponent
  // },
  {
    path: 'pricing',
    loadChildren: ('./Modules/termsConditions/termsConditions.module#TermsConditionsModule')
  },
  {
    path: 'reports',
    loadChildren: ('./SiteAdmin/Modules/Reports/reports.module#ReportsModule')
  },
  {
    path: 'rebateprice',
    loadChildren: ('@app/Modules/rebate/rebate.module#RebateModule')
  },
  {
    path: 'cb-pricing-terms',
    component: CBPricingTermsComponent,
    canActivate: [OktaAuthGuard, ManageUserRedirect]
  },
  {
    path: 'pricing-terms-conditions',
    component: APACPricingTermsPageComponent,
    canActivate: [OktaAuthGuard, ManageUserRedirect]
  },
  { path: 'pricing-terms-conditions-popup', component: ApacPricingTearmsConditionsComponent, outlet: 'termsdialogs', canActivate: [OktaAuthGuard] },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }