let endpoints_SiteAdmin_DEV_TeamB = {
    getReports: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-tb/v1/Master/Report/GetReportsAsync",
		url_LAO: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-tb/v1/Master/Report/GetReportsAsync",
		method: "POST"
	},
	// getEmailNotificationReports: {
	// 	url_NA: "https://lao-pam-kimclark-tb-bzs-lane2.azurewebsites.net/NA/v2/ReportGeneration/GetEmailNotification",
	// 	method: "POST"
	// },
	// getUserMappingListReports: {
	// 	url_NA: "https://lao-pam-kimclark-tb-bzs-lane2.azurewebsites.net/NA/v2/ReportGeneration/GetUserMappingList",
	// 	method: "POST"
	// },
	getCustomerPriceHistory: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-kcpinvoiceprice-pxy-api-tb/NA/v1/DownloadInvoice/CustomerPriceHistory",
		url_LAO: 'https://kcc-sls-laokcpinvoiceprice-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/DownloadInvoice/CustomerPriceHistory',
		method: "POST"
	},
	getEfileCGBCompany: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-tb/NA/v1/Reports/EfileCGBCompany",
		url_LAO: 'https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/Reports/EfileCGBCompany',
		method: "POST"
	},
	getExpectedDateToPay: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-tb/NA/v1/Reports/ExpectedDateToPay",
		url_LAO: 'https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/Reports/ExpectedDateToPay',
		method: "POST"
	},
	getCustomerHistory: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-tb/NA/v1/Reports/CustomerHistory",
		url_LAO: 'https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/Reports/CustomerHistory',
		method: "POST"
	},
	getCBAcceptancePolicyList: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/NA/v2/Report/GetCBAcceptancePolicyList",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/Report/GetCBAcceptancePolicyList",
		method: "POST"
	},
	getDaysToDisplayList: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/NA/v2/Report/GetDaysToDisplayList",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/Report/GetDaysToDisplayList",
		method: "POST"
	},
	// getViewPAcomment: {
	// 	url_NA: "https://lao-pam-kimclark-tb-bzs-lane2.azurewebsites.net/NA/v2/ReportGeneration/ViewPAcomment",
	// 	method: "POST"
	// },
	// getExpectedVolumeReport: {
	// 	url_NA: "https://lao-pam-kimclark-tb-bzs-lane2.azurewebsites.net/NA/v2/ReportGeneration/ExportExpectedVolumeReport",
	// 	method: "POST"
	// },

	getReportGeneric: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/NA/v2/Report/GetDownloadLinkAsync/GetDownloadLink",	
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/Report/GetDownloadLinkAsync/GetDownloadLink",	
		method: "POST"
	},

	// UC mapping starts
	searchUsers: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/UserCustomerMapping/SearchUser",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserCustomerMapping/SearchUser",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/v2/UserCustomerMapping/SearchUser",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/v2/UserCustomerMapping/SearchUser",
		method: 'GET'
	},
	getUsersAssociated: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/UserCustomerMapping/GetUserAssociatedToMapping",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserCustomerMapping/GetUserAssociatedToMapping",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/v2/UserCustomerMapping/GetUserAssociatedToMapping",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/v2/UserCustomerMapping/GetUserAssociatedToMapping",
		method: "POST"
	},
	removeUcMappings: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/UserCustomerMapping/DeleteUserCustomerMapping",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserCustomerMapping/DeleteUserCustomerMapping",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/V2/UserCustomerMapping/DeleteUserCustomerMapping",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/V2/UserCustomerMapping/DeleteUserCustomerMapping",
		method: "POST"
	},
	addUcMappings: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/UserCustomerMapping/SaveUserCustomerMapping",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserCustomerMapping/SaveUserCustomerMapping",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/V2/UserCustomerMapping/SaveUserCustomerMapping",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/V2/UserCustomerMapping/SaveUserCustomerMapping",

		method: "POST"
	},
	getStates: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-tb/v2/Master/GetState",
		url_LAO: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-tb/v2/Master/GetState",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-tb/v2/Master/GetState",
		method: "POST"
	},
	ucMappingCustomerSearch: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/CustomerV2/GetCustomerBySearchFields",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/CustomerV2/GetCustomerBySearchFields",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/v2/CustomerV2/GetCustomerBySearchFields",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/v2/CustomerV2/GetCustomerBySearchFields",
		method: "POST"
	},
	ucMappings: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/UserCustomerMapping/GetUserCustomerMapping",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserCustomerMapping/GetUserCustomerMapping",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/v2/UserCustomerMapping/GetUserCustomerMapping",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/v2/UserCustomerMapping/GetUserCustomerMapping",
		method: "POST"
	},
	customerMappings: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/CustomerV2/GetCustomerHierarchy",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/CustomerV2/GetCustomerHierarchy",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/v2/CustomerV2/GetCustomerHierarchy",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/v2/CustomerV2/GetCustomerHierarchy",
		method: "POST"
	},
	markAsAdmin: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/UserCustomerMapping/SetGroupAdmin",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserCustomerMapping/SetGroupAdmin",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/v2/UserCustomerMapping/SetGroupAdmin",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/v2/UserCustomerMapping/SetGroupAdmin",
		method: "POST"
	},
	validateCustomerGroupMapping: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/UserCustomerMapping/ValidateCustomerGroupMapping",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserCustomerMapping/ValidateCustomerGroupMapping",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/v2/UserCustomerMapping/ValidateCustomerGroupMapping",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/v2/UserCustomerMapping/ValidateCustomerGroupMapping",
		method: "POST"
	},

	// portal usage starts
	portalUsageInternal: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/UserCustomerMapping/SearchUserForInternalAdmin",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserCustomerMapping/SearchUserForInternalAdmin",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/v2/UserCustomerMapping/SearchUserForInternalAdmin",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/v2/UserCustomerMapping/SearchUserForInternalAdmin",
		method: "POST"
	},
	portalUsageExternal: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/UserCustomerMapping/SearchUserForExternalAdmin",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserCustomerMapping/SearchUserForExternalAdmin",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/v2/UserCustomerMapping/SearchUserForExternalAdmin",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/v2/UserCustomerMapping/SearchUserForExternalAdmin",
		method: "POST"
	},
	exportPortalUsageInternal: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/Export/SearchUserForInternalAdmin",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/Export/SearchUserForInternalAdmin",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/v2/export/SearchUserForInternalAdmin",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/v2/export/SearchUserForInternalAdmin",
		method: "POST"
	},
	exportPortalUsageExternal: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/Export/SearchUserForExternalAdmin",
		url_LAO: "https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/Export/SearchUserForExternalAdmin",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/v2/export/SearchUserForExternalAdmin",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/v2/export/SearchUserForExternalAdmin",
		method: "POST"
	},
	// portal usage ends

	// E-File Access Start
	getEFileAccessBySearchFields: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-tb/na/v1/EFile/EFileAccessBySearchFields",
		url_LAO: 'https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/EFile/EFileAccessBySearchFields',
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/EFile/EFileAccessBySearchFields",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/EFile/EFileAccessBySearchFields",
		method: "POST"
	},

	updateEFileAccess: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-tb/na/v1/EFile/UpdateAccess",
		url_LAO: 'https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/EFile/UpdateAccess',
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/EFile/UpdateAccess",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/EFile/UpdateAccess",
		method: "POST"
	},

	eaGetDistinctFilterSearchGrid: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-tb/na/v1/EFile/EFileAccessSearchGridHeaderFilters",
		url_LAO: 'https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/EFile/EFileAccessSearchGridHeaderFilters',
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/EFile/EFileAccessSearchGridHeaderFilters",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/EFile/EFileAccessSearchGridHeaderFilters",
		method: "POST"
	},

	// E-File Access Ends

	//Manage User API Starts
	manageUsersSubmit:{
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-tb/EMEA/v2/UserService/ManageUsers",
		url_LAO: 'https://kcc-sls-laokcpuser-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserService/ManageUsers',
		method: "POST"
	},
	UCMSync: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/UserCustomerMapping/TriggerUCMSync",
		url_LAO: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-tb/na/v2/UserCustomerMapping/TriggerUCMSync",
		url_EMEA: "",
		url_APAC: "",
		method: "POST"
	}
}
export { endpoints_SiteAdmin_DEV_TeamB };