import { Component, OnInit, TemplateRef, Renderer2, ElementRef } from '@angular/core';
import { CpAgGridService } from '@app/Modules/cp-grid/services/cp-ag-grid-service';
import { AgRendererComponent } from 'ag-grid-angular';
import { HttpService } from '@app/Modules/Shared/Services/http.service';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DownloadPADetailsRequest, DownloadRequest, DownloadRequestSignalRRebate } from '@app/Modules/Shared/DataModels/SharedDataModel';
import { cookieSIMID, environment, SPA_LEGACY_MENU_HOST } from '@env/environment';
import { GlobalAppService } from '@app/Modules/Shared/Services/global-app.service';
import { SignalRMaster } from '@app/Modules/Shared/Services/SingnalR.master.service';

@Component({
  selector: 'app-contextmenu',
  templateUrl: './contextmenu.component.html',
  styleUrls: ['./contextmenu.component.scss']
})
export class ContextmenuComponent implements AgRendererComponent, OnInit {

  params: any;
  template: TemplateRef<any>;
  showrowdata: boolean = false;
  showmenu: boolean = false;
  rowindex: number;
  cvtView: any;
  isEuAdminModule: boolean;
  isInternalUser: boolean;
  selectedRows: Array<any>;
  selectedContractNumbers: Array<any>;
  selectedCustomerNumbers: Array<any>;
  selectedRowsEndUser: any;
  isStatusTypeExpiredOrFuture: boolean;
  isEndUserAdminPAType: boolean;
  isRegionLAO: boolean;
  region: string;
  ellipseSelect: boolean = false;
  isRebateModule: boolean;
  constructor(private httpServ: HttpService,
    private gridService: CpAgGridService,
    private renderer: Renderer2,
    private el: ElementRef,
    private appserv: AppService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private globalAppService: GlobalAppService,
    private signalRService: SignalRMaster) { }

  refresh(params: any): boolean {
    return true;
  }

  agInit(params: any): void {
    this.template = params['ngTemplate'];
    this.refresh(params);
    this.rowindex = params.rowIndex;
    this.params = params;
    if (this.params.data) {
      this.isStatusTypeExpiredOrFuture = this.params.data.status == 'Expired' || this.params.data.status == 'Future' ? true : false;
      if (this.params.data.typeName == 'National' || this.params.data.typeName == 'Local' || this.params.data.typeName == 'Regional' || this.params.data.typeName == 'GPO Courtesy' || this.params.data.typeName == 'GPO National') {
        this.isEndUserAdminPAType = false;
      }
      else {
        this.isEndUserAdminPAType = true;
      }
    }

    this.cvtView = this.globalAppService.isCVTView();
    this.globalAppService.isCVTViewSubject().subscribe((data) => {
      this.cvtView = data
    });

  }

  ngOnInit() {
    this.isInternalUser = this.appserv.isInternalUser;
    this.isEuAdminModule = this.router.url.indexOf('enduseradmin') === -1 ? false : true;
    this.isRebateModule = this.router.url.indexOf('rebate') === -1 ? false : true;
    this.region = this.globalAppService.getDefaultRegionString();
    this.isRegionLAO = this.region === 'LAO' ? true : false;
  }

  handleDownloadExcel(event) {
    this.downloadExcel();
  }

  handlePdfDownload(event) {
    this.downloadPDF();
  }

  downloadExcel() {
    this.appserv.setLoadingStatus(true);
    let downloadRequest = this.getDownloadRequest();
    downloadRequest.customerNumber = (downloadRequest.customerNumber === 'All' ? '0' : downloadRequest.customerNumber)
    if(this.isRebateModule){
      /** For Rebate Excel Download the Requet will be same as PDF download just send the different file type  */
      let execlDownloadRequest = this.pdfDownloadAttributes();
      execlDownloadRequest.fileType = 3;
      this.httpServ.downloadRebateData(execlDownloadRequest).subscribe(
        (res) => {
          if (res && res.fileUrl && res.fileUrl !== '') {            
            let opener = window.open(res.fileUrl, '_blank');
            this.appserv.setLoadingStatus(false);
          }
          else {
            this.appserv.setLoadingStatus(false);
            this.gridService.setDownloadMessage({ key: 'downloadError', summary: 'errorLabel', detail:  (res && res.statusCode ? res.statusCode + '.errorMessage' : '0000.errorMessage'), severity: 'error' });
          }
        },
        (error) => {
          this.appserv.setLoadingStatus(false);
          this.gridService.setDownloadMessage({ key: 'downloadError', summary: 'errorLabel', detail: '0000.errorMessage', severity: 'error' });
        })
    } else{
      this.httpServ.downloadPaDetailsExcel(downloadRequest)
      .subscribe(
        (res) => {
          if (res && res.fileUrl && res.fileUrl != '') {
            this.appserv.setLoadingStatus(false);
            this.router.navigate([{ outlets: { dialogs: ['downloadLinkModal'] } }], 
              {
                queryParams: {
                  region: localStorage.getItem('default-region'),
                  country: localStorage.getItem('country'), 
                  language: localStorage.getItem('country-lang')
                }, 
                relativeTo: this.isTilePages() ? this.activatedRoute.parent : this.activatedRoute.parent.parent,
                state: { url: res.fileUrl, title: 'agGrid.agGridColumns.contextMenu.downloadExcelLabel' } 
              }
            );
          }
          else {
            this.appserv.setLoadingStatus(false);
            this.gridService.setDownloadMessage({ key: 'downloadError', summary: 'errorLabel', detail: '0000.errorMessage', severity: 'error' });
          }
        },
        (error) => {
          this.appserv.setLoadingStatus(false);
          this.gridService.setDownloadMessage({ key: 'downloadError', summary: 'errorLabel', detail: '0000.errorMessage', severity: 'error' });
        })
    }
  }

  isTilePages(){
    if( (this.router.url.indexOf('activefuture') >-1) || 
        (this.router.url.indexOf('recentlychanged') >-1) || 
        (this.router.url.indexOf('expiringsoon') >-1) ||
        (this.router.url.indexOf('recentlyexpired') >-1) ||
        (this.router.url.indexOf('mypafavorite') >-1) ) 
      {
      return true;
    }else{
      return false;
    }
  }

  getDownloadRequest(): DownloadPADetailsRequest {

    let request: DownloadPADetailsRequest = new DownloadPADetailsRequest();

    request.contractNumber = this.params.data.contractNumber;
    request.customerNumber = this.params.data.customerNumber;
    request.searchView = 0;
    request.paStatus = this.params.data.statusId;
    request.fileType = 3;
    request.country = this.globalAppService.getUserDetails().preferredCountry;
    request.salesOrg = [this.globalAppService.getSalesOrg()];
    request.display = 1;
    request.filterType = 1;
    request.paProductFilter = {};
    request.paEnduserFilter = {};
    request.paGroupFilter = {};
    request.contractNumber = this.params.data.contractNumber;
    request.customerNumber = this.params.data.customerNumber;
    return request;
  }

  downloadPDF() {
    this.appserv.setLoadingStatus(true);
    let data = this.pdfDownloadAttributes();
    if (data) {
      if(this.isRebateModule){
        this.httpServ.downloadRebateData(data).subscribe(
          (res) => {
            if (res && res.fileUrl && res.fileUrl !== '') {            
              let opener = window.open(res.fileUrl, '_blank');
              this.appserv.setLoadingStatus(false);
            }
            else if(res.statusCode == "5011"){
              //Make the Signal R call.
              this.svcCallToSignalR(data);
            } else {
              this.appserv.setLoadingStatus(false);
              this.gridService.setDownloadMessage({ key: 'downloadError', summary: 'errorLabel', detail:  (res && res.statusCode ? res.statusCode + '.errorMessage' : '0000.errorMessage'), severity: 'error' });
            }
          },
          (error) => {
            this.appserv.setLoadingStatus(false);
            this.gridService.setDownloadMessage({ key: 'downloadError', summary: 'errorLabel', detail: '0000.errorMessage', severity: 'error' });
          })
      } else {
        this.httpServ.downloadData(data).subscribe(
          (res) => {
            if (res && res.fileUrl && res.fileUrl !== '') {            
              this.appserv.setLoadingStatus(false);
              this.router.navigate([{ outlets: { dialogs: ['downloadLinkModal'] } }], 
              {
                queryParams: {
                  region: localStorage.getItem('default-region'),
                  country: localStorage.getItem('country'), 
                  language: localStorage.getItem('country-lang')
                }, 
                relativeTo: this.isTilePages() ? this.activatedRoute.parent : this.activatedRoute.parent.parent,
                state: { url: res.fileUrl, title: 'agGrid.agGridColumns.contextMenu.downloadPDFLabel' } 
              }
            );
            }
            else if(res.statusCode == "5011"){
              //Make the Signal R call.
              this.svcCallToSignalR(data);
            }
            else {
              this.appserv.setLoadingStatus(false);
              this.gridService.setDownloadMessage({ key: 'downloadError', summary: 'errorLabel', detail:  (res && res.statusCode ? res.statusCode + '.errorMessage' : '0000.errorMessage'), severity: 'error' });
            }
          },
          (error) => {
            this.appserv.setLoadingStatus(false);
            this.gridService.setDownloadMessage({ key: 'downloadError', summary: 'errorLabel', detail: '0000.errorMessage', severity: 'error' });
          })
      }
    }
  }

  pdfDownloadAttributes(): DownloadRequest {
    let request: DownloadRequest = new DownloadRequest();
    request.priceAgreementSearchRequest = this.isRebateModule ? this.gridService.getSvcRequest() : null;
    request.selectedCustomerNumbers = [(this.params.data.customerNumber === 'All' ? '0' : this.params.data.customerNumber)];
    request.selectedContractNumbers = [this.params.data.contractNumber];
    request.contractCustomers = [{ customerNumber: (this.params.data.customerNumber === 'All' ? '0' : this.params.data.customerNumber), contractNumber: this.params.data.contractNumber }]
    request.isDownloadAll = false;
    request.paStatus = [this.params.data.statusId ? this.params.data.statusId : []];
    request.includeEndusers = false;
    request.dates = 1;
    request.fileType = 2;
    request.isRecentlyChanged = false;
    request.emailType = 1;
    request.SelectedSearchCount = 1;
    request.searchView = this.cvtView ? 'cvt' : 'customer';
    if(this.isRebateModule)
    request.includeExpiryItem = this.globalAppService.getIncludeExpiryItemStatus();
    return request;
  }

  svcCallToSignalR(downloadRequest) {
    let integratedUserid = "";
    if (this.appserv.getCookie(cookieSIMID) && this.appserv.isGlobalUser) {
      integratedUserid = this.appserv.getuserId(false);
    }
    this.appserv.setLoadingStatus(false);
    let request: DownloadRequestSignalRRebate = new DownloadRequestSignalRRebate();
    request.UserName  = Math.random().toString(36).substring(2, 15);
    request.IntegratedUserid  = integratedUserid;
    request.Token = 'TOKEN'
    request.Region = this.globalAppService.getDefaultRegionString().toUpperCase();
    request.requestObj = downloadRequest;
    request.ModuleType = this.isRebateModule ? "3" : "1";
    request.Reconnect = false;
    let selectedCountryLang = this.globalAppService.getSelectedCountryLang();
    request.Languagecode  = selectedCountryLang.language.split('-')[0] + "-" + selectedCountryLang.language.split('-')[1].toUpperCase();

    this.signalRService.initSignalR_Download('EMEA-PA',
    request.UserName, 
    request.IntegratedUserid,
    'TOKEN',
    request.Region,
    JSON.stringify(request.requestObj),
    request.ModuleType,
    false,
    request.Languagecode
    );
  }

  onLinkClick() {
    let qsParams = this.getQsParams();
    let isCVT: string = "CVTNo";
    if (this.globalAppService.isCVTView()) {
      this.cvtView
        = this.globalAppService.isCVTView();
      isCVT = "CVTYes"
    }
    else {
      isCVT = "CVTNo"
    }
    if (this.params.data.customerNumber == null)
      this.params.data.customerNumber = 'All';
    // window.open(SPA_LEGACY_MENU_HOST + this.params.data.contractNumber + '/' + this.params.data.customerNumber + '/' + isCVT + qsParams, '_blank')
    window.open(SPA_LEGACY_MENU_HOST + this.params.data.contractNumber + '/' + this.params.data.customerNumber + '/' + this.params.data.statusId + '/' + isCVT + qsParams);
  }

  onModifyPAClick(){
    let spartaURL = this.httpServ.getRegionSpecificURL(environment.spartaModifyPAURL);
    if(this.params && this.params.data){
      let encryptedContractNumber = this.params.data.encryptedcontractnumber;
      spartaURL = spartaURL.replace('####', encryptedContractNumber);
    }
    window.open(spartaURL, '_blank');
  }

  getQsParams(): string {
    let qsList: Array<any> = new Array<any>();
    let qsParams: string;
    let qsTileType: string = this.params.tileType ? ('tileType=' + this.params.tileType) : "";
    if (qsTileType)
      qsList.push(qsTileType);

    let fullNameList = [];
    let oCustomerName: any = this.params.data.customerName;
    if (oCustomerName) {
      fullNameList.push(oCustomerName.name1 || '');
      // fullNameList.push(oCustomerName.name2 || '');
      // fullNameList.push(oCustomerName.name3 || '');
      // fullNameList.push(oCustomerName.Fname4 || '');
    }
    let qsCustomerName: string = fullNameList && fullNameList.length ? ('custName=' + fullNameList.join(' ')) : '';
    if (qsCustomerName)
      qsList.push(qsCustomerName);

    let dataRequest: any = this.gridService.getSvcRequest();;
    let dataDurationType: number = dataRequest && dataRequest.query ? dataRequest.query.dataDurationType : 0;
    if (dataDurationType)
      qsList.push('dataDurationType=' + dataDurationType);
    qsParams = qsList && qsList.length > 0 ? '?' + qsList.join('&') : '';

    return qsParams;
  }
  createEndUserLink() {
    this.selectRowsEndUser();
    if (this.isEuAdminModule) {
      this.router.navigate([{ outlets: { searchResults: null } }], { relativeTo: this.activatedRoute.parent }).then(() => {
        this.router.navigate(['createenduser'], { queryParamsHandling:'preserve',relativeTo: this.activatedRoute.parent, state: { selectedPa: this.selectedRowsEndUser } });
      });
    }
    else {
      this.router.navigate(['enduseradmin/create/createenduser'], { queryParamsHandling:'preserve',relativeTo: this.activatedRoute.parent.parent, state: { selectedPa: this.selectedRowsEndUser } });
    }
  }
  selectRowsEndUser() {
    this.selectedRowsEndUser = this.params.data;
  }
  contextmenuToggle(element, e){
    element.toggle(e);
  }

  onContextMenuHide(){
    this.ellipseSelect = false;
  }

  onContextMenuShow(){
    this.ellipseSelect = true;
  }
}
