import * as tslib_1 from "tslib";
import { OnDestroy, ChangeDetectorRef, ViewRef, } from '@angular/core';
import { pastatus, patype, customerTypeFilter, euStatus, recentDownloadStatus, endUserAdminPAType, patypecustomer, kcpUpdates, endUserComments, customerSoldToComments, customerType, cbEfileStatusForActive, cbEfileStatusForTest, distinctValueFilterSet, customerTypeEMEAandLAO, moduleType, cbClaimRequestTypeAPAC } from '@app/conf/dataConfigurations';
import { CpAgGridService } from '@app/Modules/cp-grid/services/cp-ag-grid-service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { LocationLanguageService } from '@app/Modules/Shared/Services/location-language.service';
import { GlobalAppService } from '@app/Modules/Shared/Services/global-app.service';
import { TranslateService } from '@ngx-translate/core';
var MultiSelectFilterComponent = /** @class */ (function () {
    function MultiSelectFilterComponent(gridService, appService, cd, locationLanguageService, _globalAppService, translateService) {
        this.gridService = gridService;
        this.appService = appService;
        this.cd = cd;
        this.locationLanguageService = locationLanguageService;
        this._globalAppService = _globalAppService;
        this.translateService = translateService;
        this.text = '';
        this.filters = [];
        this.unAppliedfilters = [];
        this.filterModel = { type: "multi-select", value: this.filters };
        this.subscription = new Subscription();
        this.fullFilterList = [];
        this.country = [];
        this.states = [];
        this.loaded = false;
        this.isThisDistinctFilter = false;
    }
    MultiSelectFilterComponent.prototype.agInit = function (params) {
        var _this = this;
        this.params = params;
        this.orgColId = params.column.getColId();
        this.colId = params.colDef.field;
        this.colId = this.colId.indexOf('_') !== -1 ? this.colId.substring(0, this.colId.indexOf('_')) : this.colId;
        this.headerName = params.colDef.headerName;
        this.valueGetter = params.valueGetter;
        this.hideSortOption();
        this.populateValues();
        this.populateValuesDistinct();
        this.isSelected = false;
        // this.selectAll();
        this.setSearchObjectListener();
        this.params.api.setPopupParent(document.querySelector('body'));
        this.loaded = true;
        this.region = this._globalAppService.getDefaultRegionString();
        this.subscription.add(this.gridService.getColumnMultiselectFilter().subscribe(function (data) {
            _this.populateValuesDistinct();
        }));
    };
    MultiSelectFilterComponent.prototype.populateValuesDistinct = function () {
        var _this = this;
        var filterField;
        // NP To Do: Add any field name here which needs distinct multi-select filter values    
        switch (this.colId) {
            case 'rvRb':
            case 'currency':
            case 'claimRequestType':
            case 'discrepancies':
            case 'refreshStatus':
            case 'unclaimedMonth':
                filterField = this.setDistinctFilterOptions();
                break;
            case 'status':
                if (this.gridService.pageContext.pageName == 'searchResultsCB' ||
                    this.gridService.pageContext.pageName == 'recentActivity' ||
                    this.gridService.pageContext.pageName == 'issuedTileGrid' ||
                    this.gridService.pageContext.pageName == 'deniedTileGrid' ||
                    this.gridService.pageContext.pageName == 'efileSearchResult' ||
                    this.gridService.pageContext.pageName == 'mcSearchResult' ||
                    this.gridService.pageContext.pageName == 'pendingTileGrid' ||
                    this.gridService.pageContext.pageName == 'kcfileSearchResult') {
                    filterField = this.setDistinctFilterOptions();
                }
                break;
            case 'type':
                if (this.headerName === 'agGrid.agGridColumns.typeLabel' && this.gridService.pageContext.pageName == 'efileSearchResult') {
                    filterField = this.setDistinctFilterOptions();
                }
                break;
            case 'state':
                filterField = this.setDistinctFilterOptions();
                break;
            case 'category':
                filterField = this.setDistinctFilterOptions();
                break;
            case 'row':
                filterField = this.setDistinctFilterOptions();
                break;
            case 'access':
                filterField = this.setDistinctFilterOptions();
                break;
            case 'claimPeriodMonthAndYear':
                filterField = this.setDistinctFilterOptions();
                break;
            case 'country':
                if (this.gridService.pageContext.pageName == 'invoiceSearchResult' ||
                    this.gridService.pageContext.pageName == 'invoiceFavourite' ||
                    this.gridService.pageContext.pageName == 'recentRequests' ||
                    this.gridService.pageContext.pageName == 'unclaimedTileGrid') {
                    filterField = this.setDistinctFilterOptions();
                }
                break;
        }
        if (filterField) {
            this.filters = [];
            this.SelectAllValue = true;
            filterField.forEach(function (item, index) {
                var isSel = false;
                var currentfilter = _this.fullFilterList.find(function (el) { return el.value == item.value; });
                if (currentfilter) {
                    isSel = currentfilter.selected;
                }
                _this.filters.push({ text: item.label, value: item.value, selected: isSel });
                if (!isSel) {
                    _this.SelectAllValue = false;
                }
            });
            this.fullFilterList = this.filters.map(function (f) { return f; });
        }
        this.cd.detectChanges();
    };
    MultiSelectFilterComponent.prototype.populateValues = function () {
        var _this = this;
        var filterField;
        // TK To Do: Add any field name here which needs multi-select filter values    
        switch (this.colId) {
            case 'typedescription':
            case 'typeName':
            case 'contractSubTypeName':
            case 'type':
                if (this.headerName === 'agGrid.agGridColumns.typeLabel' && this.gridService.pageContext.pageName == 'efileSearchResult') {
                    return;
                }
                if (this.headerName === 'agGrid.agGridColumns.euTypeLabel') {
                    filterField = endUserAdminPAType;
                }
                else {
                    if (this._globalAppService.getDefaultRegionString() === 'LAO') {
                        // let paType = this.appService.getPAtype();
                        // let paTypeArray = [];
                        // for (let [key, value] of Object.entries(paType)) {
                        //   paTypeArray.push({
                        //     'label': `${value}`,
                        //     'value': `${key}`
                        //   })
                        // }
                        // filterField = paTypeArray;
                        if (!this.loaded) {
                            var paType = this.appService.getPAtype();
                            var paTypeArray = [];
                            for (var _i = 0, _a = Object.entries(paType); _i < _a.length; _i++) {
                                var _b = _a[_i], key = _b[0], value = _b[1];
                                paTypeArray.push({
                                    'label': "" + value,
                                    'value': "" + key
                                });
                            }
                            filterField = paTypeArray;
                            var me_1 = this;
                            this.appService.getPAtypeObservable().subscribe(function (paType) {
                                if (paType) {
                                    var paTypeArray_1 = [];
                                    for (var _i = 0, _a = Object.entries(paType); _i < _a.length; _i++) {
                                        var _b = _a[_i], key = _b[0], value = _b[1];
                                        paTypeArray_1.push({
                                            'label': "" + value,
                                            'value': "" + key
                                        });
                                    }
                                    filterField = paTypeArray_1;
                                    if (filterField) {
                                        _this.filters = filterField.slice().map(function (f) { return { text: f.label, value: f.value }; });
                                        _this.fullFilterList = _this.filters.map(function (f) { return f; });
                                        if (me_1.cd && !me_1.cd.destroyed) {
                                            me_1.cd.detectChanges();
                                        }
                                    }
                                }
                            });
                        }
                        else {
                            var me_2 = this;
                            this.appService.getPAtypeObservable().subscribe(function (paType) {
                                if (paType) {
                                    var paTypeArray = [];
                                    for (var _i = 0, _a = Object.entries(paType); _i < _a.length; _i++) {
                                        var _b = _a[_i], key = _b[0], value = _b[1];
                                        paTypeArray.push({
                                            'label': "" + value,
                                            'value': "" + key
                                        });
                                    }
                                    filterField = paTypeArray;
                                    if (filterField) {
                                        _this.filters = filterField.slice().map(function (f) { return { text: f.label, value: f.value }; });
                                        _this.fullFilterList = _this.filters.map(function (f) { return f; });
                                        if (me_2.cd && !me_2.cd.destroyed) {
                                            me_2.cd.detectChanges();
                                        }
                                    }
                                }
                            });
                        }
                    }
                    else {
                        filterField = this._globalAppService.isCVTView() ? patype : patypecustomer;
                    }
                }
                // else{
                //   filterField = this.appService.isCVTView() ? patype : patypecustomer;
                // }
                break;
            case 'salesorgName':
                if (!this.loaded) {
                    var salesOrgNames = this.appService.getSalesOrgNames();
                    var salesOrgNameArray = [];
                    for (var index = 0; index < salesOrgNames.length; index++) {
                        salesOrgNameArray.push({ label: salesOrgNames[index].salesOrgDescription, value: salesOrgNames[index].salesOrg });
                    }
                    filterField = salesOrgNameArray;
                    var me_3 = this;
                    this.appService.getSalesOrgNamesObservable().subscribe(function (salesOrgNames) {
                        if (salesOrgNames) {
                            var salesOrgNameArray_1 = [];
                            for (var index = 0; index < salesOrgNames.length; index++) {
                                salesOrgNameArray_1.push({ label: salesOrgNames[index].salesOrgDescription, value: salesOrgNames[index].salesOrg });
                            }
                            filterField = salesOrgNameArray_1;
                            if (filterField) {
                                _this.filters = filterField.slice().map(function (f) { return { text: f.label, value: f.value }; });
                                _this.fullFilterList = _this.filters.map(function (f) { return f; });
                                if (me_3.cd && !me_3.cd.destroyed) {
                                    me_3.cd.detectChanges();
                                }
                            }
                        }
                    });
                }
                else {
                    var me_4 = this;
                    this.appService.getSalesOrgNamesObservable().subscribe(function (salesOrgNames) {
                        if (salesOrgNames) {
                            var salesOrgNameArray = [];
                            for (var index = 0; index < salesOrgNames.length; index++) {
                                salesOrgNameArray.push({ label: salesOrgNames[index].salesOrgDescription, value: salesOrgNames[index].salesOrg });
                            }
                            filterField = salesOrgNameArray;
                            if (filterField) {
                                _this.filters = filterField.slice().map(function (f) { return { text: f.label, value: f.value }; });
                                _this.fullFilterList = _this.filters.map(function (f) { return f; });
                                if (me_4.cd && !me_4.cd.destroyed) {
                                    me_4.cd.detectChanges();
                                }
                            }
                        }
                    });
                }
                break;
            case 'kcOwner':
                if (!this.loaded) {
                    var KCOwnerNames = this.appService.getKCOwnerNames();
                    var KCOwnerNamesArray = [];
                    for (var index = 0; index < KCOwnerNames.length; index++) {
                        KCOwnerNamesArray.push({ label: KCOwnerNames[index].ownerDescription, value: KCOwnerNames[index].ownerId });
                    }
                    filterField = KCOwnerNamesArray;
                    var me_5 = this;
                    this.appService.getKCOwnerNamesObservable().subscribe(function (KCOwnerNames) {
                        if (KCOwnerNames) {
                            var KCOwnerNamesArray_1 = [];
                            for (var index = 0; index < KCOwnerNames.length; index++) {
                                KCOwnerNamesArray_1.push({ label: KCOwnerNames[index].ownerDescription, value: KCOwnerNames[index].ownerId });
                            }
                            filterField = KCOwnerNamesArray_1;
                            if (filterField) {
                                _this.filters = filterField.slice().map(function (f) { return { text: f.label, value: f.value }; });
                                _this.fullFilterList = _this.filters.map(function (f) { return f; });
                                if (me_5.cd && !me_5.cd.destroyed) {
                                    me_5.cd.detectChanges();
                                }
                            }
                        }
                    });
                }
                else {
                    var me_6 = this;
                    this.appService.getKCOwnerNamesObservable().subscribe(function (KCOwnerNames) {
                        if (KCOwnerNames) {
                            var KCOwnerNamesArray = [];
                            for (var index = 0; index < KCOwnerNames.length; index++) {
                                KCOwnerNamesArray.push({ label: KCOwnerNames[index].ownerDescription, value: KCOwnerNames[index].ownerId });
                            }
                            filterField = KCOwnerNamesArray;
                            if (filterField) {
                                _this.filters = filterField.slice().map(function (f) { return { text: f.label, value: f.value }; });
                                _this.fullFilterList = _this.filters.map(function (f) { return f; });
                                if (me_6.cd && !me_6.cd.destroyed) {
                                    me_6.cd.detectChanges();
                                }
                            }
                        }
                    });
                }
                break;
            case 'customerType':
                if ((this._globalAppService.getDefaultRegionString() === 'EMEA' || this._globalAppService.getDefaultRegionString() === 'LAO' || this._globalAppService.getDefaultRegionString() === 'APAC') && (this.gridService.pageContext.pageName == 'ucMappingCustomerSearch' || this.gridService.pageContext.pageName == 'customerSearchModal')) {
                    filterField = customerTypeEMEAandLAO;
                }
                else {
                    filterField = customerTypeFilter;
                }
                break;
            case 'moduleType':
                filterField = moduleType;
                break;
            case 'statusText':
            case 'status':
                if (this.gridService.pageContext.pageName == 'recentDownload') {
                    filterField = recentDownloadStatus;
                }
                else if (this.gridService.pageContext.pageName == 'efileSearchResult') {
                    filterField = cbEfileStatusForActive.concat(cbEfileStatusForTest);
                }
                else {
                    if (this.headerName === 'agGrid.agGridColumns.euStatusLabel') {
                        filterField = euStatus;
                    }
                    else {
                        filterField = pastatus;
                    }
                }
                break;
            case 'StatusDesc':
                filterField = recentDownloadStatus;
                break;
            case 'comments':
            case 'comment':
            case 'CommentsDescription':
                if (this.headerName === 'agGrid.agGridColumns.comments' || this.headerName === 'agGrid.agGridColumns.changeLabel') {
                    filterField = this.mapComments(kcpUpdates);
                }
                else if (this.headerName === 'agGrid.agGridColumns.paDetailEuComments') {
                    filterField = this.mapComments(endUserComments);
                }
                else if (this.headerName === 'agGrid.agGridColumns.soldToComment') {
                    filterField = this.mapSoldToComments(customerSoldToComments);
                }
                break;
            case 'country':
                if (this.orgColId != 'country_invoice' && this.orgColId != 'country_unclaimedTile') {
                    var country = this.gridService.getCountries();
                    country.forEach(function (item) {
                        _this.country.push({ 'label': item.label, 'value': item.value });
                    });
                    filterField = this.country;
                }
                break;
            case 'state':
                if (this.orgColId == "state_Mc") {
                    return;
                }
                this.gridService.getStateList().subscribe(function (states) {
                    if (states) {
                        filterField = [];
                        _this.states = states;
                        filterField = _this.states;
                        if (filterField) {
                            // filterField.forEach((item, index) => {
                            //   if (this.filters.map(filter => { return filter.value }).indexOf(item.value) === -1) {
                            //     this.filters.push({ text: item.label, value: item.value, selected: false });
                            //   }
                            // });
                            _this.filters = filterField.slice().map(function (f) { return { text: f.label, value: f.value }; });
                            _this.fullFilterList = _this.filters.map(function (f) { return f; });
                            if (_this.cd && !_this.cd.destroyed) {
                                _this.cd.detectChanges();
                            }
                        }
                    }
                });
                break;
            case 'customerType':
                if (this.headerName === 'agGrid.agGridColumns.customerTypeLabel') {
                    filterField = customerType;
                }
                break;
        }
        if (filterField) {
            filterField.forEach(function (item, index) {
                if (_this.filters.map(function (filter) { return filter.value; }).indexOf(item.value) === -1) {
                    _this.filters.push({ text: item.label, value: item.value, selected: false });
                }
            });
            this.fullFilterList = this.filters.map(function (f) { return f; });
        }
    };
    MultiSelectFilterComponent.prototype.setDistinctFilterOptions = function () {
        var filterField = [];
        var filterArr = this.gridService.getMultiSelectDistinctFilter()[this.gridService.getGridName()][this.colId];
        if (filterArr) {
            filterArr = filterArr.map(function (el) { return (el).toString().trim(); });
            if (this.region == "APAC") {
                distinctValueFilterSet["claimRequestType"] = cbClaimRequestTypeAPAC;
            }
            var configDataArr = distinctValueFilterSet[this.orgColId];
            if (configDataArr && configDataArr.length) {
                filterField = configDataArr.filter(function (el) {
                    return filterArr.indexOf(el.value) >= 0;
                });
            }
            else {
                filterField = filterArr.map(function (item) { return { label: item, value: item }; });
            }
        }
        this.isThisDistinctFilter = true;
        return filterField;
    };
    MultiSelectFilterComponent.prototype.getModel = function () {
        this.prestineFilter();
        this.filterModel.value = this.filters;
        return this.filterModel;
    };
    MultiSelectFilterComponent.prototype.setModel = function (model) {
        if (model && model.value) {
            this.filterModel = new Object();
            this.filterModel.type = "multi-select";
            this.filterModel.value = this.filters;
        }
        else {
            this.filters = this.filters.map(function (f) { f.selected = false; return f; });
            this.isSelected = false;
            if (this.cd && !this.cd.destroyed) {
                this.cd.detectChanges();
            }
        }
    };
    MultiSelectFilterComponent.prototype.hideSortOption = function () {
        if (this.colId === 'status') {
            switch (this.gridService.pageContext.pageName) {
                case 'searchResults':
                case 'activeFuture':
                case 'recentlyExpired':
                    this.hideSort = true;
                    break;
            }
        }
    };
    MultiSelectFilterComponent.prototype.mapComments = function (comments) {
        return comments.map(function (c) {
            var commentField = Object.assign({}, c);
            commentField.label = c.value;
            commentField.value = c.changeReason;
            delete commentField.changeReason;
            return commentField;
        });
    };
    MultiSelectFilterComponent.prototype.mapSoldToComments = function (comments) {
        return comments.map(function (c) {
            var commentField = Object.assign({}, c);
            commentField.label = c.value;
            commentField.value = c.changeReason;
            delete commentField.changeReason;
            return commentField;
        });
    };
    MultiSelectFilterComponent.prototype.setSearchObjectListener = function () {
        var _this = this;
        var me = this;
        this.subscription.add(this.appService.getSearchRequest().subscribe(function (searchObject) {
            if (searchObject && searchObject.query) {
                // below code is to hide other type option in list if top one is selected(implemented in PA)
                if (_this.colId === 'typedescription' || _this.colId === 'typeName') {
                    if (searchObject.query.contractType && searchObject.query.contractType.length) {
                        _this.fullFilterList.forEach(function (f) {
                            var contractTypeList = searchObject.query.contractType.filter(function (ct) { return ct === f.value; });
                            if (contractTypeList && contractTypeList.length)
                                f.selected = true;
                            else
                                f.selected = false;
                        });
                        _this.filters = _this.fullFilterList.filter(function (f) {
                            return f.selected;
                        }).map(function (f) { f.selected = false; return f; });
                    }
                    else {
                        _this.fullFilterList.forEach(function (f) {
                            f.selected = false;
                        });
                        _this.filters = _this.fullFilterList.map(function (f) { return f; });
                    }
                }
                // below code is to hide other status option in list if top one is selected(implemented in PA) 
                else if (_this.colId === 'status') {
                    if (_this.gridService.pageContext.pageName == 'efileSearchResult' || _this.gridService.pageContext.pageName == 'searchResults') {
                        _this.fullFilterList.forEach(function (f) {
                            f.selected = false;
                        });
                        _this.filters = _this.fullFilterList.map(function (f) { return f; });
                    }
                    else {
                        if (searchObject.query.status && searchObject.query.status.length) {
                            _this.fullFilterList.forEach(function (f) {
                                var statusList = searchObject.query.status.filter(function (s) { return s == f.value; });
                                if (statusList && statusList.length)
                                    f.selected = true;
                                else
                                    f.selected = false;
                            });
                            _this.filters = _this.fullFilterList.filter(function (f) {
                                return f.selected;
                            }).map(function (f) { f.selected = false; return f; });
                        }
                        else {
                            _this.fullFilterList.forEach(function (f) {
                                f.selected = false;
                            });
                            _this.filters = _this.fullFilterList.map(function (f) { return f; });
                        }
                    }
                }
            }
        }));
    };
    MultiSelectFilterComponent.prototype.isFilterActive = function () {
        // return !this.filters.every(f => { return f.selected });
        return this.filters.some(function (f) { return f.selected; });
    };
    MultiSelectFilterComponent.prototype.doesFilterPass = function (params) {
        var _this = this;
        if (this.selectedFilters && this.selectedFilters.length) {
            return this.selectedFilters.some(function (filter) {
                return (_this.valueGetter(params.node)
                    .toString()
                    .toLowerCase() === filter.text.toLowerCase());
            });
        }
        else {
            return true;
        }
    };
    MultiSelectFilterComponent.prototype.ngAfterViewInit = function (params) {
    };
    // noinspection JSMethodCanBeStatic
    MultiSelectFilterComponent.prototype.componentMethod = function (message) {
        // alert(`Alert from MultiSelectFilterComponent ${message}`);
    };
    MultiSelectFilterComponent.prototype.onClick = function (event, selectedFilter) {
        if (this.unAppliedfilters.length == 0) {
            this.unAppliedfilters.push(selectedFilter);
        }
        else {
            var Pos = this.unAppliedfilters.findIndex(function (item) {
                return item.value === selectedFilter.value;
            });
            if (Pos > -1) {
                //this.unAppliedfilters.splice(Pos,1);
                this.unAppliedfilters[Pos].selected = event.target.checked;
            }
            else {
                this.unAppliedfilters.push(selectedFilter);
            }
        }
        this.selectedFilters = this.filters.filter(function (filter) {
            return filter.selected;
        });
        if (this.selectedFilters && this.selectedFilters.length > 0)
            this.isSelected = true;
        else
            this.isSelected = false;
        this.SelectAllValue = this.selectedFilters ? this.selectedFilters.length === this.filters.length : false;
        if (this.cd && !this.cd.destroyed) {
            this.cd.detectChanges();
        }
    };
    MultiSelectFilterComponent.prototype.onApplyClick = function (event) {
        if (this.isSelected) {
            this.unAppliedfilters = [];
            this.params.filterChangedCallback();
            this.guiParams.hidePopup();
        }
    };
    MultiSelectFilterComponent.prototype.afterGuiAttached = function (params) {
        this.guiParams = params;
    };
    MultiSelectFilterComponent.prototype.onSortRequested = function (isSortedRequest) {
        if (!!isSortedRequest) {
            this.guiParams.hidePopup();
        }
    };
    MultiSelectFilterComponent.prototype.prestineFilter = function () {
        var _this = this;
        var gridColumn = null;
        if (this.unAppliedfilters.length > 0) {
            if (this.unAppliedfilters.length === this.filters.length) {
                this.SelectAllValue = false;
            }
            if (this.gridService.allFilters) {
                gridColumn = this.gridService.allFilters.find(function (col) {
                    return col.key === _this.colId;
                });
            }
            this.filters.map(function (filterItem) {
                var Pos = _this.unAppliedfilters.findIndex(function (unAppliedItem) {
                    return filterItem.value === unAppliedItem.value;
                });
                if (Pos > -1) {
                    if (_this.gridService.allFilters && gridColumn) {
                        // if filter are pre applied
                        var appliedFilterPos = gridColumn.text.findIndex(function (appliedFilterItem) {
                            return appliedFilterItem == filterItem.text;
                        });
                        if (appliedFilterPos > -1) {
                            filterItem.selected = true;
                        }
                        else {
                            filterItem.selected = false;
                        }
                    }
                    else {
                        filterItem.selected = false; // if no filter are pre applied
                    }
                }
            });
            this.unAppliedfilters = [];
        }
        if (this.cd && !this.cd.destroyed) {
            this.cd.detectChanges();
        }
    };
    MultiSelectFilterComponent.prototype.selectAllChange = function (event) {
        var _this = this;
        this.unAppliedfilters = [];
        this.selectedFilters = this.filters.map(function (filter) {
            if (_this.SelectAllValue) {
                _this.unAppliedfilters.push(filter);
            }
            return filter.selected = _this.SelectAllValue;
        });
        this.isSelected = this.SelectAllValue;
        if (this.cd && !this.cd.destroyed) {
            this.cd.detectChanges();
        }
    };
    MultiSelectFilterComponent.prototype.onFilter = function (event) {
        var _this = this;
        if (this.text) {
            this.filters = this.fullFilterList.filter(function (f) {
                return f.text.toLowerCase().indexOf(_this.text.toLowerCase()) !== -1;
            });
        }
        else {
            this.filters = this.fullFilterList.slice();
        }
        var selectedFilters = this.filters.filter(function (sf) { return sf.selected; });
        this.SelectAllValue = selectedFilters ? selectedFilters.length === this.filters.length : false;
        if (this.cd && !this.cd.destroyed) {
            this.cd.detectChanges();
        }
    };
    MultiSelectFilterComponent.prototype.onRemoveFilter = function () {
        this.unAppliedfilters = [];
        this.isSelected = false;
        this.SelectAllValue = false;
        this.text = "";
        this.onFilter(event);
        this.gridService.removeFilters(this.params.column.getColId());
        if (this.cd && !this.cd.destroyed) {
            this.cd.detectChanges();
        }
        this.guiParams.hidePopup();
    };
    MultiSelectFilterComponent.prototype.ngOnDestroy = function () {
        this.cd.reattach();
    };
    MultiSelectFilterComponent = tslib_1.__decorate([
        AutoUnsubscribe(),
        tslib_1.__metadata("design:paramtypes", [CpAgGridService,
            AppService,
            ChangeDetectorRef,
            LocationLanguageService,
            GlobalAppService, TranslateService])
    ], MultiSelectFilterComponent);
    return MultiSelectFilterComponent;
}());
export { MultiSelectFilterComponent };
