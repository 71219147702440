import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { createContractLink } from "@app/conf/appLink";
import { environment } from '@env/environment';
import { HttpService } from '@app/Modules/Shared/Services/http.service';

@Component({
  selector: 'app-hyperlink',
  templateUrl: './hyperlink.component.html',
  styleUrls: ['./hyperlink.component.scss']
})
export class HyperlinkComponent implements AgRendererComponent {

  constructor(private httpServ: HttpService) { }
  params: any;  

  agInit(params: any): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    return false;
  }

  onLinkClick(event) {
    if (!environment.spartaEncryption) {
      let redirectUrl: string = createContractLink.createContractURI + this.params.data.contractNumber;
      window.open(redirectUrl, '_blank');
    }
    else {
      let spartaURL = this.httpServ.getRegionSpecificURL(environment.spartaModifyPAURL);
      if(this.params && this.params.data){
        let encryptedContractNumber = this.params.data.encryptedcontractnumber;
        spartaURL = spartaURL.replace('####', encryptedContractNumber);
      }
      window.open(spartaURL, '_blank');
    }
  }

}
