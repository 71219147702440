import { Component, OnDestroy } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { CbService } from '@app/Modules/chargeback/Services/cb.service';
import { HttpChargeBackService } from '@app/Modules/Shared/Services/http-charge-back.service';
import { CpAgGridService } from '@app/Modules/cp-grid/services/cp-ag-grid-service';
import { MessageService } from 'primeng/api';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { ActivatedRoute, Router } from '@angular/router';

@AutoUnsubscribe()

@Component({
  selector: 'efile-history-download',
  templateUrl: './efile-history-download.component.html',
  styleUrls: ['./efile-history-download.component.scss']
})

export class EfileHistoryDownloadComponent implements AgRendererComponent, OnDestroy {

  params: any;
  isdownloadLatest: boolean = false;
  isdownloadOlder: boolean = false;
  fileversion: number;
  subscription: Subscription = new Subscription();
  linesInFile: number;
  
  constructor(
    private cbServ: CbService,
    private httpServ: HttpChargeBackService,
    private gridService: CpAgGridService,
    private messageService: MessageService,
    private appServ: AppService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  agInit(params: any) {
    this.params = params;
    this.linesInFile = this.cbServ.totalLinesInFile;
    let HighestRowIndex = this.cbServ.getRowCount() - 1;
    if(this.params.rowIndex === 0){
      this.isdownloadLatest = true;
      this.fileversion = 1;
    }
    else if (this.params.rowIndex > 0 && this.params.rowIndex === HighestRowIndex){
      this.isdownloadOlder = true;
      this.fileversion = 0;
    }
  }

  svcCall(){
    this.appServ.setLoadingStatus(true);
    let pageContext  = this.gridService.pageContext;
    let request = {
      "claimFileId": pageContext.request.claimFileId,
      "salesOrgId": pageContext.request.salesOrgId,
      "fileversion": this.fileversion
    }
    this.subscription.add(this.httpServ.efileDownload(request).subscribe((res) => {
      if (res && res.fileUrl) {

        this.router.navigate([{ outlets: { dialogs: ['downloadLinkModal'] } }],
            {
              queryParams: {
                region: localStorage.getItem('default-region'),
                country: localStorage.getItem('country'),
                language: localStorage.getItem('country-lang')
              },
              relativeTo: this.activatedRoute.parent,
              state: { url: res.fileUrl, title: 'chargeback.ribbonmenu.eFileLabel' }
            }
          );
      }
      else {
        this.messageService.add({ key: 'efileDownload', detail: 'An unexpected error occurred. Please try again.', summary: 'Error', severity: 'error' })
      }
    },
      (e) => {
        this.messageService.add({ key: 'efileDownload', detail: 'An unexpected error occurred. Please try again.', summary: 'Error', severity: 'error' });
      },
      () => {
        this.appServ.setLoadingStatus(false);
      }
    ));
  }

  refresh(params: any): boolean {
    return false;
  }

  ngOnDestroy(){

  }
}
