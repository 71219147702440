import { TooltipAndFavoriteComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/tooltip-and-favorite/tooltip-and-favorite.component';
import { CustomcheckboxComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/customcheckbox/customcheckbox.component';
import { CbhyperlinkComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/cbhyperlink/cbhyperlink.component';
import { ContextmenucbComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/contextmenucb/contextmenucb.component';
import { EfileStatusColumnComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/efile-status-column/efile-status-column.component';
import { EfileErrorColumnComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/efile-error-column/efile-error-column.component';
import { PriceFormatterComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/price-formatter/price-formatter.component';
import { EfileDownnloadComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/efile-downnload/efile-downnload.component';
import { EfileTypeColumnComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/efile-type-column/efile-type-column.component';
import { OverflowEllipsestextComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/overflow-ellipsestext/overflow-ellipsestext.component';
import { ContextmenuManageErrorComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/contextmenu-manage-error/contextmenu-manage-error.component';
import { EfileErrorcellComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/efile-errorcell/efile-errorcell.component';
import { EfileEditErrorLineComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/efile-edit-error-line/efile-edit-error-line.component';
import { EfileHistoryDownloadComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/efile-history-download/efile-history-download.component';
import { TimeFormatComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/time-format/time-format.component';
import { FullWidthRowComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/full-width-row/full-width-row.component';
import { KcfileEditErrorLineComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/kcfile-edit-error-line/kcfile-edit-error-line.component';
import { KcFileContextmenuManageErrorComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/kcfile-contextmenu-manage-error/kcfile-contextmenu-manage-error.component';
import { KcfileHistoryDownloadComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/kcfile-history-download/kcfile-history-download.component';
import { KcfileDownnloadComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/kcfile-downnload/kcfile-downnload.component';
import { KcFileContextmenucbComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/kcfile-contextmenucb/kcfile-contextmenucb.component';
import { KcfileStatusColumnComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/kcfile-status-column/kcfile-status-column.component';
import { HyperlinkAndFavoriteComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/hyperlink-and-favorite/hyperlink-and-favorite.component';
import { TextTranslationComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/text-translation/text-translation.component';
import { KCFileMultiplePopup } from '@app/Modules/cp-grid/grid-features/cell-renderer/kcfile-multiple-popup/kcfile-multiple-popup.component';
import { ReferenceNumberMultiplePopup } from '@app/Modules/cp-grid/grid-features/cell-renderer/refnumber-multiple-popup/refnumber-multiple-popup.component';
import { ClaimNumberMultipleHyperlink } from '@app/Modules/cp-grid/grid-features/cell-renderer/claimnumber-multiple-hyperlink/claimnumber-multiple-hyperlink.component';

let CustomCheckbox = {
  headerName: '',
  field: '_cpCheckbox',
  cellRendererFramework: CustomcheckboxComponent,
  checkboxSelection: true,
  headerCheckboxSelection: '',
  suppressSizeToFit: true,
  width: 30,
  minWidth: 50,
  autoHeight: true,
  suppressMenu: true,
  cellClass: "",
  suppressMovable: true,
  colId: '_cpCheckbox',
  // pinned: 'left'
}

let CouponNumber = {
  headerName: 'agGrid.agGridColumns.couponNumberLabel',
  field: 'couponNumber',
  filter: "freeTextFilter",
  cellRendererFramework: CbhyperlinkComponent,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  menuTabs: ["filterMenuTab"],
  suppressMovable: true,
  width: 90,
  minWidth: 50,
  colId: 'couponNumber',
  // pinned: 'left'
}

let ReferenceNumber = {
  headerName: 'agGrid.agGridColumns.referenceNumberLabel',
  field: 'referenceNumber',
  filter: "freeTextFilter",
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  menuTabs: ["filterMenuTab"],
  suppressMovable: true,
  width: 120,
  minWidth: 50,
  colId: 'referenceNumber',
  cellRendererFramework: OverflowEllipsestextComponent
}

let ClaimNumberMultiple = {
  headerName: 'chargeback.search.claimNumberLabel',
  field: 'claimNumber',
  filter: "freeTextFilter",
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  menuTabs: ["filterMenuTab"],
  suppressMovable: true,
  width: 180,
  minWidth: 50,
  colId: 'claimNumber',
  cellRendererFramework: ClaimNumberMultipleHyperlink
}

let ReferenceNumberFullName = {
  headerName: 'agGrid.agGridColumns.referenceNumberLabelFull',
  field: 'referenceNumber',
  filter: "freeTextFilter",
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  menuTabs: ["filterMenuTab"],
  suppressMovable: true,
  width: 120,
  minWidth: 50,
  colId: 'referenceNumber',
  cellRendererFramework: OverflowEllipsestextComponent
}

let ReferenceNumberMultiple = {
  headerName: 'agGrid.agGridColumns.referenceNumberLabel',
  field: 'referenceNumber',
  filter: "freeTextFilter",
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  menuTabs: ["filterMenuTab"],
  suppressMovable: true,
  width: 150,
  minWidth: 50,
  colId: 'referenceNumber',
  cellRendererFramework: ReferenceNumberMultiplePopup
}

let ClaimPeriod = {
  headerName: 'agGrid.agGridColumns.claimPeriodLabel',
  field: 'claimPeriodMonthAndYear',
  filter: "multiSelectFilter",
  cellRendererFramework: '',
  checkboxSelection: '',
  width: 150,
  minWidth: 50,
  autoHeight: true,
  headerCheckboxSelection: '',
  menuTabs: ["filterMenuTab"],
  cellClass: "cell-wrap-text ellipsis",
  suppressMovable: true,
  colId: 'claimPeriodMonthAndYear'
}

let IssuedDate = {
  headerName: 'agGrid.agGridColumns.issuedDateLabel',
  field: 'issueDate',
  filter: "dateSelector",
  cellRendererFramework: '',
  checkboxSelection: '',
  headerCheckboxSelection: '',
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  menuTabs: ["filterMenuTab"],
  suppressMovable: true,
  width: 90,
  minWidth: 50,
  colId: 'issueDate'
}
let SubmittedDate = {
  headerName: 'agGrid.agGridColumns.submittedDateLabel',
  field: 'submittedDate',
  filter: "dateSelector",
  cellRendererFramework: '',
  checkboxSelection: '',
  headerCheckboxSelection: '',
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  menuTabs: ["filterMenuTab"],
  suppressMovable: true,
  width: 90,
  minWidth: 50,
  colId: 'submittedDate'
}



let Requested = {
  headerName: 'agGrid.agGridColumns.requestedLabel',
  field: 'requested',
  filter: "sortonly",
  cellRendererFramework: PriceFormatterComponent,
  menuTabs: ["filterMenuTab"],
  width: 80,
  minWidth: 50,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis textRight",
  suppressMovable: true,
  headerClass: 'textRight',
  colId: 'requested'
}

let Errors = {
  headerName: 'agGrid.agGridColumns.errorsLabel',
  field: 'errors',
  filter: "sortonly",
  cellRendererFramework: CbhyperlinkComponent,
  menuTabs: ["filterMenuTab"],
  width: 70,
  minWidth: 50,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis textRight",
  suppressMovable: true,
  headerClass: 'textRight',
  colId: 'errors'
}

let NetIssued = {
  headerName: 'agGrid.agGridColumns.netIssuedLabel',
  field: 'netIssued',
  filter: "sortonly",
  cellRendererFramework: CbhyperlinkComponent,
  menuTabs: ["filterMenuTab"],
  width: 120,
  minWidth: 50,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis textRight",
  headerClass: 'textRight',
  suppressMovable: true,
  colId: 'netIssued'
}

let TermsRecaptured = {
  headerName: 'agGrid.agGridColumns.termsRecapturedLabel',
  field: 'termsRecaptured',
  filter: "sortonly",
  cellRendererFramework: PriceFormatterComponent,
  menuTabs: ["filterMenuTab"],
  width: 80,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis textRight",
  suppressMovable: true,
  headerClass: 'textRight',
  colId: 'termsRecaptured'
}

let taxNumberCB = {
  headerName: 'agGrid.agGridColumns.taxNumberLabel',
  field: 'tax',
  cellRendererFramework: PriceFormatterComponent,
  filter: "sortonly",
  autoHeight: true,
  width: 90,
  cellClass: "cell-wrap-text ellipsis",
  menuTabs: ["filterMenuTab"],
  suppressMovable: true,
  colId: 'tax'
}

let StatusCb = {
  headerName: 'agGrid.agGridColumns.statusCbLabel',
  field: 'status',
  filter: "multiSelectFilter",
  cellRendererFramework: TooltipAndFavoriteComponent,
  checkboxSelection: '',
  headerCheckboxSelection: '',
  width: 120,
  minWidth: 50,
  autoHeight: true,
  cellClass: "cell-wrap-text statusColor",
  menuTabs: ["filterMenuTab"],
  suppressMovable: true,
  colId: 'status'
}

let efileStatusCb = {
  headerName: 'agGrid.agGridColumns.statusCbLabel',
  field: 'status',
  filter: "multiSelectFilter",
  cellRendererFramework: EfileStatusColumnComponent,
  checkboxSelection: '',
  headerCheckboxSelection: '',
  width: 120,
  minWidth: 50,
  autoHeight: true,
  cellClass: "ellipsis statusColor",
  menuTabs: ["filterMenuTab"],
  suppressMovable: true,
  colId: 'status_Efile'
}

let ClaimRequestType = {
  headerName: 'agGrid.agGridColumns.claimRequestTypeLabel',
  field: 'claimRequestType',
  filter: "multiSelectFilter",
  cellRendererFramework: TooltipAndFavoriteComponent, OverflowEllipsestextComponent,
  checkboxSelection: '',
  headerCheckboxSelection: '',
  width: 70,
  minWidth: 50,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  menuTabs: ["filterMenuTab"],
  suppressMovable: true,
  colId: 'claimRequestType'
}

let RvRb = {
  headerName: 'agGrid.agGridColumns.rvRbLabel',
  field: 'rvRb',
  filter: "multiSelectFilter",
  cellRendererFramework: TooltipAndFavoriteComponent,
  checkboxSelection: '',
  headerCheckboxSelection: '',
  width: 100,
  minWidth: 50,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  menuTabs: ["filterMenuTab"],
  suppressMovable: true,
  colId: 'rvRb'
}

let Currency = {
  headerName: 'agGrid.agGridColumns.currencyLabel',
  field: 'currency',
  filter: "multiSelectFilter",
  checkboxSelection: '',
  headerCheckboxSelection: '',
  width: 70,
  minWidth: 50,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  menuTabs: ["filterMenuTab"],
  suppressMovable: true,
  colId: 'currency',
  // pinned: 'right'
}

let Received = {
  headerName: 'agGrid.agGridColumns.receivedLabel',
  field: 'receivedDate',
  filter: "dateSelector",
  checkboxSelection: '',
  headerCheckboxSelection: '',
  autoHeight: true,
  cellClass: "cell-wrap-text",
  menuTabs: ["filterMenuTab"],
  suppressMovable: true,
  width: 150,
  colId: 'receivedDate'
}

let Available = {
  headerName: 'agGrid.agGridColumns.availableLabel',
  field: 'availableDate',
  filter: "dateSelector",
  checkboxSelection: '',
  headerCheckboxSelection: '',
  autoHeight: true,
  cellClass: "cell-wrap-text",
  menuTabs: ["filterMenuTab"],
  suppressMovable: true,
  width: 150,
  colId: 'availableDate'
}

let customerNumberCB = {
  headerName: 'agGrid.agGridColumns.customerNumberLabel',
  field: 'customerNumber',
  filter: "freeTextFilter",
  cellRendererFramework: TooltipAndFavoriteComponent,
  width: 130,
  minWidth: 50,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  menuTabs: ["filterMenuTab"],
  suppressMovable: true,
  colId: 'customerNumber'
}

let efileCustomerNumberCB = {
  headerName: 'agGrid.agGridColumns.customerNumberLabel',
  field: 'customerNumber',
  filter: "freeTextFilter",
  width: 130,
  minWidth: 50,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  menuTabs: ["filterMenuTab"],
  suppressMovable: true,
  colId: 'customerNumber_CB',
  cellRendererFramework: KCFileMultiplePopup
}

/* Col Def. for Coupon Summery Result */
let customerEUCB = {
  headerName: 'agGrid.agGridColumns.customerEULabel',
  field: 'custEU',
  filter: "typeAheadFilter",
  cellRendererFramework: CbhyperlinkComponent,
  width: 170,
  minWidth: 50,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  menuTabs: ["filterMenuTab"],
  suppressMovable: true,
  colId: 'custEU'
}

let customerEUDetailsCB = {
  headerName: 'agGrid.agGridColumns.customerEUDetailsLabel',
  field: 'custEuDetails',
  filter: "freeTextFilter",
  cellRendererFramework: '',
  width: 250,
  autoHeight: true,
  cellClass: "cell-wrap-text",
  menuTabs: ["filterMenuTab"],
  suppressMovable: true,
  colId: 'custEuDetails'
}

let kcEUCB = {
  headerName: 'agGrid.agGridColumns.kceuLabel',
  field: 'kCEU',
  filter: "freeTextFilter",
  cellRendererFramework: '',
  width: 150,
  minWidth: 50,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  menuTabs: ["filterMenuTab"],
  suppressMovable: true,
  colId: 'kCEU'
}

let kcEUDetailsCB = {
  headerName: 'agGrid.agGridColumns.kceuDetailsLabel',
  field: 'kCEUDetails',
  filter: "freeTextFilter",
  cellRendererFramework: OverflowEllipsestextComponent,
  width: 250,
  minWidth: 50,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  menuTabs: ["filterMenuTab"],
  suppressMovable: true,
  colId: 'kCEUDetails'
}

let requestedCB = {
  headerName: 'agGrid.agGridColumns.requestedCBLabel',
  field: 'requested',
  filter: "freeTextFilter",
  cellRendererFramework: PriceFormatterComponent,
  width: 130,
  minwidth: 50,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis textRight",
  menuTabs: ["filterMenuTab"],
  suppressMovable: true,
  headerClass: 'textRight',
  colId: 'requested'
}

let issuedCB = {
  headerName: 'agGrid.agGridColumns.issuedLabel',
  field: 'issued',
  filter: "freeTextFilter",
  cellRendererFramework: PriceFormatterComponent,
  width: 130,
  minWidth: 50,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis textRight",
  menuTabs: ["filterMenuTab"],
  suppressMovable: true,
  headerClass: 'textRight',
  colId: 'issued'
}

let discrepanciesCB = {
  headerName: 'agGrid.agGridColumns.discrepanciesLabel',
  field: 'discrepancies',
  filter: "multiSelectFilter",
  cellRendererFramework: TextTranslationComponent,
  width: 130,
  minWidth: 50,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  menuTabs: ["filterMenuTab"],
  suppressMovable: true,
  headerClass: "discrepanciesCol",
  colId: 'discrepancies'
}
let linesCB = {
  headerName: 'agGrid.agGridColumns.linesLabel',
  field: 'lines',
  filter: "freeTextFilter",
  cellRendererFramework: '',
  width: 130,
  minWidth: 50,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis textCenter",
  menuTabs: ["filterMenuTab"],
  suppressMovable: true,
  colId: 'lines',
  cellRenderer: 'linesCellRenderer',
  cellRendererParams: {
    //suppressEnterExpand: true,
  },
}
/*-----------------------------------*/
let CouponClaimPeriod = {
  headerName: 'agGrid.agGridColumns.claimPeriodLabel',
  field: 'claimPeriodMonthAndYear',
  filter: false,
  menuTabs: [],
  cellRendererFramework: '',
  checkboxSelection: '',
  width: 150,
  minWidth: 50,
  autoHeight: true,
  headerCheckboxSelection: '',
  cellClass: "cell-wrap-text ellipsis",
  suppressMovable: true,
  colId: 'claimPeriodMonthAndYear'
}

let GrossIssued = {
  headerName: 'agGrid.agGridColumns.grossIssuedLabel',
  field: 'grossIssued',
  filter: false,
  cellRendererFramework: PriceFormatterComponent,
  menuTabs: [],
  width: 150,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis textRight",
  suppressMovable: true,
  headerClass: 'textRight',
  colId: 'grossIssued'
}

let Difference = {
  headerName: 'agGrid.agGridColumns.differenceLabel',
  field: 'difference',
  filter: false,
  cellRendererFramework: PriceFormatterComponent,
  menuTabs: [],
  width: 150,
  minWidth: 50,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  suppressMovable: true,
  //headerClass: 'textRight',
  colId: 'difference'
}

let SummaryNetIssued = {
  headerName: 'agGrid.agGridColumns.netIssuedSummaryLabel',
  field: 'totalIssueAmount',
  filter: false,
  cellRendererFramework: PriceFormatterComponent,
  menuTabs: [],
  width: 150,
  minWidth: 50,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis textRight",
  headerClass: 'textRight',
  suppressMovable: true,
  colId: 'totalIssueAmount',
  cellStyle: { color: '#009900', 'font-weight': 'bold' }
}

let RequestedAmount = {
  headerName: 'agGrid.agGridColumns.requestedSummaryLabel',
  field: 'requestedAmount',
  filter: "sortonly",
  cellRendererFramework: PriceFormatterComponent,
  menuTabs: [],
  width: 150,
  minWidth: 50,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  suppressMovable: true,
  //headerClass: 'textRight',
  colId: 'requestedAmount'
}

let ErrorDescription = {
  headerName: 'agGrid.agGridColumns.errorDescriptionLabel',
  field: 'errorDescription',
  filter: "sortonly",
  cellRendererFramework: PriceFormatterComponent,
  menuTabs: [],
  width: 150,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis textRight",
  suppressMovable: true,
  headerClass: 'textRight',
  colId: 'errorDescription'
}


let TaxAmount = {
  headerName: 'agGrid.agGridColumns.taxNumberSummaryLabel',
  field: 'taxAmount',
  filter: "sortonly",
  cellRendererFramework: PriceFormatterComponent,
  autoHeight: true,
  width: 100,
  cellClass: "cell-wrap-text ellipsis",
  menuTabs: [],
  suppressMovable: true,
  colId: 'taxAmount',
  cellStyle: { textAlign: 'center' }
}

let TermsRecapturedSummary = {
  headerName: 'agGrid.agGridColumns.termsRecapturedSummaryLabel',
  field: 'termsRecaptured',
  filter: "sortonly",
  cellRendererFramework: PriceFormatterComponent,
  menuTabs: [],
  width: 200,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis textRight",
  suppressMovable: true,
  headerClass: 'textRight',
  colId: 'termsRecaptured'
}


//Added For Coupon End User Details Result Grid
let CustEuDetails = {
  headerName: '',
  field: 'custEuDetails',
  filter: false,
  width: 200,
  autoHeight: true,
  cellClass: "cell-wrap-text",
  suppressMovable: true,
  colId: 'custEuDetails',
  rowGroup: false,
  hide: false
}
let LineItemNumber = {
  //headerName: 'agGrid.agGridColumns.lineItemNumberLabel',
  field: 'lineItemNumber',
  rowGroup: true,
  colId: 'lineItemNumber',
  hide: true
}


let LineType = {
  headerName: 'agGrid.agGridColumns.lineTypeLabel',
  field: 'lineType',
  filter: false,
  width: 60,
  autoHeight: true,
  cellClass: "cell-wrap-text",
  suppressMovable: true,
  suppressMenu: true,
  colId: 'lineType'
}

let PANumber = {
  headerName: 'agGrid.agGridColumns.pANumberLabel',
  field: 'pANumber',
  filter: false,
  width: 100,
  autoHeight: true,
  cellClass: "cell-wrap-text",
  suppressMovable: true,
  suppressMenu: true,
  colId: 'pANumber'
}
let Product = {
  headerName: 'agGrid.agGridColumns.productLabel',
  field: 'product',
  filter: false,
  width: 80,
  autoHeight: true,
  cellClass: "cell-wrap-text",
  suppressMovable: true,
  suppressMenu: true,
  colId: 'product'
}
let ProductDescription = {
  headerName: 'agGrid.agGridColumns.productDescriptionLabel',
  field: 'productDescription',
  filter: false,
  width: 200,
  autoHeight: true,
  cellClass: "cell-wrap-text",
  suppressMovable: true,
  suppressMenu: true,
  colId: 'productDescription'
}

let AltProduct = {
  headerName: 'agGrid.agGridColumns.altProductLabel',
  field: 'altProduct',
  filter: false,
  width: 100,
  autoHeight: true,
  cellClass: "cell-wrap-text",
  suppressMovable: true,
  suppressMenu: true,
  colId: 'altProduct'
}
let Quantity = {
  headerName: 'agGrid.agGridColumns.quantityLabel',
  field: 'quantity',
  filter: false,
  width: 100,
  autoHeight: true,
  cellClass: "cell-wrap-text",
  suppressMovable: true,
  suppressMenu: true,
  colId: 'quantity',
  cellRendererFramework: PriceFormatterComponent
}
let UOM = {
  headerName: 'agGrid.agGridColumns.uOMLabel',
  field: 'uOM',
  filter: false,
  width: 80,
  autoHeight: true,
  cellClass: "cell-wrap-text",
  suppressMovable: true,
  suppressMenu: true,
  colId: 'uOM'
}
let InvoiceNumber = {
  headerName: 'agGrid.agGridColumns.invoiceNumberLabel',
  field: 'invoiceNumber',
  filter: false,
  width: 100,
  autoHeight: true,
  cellClass: "cell-wrap-text",
  suppressMovable: true,
  suppressMenu: true,
  colId: 'invoiceNumber'
}
let InvoiceDate = {
  headerName: 'agGrid.agGridColumns.invoiceDateLabel',
  field: 'invoiceDate',
  filter: false,
  width: 110,
  autoHeight: true,
  cellClass: "cell-wrap-text",
  suppressMovable: true,
  suppressMenu: true,
  colId: 'invoiceDate'
}
let InvoicePrice = {
  headerName: 'agGrid.agGridColumns.invoicePriceLabel',
  field: 'invoicePrice',
  cellRendererFramework: PriceFormatterComponent,
  filter: false,
  width: 130,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis textRight",
  headerClass: 'textRight',
  suppressMovable: true,
  suppressMenu: true,
  colId: 'invoicePrice'
}
let PAPrice = {
  headerName: 'agGrid.agGridColumns.pAPriceLabel',
  field: 'pAPrice',
  cellRendererFramework: PriceFormatterComponent,
  filter: false,
  width: 100,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis textRight",
  headerClass: 'textRight',
  suppressMovable: true,
  suppressMenu: true,
  colId: 'pAPrice'
}
let RebateAmount = {
  headerName: 'agGrid.agGridColumns.rebateAmountLabel',
  field: 'rebateAmount',
  cellRendererFramework: PriceFormatterComponent,
  filter: false,
  width: 100,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis textRight",
  headerClass: 'textRight',
  suppressMovable: true,
  suppressMenu: true,
  colId: 'rebateAmount'
}
let PATotalLine = {
  headerName: 'agGrid.agGridColumns.pATotalLineLabel',
  field: 'pATotalLine',
  cellRendererFramework: PriceFormatterComponent,
  filter: false,
  width: 100,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis textRight",
  headerClass: 'textRight',
  suppressMovable: true,
  suppressMenu: true,
  colId: 'pATotalLine'
}
let Differences = {
  headerName: 'agGrid.agGridColumns.differencesLabel',
  field: 'differences',
  cellRendererFramework: PriceFormatterComponent,
  filter: false,
  width: 100,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis textRight padRight20",
  headerClass: 'textRight',
  suppressMovable: true,
  suppressMenu: true,
  colId: 'differences'
}
let EmptyCol = {
  width: 18,
  field: 'emptyCol',
  colId: 'emptyCol',
  cellStyle: { padding: '0 !important', 'max-width': '18px' }
}
let EmptyColSmall = {
  width: 2,
  field: 'emptyColSmall',
  colId: 'emptyColSmall',
  cellStyle: { padding: '0 !important', 'max-width': '10px' }
}
let FooterId = {
  headerName: '',
  field: 'id',
  filter: false,
  width: 100,
  cellRendererFramework: FullWidthRowComponent,
  autoHeight: true,
  cellClass: "cell-wrap-text",
  suppressMovable: true,
  suppressMenu: true,
  colId: 'id'
}
let FooterVal = {
  headerName: '',
  field: 'val',
  cellRendererFramework: PriceFormatterComponent,
  filter: false,
  width: 100,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis textRight",
  suppressMovable: true,
  suppressMenu: true,
  colId: 'val',
  suppressSizeToFit: true,
  pinned: 'right'
}

let efileId = {
  headerName: 'agGrid.agGridColumns.eFileIDLabel',
  field: 'eFileID',
  filter: "freeTextFilter",
  width: 100,
  minWidth: 50,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  suppressMovable: true,
  colId: 'eFileID',
  menuTabs: ["filterMenuTab"]
}

let claimFileId = {
  headerName: 'agGrid.agGridColumns.eFileIDLabel',
  field: 'claimFileId',
  filter: "freeTextFilter",
  width: 100,
  minWidth: 50,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  suppressMovable: true,
  colId: 'claimFileId',
  menuTabs: ["filterMenuTab"]
}

let efileName = {
  headerName: 'agGrid.agGridColumns.eFileNameLabel',
  field: 'eFileName',
  width: 100,
  minWidth: 50,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  suppressMovable: true,
  colId: 'eFileName',
  menuTabs: ["filterMenuTab"],
  filter: "freeTextFilter",
  cellRendererFramework: EfileDownnloadComponent
}

let uploadedBy = {
  headerName: 'agGrid.agGridColumns.UploadedByLabel',
  field: 'uploadedBy',
  width: 100,
  minWidth: 50,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  suppressMovable: true,
  colId: 'uploadedBy',
  menuTabs: ["filterMenuTab"],
  filter: "freeTextFilter",
  cellRendererFramework: OverflowEllipsestextComponent
}

let customerName = {
  headerName: 'agGrid.agGridColumns.customerNameLabel',
  field: 'customerName',
  width: 100,
  minWidth: 50,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  suppressMovable: true,
  colId: 'customerName',
  menuTabs: ["filterMenuTab"],
  filter: "freeTextFilter",
  cellRendererFramework: OverflowEllipsestextComponent
}

let customerNameWithMultiple = {
  headerName: 'agGrid.agGridColumns.customerNameLabel',
  field: 'customerName',
  width: 100,
  minWidth: 50,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  suppressMovable: true,
  colId: 'customerName',
  menuTabs: ["filterMenuTab"],
  filter: "freeTextFilter",
  cellRendererFramework: KCFileMultiplePopup
}

let CustomerName1 = {
  headerName: 'agGrid.agGridColumns.customerNameLabel',
  field: 'name1',
  filter: "freeTextFilter",
  cellRendererFramework: '',
  checkboxSelection: '',
  headerCheckboxSelection: '',
  autoHeight: true,
  cellClass: "",
  menuTabs: ["filterMenuTab"],
  suppressMovable: true,
  sort: 'asc'
}

let uploaded = {
  headerName: 'agGrid.agGridColumns.uploadedLabel',
  field: 'uploaded',
  width: 100,
  minWidth: 50,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  suppressMovable: true,
  colId: 'uploaded',
  menuTabs: ["filterMenuTab"],
  filter: "dateSelector"
}

let lastChanged = {
  headerName: 'agGrid.agGridColumns.lastChangedLabel',
  field: 'lastChanged',
  width: 100,
  minWidth: 50,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  suppressMovable: true,
  colId: 'lastChanged',
  menuTabs: ["filterMenuTab"],
  filter: "dateSelector"
}

let submitted = {
  headerName: 'agGrid.agGridColumns.eFileSubmittedHeading',
  field: 'submitted',
  width: 100,
  minWidth: 50,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  suppressMovable: true,
  colId: 'submitted',
  menuTabs: ["filterMenuTab"],
  filter: "dateSelector"
}

let lines = {
  headerName: 'agGrid.agGridColumns.linesNoLabel',
  field: 'lines',
  width: 100,
  minWidth: 50,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  suppressMovable: true,
  colId: 'lines',
  menuTabs: ["filterMenuTab"],
  filter: "freeTextFilter"
}

let errors = {
  headerName: 'agGrid.agGridColumns.errorsLabel',
  field: 'errors',
  width: 100,
  minWidth: 50,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  suppressMovable: true,
  colId: 'errors',
  menuTabs: ["filterMenuTab"],
  filter: "freeTextFilter",
  cellRendererFramework: EfileErrorColumnComponent
}

let fileUploadTypeCB = {
  headerName: 'agGrid.agGridColumns.typeLabel',
  field: 'type',
  filter: "multiSelectFilter",
  width: 100,
  minWidth: 50,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  suppressMovable: true,
  colId: 'type',
  menuTabs: ["filterMenuTab"],
  cellRendererFramework: EfileTypeColumnComponent
}

let ContextMenuCB = {
  headerName: '',
  field: '',
  cellRendererFramework: ContextmenucbComponent,
  checkboxSelection: '',
  headerCheckboxSelection: '',
  suppressSizeToFit: true,
  width: 40,
  minWidth: 40,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  suppressMenu: true,
  suppressMovable: true
}


let KcFileContextMenuCB = {
  headerName: '',
  field: '',
  cellRendererFramework: KcFileContextmenucbComponent,
  checkboxSelection: '',
  headerCheckboxSelection: '',
  suppressSizeToFit: true,
  width: 40,
  minWidth: 40,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  suppressMenu: true,
  suppressMovable: true
}

let updatedBy = {
  headerName: 'agGrid.agGridColumns.updatedBy',
  field: 'updatedby',
  width: 140,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  suppressMovable: true,
  colId: 'updatedby',
  suppressMenu: true,
  cellRendererFramework: OverflowEllipsestextComponent
}

let historyLogStatus = {
  headerName: 'agGrid.agGridColumns.statusCbLabel',
  field: 'status',
  width: 120,
  autoHeight: true,
  cellClass: "cell-wrap-text statusColor",
  suppressMovable: true,
  colId: 'status',
  suppressMenu: true
}

let historyLogErrors = {
  headerName: 'agGrid.agGridColumns.errorsLabel',
  field: 'errors',
  width: 50,
  autoHeight: true,
  cellClass: "cell-wrap-text",
  suppressMovable: true,
  colId: 'errors',
  suppressMenu: true,
  cellRendererFramework: EfileErrorColumnComponent
}

let historyLoglines = {
  headerName: 'agGrid.agGridColumns.linesNoLabel',
  field: 'lines',
  width: 50,
  autoHeight: true,
  cellClass: "cell-wrap-text",
  suppressMovable: true,
  colId: 'lines',
  suppressMenu: true
}

let updated = {
  headerName: 'agGrid.agGridColumns.updated',
  field: 'updated',
  width: 150,
  autoHeight: true,
  cellClass: "cell-wrap-text",
  suppressMovable: true,
  colId: 'updated',
  suppressMenu: true,
  cellRendererFramework: TimeFormatComponent
}

let efileDownload = {
  headerName: 'agGrid.agGridColumns.efile',
  field: '',
  width: 100,
  autoHeight: true,
  cellClass: "cell-wrap-text",
  suppressMovable: true,
  colId: '',
  suppressMenu: true,
  cellRendererFramework: EfileHistoryDownloadComponent
}

let row = {
  headerName: 'agGrid.agGridColumns.row',
  field: 'row',
  filter: "multiSelectFilter",
  width: 140,
  autoHeight: true,
  cellClass: "cell-wrap-text",
  suppressMovable: true,
  colId: 'row_Col',
  menuTabs: ["filterMenuTab"]
}

let field = {
  headerName: 'agGrid.agGridColumns.field',
  field: 'field',
  width: 100,
  autoHeight: true,
  cellClass: "cell-wrap-text",
  suppressMovable: true,
  colId: 'field',
  menuTabs: ["filterMenuTab"],
  filter: "freeTextFilter"
}
let fieldName = {
  headerName: 'agGrid.agGridColumns.fieldName',
  field: 'fieldName',
  width: 180,
  autoHeight: true,
  cellClass: "cell-wrap-text",
  suppressMovable: true,
  colId: 'fieldName',
  menuTabs: ["filterMenuTab"],
  filter: "freeTextFilter"
}

let error = {
  headerName: 'agGrid.agGridColumns.error',
  field: 'error',
  width: 200,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  suppressMovable: true,
  colId: 'error',
  menuTabs: ["filterMenuTab"],
  filter: "freeTextFilter",
  suppressSizeToFit: true,
  cellRendererFramework: OverflowEllipsestextComponent,
}

let refNum = {
  headerName: 'agGrid.agGridColumns.refnum',
  field: 'reF_NUM',
  width: 100,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  suppressMovable: true,
  colId: 'reF_NUM',
  menuTabs: ["filterMenuTab"],
  suppressMenu: true,
  cellRendererFramework: EfileErrorcellComponent
}


let custLoc = {
  headerName: 'agGrid.agGridColumns.custloc',
  field: 'custloc',
  width: 100,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  suppressMovable: true,
  colId: 'custloc',
  menuTabs: ["filterMenuTab"],
  suppressMenu: true,
  cellRendererFramework: EfileErrorcellComponent
}

let dBranch = {
  headerName: 'agGrid.agGridColumns.dbranch',
  field: 'd_BRANCH',
  width: 110,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  suppressMovable: true,
  colId: 'd_BRANCH',
  menuTabs: ["filterMenuTab"],
  suppressMenu: true,
  cellRendererFramework: EfileErrorcellComponent
}

let KCEndu = {
  headerName: 'agGrid.agGridColumns.kcendu',
  field: 'kC_ENDU',
  width: 100,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  suppressMovable: true,
  colId: 'kC_ENDU',
  menuTabs: ["filterMenuTab"],
  suppressMenu: true,
  cellRendererFramework: EfileErrorcellComponent
}

let shtoNbr = {
  headerName: 'agGrid.agGridColumns.shtonbr',
  field: 'shtO_NBR',
  width: 110,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  suppressMovable: true,
  colId: 'shtO_NBR',
  menuTabs: ["filterMenuTab"],
  suppressMenu: true,
  cellRendererFramework: EfileErrorcellComponent
}

let shtoNme = {
  headerName: 'agGrid.agGridColumns.shtonme',
  field: 'shtO_NME',
  width: 110,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  suppressMovable: true,
  colId: 'shtO_NME',
  menuTabs: ["filterMenuTab"],
  suppressMenu: true,
  cellRendererFramework: EfileErrorcellComponent
}

let shtoAd1 = {
  headerName: 'agGrid.agGridColumns.shtoad1',
  field: 'shtO_AD1',
  width: 100,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  suppressMovable: true,
  colId: 'shtO_AD1',
  menuTabs: ["filterMenuTab"],
  suppressMenu: true,
  cellRendererFramework: EfileErrorcellComponent
}

let shtoAd2 = {
  headerName: 'agGrid.agGridColumns.shtoad2',
  field: 'shtO_AD2',
  width: 100,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  suppressMovable: true,
  colId: 'shtO_AD2',
  menuTabs: ["filterMenuTab"],
  suppressMenu: true,
  cellRendererFramework: EfileErrorcellComponent
}

let shtoCty = {
  headerName: 'agGrid.agGridColumns.shtocty',
  field: 'shtO_CTY',
  width: 100,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  suppressMovable: true,
  colId: 'shtO_CTY',
  menuTabs: ["filterMenuTab"],
  suppressMenu: true,
  cellRendererFramework: EfileErrorcellComponent
}

let shtoSt = {
  headerName: 'agGrid.agGridColumns.shtost',
  field: 'shtO_ST',
  width: 100,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  suppressMovable: true,
  colId: 'shtO_ST',
  menuTabs: ["filterMenuTab"],
  suppressMenu: true,
  cellRendererFramework: EfileErrorcellComponent
}

let shtoZip = {
  headerName: 'agGrid.agGridColumns.shtozip',
  field: 'shtO_ZIP',
  width: 100,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  suppressMovable: true,
  colId: 'shtO_ZIP',
  menuTabs: ["filterMenuTab"],
  suppressMenu: true,
  cellRendererFramework: EfileErrorcellComponent
}

let shtoCtry = {
  headerName: 'agGrid.agGridColumns.shtoctry',
  field: 'shtO_CTRY',
  width: 110,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  suppressMovable: true,
  colId: 'shtO_CTRY',
  menuTabs: ["filterMenuTab"],
  suppressMenu: true,
  cellRendererFramework: EfileErrorcellComponent
}

let prodID = {
  headerName: 'agGrid.agGridColumns.prodid',
  field: 'proD_ID',
  width: 100,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  suppressMovable: true,
  colId: 'proD_ID',
  menuTabs: ["filterMenuTab"],
  suppressMenu: true,
  cellRendererFramework: EfileErrorcellComponent
}

let invcDte = {
  headerName: 'agGrid.agGridColumns.invcdte',
  field: 'invC_DTE',
  width: 100,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  suppressMovable: true,
  colId: 'invC_DTE',
  menuTabs: ["filterMenuTab"],
  suppressMenu: true,
  cellRendererFramework: EfileErrorcellComponent
}

let clmStDte = {
  headerName: 'agGrid.agGridColumns.clmStDte',
  field: 'clM_ST_DTE',
  width: 130,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  suppressMovable: true,
  colId: 'clM_ST_DTE',
  menuTabs: ["filterMenuTab"],
  suppressMenu: true,
  cellRendererFramework: EfileErrorcellComponent
}

let clmEnDte = {
  headerName: 'agGrid.agGridColumns.clmEnDte',
  field: 'clM_EN_DTE',
  width: 130,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  suppressMovable: true,
  colId: 'clM_EN_DTE',
  menuTabs: ["filterMenuTab"],
  suppressMenu: true,
  cellRendererFramework: EfileErrorcellComponent
}

let curr = {
  headerName: 'agGrid.agGridColumns.curr',
  field: 'curr',
  width: 100,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  suppressMovable: true,
  colId: 'curr',
  menuTabs: ["filterMenuTab"],
  suppressMenu: true,
  cellRendererFramework: EfileErrorcellComponent
}

let invcNbr = {
  headerName: 'agGrid.agGridColumns.invcnbr',
  field: 'invC_NBR',
  width: 100,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  suppressMovable: true,
  colId: 'invC_NBR',
  menuTabs: ["filterMenuTab"],
  suppressMenu: true,
  cellRendererFramework: EfileErrorcellComponent
}

let cases = {
  headerName: 'agGrid.agGridColumns.cases',
  field: 'cases',
  width: 100,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  suppressMovable: true,
  colId: 'cases',
  menuTabs: ["filterMenuTab"],
  suppressMenu: true,
  cellRendererFramework: EfileErrorcellComponent
}

let untMsr = {
  headerName: 'agGrid.agGridColumns.untmsr',
  field: 'unT_MSR',
  width: 100,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  suppressMovable: true,
  colId: 'unT_MSR',
  menuTabs: ["filterMenuTab"],
  suppressMenu: true,
  cellRendererFramework: EfileErrorcellComponent
}

let itwPrc = {
  headerName: 'agGrid.agGridColumns.itwprc',
  field: 'itW_PRC',
  width: 100,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  suppressMovable: true,
  colId: 'itW_PRC',
  menuTabs: ["filterMenuTab"],
  suppressMenu: true,
  cellRendererFramework: EfileErrorcellComponent
}

let PaPrc = {
  headerName: 'agGrid.agGridColumns.paprc',
  field: 'pA_PRC',
  width: 100,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  suppressMovable: true,
  colId: 'pA_PRC',
  menuTabs: ["filterMenuTab"],
  suppressMenu: true,
  cellRendererFramework: EfileErrorcellComponent
}

let extRbt = {
  headerName: 'agGrid.agGridColumns.extrbt',
  field: 'exT_RBT',
  width: 100,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  suppressMovable: true,
  colId: 'exT_RBT',
  menuTabs: ["filterMenuTab"],
  suppressMenu: true,
  cellRendererFramework: EfileErrorcellComponent
}

let PaNbr = {
  headerName: 'agGrid.agGridColumns.panbr',
  field: 'pA_NBR',
  width: 100,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  suppressMovable: true,
  colId: 'pA_NBR',
  menuTabs: ["filterMenuTab"],
  suppressMenu: true,
  cellRendererFramework: EfileErrorcellComponent
}

let GPOID = {
  headerName: 'agGrid.agGridColumns.gpoid',
  field: 'gpO_ID',
  width: 100,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  suppressMovable: true,
  colId: 'gpO_ID',
  menuTabs: ["filterMenuTab"],
  suppressMenu: true,
  cellRendererFramework: EfileErrorcellComponent
}

let billToNbr = {
  headerName: 'agGrid.agGridColumns.billToNbr',
  field: 'bilL_TO_NBR',
  width: 120,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  suppressMovable: true,
  colId: 'bilL_TO_NBR',
  menuTabs: ["filterMenuTab"],
  suppressMenu: true,
  cellRendererFramework: EfileErrorcellComponent
}

let billToNme = {
  headerName: 'agGrid.agGridColumns.billToNme',
  field: 'bilL_TO_NME',
  width: 120,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  suppressMovable: true,
  colId: 'bilL_TO_NME',
  menuTabs: ["filterMenuTab"],
  suppressMenu: true,
  cellRendererFramework: EfileErrorcellComponent
}


let dProd = {
  headerName: 'agGrid.agGridColumns.dprod',
  field: 'd_PROD',
  width: 100,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  suppressMovable: true,
  colId: 'd_PROD',
  menuTabs: ["filterMenuTab"],
  suppressMenu: true,
  cellRendererFramework: EfileErrorcellComponent
}

let efileErrors = {
  headerName: 'agGrid.agGridColumns.errorsLabel',
  field: 'errors',
  width: 100,
  autoHeight: true,
  cellClass: "cell-wrap-text",
  suppressMovable: true,
  colId: 'efile_Errors',
  menuTabs: ["filterMenuTab"],
  cellRendererFramework: EfileEditErrorLineComponent,
  pinned: 'left',
  suppressMenu: true
}

let efileLines = {
  headerName: 'agGrid.agGridColumns.linesLabel',
  field: 'line',
  width: 100,
  autoHeight: true,
  cellClass: "cell-wrap-text",
  suppressMovable: true,
  colId: 'line_efile',
  menuTabs: ["filterMenuTab"],
  pinned: 'left',
  suppressMenu: true
}
let efileLines1 = {
  headerName: 'agGrid.agGridColumns.linesLabel',
  field: 'line',
  width: 100,
  autoHeight: true,
  cellClass: "cell-wrap-text",
  suppressMovable: true,
  colId: 'line_kcfile',
  menuTabs: ["filterMenuTab"],
  pinned: 'left',
  suppressMenu: true
}

let errorsContextMenuCB = {
  headerName: '',
  field: '',
  cellRendererFramework: ContextmenuManageErrorComponent,
  checkboxSelection: '',
  headerCheckboxSelection: '',
  suppressSizeToFit: true,
  width: 40,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  suppressMenu: true,
  suppressMovable: true,
  pinned: 'right'
}


let CP_Customer_Number = {
  headerName: 'agGrid.agGridColumns.customerIdLabel',
  field: 'customerNumber',
  filter: "freeTextFilter",
  cellRendererFramework: OverflowEllipsestextComponent,
  width: 120,
  minWidth: 50,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  menuTabs: ["filterMenuTab"],
  suppressMovable: true,
  colId: 'customerNumber'
  // suppressMenu: true //remove it later when azure done with their filter/sorting implementation
}
let CP_Customer_Name = {
  headerName: 'agGrid.agGridColumns.customerNameLabel',
  field: 'customerName',
  filter: "freeTextFilter",
  cellRendererFramework: OverflowEllipsestextComponent,
  width: 250,
  minWidth: 50,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  menuTabs: ["filterMenuTab"],
  suppressMovable: true,
  colId: 'customerName'
  // suppressMenu: true //remove it later when azure done with their filter/sorting implementation
}
let CP_Street = {
  headerName: 'agGrid.agGridColumns.addressLabel',
  field: 'address',
  filter: "freeTextFilter",
  cellRendererFramework: OverflowEllipsestextComponent,
  width: 200,
  minWidth: 50,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  menuTabs: ["filterMenuTab"],
  suppressMovable: true,
  colId: 'address'
  // suppressMenu: true //remove it later when azure done with their filter/sorting implementation
}
let CP_CP_City = {
  headerName: 'agGrid.agGridColumns.cityLabel',
  field: 'city',
  filter: "freeTextFilter",
  cellRendererFramework: OverflowEllipsestextComponent,
  width: 130,
  minWidth: 50,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  menuTabs: ["filterMenuTab"],
  suppressMovable: true,
  colId: 'city'
  // suppressMenu: true //remove it later when azure done with their filter/sorting implementation
}
let CP_State_Name = {
  headerName: 'myProfile.MyLocation.addLocationpopUp.stateLabel',
  field: 'state',
  filter: "freeTextFilter",
  cellRendererFramework: OverflowEllipsestextComponent,
  width: 100,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  menuTabs: ["filterMenuTab"],
  suppressMovable: true,
  // suppressMenu: true //remove it later when azure done with their filter/sorting implementation
}
let PA_CP_Country_Name = {
  headerName: 'agGrid.agGridColumns.countryLabel',
  field: 'country',
  filter: "multiSelectFilter",
  width: 150,
  minWidth: 50,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  menuTabs: ["filterMenuTab"],
  suppressMovable: true,
  colId: 'country'
  // suppressMenu: true //remove it later when azure done with their filter/sorting implementation
}
let CP_Zip_Code = {
  headerName: 'agGrid.agGridColumns.postalCodeLabel',
  field: 'postalCode',
  filter: "freeTextFilter",
  cellRendererFramework: OverflowEllipsestextComponent,
  width: 120,
  minWidth: 50,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  menuTabs: ["filterMenuTab"],
  suppressMovable: true,
  colId: 'postalCode'
  // suppressMenu: true //remove it later when azure done with their filter/sorting implementation
}

let postalCode = {
  headerName: 'agGrid.agGridColumns.postalCodeLabel',
  field: 'postalCode',
  filter: "freeTextFilter",
  cellRendererFramework: OverflowEllipsestextComponent,
  width: 120,
  minWidth: 50,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  menuTabs: ["filterMenuTab"],
  suppressMovable: true,
  colId: 'postalCode'
}

let CP_Customer_Type = {
  headerName: 'agGrid.agGridColumns.typeLabel',
  field: 'customerType',
  filter: "multiSelectFilter",
  cellRendererFramework: OverflowEllipsestextComponent,
  width: 120,
  minWidth: 50,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  menuTabs: ["filterMenuTab"],
  suppressMovable: true,
  colId: 'customerType'
  // suppressMenu: true //remove it later when azure done with their filter/sorting implementation
}

let unclaimedMonth = {
  headerName: 'agGrid.agGridColumns.unclaimedMonthLabel',
  field: 'unclaimedMonth',
  filter: "multiSelectFilter",
  cellRendererFramework: '',
  width: 120,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  menuTabs: ["filterMenuTab"],
  suppressMovable: true,
  colId: 'unclaimedMonth'
}

let country = {
  headerName: 'agGrid.agGridColumns.countryLabel',
  field: 'country',
  filter: "multiSelectFilter",
  cellRendererFramework: '',
  width: 120,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  menuTabs: ["filterMenuTab"],
  suppressMovable: true,
  colId: 'country_unclaimedTile'
}

//-----Added For KC-File --------//

let kcfileId = {
  headerName: 'agGrid.agGridColumns.kcFileIDLabel',
  field: 'kcFileID',
  filter: "freeTextFilter",
  width: 100,
  minWidth: 50,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  suppressMovable: true,
  colId: 'kcFileID',
  menuTabs: ["filterMenuTab"]
}

let kcfileName = {
  headerName: 'agGrid.agGridColumns.kcFileNameLabel',
  field: 'kcFileName',
  width: 100,
  minWidth: 50,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  suppressMovable: true,
  colId: 'kcFileName',
  menuTabs: ["filterMenuTab"],
  filter: "freeTextFilter",
  cellRendererFramework: KcfileDownnloadComponent
}

let kcfileDownload = {
  headerName: 'agGrid.agGridColumns.kcfile',
  field: '',
  width: 100,
  autoHeight: true,
  cellClass: "cell-wrap-text",
  suppressMovable: true,
  colId: '',
  suppressMenu: true,
  cellRendererFramework: KcfileHistoryDownloadComponent
}

let kcfileErrors = {
  headerName: 'agGrid.agGridColumns.errorsLabel',
  field: 'errors',
  width: 100,
  autoHeight: true,
  cellClass: "cell-wrap-text",
  suppressMovable: true,
  colId: 'kcfile_Errors',
  menuTabs: ["filterMenuTab"],
  cellRendererFramework: KcfileEditErrorLineComponent,
  pinned: 'left',
  suppressMenu: true
}

let kcFileErrorsContextMenuCB = {
  headerName: '',
  field: '',
  cellRendererFramework: KcFileContextmenuManageErrorComponent,
  checkboxSelection: '',
  headerCheckboxSelection: '',
  suppressSizeToFit: true,
  width: 40,
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  suppressMenu: true,
  suppressMovable: true,
  pinned: 'right'
}

let kcfileStatusCb = {
  headerName: 'agGrid.agGridColumns.statusCbLabel',
  field: 'status',
  filter: "multiSelectFilter",
  cellRendererFramework: KcfileStatusColumnComponent,
  checkboxSelection: '',
  headerCheckboxSelection: '',
  width: 120,
  minWidth: 50,
  autoHeight: true,
  cellClass: "ellipsis statusColor",
  menuTabs: ["filterMenuTab"],
  suppressMovable: true,
  colId: 'status_Kcfile'
}

let kcfilePANumber = {
  headerName: 'agGrid.agGridColumns.pANumberLabel',
  field: 'legacyPANumber',
  filter: "freeTextFilter",
  width: 100,
  autoHeight: true,
  cellClass: "cell-wrap-text",
  suppressMovable: true,
  menuTabs: ["filterMenuTab"],
  colId: 'legacyPANumber',
  cellRendererFramework: HyperlinkAndFavoriteComponent
}
let kcfilePAName = {
  headerName: 'agGrid.agGridColumns.panameLabel',
  field: 'contractName',
  filter: "freeTextFilter",
  width: 100,
  autoHeight: true,
  cellClass: "cell-wrap-text",
  suppressMovable: true,
  colId: 'contractName',
  menuTabs: ["filterMenuTab"]
}
let kcfileCustomerId = {
  headerName: 'agGrid.agGridColumns.customerIdLabel',
  field: 'customerNumber',
  filter: "freeTextFilter",
  width: 100,
  autoHeight: true,
  cellClass: "cell-wrap-text",
  suppressMovable: true,
  colId: 'customerNumber',
  menuTabs: ["filterMenuTab"]
}
let kcfileActiveLines = {
  headerName: 'agGrid.agGridColumns.activeLinesLabel',
  field: 'ActiveLines',
  filter: "freeTextFilter",
  width: 100,
  autoHeight: true,
  cellClass: "cell-wrap-text",
  suppressMovable: true,
  colId: 'ActiveLines',
  menuTabs: ["filterMenuTab"]
}

let kcfileCustomerName = {
  headerName: 'agGrid.agGridColumns.customerNameLabel',
  field: 'customerName',
  filter: "freeTextFilter",
  // cellRendererFramework: OverflowEllipsestextComponent,
  checkboxSelection: '',
  headerCheckboxSelection: '',
  autoHeight: true,
  cellClass: "cell-wrap-text ellipsis",
  menuTabs: ["filterMenuTab"],
  suppressMovable: true,
  colId: 'customerName',
  cellRenderer: function (params) {
    let fullNameList = [];
    if (params.data && params.data.customerName) {
      fullNameList.push(params.data.customerName.name1 || '');
    }
    if (fullNameList && fullNameList.length) {
      let joinText = fullNameList.join(' ');
      return "<span title='" + joinText + "'>" + joinText + "</span>"
    }
    else {
      return '';
    }
  }
}

//--------------------------------//

let searchResultCBColDefExternal = [CustomCheckbox, CouponNumber, ReferenceNumber, customerNumberCB, ClaimPeriod, SubmittedDate, IssuedDate, Requested, Errors, NetIssued, StatusCb, ClaimRequestType, RvRb, Currency];
let searchResultCBColDefInternal = [CustomCheckbox, CouponNumber, ReferenceNumber, customerNumberCB, ClaimPeriod, SubmittedDate, IssuedDate, Requested, Errors, NetIssued, StatusCb, ClaimRequestType, RvRb, Currency, ClaimNumberMultiple];
let searchResultEfileInternalColDef = [efileId, efileName, ReferenceNumberMultiple, uploadedBy, efileCustomerNumberCB, customerNameWithMultiple, uploaded, lastChanged, submitted, lines, errors, fileUploadTypeCB, efileStatusCb, ContextMenuCB];
let searchResultEfileExternalColDef = [efileId, efileName, ReferenceNumberMultiple, uploadedBy, uploaded, lastChanged, submitted, lines, errors, fileUploadTypeCB, efileStatusCb, ContextMenuCB];
let recentActivityTileGridColDef = [CustomCheckbox, CouponNumber, ReferenceNumber,  customerNumberCB, ClaimPeriod, SubmittedDate, IssuedDate, Requested, Errors, NetIssued, StatusCb, ClaimRequestType, RvRb, Currency];
let pendingTileGridColDef = [ReferenceNumber, customerNumberCB, customerName, ClaimPeriod, SubmittedDate, Requested, StatusCb, ClaimRequestType, RvRb, Currency];
let issuedTileGridColDef = [CustomCheckbox, CouponNumber, ReferenceNumber, customerNumberCB, ClaimPeriod, SubmittedDate, IssuedDate, Requested, Errors, NetIssued, StatusCb, ClaimRequestType, RvRb, Currency];
let deniedTileGridColDef = [ReferenceNumber, customerNumberCB, customerName, ClaimPeriod, SubmittedDate, Requested, StatusCb, ClaimRequestType, RvRb, Currency];
let unclaimedTileGridColDef = [customerNumberCB, unclaimedMonth, customerName, CP_Street, CP_CP_City, postalCode, country];
let couponSummaryResultColDef = [CustomCheckbox, customerEUCB, kcEUCB, kcEUDetailsCB, requestedCB, issuedCB, discrepanciesCB, linesCB];
let couponSummaryGridColDef = [CouponClaimPeriod, RequestedAmount, Difference, SummaryNetIssued];
let couponEndUserGridColDef = [LineItemNumber, LineType, PANumber, Product, ProductDescription, Quantity, UOM, InvoicePrice, PAPrice, RebateAmount, PATotalLine, Differences];

let couponEndUserGridFooterColumnDefs = [FooterId, FooterVal]

let historyLog = [updatedBy, historyLogStatus, updated, historyLoglines, historyLogErrors, efileDownload];

let errorSummary = [row, field, fieldName, error];

let manageErrorExternal = [efileErrors, efileLines, refNum, custLoc, dBranch, KCEndu, shtoNbr, shtoNme, shtoAd1, shtoAd2, shtoCty, shtoSt, shtoZip, shtoCtry, prodID, dProd, invcDte, invcNbr, cases, untMsr, itwPrc, PaPrc, extRbt, PaNbr, billToNbr, billToNme, GPOID, errorsContextMenuCB];

let manageErrorInternal = [efileErrors, efileLines, refNum, custLoc, dBranch, KCEndu, shtoNbr, shtoNme, shtoAd1, shtoAd2, shtoCty, shtoSt, shtoZip, shtoCtry, prodID, dProd, invcDte, invcNbr, cases, untMsr, itwPrc, PaPrc, extRbt, PaNbr, billToNbr, billToNme, GPOID]

let customerSearchColDef = [CustomCheckbox, CP_Customer_Number, CP_Customer_Name, CP_Street, CP_CP_City, PA_CP_Country_Name, CP_Zip_Code, CP_Customer_Type]

let customerSearchLAOColDef = [CustomCheckbox, CP_Customer_Number, CP_Customer_Name]

let custListModalDef = [CP_Customer_Number, CustomerName1];

let searchResultKcfileInternalColDef = [kcfileId, kcfileName, ReferenceNumber, ClaimPeriod, uploadedBy, efileCustomerNumberCB, customerNameWithMultiple, uploaded, lastChanged, submitted, lines, errors, kcfileStatusCb, KcFileContextMenuCB];
let searchResultKcfileExternalColDef = [kcfileId, kcfileName, ReferenceNumber, uploadedBy, ClaimPeriod, uploaded, lastChanged, submitted, lines, errors, kcfileStatusCb, KcFileContextMenuCB];
let kcfileHistoryLog = [updatedBy, historyLogStatus, updated, historyLoglines, historyLogErrors, kcfileDownload];
let kcFilePASearchColdef = [CustomCheckbox, kcfilePANumber, kcfilePAName, kcfileCustomerId, kcfileCustomerName, kcfileActiveLines]

let manageErrorExternalKcFile = [kcfileErrors, efileLines1, PaNbr, custLoc, KCEndu, shtoNbr, shtoNme, prodID, itwPrc, PaPrc, extRbt, refNum, invcDte, invcNbr, cases, clmStDte, clmEnDte, curr, kcFileErrorsContextMenuCB];
let manageErrorInternalKcFile = [kcfileErrors, efileLines1, PaNbr, custLoc, KCEndu, shtoNbr, shtoNme, prodID, itwPrc, PaPrc, extRbt, refNum, invcDte, invcNbr, cases, clmStDte, clmEnDte, curr];

let multipleRefNumberColDef = [ReferenceNumberFullName];
let multipleClaimNumberColDef = [ClaimNumberMultiple];

const EMEA_COL_DEF = {
  recentActivityTileGridColDef,
  pendingTileGridColDef,
  issuedTileGridColDef,
  deniedTileGridColDef,
  unclaimedTileGridColDef,
  searchResultCBColDefInternal,
  searchResultCBColDefExternal,
  searchResultEfileExternalColDef,
  searchResultEfileInternalColDef,
  couponSummaryResultColDef,
  couponSummaryGridColDef,
  couponEndUserGridColDef,
  couponEndUserGridFooterColumnDefs,
  historyLog,
  errorSummary,
  manageErrorExternal,
  manageErrorInternal,
  customerSearchColDef,
  customerSearchLAOColDef,
  custListModalDef,
  searchResultKcfileExternalColDef,
  searchResultKcfileInternalColDef,
  kcfileHistoryLog,
  manageErrorExternalKcFile,
  manageErrorInternalKcFile,
  kcFilePASearchColdef,
  multipleRefNumberColDef,
  multipleClaimNumberColDef
};

export { EMEA_COL_DEF };