import { Component, Input, OnDestroy, OnInit, EventEmitter, Output } from "@angular/core";
import { RegionWiseColDefination } from "@app/conf/col-def-chargeback/region-wise-col-conf";
import { CustomNoRowsOverlayComponent } from "@app/Modules/cp-grid/components/custom-no-rows-overlay/custom-no-rows-overlay.component";
import { CustomLoadingOverlayComponent } from '@app/Modules/cp-grid/components/custom-loading-overlay/custom-loading-overlay.component';
import { CpColumnHeaderComponent } from "@app/Modules/cp-grid/grid-features/cell-renderer/column-header/cp-column-header.component";
import { DateSelectorComponent } from "@app/Modules/cp-grid/grid-features/filters/date-selector/date-selector.component";
import { DaterangeSelectorComponent } from "@app/Modules/cp-grid/grid-features/filters/daterange-selector/daterange-selector.component";
import { FreetextSearchComponent } from "@app/Modules/cp-grid/grid-features/filters/freetext-search/freetext-search.component";
import { MultiSelectFilterComponent } from "@app/Modules/cp-grid/grid-features/filters/multi-select-filter/multi-select-filter.component";
import { SortonlyComponent } from '@app/Modules/cp-grid/grid-features/filters/sortonly/sortonly.component';
import { CpAgGridService } from "@app/Modules/cp-grid/services/cp-ag-grid-service";
import { AppService } from "@app/Modules/Shared/Services/app.service";
import { environment } from "@env/environment";
import { Subscription } from "rxjs";
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { HttpService } from '@app/Modules/Shared/Services/http.service';
import { GlobalAppService } from '@app/Modules/Shared/Services/global-app.service';

@AutoUnsubscribe()
@Component({
  selector: 'multiple-ref-number-list',
  templateUrl: './multiple-ref-number-list.component.html',
  styleUrls: ['./multiple-ref-number-list.component.scss'],
  providers: [CpAgGridService]
})
export class MultipleRefernceNumberListComponent implements OnInit, OnDestroy {

  columnDefs: any;
  defaultColDef: any;
  rowData: any = [];
  rowHeight;
  gridApi: any;
  gridOptions: any = {};

  public rowModelType;
  public paginationPageSize;
  public cacheOverflowSize;
  public maxConcurrentDatasourceRequests;
  public infiniteInitialRowCount;
  public maxBlocksInCache;
  public getRowNodeId;
  public noRowsOverlayComponent;

  public subscription: Subscription = new Subscription();loadingOverlayComponent: string;
;
  public recordCountSubscription: Subscription;
  public recordCount: number;
  public frameworkComponents: any;
  allFilters: any;
  tolltipHoverClass: string = "tooltip-hover";
  selectedPageSize: any;
  pageSizes: Array<any>;
  isCountAvailable: boolean;
  showRowSelectionMsg: boolean = false;

  rowSelection: string;

  showGrid: boolean = false;
  scrollVisible: string;
  gridLoadedSubscription: Subscription;
  filterKey: string = '';
  multipleRefNumberColDef: any;
  region: any;

  @Output() closedCustModal: EventEmitter<any> = new EventEmitter<any>();
  @Input() visibleCustModal: boolean = true;
  @Input() searchObj: any;

  constructor(
    private gridService: CpAgGridService,
    private appServ: AppService,
    private httpServ: HttpService,
    private globalAppService: GlobalAppService
  ) {
    this.pageSizes = this.gridService.getPageSizes();
    this.selectedPageSize = this.gridService.clientPageSize;
    this.setGridAttributes();
  }

  ngOnInit(): void {
    this.recordCount = 0;
    this.loadRegionWiseColumnDefination();
    this.setGridLoadedListener();
    this.setScreenResizeListener();
  }


  onGridReady(params) {
    this.gridService.onGridReady(params);
    this.gridApi = this.gridService.gridApi;
    this.reinstatePage(this.searchObj);
    this.setRecordCountListener();
  }

  setScreenResizeListener() {
    let screenResizeSubscription: Subscription = this.appServ.getScreenResize().subscribe(screenResized => {
      if (screenResized) {
        if (this.gridApi)
          this.gridApi.sizeColumnsToFit();
      }
    });
    this.subscription.add(screenResizeSubscription);
  }

  setRecordCountListener() {
    this.subscription.add(this.gridService
      .getRecordCount()
      .subscribe(recordCount => {
        this.recordCount = recordCount;
        if (recordCount) {
          this.paginationPageSize =
            this.recordCount > this.paginationPageSize
              ? this.gridService.clientPageSize
              : this.recordCount;
          setTimeout(() => {
            this.isCountAvailable = true;
          }, 500);
        }
      }));
  }

  setPageContext(searchRequest: any) {
    let pageContext: any = {
      url: this.httpServ.getRegionSpecificURL(environment.GetClaimRefernce),
      verb: "POST",
      pageName: "multipleRefNumber",
      request: this.searchObj
    };
    this.gridService.pageContext = pageContext;
  }

  reinstatePage(searchRequest: any, clearState: boolean = true) {
    if (clearState) {
      this.recordCount = 0;
      let isFilterApplied: boolean =
        this.allFilters && this.allFilters.length > 0;
      if (isFilterApplied) this.gridService.clearFilterState();
      this.allFilters = this.gridService.allFilters;
      if (this.gridService.isSorted) this.gridService.clearSortState();
      if (!this.gridService.isSorted && !isFilterApplied) {
        this.registerDataSource(searchRequest);
      }
    } else {
      this.registerDataSource(searchRequest);
    }
    this.gridService.sizeColumnsToFit();
  }

  registerDataSource(searchRequest: any) {
    this.setPageContext(this.searchObj);
    this.gridApi.setDatasource(this.gridService.getDataSource());
  }

  setGridAttributes() {
    this.rowHeight = 30;
    this.gridService.gridRowModelType = this.gridService.getRowModelType();
    this.rowModelType = this.gridService.getRowModelType();
    this.paginationPageSize = this.gridService.clientPageSize;
    this.cacheOverflowSize = this.gridService.getCacheOverflowSize();
    this.maxConcurrentDatasourceRequests = this.gridService.getMaxConcurrentDatasourceRequests();
    this.infiniteInitialRowCount = this.gridService.getInfiniteInitialRowCount();
    this.maxBlocksInCache = this.gridService.getMaxBlocksInCache();
    this.getRowNodeId = this.getRowNode();
    this.gridOptions.context = this;
    this.gridOptions.getContextMenuItems = function () {
      return ['copy'];
    };
    this.frameworkComponents = {
      freeTextFilter: FreetextSearchComponent,
      multiSelectFilter: MultiSelectFilterComponent,
      dateSelector: DateSelectorComponent,
      dateRangeSelector: DaterangeSelectorComponent,
      agColumnHeader: CpColumnHeaderComponent,
      customNoRowsOverlay: CustomNoRowsOverlayComponent,
      sortonly: SortonlyComponent,
      loadingOverlayComponent: CustomLoadingOverlayComponent
    };
    this.rowSelection = this.gridService.getRowSelection();
    this.noRowsOverlayComponent = "customNoRowsOverlay";
    this.loadingOverlayComponent = 'loadingOverlayComponent';
  }


  setGridLoadedListener() {
    let me = this;
  }

  async loadRegionWiseColumnDefination(){
    this.region = this.globalAppService.getDefaultRegionString();
    let colDefObj = new RegionWiseColDefination();
    let { multipleRefNumberColDef } = await colDefObj.getRegionWiseColDef(this.region);
    this.multipleRefNumberColDef = multipleRefNumberColDef;
    this.setColumnDefinitions();
  }

  setColumnDefinitions() {
    let colDefs = this.multipleRefNumberColDef;
    this.columnDefs = this.gridService.mapColDefs(colDefs);
    this.gridService.columnDefs = this.columnDefs;
    this.defaultColDef = this.gridService.getDefaultColDef();
  }

  onFilterChanged(event) {
    let filterModel: any = event.api.getFilterModel();
    this.paginationPageSize = this.gridService.clientPageSize;
    let isFilterApplied: boolean = Object.entries(filterModel).every(fm => fm[1]["value"] === null);
    this.gridService.onFilterChanged();
    this.allFilters = this.gridService.allFilters;
  }

  showTooltip(event) {
    this.tolltipHoverClass = this.gridService.getTooltipHoverClass(event);
  }

  onRemoveFilter(event, key) {
    this.gridApi.deselectAll();
    this.gridService.removeFilters(key);
  }

  onColumnResized(event) {
  }

  onPageSizeChanged(event) {
    this.paginationPageSize = this.gridService.clientPageSize;
    if (this.paginationPageSize <= this.recordCount)
      this.reinstatePage(this.gridService.oRequest, false);
    else {
      this.paginationPageSize = this.recordCount;
      this.gridApi.paginationSetPageSize(this.paginationPageSize);
    }
  }
  onRowSelected(event) {
    if (event.api.getSelectedNodes() && event.api.getSelectedNodes()[0])
      this.showRowSelectionMsg = true;
    else this.showRowSelectionMsg = false;
  }

  getRowNode() {
    return function (item) {
      return item.RowIndex ? item.RowIndex : 0;
    };
  }

  openDialog() {
    this.showGrid = true;
    this.isCountAvailable = false;
    this.recordCount = 0;
  }

  close() {
    this.closedCustModal.emit({ close: 'false' });
  }

  applySelection() {
    this.close();
  }

  ngOnDestroy() {

  }
}
