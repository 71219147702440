import * as tslib_1 from "tslib";
import { OnInit, EventEmitter, OnDestroy } from '@angular/core';
import { CpAgGridService } from '@app/Modules/cp-grid/services/cp-ag-grid-service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import { AppService } from '@app/Modules/Shared/Services/app.service';
var CpGridPaginationComponent = /** @class */ (function () {
    function CpGridPaginationComponent(gridService, appService) {
        this.gridService = gridService;
        this.appService = appService;
        this.currentPage = 1;
        this.reloadGrid = true;
        this.subscription = new Subscription();
        this.pageSizeChanged = new EventEmitter();
    }
    CpGridPaginationComponent.prototype.ngOnInit = function () {
        this.initPagination();
        this.setRecordCountListener();
    };
    CpGridPaginationComponent.prototype.initPagination = function () {
        this.pageSizes = this.gridService.getPageSizes();
        this.selectedPageSize = this.gridService.clientPageSize.toString();
        this.actualPageSize = this.gridService.clientPageSize;
    };
    CpGridPaginationComponent.prototype.setCurrentPageDetails = function (action) {
        switch (action) {
            case 'decrement':
                this.currentPageDetails = {
                    startIndex: (this.actualPageSize * (this.currentPage - 1) + 1),
                    lastIndex: (this.currentPageDetails.startIndex - 1)
                };
                break;
            case 'increment':
                this.currentPageDetails = {
                    startIndex: (this.currentPageDetails.lastIndex + 1),
                    // lastIndex: (this.actualPageSize * this.currentPage)
                    lastIndex: this.totalCount < (this.actualPageSize * this.currentPage) ? this.totalCount : (this.actualPageSize * this.currentPage)
                };
                break;
            case 'last':
                this.currentPageDetails = {
                    startIndex: this.actualPageSize * (this.currentPage - 1) + 1,
                    lastIndex: this.totalCount < (this.actualPageSize * this.currentPage) ? this.totalCount : (this.actualPageSize * this.currentPage)
                };
                break;
            case 'reset':
            default:
                this.currentPageDetails = {
                    startIndex: 1,
                    lastIndex: (this.actualPageSize * this.currentPage)
                };
                break;
        }
    };
    CpGridPaginationComponent.prototype.calculatePageNumbers = function () {
        this.lastPage = Math.ceil(this.totalCount / this.selectedPageSize);
    };
    CpGridPaginationComponent.prototype.setRecordCountListener = function () {
        var _this = this;
        var me = this;
        this.subscription.add(this.gridService.getRecordCount().subscribe(function (totalCount) {
            _this.totalCount = totalCount;
            if (totalCount) {
                if (_this.gridService.pageContext.pageName == 'ManageErrorKcFile') {
                    if (_this.totalCount <= _this.selectedPageSize) {
                        _this.resetPagination();
                    }
                }
                if (me.reloadGrid) {
                    me.resetPagination();
                    me.setReloadGridListener();
                    me.reloadGrid = false;
                }
            }
        }));
    };
    CpGridPaginationComponent.prototype.setReloadGridListener = function () {
        var me = this;
        this.subscription.add(this.gridService.getReloadGrid().subscribe(function (reloadGrid) {
            if (reloadGrid) {
                me.reloadGrid = reloadGrid;
            }
        }));
    };
    CpGridPaginationComponent.prototype.onPreviousBtnClick = function (event) {
        this.currentPage--;
        this.setCurrentPageDetails('decrement');
        this.gridService.gridApi.paginationGoToPreviousPage();
    };
    CpGridPaginationComponent.prototype.onNextBtnClick = function (event) {
        this.currentPage++;
        this.setCurrentPageDetails('increment');
        this.gridService.gridApi.paginationGoToNextPage();
    };
    CpGridPaginationComponent.prototype.onPagePerSizeChanged = function (event) {
        this.selectedPageSize = event.value;
        this.gridService.clientPageSize = parseInt(event.value, 10);
        this.resetPagination();
        this.pageSizeChanged.emit(this.gridService.clientPageSize);
    };
    CpGridPaginationComponent.prototype.onFirstBtnClick = function (event) {
        this.currentPage = 1;
        this.gridService.gridApi.paginationGoToFirstPage();
        this.setCurrentPageDetails('reset');
    };
    CpGridPaginationComponent.prototype.onLastBtnClick = function (event) {
        var _this = this;
        this.appService.setLoadingStatus(true);
        this.gridService.gridApi.refreshInfiniteCache();
        setTimeout(function () {
            _this.currentPage = _this.lastPage;
            var page = _this.lastPage;
            page = Math.ceil(_this.totalCount / _this.gridService.serverPageSize);
            if (_this.gridService.gridApi.getInfiniteRowCount() < page * _this.gridService.serverPageSize)
                _this.gridService.gridApi.setInfiniteRowCount(page * _this.gridService.serverPageSize, false);
            _this.gridService.gridApi.ensureIndexVisible(page * _this.gridService.serverPageSize - 1);
            _this.setCurrentPageDetails('last');
            _this.appService.setLoadingStatus(false);
        });
    };
    CpGridPaginationComponent.prototype.resetPagination = function () {
        this.currentPage = 1;
        this.actualPageSize = this.selectedPageSize;
        if (this.totalCount < this.actualPageSize)
            this.actualPageSize = this.totalCount;
        this.calculatePageNumbers();
        this.setCurrentPageDetails('reset');
    };
    CpGridPaginationComponent.prototype.ngOnDestroy = function () {
        //Destroying component
    };
    CpGridPaginationComponent = tslib_1.__decorate([
        AutoUnsubscribe(),
        tslib_1.__metadata("design:paramtypes", [CpAgGridService,
            AppService])
    ], CpGridPaginationComponent);
    return CpGridPaginationComponent;
}());
export { CpGridPaginationComponent };
