<div class="body-wrapper">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="content-area__white-box">
                    <div class="modal__language-selector">
                        <ul class=" unlisted inline ">
                            <li *ngFor="let l of languageListForAPACPolicy" [ngClass]="{ 'current-language' : currentSelectedLanguage ==l } " (click)="onUserLanguageClick(l) ">{{userLanguageName[l]}}</li>
                        </ul>
                    </div>
                    <div class="info-content">
                        <div class="body-wrapper">
                            <div>
                                <div>
                                    <div class="modal__shade"></div>
                                    <div class="modal__wrapper">
                                        <div class="container-fluid">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="modal__search__end-user-search content-area__white-box">
                                                        <form class="needs-validation current-language" data-language="english" novalidate="" [innerHTML]="policyContent">
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- content area end -->
</div>
<!-- body wrapper end -->