import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from "@angular/core";
import { Router } from '@angular/router';
import { HttpService } from "@app/Modules/Shared/Services/http.service";
import { LanguageSelectionService } from "@app/Modules/Shared/Services/language-selection.service";
import { MenuTogglerService } from "@app/Modules/Shared/Services/menu-toggler.service";
import { APP_LEGACY_MENU_HOST, APP_LEGACY_MENU_HOST_OLD, environment } from '@env/environment';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import { GlobalAppService } from '@app/Modules/Shared/Services/global-app.service';
import { LocationLanguageService } from '@app/Modules/Shared/Services/location-language.service';
import { kcProfessionalLink } from "@app/conf/appLink";
import { AppService } from '../../Services/app.service';
import { TranslateService } from "@ngx-translate/core";

@AutoUnsubscribe()
@Component({
  selector: "app-hamburger",
  templateUrl: "./hamburger.component.html",
  styleUrls: ["./hamburger.component.scss"]
})
export class HamburgerComponent implements OnInit {
  menuScale: number = 0;
  selectedlanguage: any;
  menu: any;
  showMenu: boolean = false;
  showTopHeader: boolean = false;

  private _selectedLanguage: string = "en";
  private _headerLanguage: string;
  private _headerCountry: string;
  @ViewChild("mainTag") mainElementRef;

  @Input() selecteCountry: string;
  @Input() selecteCountryLabel: string;
  @Input() preferredLanguageLabel: string;
  @Input() _username: string;
  @Input() _simUserId: string;


  parentNode: any;
  //public isDestroy: string = "Eng";
  @Output() clickedValue = new EventEmitter<any>();
  subscription: Subscription = new Subscription();
  kcProfessionalLink: string = kcProfessionalLink;
  region: string;
  backMessage: any;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private languageServ: LanguageSelectionService,
    private menuTogglerService: MenuTogglerService,
    private _httpServ: HttpService,
    private route: Router,
    private _globalAppService: GlobalAppService,
    private locationLanguageService: LocationLanguageService,
    private appServ: AppService,
    private translateServ: TranslateService
  ) {
    // this.translateServ.setDefaultLang('LAO/en');
  }

  ngOnInit(): void {
    this.region = this._globalAppService.getDefaultRegionString();

    this.showTopHeader = this._globalAppService.visiblePhaseIII(this.region);

    this.translateServ.get("header.backBtn").toPromise().then(response => {
      this.backMessage = response;
    })

    let defaultCountryString = this._globalAppService.getDefaultCountryString();
    this._globalAppService.getDefaultCountry().subscribe((c)=>{
      defaultCountryString = c;
      if (defaultCountryString) {
        this._headerCountry = defaultCountryString.toLowerCase();
      }
      if (this._headerCountry) {
        this.setKcProfessionalLink(this._headerCountry);
      }
    })
    

    this.subscription.add(this.menuTogglerService.menuControls$.subscribe(isVisible => {
      if (isVisible) {
        if (this._selectedLanguage) {
          let currentLocation = this._globalAppService.getSelectedCountryLang();
          this._headerLanguage = currentLocation && currentLocation.language ? currentLocation.language : this._globalAppService.getDefaultLanguageString();
          // this._headerLanguage = ;
          this.getMenu();
          

        }

      } else this.destroyMenu();

      this._globalAppService.getCountryFromMenu().subscribe(country => {
        if (country) {
          this._headerCountry = country;
          this._headerLanguage = 'en-' + this._headerCountry;
          this.setKcProfessionalLink(this._headerCountry);
          // this.getMenu();
        }
      })
    }));

    this.subscription.add(this.languageServ.getlanguage().subscribe(lang => {
      this._headerLanguage = lang;
      this.getMenu();
    }));
  }

  setKcProfessionalLink(countryCode) {
    this.kcProfessionalLink = this.appServ.setCountryURL(countryCode);
  }

  getMenu() {

    if (this.showTopHeader) {
      this.subscription.add(this._httpServ.getHamburgerMenuPhaseIII(this._headerLanguage).subscribe(menuData => {
        this.menu = menuData;
        this.createMenu();
      }));
    } else {
      this.subscription.add(this._httpServ.getHamburgerMenu(this._headerLanguage).subscribe(menuData => {
        this.menu = menuData;
        this.createMenu();
      }));
    }

  }

  createULTag(): any {
    return this.renderer.createElement("UL");
  }

  createLITag(): any {
    return this.renderer.createElement("LI");
  }

  createSpanTag(): any {
    return this.renderer.createElement("SPAN");
  }

  createDivTag(): any {
    return this.renderer.createElement("DIV");
  }

  addClass(element, cls: string) {
    this.renderer.addClass(element, cls);
  }


  setElementPropsPhaseIII(parentElement: any,
    element: any,
    value: string,
    url: string,
    menuId: string,
    isInCloud: boolean,
    isLevelOneMenu: boolean,
    applicationId: any
  ): void {



    if (applicationId === 1) {
      //legacy portal

      if (this.region == 'NA') {
        let modifiedUrl = this.appServ.appendQueryParamToLegacyUrl(url);
        url = modifiedUrl;
      }
      if ((url.indexOf('https') == -1 || url.indexOf('http') == -1)) {
        let link = this.renderer.createElement("a");
        url = APP_LEGACY_MENU_HOST_OLD + url;
        this.renderer.setAttribute(link, "href", url);

        if (value == "KCP Advantage" || menuId == "Ham_Order_Management")
          this.renderer.setAttribute(link, "target", "_blank");

        this.renderer.appendChild(link, this.renderer.createText(value));
        this.renderer.appendChild(element, link);
      }
      else if ((url.indexOf('https') > -1 || url.indexOf('http') > -1)) {
        let link = this.renderer.createElement("a");
        this.renderer.setAttribute(link, "href", url);

        if (value == "KCP Advantage" || menuId == "Ham_Order_Management")
          this.renderer.setAttribute(link, "target", "_blank");

        if ((this.showTopHeader == true) && (value === 'Site Admin' || value === 'KCP Global Website')) {
          this.renderer.setAttribute(link, "target", "_blank");
        }

        this.renderer.appendChild(link, this.renderer.createText(value));
        this.renderer.appendChild(element, link);
      }


    }
    else if (applicationId === 2) {
      //this Angular portal

      this.renderer.appendChild(element, this.renderer.createText(value));

      if (this.showTopHeader && !isLevelOneMenu) {
        this.addClass(element, 'linkpointer-top');
      }
      else {
        this.addClass(element, 'linkpointer');
      }

      // if (menuId == "Ham_Home") {
      if (isLevelOneMenu) {
        this.addClass(element, 'home-menu-link');
      }
      this.renderer.listen(element, "click", event => {
        url = '/' + url;
        // below condition to be removed one invoice NA integration is completed
        // if ((this.region == 'EMEA' || this.region == 'LAO' || this.region == 'APAC') && (url.indexOf('//invoice') > -1)) {
        //   url = '//invoice-new';
        // }
        this.route.navigate([url], { queryParamsHandling: 'preserve' });
        this.passclicked('menu')
      });


    }
    else if (applicationId === 3) {

      if (this.region == 'NA') {
        let modifiedUrl = this.appServ.appendQueryParamToLegacyUrl(url);
        url = modifiedUrl;
      }
      if ((url.indexOf('https') == -1 || url.indexOf('http') == -1)) {
        let link = this.renderer.createElement("a");

        url = (this.region == 'EMEA' ? environment.magentoEMEA.url : environment.magento.url) + url;

        this.renderer.setAttribute(link, "href", url);

        if (value == "KCP Advantage" || menuId == "Ham_Order_Management")
          this.renderer.setAttribute(link, "target", "_blank");

        this.renderer.appendChild(link, this.renderer.createText(value));
        this.renderer.appendChild(element, link);
      }
      else if ((url.indexOf('https') > -1 || url.indexOf('http') > -1)) {
        let link = this.renderer.createElement("a");
        this.renderer.setAttribute(link, "href", url);

        if (value == "KCP Advantage" || menuId == "Ham_Order_Management")
          this.renderer.setAttribute(link, "target", "_blank");

        this.renderer.appendChild(link, this.renderer.createText(value));
        this.renderer.appendChild(element, link);
      }





    }
    this.renderer.appendChild(parentElement, element);
  }


  setElementProps(
    parentElement: any,
    element: any,
    value: string,
    url: string,
    menuId: string,
    isInCloud: boolean,
    isLevelOneMenu: boolean
  ): void {
    if (isInCloud) {
      this.renderer.appendChild(element, this.renderer.createText(value));

      if (this.showTopHeader && !isLevelOneMenu) {
        this.addClass(element, 'linkpointer-top');
      }
      else {
        this.addClass(element, 'linkpointer');
      }

      // if (menuId == "Ham_Home") {
      if (isLevelOneMenu) {
        this.addClass(element, 'home-menu-link');

      }
      this.renderer.listen(element, "click", event => {
        /* ------ Block APAC Not available Module Navigation ------ */
        // if (this.region == 'APAC' && menuId != "Ham_Price_Agreement_Center" && menuId != "Ham_Internal_Price_Agreement_Browse" && menuId != "Ham_eFile" && menuId !="Ham_Chargeback_Coupon_Browse" && menuId != "Ham_Home" && menuId !="Ham_Internal_Chargeback_Coupon_Browse") {
        //   this.passclicked('menu');
        //   return;
        // }
        /*-------- /////////////////////////////////////// ------- */
        url = '/' + url;
        // below condition to be removed one invoice NA integration is completed
        // if ((this.region == 'EMEA' || this.region == 'LAO' || this.region == 'APAC') && (url.indexOf('//invoice') > -1)) {
        //   url = '//invoice-new';
        // }
        this.route.navigate([url], { queryParamsHandling: 'preserve' });
        this.passclicked('menu')
      });
    }
    else {
      if (this.region == 'NA') {
        let modifiedUrl = this.appServ.appendQueryParamToLegacyUrl(url);
        url = modifiedUrl;
      }
      if ((url.indexOf('https') == -1 || url.indexOf('http') == -1)) {
        let link = this.renderer.createElement("a");
        url = APP_LEGACY_MENU_HOST_OLD + url;
        this.renderer.setAttribute(link, "href", url);

        if (value == "KCP Advantage" || menuId == "Ham_Order_Management")
          this.renderer.setAttribute(link, "target", "_blank");

        this.renderer.appendChild(link, this.renderer.createText(value));
        this.renderer.appendChild(element, link);
      }
      else if ((url.indexOf('https') > -1 || url.indexOf('http') > -1)) {
        let link = this.renderer.createElement("a");
        this.renderer.setAttribute(link, "href", url);

        if (value == "KCP Advantage" || menuId == "Ham_Order_Management")
          this.renderer.setAttribute(link, "target", "_blank");

        this.renderer.appendChild(link, this.renderer.createText(value));
        this.renderer.appendChild(element, link);
      }
    }
    this.renderer.appendChild(parentElement, element);
  }

  setElementSpan(parentElement: any, element: any, value: string): void {
    let span = this.renderer.createElement("span");
    this.renderer.appendChild(span, this.renderer.createText(value));
    this.renderer.appendChild(element, span);
    this.renderer.appendChild(parentElement, element);
  }

  createMenu() {
    this.destroyMenu();

    this.translateServ.get("header.backBtn").toPromise().then(response => {
      this.backMessage = response;
    })

    this.setSubbarWidth();

    const UL = this.createULTag();
    this.addClass(UL, "unlisted");
    this.renderer.appendChild(this.mainElementRef.nativeElement, UL);
    this.renderer.listen(UL, "click", event => {

      let innerHTML = event.target.innerHTML;

      if (innerHTML != "Home" && innerHTML != "My Lists" && innerHTML != "My Orders" && innerHTML != "My Invoices" && innerHTML != "Site Admin") {
        this.renderer.addClass(document.body, "sub-nav-open");
      }

      this.menuScale = 1;
      this.openSubNav(event);

    });
    for (let i = 0; i < this.menu.length; i++) {
      if ((this._globalAppService.blockModules("CB") && this.menu[i].menuId == "Ham_Chargeback_Coupon_Center") || (this._globalAppService.blockModules("IN") && this.menu[i].menuId == "Ham_Invoice_Price")) {
        continue;
      }
      let LI = this.createLITag();
      if (this.menu[i].HasChild) {

        this.addClass(LI, "header__nav__has-sub-nav");
        if (i == 2) {
          // this.renderer.addClass(LI,"header__nav__has-sub-nav--open");
        }
        const SPAN = this.createSpanTag();

        this.renderer.appendChild(
          SPAN,
          this.renderer.createText(this.menu[i].label)
        );
        this.renderer.appendChild(LI, SPAN);
        const CHILDIV = this.createDivTag();

        if (this.showTopHeader) {
          this.addClass(CHILDIV, "header__nav__sub-nav-top");
        } else {
          this.addClass(CHILDIV, "header__nav__sub-nav");
        }

        if (this.showTopHeader) {
          const ARROWDIV = this.createDivTag();
          this.renderer.addClass(ARROWDIV, "backArrow");

          this.renderer.appendChild(ARROWDIV, this.renderer.createText(this.backMessage))
          this.renderer.appendChild(CHILDIV, ARROWDIV);

          this.renderer.listen(ARROWDIV, "click", event => {
            this.destroyMenu();
          });

        }

        const RESDIV = this.createDivTag();
        this.addClass(RESDIV, "header__nav__sub-nav__heading");

        if (this.showTopHeader == false) {
          this.addClass(RESDIV, "d-md-none");
        }

        this.renderer.appendChild(
          RESDIV,
          this.renderer.createText(this.menu[i].label)
        );

        this.renderer.appendChild(CHILDIV, RESDIV);

        this.renderer.appendChild(LI, CHILDIV);
        this.renderer.appendChild(UL, LI);
        this.hasSubMenu(this.menu[i], CHILDIV);
      } else {

        if (this.showTopHeader) {
          this.setElementPropsPhaseIII(UL, LI, this.menu[i].label, this.menu[i].Url, this.menu[i].menuId, this.menu[i].IsInCloud, true, this.menu[i].ApplicationId);
        }
        else {
          this.setElementProps(UL, LI, this.menu[i].label, this.menu[i].Url, this.menu[i].menuId, this.menu[i].IsInCloud, true);
        }
      }
    }
    this.parentNode = UL;
  }

  hasSubMenu(menuItem: any, parentElem: any) {
    if (menuItem.HasChild) {
      let UL = this.createULTag();
      this.addClass(UL, "unlisted");
      this.renderer.appendChild(parentElem, UL);
      this.renderer.listen(UL, "click", event => {
        this.menuScale = 2;

        this.openSubNav(event);
        event.stopPropagation();
      });
      let childMenu = menuItem.Menu;

      for (let j = 0; j < childMenu.length; j++) {
        if(childMenu[j].menuId == 'Ham_eFile' && (this.region == 'LAO' && !environment.laoEFileVisible)) {
          childMenu.splice(j, 1);
        }
      }

      for (let j = 0; j < childMenu.length; j++) {

        let LI = this.createLITag();
        if (childMenu[j].HasChild) {
          this.renderer.addClass(LI, "header__nav__sub-nav__has-accordion");


          this.setElementSpan(UL, LI, childMenu[j].label);
          this.hasSubMenu(childMenu[j], LI);
        } else {

          //check how this validation works with the Phase III
          if (!childMenu[j].isEnabled) {
            this.renderer.addClass(LI, "disabled-menu-item");
          }

          if (this.showTopHeader) {
            this.setElementPropsPhaseIII(UL, LI, childMenu[j].label, childMenu[j].Url, childMenu[j].menuId, childMenu[j].IsInCloud, false, childMenu[j].ApplicationId);
          }
          else {
            this.setElementProps(UL, LI, childMenu[j].label, childMenu[j].Url, childMenu[j].menuId, childMenu[j].IsInCloud, false);
          }

        }

        if (childMenu[j].HasChild) {
          // this.renderer.addClass(LI,"header__nav__sub-nav__has-accordion"
          // this.hasSubMenu(childMenu[j],LI);
        }
      }
    }
  }


  setSubbarWidth() {

    let maxCharacters: number = 0;

    for (let i = 0; i < this.menu.length; i++) {

      if (this.menu[i].HasChild) {

        for (let j = 0; j < this.menu[i].Menu.length; j++) {

          if (maxCharacters < this.menu[i].Menu[j].label.length) {
            maxCharacters = this.menu[i].Menu[j].label.length;
          }

        }
      }
    }

    if (maxCharacters > 30) {
      //255 is the original width set on --sidebar-width
      let totalWidth = 255 + maxCharacters;
      document.querySelector("body").style.cssText = "--sidebar-width: " + totalWidth + 'px !important';
    }
  }


  openSubNav(event: any) {

    let currentElement = event.target;
    let currentLI = this.renderer.parentNode(currentElement);
    let currentUL = this.renderer.parentNode(currentLI);
    let fisrtNode = currentUL.firstChild;
    let ctr = 0;
    var nextNode = null;
    let CssClassOnLink = "";
    if (this.menuScale === 1) {
      CssClassOnLink = "header__nav__has-sub-nav--open";
    } else {
      CssClassOnLink = "header__nav__sub-nav__has-accordion--open";
    }

    if (currentLI.classList.contains(CssClassOnLink)) {
      this.renderer.removeClass(currentLI, CssClassOnLink);
      if (this.menuScale === 1) {
        this.renderer.removeClass(document.body, "sub-nav-open");
      }
    } else {
      if (fisrtNode) {
        this.renderer.removeClass(fisrtNode, CssClassOnLink);
      }

      do {
        if (ctr === 0) {
          nextNode = this.renderer.nextSibling(fisrtNode);
        } else {
          nextNode = this.renderer.nextSibling(nextNode);
        }

        if (nextNode) {
          this.renderer.removeClass(nextNode, CssClassOnLink);
          let hasAccordianOpen = document.querySelector(
            "p-sidebar app-hamburger .unlisted .header__nav__has-sub-nav .header__nav__sub-nav__has-accordion--open"
          );
          if (hasAccordianOpen) {
            this.renderer.removeClass(
              hasAccordianOpen,
              "header__nav__sub-nav__has-accordion--open"
            );
          }
        }

        ctr++;
      } while (nextNode);
      this.renderer.addClass(currentLI, CssClassOnLink);
    }
  }

  passclicked(value) {
    this.clickedValue.emit(value);
  }

  destroyMenu() {
    if (this.parentNode) {
      this.renderer.removeClass(document.body, "sub-nav-open");
      this.renderer.removeChild(
        this.mainElementRef.nativeElement,
        this.parentNode
      );
    }
  }

  ngOnDestroy() {

  }
}
