import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { EfileKcfileAccessSearch } from '@app/Modules/Shared/DataModels/SharedDataModel';
import { siteAdminRoles } from '@app/conf/dataConfigurations';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { GlobalAppService } from '@app/Modules/Shared/Services/global-app.service';

@Injectable({
  providedIn: 'root'
})
export class EfileAccessService {

  userSearchSubject: BehaviorSubject<EfileKcfileAccessSearch> = new BehaviorSubject<EfileKcfileAccessSearch>(null);
  userSearchRequest: EfileKcfileAccessSearch;
  selectedUserSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  _parentCustomerLocation: any;
  _selectedUser: any;
  _userDetails: any;
  saveSuccessIndicator: Subject<any> = new Subject<any>();
  proceedIndicator: Subject<any> = new Subject<any>();
  refreshSections: Subject<any> = new Subject<any>();
  gridLoaded: Subject<boolean> = new Subject<boolean>();
  mySearchesNavigation: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  region: any;
  lang: any;
  adminApproles: Array<string>;
  isAdminRole: boolean = false;
  isAdminRoleReadOnly: boolean = false;
  siteAdminRoles: any = siteAdminRoles;

  constructor(private appServe: AppService, private _globalAppService: GlobalAppService) { }

  updateUserSearch(value: EfileKcfileAccessSearch) {
    this.userSearchSubject.next(value);
    this.userSearchRequest = value;
  }

  getUserSearch(): Observable<EfileKcfileAccessSearch> {
    return this.userSearchSubject.asObservable();
  }

  getSearchRequest(): EfileKcfileAccessSearch {
    return this.userSearchRequest;
  }

  updateSelectedUser(value: any) {
    this.selectedUserSubject.next(value);
  }

  getSelectedUser(): Observable<any> {
    return this.selectedUserSubject.asObservable();
  }

  get selectedUser(): any {
    return this._selectedUser;
  }

  set selectedUser(value: any) {
    this._selectedUser = value;
  }

  set parentCustomerLocation(value: any) {
    this._parentCustomerLocation = value;
  }

  get parentCustomerLocation(): any {
    return this._parentCustomerLocation;
  }

  set userDetails(value: any) {
    this._userDetails = value;
  }

  get userDetails(): any {
    return this._userDetails;
  }

  updateSaveSuccessIndicator(value: any) {
    this.saveSuccessIndicator.next(value);
  }

  getSaveSuccessIndicator(): Observable<any> {
    return this.saveSuccessIndicator.asObservable();
  }

  setProceedIndicator(value: any) {
    this.proceedIndicator.next(value);
  }

  getProceedIndicator(): Observable<any> {
    return this.proceedIndicator.asObservable();
  }

  setRefreshSections(value: any) {
    this.refreshSections.next(value);
  }

  getRefreshSections(): Observable<any> {
    return this.refreshSections.asObservable();
  }

  setGridLoaded(value) {
    return this.gridLoaded.next(value);
  }
  getGridLoaded() {
    return this.gridLoaded.asObservable();
  }
  setMySearchesNavigation(value: boolean) {
    this.mySearchesNavigation.next(value);
  }

  getMySearchesNavigation(): Observable<boolean> {
    return this.mySearchesNavigation.asObservable();
  }

  setAdminRole() {
    this._globalAppService.getDefaultRegion().subscribe(region => {
      if (region) {
        this.region = region;        
      }
    });

    let roles: Array<any> = this._globalAppService.getApprolesArray();
    if (this.region) {
      let whitelistedRoles: Array<any> = [];
      switch (this.region) {
        case 'EMEA':
          whitelistedRoles = this.siteAdminRoles['EMEA'];
          break;
        case 'NA':
          whitelistedRoles = this.siteAdminRoles['NA'];
          break;
        case 'LAO':
          whitelistedRoles = this.siteAdminRoles['LAO'];
          break;
        case 'APAC':
          whitelistedRoles = this.siteAdminRoles['APAC'];
          break;
      }
      if (this.appServe.isGlobalUser) {
        this.isAdminRole = false;
      }
      else {
        let adminRoles = [];
        this.isAdminRole = whitelistedRoles.some(wl => {
          if (roles.indexOf(wl) !== -1) {
            adminRoles.push(wl);
            return true;
          } else {
            return false;
          }
        });
        this.isAdminRoleReadOnly = adminRoles.toString().indexOf("K-C_CustomerPortal_SiteAdminReadOnly") > -1;
      }
    }
    //this.isAdminRole = true;
    return this.isAdminRole && !this.isAdminRoleReadOnly
  }

}
