import { Component, OnInit, OnDestroy } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CbService } from '@app/Modules/chargeback/Services/cb.service';
import { HttpChargeBackService } from '@app/Modules/Shared/Services/http-charge-back.service';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { GlobalAppService } from '@app/Modules/Shared/Services/global-app.service';

@AutoUnsubscribe()

@Component({
  selector: 'app-kcfile-contextmenucb',
  templateUrl: './kcfile-contextmenucb.component.html',
  styleUrls: ['./kcfile-contextmenucb.component.scss'],
  providers: [MessageService]
})

export class KcFileContextmenucbComponent implements AgRendererComponent, OnInit, OnDestroy {
  submittedContextMenu: boolean = false;
  validatedContextMenu: boolean = false;
  errorFoundTestContextMenu: boolean = false;
  errorFoundActiveContextMenu: boolean = false;
  processingTestContextMenu: boolean = false;
  processingActiveContextMenu: boolean = false;
  readyToSubmitContextMenu: boolean = false;
  deletedTestContextMenu: boolean = false;
  deletedActiveContextMenu: boolean = false;
  isInternalUser: boolean = true;
  isGlobalUser: boolean = false;
  isKcfileSubmitted: boolean = false;
  params: any;
  isKcfileDeleted: boolean = false;
  statusValue: string;
  rowIndex: number;
  subscription: Subscription = new Subscription();
  ellipseSelect: boolean = false;
  region: string;

  constructor(
    private appServ: AppService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private cbServ: CbService,
    private httpServ: HttpChargeBackService,
    private messageService: MessageService,
    private globalAppService: GlobalAppService
  ) { }

  agInit(params: any) {
    this.params = params;
    this.rowIndex = this.params.rowIndex;
    if (params.data && params.data.status) {
      switch (params.data.status) {
        case "1":
          this.errorFoundActiveContextMenu = true;
          break;
        case "2":
          this.validatedContextMenu = true;
          break;
        case "3":
          this.processingActiveContextMenu = true;
          break;
        case "4":
          this.readyToSubmitContextMenu = true;
          break;
        case "5":
          this.submittedContextMenu = true;
          break;
        case "6":
          this.deletedActiveContextMenu = true;
          break;

      }
    }

    this.isInternalUser = this.appServ.isInternalUser;
    this.isGlobalUser = this.appServ.isGlobalUser;
    this.region = this.globalAppService.getDefaultRegionString();
  }

  ngOnInit() {
    //below is to change the contextmenu after kcfile submit
    this.subscription.add(this.cbServ.getSubmitContextMenu().subscribe(data => {
      this.isKcfileSubmitted = data;
    }));
    //below is to change contextmenu after file deleted
    this.subscription.add(this.cbServ.getDeletedStatusText().subscribe(data => {
      if (this.params.rowIndex === data.rowIndex) {
        switch (data.status) {
          case '1':
            this.errorFoundActiveContextMenu = false;
            this.errorFoundTestContextMenu = false;
            this.deletedActiveContextMenu = true;
            break;
          case '2':
            this.validatedContextMenu = false;
            this.deletedActiveContextMenu = true;
            break;
          case '4':
            this.readyToSubmitContextMenu = false;
            this.deletedActiveContextMenu = true;
            break;
        }
      }

    }));
  }

  refresh(params: any): boolean {
    return true;
  }

  errorSummaryClick() {
    this.router.navigate([{ outlets: { errorSummaryDialog: ['errorSummary'] } }], { relativeTo: this.activatedRoute.parent, state: { 'kcfileName': this.params.data.kcFileName, 'claimFileId': this.params.data.kcFileID, 'customerNumber': this.params.data.customerNumber, 'salesOrgId': this.params.data.salesOrgId } });
  }

  openDeletePopup(e, element) {
    element.hide(e);
    this.router.navigate([{ outlets: { deleteConfirmationDialog: ['deleteConfirmation'] } }], { relativeTo: this.activatedRoute.parent, state: { 'kcFileName': this.params.data.kcFileName, 'kcFileID': this.params.data.kcFileID, 'salesOrgId': this.params.data.salesOrgId, 'status': this.params.data.status, 'rowIndex': this.params.rowIndex } });
  }

  downloadKcfile(e, element) {
    if(this.params.data.lines > 0){
      this.appServ.setLoadingStatus(true);
      if (this.params.data && this.params.data.kcFileID && this.params.data.salesOrgId) {
        let fileversion = (this.params.data.status && this.params.data.status == '3') ? 0 : 1;
        let request = {
          "claimFileId": this.params.data.kcFileID,
          "salesOrgId": this.params.data.salesOrgId,
          "fileversion": fileversion //passing fileversion as 1 as new file to be downloaded code is 1 and for old its 0
        }
        this.subscription.add(this.httpServ.kcfileDownload(request).subscribe((res) => {
          if (res && res.fileUrl) {
            window.open(res.fileUrl, '_blank');
          }
          else {
            this.appServ.setLoadingStatus(false);
            this.messageService.add({ key: 'kcfileDownload', detail: '0000.errorMessage', summary: 'errorLabel', severity: 'error' })
          }
        },
          (e) => {
            this.appServ.setLoadingStatus(false);
            this.messageService.add({ key: 'kcfileDownload', detail: '0000.errorMessage', summary: 'errorLabel', severity: 'error' })
          },
          () => {
            element.hide(e);
            this.appServ.setLoadingStatus(false);
          }
        ));
      }
    }
  }

  kcfileHistoryPopUp(e, element) {
    element.hide(e);
    this.cbServ.totalLinesInFile = this.params.data.lines;
    this.router.navigate([{ outlets: { kcfileHistoryDialog: ['kcfileHistory'] } }], { relativeTo: this.activatedRoute.parent, state: { 'kcFileName': this.params.data.kcFileName, 'kcFileID': this.params.data.kcFileID, 'salesOrgId': this.params.data.salesOrgId } });
  }

  manageErrorsOnly() {
    if (this.isInternalUser || this.params.data.errors <= 50) {
      this.router.navigate([{ outlets: { kcfileSearchResultDisplay: null } }], { relativeTo: this.activatedRoute.parent }).then(() => {
        this.router.navigate(['manage-errors'], {
          queryParams: { 'kcFileID': this.params.data.kcFileID, 'salesOrgId': this.params.data.salesOrgId }, relativeTo: this.activatedRoute.parent.parent.parent, state: {
            'kcfileId': this.params.data.kcFileID, 'salesOrgId': this.params.data.salesOrgId, 'kcFileName': this.params.data.kcFileName
          }
        });
      });
    }
  }


  submitKcfile(e, element) {
    element.hide(e);
    this.appServ.setLoadingStatus(true);
    let request = {
      "claimFileId": this.params.data.kcFileID,
      "salesOrgId": this.params.data.salesOrgId,
      "isDeleted": false, //for submit this should be false for delete this should be true
      "kcFileStatus": 5 //this is enum for "Submitted" status and should always be passed as 5 only, for delete this parameter can be skipped
    };
    this.subscription.add(this.httpServ.updateCBKcfileStatus(request).subscribe(data => {
      if (data) {
        this.cbServ.setSubmitStatusText(true, this.rowIndex);
        this.cbServ.initiateFileSubmit(request);
      }
      else {
        this.appServ.setLoadingStatus(false);
        this.messageService.add({ key: 'kcfileDownload', detail: '0000.errorMessage', summary: 'errorLabel', severity: 'error' })
      }
    },
      (e) => {
        this.appServ.setLoadingStatus(false);
        this.messageService.add({ key: 'kcfileDownload', detail: '0000.errorMessage', summary: 'errorLabel', severity: 'error' })
      },
      () => {
        this.appServ.setLoadingStatus(false);
      }));
  }

  contextmenuToggle(element, e) {
    element.toggle(e);
  }

  onContextMenuHide() {
    this.ellipseSelect = false;
  }

  onContextMenuShow() {
    this.ellipseSelect = true;
  }

  ngOnDestroy() {

  }

}

