import { HyperlinkAndFavoriteComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/hyperlink-and-favorite/hyperlink-and-favorite.component';
import { TooltipAndFavoriteComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/tooltip-and-favorite/tooltip-and-favorite.component';
import { TooltipComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/tooltip/tooltip.component';
import { ContextmenuComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/contextmenu/contextmenu.component';
import { HyperlinkComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/hyperlink/hyperlink.component';
import { CustomcheckboxComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/customcheckbox/customcheckbox.component';
import { ProductcodeHyperlinkComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/productcode-hyperlink/productcode-hyperlink.component';
import { PadetailsHyperLinkComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/padetails-hyper-link/padetails-hyper-link.component';
import { PriceFormatterComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/price-formatter/price-formatter.component';
import { RecentDownloadDocumentComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/recent-download-document/recent-download-document.component';
import { OverflowEllipsestextComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/overflow-ellipsestext/overflow-ellipsestext.component';
import { DigitFormatterComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/digit-formatter/digit-formatter.component';
import { CommentformatterComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/commentformatter/commentformatter.component';
import { PaIdHyperlinkOnlyComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/pa-id-hyperlink-only/pa-id-hyperlink-only.component';
import { HyperlinkAndLegendComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/hyperlink-and-legend/hyperlink-and-legend.component';
import { HyperlinkAndPopupComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/hyperlink-and-popup/hyperlink-and-popup.component';
import { MyprofileAddtoPreferenceComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/myprofile-addto-preference/myprofile-addto-preference.component';
import { MyprofilePriceagreementComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/myprofile-priceagreement/myprofile-priceagreement.component';
import { MyprofileInternalPAComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/myprofile-Internal-pa/myprofile-Internal-pa.component';
import { NumberFormatterComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/number-formatter/number-formatter.component';
import { SfdcPaDetailsComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/sfdc-pa-details/sfdc-pa-details.component';
import { EndUserNumberComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/end-user-number/end-user-number.component';
import { StatusColumnComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/status-column/status-column.component';
import { DelegatedUserHyperlinkComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/delegated-user-hyperlink/delegated-user-hyperlink.component';
import { EfileErrorColumnComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/efile-error-column/efile-error-column.component';
import { HyperlinkAndClickComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/hyperlink-and-click/hyperlink-and-click.component';
import { PaSplitDetailsComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/pa-split-details/pa-split-details.component';
import { ModuleTypeColumnComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/module-type-column/module-type-column.component';
import { EaDropdownComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/ea-dropdown/ea-dropdown.component';
import { PadetailsDecimalValuesComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/padetails-decimal-values/padetails-decimal-values.component';

let CustomCheckbox = {
    headerName: '',
    field: '_cpCheckbox',
    cellRendererFramework: CustomcheckboxComponent,
    checkboxSelection: true,
    headerCheckboxSelection: '',
    suppressSizeToFit: true,
    width: 30,
    minWidth: 30,
    autoHeight: true,
    suppressMenu: true,
    cellClass: "",
    suppressMovable: true,
    colId: '_cpCheckbox'
}

let radioButton = {
    headerName: '',
    field: '',
    cellRendererFramework: '',
    checkboxSelection: true,
    headerCheckboxSelection: '',
    suppressSizeToFit: true,
    width: 60,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMenu: true,
    suppressMovable: true,
    cellClassRules:
    {
        "disableRadio": function (params) {
            if (params && params.data)
                return params.data.statusId === 2 ? true : false
            else
                return false;
        }
    }
    // cellClassRules: {
    //   "disableRadio": "x == 'Expired'"
    // },
}

let PA_ID_Sorted = {
    headerName: 'agGrid.agGridColumns.contractIdLabel',
    field: 'contractId',
    filter: "freeTextFilter",
    cellRendererFramework: HyperlinkAndFavoriteComponent,
    width: 140,
    minWidth: 50,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: 'contractId',
    sort: 'asc',
    suppressColumnVirtualisation: true
}

let PA_ID = {
    headerName: 'agGrid.agGridColumns.contractIdLabel',
    field: 'contractId',
    filter: "freeTextFilter",
    cellRendererFramework: HyperlinkAndFavoriteComponent,
    width: 140,
    minWidth: 50,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: 'contractId',
    suppressColumnVirtualisation: true
}
let PA_ID_MyNotification = {
    headerName: 'agGrid.agGridColumns.contractIdLabel',
    field: 'contractNumber',
    filter: "freeTextFilter",
    cellRendererFramework: HyperlinkAndFavoriteComponent,
    width: 140,
    minWidth: 50,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: 'contractNumber',
    suppressColumnVirtualisation: true
}

let PA_ID_Rebate = {
    headerName: 'agGrid.agGridColumns.contractIdLabel',
    field: 'contractId',
    filter: "freeTextFilter",
    cellRendererFramework: HyperlinkAndClickComponent,
    width: 140,
    minWidth: 50,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: 'contractId',
    suppressColumnVirtualisation: true
}

let PA_ID_CVT = {
    headerName: 'agGrid.agGridColumns.contractIdLabel',
    field: 'contractId',
    filter: "freeTextFilter",
    cellRendererFramework: PaIdHyperlinkOnlyComponent,
    width: 110,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true
}

let PAName = {
    headerName: 'agGrid.agGridColumns.contractNameLabel',
    field: 'contractName',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: '',
    width: 200,
    minWidth: 50,
    headerCheckboxSelection: '',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: 'contractName',
    suppressColumnVirtualisation: true

}

let kcOwner = {
    headerName: 'agGrid.agGridColumns.ownerLabel',
    field: 'kcOwner',
    filter: "multiSelectFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 120,
    minWidth: 50,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: 'kcOwner',
}

let kcOwnerAPAC = {
    headerName: 'agGrid.agGridColumns.ownerLabel',
    field: 'paOwnerName',
    filter: "multiSelectFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 120,
    minWidth: 50,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: 'paOwnerName',
}

let Owner = {
    headerName: 'agGrid.agGridColumns.ownerLabel',
    field: 'owner',
    filter: "multiSelectFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 120,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: 'owner',
}

let EMEA_Customer_Number = {
    headerName: 'agGrid.agGridColumns.customerNumberLabel',
    field: 'customerNumber',
    filter: "freeTextFilter",
    width: 140,
    minWidth: 50,
    cellRendererFramework: OverflowEllipsestextComponent,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: "customerNumber"
}

let salesOrg = {
    headerName: 'agGrid.agGridColumns.salesOrgLabel',
    field: 'salesorgName',
    filter: "multiSelectFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 110,
    minWidth: 50,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: 'salesorgName',
}

let priceNegotiator = {
    headerName: 'agGrid.agGridColumns.priceNegotiatorNameLabel',
    field: 'ownerName',
    filter: "freeTextFilter",
    width: 225,
    minWidth: 50,
    cellRendererFramework: OverflowEllipsestextComponent,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: "ownerName"
}

let EUPAName = {
    headerName: 'agGrid.agGridColumns.contractNameLabel',
    field: 'contractName',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: '',
    width: 115,
    headerCheckboxSelection: '',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true
}

let Customer = {
    headerName: 'agGrid.agGridColumns.customerIdLabel',
    field: 'customerNumber',
    filter: "freeTextFilter",
    cellRendererFramework: TooltipAndFavoriteComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 115,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true
}

let customerSoldToSfdc = {
    headerName: 'agGrid.agGridColumns.customerIdLabel',
    field: 'customerNumber',
    filter: "freeTextFilter",
    cellRendererFramework: SfdcPaDetailsComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    autoHeight: true,
    minWidth: 50,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true
}

let CustomerSoldToId = {
    headerName: 'agGrid.agGridColumns.customerSoldToId',
    field: 'customerNumber',
    filter: "freeTextFilter",
    cellRendererFramework: PadetailsHyperLinkComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 200,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true
}

let CustomerId = {
    headerName: 'agGrid.agGridColumns.customerId',
    field: 'customerNumber',
    filter: "freeTextFilter",
    cellRendererFramework: TooltipAndFavoriteComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 130,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true
}

let recentDldCustomerId = {
    headerName: 'agGrid.agGridColumns.customerId',
    field: 'customerNumber',
    filter: "freeTextFilter",
    cellRendererFramework: TooltipAndFavoriteComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 60,
    autoHeight: true,
    cellClass: "cell-wrap-text",
    cellClassRules: {
        'cellLeftAlignment': params => {
            return params.value;
        },
    },
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId:"customerNumber"
}

let customerSoldToName = {
    headerName: 'agGrid.agGridColumns.customerSoldToName',
    field: 'customerName',
    filter: "freeTextFilter",
    cellRenderer: function (params) {
        let fullname = [];
        if (params.data && params.data.customerName) {
            fullname.push(params.data.customerName.name1 || '');
            // fullname.push(params.data.customerName.name2 || '');
            // fullname.push(params.data.customerName.name3 || '');
            // fullname.push(params.data.customerName.name4 || '');
        }
        // "cell-wrap-text ellipsis",
        if (fullname && fullname.length) {
            let joinText = fullname.join(' ');
            return "<span title='" + joinText + "'>" + joinText + "</span>"
        }
        else {
            return '';
        }
    },
    autoHeight: true,
    minWidth: 50,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    // suppressMenu: true,
    suppressMovable: true,
    sort: "asc"
}

let customerSoldToAddress = {
    headerName: 'agGrid.agGridColumns.customerSoldToAddress',
    field: 'address',
    filter: "freeTextFilter",
    cellRenderer: function (params) {
        let addressList = [];
        if (params.data && params.data.address) {
            (params.data.address.address1 && params.data.address.address1.length > 0 ? addressList.push(params.data.address.address1 + ", ") : '');
            (params.data.address.address2 && params.data.address.address2.length > 0 ? addressList.push(params.data.address.address2 + ", ") : '');
            (params.data.address.address3 && params.data.address.address3.length > 0 ? addressList.push(params.data.address.address3 + ", ") : '');
            (params.data.address.address4 && params.data.address.address4.length > 0 ? addressList.push(params.data.address.address4 + ", ") : '');
            (params.data.address.address5 && params.data.address.address5.length > 0 ? addressList.push(params.data.address.address5 + ", ") : '');
            (params.data.address.city && params.data.address.city.length > 0 ? addressList.push(params.data.address.city + ", ") : '');
            (params.data.address.state && params.data.address.state.length > 0 ? addressList.push(params.data.address.state + ", ") : '');
            (params.data.address.postalCode && params.data.address.postalCode.length > 0 ? addressList.push(params.data.address.postalCode) : '');
        }
        if (addressList && addressList.length) {
            let joinText = addressList.join('');
            return "<span title='" + joinText + "'>" + joinText + "</span>"
        }
        else {
            return '';
        }
    },
    cellRendererFramework: '',
    menuTabs: ["filterMenuTab"], //Since we are seggregating data on UI - filter is not applied
    autoHeight: true,
    minWidth: 50,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true
}

let CustomerName = {
    headerName: 'agGrid.agGridColumns.customerNameLabel',
    field: 'customerName',
    filter: "freeTextFilter",
    cellRendererFramework: TooltipComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    autoHeight: true,
    cellClass: "ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    width: 180,
    minWidth: 50,
    colId: 'customerName',
    suppressColumnVirtualisation: true
}

let CustomerName1 = {
    headerName: 'agGrid.agGridColumns.customerNameLabel',
    field: 'name1',
    filter: "freeTextFilter",
    cellRendererFramework: '',
    checkboxSelection: '',
    headerCheckboxSelection: '',
    autoHeight: true,
    cellClass: "",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    sort: 'asc'
}

let customerName_Download = {
    headerName: 'agGrid.agGridColumns.customerNameLabel',
    field: 'customerName',
    filter: "freeTextFilter",
    cellRendererFramework: '',
    checkboxSelection: '',
    headerCheckboxSelection: '',
    autoHeight: true,
    cellClass: "",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    sort: 'asc'
}

let EuCustomerName = {
    headerName: 'agGrid.agGridColumns.eucustomerNameLabel',
    field: 'customerName',
    filter: "freeTextFilter",
    cellRendererFramework: TooltipComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    width: 100
}

let EuEstimatedUnit = {
    headerName: 'agGrid.agGridColumns.euEstimatedUnitLabel',
    field: 'estimatedUnitCount',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    width: 100
}

let EuDistributor = {
    headerName: 'agGrid.agGridColumns.euDistributorLabel',
    field: 'distEuNumber',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    width: 100
}

let RecentDldCustomerName = {
    headerName: 'agGrid.agGridColumns.customerNameLabel',
    field: 'customerName',
    filter: "freeTextFilter",
    cellRendererFramework: TooltipAndFavoriteComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    width: 150,
    minWidth: 50,
    colId: "customerName"
}

let Type = {
    headerName: 'agGrid.agGridColumns.typeLabel',
    field: 'typedescription',
    filter: "multiSelectFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 80,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true
}

let TypeName = {
    headerName: 'agGrid.agGridColumns.typeLabel',
    field: 'typeName',
    filter: "multiSelectFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 100,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: 'typeName',
    suppressColumnVirtualisation: true
}



let euType = {
    headerName: 'agGrid.agGridColumns.euTypeLabel',
    field: 'typeName',
    filter: "multiSelectFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 80,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true
}

let ValidFrom = {
    headerName: 'agGrid.agGridColumns.validFromLabel',
    field: 'startDate',
    filter: "dateSelector",
    cellRendererFramework: '',
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 80,
    autoHeight: true,
    menuTabs: ["filterMenuTab"],
    cellClass: "cell-wrap-text",
    suppressMovable: true,
    colId: 'startDate'
}

let ValidFromPADetails = {
    headerName: 'agGrid.agGridColumns.validFromPADetailsLabel',
    field: 'startDate',
    filter: "dateSelector",
    cellRendererFramework: '',
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 80,
    autoHeight: true,
    menuTabs: ["filterMenuTab"],
    cellClass: "cell-wrap-text",
    suppressMovable: true
}

let PADetailsValidFrom = {
    headerName: 'agGrid.agGridColumns.validFromLabel',
    field: 'startDate',
    filter: "dateSelector",
    cellRendererFramework: '',
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 150,
    minWidth: 50,
    autoHeight: true,
    menuTabs: ["filterMenuTab"],
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true
}
let ValidSoldtoFrom = {
    headerName: 'agGrid.agGridColumns.validFromLabel',
    field: 'validFrom',
    filter: "dateSelector",
    cellRendererFramework: '',
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 110,
    minWidth: 50,
    autoHeight: true,
    menuTabs: ["filterMenuTab"],
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true
}

let ValidSoldtoToUFN = {
    headerName: 'agGrid.agGridColumns.validToLabel',
    field: 'validTo',
    filter: "dateSelectorUFN",
    cellRendererFramework: '',
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 100,
    minWidth: 50,
    autoHeight: true,
    menuTabs: ["filterMenuTab"],
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true
}

let ValidSoldtoTo = {
    headerName: 'agGrid.agGridColumns.validToLabel',
    field: 'validTo',
    filter: "dateSelector",
    cellRendererFramework: '',
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 100,
    autoHeight: true,
    menuTabs: ["filterMenuTab"],
    cellClass: "cell-wrap-text",
    suppressMovable: true
}

let ValidPAFrom = {
    headerName: 'agGrid.agGridColumns.validFromLabel',
    field: 'startDate',
    filter: "dateSelector",
    cellRendererFramework: '',
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 80,
    minWidth: 50,
    autoHeight: true,
    menuTabs: ["filterMenuTab"],
    cellClass: "cell-wrap-text ellipsis",
    colId: 'startDate',
    suppressColumnVirtualisation: true
}

let ValidRebateFrom = {
    headerName: 'agGrid.agGridColumns.validFromLabel',
    field: 'startDate',
    filter: "dateSelector",
    cellRendererFramework: '',
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 80,
    minWidth: 50,
    autoHeight: true,
    menuTabs: ["filterMenuTab"],
    cellClass: "cell-wrap-text ellipsis",
    // sort: 'desc',
    colId: 'startDate',
    suppressColumnVirtualisation: true
}

let ValidTo = {
    headerName: 'agGrid.agGridColumns.validToLabel',
    field: 'endDate',
    filter: "dateSelector",
    cellRendererFramework: '',
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 80,
    autoHeight: true,
    menuTabs: ["filterMenuTab"],
    cellClass: "cell-wrap-text",
    suppressMovable: true
}

let ValidToUFN = {
    headerName: 'agGrid.agGridColumns.validToLabel',
    field: 'endDate',
    filter: "dateSelectorUFN",
    cellRendererFramework: '',
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 80,
    autoHeight: true,
    menuTabs: ["filterMenuTab"],
    cellClass: "cell-wrap-text",
    suppressMovable: true,
    suppressColumnVirtualisation: true
}

let ValidToPADetails = {
    headerName: 'agGrid.agGridColumns.validToPADetailsLabel',
    field: 'endDate',
    filter: "dateSelector",
    cellRendererFramework: '',
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 80,
    autoHeight: true,
    menuTabs: ["filterMenuTab"],
    cellClass: "cell-wrap-text",
    suppressMovable: true,
    suppressColumnVirtualisation: true
}


let PADetailsValidTo = {
    headerName: 'agGrid.agGridColumns.validToLabel',
    field: 'endDate',
    filter: "dateSelector",
    cellRendererFramework: '',
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 150,
    minWidth: 50,
    autoHeight: true,
    menuTabs: ["filterMenuTab"],
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true
}
let ValidPATo = {
    headerName: 'agGrid.agGridColumns.validToLabel',
    field: 'endDate',
    filter: "dateSelector",
    cellRendererFramework: '',
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 180,
    minWidth: 50,
    autoHeight: true,
    menuTabs: ["filterMenuTab"],
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true,
    colId: 'endDate',
    suppressColumnVirtualisation: true
}

let LastChanged = {
    headerName: 'agGrid.agGridColumns.lastChangedLabel',
    field: 'lastChanged',
    filter: "dateRangeSelector",
    cellRendererFramework: '',
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 100,
    minWidth: 50,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    sort: 'desc'
}

let PASearchLastChangedUnsorted = {
    headerName: 'agGrid.agGridColumns.lastChangedLabel',
    field: 'lastChanged',
    filter: "dateRangeSelector",
    cellRendererFramework: '',
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 90,
    minWidth: 50,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: 'lastChanged',
    suppressColumnVirtualisation: true
}

let PASearchLastChanged = {
    headerName: 'agGrid.agGridColumns.lastChangedLabel',
    field: 'lastChanged',
    filter: "dateRangeSelector",
    cellRendererFramework: '',
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 90,
    minWidth: 50,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    sort: 'desc',
    colId: 'lastChanged',
    suppressColumnVirtualisation: true
}

let PASearchRebateLastChanged = {
    headerName: 'agGrid.agGridColumns.lastChangedLabel',
    field: 'lastChanged',
    filter: "dateRangeSelector",
    cellRendererFramework: '',
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 90,
    minWidth: 50,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: 'lastChanged',
    suppressColumnVirtualisation: true,
    sort:'desc'

}

let Status = {
    headerName: 'agGrid.agGridColumns.statusLabel',
    field: 'status',
    filter: "multiSelectFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    minWidth: 50,
    width: 120,
    autoHeight: true,
    cellClass: "cell-wrap-text statusColor",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: 'status',
    suppressColumnVirtualisation: true
}

let PADetailsStatus = {
    headerName: 'agGrid.agGridColumns.statusLabel',
    field: 'status',
    filter: "multiSelectFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    // cellClassRules: {
    //   "statusExpired": "x == 'Expireds'" || "x == 'Denied'",
    //   "statusFuture": "x == 'Futuree'",
    //   "statusComplete": "x == 'Complete'",
    //   // "statusPending": "x == 'Pending'"
    // },
    width: 100,
    autoHeight: true,
    cellClass: "cell-wrap-text statusColor",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true
}

let StatusText = {
    headerName: 'agGrid.agGridColumns.statusLabel',
    field: 'statusText',
    filter: "multiSelectFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    // cellClassRules: {
    //   "statusExpired": "x == 'Expireds'" || "x == 'Denied'",
    //   "statusFuture": "x == 'Futuree'",
    //   "statusComplete": "x == 'Complete'",
    //   // "statusPending": "x == 'Pending'"
    // },
    width: 100,
    autoHeight: true,
    cellClass: "cell-wrap-text statusColor",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true
}

let StatusDesc = {
    headerName: 'agGrid.agGridColumns.statusLabel',
    // field: 'StatusDesc',
    field: 'status',
    filter: "multiSelectFilter",
    cellRendererFramework: StatusColumnComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 100,
    autoHeight: true,
    cellClass: "cell-wrap-text statusColor",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    // cellClassRules: {
    //   "statusComplete": "x == 'Completed'",
    //   // "statusPending": "x == 'Pending'"
    // },
    colId:"status"
}

let moduleType = {
    headerName: 'agGrid.agGridColumns.moduleType',
    field: 'moduleType',
    filter: "multiSelectFilter",
    cellRendererFramework: ModuleTypeColumnComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 120,
    autoHeight: true,
    cellClass: "cell-wrap-text",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: 'moduleType',
    suppressColumnVirtualisation: true
}

let LastDownload = {
    headerName: 'agGrid.agGridColumns.lastDownloadLabel',
    field: 'lastDownload',
    filter: "dateSelector",
    cellRendererFramework: '',
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 120,
    autoHeight: true,
    menuTabs: ["filterMenuTab"],
    cellClass: "cell-wrap-text",
    suppressMovable: true,
    colId: 'lastDownload'
}

let LastDownloadPASearch = {
    headerName: 'agGrid.agGridColumns.lastDownloadPASearchLabel',
    field: 'lastDownload',
    filter: "dateSelector",
    cellRendererFramework: '',
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 140,
    minWidth: 50,
    autoHeight: true,
    menuTabs: ["filterMenuTab"],
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true,
    colId: 'lastDownload'
}

let lastDownloadedDate = {
    headerName: 'agGrid.agGridColumns.lastDownloadLabel',
    field: 'lastDownloadedDate',
    filter: "dateSelector",
    cellRendererFramework: '',
    checkboxSelection: '',
    width: 150,
    autoHeight: true,
    cellClass: "cell-wrap-text",
    headerCheckboxSelection: '',
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: "lastDownloadedDate"
}

let Change = {
    headerName: 'agGrid.agGridColumns.changeLabel',
    field: 'customerComments',
    filter: "sortonly",
    cellRendererFramework: HyperlinkAndPopupComponent,
    checkboxSelection: '',
    width: 120,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    headerCheckboxSelection: '',
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: 'customerComments'
}

let LastUpdated = {
    headerName: 'agGrid.agGridColumns.lastUpdatedPAFavouriteLabel',
    // field: 'updatedDate',
    field: 'lastChanged',
    filter: "dateRangeSelector",
    cellRendererFramework: '',
    checkboxSelection: '',
    width: 130,
    autoHeight: true,
    headerCheckboxSelection: '',
    menuTabs: ["filterMenuTab"],
    cellClass: "cell-wrap-text",
    suppressMovable: true,
    sort: 'desc'
}

let myNotificationLastChanged = {
    headerName: 'agGrid.agGridColumns.lastChangedLabel',
    field: 'lastChangedDate',
    filter: "dateSelector",
    cellRendererFramework: '',
    checkboxSelection: '',
    width: 130,
    autoHeight: true,
    headerCheckboxSelection: '',
    menuTabs: ["filterMenuTab"],
    cellClass: "cell-wrap-text",
    suppressMovable: true,
    sort: 'desc',
    colId: "lastChangedDate"
}

let lastChangedDate = {
    headerName: 'agGrid.agGridColumns.lastUpdatedLabel',
    field: 'lastChangedDate',
    filter: "dateRangeSelector",
    cellRendererFramework: '',
    checkboxSelection: '',
    width: 130,
    autoHeight: true,
    headerCheckboxSelection: '',
    menuTabs: ["filterMenuTab"],
    cellClass: "cell-wrap-text",
    suppressMovable: true,
    colId: "lastChangedDate",
    sort: 'desc'
}

let lastChangedDateUnSorted = {
    headerName: 'agGrid.agGridColumns.lastUpdatedLabel',
    field: 'lastChangedDate',
    filter: "dateRangeSelector",
    cellRendererFramework: '',
    checkboxSelection: '',
    width: 130,
    autoHeight: true,
    headerCheckboxSelection: '',
    menuTabs: ["filterMenuTab"],
    cellClass: "cell-wrap-text",
    suppressMovable: true,
    colId: "lastChangedDate",
}

let updateDate = {
    headerName: 'agGrid.agGridColumns.lastUpdatedLabel',
    field: 'lastUpdatedDate',
    filter: "dateRangeSelector",
    cellRendererFramework: '',
    checkboxSelection: '',
    width: 100,
    autoHeight: true,
    cellClass: "cell-wrap-text",
    headerCheckboxSelection: '',
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    sort: 'desc'
}

let updateDateUnsorted = {
    headerName: 'agGrid.agGridColumns.lastUpdatedLabel',
    field: 'lastUpdatedDate',
    filter: "dateRangeSelector",
    cellRendererFramework: '',
    checkboxSelection: '',
    width: 100,
    autoHeight: true,
    cellClass: "cell-wrap-text",
    headerCheckboxSelection: '',
    menuTabs: ["filterMenuTab"],
    suppressMovable: true
}

let Expiring = {
    headerName: 'agGrid.agGridColumns.expiringLabel',
    field: 'expiring',
    filter: "dateRangeSelector",
    cellRendererFramework: '',
    checkboxSelection: '',
    width: 100,
    autoHeight: true,
    cellClass: "cell-wrap-text",
    headerCheckboxSelection: '',
    menuTabs: ["filterMenuTab"],
    suppressMovable: true
}

let Territory = {
    headerName: 'agGrid.agGridColumns.territoryLabel',
    field: 'territoryNumber',
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: '',
    filter: "freeTextFilter",
    headerCheckboxSelection: '',
    autoHeight: true,
    menuTabs: ["filterMenuTab"],
    width: 120,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true
}

let TerritoryName = {
    headerName: 'agGrid.agGridColumns.TerritoryNameLabel',
    field: 'territoryName',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: '',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    headerCheckboxSelection: '',
    menuTabs: ["filterMenuTab"],
    width: 180,
    suppressMovable: true
}

let ModifyPA = {
    headerName: 'agGrid.agGridColumns.modifyPALabel',
    field: "",
    cellRendererFramework: HyperlinkComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 100,
    suppressMenu: true,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true
}

let ContextMenu = {
    headerName: '',
    field: '',
    cellRendererFramework: ContextmenuComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 50,
    minWidth: 50,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMenu: true,
    suppressMovable: true,
    suppressSizeToFit: true
}

let EUSearchContextMenu = {
    headerName: '',
    field: 'status',
    cellRendererFramework: ContextmenuComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 50,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMenu: true,
    suppressMovable: true,
    cellClassRules: {
        "disableRadio": "x == 'Expired'"
    }
}

let tradeOrgNumber = {
    headerName: 'agGrid.agGridColumns.tradeOrgNumber',
    field: 'groupNumber',
    filter: "freeTextFilter",
    width: 200,
    minWidth: 50,
    hide: true,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true
}

let tradeOrgName = {
    headerName: 'agGrid.agGridColumns.tradeOrgName',
    field: 'fullName',
    filter: "freeTextFilter",
    width: 200,
    minWidth: 50,
    menuTabs: ["filterMenuTab"],
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true,
    cellRenderer: function (params) {
        let fullNameList = [];
        if (params.data && params.data.fullName) {
            fullNameList.push(params.data.fullName.name1 || '');
            // fullNameList.push(params.data.fullName.name2 || '');
            // fullNameList.push(params.data.fullName.name3 || '');
            // fullNameList.push(params.data.fullName.name4 || '');
        }
        // return fullNameList && fullNameList.length ? fullNameList.join(' ') : '';
        if (fullNameList && fullNameList.length) {
            let joinText = fullNameList.join(' ');
            return "<span title='" + joinText + "'>" + joinText + "</span>"
        }
        else {
            return '';
        }
    }
}

let tradeOrgAddress = {
    headerName: 'agGrid.agGridColumns.tradeOrgAddress',
    field: 'address',
    filter: "freeTextFilter",
    cellRenderer: function (params) {
        let addressList = [];
        if (params.data && params.data.address) {
            (params.data.address.address1 && params.data.address.address1.length > 0 ? addressList.push(params.data.address.address1 + ", ") : '');
            (params.data.address.address2 && params.data.address.address2.length > 0 ? addressList.push(params.data.address.address2 + ", ") : '');
            (params.data.address.address3 && params.data.address.address3.length > 0 ? addressList.push(params.data.address.address3 + ", ") : '');
            (params.data.address.address4 && params.data.address.address4.length > 0 ? addressList.push(params.data.address.address4 + ", ") : '');
            (params.data.address.address5 && params.data.address.address5.length > 0 ? addressList.push(params.data.address.address5 + ", ") : '');
            (params.data.address.city && params.data.address.city.length > 0 ? addressList.push(params.data.address.city + ", ") : '');
            (params.data.address.state && params.data.address.state.length > 0 ? addressList.push(params.data.address.state + ", ") : '');
            (params.data.address.zipCode && params.data.address.zipCode.length > 0 ? addressList.push(params.data.address.zipCode + ",") : '');
            (params.data.address.postalCode && params.data.address.postalCode.length > 0 ? addressList.push(params.data.address.postalCode) : '');
        }
        if (addressList && addressList.length) {
            let joinText = addressList.join('');
            return "<span title='" + joinText + "'>" + joinText + "</span>"
        }
        else {
            return '';
        }
    },
    width: 200,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true,
    menuTabs: ["filterMenuTab"] // Since we are seggregating data on UI - filter is not applied
}

let EuRequestAddress = {
    headerName: 'agGrid.agGridColumns.addressLabel',
    field: 'addressLine1',
    filter: "freeTextFilter",
    cellRenderer: function (params) {
        let addressList = [];
        if (params.data) {
            (params.data.addressLine1 && params.data.addressLine1.length > 0 ? addressList.push(params.data.addressLine1 + ", ") : '');
            (params.data.addressLine2 && params.data.addressLine2.length > 0 ? addressList.push(params.data.addressLine2 + ", ") : '');
            (params.data.city && params.data.city.length > 0 ? addressList.push(params.data.city + ", ") : '');
            (params.data.state && params.data.state.length > 0 ? addressList.push(params.data.state + ", ") : '');
            (params.data.zipCode && params.data.zipCode.length > 0 ? addressList.push(params.data.zipCode) : '');
        }
        if (addressList && addressList.length) {
            let joinText = addressList.join('');
            //This has been assigned back to addressline1 because copy gives  param.data.addressLine1 value only not the joined Text
            params.data.addressLine1 = joinText;
            return "<span title='" + joinText + "'>" + joinText + "</span>"
        }
        else {
            return '';
        }
    },
    width: 180,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    // suppressMenu: true,
    menuTabs: ["filterMenuTab"],
    suppressMovable: true
}

let PADetailProductCode = {
    headerName: 'agGrid.agGridColumns.productcode',
    field: 'shortProductCode',
    filter: "freeTextFilter",
    menuTabs: ["filterMenuTab"],
    width: 100,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true,
    colId: "shortProductCode"
}

let PADetailProductCodeSorted = {
    headerName: 'agGrid.agGridColumns.productcode',
    field: 'shortProductCode',
    filter: "freeTextFilter",
    menuTabs: ["filterMenuTab"],
    width: 100,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true,
    colId: "shortProductCode",
    sort: 'asc'
}

let PADetailsProductCode = {
    headerName: 'agGrid.agGridColumns.productcode',
    field: 'shortProductCode',
    filter: "freeTextFilter",
    cellRendererFramework: ProductcodeHyperlinkComponent,
    menuTabs: ["filterMenuTab"],
    width: 90,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true
}

let PADetailProductCodeCVT = {
    headerName: 'agGrid.agGridColumns.productcode',
    field: 'shortProductCode',
    filter: "freeTextFilter",
    menuTabs: ["filterMenuTab"],
    width: 110,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true,
    sort: 'asc'
}

let description = {
    headerName: 'agGrid.agGridColumns.description',
    field: 'productDescription',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    menuTabs: ["filterMenuTab"],
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    width: 270,
    suppressMovable: true,
    colId: "productDescription"
}

let PADetailsdescription = {
    headerName: 'agGrid.agGridColumns.description',
    field: 'productDescription',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    menuTabs: ["filterMenuTab"],
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    width: 220,
    suppressMovable: true
}
let uom = {
    headerName: 'agGrid.agGridColumns.uom',
    field: 'uom',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    menuTabs: ["filterMenuTab"],
    width: 60,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true
}

let endUserPrice = {
    headerName: 'agGrid.agGridColumns.endUserPrice',
    field: 'euPrice',
    filter: "freeTextFilter",
    cellRendererFramework: PadetailsDecimalValuesComponent,
    menuTabs: ["filterMenuTab"],
    width: 60,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true,
    colId: 'euPrice'
}

let por = {
    headerName: 'agGrid.agGridColumns.por',
    field: 'por',
    filter: "freeTextFilter",
    cellRendererFramework: PadetailsDecimalValuesComponent,
    menuTabs: ["filterMenuTab"],
    width: 60,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true,
    colId: 'por'
}

let contractPrice = {
    headerName: 'agGrid.agGridColumns.contractPrice',
    field: 'price',
    filter: "freeTextFilter",
    cellRendererFramework: '',
    menuTabs: ["filterMenuTab"],
    width: 60,
    autoHeight: true,
    cellClass: "",
    suppressMovable: true
}

let volume = {
    headerName: 'agGrid.agGridColumns.volume',
    field: 'volume',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    menuTabs: ["filterMenuTab"],
    width: 60,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true,
    colId: 'volume'
}

let PADetailsuom = {
    headerName: 'agGrid.agGridColumns.uom',
    field: 'uom',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    menuTabs: ["filterMenuTab"],
    width: 90,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true
}
let itemsPerUom = {
    headerName: 'agGrid.agGridColumns.itemsperuom',
    field: 'itemsPerUOM',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    menuTabs: ["filterMenuTab"],
    width: 115,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true
}
let PADetailsitemsPerUom = {
    headerName: 'agGrid.agGridColumns.itemsperuom',
    field: 'itemsPerUOM',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    menuTabs: ["filterMenuTab"],
    width: 120,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true
}

let committedVolume = {
    headerName: 'agGrid.agGridColumns.committedVolume',
    field: 'committedVolume',
    filter: "freeTextFilter",
    cellRendererFramework: NumberFormatterComponent,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    type: 'numericColumn',
    width: 130
}


let price = {
    headerName: 'agGrid.agGridColumns.price',
    field: 'price',
    filter: "freeTextFilter",
    cellRendererFramework: '',
    menuTabs: ["filterMenuTab"],
    width: 100,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true
}

let PADetailsprice = {
    headerName: 'agGrid.agGridColumns.price',
    field: 'price',
    filter: "freeTextFilter",
    cellRendererFramework: PriceFormatterComponent,
    menuTabs: ["filterMenuTab"],
    width: 110,
    // autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true
}
let comments = {
    headerName: 'agGrid.agGridColumns.comments',
    field: 'comments',
    filter: "multiSelectFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    menuTabs: ["filterMenuTab"],
    width: 130,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true
}
let paDetailEuComments = {
    headerName: 'agGrid.agGridColumns.comments',
    field: 'commentId',
    filter: "multiSelectFilter",
    cellRendererFramework: CommentformatterComponent,
    menuTabs: ["filterMenuTab"],
    width: 180,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true
}

let tradeOrgComment = {
    headerName: 'agGrid.agGridColumns.paDetailEuComments',
    field: 'CommentsDescription',
    // filter: "multiSelectFilter",
    filter: "freeTextFilter",
    cellRendererFramework: CommentformatterComponent,
    menuTabs: ["filterMenuTab"],
    width: 155,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true,
    // suppressMenu: true
}

let SoldToComment = {
    headerName: 'agGrid.agGridColumns.soldToComment',
    field: 'comment',
    filter: "multiSelectFilter",
    cellRendererFramework: CommentformatterComponent,
    menuTabs: ["filterMenuTab"],
    width: 180,
    minWidth: 50,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true
}

let euComments = {
    headerName: 'agGrid.agGridColumns.euComments',
    field: 'comments',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    menuTabs: ["filterMenuTab"],
    width: 120,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true
}
let caseBreakdownUOM = {
    headerName: 'agGrid.agGridColumns.caseBreakdownUOM',
    field: 'caseBreakDownUom',
    filter: "freeTextFilter",
    cellRendererFramework: '',
    menuTabs: ["filterMenuTab"],
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true,
    // suppressMenu: true,
    width: 100,
}
let caseBreakdown = {
    headerName: 'agGrid.agGridColumns.caseBreakdown',
    field: 'caseBreakDown',
    filter: "freeTextFilter",
    cellRendererFramework: '',
    menuTabs: ["filterMenuTab"],
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true,
    width: 100
}
let rollingSalies = {
    headerName: 'agGrid.agGridColumns.rollingSalies',
    field: 'twelveMonthSales',
    filter: "freeTextFilter",
    cellRendererFramework: PriceFormatterComponent,
    menuTabs: ["filterMenuTab"],
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis textRight",
    width: 105,
    suppressMovable: true
}

let rollingVolume = {
    headerName: 'agGrid.agGridColumns.rollingVolume',
    field: 'twelveMonthVolume',
    filter: "freeTextFilter",
    cellRendererFramework: DigitFormatterComponent,
    menuTabs: ["filterMenuTab"],
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis textRight",
    suppressMovable: true,
    width: 105
}
let pricePerCase = {
    headerName: 'agGrid.agGridColumns.pricePerCase',
    field: 'casePrice',
    filter: "freeTextFilter",
    cellRendererFramework: PriceFormatterComponent,
    menuTabs: ["filterMenuTab"],
    width: 80,
    autoHeight: true,
    cellClass: "cell-wrap-text textRight",
    suppressMovable: true
}
let pricePerCaseForREandFD = {
    headerName: 'agGrid.agGridColumns.pricePerCase',
    field: 'casePrice',//this is hardcoded
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    width: 70,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true,
    suppressMenu: true,
}
let priceChargeback = {
    headerName: 'agGrid.agGridColumns.priceChargeback',
    field: '',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    menuTabs: ["filterMenuTab"],
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true,
    width: 130,
}
let FDRate = {
    headerName: 'agGrid.agGridColumns.FDRate',
    field: 'price',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    menuTabs: ["filterMenuTab"],
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true,
    width: 90,
}
let FDstartDate = {
    headerName: 'agGrid.agGridColumns.FDstartDate',
    field: 'startDate',
    filter: "freeTextFilter",
    cellRendererFramework: '',
    menuTabs: ["filterMenuTab"],
    autoHeight: true,
    cellClass: "cell-wrap-text",
    suppressMovable: true,
    width: 120,
}
let FDEndDate = {
    headerName: 'agGrid.agGridColumns.FDEndDate',
    field: 'endDate',
    filter: "freeTextFilter",
    cellRendererFramework: '',
    menuTabs: ["filterMenuTab"],
    autoHeight: true,
    cellClass: "cell-wrap-text",
    suppressMovable: true,
    width: 100,
}
let EU_ID = {
    headerName: 'agGrid.agGridColumns.EU_ID',
    field: 'endUserNumber',
    filter: "freeTextFilter",
    cellRendererFramework: '',
    menuTabs: ["filterMenuTab"],
    autoHeight: true,
    width: 120,
    cellClass: "cell-wrap-text",
    suppressMovable: true
}

let EU_ID_Sorted = {
    headerName: 'agGrid.agGridColumns.EU_ID',
    field: 'endUserNumber',
    filter: "freeTextFilter",
    cellRendererFramework: '',
    menuTabs: ["filterMenuTab"],
    autoHeight: true,
    width: 120,
    cellClass: "cell-wrap-text",
    suppressMovable: true,
    sort: 'asc'
}

let EuRequestsNumber = {
    headerName: 'agGrid.agGridColumns.EuRequestsNumber',
    field: 'euNumber',
    filter: "freeTextFilter",
    cellRendererFramework: EndUserNumberComponent,
    menuTabs: ["filterMenuTab"],
    autoHeight: true,
    width: 120,
    cellClass: "cell-wrap-text",
    suppressMovable: true
}


let EUName = {
    headerName: 'agGrid.agGridColumns.EUModalName',
    field: 'fullName',
    filter: "freeTextFilter",
    cellRendererFramework: '',
    menuTabs: ["filterMenuTab"],
    width: 120,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true,
    cellRenderer: function (params) {
        let fullNameList = [];
        if (params.data && params.data.fullName) {
            fullNameList.push(params.data.fullName.name1 || '');
            // fullNameList.push(params.data.fullName.name2 || '');
            // fullNameList.push(params.data.fullName.name3 || '');
            // fullNameList.push(params.data.fullName.name4 || '');
        }
        // return fullNameList && fullNameList.length ? fullNameList.join(' ') : '';
        if (fullNameList && fullNameList.length) {
            let joinText = fullNameList.join(' ');
            return "<span title='" + joinText + "'>" + joinText + "</span>"
        }
        else {
            return '';
        }
    }
}


let EUNamePADetails = {
    headerName: 'agGrid.agGridColumns.EUModalName',
    field: 'fullName',
    filter: "freeTextFilter",
    cellRendererFramework: '',
    menuTabs: ["filterMenuTab"],
    width: 120,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true,
    cellRenderer: function (params) {
        let fullNameList = [];
        if (params.data && params.data.fullName) {
            fullNameList.push(params.data.fullName.name1 || '');
            fullNameList.push(params.data.fullName.name2 || '');
            fullNameList.push(params.data.fullName.name3 || '');
            fullNameList.push(params.data.fullName.name4 || '');
        }
        if (fullNameList && fullNameList.length) {
            let joinText = fullNameList.join(' ');
            return "<span title='" + joinText + "'>" + joinText + "</span>"
        }
        else {
            return '';
        }
    }
}


let EUAddress = {
    headerName: 'agGrid.agGridColumns.EUModalAddress',
    field: 'address',
    width: 200,
    cellRenderer: function (params) {
        let addressList = [];
        if (params.data && params.data.address) {
            (params.data.address.address1 && params.data.address.address1.length > 0 ? addressList.push(params.data.address.address1 + ", ") : '');
            (params.data.address.address2 && params.data.address.address2.length > 0 ? addressList.push(params.data.address.address2 + ", ") : '');
            (params.data.address.address3 && params.data.address.address3.length > 0 ? addressList.push(params.data.address.address3 + ", ") : '');
            (params.data.address.address4 && params.data.address.address4.length > 0 ? addressList.push(params.data.address.address4 + ", ") : '');
            (params.data.address.address5 && params.data.address.address5.length > 0 ? addressList.push(params.data.address.address5 + ", ") : '');
            (params.data.address.city && params.data.address.city.length > 0 ? addressList.push(params.data.address.city + ", ") : '');
            (params.data.address.state && params.data.address.state.length > 0 ? addressList.push(params.data.address.state + ", ") : '');
            (params.data.address.zipCode && params.data.address.zipCode.length > 0 ? addressList.push(params.data.address.zipCode) : '');
            (params.data.address.postalCode && params.data.address.postalCode.length > 0 ? addressList.push(params.data.address.postalCode) : '');
        }
        if (addressList && addressList.length) {
            let joinText = addressList.join('');
            return "<span title='" + joinText + "'>" + joinText + "</span>"
        }
        else {
            return '';
        }
    },
    filter: "freeTextFilter",
    cellRendererFramework: '',
    menuTabs: ["filterMenuTab"], //Since we are seggregating data on UI - filter is not applied
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    // suppressMenu: true,
    suppressMovable: true
}

let modalProductCode = {
    headerName: 'agGrid.agGridColumns.ColProductCode',
    field: 'shortProductCode',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: true,
    headerCheckboxSelection: '',
    menuTabs: ["filterMenuTab"],
    autoHeight: true,
    width: 100,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true,
    sort: 'asc',
    region: ['NA', 'LAO', 'EMEA', 'APAC'],
    colId: "shortProductCode"
}

let ColAltProduct = {
    headerName: 'agGrid.agGridColumns.ColAltProduct',
    field: 'altProductCode',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    menuTabs: ["filterMenuTab"],
    autoHeight: true,
    width: 80,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true
}
let PADetailsColAltProduct = {
    headerName: 'agGrid.agGridColumns.ColAltProduct',
    field: 'altProductCode',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    menuTabs: ["filterMenuTab"],
    autoHeight: true,
    width: 80,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true,
    colId: 'altProductCode'
}
let altProduct = {
    headerName: 'agGrid.agGridColumns.ColAltProduct',
    field: 'altProductCode',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    menuTabs: ["filterMenuTab"],
    autoHeight: true,
    width: 80,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true,
    region: ['NA'],
    colId: 'altProductCode'
}
let ColProductCat = {
    headerName: 'agGrid.agGridColumns.ColProductCat',
    field: 'categoryDescription',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    autoHeight: true,
    width: 150,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    region: ['NA']
}

let ColProductDesc = {
    headerName: 'agGrid.agGridColumns.ColProductDesc',
    field: 'productDescription',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    region: ['NA', 'LAO', 'EMEA', 'APAC'],
    colId: "productDescription"
}

let PANumber = {
    headerName: 'agGrid.agGridColumns.contractIdLabel',
    field: 'contractId',
    filter: "freeTextFilter",
    width: 120,
    menuTabs: ["filterMenuTab"],
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true
}

let ContractNumber = {
    headerName: 'agGrid.agGridColumns.selectedPA',
    field: 'contractNumber',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    width: 120,
    menuTabs: ["filterMenuTab"],
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true
}

let EURequestedDate = {
    headerName: 'agGrid.agGridColumns.requestedLabel',
    field: 'requestedDate',
    filter: "dateSelector",
    cellRendererFramework: '',
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 120,
    menuTabs: ["filterMenuTab"],
    sort: 'desc',
    autoHeight: true,
    cellClass: "cell-wrap-text",
    suppressMovable: true
}

let companyName = {
    headerName: 'agGrid.agGridColumns.EUName',
    field: 'companyName',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    menuTabs: ["filterMenuTab"],
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true,
    width: 150
}

let EUcompanyName = {
    headerName: 'agGrid.agGridColumns.EUName',
    field: 'companyName',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    menuTabs: ["filterMenuTab"],
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true
}

let EU_Name = {
    headerName: 'agGrid.agGridColumns.EUName',
    field: 'fullName',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    menuTabs: ["filterMenuTab"],
    suppressMenu: true,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true
}

let EUFullAddress = {
    headerName: 'agGrid.agGridColumns.euFullAddress',
    field: 'address',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    menuTabs: ["filterMenuTab"],
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMenu: true,
    suppressMovable: true
}

let EUCity = {
    headerName: 'agGrid.agGridColumns.cityLabel',
    field: 'city',
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMenu: true,
    suppressMovable: true
}

let EUState = {
    headerName: 'agGrid.agGridColumns.stateLabel',
    field: 'state',
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMenu: true,
    width: 100,
    suppressMovable: true
}

let EUZipCode = {
    headerName: 'agGrid.agGridColumns.zipCodeLabel',
    field: 'zipCode',
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMenu: true,
    width: 100,
    suppressMovable: true
}

let EUPhone = {
    headerName: 'agGrid.agGridColumns.businessPhoneLabel',
    field: 'businessPhone',
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMenu: true,
    suppressMovable: true
}

let EUStatus = {
    headerName: 'agGrid.agGridColumns.euStatusLabel',
    field: 'status',
    filter: "multiSelectFilter",
    // cellRendererFramework: '',
    cellRenderer: function (params) {
        if (params && params.data && params.data.status) {
            if (params.data.status === 'Completed' || params.data.status === 'Complete' || params.data.status === 'COM')
                return "<span class='statusComplete'>" + params.data.status + "</span>"
            // else if (params.data.status === 'Future' || params.data.status === 'FUT')
            //   return "<span class='statusFuture'>" + params.data.status + "</span>"
            else if (params.data.status === 'Denied' || params.data.status === 'Expired' || params.data.status === 'DEN')
                return "<span class='statusDeclined'>" + params.data.status + "</span>"
            else
                return params.data.status
        }
        else
            return '';
    },
    checkboxSelection: '',
    headerCheckboxSelection: '',
    cellClassRules: {
        "statusDeclined": "x == 'Declined'",
        "statusApproved": "x == 'Approved'",
        "statusComplete": "x == 'Complete'"
        // "statusPending": "x == 'Pending'"
    },
    width: 100,
    autoHeight: true,
    cellClass: "cell-wrap-text",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true
}

let EUModalNum = {
    headerName: 'agGrid.agGridColumns.EUModalID',
    field: 'endUserNumber',
    // filter: 'sortonly',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: true,
    headerCheckboxSelection: '',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    sort: 'desc',
    colId: "endUserNumber",
    minWidth: 50
}
let EUModalName = {
    headerName: 'agGrid.agGridColumns.EUModalName',
    field: 'endUserName',
    // filter: "sortonly",
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    minWidth: 50,
    width: 200,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: 'endUserName',
}

let EUModalTax = {
    headerName: 'agGrid.agGridColumns.EUModalTax',
    field: 'taxNumber',
    // filter: "sortonly",
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 200,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true
}
let EUModalAddress = {
    headerName: 'agGrid.agGridColumns.EUModalAddress',
    field: 'address',
    // filter: "sortonly",
    filter: "freeTextFilter",
    checkboxSelection: '',
    headerCheckboxSelection: '',
    cellRendererFramework: OverflowEllipsestextComponent,
    width: 250,
    minWidth: 50,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: 'address',
}
let EUModalCity = {
    headerName: 'agGrid.agGridColumns.cityLabel',
    field: 'city',
    // filter: "sortonly",
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    width: 130,
    minWidth: 50,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: 'city',
}
let EUModalState = {
    headerName: 'agGrid.agGridColumns.stateLabel',
    field: 'state',
    // filter: "sortonly",
    filter: "freeTextFilter",
    checkboxSelection: '',
    headerCheckboxSelection: '',
    cellRendererFramework: OverflowEllipsestextComponent,
    width: 120,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true
}
let EUModalZipcode = {
    headerName: 'agGrid.agGridColumns.postalCodeLabel',
    field: 'postalCode',
    // filter: "sortonly",
    filter: "freeTextFilter",
    checkboxSelection: '',
    headerCheckboxSelection: '',
    cellRendererFramework: OverflowEllipsestextComponent,
    width: 150,
    minWidth: 50,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: 'postalCode',
}

let EUModalCountry = {
    headerName: 'agGrid.agGridColumns.countryLabel',
    field: 'country',
    // filter: "sortonly",
    filter: "multiSelectFilter",
    checkboxSelection: '',
    headerCheckboxSelection: '',
    cellRendererFramework: OverflowEllipsestextComponent,
    width: 150,
    minWidth: 50,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: 'country',
}
let EUModalPhone = {
    headerName: 'agGrid.agGridColumns.phoneLabel',
    field: 'phone',
    // filter: "sortonly",
    filter: "freeTextFilter",
    checkboxSelection: '',
    headerCheckboxSelection: '',
    cellRendererFramework: OverflowEllipsestextComponent,
    width: 150,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true
}

let FileName = {
    headerName: 'agGrid.agGridColumns.fileNameLabel',
    // field: 'fileName',
    width: 75,
    cellRendererFramework: RecentDownloadDocumentComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    suppressMenu: true,
    colId: "FileName"
}
let FileSize = {
    headerName: 'agGrid.agGridColumns.fileSize',
    field: 'size',
    filter: "freeTextFilter",
    cellRendererFramework: NumberFormatterComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    autoHeight: true,
    cellClass: "cell-wrap-text",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    width: 120,
    colId: "size"
}
let AvaliableUnit = {
    headerName: 'agGrid.agGridColumns.avaliableUnit',
    field: 'availableUntil',
    filter: "dateSelector",
    cellRendererFramework: '',
    checkboxSelection: '',
    headerCheckboxSelection: '',
    autoHeight: true,
    cellClass: "cell-wrap-text",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    width: 150,
    sort: 'desc',
    colId: "availableUntil"
}

let HQModalHQID = {
    headerName: 'agGrid.agGridColumns.tradeOrgNumber',
    field: 'tradeOrgNumber',
    // filter: "sortonly",
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: true,
    headerCheckboxSelection: '',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    minWidth: 50,
    sort: 'asc'
}

let HQModalHQName = {
    headerName: 'agGrid.agGridColumns.tradeOrgName',
    field: 'tradeOrgName',
    // filter: "sortonly",
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    minWidth: 50,
    suppressMovable: true
}

let HQModalAddress = {
    headerName: 'agGrid.agGridColumns.addressLabel',
    field: 'address',
    // filter: "sortonly",
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true
}

let HQModalCity = {
    headerName: 'agGrid.agGridColumns.cityLabel',
    field: 'city',
    // filter: "sortonly",
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    width: 120,
    suppressMovable: true
}
let HQModalState = {
    headerName: 'agGrid.agGridColumns.stateLabel',
    field: 'state',
    // filter: "sortonly",
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    width: 120,
    suppressMovable: true
}

let TradeOrgCountry = {
    headerName: 'agGrid.agGridColumns.countryLabel',
    field: 'country',
    // filter: "sortonly",
    filter: "multiSelectFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    width: 120,
    suppressSizeToFit: true,
    suppressMovable: true
}
let HQModalZip = {
    headerName: 'agGrid.agGridColumns.zipCodeLabel',
    field: 'postalCode',
    // filter: "sortonly",
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    width: 120,
    suppressMovable: true
}


let CP_Customer_Number = {
    headerName: 'agGrid.agGridColumns.customerIdLabel',
    field: 'customerNumber',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    width: 120,
    minWidth: 50,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: 'customerNumber'
    // suppressMenu: true //remove it later when azure done with their filter/sorting implementation
}
let CP_Customer_Name = {
    headerName: 'agGrid.agGridColumns.customerNameLabel',
    field: 'customerName',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    width: 250,
    minWidth: 50,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: 'customerName'
    // suppressMenu: true //remove it later when azure done with their filter/sorting implementation
}
let CP_Street = {
    headerName: 'agGrid.agGridColumns.addressLabel',
    field: 'address',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    width: 200,
    minWidth: 50,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: 'address'
    // suppressMenu: true //remove it later when azure done with their filter/sorting implementation
}
let CP_CP_City = {
    headerName: 'agGrid.agGridColumns.cityLabel',
    field: 'city',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    width: 130,
    minWidth: 50,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: 'city'
    // suppressMenu: true //remove it later when azure done with their filter/sorting implementation
}
let CP_State_Name = {
    headerName: 'myProfile.MyLocation.addLocationpopUp.stateLabel',
    field: 'state',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    width: 100,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    // suppressMenu: true //remove it later when azure done with their filter/sorting implementation
}
let PA_CP_State_Name = {
    headerName: 'agGrid.agGridColumns.stateORprovianceLabel',
    field: 'state',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    width: 150,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    // suppressMenu: true //remove it later when azure done with their filter/sorting implementation
}
let PA_CP_country_Name = {
    headerName: 'agGrid.agGridColumns.countryLabel',
    field: 'country',
    filter: "multiSelectFilter",
    autoHeight: true,
    width: 100,
    minWidth: 50,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: "country"
}
let CP_Zip_Code = {
    headerName: 'agGrid.agGridColumns.zipCodeLabel',
    field: 'postalCode',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    width: 120,
    minWidth: 50,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: 'postalCode'
    // suppressMenu: true //remove it later when azure done with their filter/sorting implementation
}
let CP_Customer_Type = {
    headerName: 'agGrid.agGridColumns.typeLabel',
    field: 'customerType',
    filter: "multiSelectFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    width: 120,
    minWidth: 50,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    // suppressMenu: true //remove it later when azure done with their filter/sorting implementation
}
let Notification = {
    headerName: 'agGrid.agGridColumns.Notification',
    field: 'changeReason',
    filter: 'freeTextFilter',
    cellRendererFramework: '',
    cellClass: "cell-wrap-text",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: "changeReason",
}

let Sent = {
    headerName: 'agGrid.agGridColumns.Sent',
    field: "updatedDate",
    filter: 'dateSelector',
    cellRendererFramework: '',
    cellClass: "cell-wrap-text",
    autoHeight: true,
    width: 70,
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    sort: 'desc'
}

let PA_Notification = {
    headerName: 'agGrid.agGridColumns.contractIdLabel',
    field: 'contractId',
    filter: "freeTextFilter",
    // cellRendererFramework: OverflowEllipsestextComponent,
    cellRendererFramework: HyperlinkAndFavoriteComponent,
    cellClass: "cell-wrap-text ellipsis",
    autoHeight: true,
    width: 80,
    menuTabs: ["filterMenuTab"],
    suppressMovable: true
}


let PA_Name = {
    headerName: 'agGrid.agGridColumns.contractNameLabel',
    field: 'contractName',
    filter: "freeTextFilter",
    cellRendererFramework: OverflowEllipsestextComponent,
    width: 120,
    cellClass: "cell-wrap-text ellipsis",
    autoHeight: true,
    menuTabs: ["filterMenuTab"],
    suppressMovable: true
}

let Customer_Notification = {
    headerName: 'agGrid.agGridColumns.customerIdLabel',
    field: 'customerNumber',
    filter: "freeTextFilter",
    cellRendererFramework: TooltipAndFavoriteComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    cellClass: "cell-wrap-text ellipsis",
    autoHeight: true,
    width: 100,
    menuTabs: ["filterMenuTab"],
    suppressMovable: true
}

let Customer_Name = {
    headerName: 'agGrid.agGridColumns.customerNameLabel',
    field: 'customerName',
    filter: "freeTextFilter",
    cellRendererFramework: TooltipAndFavoriteComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    cellClass: "cell-wrap-text ellipsis",
    width: 120,
    autoHeight: true,
    menuTabs: ["filterMenuTab"],
    suppressMovable: true
}

let userId = {
    headerName: 'agGrid.agGridColumns.userIdLabel', //User ID
    field: 'userId',
    filter: "freeTextFilter",
    cellRendererFramework: HyperlinkAndLegendComponent,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    width: 120
}

let pu_userId = {
    headerName: 'agGrid.agGridColumns.userIdLabel', //User ID
    field: 'userId',
    filter: "freeTextFilter",
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: 'userId'
}

let delegatedUserId = {
    headerName: 'agGrid.agGridColumns.userIdLabel',
    field: 'userId',
    filter: "freeTextFilter",
    cellRendererFramework: DelegatedUserHyperlinkComponent,
    checkboxSelection: true,
    headerCheckboxSelection: '',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    width: 160,
}

let lastLoginFrom = {
    headerName: 'agGrid.agGridColumns.loginFromLabel',
    field: 'lastLoginDate',
    // filter: "freeTextFilter",
    filter: 'dateSelector',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true
}

let passwordExpiration = {
    headerName: 'agGrid.agGridColumns.passwordExpirationLabel',
    field: 'passwordExpiryDate',
    filter: 'dateSelector',
    // filter: "freeTextFilter",
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true
}

let pu_passwordExpiration = {
    headerName: 'agGrid.agGridColumns.passwordExpirationLabel',
    field: 'passwordExpiryDate',
    filter: 'dateSelector',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: 'passwordExpiryDate'
}

let totalVisits = {
    headerName: 'agGrid.agGridColumns.totalVisitslabel',
    field: 'loginCount',
    filter: "freeTextFilter",
    cellRendererFramework: NumberFormatterComponent,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    type: 'numericColumn',
    colId: 'loginCount'
}

let lastLogin = {
    headerName: 'agGrid.agGridColumns.lastLoginLabel',
    field: 'lastLoginDate',
    filter: "dateSelector",
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    sort: 'desc',
    colId: 'lastLoginDate'
}

let lastLoginTime = {
    headerName: 'agGrid.agGridColumns.lastLoginTimeLabel',
    field: 'lastLoginDate__1',
    filter: "sortonly",
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    sort: 'desc',
    colId: 'lastLoginDate__1'
}

let firstName = {
    headerName: 'agGrid.agGridColumns.firstNameLabel', //First Name
    field: 'firstName',
    filter: "freeTextFilter",
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: 'firstName'
}

let lastName = {
    headerName: 'agGrid.agGridColumns.lastNameLabel',//Last Name 
    field: 'lastName',
    filter: "freeTextFilter",
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: 'lastName'
}

let email = {
    headerName: 'agGrid.agGridColumns.emailLabel', //Email
    field: 'email',
    filter: "freeTextFilter",
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: 'email',
    minWidth: 50,
    cellRendererFramework: OverflowEllipsestextComponent
}

let telephone = {
    headerName: 'agGrid.agGridColumns.telephoneLabel', //Email
    field: 'phone',
    filter: "freeTextFilter",
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    width: 120
}

let customerNumber = {
    headerName: 'agGrid.agGridColumns.customerNumber',
    field: 'customerNumber',
    filter: "freeTextFilter",
    width: 125,
    cellRendererFramework: HyperlinkAndLegendComponent,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: "customerNumber"
}

let customerName = {
    headerName: 'agGrid.agGridColumns.customerName',
    field: 'customerName',
    filter: "freeTextFilter",
    width: 125,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: "customerName"
}

let customerAddress = {
    headerName: 'agGrid.agGridColumns.customerAddress',
    field: 'address',
    filter: "freeTextFilter",
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true
}

let city = {
    headerName: 'agGrid.agGridColumns.cityLabel',
    field: 'city',
    filter: "freeTextFilter",
    width: 80,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: "city"
}

let state = {
    headerName: 'agGrid.agGridColumns.stateLabel',
    field: 'state',
    filter: "freeTextFilter",
    width: 100,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true
}

let zipCode = {
    headerName: 'agGrid.agGridColumns.zipCodeLabel',
    field: 'postalCode',
    filter: "freeTextFilter",
    width: 130,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: "postalCode"
}

let country = {
    headerName: 'agGrid.agGridColumns.countryLabel',
    field: 'country',
    filter: "multiSelectFilter",
    autoHeight: true,
    width: 100,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: "country",
    minWidth: 50,
}

let taxNumber = {
    headerName: 'agGrid.agGridColumns.taxLabel',
    field: 'tax',
    filter: "freeTextFilter",
    autoHeight: true,
    width: 125,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true
}

let customerType = {
    headerName: 'agGrid.agGridColumns.customerTypeLabel',
    field: 'customerType',
    width: 125,
    filter: "multiSelectFilter",
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: "customerType",
    minWidth: 50,
}

let customerLocation = {
    headerName: 'siteAdmin.agGridColumns.customerLocation',
    field: 'customerNumber',
    suppressMenu: true,
    autoHeight: true,
    suppressSizeToFit: true,
    cellClass: "cell-wrap-text ellipsis",
    suppressMovable: true,
    hide: true
}

let addToPreference = {
    pinned: 'left',
    headerName: "agGrid.agGridColumns.addToPreferenceLabel",
    field: '_addtopref',
    filter: "",
    cellRendererFramework: MyprofileAddtoPreferenceComponent,
    width: 210,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis mylocationexternal",
    menuTabs: [],
    suppressMenu: true,
    suppressMovable: true,
    suppressSizeToFit: true,
}

let priceAgreementExternalLAO = {
    headerName: "agGrid.agGridColumns.priceAgreementLabel",
    field: '_myprofilePA',
    filter: "",
    cellRendererFramework: MyprofilePriceagreementComponent,
    width: 200,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis mylocationexternal",
    menuTabs: [],
    suppressMenu: true,
    suppressMovable: true,
    appFeatureId: 1
}

let priceAgreementExternalNA = {
    headerName: "agGrid.agGridColumns.priceAgreementLabel",
    field: '_myprofilePA',
    filter: "",
    cellRendererFramework: MyprofilePriceagreementComponent,
    width: 200,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis mylocationexternal",
    menuTabs: [],
    suppressMenu: true,
    suppressMovable: true,
    appFeatureId: 1,
    suppressSizeToFit: true,
}

let meetCompExternal = {
    headerName: "agGrid.agGridColumns.meetCompLabel",
    field: '_myprofileMC',
    filter: "",
    cellRendererFramework: MyprofilePriceagreementComponent,
    width: 120,
    pinned: 'right',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: [],
    suppressMenu: true,
    suppressMovable: true,
    appFeatureId: 2
}

let chargeBackExternal = {
    headerName: "agGrid.agGridColumns.chargeBackManagement",
    field: '_myprofileCB',
    filter: "",
    cellRendererFramework: MyprofilePriceagreementComponent,
    width: 200,
    autoHeight: true,
    suppressSizeToFit: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: [],
    suppressMenu: true,
    suppressMovable: true,
    appFeatureId: 3
}

let invoicesExternal = {
    headerName: "agGrid.agGridColumns.invoicesLabel",
    field: '_myprofileIN',
    filter: "",
    cellRendererFramework: MyprofilePriceagreementComponent,
    width: 120,
    pinned: 'right',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: [],
    suppressMenu: true,
    suppressMovable: true,
    appFeatureId: 4
}

let ordersExternal = {
    headerName: "Orders",
    field: '_myprofileOD',
    filter: "",
    cellRendererFramework: MyprofilePriceagreementComponent,
    width: 120,
    pinned: 'right',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: [],
    suppressMenu: true,
    suppressMovable: true,
    appFeatureId: 5
}

let priceAgreementInternalLAO = {
    headerName: "agGrid.agGridColumns.priceAgreementLabel",
    field: '_myprofilePA',
    filter: "",
    cellRendererFramework: MyprofileInternalPAComponent,
    width: 200,
    pinned: 'right',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: [],
    suppressMenu: true,
    suppressMovable: true,
    appFeatureId: 1
}

let priceAgreementInternalNA = {
    headerName: "agGrid.agGridColumns.priceAgreementLabel",
    field: '_myprofilePA',
    filter: "",
    cellRendererFramework: MyprofileInternalPAComponent,
    width: 200,
    suppressSizeToFit: true,
    pinned: 'right',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: [],
    suppressMenu: true,
    suppressMovable: true,
    appFeatureId: 1
}

let meetCompInternal = {
    headerName: "agGrid.agGridColumns.meetCompLabel",
    field: '_myprofileMC',
    filter: "",
    cellRendererFramework: MyprofileInternalPAComponent,
    width: 120,
    pinned: 'right',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: [],
    suppressMenu: true,
    suppressMovable: true,
    appFeatureId: 2
}

let chargeBackInternal = {
    headerName: "agGrid.agGridColumns.chargeBackManagement",
    field: '_myprofileCB',
    filter: "",
    cellRendererFramework: MyprofileInternalPAComponent,
    width: 200,
    suppressSizeToFit: true,
    pinned: 'right',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: [],
    suppressMenu: true,
    suppressMovable: true,
    appFeatureId: 3
}

let invoicesInternal = {
    headerName: "agGrid.agGridColumns.invoicesLabel",
    field: '_myprofileIN',
    filter: "",
    cellRendererFramework: MyprofileInternalPAComponent,
    width: 120,
    pinned: 'right',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: [],
    suppressMenu: true,
    suppressMovable: true,
    appFeatureId: 4
}

let ordersInternal = {
    headerName: "Orders",
    field: '_myprofileOD',
    filter: "",
    cellRendererFramework: MyprofileInternalPAComponent,
    width: 120,
    pinned: 'right',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: [],
    suppressMenu: true,
    suppressMovable: true,
    appFeatureId: 5
}

let locationName = {
    headerName: "XYZ",
    field: 'name',
    hide: true,
    suppressMovable: true,
}

let parentCust = {
    headerName: "PQR",
    field: "parentCustomer",
    rowGroup: true,
    hide: true,
    suppressMovable: true,
}

let mylocation = {
    headerName: 'agGrid.agGridColumns.myLocationLabel',
    field: "name",
    cellClass: "mylocationclass",
    suppressMenu: true,
    showRowGroup: true,
    suppressMovable: true,
    cellRenderer: 'agGroupCellRenderer',
    cellRendererParams: {
        suppressCount: true,
        checkbox: true,
        innerRenderer: 'simpleCellRenderer',
        //suppressDoubleClickExpand: true,
        //suppressEnterExpand: true,
    },
}

//Added for chargeback

let couponNumber = {
    headerName: 'agGrid.agGridColumns.couponNumberLabel',
    field: 'couponNumber',
    filter: "freeTextFilter",
    width: 120,
    cellRendererFramework: HyperlinkAndLegendComponent,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true
}

let referenceNumber = {
    headerName: 'agGrid.agGridColumns.referenceNumberLabel',
    field: 'referenceNumber',
    filter: "freeTextFilter",
    width: 140,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true
}

let ClaimPeriod = {
    headerName: 'agGrid.agGridColumns.claimPeriodLabel',
    field: 'claimStartDate',
    filter: "dateRangeSelector",
    cellRendererFramework: '',
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 220,
    autoHeight: true,
    cellClass: "cell-wrap-text",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
}


let IssuedDate = {
    headerName: 'agGrid.agGridColumns.issuedDateLabel',
    field: 'issueDate',
    filter: "dateSelector",
    cellRendererFramework: '',
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 130,
    autoHeight: true,
    menuTabs: ["filterMenuTab"],
    cellClass: "cell-wrap-text",
    suppressMovable: true
}

let Requested = {
    headerName: 'agGrid.agGridColumns.requestedLabel',
    field: 'requested',
    filter: "sortonly",
    width: 140,
    cellRendererFramework: '',
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    cellStyle: { textAlign: 'right' },
    headerClass: 'right-align-column-header'

}

let Errors = {
    headerName: 'agGrid.agGridColumns.errorsLabel',
    field: 'errors',
    filter: "sortonly",
    cellRendererFramework: HyperlinkAndLegendComponent,
    autoHeight: true,
    width: 110,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    cellStyle: { textAlign: 'right' },
    headerClass: 'right-align-column-header'
}

let NetCredits = {
    headerName: 'agGrid.agGridColumns.netCreditsLabel',
    field: 'netIssued',
    filter: "sortonly",
    cellRendererFramework: HyperlinkAndLegendComponent,
    autoHeight: true,
    width: 120,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    cellStyle: { textAlign: 'right' },
    headerClass: 'right-align-column-header'
}

let TermsRecaptured = {
    headerName: 'agGrid.agGridColumns.termsRecapturedLabel',
    field: 'termsRecaptured',
    filter: "sortonly",
    autoHeight: true,
    width: 130,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    cellStyle: { textAlign: 'right' },
    headerStyle: { textAlign: 'right' },
    headerClass: 'right-align-column-header'
}

let customerNumberCB = {
    headerName: 'agGrid.agGridColumns.customerNumberLabel',
    field: 'customerNumber',
    filter: "freeTextFilter",
    cellRendererFramework: TooltipAndFavoriteComponent,
    width: 130,
    autoHeight: true,
    cellClass: "cell-wrap-text",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true
}

let taxNumberCB = {
    headerName: 'agGrid.agGridColumns.taxNumberLabel',
    field: 'tax',
    filter: "sortonly",
    autoHeight: true,
    width: 90,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true
}
/* Status,  is already present in previous definitions. */

let ClaimRequestType = {
    headerName: 'agGrid.agGridColumns.claimRequestTypeLabel',
    field: 'claimRequestType',
    filter: "multiSelectFilter",
    cellRendererFramework: TooltipAndFavoriteComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 130,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true
}

let RvRb = {
    headerName: 'agGrid.agGridColumns.rvRbLabel',
    field: 'rvRb',
    filter: "multiSelectFilter",
    cellRendererFramework: TooltipAndFavoriteComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 100,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true
}


let Currency = {
    headerName: 'agGrid.agGridColumns.currencyLabel',
    field: 'currency',
    filter: false,
    cellRendererFramework: OverflowEllipsestextComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 120,
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true
}

let StatusCb = {
    headerName: 'agGrid.agGridColumns.statusCbLabel',
    field: 'status',
    filter: "multiSelectFilter",
    cellRendererFramework: TooltipAndFavoriteComponent,
    checkboxSelection: '',
    headerCheckboxSelection: '',
    width: 120,
    autoHeight: true,
    cellClass: "cell-wrap-text statusColor",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true
}

let multipleChangesNotes = {
    headerName: 'agGrid.agGridColumns.multipleChangesNotes',
    field: 'changeReason',
    filter: "",
    cellRendererFramework: OverflowEllipsestextComponent,
    menuTabs: [],
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    width: 300,
    suppressMovable: true,
    suppressFilterButton: true,
    feature: "emeaNotifications"
}

let multipleChangesDate = {
    headerName: 'agGrid.agGridColumns.multipleChangesDate',
    field: 'updatedDate',
    filter: "",
    cellRendererFramework: '',
    menuTabs: [],
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    width: 200,
    suppressMovable: true,
    suppressFilterButton: true
}

let manageMappingsColDef = [customerLocation];

let customerMappingsColDef = [customerLocation];

let ucMappingCustomerSearchColDef = [
    customerNumber,
    customerName,
    city,
    zipCode,
    country,
    customerType,
];

let userSearchColDef = [userId, firstName, lastName, email, telephone];

let puSearchInternalColDef = [
    pu_userId,
    firstName,
    lastName,
    email,
    pu_passwordExpiration,
    totalVisits,
    lastLogin,
    lastLoginTime,
];

let puSearchExternalColDef = [
    pu_userId,
    firstName,
    lastName,
    email,
    lastLogin,
    lastLoginTime,
];

let delegatedUserSearchColDef = [
    delegatedUserId,
    firstName,
    lastName,
    email,
    lastLoginFrom,
    passwordExpiration,
];

let ExternallocationandMailsColDef = [
    addToPreference,
    chargeBackExternal,
    priceAgreementExternalNA,
];

let InternallocationandMailsColDef = [
    mylocation,
    parentCust,
    locationName,
    chargeBackInternal,
    priceAgreementInternalNA,
];

let myProfileAddLocationColDef = [
    CustomCheckbox,
    CP_Customer_Number,
    CP_Customer_Name,
    CP_Street,
    CP_CP_City,
    CP_Zip_Code,
    country,
    customerType,
];

let customerSearchColDef = [
  CustomCheckbox,
  CP_Customer_Number,
  CP_Customer_Name,
  CP_Street,
  CP_CP_City,
  PA_CP_country_Name,
  CP_Zip_Code,
  CP_Customer_Type,
];

let HQModalDef = [
    HQModalHQID,
    HQModalHQName,
    HQModalAddress,
    HQModalCity,
    HQModalZip,
    TradeOrgCountry
];

let endUserSearchModalDef = [
    EUModalNum,
    EUModalName,
    EUModalAddress,
    EUModalCity,
    EUModalZipcode,
    EUModalCountry
];

let productSearchModalDef = [
    modalProductCode,
    altProduct,
    ColProductDesc,
    ColProductCat,
];

let custListModalDef = [CP_Customer_Number, CustomerName1];

let activeFutureColDef = [
    CustomCheckbox,
    PA_ID,
    PAName,
    //kcOwner,
    EMEA_Customer_Number,
    CustomerName,
    //priceNegotiator,
    ValidPAFrom,
    ValidPATo,
    LastChanged,
    Status,
    LastDownloadPASearch,
    ContextMenu,
];

let recentlyChangedColDef = [
    CustomCheckbox,
    PA_ID,
    PAName,
    //kcOwner,
    EMEA_Customer_Number,
    CustomerName,
    //priceNegotiator,
    ValidPAFrom,
    ValidPATo,
    Change,
    LastChanged,
    ContextMenu
];

let expiringSoonColDef = [
    CustomCheckbox,
    PA_ID,
    PAName,
    //kcOwner,
    EMEA_Customer_Number,
    CustomerName,
    //priceNegotiator,
    ValidPAFrom,
    ValidPATo,
    ContextMenu,
];

let recentlyExpiredColDef = [
    CustomCheckbox,
    PA_ID,
    PAName,
    //kcOwner,
    EMEA_Customer_Number,
    CustomerName,
    //priceNegotiator,
    ValidPAFrom,
    ValidPATo,
    LastChanged,
    Status,
    ContextMenu,
];

let searchResultColDef = [
    CustomCheckbox,
    PA_ID,
    PAName,
    //kcOwner,
    EMEA_Customer_Number,
    CustomerName,
    //priceNegotiator,
    ValidPAFrom,
    ValidPATo,
    PASearchLastChanged,
    Status,
    LastDownloadPASearch,
    ContextMenu,
];

let searchResultExternalColDef = [
    CustomCheckbox,
    PA_ID_Sorted,
    PAName,
    //kcOwner,
    EMEA_Customer_Number,
    CustomerName,
    salesOrg,
    //priceNegotiator,
    ValidPAFrom,
    ValidPATo,
    PASearchLastChangedUnsorted,
    Status,
    ContextMenu,
];

let CVTResultColDef = [
    PA_ID_CVT,
    PAName,
    TypeName,
    Territory,
    TerritoryName,
    ValidFrom,
    ValidTo,
    Status,
    ModifyPA,
    ContextMenu,
];

let endUserAdminSearchResultColDef = [
    radioButton,
    PA_ID,
    PAName,
    CustomerId,
    CustomerName,
    euType,
    ValidPAFrom,
    ValidPATo,
    PASearchLastChanged,
    Status,
    EUSearchContextMenu,
];

let myPAFavoritesColDef = [
    CustomCheckbox,
    PA_ID,
    PAName,
    //kcOwner,
    EMEA_Customer_Number,
    CustomerName,
    //priceNegotiator,
    ValidPAFrom,
    ValidPATo,
    LastChanged,
    ContextMenu,
];

let tradeOrgColDef = [tradeOrgNumber, tradeOrgName, tradeOrgAddress, ValidFrom, ValidPATo];

let productColDef = [
    PADetailProductCode,
    description,
    uom,
    endUserPrice,
    contractPrice,
    volume,
    ValidFrom,
    ValidPATo,
    lastChangedDate,
    comments
];

let productColDefInternal = [
    PADetailProductCodeSorted,
    description,
    uom,
    endUserPrice,
    contractPrice,
    volume,
    ValidFrom,
    ValidPATo,
    lastChangedDateUnSorted,
    comments
];

let endUserColDef = [
    EU_ID,
    EUNamePADetails,
    EUAddress,
    ValidFrom,
    ValidPATo,
    updateDate,
    paDetailEuComments,
];

let endUserColDefInternal = [
    EU_ID_Sorted,
    EUNamePADetails,
    EUAddress,
    ValidFrom,
    ValidPATo,
    updateDateUnsorted,
    paDetailEuComments,
];

let endUserRequestsColDef = [
    PANumber,
    EUPAName,
    CustomerId,
    EuCustomerName,
    EuDistributor,
    EuRequestsNumber,
    companyName,
    EuRequestAddress,
    EURequestedDate,
    EUStatus,
    euComments,
    EuEstimatedUnit,
];

let endUserSubmittedColDef = [
    EUcompanyName,
    EUFullAddress,
    EUCity,
    EUState,
    EUZipCode,
    EUPhone,
    EuDistributor,
    EuEstimatedUnit,
];

let recentDownloadColDef = [
    CustomCheckbox,
    FileName,
    recentDldCustomerId,
    RecentDldCustomerName,
    FileSize,
    StatusDesc,
    AvaliableUnit,
    lastDownloadedDate,
    moduleType
];

let myNotificationsColDef = [
    PA_ID_MyNotification,
    PAName,
    //kcOwnerAPAC,
    EMEA_Customer_Number,
    CustomerName,
    Notification,
    myNotificationLastChanged,
];

let customerSoldToColDef = [
    CustomerSoldToId,
    customerSoldToName,
    customerSoldToAddress,
    ValidSoldtoFrom,
    ValidSoldtoTo,
    SoldToComment,
];

//Added for E-File Kc-File Access
let customerNumberAccess = {
    headerName: 'agGrid.agGridColumns.customerNumberLabel',
    field: 'customerNumber',
    filter: "freeTextFilter",
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: "customerNumber_AC"
}

let customerNameAccess = {
    headerName: 'agGrid.agGridColumns.customerNameLabel',
    field: 'customerName',
    filter: "freeTextFilter",
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: "customerName_AC"
}
let fileAccess = {
    headerName: 'agGrid.agGridColumns.customerAccessLabel',
    field: 'access',
    filter: "multiSelectFilter",
    autoHeight: true,
    cellClass: "cell-wrap-text ellipsis ea-dropdown-container",
    menuTabs: ["filterMenuTab"],
    suppressMovable: true,
    colId: "access_AC",
    cellRendererFramework: EaDropdownComponent
}
let efileKcFileAccessColDef = [
    CustomCheckbox,
    customerNumberAccess,
    customerNameAccess,
    fileAccess
];

//Added for E-File Kc-File Access - END

let sfdcColDef = [customerSoldToSfdc];

let sfdcColDefCustAndEUNumber = [
    PA_ID, 
    PAName,
    EMEA_Customer_Number,
    CustomerName,
    ValidPAFrom,
    ValidPATo,
];

let dowloadCustomerListColDef = [CP_Customer_Number, customerName_Download, ContractNumber];

let multipleChangedModalDef = [multipleChangesNotes, multipleChangesDate];

let rebateSearchResult = [
    CustomCheckbox,
    PA_ID_Rebate,
    PAName,
    kcOwner,
    EMEA_Customer_Number,
    CustomerName,
    ValidRebateFrom,
    ValidPATo,
    PASearchRebateLastChanged,
    Status,
    ContextMenu
];

const APAC_COL_DEF = {
    customerSoldToColDef,
    sfdcColDef,
    sfdcColDefCustAndEUNumber,
    searchResultColDef,
    CVTResultColDef,
    tradeOrgColDef,
    productColDef,
    productColDefInternal,
    endUserColDef,
    endUserColDefInternal,
    productSearchModalDef,
    custListModalDef,
    endUserSubmittedColDef,
    endUserRequestsColDef,
    endUserAdminSearchResultColDef,
    endUserSearchModalDef,
    recentDownloadColDef,
    HQModalDef,
    customerSearchColDef,
    myProfileAddLocationColDef,
    myNotificationsColDef,
    searchResultExternalColDef,
    userSearchColDef,
    delegatedUserSearchColDef,
    manageMappingsColDef,
    customerMappingsColDef,
    ucMappingCustomerSearchColDef,
    ExternallocationandMailsColDef,
    InternallocationandMailsColDef,
    recentlyChangedColDef,
    expiringSoonColDef,
    recentlyExpiredColDef,
    myPAFavoritesColDef,
    activeFutureColDef,
    dowloadCustomerListColDef,
    puSearchInternalColDef,
    puSearchExternalColDef,
    multipleChangedModalDef,
    rebateSearchResult,
    efileKcFileAccessColDef
}

export { APAC_COL_DEF };