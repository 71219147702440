import { Component } from '@angular/core';
import { EfileKcfileMultipleRefNumber, EfileKcfileMultipleRefNumberFilter } from '@app/Modules/Shared/DataModels/SharedDataModel';
import { AgRendererComponent } from 'ag-grid-angular';

@Component({
  selector: 'app-refnumber-multiple-popup',
  templateUrl: './refnumber-multiple-popup.component.html',
  styleUrls: ['./refnumber-multiple-popup.component.scss']
})
export class ReferenceNumberMultiplePopup implements AgRendererComponent {

  constructor() { }
  params: any;
  multipleCustomerRequest: any;
  showModal: boolean = false;
  
  agInit(params: any): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    return false;
  }

  onLinkClicked(){
    let request: EfileKcfileMultipleRefNumber = new EfileKcfileMultipleRefNumber();
    request.query = new Object();
    if(this.params && this.params.data && this.params.data.eFileID){
      request.query.claimFileId = this.params.data.eFileID;
    }
    request.filter = new EfileKcfileMultipleRefNumberFilter();
    this.multipleCustomerRequest = request;
    this.showModal = true;
  }

  closedCustModal(event) {
    this.showModal = (event.close === 'true');
  }


}
