/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./search-result.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./search-result.component";
import * as i5 from "../../Services/http.service";
var styles_SearchResultComponent = [i0.styles];
var RenderType_SearchResultComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SearchResultComponent, data: {} });
export { RenderType_SearchResultComponent as RenderType_SearchResultComponent };
function View_SearchResultComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.category_url, ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.name; _ck(_v, 2, 0, currVal_1); }); }
function View_SearchResultComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "ul", [["class", "unlisted header__search-dropdown__quick-links"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SearchResultComponent_2)), i1.ɵdid(5, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.categoryResults; _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("searchResultModule.quickLinks")); _ck(_v, 2, 0, currVal_0); }); }
function View_SearchResultComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵpid(0, i3.SlicePipe, [])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("searchResultModule.options")); _ck(_v, 3, 0, currVal_0); var currVal_1 = ((_v.parent.context.$implicit.options.length > 40) ? (i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_v.parent.context.$implicit.options, 0, 40)) + "...") : _v.parent.context.$implicit.options); _ck(_v, 6, 0, currVal_1); }); }
function View_SearchResultComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["class", "header__search-dropdown__three-up__image"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(0, i3.SlicePipe, []), (_l()(), i1.ɵeld(6, 0, null, null, 3, "table", [["cellpadding", "0"], ["cellspacing", "0"], ["class", "info-table"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SearchResultComponent_5)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_4 = (_v.context.$implicit.options.length > 0); _ck(_v, 9, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.product_url, ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.image; _ck(_v, 2, 0, currVal_1); var currVal_2 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.product_url, ""); _ck(_v, 3, 0, currVal_2); var currVal_3 = ((_v.context.$implicit.name.length > 60) ? (i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_v.context.$implicit.name, 0, 60)) + "...") : _v.context.$implicit.name); _ck(_v, 4, 0, currVal_3); }); }
function View_SearchResultComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "header__search-dropdown__three-up"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "ul", [["class", "unlisted inline"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SearchResultComponent_4)), i1.ɵdid(3, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", " - (", ")"])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co._resultList; _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.allProductsURL, ""); _ck(_v, 4, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("searchResultModule.viewAllProducts")); var currVal_3 = _co.resultCount; _ck(_v, 5, 0, currVal_2, currVal_3); }); }
export function View_SearchResultComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "header__search-dropdown"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "header-search-dropdown-top"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "col-12 col-md-6 offset-md-3 col-lg-2 offset-lg-2"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SearchResultComponent_1)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "col-12 col-lg-6 offset-lg-0 d-none d-md-block"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SearchResultComponent_3)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.categoriesLoaded == true); _ck(_v, 6, 0, currVal_0); var currVal_1 = (_co.resultsLoaded == true); _ck(_v, 9, 0, currVal_1); }, null); }
export function View_SearchResultComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-search-result", [], null, null, null, View_SearchResultComponent_0, RenderType_SearchResultComponent)), i1.ɵdid(1, 114688, null, 0, i4.SearchResultComponent, [i5.HttpService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SearchResultComponentNgFactory = i1.ɵccf("app-search-result", i4.SearchResultComponent, View_SearchResultComponent_Host_0, { resultCount: "resultCount", searchText: "searchText", categoryResults: "categoryResults", searchResult: "searchResult" }, {}, []);
export { SearchResultComponentNgFactory as SearchResultComponentNgFactory };
