/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./category-col-tooltip.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../../../../../../node_modules/primeng/components/overlaypanel/overlaypanel.ngfactory";
import * as i4 from "primeng/components/overlaypanel/overlaypanel";
import * as i5 from "@angular/common";
import * as i6 from "./category-col-tooltip.component";
var styles_CategoryColTooltipComponent = [i0.styles];
var RenderType_CategoryColTooltipComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CategoryColTooltipComponent, data: {} });
export { RenderType_CategoryColTooltipComponent as RenderType_CategoryColTooltipComponent };
function View_CategoryColTooltipComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_v.context.$implicit.label)); _ck(_v, 1, 0, currVal_0); }); }
export function View_CategoryColTooltipComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "custom-table__popup custom-table__favorite ellipsisWrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "custom-table__customer custom-table__popup__trigger ellipsis"]], null, [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (_co.showCustomerInfoTooltip($event, i1.ɵnov(_v, 4)) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (_co.hideCustomerInfoTooltip(i1.ɵnov(_v, 4)) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, [" ", "\n"])), (_l()(), i1.ɵeld(3, 0, null, null, 4, "p-overlayPanel", [["appendTo", "body"]], null, null, null, i3.View_OverlayPanel_0, i3.RenderType_OverlayPanel)), i1.ɵdid(4, 180224, [["op", 4]], 0, i4.OverlayPanel, [i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef, i1.NgZone], { appendTo: [0, "appendTo"], showTransitionOptions: [1, "showTransitionOptions"], hideTransitionOptions: [2, "hideTransitionOptions"] }, null), (_l()(), i1.ɵeld(5, 0, null, 0, 2, "span", [["class", "custom-table__popup__info"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CategoryColTooltipComponent_1)), i1.ɵdid(7, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "body"; var currVal_2 = "500ms"; var currVal_3 = "0ms"; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.categoryList; _ck(_v, 7, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.params.value; _ck(_v, 2, 0, currVal_0); }); }
export function View_CategoryColTooltipComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-category-col-tooltip", [], null, null, null, View_CategoryColTooltipComponent_0, RenderType_CategoryColTooltipComponent)), i1.ɵdid(1, 114688, null, 0, i6.CategoryColTooltipComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CategoryColTooltipComponentNgFactory = i1.ɵccf("app-category-col-tooltip", i6.CategoryColTooltipComponent, View_CategoryColTooltipComponent_Host_0, {}, {}, []);
export { CategoryColTooltipComponentNgFactory as CategoryColTooltipComponentNgFactory };
