import * as tslib_1 from "tslib";
import { ElementRef, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { HttpService } from '@app/Modules/Shared/Services/http.service';
import { LocationLanguageService } from '@app/Modules/Shared/Services/location-language.service';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { waysToContactDropdown } from "@app/conf/dataConfigurations";
import { ContactUsRequest } from '@app/Modules/Shared/DataModels/SharedDataModel';
import { GlobalAppService } from '@app/Modules/Shared/Services/global-app.service';
import { LanguageSelectionService } from '@app/Modules/Shared/Services/language-selection.service';
import { MessageService } from 'primeng/api';
import { cookieSIMID } from '@env/environment';
import { OktaProfile } from '@app/Modules/Shared/DataModels/SharedDataModel';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
var ContactusComponent = /** @class */ (function () {
    function ContactusComponent(_appService, httpService, locationLanguageService, fb, activatedRoute, translateService, _globalAppService, languageService, messageService) {
        this._appService = _appService;
        this.httpService = httpService;
        this.locationLanguageService = locationLanguageService;
        this.fb = fb;
        this.activatedRoute = activatedRoute;
        this.translateService = translateService;
        this._globalAppService = _globalAppService;
        this.languageService = languageService;
        this.messageService = messageService;
        this.isLoggedIn = false;
        this.templateConfig = {
            "region": {
                display: true,
                order: "1"
            },
            "country": {
                display: true,
                order: "2"
            },
            "salesOrg": {
                display: true,
                order: "3"
            },
            "language": {
                display: true,
                order: "4"
            }
        };
        this.ContactUsRequest = new ContactUsRequest();
        this.showcontactform = false;
        this.showSuccessMessage = false;
        this.showButton = true;
        this.isGlobalUser = false;
    }
    Object.defineProperty(ContactusComponent.prototype, "selectedLocationLanguage", {
        get: function () {
            return this._selectedLocationLanguage;
        },
        set: function (value) {
            this._selectedLocationLanguage = value;
            // this.setDefaultLocation();
        },
        enumerable: true,
        configurable: true
    });
    ContactusComponent.prototype.ngOnDestroy = function () {
    };
    ContactusComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.httpService.getUserInfo("").subscribe(function (profile) {
            if (profile) {
                _this.username = profile.given_name + " " + profile.family_name;
                if (_this._appService.isGlobalRoleAssigned) {
                    _this.getGlobalUserDetails(_this._username);
                }
            }
        });
        if (this._appService.getCookie(cookieSIMID) && this._appService.isGlobalUser) {
            this.isGlobalUser = true;
            this._username = this._appService.getCookie(cookieSIMID).substring(this._appService.getCookie(cookieSIMID).indexOf('~^') + 2, this._appService.getCookie(cookieSIMID).indexOf('~*'));
            this._email = this._appService.getCookie(cookieSIMID).substring(this._appService.getCookie(cookieSIMID).indexOf('~*') + 2, this._appService.getCookie(cookieSIMID).indexOf('~&'));
            this._phone = this._appService.getCookie(cookieSIMID).substring(this._appService.getCookie(cookieSIMID).indexOf('~&') + 2);
            this._userId = this._appService.getCookie(cookieSIMID).substring(0, this._appService.getCookie(cookieSIMID).indexOf('~^'));
        }
        this.isGlobalUser = this._appService.isGlobalUser;
        this.waysToContactDropdown = waysToContactDropdown;
        this.region = this._globalAppService.getDefaultRegionString();
        if (this.region == "NA") {
            this.regionNA = true;
        }
        else {
            this.regionNA = false;
        }
        this.language = this._globalAppService.getDefaultLanguageString();
        this._globalAppService.getDefaultRegion().subscribe(function (region) {
            if (region) {
                _this.region = region;
                _this._globalAppService.getDefaultLanguage().subscribe(function (lang) {
                    if (region && lang) {
                        _this.language = lang;
                        _this.translateService.setDefaultLang(_this.region + '/' + _this.languageService.getLanguageCode(_this.region, _this.language).toLowerCase());
                    }
                });
            }
        });
        if (this._appService.AvailableOKtaProfile) {
            this.oktaProfile = this._appService.AvailableOKtaProfile;
            this.isLoggedIn = true;
            if (!this.isLoggedIn) {
                this.loadLocationLanguageData();
            }
            else {
                this.httpService.getUserProfile(this._appService.getuserId(false)).subscribe(function (data) {
                    if ((data && data['defaultCustomerInfo'] && data['defaultCustomerInfo'].length)) {
                        _this.userProfileData = data["defaultCustomerInfo"][0];
                    }
                });
                if (this.region == 'NA') {
                    this.contactForm = this.fb.group({
                        comments: ['', Validators.required]
                    });
                }
                else {
                    this.contactForm = this.fb.group({
                        contactBy: ['', Validators.required],
                        comments: ['', Validators.required]
                    });
                }
                this.showcontactform = true;
                this.translateService.setDefaultLang(this.region + '/' + this.languageService.getLanguageCode(this.region, this.language).toLowerCase());
            }
        }
        else {
            this.loadLocationLanguageData(false, true);
        }
        this.languageService.getlanguage().subscribe(function (language) {
            _this.getLanguageChange(language);
        });
        var selectedCountryLang = this._globalAppService.getSelectedCountryLang();
        var currentLanguage = this._globalAppService.getDefaultLanguageString() || 'en-US';
        if (selectedCountryLang && selectedCountryLang.language) {
            currentLanguage = selectedCountryLang.language;
        }
        this.getLanguageChange(currentLanguage);
        this.translateService.get(['dataConfigData.waysToContactDropdown.select']).subscribe(function (translations) {
            _this.selectLabel = translations['dataConfigData.waysToContactDropdown.select'];
        });
    };
    ContactusComponent.prototype.getGlobalUserDetails = function (username) {
        var userId = this._appService.getuserId(true);
        this.globalUserName = username + "( " + userId + " )";
    };
    ContactusComponent.prototype.getLanguageChange = function (language) {
        var _this = this;
        if (language)
            this.translateService.use(this.region + '/' + this.languageService.getLanguageCode(this.region, language).toLowerCase());
        this.translateService.get(['dataConfigData.waysToContactDropdown.select']).subscribe(function (translations) {
            _this.selectLabel = translations['dataConfigData.waysToContactDropdown.select'];
        });
    };
    ContactusComponent.prototype.loadLocationLanguageData = function (isLoadingOnDrownDownChanges, isOnLoad) {
        var _this = this;
        if (isLoadingOnDrownDownChanges === void 0) { isLoadingOnDrownDownChanges = false; }
        if (isOnLoad === void 0) { isOnLoad = false; }
        var languageFromParams = this.activatedRoute.snapshot.queryParamMap.get('language');
        if (languageFromParams && isOnLoad) {
            this.httpService.getLocationsWithDefaultLanguage(languageFromParams).subscribe(function (locations) {
                if (locations && locations.response && locations.response.length) {
                    _this.locationLanguageService.setLocationLanguageData(locations.response);
                    _this.populateLocations(isLoadingOnDrownDownChanges);
                    _this.initPage();
                    _this.loadContactContent(_this.selectedLocationLanguage);
                }
            });
        }
        else {
            this.httpService.getLocations().subscribe(function (locations) {
                if (locations && locations.response && locations.response.length) {
                    _this.locationLanguageService.setLocationLanguageData(locations.response);
                    _this.populateLocations(isLoadingOnDrownDownChanges);
                    _this.initPage();
                    _this.loadContactContent(_this.selectedLocationLanguage);
                }
            });
        }
    };
    ContactusComponent.prototype.populateLocations = function (isLoadingOnDrownDownChanges) {
        var _this = this;
        if (isLoadingOnDrownDownChanges === void 0) { isLoadingOnDrownDownChanges = false; }
        if (!isLoadingOnDrownDownChanges) {
            this.setDefaultLocation();
        }
        this._globalAppService.setDefaultRegion(this.selectedLocationLanguage.region);
        this.regions = this.locationLanguageService.getAllRegions();
        this.regions = this.regions && this.regions.length ? this.regions.sort() : null;
        if (!this.selectedLocationLanguage || !this.selectedLocationLanguage.region) {
            this.selectedLocationLanguage = {
                region: this.regions[0].value
            };
        }
        this.countries = this.locationLanguageService.getCountriesForRegion(this.selectedLocationLanguage.region);
        this.countries = this.countries && this.countries.length ? this.countries.sort() : null;
        if (!this.selectedLocationLanguage || !this.selectedLocationLanguage.country) {
            this.selectedLocationLanguage = {
                region: this.regions[0].value,
                country: this.countries[0].value
            };
            this.selectedCountryLabel = this.countries.find(function (c) {
                return c.value === _this.selectedLocationLanguage.country;
            }).label;
            this.selectedCountryValue = this.countries.find(function (c) {
                return c.value === _this.selectedLocationLanguage.country;
            }).value;
        }
        this.languages = this.locationLanguageService.getLanguagesForCountry(this.selectedLocationLanguage.region, this.selectedLocationLanguage.country);
        this.languages = this.languages && this.languages.length ? this.languages.sort() : null;
        if (!this.selectedLocationLanguage || !this.selectedLocationLanguage.language) {
            this.selectedLocationLanguage = {
                region: this.regions[0].value,
                country: this.countries[0].value,
                language: this.languages[0].value
            };
        }
        // this.globalAppService.selectedRegion = this.selectedLocationLanguage.region;
        // this.defaultLocationLanguageChanged.emit(this.selectedLocationLanguage);
        // this.locationLanguageService.setSelectedLocationLanguage(this.selectedLocationLanguage);
        // this.globalAppService.setLocationLanguage(this.selectedLocationLanguage);
        // this.setDefaultLocation();
        // this.autoSelectLocationValues();
        this.dataLoaded = true;
    };
    ContactusComponent.prototype.initPage = function () {
        this.loadcomponentConfig();
        this.initFormGroup();
        this.showForm = true;
        this.autoSelectLocationValues();
        if (this.defaultRegion) {
            this.presetSelectLocationValues();
        }
    };
    ContactusComponent.prototype.setDefaultLocation = function () {
        // Check for query params storage first ir not available read from browser storage
        if (this.activatedRoute.snapshot.queryParams && Object.keys(this.activatedRoute.snapshot.queryParams).length) {
            this.readQueryParams();
        }
        else if (typeof (Storage) !== 'undefined' && localStorage.getItem("KCC.CP.selectedLocationLanguage") && localStorage.getItem("KCC.CP.selectedLocationLanguage") !== 'undefined') {
            this.readLocationFromLocalStorage();
        }
        else {
            this.selectedLocationLanguage = {
                region: "LAO",
                country: "AR",
                language: 'es-AR'
            };
            // this.selectedCountryLabel = this.countries.find(c => {
            //   return c.value === this.selectedLocationLanguage.country;
            // }).label;
        }
        this.translateService.setDefaultLang(this.selectedLocationLanguage.region + '/' + this.languageService.getLanguageCode(this.selectedLocationLanguage.region, this.selectedLocationLanguage.language).toLowerCase());
    };
    ContactusComponent.prototype.autoSelectLocationValues = function () {
        this.selectedRegion = this.locationLanguageService.doesRegionExists(this.selectedLocationLanguage.region, this.regions) ? this.selectedLocationLanguage.region : this.regions[0].value;
        this.selectedCountry = this.locationLanguageService.doesCountryExists(this.selectedLocationLanguage.country, this.countries) ? this.selectedLocationLanguage.country : this.countries[0].value;
        this.selectedLanguage = this.locationLanguageService.doesLangaugeExists(this.selectedLocationLanguage.language, this.languages) ? this.selectedLocationLanguage.language : this.languages[0].value;
        this.locationLanguageSelectorForm.get('region').setValue(this.selectedRegion);
        this.locationLanguageSelectorForm.get('country').setValue(this.selectedCountry);
        this.locationLanguageSelectorForm.get('language').setValue(this.selectedLanguage);
    };
    ContactusComponent.prototype.readLocationFromLocalStorage = function () {
        var parsedPreference = JSON.parse(localStorage.getItem("KCC.CP.selectedLocationLanguage"));
        this.selectedLocationLanguage = {
            region: parsedPreference.region,
            country: parsedPreference.country,
            language: parsedPreference.language
        };
    };
    ContactusComponent.prototype.readQueryParams = function () {
        var languageFromParams = this.activatedRoute.snapshot.queryParamMap.get('language');
        var regionFromParams = this.activatedRoute.snapshot.queryParamMap.get('region');
        if (languageFromParams) {
            languageFromParams = languageFromParams.substring(0, 3).toLowerCase() + languageFromParams.substring(3).toUpperCase();
            this.selectedLocationLanguage =
                {
                    region: this.activatedRoute.snapshot.queryParamMap.get('region').toUpperCase(),
                    country: this.activatedRoute.snapshot.queryParamMap.get('country').toUpperCase(),
                    language: languageFromParams
                };
        }
        else if (regionFromParams) {
            this.onRegionChange({ value: regionFromParams });
        }
    };
    ContactusComponent.prototype.initFormGroup = function () {
        if (this.selectedRegion === 'APAC') {
            this.locationLanguageSelectorForm = this.fb.group({
                region: [''],
                country: [''],
                salesOrg: [''],
                language: ['']
            });
        }
        else {
            this.locationLanguageSelectorForm = this.fb.group({
                region: [''],
                country: [''],
                language: ['']
            });
        }
        // this.formChangesListener();
    };
    ContactusComponent.prototype.loadcomponentConfig = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var locationLanguageSelector;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._appService.dynamicImport(this.selectedLocationLanguage.region)];
                    case 1:
                        locationLanguageSelector = (_a.sent()).locationLanguageSelector;
                        Object.assign(this.templateConfig, locationLanguageSelector);
                        return [2 /*return*/];
                }
            });
        });
    };
    ContactusComponent.prototype.presetSelectLocationValues = function () {
        var inputParameter = { 'value': this.defaultRegion };
        this.locationLanguageSelectorForm.get('region').setValue(this.defaultRegion);
        // this.onRegionChange(inputParameter)
    };
    ContactusComponent.prototype.formChangesListener = function () {
        var me = this;
        this.locationLanguageSelectorForm.valueChanges.subscribe(function (changes) {
            me.loadContactContent(changes);
        });
        // this.locationLanguageSelectorForm.get('region').valueChanges.subscribe((region) => {
        //   // this.onRegionChange(region);
        // });
        // this.locationLanguageSelectorForm.get('country').valueChanges.subscribe((country) => {
        //   // this.onCountryChange(country);
        // });
        // this.locationLanguageSelectorForm.get('language').valueChanges.subscribe((language) => {
        //   // this.onLanguageChange(language);
        // });
    };
    ContactusComponent.prototype.onRegionChange = function (event) {
        var _this = this;
        var region = event.value;
        /** On region Change make the API call With Default en-US languagecode to get the Region country and location data */
        this.httpService.getLocationsWithDefaultLanguage("en-US").subscribe(function (locations) {
            if (locations && locations.response && locations.response.length) {
                _this.locationLanguageService.setLocationLanguageData(locations.response);
                _this.countries = _this.locationLanguageService.getCountriesForRegion(region);
                _this.languages = _this.locationLanguageService.getLanguagesForCountry(region, _this.countries[0].value);
                _this.selectedLocationLanguage = {
                    region: region,
                    country: (region == 'NA' ? _this.countries[1].value : _this.countries[0].value),
                    language: 'en-' + (region == 'NA' ? _this.countries[1].value : _this.countries[0].value)
                };
                _this._globalAppService.setDefaultRegion(_this.selectedLocationLanguage.region);
                _this.loadContactContent(_this.selectedLocationLanguage);
                _this.translateService.use(_this.selectedLocationLanguage.region + '/' + _this.languageService.getLanguageCode(_this.selectedLocationLanguage.region, _this.selectedLocationLanguage.language).toLowerCase());
                _this._globalAppService.setDefaultLanguage(_this.selectedLocationLanguage.language);
                _this.loadLocationLanguageData(true);
            }
        });
    };
    ContactusComponent.prototype.onCountryChange = function (event) {
        var country = event.value;
        this.languages = this.locationLanguageService.getLanguagesForCountry(this.selectedLocationLanguage.region, country);
        this.selectedLocationLanguage = {
            region: this.selectedLocationLanguage.region,
            country: country,
            language: 'en-' + country //this.languages[0].value
        };
        this._globalAppService.setDefaultRegion(this.selectedLocationLanguage.region);
        this.loadContactContent(this.selectedLocationLanguage);
        this.translateService.use(this.selectedLocationLanguage.region + '/' + this.languageService.getLanguageCode(this.selectedLocationLanguage.region, this.selectedLocationLanguage.language).toLowerCase());
        this._globalAppService.setDefaultLanguage(this.selectedLocationLanguage.language);
        this.loadLocationLanguageData(true);
    };
    ContactusComponent.prototype.onLanguageChange = function (event) {
        var _this = this;
        var language = event.value;
        this.selectedLocationLanguage = {
            region: this.selectedLocationLanguage.region,
            country: this.selectedLocationLanguage.country,
            language: language
        };
        this.translateService.get(['dataConfigData.waysToContactDropdown.select']).subscribe(function (translations) {
            _this.selectLabel = translations['dataConfigData.waysToContactDropdown.select'];
        });
        this.loadContactContent(this.selectedLocationLanguage);
        this.translateService.use(this.selectedLocationLanguage.region + '/' + this.languageService.getLanguageCode(this.selectedLocationLanguage.region, this.selectedLocationLanguage.language).toLowerCase());
        this._globalAppService.setDefaultRegion(this.selectedLocationLanguage.region);
        this._globalAppService.setDefaultLanguage(this.selectedLocationLanguage.language);
        this.loadLocationLanguageData(true);
    };
    ContactusComponent.prototype.loadContactContent = function (selectedLocationLanguage) {
        var _this = this;
        this._appService.setLoadingStatus(true);
        if (this.countries && this.countries.length)
            this.selectedCountryLabel = this.countries.find(function (c) {
                return c.value === selectedLocationLanguage.country;
            }).label;
        this.selectedCountryValue = this.countries.find(function (c) {
            return c.value === selectedLocationLanguage.country;
        }).value;
        var request = {
            "regionId": selectedLocationLanguage.region,
            "countryId": selectedLocationLanguage.country,
            "languageCode": selectedLocationLanguage.language,
            "contentTypes": [
                {
                    "contentType": selectedLocationLanguage.region == 'EMEA' || selectedLocationLanguage.region == 'APAC' ? "Contacts" : 'RegisterContact'
                }
            ]
        };
        this.httpService.getContentService(request).subscribe(function (response) {
            _this._appService.setLoadingStatus(false);
            if (response && response.result && response.result.length) {
                var parsedData = JSON.parse(response.result[0].data);
                console.log("@@@@@ Contact Data:  ", parsedData);
                if (selectedLocationLanguage.region == 'EMEA' || selectedLocationLanguage.region == 'APAC') {
                    if (parsedData && parsedData.contactUs && parsedData.contactUs.customerService) {
                        if (parsedData.contactUs.customerService.phone && parsedData.contactUs.customerService.phone.length) {
                            _this.contactNumber = parsedData.contactUs.customerService.phone[0];
                        }
                        if (parsedData.contactUs.chargeBack.email && parsedData.contactUs.chargeBack.email.length) {
                            _this.emailId = parsedData.contactUs.chargeBack.email[0];
                        }
                    }
                }
                else if (selectedLocationLanguage.region == 'APAC') {
                    _this.contactNumber = parsedData.phone_number.toString();
                    _this.emailId = parsedData.emailId;
                }
                else {
                    _this.contactNumber = parsedData.phone_number;
                    _this.emailId = parsedData.emailId;
                }
            }
            else {
                _this.contactNumber = null;
                _this.emailId = null;
            }
        }, function (error) {
            _this._appService.setLoadingStatus(false);
        });
        var footerRequest = {
            "regionId": selectedLocationLanguage.region,
            "countryId": selectedLocationLanguage.country,
            "languageCode": selectedLocationLanguage.language,
            // this.footerRequest.salesOrgId = +this._globalAppService.getSalesOrg();
            // this.footerRequest.salesOrgId = this.salesOrg;
            "contentTypes": [
                {
                    "contentType": "GlobalFooter"
                }
            ]
        };
        this.httpService.getKCPContent(footerRequest).subscribe(function (data) {
            if (data && data.result && data.result.length > 0) {
                var dataResult = JSON.parse(data.result[0].data);
                _this.privacyPolicy = dataResult.privacypolicy;
                _this.legalStatement = dataResult.legalstatement;
            }
        });
    };
    ContactusComponent.prototype.contactWayChange = function (e) {
        var contactusObj = new ContactUsRequest();
        contactusObj.contactBy = this.selectedContactWay;
    };
    ContactusComponent.prototype.onSubmit = function () {
        var _this = this;
        this._appService.setLoadingStatus(true);
        var contactFromValues = this.contactForm.getRawValue();
        var country = this._globalAppService.getDefaultCountryString();
        var commentsValueHtml = this.commentsText.nativeElement.value;
        if (this.region == 'EMEA' || this.region == 'APAC') {
            var commentsValues = this.commentsText.nativeElement.value.split('\n');
            commentsValueHtml = commentsValues.join(' | ');
        }
        var custAddress = this.userProfileData ? this.userProfileData.address1 + ', ' + this.userProfileData.city + ', ' + this.userProfileData.state + ', ' + this.userProfileData.postalCode : '';
        custAddress = custAddress.replace(", null", " ");
        var request = {
            "customerNumber": this.userProfileData ? this.userProfileData.customerNumber : '',
            "customerCompany": this.userProfileData ? this.userProfileData.name : '',
            "customerCountry": this.userProfileData ? this.userProfileData.countryName : '',
            "customerAddress": custAddress,
            "userContact": this.oktaProfile ? this.oktaProfile.name : '',
            "userPhoneNumber": this.oktaProfile ? this.oktaProfile.phone : '',
            "userCountry": country,
            "contactBy": contactFromValues.contactBy,
            "comments": commentsValueHtml,
            "BrowserInformation": this.getBrowserInformation()
        };
        this.httpService.saveUserContact(request).subscribe(function (data) {
            if (data.result === true && data.status === 'OK') {
                _this.showSuccessMessage = true;
                _this.showButton = false;
            }
            _this._appService.setLoadingStatus(false);
        }, function (error) {
            _this._appService.setLoadingStatus(false);
            // this.messageService.add({ severity: 'error', summary: 'Error', detail: 'An unexpected error occurred. Please try again.' });
            _this.messageService.add({ key: 'downloadError', summary: 'errorLabel', detail: '0000.errorMessage', severity: 'error' });
        });
    };
    ContactusComponent.prototype.reset = function () {
        this.contactForm.reset();
        this.showButton = true;
        this.showSuccessMessage = false;
    };
    ContactusComponent.prototype.getBrowserInformation = function () {
        var navUserAgent = navigator.userAgent;
        var browserName = navigator.appName;
        var browserVersion = '' + parseFloat(navigator.appVersion);
        var tempNameOffset, tempVersionOffset, tempVersion;
        if ((tempVersionOffset = (/MSIE|Trident\//.test(navigator.userAgent)))) {
            browserName = "Microsoft Internet Explorer";
            var versionIndex = navUserAgent.indexOf('rv:');
            browserVersion = navUserAgent.substring(versionIndex + 3);
        }
        else if ((navigator.userAgent.indexOf("Edg") != -1)) {
            browserName = "Microsoft Edge";
            var edgIndex = navUserAgent.indexOf('Edg/');
            browserVersion = navUserAgent.substring(edgIndex + 4);
        }
        else if ((tempVersionOffset = navUserAgent.indexOf("Chrome")) != -1) {
            browserName = "Chrome";
            browserVersion = navUserAgent.substring(tempVersionOffset + 7);
        }
        else if ((tempVersionOffset = navUserAgent.indexOf("Safari")) != -1) {
            browserName = "Safari";
            browserVersion = navUserAgent.substring(tempVersionOffset + 7);
            if ((tempVersionOffset = navUserAgent.indexOf("Version")) != -1)
                browserVersion = navUserAgent.substring(tempVersionOffset + 8);
        }
        else if ((tempVersionOffset = navUserAgent.indexOf("Firefox")) != -1) {
            browserName = "Firefox";
            browserVersion = navUserAgent.substring(tempVersionOffset + 8);
        }
        else if ((tempNameOffset = navUserAgent.lastIndexOf(' ') + 1) < (tempVersionOffset = navUserAgent.lastIndexOf('/'))) {
            browserName = navUserAgent.substring(tempNameOffset, tempVersionOffset);
            browserVersion = navUserAgent.substring(tempVersionOffset + 1);
            if (browserName.toLowerCase() == browserName.toUpperCase()) {
                browserName = navigator.appName;
            }
        }
        else {
            browserName = "Browser Details Not Found";
            browserVersion = "";
        }
        // trim version
        if ((tempVersion = browserVersion.indexOf(";")) != -1)
            browserVersion = browserVersion.substring(0, tempVersion);
        if ((tempVersion = browserVersion.indexOf(" ")) != -1)
            browserVersion = browserVersion.substring(0, tempVersion);
        if ((tempVersion = browserVersion.indexOf(")")) != -1)
            browserVersion = browserVersion.substring(0, tempVersion);
        return (browserName + (browserVersion ? " - " + browserVersion : ""));
    };
    ContactusComponent = tslib_1.__decorate([
        AutoUnsubscribe(),
        tslib_1.__metadata("design:paramtypes", [AppService,
            HttpService,
            LocationLanguageService,
            FormBuilder,
            ActivatedRoute,
            TranslateService,
            GlobalAppService,
            LanguageSelectionService,
            MessageService])
    ], ContactusComponent);
    return ContactusComponent;
}());
export { ContactusComponent };
