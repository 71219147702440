import { Component, OnInit, HostListener, ElementRef, isDevMode } from '@angular/core';
import { AppService } from './Modules/Shared/Services/app.service';
import { Keepalive } from '@ng-idle/keepalive';
import { EventTargetInterruptSource, Idle } from '@ng-idle/core';
import { IdleTimeOutConfig } from '@env/environment';
import { headerLinks } from '@app/conf/appLink';
import { OktaAuthService } from '@okta/okta-angular';
import { Router } from '@angular/router';
import { TextEncoder } from 'text-encoding';
import { environment } from '../environments/environment';
import { Guid } from 'guid-typescript';
import {allPopupsList} from '@app/conf/dataConfigurations';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  idleState = 'NOT_STARTED';
  timedOut = false;
  visibleDownloadModal = true;

  constructor(private _oktaAuth: OktaAuthService, private router: Router,
    private appService: AppService, private element: ElementRef, private idle: Idle,
    private keepalive: Keepalive) {
      //below code is to prevent popup from getting refreshed on F5 press
      window.onkeydown = (event) => {
        if(event.keyCode == 116){
          const url = window.location.href;
          const result = allPopupsList.find((item) => {
            if(url.indexOf(item) > -1){
              return true;
            }
          })
          if(result){
              event.preventDefault();
          }
        }
      }
      let existingSessionId = localStorage.getItem("session-id");
      if(!existingSessionId){
        localStorage.setItem("session-id",Guid.raw());
      }
    
    // polyfill TextEncoder for IE Edge
    if (typeof (window as any).TextEncoder === 'undefined') {
      (window as any).TextEncoder = TextEncoder;
    }
    // sets an idle timeout of given time.
    idle.setIdle(IdleTimeOutConfig.setIdle);
    // sets a timeout period given time.
    idle.setTimeout(IdleTimeOutConfig.setTimeout);
    // sets the interrupts like Keydown, scroll, mouse wheel, mouse down, and etc
    idle.setInterrupts([
      new EventTargetInterruptSource(
        this.element.nativeElement, 'keydown DOMMouseScroll mousewheel mousedown touchstart touchmove scroll')]);

    idle.onIdleEnd.subscribe(() => {
      // console.log("idle end :::", new Date()) -- commented
      this._oktaAuth.getAccessToken().then((token) => { this.appService.oktaAuthRenewToken(token) });
      this.idleState = 'NO_LONGER_IDLE';
      this.reset();
    });

    idle.onTimeout.subscribe(() => {
      // console.log("onTimeout :::", new Date())
      this.idleState = 'TIMED_OUT';
      this.timedOut = true;
      this.closeProgressForm();
    });

    idle.onIdleStart.subscribe(() => {
      // console.log("idle Start :::", new Date())
      this.idleState = 'IDLE_START', this.openProgressForm(1);
    });

    idle.onTimeoutWarning.subscribe((countdown: any) => {
      // console.log("idle in progress :::", new Date())
      this.idleState = 'IDLE_TIME_IN_PROGRESS';
      this.visibleDownloadModal = true
    });

    // sets the ping interval to given time
    keepalive.interval(IdleTimeOutConfig.setInterval);

    this.reset();
  }

  ngOnInit() {
    // var encrypted = this.EncrDecr.encryptData('123456$#@$^@1ERF');
    // var decrypted = this.EncrDecr.decryptData('U2FsdGVkX1/JUA0KZk2AGtuybKTW8r5P7DZt9oJgH87eVToh2y7rE+BFvWpuIxcV');
    if (isDevMode()) {
      if(environment.GTMpreviewVersion > 0)
      {
        (document.getElementById('GoogleTagManager') as HTMLScriptElement).src += "&gtm_preview=env-" + environment.GTMpreviewVersion;
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  screenResize(event) {
    this.appService.setScreenResize(true);
  }

  // @HostListener('window:beforeunload', ['$event'])
  // unloadHandler(event) {
  //   const url = window.location.href;
  //   const result = allPopupsList.find((item) => {
  //     if(url.indexOf(item) > -1){
  //       return item;
  //     }
  //   })
  //   if(result){
  //     event.returnValue = false;
  //   }
  // }


  reverseNumber(countdown: number) {
    return (IdleTimeOutConfig.setTimeout - (countdown - 1));
  }

  openProgressForm(count: number) {
    this.visibleDownloadModal = true;
  }

  closeProgressForm() {
    // location.href = headerLinks.logoutLink;

    this.router.navigateByUrl("/logout");
  }

  reset() {
    this.visibleDownloadModal = false;
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  resetTimeOut() {
    this.visibleDownloadModal = false;
    this.idle.stop();
    this.idle.onIdleStart.unsubscribe();
    this.idle.onTimeoutWarning.unsubscribe();
    this.idle.onIdleEnd.unsubscribe();
    this.idle.onIdleEnd.unsubscribe();
  }

  ngOnDestroy() {
    this.resetTimeOut();
  }
}
