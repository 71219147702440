import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CbService } from '@app/Modules/chargeback/Services/cb.service';
import { HttpChargeBackService } from '@app/Modules/Shared/Services/http-charge-back.service';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { GlobalAppService } from './../../../../Shared/Services/global-app.service';
var ContextmenucbComponent = /** @class */ (function () {
    function ContextmenucbComponent(appServ, router, activatedRoute, cbServ, httpServ, messageService, globalAppService) {
        this.appServ = appServ;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.cbServ = cbServ;
        this.httpServ = httpServ;
        this.messageService = messageService;
        this.globalAppService = globalAppService;
        this.submittedContextMenu = false;
        this.validatedContextMenu = false;
        this.errorFoundTestContextMenu = false;
        this.errorFoundActiveContextMenu = false;
        this.processingTestContextMenu = false;
        this.processingActiveContextMenu = false;
        this.readyToSubmitContextMenu = false;
        this.deletedTestContextMenu = false;
        this.deletedActiveContextMenu = false;
        this.isInternalUser = true;
        this.isGlobalUser = false;
        this.isEfileSubmitted = false;
        this.isEfileDeleted = false;
        this.subscription = new Subscription();
        this.ellipseSelect = false;
    }
    ContextmenucbComponent.prototype.agInit = function (params) {
        this.params = params;
        this.rowIndex = this.params.rowIndex;
        if (params.data && params.data.status && params.data.type) {
            switch (params.data.status) {
                case "1":
                    if (params.data.type === "1") {
                        this.errorFoundTestContextMenu = true;
                    }
                    else {
                        this.errorFoundActiveContextMenu = true;
                    }
                    break;
                case "2":
                    this.validatedContextMenu = true;
                    break;
                case "3":
                    if (params.data.type === "1") {
                        this.processingTestContextMenu = true;
                    }
                    else {
                        this.processingActiveContextMenu = true;
                    }
                    break;
                case "4":
                    this.readyToSubmitContextMenu = true;
                    break;
                case "5":
                    this.submittedContextMenu = true;
                    break;
                case "6":
                    if (params.data.type === "1") {
                        this.deletedTestContextMenu = true;
                    }
                    else {
                        this.deletedActiveContextMenu = true;
                    }
                    break;
            }
        }
        this.isInternalUser = this.appServ.isInternalUser;
        this.isGlobalUser = this.appServ.isGlobalUser;
        this.region = this.globalAppService.getDefaultRegionString();
    };
    ContextmenucbComponent.prototype.ngOnInit = function () {
        var _this = this;
        //below is to change the contextmenu after efile submit
        this.subscription.add(this.cbServ.getSubmitContextMenu().subscribe(function (data) {
            _this.isEfileSubmitted = data;
        }));
        //below is to change contextmenu after file deleted
        this.subscription.add(this.cbServ.getDeletedStatusText().subscribe(function (data) {
            if (_this.params.rowIndex === data.rowIndex) {
                switch (data.status) {
                    case '1':
                        _this.errorFoundActiveContextMenu = false;
                        _this.errorFoundTestContextMenu = false;
                        _this.deletedActiveContextMenu = true;
                        break;
                    case '2':
                        _this.validatedContextMenu = false;
                        _this.deletedActiveContextMenu = true;
                        break;
                    case '4':
                        _this.readyToSubmitContextMenu = false;
                        _this.deletedActiveContextMenu = true;
                        break;
                }
            }
        }));
    };
    ContextmenucbComponent.prototype.refresh = function (params) {
        return true;
    };
    ContextmenucbComponent.prototype.errorSummaryClick = function () {
        this.router.navigate([{ outlets: { errorSummaryDialog: ['errorSummary'] } }], { relativeTo: this.activatedRoute.parent, state: { 'efileName': this.params.data.eFileName, 'claimFileId': this.params.data.eFileID, 'customerNumber': this.params.data.customerNumber, 'salesOrgId': this.params.data.salesOrgId } });
    };
    ContextmenucbComponent.prototype.openDeletePopup = function (e, element) {
        element.hide(e);
        this.router.navigate([{ outlets: { deleteConfirmationDialog: ['deleteConfirmation'] } }], { relativeTo: this.activatedRoute.parent, state: { 'eFileName': this.params.data.eFileName, 'eFileID': this.params.data.eFileID, 'salesOrgId': this.params.data.salesOrgId, 'status': this.params.data.status, 'type': this.params.data.type, 'rowIndex': this.params.rowIndex } });
    };
    ContextmenucbComponent.prototype.downloadEfile = function (e, element) {
        var _this = this;
        if (this.params.data.lines > 0) {
            this.appServ.setLoadingStatus(true);
            if (this.params.data && this.params.data.eFileID && this.params.data.salesOrgId) {
                var fileversion = (this.params.data.status && this.params.data.status == '3') ? 0 : 1;
                var request = {
                    "claimFileId": this.params.data.eFileID,
                    "salesOrgId": this.params.data.salesOrgId,
                    "fileversion": fileversion //passing fileversion as 1 as new file to be downloaded code is 1 and for old its 0
                };
                this.subscription.add(this.httpServ.efileDownload(request).subscribe(function (res) {
                    if (res && res.fileUrl) {
                        // window.open(res.fileUrl, '_blank');
                        _this.router.navigate([{ outlets: { dialogs: ['downloadLinkModal'] } }], {
                            queryParams: {
                                region: localStorage.getItem('default-region'),
                                country: localStorage.getItem('country'),
                                language: localStorage.getItem('country-lang')
                            },
                            relativeTo: _this.activatedRoute.parent,
                            state: { url: res.fileUrl, title: 'chargeback.eFile.contextMenu.downloadEfile' }
                        });
                    }
                    else {
                        _this.appServ.setLoadingStatus(false);
                        _this.messageService.add({ key: 'efileDownload', detail: '0000.errorMessage', summary: 'errorLabel', severity: 'error' });
                    }
                }, function (e) {
                    _this.appServ.setLoadingStatus(false);
                    _this.messageService.add({ key: 'efileDownload', detail: '0000.errorMessage', summary: 'errorLabel', severity: 'error' });
                }, function () {
                    element.hide(e);
                    _this.appServ.setLoadingStatus(false);
                }));
            }
        }
    };
    ContextmenucbComponent.prototype.efileHistoryPopUp = function (e, element) {
        element.hide(e);
        this.cbServ.totalLinesInFile = this.params.data.lines;
        this.router.navigate([{ outlets: { efileHistoryDialog: ['efileHistory'] } }], { relativeTo: this.activatedRoute.parent, state: { 'eFileName': this.params.data.eFileName, 'eFileID': this.params.data.eFileID, 'salesOrgId': this.params.data.salesOrgId } });
    };
    ContextmenucbComponent.prototype.manageErrorsOnly = function () {
        var _this = this;
        if (this.isInternalUser || this.params.data.errors <= 50) {
            this.router.navigate([{ outlets: { efileSearchResultDisplay: null } }], { relativeTo: this.activatedRoute.parent }).then(function () {
                _this.router.navigate(['manage-errors'], {
                    queryParams: { 'eFileID': _this.params.data.eFileID, 'salesOrgId': _this.params.data.salesOrgId }, relativeTo: _this.activatedRoute.parent.parent, state: {
                        'efileId': _this.params.data.eFileID, 'salesOrgId': _this.params.data.salesOrgId, 'type': _this.params.data.type, 'eFileName': _this.params.data.eFileName
                    }
                });
            });
        }
    };
    ContextmenucbComponent.prototype.submitEfile = function (e, element) {
        var _this = this;
        element.hide(e);
        this.appServ.setLoadingStatus(true);
        var request = {
            "claimFileId": this.params.data.eFileID,
            "salesOrgId": this.params.data.salesOrgId,
            "isDeleted": false,
            "eFileStatus": 5 //this is enum for "Submitted" status and should always be passed as 5 only, for delete this parameter can be skipped
        };
        this.subscription.add(this.httpServ.updateCBEfileStatus(request).subscribe(function (data) {
            if (data) {
                _this.cbServ.setSubmitStatusText(true, _this.rowIndex);
                _this.cbServ.initiateFileSubmit(request);
            }
            else {
                _this.appServ.setLoadingStatus(false);
                _this.messageService.add({ key: 'efileDownload', detail: '0000.errorMessage', summary: 'errorLabel', severity: 'error' });
            }
        }, function (e) {
            _this.appServ.setLoadingStatus(false);
            _this.messageService.add({ key: 'efileDownload', detail: '0000.errorMessage', summary: 'errorLabel', severity: 'error' });
        }, function () {
            _this.appServ.setLoadingStatus(false);
        }));
    };
    ContextmenucbComponent.prototype.contextmenuToggle = function (element, e) {
        element.toggle(e);
    };
    ContextmenucbComponent.prototype.onContextMenuHide = function () {
        this.ellipseSelect = false;
    };
    ContextmenucbComponent.prototype.onContextMenuShow = function () {
        this.ellipseSelect = true;
    };
    ContextmenucbComponent.prototype.ngOnDestroy = function () {
    };
    ContextmenucbComponent = tslib_1.__decorate([
        AutoUnsubscribe(),
        tslib_1.__metadata("design:paramtypes", [AppService,
            Router,
            ActivatedRoute,
            CbService,
            HttpChargeBackService,
            MessageService,
            GlobalAppService])
    ], ContextmenucbComponent);
    return ContextmenucbComponent;
}());
export { ContextmenucbComponent };
