/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cbhyperlink.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./cbhyperlink.component";
import * as i5 from "../../../../Shared/Services/app.service";
import * as i6 from "@angular/router";
import * as i7 from "../../../../chargeback/Modules/CBCouponDetails/Services/coupon-details.service";
import * as i8 from "../../../../Shared/Services/global-app.service";
import * as i9 from "../../../../Shared/Services/language-selection.service";
import * as i10 from "../../../services/cp-ag-grid-service";
var styles_CbhyperlinkComponent = [i0.styles];
var RenderType_CbhyperlinkComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CbhyperlinkComponent, data: {} });
export { RenderType_CbhyperlinkComponent as RenderType_CbhyperlinkComponent };
function View_CbhyperlinkComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.FormattedValue || _co.params.valueFormatted) || _co.params.value); _ck(_v, 1, 0, currVal_0); }); }
function View_CbhyperlinkComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onLinkClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.FormattedValue || _co.params.valueFormatted) || _co.params.value); _ck(_v, 1, 0, currVal_0); }); }
function View_CbhyperlinkComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CbhyperlinkComponent_4)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hideLink; _ck(_v, 2, 0, currVal_0); }, null); }
function View_CbhyperlinkComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onLinkClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("agGrid.agGridColumns.customerDetails")); _ck(_v, 1, 0, currVal_0); }); }
function View_CbhyperlinkComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CbhyperlinkComponent_6)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hideLink; _ck(_v, 1, 0, currVal_0); }, null); }
function View_CbhyperlinkComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CbhyperlinkComponent_3)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["MCCustomerColBlock", 2]], null, 0, null, View_CbhyperlinkComponent_5))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isMCCustomerCol; var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_CbhyperlinkComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CbhyperlinkComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["elseBlock", 2]], null, 0, null, View_CbhyperlinkComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isnoHyperlinkPages; var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_CbhyperlinkComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cbhyperlink", [], null, null, null, View_CbhyperlinkComponent_0, RenderType_CbhyperlinkComponent)), i1.ɵdid(1, 245760, null, 0, i4.CbhyperlinkComponent, [i5.AppService, i6.Router, i6.ActivatedRoute, i7.CouponDetailsService, i8.GlobalAppService, i9.LanguageSelectionService, i10.CpAgGridService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CbhyperlinkComponentNgFactory = i1.ɵccf("app-cbhyperlink", i4.CbhyperlinkComponent, View_CbhyperlinkComponent_Host_0, {}, {}, []);
export { CbhyperlinkComponentNgFactory as CbhyperlinkComponentNgFactory };
