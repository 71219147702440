import { Component, OnInit } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { Router, ActivatedRoute } from '@angular/router';
import { CpAgGridService } from '@app/Modules/cp-grid/services/cp-ag-grid-service';
import { CbService } from '@app/Modules/chargeback/Services/cb.service';
import { GlobalAppService } from '@app/Modules/Shared/Services/global-app.service';

@Component({
  selector: 'app-kcfile-contextmenu-manage-error',
  templateUrl: './kcfile-contextmenu-manage-error.component.html',
  styleUrls: ['./kcfile-contextmenu-manage-error.component.scss']
})

export class KcFileContextmenuManageErrorComponent implements AgRendererComponent, OnInit {

  params: any;
  ellipseSelect: boolean = false;
  taxIdProdIdError: boolean = false;
  region:string;
  
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private cpAgGridService: CpAgGridService,
    private cbServ: CbService,
    private globalAppService: GlobalAppService
  ) { }

  agInit(params: any) {
    this.params = params;
  }

  ngOnInit(){
    this.region = this.globalAppService.getDefaultRegionString();
    this.cbServ.implementRowDelete().subscribe(data => {
      if (data) {
        // this.params.api.updateRowData({ remove: [this.params.node.data] });
      }
    });
    this.isTaxIdProdIdError();
  }

  refresh(params: any): boolean {
    return true;
  }

  contextmenuToggle(element, e){
    element.toggle(e);
  }

  onContextMenuHide(){
    this.ellipseSelect = false;
  }

  onContextMenuShow(){
    this.ellipseSelect = true;
  }

  editLine(e, element){
    // let rawErrorData = Object.assign({}, this.cpAgGridService.getKcFileErrorData(), {"currentErrorLine": this.params.data.line});
    let fileDetails = this.cpAgGridService.getKcFileErrorData().fileDetails;
    let errorDataArray = this.cpAgGridService.getKcFileErrorData().errorData;
    errorDataArray = errorDataArray.filter(item => {
      return !item.isDeleted;
    });
    let Obj = {
      "errorData": errorDataArray,
      "fileDetails": fileDetails
    }
    let rawErrorData = Object.assign({}, Obj, {"currentErrorLine": this.params.data.line});
    element.hide(e);
    this.router.navigate([{ outlets: { editModalDialog: ['editModal'] } }], { relativeTo: this.activatedRoute, queryParamsHandling: "preserve", state: {'fileErrorData': rawErrorData} });
  }
  deleteLine(e, element) {
    let payload = this.cbServ.getDeleteOrDownloadPayload();//this gets claimefileID and salesorgid for delete error line and download error file on correct error page
    let line = this.params.data.line;
    let request = {
      "claimFileId": payload.request.query.claimFileId,
      "lineNumber": line,
      "salesOrgId": payload.request.query.salesOrgId
    }
    element.hide(e);
    this.router.navigate([{ outlets: { deleteErrorLineConfirmation: ['deleteErrorLineConfirmation'] } }], { relativeTo: this.activatedRoute, skipLocationChange: true, state: {'request': request}, queryParamsHandling: "preserve" });
  }

  isTaxIdProdIdError() {
    if (this.region == "LAO" && this.params.data) {
      let findErrorCell = this.params.data.lineErrors.find(item => {
        if ((item.fieldName).toUpperCase() === ("taX_ID").toUpperCase() || (item.fieldName).toUpperCase() === ("proD_ID").toUpperCase()) {
          this.taxIdProdIdError = true;
          return true;
        }
      });
    } else {
      this.taxIdProdIdError = false;
    }
  }
}
