import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import "rxjs/add/observable/of";
import { HttpService } from '@app/Modules/Shared/Services/http.service';
import { Router, ActivatedRoute } from '@angular/router';
import { globalUser, cookieSIMID, cookieLoggedInUserId, APP_LEGACY_MENU_HOST } from '@env/environment';
import { indexLink } from '@app/conf/appLink';
import { GlobalAppService } from './global-app.service';
import * as moment from 'moment';
import { ribbonMenuExternal, ribbonMenuInternalCustomer, ribbonMenuInternalCVT, ribbonMenuGlobal, DashBoardTiles } from '@app/conf/dataConfigurations';
import { LanguageSelectionService } from './language-selection.service';
import { DataLayerService } from './data-layer.service';
import { FullStoryService } from './full-story.service';
import { countryOBJ } from '@app/conf/countryURLConfig';
import * as i0 from "@angular/core";
import * as i1 from "./http.service";
import * as i2 from "@angular/router";
import * as i3 from "./global-app.service";
import * as i4 from "./language-selection.service";
import * as i5 from "./data-layer.service";
import * as i6 from "./full-story.service";
var AppService = /** @class */ (function () {
    function AppService(_HTTPService, _router, route, globalAppService, languageSelected, _dataLayerService, _fullStoryService) {
        this._HTTPService = _HTTPService;
        this._router = _router;
        this.route = route;
        this.globalAppService = globalAppService;
        this.languageSelected = languageSelected;
        this._dataLayerService = _dataLayerService;
        this._fullStoryService = _fullStoryService;
        this.cvtViewSubject = new Subject();
        this._oktaProfileData = new Subject();
        this._userProfileData = new Subject();
        this._isLoading = new BehaviorSubject(false);
        this._loadedHomeModal = false;
        this._SearchSelectedModalData = new Subject();
        this.globalUser = false;
        // profileLoaded: Subject<boolean> = new Subject<boolean>();
        this.screenResize = new Subject();
        this._disableLink = new Subject();
        this.isProfileSet = false;
        this.isExternalAdminUserVar = false;
        this.isDelegatedUserPage = new Subject();
        this.isDelegatedUserPageBoolean = false;
        this.paTypeSubject = new Subject();
        this.salesOrgNamesSubject = new Subject();
        this.KCOwnerNamesSubject = new Subject();
        this.homeNavigation = new Subject();
        this.subscription = new Subscription();
        this.showAcceptancePolicyModal = new Subject();
        this._timeOutLoaderHide = new Subject();
        this.flagImageResRecd = new Subject();
        this.popUpStatusSubject = new Subject();
        this.popupSequenceNumber = [];
        this.popupSequenceNumberIndex = -1;
        this.appLoaded = false;
        this.CBAcceptancePolicyLoaded = false;
        this.searchRequestState = new BehaviorSubject(null);
        this.locationLanguage = new BehaviorSubject(null);
        this.activesubNav = new BehaviorSubject({ id: "1" });
        this.activeTile = new BehaviorSubject(DashBoardTiles["LAO"].find(function (x) { return x.id == "activeCount"; }));
        this.menu = new BehaviorSubject(ribbonMenuGlobal["LAO"]);
        this.setMenu();
    }
    AppService.prototype.setLoaderStatusForHeaderFlagImage = function (value) {
        this.flagImageResRecd.next({
            'flagImageResRecd': value
        });
    };
    AppService.prototype.getLoaderStatusForHeaderFlagImage = function () {
        return this.flagImageResRecd.asObservable();
    };
    AppService.prototype.setUserSimulated = function (value) {
        this.userSimulated = value;
    };
    AppService.prototype.getUserSimulated = function () {
        return this.userSimulated;
    };
    AppService.prototype.setAcceptancePolicyModalVisibility = function (value) {
        console.log("set acceptance policy modal", value);
        this.showAcceptancePolicyModal.next(value);
    };
    AppService.prototype.getAcceptancePolicyModalVisibility = function () {
        return this.showAcceptancePolicyModal.asObservable();
    };
    AppService.prototype.setHomeNavigation = function (homeNavigation) {
        this.homeNavigation.next(homeNavigation);
    };
    AppService.prototype.getHomeNavigation = function () {
        return this.homeNavigation;
    };
    AppService.prototype.setPAtype = function (patype) {
        this._paType = patype;
        this.paTypeSubject.next(patype);
    };
    AppService.prototype.getPAtype = function () {
        return this._paType;
    };
    AppService.prototype.getPAtypeObservable = function () {
        return this.paTypeSubject.asObservable();
    };
    AppService.prototype.setSaleOrgNames = function (salesOrgNames) {
        this.salesOrgNames = salesOrgNames;
        this.salesOrgNamesSubject.next(salesOrgNames);
    };
    AppService.prototype.getSalesOrgNames = function () {
        return this.salesOrgNames;
    };
    AppService.prototype.getSalesOrgNamesObservable = function () {
        return this.salesOrgNamesSubject.asObservable();
    };
    AppService.prototype.setKCOwnerNames = function (KCOwnerNames) {
        this.KCOwnerNames = KCOwnerNames;
        this.KCOwnerNamesSubject.next(KCOwnerNames);
    };
    AppService.prototype.getKCOwnerNames = function () {
        return this.KCOwnerNames;
    };
    AppService.prototype.getKCOwnerNamesObservable = function () {
        return this.KCOwnerNamesSubject.asObservable();
    };
    AppService.prototype.setIsDelegatedUserPage = function (value) {
        this.isDelegatedUserPageBoolean = value;
        this.isDelegatedUserPage.next(value);
    };
    AppService.prototype.getIsDelegatedUserPage = function () {
        return this.isDelegatedUserPage;
    };
    AppService.prototype.getisDelegatedUserPageBoolean = function () {
        return this.isDelegatedUserPageBoolean;
    };
    AppService.prototype.setLocationLanguage = function (value) {
        this.locationLanguage.next(value);
    };
    AppService.prototype.getLocationLanguage = function () {
        return this.locationLanguage.asObservable();
    };
    AppService.prototype.getSearchRequest = function () {
        return this.searchRequestState.asObservable();
    };
    AppService.prototype.changeSearchRequest = function (searchObject) {
        this.searchRequestState.next(searchObject);
    };
    // getProfileLoadedObservable(): Observable<boolean> {
    //   return this.profileLoaded.asObservable();
    // }
    AppService.prototype.getFavoritePA = function () {
        return this.favoritepaList;
    };
    AppService.prototype.setFavoritePA = function () {
        var _this = this;
        this.favoritepaList = [];
        this._HTTPService.getFavContractList().subscribe(function (data) {
            if (data && data.favoriteContractList) {
                _this.favoritepaList = data.favoriteContractList;
            }
        });
    };
    AppService.prototype.setuserProfileData = function (customerInfo) {
        this.userProfile = Object.assign({}, customerInfo);
        this._userProfileData.next(customerInfo);
    };
    Object.defineProperty(AppService.prototype, "userProfileInfo", {
        get: function () {
            return this.userProfile;
        },
        enumerable: true,
        configurable: true
    });
    AppService.prototype.getuserProfileData = function () {
        return this._userProfileData;
    };
    AppService.prototype.getScreenResize = function () {
        return this.screenResize.asObservable();
    };
    AppService.prototype.setScreenResize = function (value) {
        return this.screenResize.next(value);
    };
    AppService.prototype.isUserInformationAvailable = function () {
        return this.gUserInfo;
    };
    AppService.prototype.getOKTAProfile = function (OKTA_TOKEN) {
        var _this = this;
        this._HTTPService.getUserInfo(OKTA_TOKEN).subscribe(function (profile) {
            // this.profileLoaded.next(true);
            _this.setOktaProfile(profile);
            //global user check
            _this._userID = profile.sAMAccountName;
            _this._userType = profile.userType;
            document.cookie = cookieLoggedInUserId + "=" + _this._userID + ";path=/"; // This cookie is required by legacy application            
            if (_this.isGlobalUser && _this.getCookie(cookieSIMID)) {
                _this.globalUser = true;
            }
            if (_this.globalUser && _this.getCookie(cookieSIMID) == null) {
                window.open(indexLink, '_self');
                return;
            }
        });
    };
    //code ends here for global user
    AppService.prototype.registerUserInformation = function (userInformation) {
        this.gUserInfo = userInformation;
        // create a UserProfile member variable and assign the UserObject to it.
    };
    AppService.prototype.processUserInformation = function (gUserInfo) {
        var _this = this;
        // this.profileLoaded.next(true);    
        if (!gUserInfo) {
            return;
        }
        this.setOktaProfile(gUserInfo);
        this.setGTMDataLayer(gUserInfo);
        // this.setFullStoryVariables(gUserInfo);
        //global user check
        this._userID = gUserInfo.sAMAccountName;
        this._userType = gUserInfo.userType;
        // document.cookie = cookieLoggedInUserId + "=" + this._userID + ";path=/"; // This cookie is required by legacy application      
        // document.cookie = cookieSIMID + "=9crp000105~^KC test user KC test user~*test@kcc.com;path=/";
        if (this.isGlobalUser && this.getCookie(cookieSIMID) == null) {
            // window.open(indexLink, '_self');
            this._router.navigate(['delegatedUserSearch'], { queryParams: { region: localStorage.getItem('default-region'), country: localStorage.getItem('country'), language: localStorage.getItem('country-lang') } });
        }
        //code ends here for global user
        this._HTTPService.getUserProfile(this.getuserId(false)).subscribe(function (data) {
            // if (data && data['Customer_Information'] && data['Customer_Information'].Status) {      
            if (!(data && data['defaultCustomerInfo'] && data['defaultCustomerInfo'].length)) {
                _this._HTTPService.getWelcomeFlag(_this.getuserId(false))
                    .subscribe(function (d) {
                    if (d.showWelcomeMessage === false) {
                        if (_this.isGlobalUser && _this.getCookie(cookieSIMID) == null) {
                            _this._router.navigate(['delegatedUserSearch'], { queryParams: { region: localStorage.getItem('default-region'), country: localStorage.getItem('country'), language: localStorage.getItem('country-lang') } });
                        }
                        else {
                            if (!_this.isInternalUser) {
                                _this._HTTPService.getLocation(_this.getuserId(false)).subscribe(function (data) {
                                    if (data.result.customerMapping.length == 0) {
                                        console.log("@@@Redirecting to Profile as no customer mapping and not defaultCustomerInfo");
                                        _this.globalAppService.setNoLocationsAvaiable(true);
                                    }
                                    _this._router.navigate(['profile'], { queryParams: { region: localStorage.getItem('default-region'), country: localStorage.getItem('country'), language: localStorage.getItem('country-lang') } });
                                    _this.globalAppService.setIsFirstTimeOnProfile(true);
                                    _this.isProfileSet = false;
                                    _this.redirectHome = false;
                                });
                            }
                            else {
                                _this._router.navigateByUrl('profile', { queryParamsHandling: 'preserve' });
                                _this.globalAppService.setIsFirstTimeOnProfile(true);
                                _this.isProfileSet = false;
                                _this.redirectHome = false;
                            }
                        }
                    }
                    else {
                        _this._router.navigateByUrl("welcome");
                        _this.redirectHome = false;
                        _this.isProfileSet = false;
                    }
                });
            }
            else {
                _this.globalAppService.setDefaultCustomerInfo(data['defaultCustomerInfo']);
                _this._HTTPService.getWelcomeFlag(_this.getuserId(false)).subscribe(function (d) {
                    _this.setuserProfileData(data);
                    _this.setCvtViewOkta(gUserInfo.userType, data);
                    if (d.showWelcomeMessage) {
                        _this.redirectHome = true;
                        _this._router.navigateByUrl("welcome");
                    }
                    else {
                        // if (this._router.url.indexOf('/pa/details') === -1 && this._router.url.indexOf('/dashboard/search') === -1)
                        if (window.location.href.indexOf('/pa/details') === -1 &&
                            window.location.href.indexOf('/chargeback/coupon/details') === -1 &&
                            window.location.href.indexOf('/delegatedUserSearch') === -1 &&
                            window.location.href.indexOf('/meetcomps/meetcompsDetails/details') === -1 &&
                            window.location.href.indexOf('/dashboard/search') === -1 &&
                            window.location.href.indexOf('/priceagreement/dashboard') === -1 &&
                            window.location.href.indexOf('/priceagreement/dashboard/mypafavorite') === -1 &&
                            window.location.href.indexOf('/priceagreement/dashboard/mysearches') === -1 &&
                            window.location.href.indexOf('/portalusage/dashboard') === -1 &&
                            window.location.href.indexOf('/uc-mapping/dashboard/search') === -1 &&
                            window.location.href.indexOf('/chargeback') === -1 &&
                            window.location.href.indexOf('/profile/myprofile') === -1 &&
                            window.location.href.indexOf('/meetcomps') === -1 &&
                            window.location.href.indexOf('/invoice') === -1 &&
                            window.location.href.indexOf('/invoice-new') === -1 &&
                            window.location.href.indexOf('/contactus') === -1 &&
                            window.location.href.indexOf('/recentDownload?') === -1 &&
                            window.location.href.indexOf('/manage-errors?') === -1 &&
                            window.location.href.indexOf('/cb-pricing-terms') === -1 &&
                            window.location.href.indexOf('/pricing-terms-conditions') === -1 &&
                            window.location.href.indexOf('/pricing/termsConditions') === -1 &&
                            window.location.href.indexOf('/reports') === -1) {
                            if (sessionStorage.getItem('recentDownloadParams')) {
                                var parsedParams = JSON.parse(sessionStorage.getItem('recentDownloadParams'));
                                _this._router.navigate(['priceagreement/dashboard/recentDownload'], { queryParams: { parsedParams: parsedParams, region: localStorage.getItem('default-region'), country: localStorage.getItem('country'), language: localStorage.getItem('country-lang') } });
                                sessionStorage.removeItem('recentDownloadParams');
                                sessionStorage.removeItem('recentDownloadUrl');
                            }
                            else if (localStorage.getItem("cb-pricing-terms-url")) {
                                var pricingTermURL = localStorage.getItem("cb-pricing-terms-url");
                                localStorage.removeItem('cb-pricing-terms-url');
                                var modifiedBaseUrl = APP_LEGACY_MENU_HOST.indexOf('http://localhost:34025') > -1 ? APP_LEGACY_MENU_HOST : APP_LEGACY_MENU_HOST;
                                window.open(modifiedBaseUrl + pricingTermURL, '_self');
                            }
                            else if (localStorage.getItem("pricing-terms-conditions-url")) {
                                var pricingTermURL = localStorage.getItem("pricing-terms-conditions-url");
                                localStorage.removeItem('pricing-terms-conditions-url');
                                var modifiedBaseUrl = APP_LEGACY_MENU_HOST.indexOf('http://localhost:34025') > -1 ? APP_LEGACY_MENU_HOST : APP_LEGACY_MENU_HOST;
                                window.open(modifiedBaseUrl + pricingTermURL, '_self');
                            }
                            else if (localStorage.getItem("reportid-from-link")) {
                                console.log("@@@@ Found the report id Navigate to home page");
                                _this._router.navigate(['home'], { queryParams: { region: localStorage.getItem('default-region'), country: localStorage.getItem('country'), language: localStorage.getItem('country-lang') } });
                            }
                            else if (localStorage.getItem('KCFileManageErrorDownloadUrl')) {
                                _this._router.navigate(['chargeback']);
                            }
                            else {
                                _this.isProfileSet = true; // Check With Sagar
                                var paDetailsURL = window.sessionStorage.getItem('paDetailsURL');
                                // console.log('paDetailsURL', paDetailsURL);
                                if (paDetailsURL) {
                                    window.sessionStorage.removeItem('paDetailsURL');
                                    // let modifiedBaseUrl = APP_LEGACY_MENU_HOST.indexOf('http://localhost:34025') > -1 ? APP_LEGACY_MENU_HOST : APP_LEGACY_MENU_HOST + '/SPA';
                                    var modifiedBaseUrl = APP_LEGACY_MENU_HOST.indexOf('http://localhost:34025') > -1 ? APP_LEGACY_MENU_HOST : APP_LEGACY_MENU_HOST;
                                    window.open(modifiedBaseUrl + paDetailsURL, '_self');
                                }
                                if (!_this.isGlobalUser && window.location.href.indexOf('/error') === -1) {
                                    _this._HTTPService.getCountrySalesOrgLanguage().subscribe(function (countriesLangResponse) {
                                        if (countriesLangResponse && countriesLangResponse.result && countriesLangResponse.result[0].country && countriesLangResponse.result[0].country.length) {
                                            var mappedCountries = countriesLangResponse.result[0].country;
                                            var userPreferedCountry_1 = _this.globalAppService.getUserDetails().preferredCountry;
                                            var isPreferredCountryMatched_1 = false;
                                            mappedCountries.forEach(function (country) {
                                                if (country.countryId == userPreferedCountry_1) {
                                                    isPreferredCountryMatched_1 = true;
                                                }
                                            });
                                            if (isPreferredCountryMatched_1) {
                                                // this._router.navigateByUrl('home');  
                                                if (window.location.href.indexOf('/register') === -1 && window.location.href.indexOf('/login') === -1) {
                                                    if (location.href.indexOf('/notifications') > -1) {
                                                        return;
                                                    }
                                                    _this._router.navigate(['/home'], {
                                                        relativeTo: _this.route,
                                                        queryParams: { region: localStorage.getItem('default-region'), country: localStorage.getItem('country'), language: localStorage.getItem('country-lang') },
                                                    });
                                                }
                                            }
                                            else {
                                                _this._router.navigate(['profile'], { queryParams: { region: localStorage.getItem('default-region'), country: localStorage.getItem('country'), language: localStorage.getItem('country-lang') } });
                                                _this.globalAppService.setIsFirstTimeOnProfile(true);
                                            }
                                        }
                                        else {
                                            _this.globalAppService.setNoLocationsAvaiable(true);
                                            _this._router.navigate(['profile'], { queryParams: { region: localStorage.getItem('default-region'), country: localStorage.getItem('country'), language: localStorage.getItem('country-lang') } });
                                            _this.globalAppService.setIsFirstTimeOnProfile(true);
                                        }
                                    });
                                }
                                else if (_this.isGlobalUser && _this.getCookie(cookieSIMID)) {
                                    if (window.location.href.indexOf('/register') === -1 && window.location.href.indexOf('/login') === -1) {
                                        _this._router.navigate(['home'], { queryParams: { region: localStorage.getItem('default-region'), country: localStorage.getItem('country'), language: localStorage.getItem('country-lang') } });
                                    }
                                }
                                else if (_this.isGlobalUser && !_this.getCookie(cookieSIMID)) {
                                    _this._router.navigate(['delegatedUserSearch'], { queryParams: { region: localStorage.getItem('default-region'), country: localStorage.getItem('country'), language: localStorage.getItem('country-lang') } });
                                }
                            }
                        }
                    }
                }, function (err) {
                    console.log("Error");
                });
            }
        }, function (err) {
            console.log("Error");
        });
    };
    AppService.prototype.navigateToLanding = function (OKTA_TOKEN) {
        var _this = this;
        // check if the global user profile is available if not then make API call.
        if (this.gUserInfo) {
            this.processUserInformation(this.gUserInfo);
        }
        else {
            this._HTTPService.getUserInfo(OKTA_TOKEN).subscribe(function (profile) {
                _this.gUserInfo = profile;
                _this.processUserInformation(_this.gUserInfo);
            });
        }
    };
    //  User Okta Information
    AppService.prototype.setOktaProfile = function (oktaProfile) {
        if (oktaProfile.userType) {
            this._userType = oktaProfile.userType;
        }
        if (oktaProfile.sAMAccountName) {
            this._userID = oktaProfile.sAMAccountName;
        }
        this._LoggedInUserProfile = oktaProfile;
        this._oktaProfileData.next(oktaProfile);
    };
    Object.defineProperty(AppService.prototype, "AvailableOKtaProfile", {
        get: function () {
            return this._LoggedInUserProfile;
        },
        enumerable: true,
        configurable: true
    });
    AppService.prototype.getOktaProfile = function () {
        return this._oktaProfileData;
    };
    AppService.prototype.getuserId = function (force) {
        if (force === void 0) { force = false; }
        if (force) {
            return this._userID;
        }
        else {
            if (this.getCookie(cookieSIMID) && this.getCookie(cookieSIMID).length > 0) {
                var userID = this.getCookie(cookieSIMID).substring(0, this.getCookie(cookieSIMID).indexOf('~^'));
                var userEmail = this.getCookie(cookieSIMID).substring(this.getCookie(cookieSIMID).indexOf('~*') + 2, this.getCookie(cookieSIMID).indexOf('~&'));
                return userID;
            }
            else {
                return this._userID;
            }
        }
    };
    AppService.prototype.getuserEmail = function () {
        if (this.getCookie(cookieSIMID) && this.getCookie(cookieSIMID).length > 0) {
            var userEmail = this.getCookie(cookieSIMID).substring(this.getCookie(cookieSIMID).indexOf('~*') + 2, this.getCookie(cookieSIMID).indexOf('~&'));
            return userEmail;
        }
    };
    AppService.prototype.getuserPhone = function () {
        if (this.getCookie(cookieSIMID) && this.getCookie(cookieSIMID).length > 0) {
            var userPhone = this.getCookie(cookieSIMID).substring(this.getCookie(cookieSIMID).indexOf('~&') + 2);
            return userPhone;
        }
    };
    Object.defineProperty(AppService.prototype, "isGlobalUser", {
        get: function () {
            var _this = this;
            var isGlobalRole = false;
            var region = this.globalAppService.getDefaultRegionString();
            var appRolesList = this.globalAppService.getApprolesArray();
            if (region) {
                globalUser[region.toLocaleUpperCase()].forEach(function (element) {
                    if (appRolesList && appRolesList.indexOf(element) > -1 && _this.getCookie(cookieSIMID)) {
                        isGlobalRole = true;
                    }
                });
            }
            return isGlobalRole;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppService.prototype, "isGlobalRoleAssigned", {
        get: function () {
            var isGlobalRolePresent = false;
            var region = this.globalAppService.getDefaultRegionString();
            var appRolesList = this.globalAppService.getApprolesArray();
            if (region) {
                globalUser[region.toLocaleUpperCase()].forEach(function (element) {
                    if (appRolesList && appRolesList.indexOf(element) > -1) {
                        isGlobalRolePresent = true;
                    }
                });
            }
            return isGlobalRolePresent;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppService.prototype, "isInternalUser", {
        get: function () {
            if (this.isGlobalUser && this.getCookie(cookieSIMID)) {
                return false;
            }
            if (this._userType == "C" || this._userType == "E") {
                return true;
            }
            else {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    AppService.prototype.setLoadingStatus = function (loadingStatus) {
        this._isLoading.next(loadingStatus);
    };
    AppService.prototype.getLoadingStatus = function () {
        return this._isLoading;
    };
    Object.defineProperty(AppService.prototype, "loadedHomeModal", {
        get: function () {
            return this._loadedHomeModal;
        },
        set: function (loaded) {
            this._loadedHomeModal = loaded;
        },
        enumerable: true,
        configurable: true
    });
    AppService.prototype.getCookie = function (name) {
        var dc = document.cookie;
        var prefix = name + "=";
        var begin = dc.indexOf("; " + prefix);
        if (begin == -1) {
            begin = dc.indexOf(prefix);
            if (begin != 0)
                return null;
        }
        else {
            begin += 2;
            var end = document.cookie.indexOf(";", begin);
            if (end == -1) {
                end = dc.length;
            }
        }
        // because unescape has been deprecated, replaced with decodeURI
        //return unescape(dc.substring(begin + prefix.length, end));
        return decodeURI(dc.substring(begin + prefix.length, end));
    };
    AppService.prototype.getRedirectHome = function () {
        return this.redirectHome;
    };
    AppService.prototype.getActiveSubNav = function () {
        return this.activesubNav;
    };
    AppService.prototype.updateNavStatus = function (updateSubanv) {
        this.activesubNav.next(updateSubanv);
    };
    AppService.prototype.getActiveTile = function () {
        return this.activeTile;
    };
    AppService.prototype.updateTile = function (updateTile) {
        this.activeTile.next(updateTile);
    };
    AppService.prototype.setModalData = function (updateData) {
        this._SearchSelectedModalData.next(updateData);
    };
    AppService.prototype.getModalData = function () {
        return this._SearchSelectedModalData;
    };
    AppService.prototype.setCvtViewOkta = function (userType, data) {
        // let arraymap = data['User_Favorate'];
        // let filteritem = arraymap.find((item) => {
        //   return (item.Fav_Type_Id == '200' && item.SalesOrg == '2811');
        // })
        // if (filteritem && userType == "C" || userType == "E") {
        // this.cvtView = (filteritem && filteritem.Favorite_Value && filteritem.Favorite_Value === '2' ? false : true);
        // }
        // else {
        //   this.cvtView = false;
        // }
        //set Favorite marked contract for the user
        this.cvtView = false;
        this.setFavoritePA();
        if (location.href && location.href.indexOf("/pa/details") > -1) {
            if (location.href.indexOf("/CVTYes") > -1 && this.isInternalUser) {
                if (this.globalAppService.getDefaultRegionString().toUpperCase() == 'LAO')
                    this.setCVTView(false);
                else
                    this.setCVTView(true);
            }
            else {
                this.setCVTView(false);
            }
        }
        else {
            this.setCVTView(this.cvtView);
        }
    };
    AppService.prototype.isCVTView = function () {
        return this.cvtView;
    };
    AppService.prototype.isCVTViewSubject = function () {
        return this.cvtViewSubject.asObservable();
    };
    AppService.prototype.setCVTView = function (status) {
        if (this.isGlobalUser) {
            status = false;
        }
        this.cvtViewSubject.next(status);
        this.cvtView = status;
    };
    AppService.prototype.forceDownloadCSV = function (recentlyCHangedDownload) {
        this.recentlyChangedDownload = recentlyCHangedDownload;
    };
    AppService.prototype.isForceDownloadCSV = function () {
        return this.recentlyChangedDownload;
    };
    AppService.prototype.oktaAuthRenewToken = function (token) {
        this._HTTPService.oktaAuthRenew(token).subscribe(function (profile) {
            // console.log("%%%%%%%%%%%%%okta renew token%%%%%%%%%%%%%%%%%%%%%", profile)
        });
    };
    //to dynamically import path for template override to show/hide sections in component
    AppService.prototype.dynamicImport = function (region) {
        switch (region) {
            case 'NA':
                return import('@app/conf/component-overrides/regions/NA/override.ts');
            case 'LAO':
                return import('@app/conf/component-overrides/regions/LAO/override.ts');
            case 'APAC':
                return import('@app/conf/component-overrides/regions/APAC/override.ts');
            case 'EMEA':
                return import('@app/conf/component-overrides/regions/EMEA/override.ts');
        }
    };
    //Added for chargeback
    AppService.prototype.dynamicImportColumnDefCB = function () {
        return import('@app/conf/agColumnsConfigCB.ts');
    };
    //Added for chargeback---end
    //Added for MeetComp
    AppService.prototype.dynamicImportColumnDefMC = function () {
        return import('@app/conf/col-def-meetcomp/agColumnsConfigMCNA.ts');
    };
    //Added for MeetComp---end
    AppService.prototype.setDisableLink = function (value) {
        this._disableLink.next(value);
    };
    AppService.prototype.getDisableLink = function () {
        return this._disableLink.asObservable();
    };
    AppService.prototype.getUserProfileStatus = function () {
        return this.isProfileSet;
    };
    AppService.prototype.isExternalAdminUser = function () {
        return this.isExternalAdminUserVar;
    };
    AppService.prototype.setExternalAdminUser = function (value) {
        this.isExternalAdminUserVar = value;
    };
    AppService.prototype.isIEBrowser = function () {
        var ua = window.navigator.userAgent;
        var msie = ua.indexOf("MSIE ");
        if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) // If Internet Explorer, return true
         {
            return true;
        }
        else // If another browser, return false
         {
            return false;
        }
    };
    AppService.prototype.getDateForRequestParam = function (dateValue) {
        var formattedDateValue = "";
        if (dateValue) {
            if (typeof dateValue === 'object') {
                formattedDateValue = moment(dateValue).format("MM/DD/yyyy");
            }
            else if (typeof dateValue === 'string' && dateValue.length > 10) {
                formattedDateValue = moment(dateValue.substring(0, 10), 'yyyy-MM-DD').format("MM/DD/yyyy");
            }
            else if (typeof dateValue === 'string' && dateValue.length === 10) {
                formattedDateValue = moment(dateValue.substring(0, 10), 'MM/DD/yyyy').format("MM/DD/yyyy");
            }
        }
        return formattedDateValue;
    };
    AppService.prototype.setTimeoutLoaderHide = function (value) {
        this._timeOutLoaderHide.next(value);
    };
    AppService.prototype.gettTimeoutLoaderHide = function () {
        return this._timeOutLoaderHide.asObservable();
    };
    AppService.prototype.setMenu = function () {
        var _this = this;
        this.globalAppService.getDefaultRegion().subscribe(function (region) {
            if (_this.isInternalUser) {
                if (_this.cvtView) {
                    _this.menu.next(ribbonMenuInternalCVT[region]);
                }
                else {
                    _this.menu.next(ribbonMenuInternalCustomer[region]);
                }
            }
            else if (_this.isGlobalUser) {
                _this.menu.next(ribbonMenuGlobal[region]);
            }
            else {
                _this.menu.next(ribbonMenuExternal[region]);
            }
            return _this.menu;
        });
    };
    AppService.prototype.getMenu = function () {
        return this.menu;
    };
    AppService.prototype.setGTMDataLayer = function (oktaProfile) {
        var _this = this;
        this.languageSelected.currentSelected.subscribe(function (isUserLoggedIn) {
            _this._dataLayerService.dimensions = {
                'event': !_this.getCookie(cookieLoggedInUserId) ? 'login' : 'dayalayer-fill',
                'userId': _this.getuserId(false),
                'user_type': _this.isInternalUser ? 'internal' : 'external',
                'page_language': isUserLoggedIn.regionCode
            };
            _this._dataLayerService.trigger();
        });
    };
    AppService.prototype.setFullStoryVariables = function (oktaProfile) {
        var _this = this;
        this.languageSelected.currentSelected.subscribe(function (isUserLoggedIn) {
            var userId = _this.getuserId(false);
            _this._fullStoryService.identify = userId;
            _this._fullStoryService.dimensions = {
                'displayName': userId,
                'userType_str': _this.isInternalUser ? 'internal' : 'external',
                'pageLanguage_str': isUserLoggedIn.regionCode
            };
            _this._fullStoryService.trigger();
        });
    };
    AppService.prototype.setCountryURL = function (countryCode) {
        var obj = countryOBJ.find(function (item) {
            return item.countryID === countryCode.toLowerCase();
        });
        return obj ? obj.url : countryOBJ[0].url;
    };
    AppService.prototype.appendQueryParamToLegacyUrl = function (url) {
        var queryParam;
        var savedStatus = window.localStorage.getItem('profileSavedStatus');
        if (savedStatus) {
            queryParam = 'RefreshProfile=1';
        }
        else {
            queryParam = 'RefreshProfile=0';
        }
        var separator = (url.indexOf("?") === -1) ? "?" : "&";
        var returnUrl = url.trim() + separator + queryParam;
        return returnUrl;
    };
    AppService.prototype.setPopUpSequenceStatus = function (status) {
        this.popUpStatusSubject.next(status);
    };
    AppService.prototype.getPopUpSequenceStatus = function () {
        return this.popUpStatusSubject.asObservable();
    };
    AppService.prototype.setPopupSequenceNumber = function (num) {
        this.popupSequenceNumber = num.slice();
    };
    AppService.prototype.getPopupSequenceNumber = function () {
        this.popupSequenceNumberIndex++;
        if (this.popupSequenceNumber[this.popupSequenceNumberIndex]) {
            return this.popupSequenceNumber[this.popupSequenceNumberIndex];
        }
        else {
            return null;
        }
    };
    Object.defineProperty(AppService.prototype, "appLoadingStatus", {
        get: function () {
            return this.appLoaded;
        },
        set: function (status) {
            this.appLoaded = status;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppService.prototype, "CBAccetancePolicyLoadingStatus", {
        get: function () {
            return this.CBAcceptancePolicyLoaded;
        },
        set: function (status) {
            this.CBAcceptancePolicyLoaded = status;
        },
        enumerable: true,
        configurable: true
    });
    AppService.ngInjectableDef = i0.defineInjectable({ factory: function AppService_Factory() { return new AppService(i0.inject(i1.HttpService), i0.inject(i2.Router), i0.inject(i2.ActivatedRoute), i0.inject(i3.GlobalAppService), i0.inject(i4.LanguageSelectionService), i0.inject(i5.DataLayerService), i0.inject(i6.FullStoryService)); }, token: AppService, providedIn: "root" });
    return AppService;
}());
export { AppService };
