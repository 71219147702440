import {
  Component, OnDestroy, ChangeDetectorRef,
  ChangeDetectionStrategy,
  ViewRef,
} from '@angular/core';
import { pastatus, patype, customerTypeFilter, euStatus, recentDownloadStatus, endUserAdminPAType, patypecustomer, kcpUpdates, endUserComments, customerSoldToComments, customerType, cbStatus, cbRvRb, cbClaimRequestType, cbDiscrepancies, cbEfileType, cbEfileStatusForActive, cbEfileStatusForTest, distinctValueFilterSet, customerTypeEMEAandLAO, moduleType, cbClaimRequestTypeAPAC } from '@app/conf/dataConfigurations';
import {
  IAfterGuiAttachedParams,
  IDoesFilterPassParams,
  IFilterParams,
  RowNode,
} from 'ag-grid-community';

import { IFilterAngularComp } from 'ag-grid-angular';
import { CpAgGridService } from '@app/Modules/cp-grid/services/cp-ag-grid-service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { LocationLanguageService } from '@app/Modules/Shared/Services/location-language.service';
import { GlobalAppService } from '@app/Modules/Shared/Services/global-app.service';
import { TranslateService } from '@ngx-translate/core';

@AutoUnsubscribe()
@Component({
  selector: 'multi-select-filter',
  templateUrl: './multi-select-filter.component.html',
  styleUrls: ['./multi-select-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiSelectFilterComponent implements IFilterAngularComp, OnDestroy {

  public params: IFilterParams;
  public valueGetter: (rowNode: RowNode) => any;
  public text: string = '';
  public colId: string;
  public filters: Array<any> = [];
  public selectedFilters: Array<any>;

  unAppliedfilters: Array<any> = [];
  
  resultSetData: Array<any>;
  filterModel: any = { type: "multi-select", value: this.filters };
  isSelected: boolean;
  headerName: string;
  guiParams: any;
  subscription: Subscription = new Subscription();
  fullFilterList: any[] = [];
  orgColId: string;
  hideSort: boolean;
  SelectAllValue: boolean;
  country: Array<any> = [];
  states: Array<any> = [];
  loaded: boolean = false;
  isThisDistinctFilter = false;
  region: string;
  constructor(private gridService: CpAgGridService,
    private appService: AppService,
    private cd: ChangeDetectorRef,
    private locationLanguageService: LocationLanguageService,
    private _globalAppService: GlobalAppService, private translateService: TranslateService) {
  }

  agInit(params: IFilterParams): void {
    this.params = params;
    this.orgColId = params.column.getColId();
    this.colId = params.colDef.field;
    this.colId = this.colId.indexOf('_') !== -1 ? this.colId.substring(0, this.colId.indexOf('_')) : this.colId;
    this.headerName = params.colDef.headerName;
    this.valueGetter = params.valueGetter;
    this.hideSortOption();
    this.populateValues();
    this.populateValuesDistinct();
    this.isSelected = false;
    // this.selectAll();
    this.setSearchObjectListener();
    this.params.api.setPopupParent(document.querySelector('body'));
    this.loaded = true;
    this.region = this._globalAppService.getDefaultRegionString();
    this.subscription.add(this.gridService.getColumnMultiselectFilter().subscribe(data => {
      this.populateValuesDistinct();
    }));
  }

  populateValuesDistinct() {
    let filterField: Array<any>;
    // NP To Do: Add any field name here which needs distinct multi-select filter values    
    switch (this.colId) {
      case 'rvRb':
      case 'currency':
      case 'claimRequestType':
      case 'discrepancies':
      case 'refreshStatus':
      case 'unclaimedMonth':
        filterField = this.setDistinctFilterOptions();
        break;
      case 'status':
        if (this.gridService.pageContext.pageName == 'searchResultsCB' ||
          this.gridService.pageContext.pageName == 'recentActivity' ||
          this.gridService.pageContext.pageName == 'issuedTileGrid' ||
          this.gridService.pageContext.pageName == 'deniedTileGrid' ||
          this.gridService.pageContext.pageName == 'efileSearchResult' ||
          this.gridService.pageContext.pageName == 'mcSearchResult' ||
          this.gridService.pageContext.pageName == 'pendingTileGrid' ||
          this.gridService.pageContext.pageName == 'kcfileSearchResult') {
          filterField = this.setDistinctFilterOptions();
        }
        break;
      case 'type':
        if (this.headerName === 'agGrid.agGridColumns.typeLabel' && this.gridService.pageContext.pageName == 'efileSearchResult') {
          filterField = this.setDistinctFilterOptions();
        }
        break;
      case 'state':
        filterField = this.setDistinctFilterOptions();
        break;
      case 'category':
        filterField = this.setDistinctFilterOptions();
        break;
      case 'row':
        filterField = this.setDistinctFilterOptions();
        break;
      case 'access':
        filterField = this.setDistinctFilterOptions();
        break;
      case 'claimPeriodMonthAndYear':
        filterField = this.setDistinctFilterOptions();
        break;
      case 'country':
        if (this.gridService.pageContext.pageName == 'invoiceSearchResult' ||
            this.gridService.pageContext.pageName == 'invoiceFavourite' ||
            this.gridService.pageContext.pageName == 'recentRequests' ||
            this.gridService.pageContext.pageName == 'unclaimedTileGrid'){
          filterField = this.setDistinctFilterOptions();
        }        
        break;
    }
    if (filterField) {
      this.filters = [];
      this.SelectAllValue = true;
      filterField.forEach((item, index) => {
        let isSel = false;
        let currentfilter = this.fullFilterList.find((el) => { return el.value == item.value })
        if (currentfilter) {
          isSel = currentfilter.selected
        }
        this.filters.push({ text: item.label, value: item.value, selected: isSel });
        if (!isSel) {
          this.SelectAllValue = false;
        }
      });
      this.fullFilterList = this.filters.map(f => { return f; });
    }
    this.cd.detectChanges();
  }
  populateValues() {
    let filterField: Array<any>;
    // TK To Do: Add any field name here which needs multi-select filter values    
    switch (this.colId) {
      case 'typedescription':
      case 'typeName':
      case 'contractSubTypeName':
      case 'type':
        if (this.headerName === 'agGrid.agGridColumns.typeLabel' && this.gridService.pageContext.pageName == 'efileSearchResult') {
          return;
        }
        if (this.headerName === 'agGrid.agGridColumns.euTypeLabel') {
          filterField = endUserAdminPAType;
        } else {
          if (this._globalAppService.getDefaultRegionString() === 'LAO') {
            // let paType = this.appService.getPAtype();
            // let paTypeArray = [];
            // for (let [key, value] of Object.entries(paType)) {
            //   paTypeArray.push({
            //     'label': `${value}`,
            //     'value': `${key}`
            //   })
            // }
            // filterField = paTypeArray;
            if (!this.loaded) {
              let paType = this.appService.getPAtype();
              let paTypeArray = [];
              for (let [key, value] of Object.entries(paType)) {
                paTypeArray.push({
                  'label': `${value}`,
                  'value': `${key}`
                })
              }
              filterField = paTypeArray;
              let me = this;
              this.appService.getPAtypeObservable().subscribe(paType => {
                if (paType) {
                  let paTypeArray = [];
                  for (let [key, value] of Object.entries(paType)) {
                    paTypeArray.push({
                      'label': `${value}`,
                      'value': `${key}`
                    })
                  }
                  filterField = paTypeArray;
                  if (filterField) {
                    this.filters = [...filterField].map(f => { return { text: f.label, value: f.value } });
                    this.fullFilterList = this.filters.map(f => { return f; });
                    if (me.cd && !(me.cd as ViewRef).destroyed) {
                      me.cd.detectChanges();
                    }
                  }
                }
              });
            }
            else {
              let me = this;
              this.appService.getPAtypeObservable().subscribe(paType => {
                if (paType) {
                  let paTypeArray = [];
                  for (let [key, value] of Object.entries(paType)) {
                    paTypeArray.push({
                      'label': `${value}`,
                      'value': `${key}`
                    })
                  }
                  filterField = paTypeArray;
                  if (filterField) {
                    this.filters = [...filterField].map(f => { return { text: f.label, value: f.value } });
                    this.fullFilterList = this.filters.map(f => { return f; });
                    if (me.cd && !(me.cd as ViewRef).destroyed) {
                      me.cd.detectChanges();
                    }
                  }
                }
              });
            }
          }
          else {
            filterField = this._globalAppService.isCVTView() ? patype : patypecustomer;
          }
        }
        // else{
        //   filterField = this.appService.isCVTView() ? patype : patypecustomer;
        // }
        break;
      case 'salesorgName':
        if (!this.loaded) {
          let salesOrgNames = this.appService.getSalesOrgNames();
          let salesOrgNameArray = [];
          for (let index = 0; index < salesOrgNames.length; index++) {
            salesOrgNameArray.push({ label: salesOrgNames[index].salesOrgDescription, value: salesOrgNames[index].salesOrg });
          }
          filterField = salesOrgNameArray;
          let me = this;
          this.appService.getSalesOrgNamesObservable().subscribe(salesOrgNames => {
            if (salesOrgNames) {
              let salesOrgNameArray = [];
              for (let index = 0; index < salesOrgNames.length; index++) {
                salesOrgNameArray.push({ label: salesOrgNames[index].salesOrgDescription, value: salesOrgNames[index].salesOrg });
              }
              filterField = salesOrgNameArray;
              if (filterField) {
                this.filters = [...filterField].map(f => { return { text: f.label, value: f.value } });
                this.fullFilterList = this.filters.map(f => { return f; });
                if (me.cd && !(me.cd as ViewRef).destroyed) {
                  me.cd.detectChanges();
                }
              }
            }
          });
        }
        else {
          let me = this;
          this.appService.getSalesOrgNamesObservable().subscribe(salesOrgNames => {
            if (salesOrgNames) {
              let salesOrgNameArray = [];
              for (let index = 0; index < salesOrgNames.length; index++) {
                salesOrgNameArray.push({ label: salesOrgNames[index].salesOrgDescription, value: salesOrgNames[index].salesOrg });
              }
              filterField = salesOrgNameArray;
              if (filterField) {
                this.filters = [...filterField].map(f => { return { text: f.label, value: f.value } });
                this.fullFilterList = this.filters.map(f => { return f; });
                if (me.cd && !(me.cd as ViewRef).destroyed) {
                  me.cd.detectChanges();
                }
              }
            }
          });
        }
        break;
      case 'kcOwner':
        if (!this.loaded) {
          let KCOwnerNames = this.appService.getKCOwnerNames();
          let KCOwnerNamesArray = [];
          for (let index = 0; index < KCOwnerNames.length; index++) {
            KCOwnerNamesArray.push({ label: KCOwnerNames[index].ownerDescription, value: KCOwnerNames[index].ownerId });
          }
          filterField = KCOwnerNamesArray;
          let me = this;
          this.appService.getKCOwnerNamesObservable().subscribe(KCOwnerNames => {
            if (KCOwnerNames) {
              let KCOwnerNamesArray = [];
              for (let index = 0; index < KCOwnerNames.length; index++) {
                KCOwnerNamesArray.push({ label: KCOwnerNames[index].ownerDescription, value: KCOwnerNames[index].ownerId });
              }
              filterField = KCOwnerNamesArray;
              if (filterField) {
                this.filters = [...filterField].map(f => { return { text: f.label, value: f.value } });
                this.fullFilterList = this.filters.map(f => { return f; });
                if (me.cd && !(me.cd as ViewRef).destroyed) {
                  me.cd.detectChanges();
                }
              }
            }
          });
        }
        else {
          let me = this;
          this.appService.getKCOwnerNamesObservable().subscribe(KCOwnerNames => {
            if (KCOwnerNames) {
              let KCOwnerNamesArray = [];
              for (let index = 0; index < KCOwnerNames.length; index++) {
                KCOwnerNamesArray.push({ label: KCOwnerNames[index].ownerDescription, value: KCOwnerNames[index].ownerId });
              }
              filterField = KCOwnerNamesArray;
              if (filterField) {
                this.filters = [...filterField].map(f => { return { text: f.label, value: f.value } });
                this.fullFilterList = this.filters.map(f => { return f; });
                if (me.cd && !(me.cd as ViewRef).destroyed) {
                  me.cd.detectChanges();
                }
              }
            }
          });
        }
        break;
      case 'customerType':
        if ((this._globalAppService.getDefaultRegionString() === 'EMEA' || this._globalAppService.getDefaultRegionString() === 'LAO' || this._globalAppService.getDefaultRegionString() === 'APAC') && (this.gridService.pageContext.pageName == 'ucMappingCustomerSearch' || this.gridService.pageContext.pageName == 'customerSearchModal')) {
          filterField = customerTypeEMEAandLAO;
        } else {
          filterField = customerTypeFilter;
        }
        break;
      case 'moduleType':
        filterField = moduleType;
        break;
      case 'statusText':
      case 'status':
        if (this.gridService.pageContext.pageName == 'recentDownload') {
          filterField = recentDownloadStatus;
        }
        else if (this.gridService.pageContext.pageName == 'efileSearchResult') {
          filterField = [...cbEfileStatusForActive, ...cbEfileStatusForTest];
        }
        else {
          if (this.headerName === 'agGrid.agGridColumns.euStatusLabel') {
            filterField = euStatus;
          }
          else {
            filterField = pastatus;
          }
        }
        break;
      case 'StatusDesc':
        filterField = recentDownloadStatus;
        break;
      case 'comments':
      case 'comment':
      case 'CommentsDescription':
        if (this.headerName === 'agGrid.agGridColumns.comments' || this.headerName === 'agGrid.agGridColumns.changeLabel') {
          filterField = this.mapComments(kcpUpdates);
        }
        else if (this.headerName === 'agGrid.agGridColumns.paDetailEuComments') {
          filterField = this.mapComments(endUserComments);
        }
        else if (this.headerName === 'agGrid.agGridColumns.soldToComment') {
          filterField = this.mapSoldToComments(customerSoldToComments);
        }
        break;
      case 'country':
        if(this.orgColId != 'country_invoice' && this.orgColId != 'country_unclaimedTile'){
          let country = this.gridService.getCountries();
          country.forEach((item) => {
            this.country.push({ 'label': item.label, 'value': item.value });
          });
          filterField = this.country;
        }
        break;
      case 'state':
        if (this.orgColId == "state_Mc") {
          return;
        }
        this.gridService.getStateList().subscribe(states => {
          if (states) {
            filterField = [];
            this.states = states;
            filterField = this.states;
            if (filterField) {
              // filterField.forEach((item, index) => {
              //   if (this.filters.map(filter => { return filter.value }).indexOf(item.value) === -1) {
              //     this.filters.push({ text: item.label, value: item.value, selected: false });
              //   }
              // });
              this.filters = [...filterField].map(f => { return { text: f.label, value: f.value } });

              this.fullFilterList = this.filters.map(f => { return f; });
              if (this.cd && !(this.cd as ViewRef).destroyed) {
                this.cd.detectChanges();
              }
            }
          }
        });
        break;
      case 'customerType':
        if (this.headerName === 'agGrid.agGridColumns.customerTypeLabel') {
          filterField = customerType;
        }
        break;
    }
    if (filterField) {
      filterField.forEach((item, index) => {
        if (this.filters.map(filter => { return filter.value }).indexOf(item.value) === -1) {
          this.filters.push({ text: item.label, value: item.value, selected: false });
        }
      });
      this.fullFilterList = this.filters.map(f => { return f; });
    }
  }

  setDistinctFilterOptions() {
    let filterField = [];
    let filterArr = this.gridService.getMultiSelectDistinctFilter()[this.gridService.getGridName()][this.colId];
    if(filterArr){
      filterArr = filterArr.map(el => { return (el).toString().trim() });
      if(this.region == "APAC"){
        distinctValueFilterSet["claimRequestType"] = cbClaimRequestTypeAPAC;
      }
      let configDataArr: Array<any> = distinctValueFilterSet[this.orgColId];
      if (configDataArr && configDataArr.length) {
        filterField = configDataArr.filter((el) => {
          return filterArr.indexOf(el.value) >= 0
        });
      } else {
        filterField = filterArr.map((item) => { return { label: item, value: item } });
      }
    }
    this.isThisDistinctFilter = true;
    return filterField;
  }
  getModel(): any {
    this.prestineFilter();
    this.filterModel.value = this.filters;
    return this.filterModel;
  }

  setModel(model: any): void {
    if (model && model.value) {
      this.filterModel = new Object();
      this.filterModel.type = "multi-select";
      this.filterModel.value = this.filters;
    }
    else {
      this.filters = this.filters.map(f => { f.selected = false; return f; })
      this.isSelected = false;
      if (this.cd && !(this.cd as ViewRef).destroyed) {
        this.cd.detectChanges();
      }
    }
  }

  hideSortOption() {
    if (this.colId === 'status') {
      switch (this.gridService.pageContext.pageName) {
        case 'searchResults':
        case 'activeFuture':
        case 'recentlyExpired':
          this.hideSort = true;
          break;
      }
    }
  }

  mapComments(comments: Array<any>): Array<any> {
    return comments.map(c => {
      let commentField: any = Object.assign({}, c);
      commentField.label = c.value;
      commentField.value = c.changeReason;
      delete commentField.changeReason;
      return commentField;
    });
  }

  mapSoldToComments(comments: Array<any>): Array<any> {
    return comments.map(c => {
      let commentField: any = Object.assign({}, c);
      commentField.label = c.value;
      commentField.value = c.changeReason;
      delete commentField.changeReason;
      return commentField;
    });
  }

  setSearchObjectListener() {
    let me = this;
    this.subscription.add(this.appService.getSearchRequest().subscribe((searchObject) => {
      if (searchObject && searchObject.query) {
        // below code is to hide other type option in list if top one is selected(implemented in PA)
        if (this.colId === 'typedescription' || this.colId === 'typeName') {
          if (searchObject.query.contractType && searchObject.query.contractType.length) {
            this.fullFilterList.forEach(f => {
              let contractTypeList: Array<any> = searchObject.query.contractType.filter(ct => { return ct === f.value });
              if (contractTypeList && contractTypeList.length)
                f.selected = true;
              else
                f.selected = false;
            });
            this.filters = this.fullFilterList.filter(f => {
              return f.selected;
            }).map(f => { f.selected = false; return f; });
          }
          else {
            this.fullFilterList.forEach(f => {
              f.selected = false;
            });
            this.filters = this.fullFilterList.map(f => { return f; });
          }
        }

        // below code is to hide other status option in list if top one is selected(implemented in PA) 
        else if (this.colId === 'status') {
          if (this.gridService.pageContext.pageName == 'efileSearchResult' || this.gridService.pageContext.pageName == 'searchResults') {
            this.fullFilterList.forEach(f => {
              f.selected = false;
            });
            this.filters = this.fullFilterList.map(f => { return f; });
          }
          else {
            if (searchObject.query.status && searchObject.query.status.length) {
              this.fullFilterList.forEach(f => {
                let statusList: Array<any> = searchObject.query.status.filter(s => { return s == f.value });
                if (statusList && statusList.length)
                  f.selected = true;
                else
                  f.selected = false;
              })
              this.filters = this.fullFilterList.filter(f => {
                return f.selected;
              }).map(f => { f.selected = false; return f; });
            }
            else {
              this.fullFilterList.forEach(f => {
                f.selected = false;
              });
              this.filters = this.fullFilterList.map(f => { return f; });
            }
          }
        }
      }
    }));
  }

  isFilterActive(): boolean {
    // return !this.filters.every(f => { return f.selected });
    return this.filters.some(f => { return f.selected });
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    if (this.selectedFilters && this.selectedFilters.length) {
      return this.selectedFilters.some(filter => {
        return (
          this.valueGetter(params.node)
            .toString()
            .toLowerCase() === filter.text.toLowerCase()
        );
      });
    } else {
      return true;
    }
  }

  ngAfterViewInit(params: IAfterGuiAttachedParams): void {
  }

  // noinspection JSMethodCanBeStatic
  componentMethod(message: string): void {
    // alert(`Alert from MultiSelectFilterComponent ${message}`);
  }

  onClick(event, selectedFilter): void {

    if(this.unAppliedfilters.length==0)
    {
      this.unAppliedfilters.push(selectedFilter)
    }
    else
    {
      const Pos = this.unAppliedfilters.findIndex((item)=>{
        return item.value === selectedFilter.value;
      });

      if(Pos>-1)
      {
        //this.unAppliedfilters.splice(Pos,1);
        this.unAppliedfilters[Pos].selected=event.target.checked;
      }
      else{
        this.unAppliedfilters.push(selectedFilter)
      }
    }
    
    this.selectedFilters = this.filters.filter(filter => {
      return filter.selected;
    });

    if (this.selectedFilters && this.selectedFilters.length > 0)
      this.isSelected = true;
    else
      this.isSelected = false;

    this.SelectAllValue = this.selectedFilters ? this.selectedFilters.length === this.filters.length : false;
    if (this.cd && !(this.cd as ViewRef).destroyed) {
      this.cd.detectChanges();
    }
  }

  onApplyClick(event) {
    if (this.isSelected) {
      this.unAppliedfilters=[];
      this.params.filterChangedCallback();
      this.guiParams.hidePopup();
    }
  }

  afterGuiAttached(params) {
    this.guiParams = params;
  }

  onSortRequested(isSortedRequest) {
    if (!!isSortedRequest) {
      this.guiParams.hidePopup();
    }
  }

  prestineFilter()
  {
    let gridColumn=null;
    if(this.unAppliedfilters.length>0)
    {
      if(this.unAppliedfilters.length === this.filters.length)
      {
        this.SelectAllValue=false;
      }

      if(this.gridService.allFilters)
      {
          gridColumn  =  this.gridService.allFilters.find((col)=>{
          return col.key === this.colId;
        });
      }

      this.filters.map((filterItem)=>{
        const Pos = this.unAppliedfilters.findIndex((unAppliedItem)=>{
          return filterItem.value ===unAppliedItem.value;
        })
        if(Pos>-1)
        {
          if(this.gridService.allFilters && gridColumn)
          { 
            // if filter are pre applied
            const appliedFilterPos = gridColumn.text.findIndex((appliedFilterItem)=>{
              return appliedFilterItem ==filterItem.text;
            })
            if(appliedFilterPos>-1)
            {
              filterItem.selected = true;
            }
            else
            {
              filterItem.selected = false;
            }
          }
          else
          {
            filterItem.selected =false; // if no filter are pre applied
          }
          
        }
      })
      this.unAppliedfilters=[];
    }
    if (this.cd && !(this.cd as ViewRef).destroyed) {
      this.cd.detectChanges();
    }
  }

  selectAllChange(event) {

    this.unAppliedfilters =[];
    
    this.selectedFilters = this.filters.map(filter => {
      if(this.SelectAllValue)
      {
        this.unAppliedfilters.push(filter);
      }
      return filter.selected = this.SelectAllValue;
    });
    this.isSelected = this.SelectAllValue;
    if (this.cd && !(this.cd as ViewRef).destroyed) {
      this.cd.detectChanges();
    }
  }

  onFilter(event) {
    if (this.text) {
      this.filters = this.fullFilterList.filter((f) => {
        return f.text.toLowerCase().indexOf(this.text.toLowerCase()) !== -1;
      })
    }
    else {
      this.filters = [...this.fullFilterList];
    }
    let selectedFilters = this.filters.filter(sf => { return sf.selected });
    this.SelectAllValue = selectedFilters ? selectedFilters.length === this.filters.length : false;
    if (this.cd && !(this.cd as ViewRef).destroyed) {
      this.cd.detectChanges();
    }
  }

  onRemoveFilter() {
    this.unAppliedfilters = [];
    this.isSelected = false;
    this.SelectAllValue = false;
    this.text = "";
    this.onFilter(event)
    this.gridService.removeFilters(this.params.column.getColId());
    if (this.cd && !(this.cd as ViewRef).destroyed) {
      this.cd.detectChanges();
    }
    this.guiParams.hidePopup();

  }



  ngOnDestroy() {
    this.cd.reattach();
  }
}
