import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { CpAgGridService } from '@app/Modules/cp-grid/services/cp-ag-grid-service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import { AppService } from '@app/Modules/Shared/Services/app.service';

@AutoUnsubscribe()
@Component({
  selector: 'cp-grid-pagination',
  templateUrl: './cp-grid-pagination.component.html',
  styleUrls: ['./cp-grid-pagination.component.scss']
})

export class CpGridPaginationComponent implements OnInit, OnDestroy {
  currentPage: number = 1;
  lastPage: number;
  selectedPageSize: any;
  pageSizes: Array<number>;
  totalCount: number;
  currentPageDetails: any;
  actualPageSize: number;
  reloadGrid: boolean = true;
  subscription: Subscription = new Subscription();

  @Output() public pageSizeChanged: EventEmitter<number> = new EventEmitter<number>();
  @Input() public resultsPerPageId: string;

  constructor(
    private gridService: CpAgGridService,
    private appService: AppService) {
  }

  ngOnInit() {
    this.initPagination();
    this.setRecordCountListener();
  }

  initPagination() {
    this.pageSizes = this.gridService.getPageSizes();
    this.selectedPageSize = this.gridService.clientPageSize.toString();
    this.actualPageSize = this.gridService.clientPageSize;
  }

  setCurrentPageDetails(action: string) {
    switch (action) {
      case 'decrement':
        this.currentPageDetails = {
          startIndex: (this.actualPageSize * (this.currentPage - 1) + 1),
          lastIndex: (this.currentPageDetails.startIndex - 1)
        };
        break;
      case 'increment':
        this.currentPageDetails = {
          startIndex: (this.currentPageDetails.lastIndex + 1),
          // lastIndex: (this.actualPageSize * this.currentPage)
          lastIndex: this.totalCount < (this.actualPageSize * this.currentPage) ? this.totalCount : (this.actualPageSize * this.currentPage)
        };
        break;
      case 'last':
        this.currentPageDetails = {
          startIndex: this.actualPageSize * (this.currentPage - 1) + 1,
          lastIndex: this.totalCount < (this.actualPageSize * this.currentPage) ? this.totalCount : (this.actualPageSize * this.currentPage)
        }
        break;
      case 'reset':
      default:
        this.currentPageDetails = {
          startIndex: 1,
          lastIndex: (this.actualPageSize * this.currentPage)
        };
        break;
    }
  }

  calculatePageNumbers() {
    this.lastPage = Math.ceil(this.totalCount / this.selectedPageSize);
  }

  setRecordCountListener() {
    let me = this;
    this.subscription.add(this.gridService.getRecordCount().subscribe((totalCount) => {
      this.totalCount = totalCount;
      if (totalCount) {
        if(this.gridService.pageContext.pageName == 'ManageErrorKcFile'){
          if(this.totalCount <= this.selectedPageSize){
            this.resetPagination();
          }
        }
        if (me.reloadGrid) {
          me.resetPagination();
          me.setReloadGridListener();
          me.reloadGrid = false;
        }
      }
    }));
  }

  setReloadGridListener() {
    let me = this;
    this.subscription.add(this.gridService.getReloadGrid().subscribe((reloadGrid) => {
      if (reloadGrid) {
        me.reloadGrid = reloadGrid;
      }
    }));
  }

  onPreviousBtnClick(event) {
    this.currentPage--;
    this.setCurrentPageDetails('decrement');
    this.gridService.gridApi.paginationGoToPreviousPage();
  }

  onNextBtnClick(event) {
    this.currentPage++;
    this.setCurrentPageDetails('increment');
    this.gridService.gridApi.paginationGoToNextPage();
  }

  onPagePerSizeChanged(event) {
    this.selectedPageSize = event.value;
    this.gridService.clientPageSize = parseInt(event.value, 10);
    this.resetPagination();
    this.pageSizeChanged.emit(this.gridService.clientPageSize);
  }

  onFirstBtnClick(event) {
    this.currentPage = 1;
    this.gridService.gridApi.paginationGoToFirstPage();
    this.setCurrentPageDetails('reset');
  }

  onLastBtnClick(event) {
    this.appService.setLoadingStatus(true);
    this.gridService.gridApi.refreshInfiniteCache();
    setTimeout(()=>{
      this.currentPage = this.lastPage;
      let page: number = this.lastPage;
      page = Math.ceil(this.totalCount / this.gridService.serverPageSize);
      if (this.gridService.gridApi.getInfiniteRowCount() < page * this.gridService.serverPageSize)
        this.gridService.gridApi.setInfiniteRowCount(page * this.gridService.serverPageSize, false);
      this.gridService.gridApi.ensureIndexVisible(page * this.gridService.serverPageSize - 1);
      this.setCurrentPageDetails('last');
      this.appService.setLoadingStatus(false);
    })
  }

  resetPagination() {
    this.currentPage = 1;
    this.actualPageSize = this.selectedPageSize;
    if (this.totalCount < this.actualPageSize)
      this.actualPageSize = this.totalCount;
    this.calculatePageNumbers();
    this.setCurrentPageDetails('reset');
  }

  ngOnDestroy() {
    //Destroying component
  }
}
