let endpoints_CB_DEV_TeamB = {
    tilesCountCB: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-tb/NA/v1/Dashboard/TilesCount",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/Dashboard/TilesCount",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/Dashboard/TilesCount",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/Dashboard/TilesCount",
		method: "GET"
	},
	tilesPendingCountCB: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-tb/NA/v1/Dashboard/TilesPendingCount",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/Dashboard/TilesPendingCount",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/Dashboard/TilesPendingCount",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/Dashboard/TilesPendingCount",
		method: "GET"
	},
	tilesUnclaimedCountCB: {
		url_NA: "",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/Dashboard/TilesUnclaimedCount",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/Dashboard/TilesUnclaimedCount",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/Dashboard/TilesUnclaimedCount",
		method: "GET"
	},
	tilesResultCB: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-tb/NA/v1/Dashboard/TilesResult",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/Dashboard/TilesResult",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/Dashboard/TilesResult",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/Dashboard/TilesResult",
		method: "POST"
	},
	tilesPendingResultCB: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-tb/NA/v1/Dashboard/TilesPendingResult",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/Dashboard/TilesPendingResult",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/Dashboard/TilesPendingResult",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/Dashboard/TilesPendingResult",
		method: "POST"
	},
	exportTilesResultCB: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-tb/na/v1/Export/TilesResult",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/Export/TilesResult",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/Export/TilesResult",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/Export/TilesResult",
		method: "POST"
	},
	exportTilesPendingResultCB: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-tb/na/v1/Export/TilesPendingResult",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/Export/TilesPendingResult",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/Export/TilesPendingResult",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/Export/TilesPendingResult",
		method: "POST"
	},

	searchByFieldsCB: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-tb/NA/v1/Chargeback/BySearchFields",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/Chargeback/BySearchFields",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/Chargeback/BySearchFields",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/Chargeback/BySearchFields",
		method: "POST"
	},
	

	exportBySearchResultCB: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-tb/na/v1/Export/BySearchResult",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/Export/BySearchResult",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/Export/BySearchResult",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/Export/BySearchResult",
		method: "POST"
	},

	saveRecentSearchCB: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-tb/NA/v2/UserService/ChargeBackSaveSearch",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-tb/EMEA/v2/UserService/ChargeBackSaveSearch",
		url_LAO: "https://kcc-sls-laokcpuser-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserService/ChargeBackSaveSearch",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apackcpuser-pxy-api-pdqa/APAC/v2/UserService/ChargeBackSaveSearch",
		method: "POST"
	},
	getSavedFavoriteSearchCB: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-tb/NA/v2/UserService/ChargeBackGetFavoriteSearch",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-tb/EMEA/v2/UserService/ChargeBackGetFavoriteSearch",
		url_LAO: "https://kcc-sls-laokcpuser-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserService/ChargeBackGetFavoriteSearch",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apackcpuser-pxy-api-pdqa/APAC/v2/UserService/ChargeBackGetFavoriteSearch",
		method: "GET"
	},
	removeSavedFavoriteSearchCB: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-tb/NA/v2/UserService/ChargeBackRemoveFavoriteSearch",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-tb/EMEA/v2/UserService/ChargeBackRemoveFavoriteSearch",
		url_LAO: "https://kcc-sls-laokcpuser-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserService/ChargeBackRemoveFavoriteSearch",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apackcpuser-pxy-api-pdqa/APAC/v2/UserService/ChargeBackRemoveFavoriteSearch",
		method: "POST"
	},

	getRecentSearchCB: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-tb/NA/v2/UserService/ChargeBackGetRecentSearch",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-tb/EMEA/v2/UserService/ChargeBackGetRecentSearch",
		url_LAO: "https://kcc-sls-laokcpuser-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserService/ChargeBackGetRecentSearch",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apackcpuser-pxy-api-pdqa/APAC/v2/UserService/ChargeBackGetRecentSearch",
		method: "GET"
	},

	markSearchFavrouiteCB: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-tb/NA/v2/UserService/ChargeBackSaveFavoriteSearch",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-tb/EMEA/v2/UserService/ChargeBackSaveFavoriteSearch",
		url_LAO: "https://kcc-sls-laokcpuser-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserService/ChargeBackSaveFavoriteSearch",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apackcpuser-pxy-api-pdqa/APAC/v2/UserService/ChargeBackSaveFavoriteSearch",
		method: "POST"
	},

	removeSearchCB: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-tb/NA/v2/UserService/ChargeBackRemoveFavoriteSearch",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-tb/EMEA/v2/UserService/ChargeBackRemoveFavoriteSearch",
		url_LAO: "https://kcc-sls-laokcpuser-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserService/ChargeBackRemoveFavoriteSearch",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apackcpuser-pxy-api-pdqa/APAC/v2/UserService/ChargeBackRemoveFavoriteSearch",
		method: "POST"
	},
	saveSearchNameCB: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-tb/NA/v2/UserService/ChargeBackSaveSearchName",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-tb/EMEA/v2/UserService/ChargeBackSaveSearchName",
		url_LAO: "https://kcc-sls-laokcpuser-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserService/ChargeBackSaveSearchName",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apackcpuser-pxy-api-pdqa/APAC/v2/UserService/ChargeBackSaveSearchName",
		method: "POST"
	},

	summaryResultsCB: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-tb/NA/v1/Chargeback/ChargeBackSummaryResults",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/Chargeback/ChargeBackSummaryResults",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/Chargeback/ChargeBackSummaryResults",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/Chargeback/ChargeBackSummaryResults",
		method: "POST"
	},

	getCouponSummaryCB: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-tb/na/v1/Chargeback/GetSummary",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1//Chargeback/GetSummary",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1//Chargeback/GetSummary",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1//Chargeback/GetSummary",
		method: "POST"
	},

	getCustomerEuCB: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-tb/NA/v1/Chargeback/GetCustEU",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/V1/Chargeback/GetCustEU ",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/V1/Chargeback/GetCustEU",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/V1/Chargeback/GetCustEU",
		method: "POST"
	},

	downloadCB: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-tb/NA/v1/Download/DownloadSearchAsync",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/Download/DownloadSearchAsync",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/Download/DownloadSearchAsync",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/Download/DownloadSearchAsync",
		method: "POST"
	},

	getEndUserDetailsCB: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-tb/NA/v1/Chargeback/ChargeBackEndUserDetails",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/Chargeback/ChargeBackEndUserDetails",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/Chargeback/ChargeBackEndUserDetails",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/Chargeback/ChargeBackEndUserDetails",
		method: "POST"
	},

	downloadTileCB: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-tb/NA/v1/Download/DownloadTilesAsync",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/Download/DownloadTilesAsync",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/Download/DownloadTilesAsync",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/Download/DownloadTilesAsync",
		method: "POST"
	},
	downloadEUDetails: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-tb/NA/v1/Download/DownloadEUDetailsAsync",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/Download/DownloadEUDetailsAsync",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/Download/DownloadEUDetailsAsync",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/Download/DownloadEUDetailsAsync",
		method: "POST"
	},
	exportCBSummaryResult: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-tb/NA/v1/Export/CBSummaryResult",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/Export/CBSummaryResult",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/Export/CBSummaryResult",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/Export/CBSummaryResult",
		method: "POST"
	},
	getUploadedByEmailIds: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-tb/NA/v2/UserService/GetUserEmailAddress",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-tb/EMEA/v2/UserService/GetUserEmailAddress",
		url_LAO: "https://kcc-sls-laokcpuser-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserService/GetUserEmailAddress",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v2/UserService/GetUserEmailAddress",
		method: "POST"
	},
	exportCBEfileResult: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-tb/NA/v1/Export/EFileResult",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/Export/EFileResult",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/Export/EFileResult",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/Export/EFileResult",
		method: "POST"
	},

	searchCBEfileResult: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-tb/NA/v1/EFile/Search",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/EFile/Search",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/EFile/Search",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/EFile/Search",
		method: "POST"
	},
	efileCustomerDetails: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-tb/NA/v1/EFile/GetEFileCustomerDetails",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/EFile/GetEFileCustomerDetails",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/EFile/GetEFileCustomerDetails",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/EFile/GetEFileCustomerDetails",
		method: "POST"
	},
	efileDownload: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-tb/NA/v1/EFile/UserEFileDownload",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/EFile/UserEFileDownload",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/EFile/UserEFileDownload",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/EFile/UserEFileDownload",
		method: "POST"
	},
	efileResultExport: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-tb/NA/v1/Export/EFileResult",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/Export/EFileResult",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/Export/EFileResult",
		method: "POST"
	},
	downloadCBEfile: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-tb/NA/v1/EFile/UserEFileDownload",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/EFile/UserEFileDownload",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/EFile/UserEFileDownload",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/EFile/UserEFileDownload",
		method: "POST"
	},


	updateCBEfileStatus: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-tb/NA/v1/EFile/UpdateEFileStatus",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/EFile/UpdateEFileStatus",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/EFile/UpdateEFileStatus",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/EFile/UpdateEFileStatus",
		method: "POST"
	},

	cbGetDiscrepancies: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-tb/NA/v1/Chargeback/CBGetDiscrepancies",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/Chargeback/CBGetDiscrepancies",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/Chargeback/CBGetDiscrepancies",
		method: "POST"
	},

	uploadEfile: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-tb/NA/v1/EFile/UploadEFile",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/EFile/UploadEFile",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/EFile/UploadEFile",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/EFile/UploadEFile",
		method: "POST"
	},

	downloadEFileTemplate: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-tb/na/v1/EFile/DownloadEfileTemplate",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/EFile/DownloadEfileTemplate",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/EFile/DownloadEfileTemplate",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/EFile/DownloadEfileTemplate",
		method: "GET"
	},
	eFileLinesWithErrors: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-tb/NA/V1/EFile/EFileLinesWithErrors",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/EFile/EFileLinesWithErrors",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/EFile/EFileLinesWithErrors",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/EFile/EFileLinesWithErrors",
		method: "POST"
	},
	exportCBEFileErrorSummaryResult: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-tb/na/v1/Export/EFileErrorSummaryResult",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/Export/EFileErrorSummaryResult",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/Export/EFileErrorSummaryResult",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/Export/EFileErrorSummaryResult",
		method: "POST"
	},
	eFileErrorSummary: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-tb/NA/V1/EFile/EFileErrorSummary",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/EFile/EFileErrorSummary",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/EFile/EFileErrorSummary",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/EFile/EFileErrorSummary",
		method: "POST"
	},
	deleteEFileLineWithErrors: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-tb/na/v1/EFile/DeleteEFileLineWithErrors",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/EFile/DeleteEFileLineWithErrors",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/EFile/DeleteEFileLineWithErrors",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/EFile/DeleteEFileLineWithErrors",
		method: "POST"
	},
	efileHistoryLog: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-tb/NA/v1/EFile/EFileHistoryLog",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/EFile/EFileHistoryLog",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/EFile/EFileHistoryLog",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/EFile/EFileHistoryLog",
		method: "POST"
	},
	exportEfileHistory: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-tb/NA/v1/Export/EFileHistory",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/Export/EFileHistory",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/Export/EFileHistory",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/Export/EFileHistory",
		method: "POST"
	},

	cbGetDistinctFilterSearchGrid: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-tb/na/v1/Chargeback/ChargebackGridHeaderFilters",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/Chargeback/ChargebackGridHeaderFilters",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/Chargeback/ChargebackGridHeaderFilters",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/Chargeback/ChargebackGridHeaderFilters",
		method: "POST"
	},

	cbGetDistinctFilterSummaryGrid: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-tb/na/v1/Chargeback/ChargeBackSummaryGridHeaderFilters",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/Chargeback/ChargeBackSummaryGridHeaderFilters",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/Chargeback/ChargeBackSummaryGridHeaderFilters",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/Chargeback/ChargeBackSummaryGridHeaderFilters",
		method: "POST"
	},
	correctEfileErrors: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-tb/NA/V1/EFile/CorrectErrors",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/EFile/CorrectErrors",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/EFile/CorrectErrors",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/EFile/CorrectErrors",
		method: "POST"
	},

	cbGetDistinctFilterTilesPending: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-tb/na/v1/Dashboard/TilesPendingGridHeaderFilters",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/Dashboard/TilesPendingGridHeaderFilters",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/Dashboard/TilesPendingGridHeaderFilters",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/Dashboard/TilesPendingGridHeaderFilters",
		method: "POST"
	},

	cbGetDistinctFilterTilesGrid: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-tb/na/v1/Dashboard/TilesGridHeaderFilters",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/Dashboard/TilesGridHeaderFilters",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/Dashboard/TilesGridHeaderFilters",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/Dashboard/TilesGridHeaderFilters",
		method: "POST"
	},

	cbGetDistinctFilterEFileGrid: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-tb/na/v1/EFile/EFileGridHeaderFilters",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/EFile/EFileGridHeaderFilters",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/EFile/EFileGridHeaderFilters",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/EFile/EFileGridHeaderFilters",
		method: "POST"
	},

	cbGetDistinctFilterEFileErrorSummaryGrid: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-tb/na/v1/EFile/EFileErrorSummaryGridHeaderFilters",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/EFile/EFileErrorSummaryGridHeaderFilters",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/EFile/EFileErrorSummaryGridHeaderFilters",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/EFile/EFileErrorSummaryGridHeaderFilters",
		method: "POST"
	},

	EfileUploadKCPContent: {
		url_NA: "",
		url_LAO: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-tb/LAO/v2/Content/GetKCPContent",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-tb/EMEA/v2/Content/GetKCPContent",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v2/Content/GetKCPContent",
		method: "POST"
	},
	EfileUploadgetAccptancePolicyStatus: {
		url_NA: "",
		url_LAO: "https://kcc-sls-laokcpuser-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserService/GetUserAgreementAcceptanceStatus",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-tb/EMEA/v2/UserService/GetUserAgreementAcceptanceStatus",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v2/UserService/GetUserAgreementAcceptanceStatus",
		method: "POST"
	},
	EfileUploadsetAccptancePolicyStatus: {
		url_NA: "",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/EFile/SaveEFileAcceptancePolicy",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/EFile/SaveEFileAcceptancePolicy",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/EFile/SaveEFileAcceptancePolicy",
		method: "POST"
	},
	signalREndpoint: {
		url_NA: "https://app-chargeback-kimcl-dev-ncus.azurewebsites.net/signalRHub/ChargebackHubEntryPoint",
		url_LAO: "https://app-lao-chargeback-k-dev-bzs-1.azurewebsites.net/signalRHub/ChargebackHubEntryPoint",
		url_EMEA: "https://app-emea-chargeback-dev-weur-1.azurewebsites.net/signalRHub/ChargebackHubEntryPoint",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/signalRHub/ChargebackHubEntryPoint"
	},
	tilesUnclaimedResultCB: {
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/Dashboard/TilesUnclaimedResult",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/Dashboard/TilesUnclaimedResult",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/Dashboard/TilesUnclaimedResult",
		method: "POST"
	},
	tilesUnclaimedResultExportCB: {
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/Export/TilesUnclaimedResult",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/Export/TilesUnclaimedResult",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/Export/TilesUnclaimedResult",
		method: "POST"
	},
	tilesUnclaimedDistinctFilterCB: {
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/Dashboard/TilesUnclaimedGridHeaderFilters",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/Dashboard/TilesUnclaimedGridHeaderFilters",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/Dashboard/TilesUnclaimedGridHeaderFilters",
		method: "POST"
	},

	//------ Added For Kc-File ----------//
	searchCBKcfileResult: {
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/KCFile/Search",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/KCFile/Search",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/KCFile/Search",
		method: "POST"
	},
	exportCBKcfileResult: {
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/Export/KCFileResult",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/Export/KCFileResult",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/Export/KCFileResult",
		method: "POST"
	},

	uploadKcfile: {
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/KCFile/UploadKCFile",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/KCFile/UploadKCFile",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/KCFile/UploadKCFile",
		method: "POST"
	},

	downloadKcFileTemplate: {
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/KCFile/KCFileTemplateDownload",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/KCFile/KCFileTemplateDownload",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/KCFile/KCFileTemplateDownload",
		method: "GET"
	},

	kcfileHistoryLog: {
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/KCFile/KCFileHistoryLog",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/KCFile/KCFileHistoryLog",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/KCFile/KCFileHistoryLog",
		method: "POST"
	},
	exportKcfileHistory: {
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/Export/KCFileHistory",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/Export/KCFileHistory",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/Export/KCFileHistory",
		method: "POST"
	},
	kcfileDownload: {
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/KCFile/UserKCFileDownload",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/KCFile/UserKCFileDownload",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/KCFile/UserKCFileDownload",
		method: "POST"
	},
	cbGetDistinctFilterKcFileGrid: {
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/KCFile/KCFileGridHeaderFilters",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/KCFile/KCFileGridHeaderFilters",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/KCFile/KCFileGridHeaderFilters",
		method: "POST"
	},

	exportCBKcFileErrorSummaryResult: {
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/emea/v1/Export/KCFileErrorSummaryResult",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/Export/KCFileErrorSummaryResult",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/Export/KCFileErrorSummaryResult",
		method: "POST"
	},
	kcFileErrorSummary: {
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/KCFile/KCFileErrorSummary",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/KCFile/KCFileErrorSummary",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/KCFile/KCFileErrorSummary",
		method: "POST"
	},

	cbGetDistinctFilterKcFileErrorSummaryGrid: {
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/KCFile/KCFileErrorSummaryGridHeaderFilters",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/KCFile/KCFileErrorSummaryGridHeaderFilters",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/KCFile/KCFileErrorSummaryGridHeaderFilters",
		method: "POST"
	},

	updateCBKcfileStatus: {
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/KCFile/UpdateKCFileStatus",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/KCFile/UpdateKCFileStatus",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/KCFile/UpdateKCFileStatus",
		method: "POST"
	},

	correctKcfileErrors: {
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/KCFile/CorrectErrors",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/KCFile/CorrectErrors",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/KCFile/CorrectErrors",
		method: "POST"
	},

	kcFileLinesWithErrors: {
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/KCFile/KCFileLinesWithErrors",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/KCFile/KCFileLinesWithErrors",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/KCFile/KCFileLinesWithErrors",
		method: "POST"
	},

	deleteKcFileLineWithErrors: {
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/KCFile/DeleteKCFileLineWithErrors",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/KCFile/DeleteKCFileLineWithErrors",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/KCFile/DeleteKCFileLineWithErrors",
		method: "POST"
	},
	KCFilePABySearchFields: {
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/v2/PriceAgreement/KCFilePABySearchFields",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/PriceAgreement/KCFilePABySearchFields",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/v2/PriceAgreement/KCFilePABySearchFields",
		method: "POST"
	},
	KCFilePriceAgreementSearch: {
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/v2/Export/KCFilePriceAgreementSearch",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/Export/KCFilePriceAgreementSearch",
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/v2/Export/KCFilePriceAgreementSearch",
		method: "POST"
	},
	KCFileTemplateDownload: {
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/emea/v1/KCFile/KCFileTemplateDownload ",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/KCFile/KCFileDownTemp",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/KCFile/KCFileTemplateDownload",
		method: "POST"
	},
	//multiple notification popup API
	EFileNotification:{
		url_NA:"https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-tb/NA/v1/EFile/EFileNotification",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/EFile/EFileNotification",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/EFile/EFileNotification",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/EFile/EFileNotification",
		method: "GET"
	},
	UnclaimedCBNotification:{
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/Dashboard/UnclaimedCBNotification",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/Dashboard/UnclaimedCBNotification",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/Dashboard/UnclaimedCBNotification",
		method: "GET"
	},
	KCFileNotification:{
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/KCFile/KCFileNotification",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/KCFile/KCFileNotification",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/KCFile/KCFileNotification",
		method: "GET"
	},
	GetClaimRefernce:{
		url_NA:"https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-tb/NA/v1/EFile/GetClaimReference",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/EFile/GetClaimReference",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/EFile/GetClaimReference",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/EFile/GetClaimReference",
		method: "POST"
	},
	GetClaimNumbersByCoupon:{
		url_NA:"https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-tb/NA/v1/Chargeback/GetClaimNumbersByCoupon",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-tb/EMEA/v1/Chargeback/GetClaimNumbersByCoupon",
		url_LAO: "https://kcc-sls-laochargeback-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/Chargeback/GetClaimNumbersByCoupon",
		url_APAC: "https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/Chargeback/GetClaimNumbersByCoupon",
		method: "POST"
	},
	EFileKCFileGroupValidationStatus:{
		url_NA:" ",
		url_EMEA: " ",
		url_LAO: " ",
		url_APAC: "	https://app-apac-chargeback-dev-cia-1.azurewebsites.net/APAC/v1/EFile/KCFileGroupValidation",
		method: "GET"
	}

}

export { endpoints_CB_DEV_TeamB };