import { APP_LEGACY_MENU_HOST, SPART_HOST, contentMangement, APP_LEGACY_MENU_HOST_OLD, efileHistoryLegacyDomain, efileHistoryLegacyDomainEMEA, manageUserLinkNAEMEA } from '@env/environment';

const createContractURI = SPART_HOST + "/create-contract.aspx?ContractNo="

const privacyPolicy = "https://www.kcprofessional.com/en-US/Privacy-Policy";
const legalLink = "https://www.kcprofessional.com/en-US/Legal-Disclaimers";
const pardotLink = APP_LEGACY_MENU_HOST + "/UI/Root/PardotForm.aspx?ruri="
const indexLink = APP_LEGACY_MENU_HOST + "/UI/Root/Default.aspx";
const globalUserLink = APP_LEGACY_MENU_HOST_OLD + "/UI/Root/Default.aspx?";
const registerLink = APP_LEGACY_MENU_HOST + "/CustomerPortal/RegisterCompany.aspx";
const myProfileLink = "/UI/Site%20Admin%20Center/MyProfile.aspx";
const myProfileLinkInternalView = "/UI/Site%20Admin%20Center/MyProfile_InternalView.aspx";
const changePasswordLink = "https://mktaccess.kcmkt.com/num2neuusape/num2neuusape.asp?SN=&SD=&SC=&UserName=9crp000104&selLanguage=en-US";
const forgotPasswordLink = "https://mktaccess.kcmkt.com/num2neuusape/num2neuusape_alt.asp?selLanguage=en-US";
const manageUserLink = manageUserLinkNAEMEA;
const manageUserLinkLAO = manageUserLinkNAEMEA;
const appLinksManageUserLinkNAEMEA = manageUserLinkNAEMEA;
const portalUsageLink = APP_LEGACY_MENU_HOST + "/UI/Site%20Admin%20Center/PortalUsageReport.aspx?x=2";
const adminManualLinkLAO = APP_LEGACY_MENU_HOST + "/assets/images/pdf/LAO/site_administrator_manual_LAO.pdf";
const adminManualLink = APP_LEGACY_MENU_HOST_OLD + "/data/Documents/Upload/SiteAdmin/Site%20Administrator%20Manual.pdf";
const logoutLink = APP_LEGACY_MENU_HOST_OLD + "/UI/Other/Logout.aspx#";
const helpLink = APP_LEGACY_MENU_HOST + "/UI/Site%20Admin%20Center/Help.aspx";
const customerPortal = "http://www.nacustomerportal.kcc.com/CustomerPortal_Internet/UI/Site%20Admin%20Center/Home.aspx";
const contactLink = APP_LEGACY_MENU_HOST + "/UI/Site%20Admin%20Center/Feedback.aspx"
const emailLink = "kcpchargebacks@kcc.com"
const viewallKcpConnection = APP_LEGACY_MENU_HOST_OLD + "/UI/Learning%20Center/Announcements.aspx?x=2"
const kcpConnectionLink = APP_LEGACY_MENU_HOST_OLD + "/UI/Learning%20Center/KCPConnectionPreview.aspx?ID="
const prodIDSearchLink = APP_LEGACY_MENU_HOST_OLD + "/UI/Learning%20Center/ProductAdvanceSearch.aspx?ProdCode="
const prodCatSearchLink = APP_LEGACY_MENU_HOST_OLD + "/UI/Learning%20Center/ProductDrillDownSearch.aspx?x=2&l=0&p=0&topid=CategoryIDEncrypt&v=VpYbM1iCS5/1jwYXDLzZVA==|VpYbM1iCS5/1jwYXDLzZVA==|CategoryIDEncrypt|VpYbM1iCS5/1jwYXDLzZVA==|aSya43rXzSR+73shkiN1qg==|&f=3&ID=CategoryIDEncrypt"
const productLink = APP_LEGACY_MENU_HOST_OLD + "/UI/Learning%20Center/ProductPreview.aspx?ID=###&SID=aSya43rXzSR+73shkiN1qg==&LangCode=Ci2L7eVAjvWQz3aoCxeR0g==&PADetails=1"
const contentMangementTool = contentMangement;
const compliance = "http://www.kimberly-clark.com/sustainability1/people/CSC.aspx";
const kcProfessionalLink = "https://www.kcprofessional.com";

const historicalPA = "https://reports.kcc.com/ReportServer/Pages/ReportViewer.aspx?/A159/Reports/ALL-ALL-AG-IR-Interface%20Redesign%20contract%20report&contract="

const historicPricingMC = "https://reports.kcc.com/reports/report/A159/Reports/KCP-PA-FC-IR-Meetcomp%20contract%20by%20contract%20showing%20products%20history";

const createProductCodeURI = APP_LEGACY_MENU_HOST_OLD + "/UI/Learning%20Center/ProductPreview.aspx?ID=####&SID=aSya43rXzSR+73shkiN1qg==&LangCode=Ci2L7eVAjvWQz3aoCxeR0g==&PADetails=1"

const efileHistoryLegacySite = efileHistoryLegacyDomain + "/CustomerPortal/UI/Transaction%20Center/eFileHistorySearch.aspx?x=1";
const efileHistoryLegacySiteEMEA = efileHistoryLegacyDomainEMEA + "/Customerportal/UI/Transaction%20Center/eFileHistorySearch.aspx?x=1"


const cmtReportsLink = "https://www.nacustomerportal.kcc.com/CustomerPortal/UI/Site Admin Center/SiteAdminReports.aspx";

const desposibleMaskURL = "https://www.kcprofessional.com/en-us/products/scientific-and-research/lab-environment/disposable-masks/kimtech-n95-pouch-respirator-not-for-medical-use/53358";

const kcMarketPlaceURL = "https://marketplace.kcprofessional.com";

let contactsLink = { emailLink }
let footerLinks = { privacyPolicy, legalLink }
let headerLinks = { myProfileLink, myProfileLinkInternalView, changePasswordLink, forgotPasswordLink, logoutLink, manageUserLink, manageUserLinkLAO, portalUsageLink, adminManualLink, adminManualLinkLAO, helpLink, contactLink, customerPortal, contentMangementTool, kcProfessionalLink, appLinksManageUserLinkNAEMEA }
let productSearch = { prodIDSearchLink, prodCatSearchLink }
let kcpConnections = { kcpConnectionLink, viewallKcpConnection }
let createContractLink = { createContractURI };
let WelcomeGetStartedLink = { myProfileLink, myProfileLinkInternalView };
let historicalLink = { historicalPA }
let createProductCodeLink = { createProductCodeURI };
let socialCompliance = { compliance };
let historicPricingLinkMC = historicPricingMC;
let CMTReportsURL = cmtReportsLink;
let NAdesposibleMaskURL = desposibleMaskURL;
let KCMarketPlaceURL = kcMarketPlaceURL;

export {
    registerLink, pardotLink, indexLink, historicalLink, footerLinks, headerLinks, contactsLink, kcpConnections, productSearch, createContractLink, WelcomeGetStartedLink, createProductCodeLink, socialCompliance, kcProfessionalLink, efileHistoryLegacySite, efileHistoryLegacySiteEMEA,
    globalUserLink, historicPricingLinkMC, CMTReportsURL, NAdesposibleMaskURL, appLinksManageUserLinkNAEMEA, KCMarketPlaceURL
};
