import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CpAgGridService } from '@app/Modules/cp-grid/services/cp-ag-grid-service';
import { InvoicePriceService } from '@app/Modules/in-voice-new/Services/invoice-price.service';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { GlobalAppService } from '@app/Modules/Shared/Services/global-app.service';
import { HttpInvoicePriceNewService } from '@app/Modules/Shared/Services/http-invoice-price-new.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-contextmenu-advanced-invoice',
  templateUrl: './contextmenu-invoicenew.component.html',
  styleUrls: ['./contextmenu-invoicenew.component.scss']
})
export class ContextmenuInvoiceNewComponent implements OnInit {

  params: any;
  ellipseSelect: boolean = false;
  isGlobalUser: boolean = false;
  region: string;
  customerNumber: string;
  isPageViewByCategory: boolean = false;
  taxNumber: string;
  constructor(private httpInvoiceService: HttpInvoicePriceNewService,
    private appService: AppService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private messageService: MessageService,
    private invoicePriceService: InvoicePriceService,
    private globalAppService: GlobalAppService,
    private appServ: AppService,
    private gridService: CpAgGridService) { }

  agInit(params: any) {
    this.params = params;
  }

  ngOnInit() {
    this.isGlobalUser = this.appService.isGlobalUser;
    this.region = this.globalAppService.getDefaultRegionString();
    let pageName = (this.gridService.pageContext && this.gridService.pageContext.pageName) ? this.gridService.pageContext.pageName : '';
    if (pageName == 'advancedInvoiceNew') {
      this.isPageViewByCategory = true;
    }
    this.customerNumber = this.appService.userProfileInfo["defaultCustomerInfo"][0].customerNumber;
    this.taxNumber = this.appService.userProfileInfo["defaultCustomerInfo"][0].taxNumber;
  }

  contextmenuToggle(element, e) {
    element.toggle(e);
  }

  onContextMenuHide() {
    this.ellipseSelect = false;
  }

  onContextMenuShow() {
    this.ellipseSelect = true;
  }

  isRefreshAllowed() {
    this.appService.setLoadingStatus(true);
    let pageName = (this.gridService.pageContext && this.gridService.pageContext.pageName) ? this.gridService.pageContext.pageName : '';
    let parentRouter;
    if (pageName == 'invoiceSearchResult') {
      parentRouter = this.activatedRoute.parent;
    }
    else {
      parentRouter = this.activatedRoute;
    }
    let userId = this.appServ.getuserId(true);
    let request = {
      userId: userId
    }
    this.httpInvoiceService.IsPriceRequestAllowedforUser_new(request).subscribe(data => {
      if (data && data.isAllowed) {
        this.refreshInvoice();
      }
      else {
        let maxAllowedValue = data.maxAllowedRequest;
        this.router.navigate([{ outlets: { RefreshNotAllowedDialog: ['RefreshNotAllowed'] } }], { relativeTo: parentRouter, state: { maxAllowedValue: maxAllowedValue } });
      }
    });
  }

  refreshInvoice() {
    if (this.isGlobalUser) {
      this.downloadInvoice();
    }
    else {
      this.appService.setLoadingStatus(true);
      let pageName = (this.gridService.pageContext && this.gridService.pageContext.pageName) ? this.gridService.pageContext.pageName : '';
      let categoryList, parentRouter;
      if (pageName == 'invoiceSearchResult' || pageName == 'recentRequests') {
        parentRouter = this.activatedRoute.parent;
      }
      else {
        parentRouter = this.activatedRoute;
      }
      if (pageName == 'advancedInvoiceNew') {
        categoryList = [this.params.data.categoryCode];
      }
      else if (pageName == 'recentRequests') {
        categoryList = this.params.data.categoryList;
      }
      else {
        categoryList = ['ALL'];
      }
      let request = {
        customerNumber: this.params.data.customerNumber,
        categoryList: categoryList
      };
      if (this.region == "APAC") {
        request["requestFrom"] = 2;
      }
      this.httpInvoiceService.priceRefreshRequest(request).subscribe(data => {
        if (data.statusMessage == "Price refresh request initiated.") {
          this.invoicePriceService.setUpdateRefreshStatusRequest([request.categoryList]);
          this.invoicePriceService.setStatus([this.params.node.id]);
          this.router.navigate([{ outlets: { emailModalDialog: ['emailModal'] } }], { relativeTo: parentRouter, state: { customerNumber: request.customerNumber, customerList: request.categoryList, pageName: pageName, taxNumber: this.taxNumber } });
        }
      },
        (e) => {
          this.invoicePriceService.setErrorMsg(true);
        },
        () => {
          this.appService.setLoadingStatus(false);
        });
    }
  }

  downloadInvoice() {
    this.appServ.setLoadingStatus(true);
    let requestObj = {
      'customerNumber': this.params.data.customerNumber
    }
    let pageName = (this.gridService.pageContext && this.gridService.pageContext.pageName) ? this.gridService.pageContext.pageName : '';
    let parentRouter;
    if (pageName == 'invoiceSearchResult') {
      parentRouter = this.activatedRoute.parent;
    }
    else {
      parentRouter = this.activatedRoute;
    }
    if (this.region == 'NA') {
      this.httpInvoiceService.getCategoriesByCustomerNumber(requestObj).subscribe(data => {
        let categoryCodes = [];
        if (pageName == 'advancedInvoiceNew') {
          categoryCodes.push(this.params.data.categoryCode);
        }
        else if (pageName == 'recentRequests' && (this.params && this.params.data && this.params.data.categoryList && this.params.data.categoryList.length && (this.params.data.categoryList[0]).toUpperCase() != 'ALL')) {
          this.params.data.categoryList.map(item => {
            categoryCodes.push(item);
          });
        }
        else {
          data.categoryList.map(item => {
            categoryCodes.push(item.categoryCode);
          });
        }
        let invDownloadData = {
          customerNumber: this.params.data.customerNumber,
          selectedCategoryNumber: categoryCodes,
          isDownloadAll: false
        }
        this.router.navigate([{ outlets: { downloadDialog: ['downloadInvoice'] } }], { relativeTo: parentRouter, state: { downloadData: invDownloadData }, queryParamsHandling: "preserve" });
      },
        (e) => {
          this.messageService.add({ key: 'downloadUnexpectedError', detail: 'An unexpected error occurred. Please try again.', summary: 'Error', severity: 'error' });
        },
        () => {
          this.appService.setLoadingStatus(false);
        });
    }
    else {
      let invDownloadData = {
        customerNumber: this.params.data.customerNumber,
        selectedCategoryNumber: ['ALL'],
        isDownloadAll: false
      }
      this.router.navigate([{ outlets: { downloadDialog: ['downloadInvoice'] } }], { relativeTo: parentRouter, state: { downloadData: invDownloadData }, queryParamsHandling: "preserve" });
    }
  }

  viewByCategory() {
    let custData = Object.assign({}, this.params.data, { 'backUrl': this.router.url });
    this.router.navigate(['viewByCategory'], { relativeTo: this.activatedRoute.parent.parent, state: { selectedCustomer: custData }, queryParams: { 'CustomerNumber': this.params.data.customerNumber } });
  }

  notifyMe() {
    let pageName = (this.gridService.pageContext && this.gridService.pageContext.pageName) ? this.gridService.pageContext.pageName : 'DefaultCustomer';
    let parentRouter;
    if (pageName == 'invoiceSearchResult') {
      parentRouter = this.activatedRoute.parent;
    }
    else {
      parentRouter = this.activatedRoute;
    }
    let categoryList;
    if (pageName == 'recentRequests') {
      categoryList = this.params.data.categoryList;
    }
    this.router.navigate([{ outlets: { notifyMeModal: ['notifyMeModal'] } }], { relativeTo: parentRouter, state: { customerNumber: this.params.data.customerNumber, selectedCustomer: this.customerNumber, pageName: pageName, customerList: categoryList, taxNumber: this.params.data.taxNumber } });
  }

}
