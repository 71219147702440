<p-dialog [(visible)]="displayPolicyModal" [dismissableMask]="false" [modal]="true" [draggable]="false" [closeOnEscape]="false" positionTop="20" [blockScroll]="true" [responsive]="true" [closable]="isGlobalUser || fromFileUpload" styleClass="dialogClass policyDialogue"
    (onHide)="close()" (onShow)="onPopupShow()">
    <p-header>
        {{"apacAcceptancePolicy.header" | translate}}
    </p-header>
    <div class="modal__language-selector">
        <ul class=" unlisted inline ">
            <!--<li [ngClass]="{ 'current-language' : currentSelectedLanguage==defaultLanguage } " (click)="onEnglishClick() ">English</li>-->
            <li *ngFor="let l of languageListForAPACPolicy" [ngClass]="{ 'current-language' : currentSelectedLanguage ==l } " (click)="onUserLanguageClick(l) ">{{userLanguageName[l]}}</li>
        </ul>
    </div>
    <div class="info-content ">
        <!------------>
        <div class="body-wrapper">
            <div>
                <div>
                    <div class="modal__shade"></div>
                    <div class="modal__wrapper">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-12">
                                    <div class="modal__search__end-user-search content-area__white-box">
                                        <form class="needs-validation current-language" data-language="english" novalidate="" [innerHTML]="policyContent">
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!------------>
    </div>

    <div class="modal__wrapper__buttons ">
        <button type="button " class="btn btn-primary btn-primary--icon-right " [hidden]="isAccepted " (click)="acknowledgePolicy() " [disabled]="isGlobalUser ">
           <span>{{"apacAcceptancePolicy.acknowledge" | translate}} </span>
            <i class="far fa-check-circle "></i>
        </button>
    </div>
</p-dialog>