/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./kcfile-history-download.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./kcfile-history-download.component";
import * as i5 from "../../../../chargeback/Services/cb.service";
import * as i6 from "../../../../Shared/Services/http-charge-back.service";
import * as i7 from "../../../services/cp-ag-grid-service";
import * as i8 from "primeng/components/common/messageservice";
import * as i9 from "../../../../Shared/Services/app.service";
var styles_KcfileHistoryDownloadComponent = [i0.styles];
var RenderType_KcfileHistoryDownloadComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_KcfileHistoryDownloadComponent, data: {} });
export { RenderType_KcfileHistoryDownloadComponent as RenderType_KcfileHistoryDownloadComponent };
function View_KcfileHistoryDownloadComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.svcCall() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("chargeback.kcFile.kcFilesPopUps.downloadLatest")); _ck(_v, 1, 0, currVal_0); }); }
function View_KcfileHistoryDownloadComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_KcfileHistoryDownloadComponent_2)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isdownloadLatest; _ck(_v, 2, 0, currVal_0); }, null); }
function View_KcfileHistoryDownloadComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.svcCall() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("chargeback.kcFile.kcFilesPopUps.downloadOlder")); _ck(_v, 1, 0, currVal_0); }); }
export function View_KcfileHistoryDownloadComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_KcfileHistoryDownloadComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_KcfileHistoryDownloadComponent_3)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.linesInFile > 0); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.isdownloadOlder; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_KcfileHistoryDownloadComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "kcfile-history-download", [], null, null, null, View_KcfileHistoryDownloadComponent_0, RenderType_KcfileHistoryDownloadComponent)), i1.ɵdid(1, 180224, null, 0, i4.KcfileHistoryDownloadComponent, [i5.CbService, i6.HttpChargeBackService, i7.CpAgGridService, i8.MessageService, i9.AppService], null, null)], null, null); }
var KcfileHistoryDownloadComponentNgFactory = i1.ɵccf("kcfile-history-download", i4.KcfileHistoryDownloadComponent, View_KcfileHistoryDownloadComponent_Host_0, {}, {}, []);
export { KcfileHistoryDownloadComponentNgFactory as KcfileHistoryDownloadComponentNgFactory };
