import * as tslib_1 from "tslib";
import { OnDestroy } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { GlobalAppService } from '@app/Modules/Shared/Services/global-app.service';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { HttpSiteadminService } from '@app/Modules/Shared/Services/http-siteadmin.service';
import { MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
var ReportsDownloadComponent = /** @class */ (function () {
    function ReportsDownloadComponent(httpServ, _globalAppService, translateServ, appServ, messageService, activatedRoute, router) {
        this.httpServ = httpServ;
        this._globalAppService = _globalAppService;
        this.translateServ = translateServ;
        this.appServ = appServ;
        this.messageService = messageService;
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.showNotification = false;
    }
    ReportsDownloadComponent.prototype.agInit = function (params) {
        this.params = params;
        this.paramsValue = params.value;
        var reportId = this.activatedRoute.snapshot.queryParams.ReportId;
        if (reportId) {
            // This download is now moved to home module
            // this.autoDownloadFile(reportId);
        }
        this.prepareDownloadLinkToCopy();
    };
    ReportsDownloadComponent.prototype.prepareDownloadLinkToCopy = function () {
        var url = window.location.href;
        var queryParam = 'ReportId=' + this.params.data.reportId;
        if (url.indexOf('ReportId=') > -1) {
            var index1 = url.indexOf('ReportId=');
            var index2 = url.indexOf('&', index1);
            if (index2 > -1 && index2 > index1) {
                this.downloadLink = url.replace(url.substring(index1, index2), queryParam);
            }
            else {
                this.downloadLink = url.replace(url.substring(index1), queryParam);
            }
        }
        else {
            var separator = (url.indexOf("?") === -1) ? "?" : "&";
            this.downloadLink = url.trim() + separator + queryParam;
        }
    };
    ReportsDownloadComponent.prototype.autoDownloadFile = function (reportId) {
        if (this.params && this.params.data && this.params.data.reportId && this.params.data.reportId == reportId) {
            this.makeAPICall(this.params.data.reportId);
        }
    };
    ReportsDownloadComponent.prototype.notify = function (e) {
        var _this = this;
        this.showNotification = true;
        setTimeout(function () {
            _this.showNotification = false;
        }, 2000);
    };
    ReportsDownloadComponent.prototype.setLanguageListener = function () {
        var _this = this;
        this.translateServ.get(['LCIDString'])
            .subscribe(function (value) {
            if (value) {
                _this.LCIDString = value.LCIDString;
            }
        });
    };
    ReportsDownloadComponent.prototype.refresh = function (params) {
        return false;
    };
    ReportsDownloadComponent.prototype.onLinkClick = function (event) {
        if (this.params && this.params.data && this.params.data.reportId) {
            this.makeAPICall(this.params.data.reportId);
        }
    };
    ReportsDownloadComponent.prototype.makeAPICall = function (reportId) {
        var _this = this;
        this.setLanguageListener();
        var region = this._globalAppService.getDefaultRegionString();
        var request = {
            "regionId": region,
            "languageCode": this.LCIDString,
            "reportType": ""
        };
        switch (reportId) {
            case 1:
                request.reportType = "KCPEmailNotifications";
                break;
            case 2:
                request.reportType = "UserCustomerMapping";
                break;
            case 8:
                request.reportType = "ViewPAcomment";
                break;
            case 10:
                request.reportType = "ExpectedVolumeReporting";
                break;
            case 17:
                request.reportType = "PAEmailReporting";
                break;
            default:
                request = this.LCIDString;
        }
        this.appServ.setLoadingStatus(true);
        this.subscription = this.httpServ.makeReportDownloadCall(request, reportId).subscribe(function (res) {
            if (res && res.reference) {
                var downloadResource_1 = res.reference;
                _this.router.navigate([{ outlets: { dialogs: null } }]).then(function () {
                    _this.router.navigate([{ outlets: { dialogs: ['downloadLinkModal'] } }], { relativeTo: _this.activatedRoute.parent, state: { url: downloadResource_1, title: 'priceagreement.title' } });
                });
            }
            else if (res && res.fileUrl) {
                var downloadResource_2 = res.fileUrl;
                _this.router.navigate([{ outlets: { dialogs: null } }]).then(function () {
                    _this.router.navigate([{ outlets: { dialogs: ['downloadLinkModal'] } }], { relativeTo: _this.activatedRoute.parent, state: { url: downloadResource_2, title: 'priceagreement.title' } });
                });
            }
            else {
                _this.appServ.setLoadingStatus(false);
                _this.messageService.add({ key: 'downloadError', summary: 'errorLabel', detail: '0000.errorMessage', severity: 'error' });
            }
        }, function (e) {
            _this.appServ.setLoadingStatus(false);
            _this.messageService.add({ key: 'downloadError', summary: 'errorLabel', detail: '0000.errorMessage', severity: 'error' });
        }, function () {
            _this.appServ.setLoadingStatus(false);
            if (_this.subscription) {
                _this.subscription.unsubscribe();
            }
        });
    };
    ReportsDownloadComponent.prototype.ngOnDestroy = function () {
        // Required for unsubscribing and destroying the component  
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    };
    ReportsDownloadComponent = tslib_1.__decorate([
        AutoUnsubscribe(),
        tslib_1.__metadata("design:paramtypes", [HttpSiteadminService,
            GlobalAppService,
            TranslateService,
            AppService,
            MessageService,
            ActivatedRoute,
            Router])
    ], ReportsDownloadComponent);
    return ReportsDownloadComponent;
}());
export { ReportsDownloadComponent };
