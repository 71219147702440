import { Component, OnDestroy, Inject, LOCALE_ID } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { HttpService } from '@app/Modules/Shared/Services/http.service';
import { PaService } from '@app/Modules/PriceAgreement/Services/pa.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { CpAgGridService } from '@app/Modules/cp-grid/services/cp-ag-grid-service';
import { DownloadCustomerRequest, DownloadCustomerFilter } from '@app/Modules/Shared/DataModels/SharedDataModel';
import {
  formatDate
} from '@angular/common';
import { cbStatus, cbRvRb, cbClaimRequestType, cbClaimRequestTypeAPAC } from '@app/conf/dataConfigurations';
import { TranslateService } from '@ngx-translate/core';
import { GlobalAppService } from '@app/Modules/Shared/Services/global-app.service';
@AutoUnsubscribe()
@Component({
  selector: 'app-tooltip-and-favorite',
  templateUrl: './tooltip-and-favorite.component.html',
  styleUrls: ['./tooltip-and-favorite.component.scss']
})
export class TooltipAndFavoriteComponent implements AgRendererComponent, OnDestroy {
  params: any;
  contractId: string = "";

  isCustIdFavorite: boolean = false;
  custIdalreadyfavorite;
  custIdfavoriteCSS: string = "";
  custIdMarkedToday;
  data: any;
  showTooltip: boolean = false;
  customerNumber: number;
  customerName: string;
  street: string;
  city: string;
  district: string;
  country: string;
  zipcode: string;
  overlaySubscription: Subscription;
  subscription: Subscription = new Subscription();
  downloadCustomerListRequest: DownloadCustomerRequest;
  showModal: boolean = false;
  searchResultsCBToolTip: string = "";
  showTooltipForCB: boolean = false;
  statusCBvalueToShow: any;
  headerNames: any;
  region: string;
  downloadType: number;
  constructor(
    private httpServ: HttpService,
    private _paServ: PaService,
    private appServ: AppService,
    private gridService: CpAgGridService, @Inject(LOCALE_ID) private locale: string,
    private translateServ: TranslateService, private _globalAppService: GlobalAppService) { }

  agInit(params: any): void {
    this.params = params;
    this.region = this._globalAppService.getDefaultRegionString();
    let custFavPAList = this.appServ.getFavoritePA();
    if (params) {

      this.showTooltipForCB = this.gridService.pageContext.pageName == 'recentActivity' ||
        this.gridService.pageContext.pageName == 'pendingTileGrid' ||
        this.gridService.pageContext.pageName == 'issuedTileGrid' ||
        this.gridService.pageContext.pageName == 'deniedTileGrid' ||
        this.gridService.pageContext.pageName == 'unclaimedTileGrid' ||
        this.gridService.pageContext.pageName == 'searchResultsCB' ? true : false;

      if (custFavPAList)
        this.custIdalreadyfavorite = custFavPAList.find((item: any) => {
          return item.customerId === params.value;
        })

      if (this.custIdalreadyfavorite) {
        this.custIdfavoriteCSS = "fa fa-heart favbtn";
        this.isCustIdFavorite = true;
      }
      else {
        this.custIdfavoriteCSS = "";
        this.isCustIdFavorite = false;
      }

      this.statusCBvalueToShow = null;
      switch (this.params.colDef.headerName) {
        case "agGrid.agGridColumns.statusCbLabel":
          this.statusCBvalueToShow = cbStatus.find(el => {
            return el.value == params.value
          });
          break;
        case "agGrid.agGridColumns.rvRbLabel":
          this.statusCBvalueToShow = cbRvRb.find(el => {
            return el.value == params.value
          });
          break;
        case "agGrid.agGridColumns.claimRequestTypeLabel":
          this.statusCBvalueToShow = cbClaimRequestType.find(el => {
            return el.value == params.value
          });
          if (this.region == "APAC") {
            this.statusCBvalueToShow = cbClaimRequestTypeAPAC.find(el => {
              return el.value == params.value
            });
          }
          break;
      }

    }
    this.loadTranslatedContent();
  }

  refresh(params: any): boolean {
    return false;
  }

  onCustomerFavClick(params) {
    this.isCustIdFavorite = !this.isCustIdFavorite;
    if (this.isCustIdFavorite) {
      // make service call to mark fav
      this.custIdMarkedToday = this.getTodaysDate();

      this.subscription.add(this.httpServ.markFavorite(this.contractId, params.value, this.custIdMarkedToday).subscribe((data) => {

      }));
    }
    else {
      // make service call to mark unfav
      this.custIdMarkedToday = this.getTodaysDate();
      this.subscription.add(this.httpServ.markUnFavorite(this.contractId, params.value, this.custIdMarkedToday).subscribe((data) => {

      }));
    }
  }

  onCustomerLinkHover(event, overlaypanel: OverlayPanel) {
    if (this.showTooltipForCB) {
      switch (this.params.colDef.headerName) {
        case "agGrid.agGridColumns.customerNumberLabel":
          let custNameAndAddress = this.params.data.customerName;
          if (custNameAndAddress) {
            if (this.params.data.address1) {
              custNameAndAddress += "<br/>" + this.params.data.address1;
            }
            if (this.params.data.address2) {
              custNameAndAddress += " <br/>" + this.params.data.address2;
            }
            if (this.params.data.city) {
              custNameAndAddress += "<br/>" + this.params.data.city;
            }
            if (this.params.data.state) {
              custNameAndAddress += ", " + this.params.data.state;
            }
            if (this.params.data.postalCode) {
              custNameAndAddress += ", " + this.params.data.postalCode;
            }
          }
          this.searchResultsCBToolTip = custNameAndAddress || this.params.data.customerNumber;
          break;
        case "agGrid.agGridColumns.statusCbLabel":
          // if ((this.params.value != "Pending" && this.params.value != "PD") || !this.params.data.receivedDate || !this.params.data.availableDate) return;
          if (this.region == "EMEA" || this.region == "LAO" || this.region == 'APAC') {
            //Fixed Defect 24298
            return;
          }
          let receivedDate: any;
          let availableDate: any;
          if (this.params.data.receivedDate != null) {
            receivedDate = this.region == 'EMEA' || this.region == 'APAC' ? formatDate(this.params.data.receivedDate, 'dd/MM/yyyy', this.locale) : formatDate(this.params.data.receivedDate, 'MM/dd/yyyy', this.locale);
          } else {
            receivedDate = null;
          }

          if (this.params.data.availableDate != null) {
            availableDate = this.region == 'EMEA' || this.region == 'APAC' ? formatDate(this.params.data.availableDate, 'dd/MM/yyyy', this.locale) : formatDate(this.params.data.availableDate, 'MM/dd/yyyy', this.locale);
          } else {
            availableDate = null;
          }
          let availableSection = availableDate ? this.headerNames.availableDate + " - " + availableDate : "";
          this.searchResultsCBToolTip = this.headerNames.receivedDate + " - " + receivedDate + "<br/>" + availableSection;
          break;
        default:
          return;
      }
      overlaypanel.hide();
      overlaypanel.show(event);
    } else {
      if (!this.isCustIdFavorite) {
        this.custIdfavoriteCSS = "far fa-heart favbtn";
      }
      this.customerNumber = this.params.data.customerNumber;
      this.overlaySubscription = this.httpServ.getCustomerInfo(this.customerNumber)
        .subscribe((data) => {
          if (data['customerInformation'] && data['customerInformation'].length > 0 && data['customerInformation'][0]) {
            let fullNameList = [];
            fullNameList.push(data['customerInformation'][0]['customer_Name_1'] || '');
            fullNameList.push(data['customerInformation'][0]['customer_Name_2'] || '');
            fullNameList.push(data['customerInformation'][0]['customer_Name_3'] || '');
            fullNameList.push(data['customerInformation'][0]['customer_Name_4'] || '');
            this.customerName = fullNameList.join(' ');
            this.street = data['customerInformation'][0]['street1'];
            this.city = data['customerInformation'][0]['city'];
            this.district = data['customerInformation'][0]['district_1'];
            this.country = data['customerInformation'][0]['country'];
            this.zipcode = data['customerInformation'][0]['zip_Code'];
            overlaypanel.show(event);
          }
        }, (err) => {
          console.log("Error");
        });
      this.subscription.add(this.overlaySubscription);
    }
  }

  onCustomerLinkMouseout(overlaypanel: OverlayPanel) {
    // if (this.gridService.pageContext.pageName !== 'recentDownload') {
    if (!this.isCustIdFavorite) {
      this.custIdfavoriteCSS = "";
    }
    else {
      this.custIdfavoriteCSS = "fa fa-heart favbtn";
    }
    overlaypanel.hide();
    if (this.overlaySubscription)
      this.overlaySubscription.unsubscribe();
  }
  // }

  getTodaysDate() {
    let today: any = new Date();
    let dd: any = today.getDate();
    let mm: any = today.getMonth() + 1;
    let yyyy: any = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    today = dd + '/' + mm + '/' + yyyy;
    return (today)
  }

  onLinkClick(event) {
    if (this.gridService.pageContext.pageName === 'recentDownload' && this.params.value === 'Multiple') {
      let request: DownloadCustomerRequest = new DownloadCustomerRequest();
      request.query = new Object();
      request.query.downloadRequestBatchId = this.params.data.downloadRequestBatchId;
      request.query.fileId = this.params.data.id;
      request.filter = new DownloadCustomerFilter();
      request.filter.customerNumber = "";
      request.filter.customerName = "";
      this.downloadCustomerListRequest = request;
      this.downloadType = this.params.data.downloadType;
      this.showModal = true;
    }
  }

  closedCustModal(event) {
    this.showModal = (event.close === 'true');
  }

  loadTranslatedContent() {
    this.translateServ.get(['agGrid']).subscribe(translations => {
      this.headerNames = translations['agGrid']['agGridCellTooltip'];
    });
  }

  ngOnDestroy() {
    // Required for unsubscribing and destroying the component  
  }
}



