import { Component } from '@angular/core';
import { createProductCodeLink } from "@app/conf/appLink";
import { GlobalAppService } from '@app/Modules/Shared/Services/global-app.service';
import { environment } from '@env/environment';
import { AgRendererComponent } from 'ag-grid-angular';

@Component({
  selector: 'app-productcode-hyperlink',
  templateUrl: './productcode-hyperlink.component.html',
  styleUrls: ['./productcode-hyperlink.component.scss']
})
export class ProductcodeHyperlinkComponent implements AgRendererComponent {

  constructor(private _globalAppService: GlobalAppService) { }
  params: any;
  encryptedproductid: any;

  agInit(params: any): void {
    this.params = params;
    if (params && params.data)
      this.encryptedproductid = params.data.encryptedproductid;

  }

  refresh(params: any): boolean {
    return false;
  }

  onLinkClick(event) {
    if(this._globalAppService.visiblePhaseIII(this._globalAppService.getDefaultRegionString().toUpperCase())){
      let catalogSearchURL = environment.magento.url + "/catalogsearch/result/?q=" + this.params.data.shortProductCode;
      window.open(catalogSearchURL, '_blank');
    } else {
      let redirectUrl: string = createProductCodeLink.createProductCodeURI;
      redirectUrl = redirectUrl.replace("####", this.encryptedproductid);
      window.open(redirectUrl, '_blank');
    }
  }

}
