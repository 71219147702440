import * as tslib_1 from "tslib";
import { OnDestroy } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { TranslateService } from '@ngx-translate/core';
var TextTranslationComponent = /** @class */ (function () {
    function TextTranslationComponent(translateServ) {
        var _this = this;
        this.translateServ = translateServ;
        this.translateServ.get(['dataConfigData.cbDiscrepancies'])
            .subscribe(function (translations) {
            _this.data = translations['dataConfigData.cbDiscrepancies'];
        });
    }
    TextTranslationComponent.prototype.agInit = function (params) {
        if (params && params.value && (params.value).toUpperCase() == 'YES') {
            this.params = this.data.Yes_key;
        }
        else if (params && params.value && (params.value).toUpperCase() == 'NO') {
            this.params = this.data.No_key;
        }
        else {
            return params.value;
        }
    };
    TextTranslationComponent.prototype.refresh = function (params) {
        return false;
    };
    TextTranslationComponent.prototype.ngOnDestroy = function () {
        // Required for unsubscribing and destroying the component  
    };
    TextTranslationComponent = tslib_1.__decorate([
        AutoUnsubscribe(),
        tslib_1.__metadata("design:paramtypes", [TranslateService])
    ], TextTranslationComponent);
    return TextTranslationComponent;
}());
export { TextTranslationComponent };
