/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./kcfile-contextmenu-manage-error.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "../../../../../../../node_modules/primeng/components/overlaypanel/overlaypanel.ngfactory";
import * as i5 from "primeng/components/overlaypanel/overlaypanel";
import * as i6 from "./kcfile-contextmenu-manage-error.component";
import * as i7 from "@angular/router";
import * as i8 from "../../../services/cp-ag-grid-service";
import * as i9 from "../../../../chargeback/Services/cb.service";
import * as i10 from "../../../../Shared/Services/global-app.service";
var styles_KcFileContextmenuManageErrorComponent = [i0.styles];
var RenderType_KcFileContextmenuManageErrorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_KcFileContextmenuManageErrorComponent, data: {} });
export { RenderType_KcFileContextmenuManageErrorComponent as RenderType_KcFileContextmenuManageErrorComponent };
function View_KcFileContextmenuManageErrorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "a", [["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.editLine($event, i1.ɵnov(_v.parent, 6)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "far fa-edit"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("chargeback.kcFile.contextMenu.editLine")); _ck(_v, 3, 0, currVal_0); }); }
export function View_KcFileContextmenuManageErrorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "span", [["class", "custom-table__row-menu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "i", [["class", "fas fa-ellipsis-h ellipsisbtn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.contextmenuToggle(i1.ɵnov(_v, 6), $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "ellipseSelect": 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 10, "p-overlayPanel", [["appendTo", "body"], ["styleClass", "contextMenu"]], null, [[null, "onHide"], [null, "onShow"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onHide" === en)) {
        var pd_0 = (_co.onContextMenuHide() !== false);
        ad = (pd_0 && ad);
    } if (("onShow" === en)) {
        var pd_1 = (_co.onContextMenuShow() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_OverlayPanel_0, i4.RenderType_OverlayPanel)), i1.ɵdid(6, 180224, [["contextmenu", 4]], 0, i5.OverlayPanel, [i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef, i1.NgZone], { styleClass: [0, "styleClass"], appendTo: [1, "appendTo"] }, { onShow: "onShow", onHide: "onHide" }), (_l()(), i1.ɵeld(7, 0, null, 0, 8, "span", [["class", "custom-table__popup__info custom-table__row-menu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 7, "ul", [["class", "custom-table__row-menu__content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_KcFileContextmenuManageErrorComponent_1)), i1.ɵdid(10, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 3, "a", [["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deleteLine($event, i1.ɵnov(_v, 6)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "i", [["class", "fa fa-times-circle-o textRed"]], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "fas fa-ellipsis-h ellipsisbtn"; var currVal_1 = _ck(_v, 4, 0, _co.ellipseSelect); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = "contextMenu"; var currVal_3 = "body"; _ck(_v, 6, 0, currVal_2, currVal_3); var currVal_4 = ((((_co.params == null) ? null : ((_co.params.data == null) ? null : _co.params.data.lineErrors)) != 0) && !_co.taxIdProdIdError); _ck(_v, 10, 0, currVal_4); }, function (_ck, _v) { var currVal_5 = i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 15).transform("chargeback.kcFile.contextMenu.deleteLine")); _ck(_v, 14, 0, currVal_5); }); }
export function View_KcFileContextmenuManageErrorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-kcfile-contextmenu-manage-error", [], null, null, null, View_KcFileContextmenuManageErrorComponent_0, RenderType_KcFileContextmenuManageErrorComponent)), i1.ɵdid(1, 114688, null, 0, i6.KcFileContextmenuManageErrorComponent, [i7.Router, i7.ActivatedRoute, i8.CpAgGridService, i9.CbService, i10.GlobalAppService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var KcFileContextmenuManageErrorComponentNgFactory = i1.ɵccf("app-kcfile-contextmenu-manage-error", i6.KcFileContextmenuManageErrorComponent, View_KcFileContextmenuManageErrorComponent_Host_0, {}, {}, []);
export { KcFileContextmenuManageErrorComponentNgFactory as KcFileContextmenuManageErrorComponentNgFactory };
