import { Component, OnInit } from '@angular/core';
import { invoicePriceCategories } from '@app/conf/dataConfigurations';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'app-category-col-tooltip',
  templateUrl: './category-col-tooltip.component.html',
  styleUrls: ['./category-col-tooltip.component.scss']
})
export class CategoryColTooltipComponent implements OnInit {

  params: any;
  categoryList: any = [];
  invoicePriceCategories: Array<any>;
  constructor() { }

  agInit(params: any) {
    this.params = params;
    this.invoicePriceCategories = invoicePriceCategories;

    let tempList = params.data.categoryList;
     this.categoryList = this.invoicePriceCategories.filter((el) => {
      return tempList.indexOf(el.value) > -1;
    });
  }

  ngOnInit() {

  }

  showCustomerInfoTooltip(event, overlaypanel: OverlayPanel) {
    overlaypanel.show(event);
  }

  hideCustomerInfoTooltip(overlaypanel: OverlayPanel) {
    overlaypanel.hide();
  }

}
