import { HubConnectionBuilder } from '@aspnet/signalr';
import { environment } from '@env/environment';
import { Subject } from 'rxjs';
import { HttpChargeBackService } from './http-charge-back.service';
import { HttpService } from './http.service';
import { OktaAuthService } from '@okta/okta-angular';
import * as i0 from "@angular/core";
import * as i1 from "./http-charge-back.service";
import * as i2 from "./http.service";
import * as i3 from "@okta/okta-angular";
var SignalRMaster = /** @class */ (function () {
    function SignalRMaster(httpServiceCB, httpServicePA, oktaAuth) {
        this.httpServiceCB = httpServiceCB;
        this.httpServicePA = httpServicePA;
        this.oktaAuth = oktaAuth;
        this.methodName = 'GetData';
        this.onMessageReceived = new Subject();
    }
    SignalRMaster.prototype.initSignalR_Download = function (connectionCategory) {
        var _this = this;
        var params = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            params[_i - 1] = arguments[_i];
        }
        return this.oktaAuth.getAccessToken().then(function (token) {
            var accessToken = 'Bearer ' + token;
            params[params.indexOf('TOKEN')] = accessToken;
            _this.createConnection(connectionCategory);
            if (connectionCategory === 'NA-MC') {
                _this.methodName = 'GetMCData';
            }
            _this.registerEvents();
            return _this.startConnection(params);
        });
    };
    SignalRMaster.prototype.createConnection = function (connectionCategory) {
        var signalRUrl = "";
        switch (connectionCategory) {
            case 'EMEA-PA':
                signalRUrl = this.httpServicePA.getRegionSpecificURL(environment.signalREndpointPA);
                break;
            case 'EMEA-RP':
                signalRUrl = this.httpServicePA.getRegionSpecificURL(environment.signalREndpointPA);
                break;
            case 'NA-CB':
                signalRUrl = this.httpServiceCB.getRegionSpecificURL(environment.signalREndpoint);
                break;
            case 'NA-MC':
                signalRUrl = this.httpServiceCB.getRegionSpecificURL(environment.signalREndpointMC);
                break;
        }
        console.log("####", signalRUrl);
        this.hubConnection = new HubConnectionBuilder().withUrl(signalRUrl).build();
    };
    SignalRMaster.prototype.startConnection = function (params) {
        var _this = this;
        this.hubConnection.start().then(function () {
            var _a;
            console.log("Params####", params);
            (_a = _this.hubConnection).send.apply(_a, [_this.methodName].concat(params)).then(function () {
                _this.onMessageReceived.next("start");
            });
        }).catch(function (err) {
            console.error(err);
            _this.stopConnection();
        });
    };
    SignalRMaster.prototype.stopConnection = function () {
        if (this.hubConnection && !this.hubConnection.state)
            this.hubConnection.stop();
    };
    SignalRMaster.prototype.registerEvents = function () {
        var _this = this;
        this.hubConnection.off(this.methodName, function (response, data) {
            console.log("Connection closed.");
        });
        this.hubConnection.on(this.methodName, function (response, data) {
            console.log("@@@@@ Signal R Data: ", data);
            var parsedData = JSON.parse(data);
            if (parsedData.fileUrl) {
                window.open(parsedData.fileUrl, '_blank');
            }
            setTimeout(function () {
                _this.onMessageReceived.next(parsedData);
            });
        });
        // Callback if connection gets closed
        this.hubConnection.onclose(function (error) {
            console.log("error on close", error);
        });
    };
    SignalRMaster.prototype.getMessageObservable = function () {
        return this.onMessageReceived.asObservable();
    };
    SignalRMaster.ngInjectableDef = i0.defineInjectable({ factory: function SignalRMaster_Factory() { return new SignalRMaster(i0.inject(i1.HttpChargeBackService), i0.inject(i2.HttpService), i0.inject(i3.OktaAuthService)); }, token: SignalRMaster, providedIn: "root" });
    return SignalRMaster;
}());
export { SignalRMaster };
