import { Component, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { CpAgGridService } from '@app/Modules/cp-grid/services/cp-ag-grid-service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { MyProfileService } from '@app/Modules/my-profile/Components/services/my-profile-service';
import { MessageService } from 'primeng/api';
import { GlobalAppService } from '@app/Modules/Shared/Services/global-app.service';
import { HttpInvoicePriceService } from '@app/Modules/Shared/Services/http-invoice-price.service';
import { InvoicePriceService } from '@app/Modules/in-voice/Services/invoice-price.service';

@AutoUnsubscribe()
@Component({
    selector: 'cp-column-header',
    templateUrl: './cp-column-header.component.html',
    styleUrls: ['./cp-column-header.component.scss'],
    providers: [MessageService]
})

export class CpColumnHeaderComponent implements OnDestroy {
    public params: any;
    private ascSort: string;
    private descSort: string;
    private noSort: string;
    subscription: Subscription = new Subscription();

    @ViewChild('menuButton', { read: ElementRef }) public menuButton;

    ifHovered: boolean = false;
    gridOptions: any = {};
    //status:boolean;
    gridApi: any;
    checkboxStatus: string = '';

    checkboxName: string;

    addToPrefCheckBoxStatus: boolean = false;
    headerCheckBoxStatusPA: boolean = false;
    headerCheckBoxStatusMC: boolean = false;
    headerCheckBoxStatusCB: boolean = false;
    headerCheckBoxStatusIN: boolean = false;
    headerCheckBoxStatusOD: boolean = false;
    isInternalUser: boolean;
    isMyProfileInternal: boolean = false;
    partialCheckBoxStatus: boolean = false;
    partialPACheckBoxStatus: boolean = false;
    tolltipHoverClass: string = "tooltip-hover";
    region: string;
    isAllFavorite: boolean;
    isGlobalUser: boolean = false;

    efileManageErrorColumnIds = ['reF_NUM', 'custloc', 'd_BRANCH','item_NBR', 'kC_ENDU', 'eU_NME', 'shtO_NBR', 'shtO_NME', 'shtO_AD1', 'shtO_AD2', 'shtO_CTY', 'shtO_ST', 'shtO_ZIP', 'shtO_CTRY', 'proD_ID', 'd_PROD', 'invC_DTE', 'invC_NBR', 'cases', 'unT_MSR', 'itW_PRC', 'pA_PRC', 'exT_RBT', 'pA_NBR','hierarchy','bilL_TO_NBR', 'bilL_TO_NME', 'gpO_ID', "clM_EN_DTE", "clM_ST_DTE", "curr", "invC_TOT_AMT", "d_PA_PRC", "d_ITW_PRC", "vaL_FRM", "vaL_TO", "kC_PA_PRC", "kC_ITW_PRC", "kC_EXT_RBT", "d_EXT_RBT", "diff", "observ", "proD_DESC", "taX_ID"];
    showEfileErrorColumnToolTip = {};

    invoicePriceColumnIds = ['lastRefreshDate_invoice', 'refreshStatus_invoice', 'nextAvailableDate_invoice'];
    shownIvoicePriceColumnToolTip = {};
    isPageInvoiceRecentRequest: boolean = false;

    gridName: string = "";

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private gridService: CpAgGridService,
        private appServ: AppService,
        private globalAppService: GlobalAppService,
        private messageService: MessageService,
        private myProfileService: MyProfileService,
        private httpInvoiceService: HttpInvoicePriceService,
        private invoicePriceService: InvoicePriceService) {
        if (this.router.url.indexOf('profile/myprofile') > -1) {
            this.isMyProfileInternal = true;
        }
    }

    agInit(params): void {
        this.params = params;
        this.isEfileManageErrorColumnId(params.column.colId);
        if (params.column.colId == 'customerNumber_invoice' || params.column.colId == 'lastRefreshDate_invoice' || params.column.colId == 'refreshStatus_invoice' || params.column.colId == 'nextAvailableDate_invoice') {
            this.isInvoicePriceColumnId(params.column.colId);
        }

        // this.subscription.add(this.languageServ.currentSelected.subscribe((language) => {
        //     this.translateServ.setDefaultLang(language.regionCode);
        // }));
        params.column.addEventListener('sortChanged', this.onSortChanged.bind(this));
        this.onSortChanged();

        this.params.api.forEachNode((node) => {
            if (node.data && node.data._cpCheckbox === false) {
            }
        });
        if (!this.checkboxName) {
            this.checkboxName = Math.random().toString(36).substring(2, 15);
        }
    }

    ngOnInit() {


        this.subscription.add(this.gridService.getRowCheckboxSelection().subscribe(data => {
            this.checkboxStatus = data;
            this.isAllFavorite = this.checkFavIconSelectionStatus();
        }));

        this.isInternalUser = this.appServ.isInternalUser;
        this.region = this.globalAppService.getDefaultRegionString();

        //Column my profile PA Header checkbox
        this.subscription.add(this.gridService.getnodeMyProfilePASelectAll().subscribe(data => {
            switch (data) {
                case "ALL":
                    this.headerCheckBoxStatusPA = true;
                    break;
                case "NONE":
                    this.headerCheckBoxStatusPA = false;
                    break;
            }
        }));

        //Column my profile Meet comp Header checkbox
        this.subscription.add(this.gridService.getnodeMyProfileMCSelectAll().subscribe(data => {
            switch (data) {
                case "ALL":
                    this.headerCheckBoxStatusMC = true;
                    break;
                case "NONE":
                    this.headerCheckBoxStatusMC = false;
                    break;
            }
        }));

        //Column my profile Charge back Header checkbox
        this.subscription.add(this.gridService.getnodeMyProfileCBSelectAll().subscribe(data => {
            switch (data) {
                case "ALL":
                    this.headerCheckBoxStatusCB = true;
                    break;
                case "NONE":
                    this.headerCheckBoxStatusCB = false;
                    break;
            }
        }));

        //Column my profile Invoices Header checkbox
        this.subscription.add(this.gridService.getnodeMyProfileINSelectAll().subscribe(data => {
            switch (data) {
                case "ALL":
                    this.headerCheckBoxStatusIN = true;
                    break;
                case "NONE":
                    this.headerCheckBoxStatusIN = false;
                    break;
            }
        }));

        //Column my profile Orders Header checkbox
        this.subscription.add(this.gridService.getnodeMyProfileODSelectAll().subscribe(data => {
            switch (data) {
                case "ALL":
                    this.headerCheckBoxStatusOD = true;
                    break;
                case "NONE":
                    this.headerCheckBoxStatusOD = false;
                    break;
            }
        }));

        this.subscription.add(this.invoicePriceService.getUpdateFavColHeader().subscribe(update => {
            if (update) {
                this.isAllFavorite = this.checkFavIconSelectionStatus();
            }
        }));
        this.isGlobalUser = this.appServ.isGlobalUser;

        if (this.gridService.pageContext && this.gridService.pageContext.pageName && this.gridService.pageContext.pageName == 'recentRequests') {
            this.isPageInvoiceRecentRequest = true;
        }

        this.gridService.getGridLoaded().subscribe((gridLoaded) => {
            if (this.gridService.pageContext && this.gridService.pageContext.pageName) {
                this.gridName = this.gridService.pageContext.pageName;
            }
        });


    }

    onMenuClicked() {
        this.params.showColumnMenu(this.menuButton.nativeElement);
    };

    onCellMouseOver() {
        this.ifHovered = true;
    }

    onCellMouseOut() {
        this.ifHovered = false;
    }

    onSortRequested(order, event) {
        this.params.setSort(order, event.shiftKey);
    }

    onSortChanged() {
        this.ascSort = this.descSort = this.noSort = 'inactive';
        if (this.params.column.isSortAscending()) {
            this.ascSort = 'active';
        } else if (this.params.column.isSortDescending()) {
            this.descSort = 'active';
        } else {
            this.noSort = 'active';
        }
    }

    selectAllNodes(event) {
        // this.checkboxStatus = event.target.checked;
        let startRowIndex = this.params.api.getFirstDisplayedRow();
        let lastRowIndex = this.params.api.getLastDisplayedRow();

        if (lastRowIndex >= 0) {
            for (let ctr = startRowIndex; ctr <= lastRowIndex; ctr++) {
                if (this.region.toUpperCase() == 'EMEA') {
                    if (this.params.api.getDisplayedRowAtIndex(ctr).data.status != "PD" &&
                        this.params.api.getDisplayedRowAtIndex(ctr).data.status != "Pending" &&
                        this.params.api.getDisplayedRowAtIndex(ctr).data.status != "B" &&
                        this.params.api.getDisplayedRowAtIndex(ctr).data.status != "Denied" &&
                        this.params.api.getDisplayedRowAtIndex(ctr).data.refreshStatus != 1) {
                        this.params.api.getDisplayedRowAtIndex(ctr).setSelected(event.target.checked);
                    }
                }
                else {
                    if (this.params.api.getDisplayedRowAtIndex(ctr).data.status != "PD" &&
                        this.params.api.getDisplayedRowAtIndex(ctr).data.status != "Pending" &&
                        this.params.api.getDisplayedRowAtIndex(ctr).data.refreshStatus != 1) {
                        this.params.api.getDisplayedRowAtIndex(ctr).setSelected(event.target.checked);
                    }
                }
            }
        }
    }

    selectAllNodesAddToPref(event) {
        this.appServ.setLoadingStatus(true);
        this.addToPrefCheckBoxStatus = event.target.checked;
        if (this.region == "EMEA" && !this.addToPrefCheckBoxStatus) {
            this.setCheckboxStatus("PACheckBoxHeader", this.addToPrefCheckBoxStatus);
            this.setCheckboxStatus("CBCheckBoxHeader", this.addToPrefCheckBoxStatus);
        } else if (this.region == "LAO" && !this.addToPrefCheckBoxStatus) {
            this.setCheckboxStatus("PACheckBoxHeader", this.addToPrefCheckBoxStatus);
            this.setCheckboxStatus("CBCheckBoxHeader", this.addToPrefCheckBoxStatus);
        } else if (this.region == "NA" && !this.addToPrefCheckBoxStatus) {
            this.setCheckboxStatus("PACheckBoxHeader", this.addToPrefCheckBoxStatus);
            this.setCheckboxStatus("MCCheckBoxHeader", this.addToPrefCheckBoxStatus);
            this.setCheckboxStatus("CBCheckBoxHeader", this.addToPrefCheckBoxStatus);
            this.setCheckboxStatus("INCheckBoxHeader", this.addToPrefCheckBoxStatus);
            this.setCheckboxStatus("ODCheckBoxHeader", this.addToPrefCheckBoxStatus);
        } else if (this.region == "APAC" && !this.addToPrefCheckBoxStatus) {
            this.setCheckboxStatus("PACheckBoxHeader", this.addToPrefCheckBoxStatus);
            this.setCheckboxStatus("CBCheckBoxHeader", this.addToPrefCheckBoxStatus);
        }

        this.gridService.gridApi.forEachNode((node) => {
            node.data.isDefault = event.target.checked;
            this.setCheckboxStatus("ATP-" + node.data.customerNumber, this.addToPrefCheckBoxStatus);
            /** If unselect all, do not set checked to false for default location's checkbox so, 
             * when you check the add to pref one by one at that time after clicking on last item 
             * make the header checkbox should get checked 
             * */
            if (!this.addToPrefCheckBoxStatus && node.data.customerNumber == this.myProfileService.getPreveiousSelectedLoc()) {
                this.setCheckboxStatus("ATP-" + node.data.customerNumber, true);
                node.data.isDefault = true;
            }
            let isDfaultLocationMatching = node.data.customerNumber == this.myProfileService.getPreveiousSelectedLoc()
            /** Update the dailly mails Prefernces object and checkstatus except default location's*/
            if (this.region == "EMEA" && !this.addToPrefCheckBoxStatus && !isDfaultLocationMatching) {
                node.data.Preferences[0].isSelected = event.target.checked;
                this.setCheckboxStatus("PA-" + node.data.customerNumber, this.addToPrefCheckBoxStatus);
                node.data.Preferences[1].isSelected = event.target.checked;
                this.setCheckboxStatus("CB-" + node.data.customerNumber, this.addToPrefCheckBoxStatus);
            } else if (this.region == "LAO" && !this.addToPrefCheckBoxStatus && !isDfaultLocationMatching) {
                node.data.Preferences[0].isSelected = event.target.checked;
                this.setCheckboxStatus("PA-" + node.data.customerNumber, this.addToPrefCheckBoxStatus);
                node.data.Preferences[1].isSelected = event.target.checked;
                this.setCheckboxStatus("CB-" + node.data.customerNumber, this.addToPrefCheckBoxStatus);
            } else if (this.region == "NA" && !this.addToPrefCheckBoxStatus && !isDfaultLocationMatching) {
                node.data.Preferences[0].isSelected = event.target.checked;
                this.setCheckboxStatus("PA-" + node.data.customerNumber, this.addToPrefCheckBoxStatus);
                node.data.Preferences[1].isSelected = event.target.checked;
                this.setCheckboxStatus("MC-" + node.data.customerNumber, this.addToPrefCheckBoxStatus);
                node.data.Preferences[2].isSelected = event.target.checked;
                this.setCheckboxStatus("CB-" + node.data.customerNumber, this.addToPrefCheckBoxStatus);
                node.data.Preferences[3].isSelected = event.target.checked;
                this.setCheckboxStatus("IN-" + node.data.customerNumber, this.addToPrefCheckBoxStatus);
                node.data.Preferences[4].isSelected = event.target.checked;
                this.setCheckboxStatus("OD-" + node.data.customerNumber, this.addToPrefCheckBoxStatus);
            } else if (this.region == "APAC" && !this.addToPrefCheckBoxStatus && !isDfaultLocationMatching) {
                node.data.Preferences[0].isSelected = event.target.checked;
                this.setCheckboxStatus("PA-" + node.data.customerNumber, this.addToPrefCheckBoxStatus);
                node.data.Preferences[1].isSelected = event.target.checked;
                this.setCheckboxStatus("CB-" + node.data.customerNumber, this.addToPrefCheckBoxStatus);
            }
        });
        setTimeout(() => {
            this.appServ.setLoadingStatus(false);
        }, 500);
    }

    setCheckboxStatus(checkboxName: string, status: boolean) {
        let el: HTMLInputElement = <HTMLInputElement>document.getElementById(checkboxName);
        if (el) {
            el.checked = status;
        }
    }

    selectAllNodesMyProfilePA(event) {
        this.headerCheckBoxStatusPA = event.target.checked;
        if (this.headerCheckBoxStatusPA) {
            this.setCheckboxStatus("addToPrefCheckBoxHeader", this.headerCheckBoxStatusPA);
        }
        console.log('this.gridService.gridApiiiiiii', this.gridService.gridApi);
        this.gridService.gridApi.forEachNode((node) => {
            if (node.data && node.data.customerNumber) {
                console.log('node.dataaaaa', node.data);
                node.data.Preferences.find(x => {
                    return x.moduleId == 1;
                }).isSelected = event.target.checked;
            }
            if (this.headerCheckBoxStatusPA && !this.isInternalUser) {
                node.data.isDefault = event.target.checked;
                this.setCheckboxStatus("ATP-" + node.data.customerNumber, this.headerCheckBoxStatusPA);
            }
        });
        if (this.isInternalUser) {
            this.gridService.setMyProfilePASelectAll(this.headerCheckBoxStatusPA);
        }
        else {
            this.gridService.gridApi.forEachNode((node) => {
                if (node && node.data && node.data.Preferences[0]) {
                    node.data.Preferences[0].isSelected = event.target.checked;
                    this.setCheckboxStatus("PA-" + node.data.customerNumber, this.headerCheckBoxStatusPA);
                }
            });
        }
    }

    selectAllNodesMyProfileMC(event) {
        this.headerCheckBoxStatusMC = event.target.checked;
        if (this.headerCheckBoxStatusMC) {
            this.setCheckboxStatus("addToPrefCheckBoxHeader", this.headerCheckBoxStatusMC);
        }
        this.gridService.gridApi.forEachNode((node) => {
            if (node.data && node.data.customerNumber) {
                node.data.Preferences.find(x => {
                    return x.moduleId == 2;
                }).isSelected = event.target.checked;
                if (this.headerCheckBoxStatusMC && !this.isInternalUser) {
                    node.data.isDefault = event.target.checked;
                    this.setCheckboxStatus("ATP-" + node.data.customerNumber, this.headerCheckBoxStatusMC);
                }
            }
        });
        if (this.isInternalUser) {
            this.gridService.setMyProfileMCSelecAll(this.headerCheckBoxStatusMC);
        } else {
            this.gridService.gridApi.forEachNode((node) => {
                node.data.Preferences[1].isSelected = event.target.checked;
                this.setCheckboxStatus("MC-" + node.data.customerNumber, this.headerCheckBoxStatusMC);
            });
        }
    }

    selectAllNodesMyProfileCB(event) {
        this.headerCheckBoxStatusCB = event.target.checked;
        if (this.headerCheckBoxStatusCB) {
            this.setCheckboxStatus("addToPrefCheckBoxHeader", this.headerCheckBoxStatusCB);
        }
        this.gridService.gridApi.forEachNode((node) => {
            if (node.data && node.data.customerNumber) {
                node.data.Preferences.find(x => {
                    return x.moduleId == 3;
                }).isSelected = event.target.checked;
                if (this.headerCheckBoxStatusCB && !this.isInternalUser) {
                    node.data.isDefault = event.target.checked;
                    this.setCheckboxStatus("ATP-" + node.data.customerNumber, this.headerCheckBoxStatusCB);
                }
            }
        });
        if (this.isInternalUser) {
            this.gridService.setMyProfileCBSelecAll(this.headerCheckBoxStatusCB);
        } else {
            this.gridService.gridApi.forEachNode((node) => {
                if (node && node.data && (node.data.regionId != "EMEA" && node.data.regionId != "LAO" && node.data.regionId != "APAC") && node.data.Preferences[2]) {
                    node.data.Preferences[2].isSelected = event.target.checked;
                    this.setCheckboxStatus("CB-" + node.data.customerNumber, this.headerCheckBoxStatusCB);
                } else if (node && node.data && (node.data.regionId == "EMEA" || node.data.regionId == "LAO" || node.data.regionId == "APAC") && node.data.Preferences[1]) {
                    node.data.Preferences[1].isSelected = event.target.checked;
                    this.setCheckboxStatus("CB-" + node.data.customerNumber, this.headerCheckBoxStatusCB);
                }
            });
        }
    }

    selectAllNodesMyProfileIN(event) {
        this.headerCheckBoxStatusIN = event.target.checked;
        if (this.headerCheckBoxStatusIN) {
            this.setCheckboxStatus("addToPrefCheckBoxHeader", this.headerCheckBoxStatusIN);
        }
        this.gridService.gridApi.forEachNode((node) => {
            if (node.data && node.data.customerNumber) {
                node.data.Preferences.find(x => {
                    return x.moduleId == 4;
                }).isSelected = event.target.checked;
                if (this.headerCheckBoxStatusIN && !this.isInternalUser) {
                    node.data.isDefault = event.target.checked;
                    this.setCheckboxStatus("ATP-" + node.data.customerNumber, this.headerCheckBoxStatusIN);
                }
            }
        });
        if (this.isInternalUser) {
            this.gridService.setMyProfileINSelecAll(this.headerCheckBoxStatusIN);
        } else {
            this.gridService.gridApi.forEachNode((node) => {
                node.data.Preferences[3].isSelected = event.target.checked;
                this.setCheckboxStatus("IN-" + node.data.customerNumber, this.headerCheckBoxStatusIN);
            });
        }
    }

    selectAllNodesMyProfileOD(event) {
        this.headerCheckBoxStatusOD = event.target.checked;
        if (this.headerCheckBoxStatusOD) {
            this.setCheckboxStatus("addToPrefCheckBoxHeader", this.headerCheckBoxStatusOD);
        }
        this.gridService.gridApi.forEachNode((node) => {
            if (node.data && node.data.customerNumber) {
                node.data.Preferences.find(x => {
                    return x.moduleId == 5;
                }).isSelected = event.target.checked;
                if (this.headerCheckBoxStatusOD && !this.isInternalUser) {
                    node.data.isDefault = event.target.checked;
                    this.setCheckboxStatus("ATP-" + node.data.customerNumber, this.headerCheckBoxStatusOD);
                }
            }
        });
        if (this.isInternalUser) {
            this.gridService.setMyProfileODSelecAll(this.headerCheckBoxStatusOD);
        } else {
            this.gridService.gridApi.forEachNode((node) => {
                node.data.Preferences[4].isSelected = event.target.checked;
                this.setCheckboxStatus("OD-" + node.data.customerNumber, this.headerCheckBoxStatusOD);
            });
        }
    }

    displayLocationmodal() {
        this.router.navigate([{ outlets: { dialogs: ['locationSearch'] } }], { queryParamsHandling: 'preserve', relativeTo: this.activatedRoute.parent });
    }

    showChangeGroupModal(isChangeGroup) {
        this.gridApi = this.gridService.gridApi;
        const selectedRows = this.gridApi.getSelectedRows();
        let defaultLocation = this.globalAppService.getDefaultProfileLocation();
        for(let selectedLocationIndex=0; selectedLocationIndex<=selectedRows.length - 1; selectedLocationIndex++){
            if(selectedRows[selectedLocationIndex].customerNumber == defaultLocation){
                selectedRows.splice(selectedLocationIndex,1);
            }
        }        
        console.log("@@@ Selected Rows: ", selectedRows);
        if (selectedRows && selectedRows.length) {
            this.router.navigate([{ outlets: { dialogs: ['selectGroup'] } }],
                {
                    queryParamsHandling: 'preserve', relativeTo: this.activatedRoute.parent,
                    state: { isChangeGroup: isChangeGroup, selectedRows: selectedRows }
                });
        } else {
            this.messageService.clear();
            this.messageService.add({ key: 'locationError', severity: 'error', summary: 'messageText.selectLocationError.header', detail: 'messageText.selectLocationError.text' });
        }
    }


    removeSelectedLocations() {
        this.gridApi = this.gridService.gridApi;
        const selectedRows = this.gridApi.getSelectedRows();
        this.myProfileService.setSelectedLocations(selectedRows);
    }

    showTooltip(event) {
        this.tolltipHoverClass = this.gridService.getTooltipHoverClass(event);
    }

    addRemoveFav(isFav) {
        let startRowIndex = this.params.api.getFirstDisplayedRow();
        let lastRowIndex = this.params.api.getLastDisplayedRow();
        let categoryList = [];
        if (lastRowIndex >= 0) {
            for (let ctr = startRowIndex; ctr <= lastRowIndex; ctr++) {
                let rowData = this.params.api.getDisplayedRowAtIndex(ctr).data;
                let obj = {
                    categoryCode: rowData.categoryCode,
                    isFavorite: isFav
                }
                categoryList.push(obj);
            }
            this.setOrRemoveFavoriteHttpCall(categoryList);
        }
    }

    setOrRemoveFavoriteHttpCall(categoryList) {
        this.appServ.setLoadingStatus(true);
        let request = {
            userId: this.appServ.getuserId(false),
            favoriteCategoryList: categoryList
        }
        this.httpInvoiceService.saveFavoriteInvoiceCategory(request).subscribe(res => {
            if (res.statusMessage == "Success") {
                this.invoicePriceService.setRedrawAdvancedGridRow(categoryList);
            }
        }, () => {

        }, () => {
            this.appServ.setLoadingStatus(false);
        });
    }

    checkFavIconSelectionStatus(): boolean {
        let startRowIndex = this.params.api.getFirstDisplayedRow();
        let lastRowIndex = this.params.api.getLastDisplayedRow();
        if (lastRowIndex >= 0) {
            for (let ctr = startRowIndex; ctr <= lastRowIndex; ctr++) {
                if (this.params.api.getDisplayedRowAtIndex(ctr).data && !this.params.api.getDisplayedRowAtIndex(ctr).data.isFavorite)
                    return false;
            }
            return true;
        }
        return true;
    }

    openOrderPreferencePopup() {
        this.router.navigate([{ outlets: { dialogs: ['orderPreferences'] } }], { relativeTo: this.activatedRoute.parent, queryParamsHandling: "preserve" });
    }

    isEfileManageErrorColumnId(id) {
        this.showEfileErrorColumnToolTip[id] = this.efileManageErrorColumnIds.indexOf(id) > -1;
    }

    isInvoicePriceColumnId(id) {
        this.shownIvoicePriceColumnToolTip[id] = this.invoicePriceColumnIds.indexOf(id) > -1;
    }

    ngOnDestroy() {
        //Destroying component
    }
}
