<div>
    <cp-sort-component #sortComponent [hidden]="hideSort" [colId]="colId" [orgColId]="orgColId" (onSortRequested)="onSortRequested($event)"></cp-sort-component>
    <ng-container *ngIf="fullFilterList && fullFilterList?.length > 5">
        <!-- <div class="form-group custom-table__thead-popup__search" (keyup.enter)="onFilter($event)">
      <label class="hidden-text-for-accessibility" for="Search">Search</label>
      <input tabindex="1" #input id="Search" [(ngModel)]="text" class="form-control" placeholder="Search..."
        maxlength="maxLength" autofocus>
      <i class="custom-table__thead-popup__search__button fas fa-search" (keyup.enter)="onFilter($event)"
        (click)="onFilter($event)"></i>
    </div> -->
        <div class="selectAll">
            <label class="custom-checkbox">

        <span>{{ translateService.instant('multiSelectFilter.selectAll') === 'multiSelectFilter.selectAll' ? "Select All" : "multiSelectFilter.selectAll" | translate}}</span>
        <input (change)="selectAllChange()" type="checkbox" name="SelectAllValue" value="SelectAll"
          [(ngModel)]="SelectAllValue">
        <span class="checkmark"></span>
      </label>
        </div>
    </ng-container>
    <div class="container custom-table-clear-filters" *ngIf="fullFilterList && fullFilterList.length>0">
        <div style="width:170px">
            <label class="custom-checkbox" *ngFor="let filter of filters">
        <span>{{filter.text | translate}}</span>
        <input type=checkbox name="filter.text" value="filter.value" [checked]="filter.selected"
          [(ngModel)]="filter.selected" (change)="onClick($event, filter)">
        <span class="checkmark"></span>
      </label>
        </div>
    </div>
    <div class="buttonWrapper" *ngIf="fullFilterList && fullFilterList.length>0">
        <button class="btn btn-primary btn-primary--disabled btn-primary--icon-left" [disabled]="!isSelected" [class.btn-primary--disabled]="!isSelected" (click)="onApplyClick($event)">
      <i class="far fa-check-circle"></i>
      {{ "calender.apply" | translate }}
    </button>
        <span (click)="onRemoveFilter()" class="clearFilter">
      <i class="far fa-times-circle" aria-hidden="true"></i> {{ "calender.clear" | translate }}
    </span>
    </div>
</div>