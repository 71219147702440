/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./invoice-new-refresh-status.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./invoice-new-refresh-status.component";
var styles_InvoiceNewRefreshStatusComponent = [i0.styles];
var RenderType_InvoiceNewRefreshStatusComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InvoiceNewRefreshStatusComponent, data: {} });
export { RenderType_InvoiceNewRefreshStatusComponent as RenderType_InvoiceNewRefreshStatusComponent };
function View_InvoiceNewRefreshStatusComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "italicText"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("invoicePrice.dashboard.processingLabel")); _ck(_v, 1, 0, currVal_0); }); }
function View_InvoiceNewRefreshStatusComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "greenText"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("invoicePrice.dashboard.readyToDnlLabel")); _ck(_v, 1, 0, currVal_0); }); }
function View_InvoiceNewRefreshStatusComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "redText"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("invoicePrice.dashboard.refReqLabel")); _ck(_v, 1, 0, currVal_0); }); }
function View_InvoiceNewRefreshStatusComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", ""]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("invoicePrice.dashboard.blankLabel")); _ck(_v, 1, 0, currVal_0); }); }
export function View_InvoiceNewRefreshStatusComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_InvoiceNewRefreshStatusComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InvoiceNewRefreshStatusComponent_2)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InvoiceNewRefreshStatusComponent_3)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InvoiceNewRefreshStatusComponent_4)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.params.value == 1); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.params.value == 2); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.params.value == 3); _ck(_v, 5, 0, currVal_2); var currVal_3 = (_co.params.value == 4); _ck(_v, 7, 0, currVal_3); }, null); }
export function View_InvoiceNewRefreshStatusComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "invoice-new-refresh-status", [], null, null, null, View_InvoiceNewRefreshStatusComponent_0, RenderType_InvoiceNewRefreshStatusComponent)), i1.ɵdid(1, 114688, null, 0, i4.InvoiceNewRefreshStatusComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InvoiceNewRefreshStatusComponentNgFactory = i1.ɵccf("invoice-new-refresh-status", i4.InvoiceNewRefreshStatusComponent, View_InvoiceNewRefreshStatusComponent_Host_0, {}, {}, []);
export { InvoiceNewRefreshStatusComponentNgFactory as InvoiceNewRefreshStatusComponentNgFactory };
