/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./kcfile-multiple-popup.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../../components/download-customer-list/download-customer-list.component.ngfactory";
import * as i4 from "../../../services/cp-ag-grid-service";
import * as i5 from "../../../../Shared/Services/http.service";
import * as i6 from "../../../../Shared/Services/global-app.service";
import * as i7 from "../../../../Shared/Services/app.service";
import * as i8 from "../../../../Shared/Services/http-charge-back.service";
import * as i9 from "../../../../Shared/Services/http-meet-comp.service";
import * as i10 from "../../../../Shared/Services/http-invoice-price.service";
import * as i11 from "../../../../Shared/Services/http-invoice-price-new.service";
import * as i12 from "@angular/router";
import * as i13 from "../../../components/download-customer-list/download-customer-list.component";
import * as i14 from "@angular/common";
import * as i15 from "./kcfile-multiple-popup.component";
var styles_KCFileMultiplePopup = [i0.styles];
var RenderType_KCFileMultiplePopup = i1.ɵcrt({ encapsulation: 0, styles: styles_KCFileMultiplePopup, data: {} });
export { RenderType_KCFileMultiplePopup as RenderType_KCFileMultiplePopup };
function View_KCFileMultiplePopup_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "multiple-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onLinkClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", "\n"])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("chargeback.multipleLabel")); _ck(_v, 1, 0, currVal_0); }); }
function View_KCFileMultiplePopup_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.params.value; _ck(_v, 1, 0, currVal_0); }); }
function View_KCFileMultiplePopup_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "download-customer-list", [], null, [[null, "closedCustModal"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closedCustModal" === en)) {
        var pd_0 = (_co.closedCustModal($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_DownloadCustomerListComponent_0, i3.RenderType_DownloadCustomerListComponent)), i1.ɵprd(512, null, i4.CpAgGridService, i4.CpAgGridService, [i5.HttpService, i1.LOCALE_ID, i6.GlobalAppService, i2.TranslateService, i7.AppService, i8.HttpChargeBackService, i9.HttpMeetCompService, i10.HttpInvoicePriceService, i5.HttpService, i11.HttpInvoicePriceNewService, i12.Router, i12.ActivatedRoute]), i1.ɵdid(3, 245760, null, 0, i13.DownloadCustomerListComponent, [i4.CpAgGridService, i7.AppService, i5.HttpService, i6.GlobalAppService], { visibleCustModal: [0, "visibleCustModal"], searchObj: [1, "searchObj"], moduleName: [2, "moduleName"] }, { closedCustModal: "closedCustModal" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showModal; var currVal_1 = _co.multipleCustomerRequest; var currVal_2 = _co.moduleName; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_KCFileMultiplePopup_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_KCFileMultiplePopup_1)), i1.ɵdid(1, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_KCFileMultiplePopup_2)), i1.ɵdid(3, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_KCFileMultiplePopup_3)), i1.ɵdid(5, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.params.value == "Multiple"); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.params.value != "Multiple"); _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.showModal; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_KCFileMultiplePopup_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-kcfile-multiple-popup", [], null, null, null, View_KCFileMultiplePopup_0, RenderType_KCFileMultiplePopup)), i1.ɵdid(1, 49152, null, 0, i15.KCFileMultiplePopup, [], null, null)], null, null); }
var KCFileMultiplePopupNgFactory = i1.ɵccf("app-kcfile-multiple-popup", i15.KCFileMultiplePopup, View_KCFileMultiplePopup_Host_0, {}, {}, []);
export { KCFileMultiplePopupNgFactory as KCFileMultiplePopupNgFactory };
