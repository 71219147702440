import { Component, Inject, OnDestroy } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { LOCALE_ID } from '@angular/core';
import { LanguageSelectionService } from '@app/Modules/Shared/Services/language-selection.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import { currency } from '@app/conf/dataConfigurations';
import { TranslateService } from '@ngx-translate/core';
import { GlobalAppService } from '@app/Modules/Shared/Services/global-app.service';
import { CpAgGridService } from '@app/Modules/cp-grid/services/cp-ag-grid-service';

@AutoUnsubscribe()
@Component({
  selector: 'app-price-formatter',
  templateUrl: './price-formatter.component.html',
  styleUrls: ['./price-formatter.component.scss']
})

export class PriceFormatterComponent implements AgRendererComponent, OnDestroy {
  params: any;
  currencyCode: string;
  subscription: Subscription = new Subscription();
  formattedValue: boolean;
  numericValue: string;
  LCIDString: any;
  noShowCurrencySymbolCols = ["requested", "requestedAmount", "difference", "totalIssueAmount", "issued", "quantity", "invoicePrice", "pAPrice", "rebateAmount", "pATotalLine", "differences", "val"];
  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private translateServ: TranslateService,
    private languageServ: LanguageSelectionService,
    private globalAppServcie: GlobalAppService,
    private gridService: CpAgGridService
  ) {
    this.translateServ.get(['LCIDString'])
      .subscribe(value => {
        if (value)
          this.LCIDString = value.LCIDString;
      });
  }

  agInit(params: any): void {
    this.setLanguageListener();
    this.params = params;

    if (this.params && this.params.data) {
      if (this.params.data.currency) {
        let cur = currency.find(item => {
          return item.label == this.params.data.currency.trim();
        });
        this.currencyCode = cur && cur.value ? cur.value + " " : "";
      }
      // let str1 = parseFloat(this.params.value).toLocaleString("en-US", {
      //   minimumFractionDigits: 2
      // });
      const languageCode = this.globalAppServcie.getDefaultLanguageString();
      let str1 = this.globalAppServcie.getNumberCurrencyFormatted(languageCode, params.value, 'NUMBER', 2);

      if (this.globalAppServcie.getDefaultRegionString() === 'LAO') {
        // CR 1378 - Use . as thousand separator even for Peru and other sales org in LAO
        // let str2 = str1.replace(/\./g, '@');
        // let str3 = str2.replace(/,/g, '.');
        // let str4 = str3.replace(/@/g, ',');
        if (params.colDef.field == 'quantity' && params.value.indexOf(",") > 0) {
          let paramsValue = params.value.replace(",", "");
          str1 = this.globalAppServcie.getNumberCurrencyFormatted(languageCode, paramsValue, 'NUMBER', 2);
        }        

        if (this.noShowCurrencySymbolCols.indexOf(this.params.colDef.field) > -1) {
          this.numericValue = ' ' + str1;
        } else {
          this.numericValue = this.currencyCode + ' ' + str1;
        }
        if (params.colDef.field == 'differences' && params.data.lineType == 'C')
            this.numericValue = '';
      }
      else {
        if ((this.gridService.pageContext && (this.gridService.pageContext.pageName == 'searchResultsCB' ||
          this.gridService.pageContext.pageName == 'recentActivity' ||
          this.gridService.pageContext.pageName == 'issuedTileGrid' ||
          this.gridService.pageContext.pageName == 'deniedTileGrid' ||
          this.gridService.pageContext.pageName == 'pendingTileGrid' ||
          this.gridService.pageContext.pageName == 'couponSummaryGrid' ||
          this.gridService.pageContext.pageName == 'summaryResultsCB' ||
          this.gridService.pageContext.pageName == 'endUserDetailsGridCB')) ||
          params.colDef.field == 'invoicePrice' || params.colDef.field == 'pAPrice' ||
          params.colDef.field == 'rebateAmount' || params.colDef.field == 'pATotalLine' ||
          params.colDef.field == 'differences' || params.colDef.field == 'val' || params.colDef.field == 'quantity') {
          if (params.colDef.field == 'differences' && params.data.lineType == 'C')
            this.numericValue = '';
          else if ((params.colDef.field == 'tax' || params.colDef.field == 'taxAmount' || params.colDef.field == 'termsRecaptured') && params.value == 0)
            this.numericValue = '';
          else if (str1.indexOf('-') > -1 && this.globalAppServcie.getDefaultRegionString() != 'EMEA' && this.globalAppServcie.getDefaultRegionString() != 'APAC')
            this.numericValue = '(' + str1.substring(1) + ')';
          else if ((params.colDef.field == 'quantity')) {
            let fractionSeparator = str1[str1.length - 3];
            if (str1.split(fractionSeparator)[1] == '00') {
              this.numericValue = str1.split(fractionSeparator)[0];
            }
            else {
              this.numericValue = str1;
            }
          }
          else
            this.numericValue = str1;
        } else {
          this.numericValue = this.currencyCode + ' ' + str1;
        }
      }

      this.formattedValue = ((parseFloat(this.params.value) - parseFloat(this.params.value)) === 0 ? true : false)
    }

  }

  refresh(params: any): boolean {
    return false;
  }

  setLanguageListener() {
    this.subscription.add(this.languageServ.getlanguage().subscribe((language) => {
      this.translateServ.get(['LCIDString'])
        .subscribe(value => {
          if (value) {
            this.LCIDString = value.LCIDString;
            this.formatNumber();
          }
        });
    }));
  }

  formatNumber() {
    if (this.params && this.params.data) {
      let cur = currency.find(item => {
        return item.label == this.params.data.currency.trim();
      });
      this.currencyCode = cur.value + " ";
      // let str1 = parseFloat(this.params.value).toLocaleString("en-US", {
      //   minimumFractionDigits: 2
      // });
      const languageCode = this.globalAppServcie.getDefaultLanguageString();
      let str1 = this.globalAppServcie.getNumberCurrencyFormatted(languageCode, this.params.value, 'NUMBER', 2);

      if (this.globalAppServcie.getDefaultRegionString() === 'LAO') {
        // CR 1378 - Use . as thousand separator even for Peru and other sales org in LAO
        // let str2 = str1.replace(/\./g, '@');
        // let str3 = str2.replace(/,/g, '.');
        // let str4 = str3.replace(/@/g, ',');

        if (this.noShowCurrencySymbolCols.indexOf(this.params.colDef.field) > -1) {
          this.numericValue = ' ' + str1;
        } else {
          this.numericValue = this.currencyCode + ' ' + str1;
        }
      }
      else {
        if ((this.gridService.pageContext && (this.gridService.pageContext.pageName == 'searchResultsCB' ||
          this.gridService.pageContext.pageName == 'recentActivity' ||
          this.gridService.pageContext.pageName == 'issuedTileGrid' ||
          this.gridService.pageContext.pageName == 'deniedTileGrid' ||
          this.gridService.pageContext.pageName == 'pendingTileGrid' ||
          this.gridService.pageContext.pageName == 'couponSummaryGrid' ||
          this.gridService.pageContext.pageName == 'summaryResultsCB' ||
          this.gridService.pageContext.pageName == 'endUserDetailsGridCB')) ||
          this.params.colDef.field == 'invoicePrice' || this.params.colDef.field == 'pAPrice' ||
          this.params.colDef.field == 'rebateAmount' || this.params.colDef.field == 'pATotalLine' ||
          this.params.colDef.field == 'differences' || this.params.colDef.field == 'val' || this.params.colDef.field == 'price' || this.params.colDef.field == 'quantity') {
          if (this.params.colDef.field == 'differences' && this.params.data.lineType == 'KC')
            this.numericValue = '';
          else if ((this.params.colDef.field == 'tax' || this.params.colDef.field == 'taxAmount') && this.params.value == 0)
            this.numericValue = '';
          else if (str1.indexOf('-') > -1 && this.globalAppServcie.getDefaultRegionString() != 'EMEA' && this.globalAppServcie.getDefaultRegionString() != 'APAC')
            this.numericValue = '(' + str1.substring(1) + ')';
          else if ((this.params.colDef.field == 'quantity')) {
            let fractionSeparator = str1[str1.length - 3];
            if (str1.split(fractionSeparator)[1] == '00') {
              this.numericValue = str1.split(fractionSeparator)[0];
            }
            else {
              this.numericValue = str1;
            }
          }
          else
            this.numericValue = str1;
        } else {
          this.numericValue = this.currencyCode + ' ' + str1;
        }
      }
    }
  }

  ngOnDestroy() {
    // Required for unsubscribing and destroying the component  
  }
}
