import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CbService } from '@app/Modules/chargeback/Services/cb.service';
import { HttpChargeBackService } from '@app/Modules/Shared/Services/http-charge-back.service';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { GlobalAppService } from '@app/Modules/Shared/Services/global-app.service';
var KcFileContextmenucbComponent = /** @class */ (function () {
    function KcFileContextmenucbComponent(appServ, router, activatedRoute, cbServ, httpServ, messageService, globalAppService) {
        this.appServ = appServ;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.cbServ = cbServ;
        this.httpServ = httpServ;
        this.messageService = messageService;
        this.globalAppService = globalAppService;
        this.submittedContextMenu = false;
        this.validatedContextMenu = false;
        this.errorFoundTestContextMenu = false;
        this.errorFoundActiveContextMenu = false;
        this.processingTestContextMenu = false;
        this.processingActiveContextMenu = false;
        this.readyToSubmitContextMenu = false;
        this.deletedTestContextMenu = false;
        this.deletedActiveContextMenu = false;
        this.isInternalUser = true;
        this.isGlobalUser = false;
        this.isKcfileSubmitted = false;
        this.isKcfileDeleted = false;
        this.subscription = new Subscription();
        this.ellipseSelect = false;
    }
    KcFileContextmenucbComponent.prototype.agInit = function (params) {
        this.params = params;
        this.rowIndex = this.params.rowIndex;
        if (params.data && params.data.status) {
            switch (params.data.status) {
                case "1":
                    this.errorFoundActiveContextMenu = true;
                    break;
                case "2":
                    this.validatedContextMenu = true;
                    break;
                case "3":
                    this.processingActiveContextMenu = true;
                    break;
                case "4":
                    this.readyToSubmitContextMenu = true;
                    break;
                case "5":
                    this.submittedContextMenu = true;
                    break;
                case "6":
                    this.deletedActiveContextMenu = true;
                    break;
            }
        }
        this.isInternalUser = this.appServ.isInternalUser;
        this.isGlobalUser = this.appServ.isGlobalUser;
        this.region = this.globalAppService.getDefaultRegionString();
    };
    KcFileContextmenucbComponent.prototype.ngOnInit = function () {
        var _this = this;
        //below is to change the contextmenu after kcfile submit
        this.subscription.add(this.cbServ.getSubmitContextMenu().subscribe(function (data) {
            _this.isKcfileSubmitted = data;
        }));
        //below is to change contextmenu after file deleted
        this.subscription.add(this.cbServ.getDeletedStatusText().subscribe(function (data) {
            if (_this.params.rowIndex === data.rowIndex) {
                switch (data.status) {
                    case '1':
                        _this.errorFoundActiveContextMenu = false;
                        _this.errorFoundTestContextMenu = false;
                        _this.deletedActiveContextMenu = true;
                        break;
                    case '2':
                        _this.validatedContextMenu = false;
                        _this.deletedActiveContextMenu = true;
                        break;
                    case '4':
                        _this.readyToSubmitContextMenu = false;
                        _this.deletedActiveContextMenu = true;
                        break;
                }
            }
        }));
    };
    KcFileContextmenucbComponent.prototype.refresh = function (params) {
        return true;
    };
    KcFileContextmenucbComponent.prototype.errorSummaryClick = function () {
        this.router.navigate([{ outlets: { errorSummaryDialog: ['errorSummary'] } }], { relativeTo: this.activatedRoute.parent, state: { 'kcfileName': this.params.data.kcFileName, 'claimFileId': this.params.data.kcFileID, 'customerNumber': this.params.data.customerNumber, 'salesOrgId': this.params.data.salesOrgId } });
    };
    KcFileContextmenucbComponent.prototype.openDeletePopup = function (e, element) {
        element.hide(e);
        this.router.navigate([{ outlets: { deleteConfirmationDialog: ['deleteConfirmation'] } }], { relativeTo: this.activatedRoute.parent, state: { 'kcFileName': this.params.data.kcFileName, 'kcFileID': this.params.data.kcFileID, 'salesOrgId': this.params.data.salesOrgId, 'status': this.params.data.status, 'rowIndex': this.params.rowIndex } });
    };
    KcFileContextmenucbComponent.prototype.downloadKcfile = function (e, element) {
        var _this = this;
        if (this.params.data.lines > 0) {
            this.appServ.setLoadingStatus(true);
            if (this.params.data && this.params.data.kcFileID && this.params.data.salesOrgId) {
                var fileversion = (this.params.data.status && this.params.data.status == '3') ? 0 : 1;
                var request = {
                    "claimFileId": this.params.data.kcFileID,
                    "salesOrgId": this.params.data.salesOrgId,
                    "fileversion": fileversion //passing fileversion as 1 as new file to be downloaded code is 1 and for old its 0
                };
                this.subscription.add(this.httpServ.kcfileDownload(request).subscribe(function (res) {
                    if (res && res.fileUrl) {
                        window.open(res.fileUrl, '_blank');
                    }
                    else {
                        _this.appServ.setLoadingStatus(false);
                        _this.messageService.add({ key: 'kcfileDownload', detail: '0000.errorMessage', summary: 'errorLabel', severity: 'error' });
                    }
                }, function (e) {
                    _this.appServ.setLoadingStatus(false);
                    _this.messageService.add({ key: 'kcfileDownload', detail: '0000.errorMessage', summary: 'errorLabel', severity: 'error' });
                }, function () {
                    element.hide(e);
                    _this.appServ.setLoadingStatus(false);
                }));
            }
        }
    };
    KcFileContextmenucbComponent.prototype.kcfileHistoryPopUp = function (e, element) {
        element.hide(e);
        this.cbServ.totalLinesInFile = this.params.data.lines;
        this.router.navigate([{ outlets: { kcfileHistoryDialog: ['kcfileHistory'] } }], { relativeTo: this.activatedRoute.parent, state: { 'kcFileName': this.params.data.kcFileName, 'kcFileID': this.params.data.kcFileID, 'salesOrgId': this.params.data.salesOrgId } });
    };
    KcFileContextmenucbComponent.prototype.manageErrorsOnly = function () {
        var _this = this;
        if (this.isInternalUser || this.params.data.errors <= 50) {
            this.router.navigate([{ outlets: { kcfileSearchResultDisplay: null } }], { relativeTo: this.activatedRoute.parent }).then(function () {
                _this.router.navigate(['manage-errors'], {
                    queryParams: { 'kcFileID': _this.params.data.kcFileID, 'salesOrgId': _this.params.data.salesOrgId }, relativeTo: _this.activatedRoute.parent.parent.parent, state: {
                        'kcfileId': _this.params.data.kcFileID, 'salesOrgId': _this.params.data.salesOrgId, 'kcFileName': _this.params.data.kcFileName
                    }
                });
            });
        }
    };
    KcFileContextmenucbComponent.prototype.submitKcfile = function (e, element) {
        var _this = this;
        element.hide(e);
        this.appServ.setLoadingStatus(true);
        var request = {
            "claimFileId": this.params.data.kcFileID,
            "salesOrgId": this.params.data.salesOrgId,
            "isDeleted": false,
            "kcFileStatus": 5 //this is enum for "Submitted" status and should always be passed as 5 only, for delete this parameter can be skipped
        };
        this.subscription.add(this.httpServ.updateCBKcfileStatus(request).subscribe(function (data) {
            if (data) {
                _this.cbServ.setSubmitStatusText(true, _this.rowIndex);
                _this.cbServ.initiateFileSubmit(request);
            }
            else {
                _this.appServ.setLoadingStatus(false);
                _this.messageService.add({ key: 'kcfileDownload', detail: '0000.errorMessage', summary: 'errorLabel', severity: 'error' });
            }
        }, function (e) {
            _this.appServ.setLoadingStatus(false);
            _this.messageService.add({ key: 'kcfileDownload', detail: '0000.errorMessage', summary: 'errorLabel', severity: 'error' });
        }, function () {
            _this.appServ.setLoadingStatus(false);
        }));
    };
    KcFileContextmenucbComponent.prototype.contextmenuToggle = function (element, e) {
        element.toggle(e);
    };
    KcFileContextmenucbComponent.prototype.onContextMenuHide = function () {
        this.ellipseSelect = false;
    };
    KcFileContextmenucbComponent.prototype.onContextMenuShow = function () {
        this.ellipseSelect = true;
    };
    KcFileContextmenucbComponent.prototype.ngOnDestroy = function () {
    };
    KcFileContextmenucbComponent = tslib_1.__decorate([
        AutoUnsubscribe(),
        tslib_1.__metadata("design:paramtypes", [AppService,
            Router,
            ActivatedRoute,
            CbService,
            HttpChargeBackService,
            MessageService,
            GlobalAppService])
    ], KcFileContextmenucbComponent);
    return KcFileContextmenucbComponent;
}());
export { KcFileContextmenucbComponent };
