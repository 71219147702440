import { Component, OnDestroy } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { PaService } from '@app/Modules/PriceAgreement/Services/pa.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { HttpService } from '@app/Modules/Shared/Services/http.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@AutoUnsubscribe()
@Component({
  selector: 'text-translation',
  templateUrl: './text-translation.component.html',
  styleUrls: ['./text-translation.component.scss']
})
export class TextTranslationComponent implements AgRendererComponent, OnDestroy {
  params: any;
  data: any;

  constructor(
    private translateServ: TranslateService
  ) {
    this.translateServ.get(['dataConfigData.cbDiscrepancies'])
    .subscribe(translations => {
      this.data = translations['dataConfigData.cbDiscrepancies'];
    });
   }

  agInit(params: any): void {
    if(params && params.value && (params.value).toUpperCase() == 'YES'){
      this.params = this.data.Yes_key;
    }
    else if(params && params.value && (params.value).toUpperCase() == 'NO'){
      this.params = this.data.No_key;
    }
    else{
      return params.value;
    }
  }

  refresh(params: any): boolean {
    return false;
  }

  ngOnDestroy() {
    // Required for unsubscribing and destroying the component  
  }

}
