import { OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { HttpService } from '../../Services/http.service';
var SearchResultComponent = /** @class */ (function () {
    function SearchResultComponent(httpServ) {
        this.httpServ = httpServ;
        this.resultsLoaded = false;
        this.categoriesLoaded = false;
    }
    Object.defineProperty(SearchResultComponent.prototype, "searchText", {
        set: function (value) {
            this.allProductsURL = this.httpServ.getRegionSpecificURL(environment.allProductsLink) + '?q=' + value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SearchResultComponent.prototype, "categoryResults", {
        get: function () {
            return this._categoryList;
        },
        set: function (value) {
            this._categoryList = value;
            this.validateCategories(this._categoryList);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SearchResultComponent.prototype, "searchResult", {
        get: function () {
            return this._resultList;
        },
        set: function (value) {
            this._resultList = value;
            this.validateResults(this._resultList);
        },
        enumerable: true,
        configurable: true
    });
    SearchResultComponent.prototype.ngOnInit = function () {
    };
    SearchResultComponent.prototype.validateCategories = function (_resultList) {
        if (this.categoryResults != undefined) {
            this.categoriesLoaded = this._categoryList.length > 0;
        }
    };
    SearchResultComponent.prototype.validateResults = function (_resultList) {
        if (this.searchResult != undefined) {
            this.resultsLoaded = this._resultList.length > 0;
        }
    };
    return SearchResultComponent;
}());
export { SearchResultComponent };
