import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/fromPromise';
import { OktaAuthService } from '@okta/okta-angular';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { cookieSIMID, globalUser } from '@env/environment';
import { Guid } from "guid-typescript";
import { GlobalAppService } from './global-app.service';


@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  private _userID: string;
  private _simUserId: string;
  private _simUserEmail: string;
  private isInternalUser: string;

  constructor(private oktaAuth: OktaAuthService,
    private _appServ: AppService, private http: HttpClient, private globalAppService: GlobalAppService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return Observable.fromPromise(this.handleAccess(request, next));
  }

  getRegionSpecificDefaultLanguage(){
    let region = this.globalAppService.getDefaultRegionString();
    if(region == 'EMEA'){
      return 'en-GB'
    } else {
      return 'en-US'
    }
  }

  private async handleAccess(request: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {


    const accessToken = await this.oktaAuth.getAccessToken();

    this._userID = this._appServ.getuserId(true);
    this._simUserId = this._appServ.getuserId(false);
    this._simUserEmail = this._appServ.getuserEmail();

    this.isInternalUser = this._appServ.isInternalUser.toString();

    let HTTP_HEADER_OBJ = {}

    if (request.url.indexOf('/UploadEFile') != -1 || request.url.indexOf('/UploadKCFile') != -1) {
      HTTP_HEADER_OBJ = Object.assign({}, { 'Authorization': 'Bearer ' + accessToken }, HTTP_HEADER_OBJ);
    } else {
      HTTP_HEADER_OBJ = Object.assign({}, { 'Authorization': 'Bearer ' + accessToken,   'Content-Type': 'application/json'}, HTTP_HEADER_OBJ);
    }
    
    if (!request.headers.get('Accept-Language')){
      let selectedCountryLang = this.globalAppService.getSelectedCountryLang();
      let currentLanguage = this.globalAppService.getDefaultLanguageString() || this.getRegionSpecificDefaultLanguage();
      if (selectedCountryLang && selectedCountryLang.language) {
        currentLanguage = selectedCountryLang.language;
      }
      HTTP_HEADER_OBJ = Object.assign({}, { 'Accept-Language': currentLanguage }, HTTP_HEADER_OBJ);
    }
    else{
      HTTP_HEADER_OBJ = Object.assign({}, { 'Accept-Language': request.headers.get('Accept-Language') }, HTTP_HEADER_OBJ);
    }
    
    let sessionId = localStorage.getItem('session-id');
    if(sessionId){
      HTTP_HEADER_OBJ = Object.assign({},{ 'sessionId' : sessionId }, HTTP_HEADER_OBJ);
    }

    HTTP_HEADER_OBJ = Object.assign({}, { 'CorrelationId': this._simUserId + "_" + Guid.raw() }, HTTP_HEADER_OBJ);
    if (this._userID) {
      HTTP_HEADER_OBJ = Object.assign({}, { 'userId': this._userID }, HTTP_HEADER_OBJ);
    }
    if (this.isInternalUser) {
      HTTP_HEADER_OBJ = Object.assign({}, { 'isInternalUser': this.isInternalUser }, HTTP_HEADER_OBJ);
    }
    if (this._appServ.getCookie(cookieSIMID) && this._appServ.isGlobalUser) {
      HTTP_HEADER_OBJ = Object.assign({}, { 'IntegratedUserId': this._simUserId }, HTTP_HEADER_OBJ);
    }
    if (this._appServ.getCookie(cookieSIMID) && this._appServ.isGlobalUser) {
      HTTP_HEADER_OBJ = Object.assign({}, { 'IntegratedUserEmail': this._simUserEmail }, HTTP_HEADER_OBJ);
    }

    if (request.url.indexOf('/GetDefaultCustomerInfo') != -1) {
      HTTP_HEADER_OBJ = Object.assign({}, { 'region': 'LAO' }, HTTP_HEADER_OBJ);
    }

    if (request.method.toUpperCase() === 'GET') {
      HTTP_HEADER_OBJ = Object.assign({}, { 'Cache-control': 'no-cache,no-store', 'Pragma': 'no-cache' }, HTTP_HEADER_OBJ);
    }    
    
    if(request.url.indexOf('/GetUserDetails') != -1 && localStorage.getItem('temp-pre-impersonated-user')){
      HTTP_HEADER_OBJ = Object.assign({}, { 'IntegratedUserId': localStorage.getItem('temp-pre-impersonated-user') }, HTTP_HEADER_OBJ);
    }

    request = request.clone({
      headers: new HttpHeaders(HTTP_HEADER_OBJ)
    });
      
    
    return next.handle(request).toPromise();

  }

}