
/** This file will import the regionwise column definations and will return the column definations of
 * passed region from component.
 */
import { NA_COL_DEF } from './agColumnsConfNA';
import { EMEA_COL_DEF } from './agColumnsConfEMEA';
import { LAO_COL_DEF } from './agColumnsConfLAO';
import { APAC_COL_DEF } from './agColumnsConfAPAC';
export class RegionWiseColDefination {
  getRegionWiseColDef(region = 'LAO') {
    let columnDefinations;
    switch (region) {
      case "NA":
        columnDefinations = NA_COL_DEF;
        break;

      case "LAO":
        columnDefinations = LAO_COL_DEF;
        break;

      case "EMEA":
        columnDefinations = EMEA_COL_DEF;
        break;
        
      case "APAC":
        columnDefinations = APAC_COL_DEF;
        break;
    }

    let {
      recentActivityTileGridColDef,
      pendingTileGridColDef,
      issuedTileGridColDef,
      deniedTileGridColDef,
      unclaimedTileGridColDef,
      searchResultCBColDefInternal,
      searchResultCBColDefExternal,
      searchResultEfileExternalColDef,
      searchResultEfileInternalColDef,
      couponSummaryResultColDef,
      couponSummaryGridColDef,
      couponEndUserGridColDef,
      couponEndUserGridFooterColumnDefs,
      historyLog,
      errorSummary,
      manageErrorExternal,
      manageErrorInternal,
      customerSearchColDef,
      customerSearchLAOColDef,
      custListModalDef,
      searchResultKcfileExternalColDef,
      searchResultKcfileInternalColDef,
      kcfileHistoryLog,
      manageErrorExternalKcFile,
      manageErrorInternalKcFile,
      kcFilePASearchColdef,
      multipleRefNumberColDef,
      multipleClaimNumberColDef
    } = columnDefinations;

    return {
      recentActivityTileGridColDef,
      pendingTileGridColDef,
      issuedTileGridColDef,
      deniedTileGridColDef,
      unclaimedTileGridColDef,
      searchResultCBColDefInternal,
      searchResultCBColDefExternal,
      searchResultEfileExternalColDef,
      searchResultEfileInternalColDef,
      couponSummaryResultColDef,
      couponSummaryGridColDef,
      couponEndUserGridColDef,
      couponEndUserGridFooterColumnDefs,
      historyLog,
      errorSummary,
      manageErrorExternal,
      manageErrorInternal,
      customerSearchColDef,
      customerSearchLAOColDef,
      custListModalDef,
      searchResultKcfileExternalColDef,
      searchResultKcfileInternalColDef,
      kcfileHistoryLog,
      manageErrorExternalKcFile,
      manageErrorInternalKcFile,
      kcFilePASearchColdef,
      multipleRefNumberColDef,
      multipleClaimNumberColDef
    };
  }
}

