import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr'
import { environment } from '@env/environment';
import { Subject, Observable } from 'rxjs';
import { HttpChargeBackService } from './http-charge-back.service';
import { HttpService } from './http.service';
import { OktaAuthService } from '@okta/okta-angular';

@Injectable({
    providedIn: 'root'
})

/**
 * How to use:
 * in the component where Signal-R needs to be implemented
 * call the initSignalR_Download() method, pass the connection category and params except token for send() call
 */
export class SignalRMaster {

  hubConnection: HubConnection;
  
  methodName = 'GetData';
  onMessageReceived: Subject<any> = new Subject<any>();

  constructor(
    private httpServiceCB: HttpChargeBackService,
    private httpServicePA: HttpService,
    private oktaAuth: OktaAuthService) { }

  initSignalR_Download(connectionCategory: string, ...params: any) {

    return this.oktaAuth.getAccessToken().then(token => {
      let accessToken = 'Bearer ' + token;
      params[params.indexOf('TOKEN')] = accessToken;
      this.createConnection(connectionCategory);

      if(connectionCategory === 'NA-MC'){
        this.methodName = 'GetMCData';
      }

      this.registerEvents();
      return this.startConnection(params);
    });
  }

  private createConnection(connectionCategory: string) {

    let signalRUrl: string = "";

    switch (connectionCategory) {
      case 'EMEA-PA':
        signalRUrl = this.httpServicePA.getRegionSpecificURL(environment.signalREndpointPA);
        break;
      case 'EMEA-RP':
        signalRUrl = this.httpServicePA.getRegionSpecificURL(environment.signalREndpointPA);
        break;
      case 'NA-CB':
        signalRUrl = this.httpServiceCB.getRegionSpecificURL(environment.signalREndpoint);
        break;
      case 'NA-MC':
        signalRUrl = this.httpServiceCB.getRegionSpecificURL(environment.signalREndpointMC);
        break;
    }
    console.log("####", signalRUrl);
    this.hubConnection = new HubConnectionBuilder().withUrl(signalRUrl).build();
  }

  private startConnection(params: any) {

    this.hubConnection.start().then(() => {
      console.log("Params####", params);
      this.hubConnection.send(this.methodName, ...params).then(() => {
        this.onMessageReceived.next("start");
      });
    }).catch(err => {
      console.error(err);
      this.stopConnection();
    });
  }


  private stopConnection() {
    if (this.hubConnection && !this.hubConnection.state)
      this.hubConnection.stop();
  }


  private registerEvents() {

    this.hubConnection.off(this.methodName, (response: any, data: any) => {
      console.log("Connection closed.")
    });

    this.hubConnection.on(this.methodName, (response: any, data: any) => {
      console.log("@@@@@ Signal R Data: ", data);
      let parsedData: any = JSON.parse(data);
      if (parsedData.fileUrl) {
        window.open(parsedData.fileUrl, '_blank');
      }
      setTimeout(() => {
        this.onMessageReceived.next(parsedData);
      });
    });

    // Callback if connection gets closed
    this.hubConnection.onclose((error: any) => {
      console.log("error on close", error)
    });
  }

  getMessageObservable(): Observable<any> {
    return this.onMessageReceived.asObservable();
  }
}