<!--temporary removal of hyperlink on error and net issued cols for EMEA region-->
<ng-container *ngIf="isnoHyperlinkPages; else elseBlock">
  {{FormattedValue || params.valueFormatted || params.value}}
</ng-container>

<ng-template #elseBlock>
  <ng-container *ngIf="!isMCCustomerCol; else MCCustomerColBlock">
    <a *ngIf="hideLink" href="javascript:void(0)" (click)="onLinkClick()" >
      {{FormattedValue || params.valueFormatted || params.value}}
    </a>
  </ng-container>
  
  
  <ng-template #MCCustomerColBlock>
    <a *ngIf="hideLink" href="javascript:void(0)" (click)="onLinkClick()">
      {{'agGrid.agGridColumns.customerDetails' | translate}}
    </a>
  </ng-template>
</ng-template>

<!-- <ng-container *ngIf="!isMCCustomerCol; else MCCustomerColBlock">
  <a *ngIf="hideLink" href="javascript:void(0)" (click)="onLinkClick()" >
    {{FormattedValue || params.valueFormatted || params.value}}
  </a>
</ng-container>


<ng-template #MCCustomerColBlock>
  <a *ngIf="hideLink" href="javascript:void(0)" (click)="onLinkClick()">
    {{'agGrid.agGridColumns.customerDetails' | translate}}
  </a>
</ng-template> -->

