import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs';
import { HttpService } from '@app/Modules/Shared/Services/http.service';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { GlobalAppService } from './../../Shared/Services/global-app.service';
import { ribbonMenuExternalCB, ribbonMenuInternalCustomerCB } from '@app/conf/dataConfigurations';

@Injectable({
  providedIn: 'root'
})

export class CbService {
  private _searchCriteria = {};
  private _efileSearchCriteria = {};
  private _mcSearchCriteria = {};
  public dataSource: any;
  public searchRequestState: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public efileSearchRequestState: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public MCSearchRequestState: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private reloadEfileSearchGrid = new Subject<boolean>();

  private _kcfileSearchCriteria = {};
  public kcfileSearchRequestState: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private reloadKcfileSearchGrid = new Subject<boolean>();


  private custInfoDataHolder: Array<any> = [];
  private locationTreeData = new Subject<any>();
  private deleted = new Subject<boolean>();
  private deletedSttausText = new Subject<any>();
  private submitStatusText = new Subject<any>();
  private locationSelectedTreeData: any;

  public searchRequestState$: Observable<number> = this.searchRequestState.asObservable();
  private _favoriteSearch = new Subject<any>();
  public recordCount: BehaviorSubject<number> = new BehaviorSubject<number>(undefined);
  public recordCount$: Observable<number> = this.recordCount.asObservable();
  public allData: any;
  public resultSetData: Array<any>;
  public _isFilterOn: boolean = false;
  private _gridApiContext: any;
  pageSize: number = 50;
  _isSortOn: boolean;
  public customerNo: Array<any> = [];
  stateList: Array<any> = [];
  public recordcountForMCDownload: number;

  gridLoaded: Subject<boolean> = new Subject<boolean>();
  mySearchesNavigation: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  _locationCount: number;
  couponDetails: any;
  typeAheadSelectedCustEU: Subject<any> = new Subject<any>();
  isRowDeleted: Subject<any> = new Subject<any>();
  rowCount: number;
  deleteErrorLineRequest: any;
  deleteErrorLineRequestSubject = new Subject<any>();
  efileManageErrorHeaderData: Subject<any> = new Subject<any>();
  efileDataErrorsCorrected: Subject<any> = new Subject<any>();
  efileValidatedColumnData: Subject<any> = new Subject<any>();

  efileSearchQueryStore: any;

  kcfileManageErrorHeaderData: Subject<any> = new Subject<any>();
  kcfileDataErrorsCorrected: Subject<any> = new Subject<any>();
  kcfileValidatedColumnData: Subject<any> = new Subject<any>();

  kcfileSearchQueryStore: any;
  menu: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public customerIds: Array<any> = [];
  public locationData: any;
  emailAdd: Subject<any> = new Subject<any>();
  emailAddressString: string;
  currentEfileHeaderData: any;
  currentKcfileHeaderData: any;
  totalLinesInFile: number;
  isFileDeleted: Subject<any> = new Subject<any>();
  isFileSubmitted: Subject<any> = new Subject<any>();

  constructor(private _http: HttpService, private appService: AppService, private globalAppService: GlobalAppService) {
    this.setMenu();
  }

  setRowCount(count) {
    this.rowCount = count;
  }

  getRowCount() {
    return this.rowCount;
  }


  setCustEUList(value) {
    return this.typeAheadSelectedCustEU.next(value);
  }

  setSubmitContextMenu(value: boolean) {
    this.deleted.next(value);
  }

  getSubmitContextMenu() {
    return this.deleted.asObservable();
  }

  setDeletedStatusText(value: boolean, status, rowIndex) {
    this.deletedSttausText.next({ 'value': value, 'status': status, 'rowIndex': rowIndex });
  }

  getDeletedStatusText() {
    return this.deletedSttausText.asObservable();
  }

  setSubmitStatusText(value: boolean, rowIndex) {
    this.submitStatusText.next({ 'value': value, 'rowIndex': rowIndex });
  }

  getSubmitStatusText() {
    return this.submitStatusText.asObservable();
  }

  getCustEUList() {
    return this.typeAheadSelectedCustEU.asObservable();
  }

  setCustomerNo(CustomerData) {
    this.customerNo = CustomerData;
  }
  getCustomerNo(): Array<any> {
    return this.customerNo;
  }
  setCustInfoDataHolder(CustomerData) {
    this.custInfoDataHolder = CustomerData;
  }
  getCustInfoDataHolder(): Array<any> {
    return this.custInfoDataHolder;
  }
  setLocationCount(value) {
    this._locationCount = value;
  }
  getLocationCount(): number {
    return this._locationCount;
  }
  setStateList(CustomerData) {
    this.stateList = CustomerData;
  }
  getStateList(): Array<any> {
    return this.stateList;
  }
  setLocationData(tree) {
    this.locationTreeData = tree;
  }
  getLocationData() {
    return this.locationTreeData;
  }
  setLocationSelectedData(tree) {
    this.locationSelectedTreeData = tree;
  }
  getLocationSelectedData() {
    return this.locationSelectedTreeData;
  }
  setGridLoaded(value) {
    return this.gridLoaded.next(value);
  }
  getGridLoaded() {
    return this.gridLoaded.asObservable();
  }
  setGridApiContext(gridContext: any): void {
    this._gridApiContext = gridContext;
  }
  getsearchData() {
    return this._searchCriteria;
  }
  initCBSearch(searchObj) {
    this._searchCriteria = searchObj;
  }
  getSearchRequest(): Observable<any> {
    return this.searchRequestState.asObservable();
  }
  changeSearchRequest(searchObject) {
    this.appService.changeSearchRequest(searchObject);
    this.searchRequestState.next(searchObject);
  }

  getMCSearchRequest(): Observable<any> {
    return this.MCSearchRequestState.asObservable();
  }
  changeMCSearchRequest(searchObject) {
    this.appService.changeSearchRequest(searchObject);
    this.MCSearchRequestState.next(searchObject);
  }
  getMCSearchData() {
    return this._mcSearchCriteria;
  }
  initMCSearch(searchObj) {
    this._mcSearchCriteria = searchObj;
  }

  geteFileSearchData() {
    return this._efileSearchCriteria;
  }
  initCBEFileSearch(searchObj) {
    this._efileSearchCriteria = searchObj;
  }
  getEfileSearchRequest(): Observable<any> {
    return this.efileSearchRequestState.asObservable();
  }
  changeEfileSearchRequest(searchObject) {
    this.appService.changeSearchRequest(searchObject);
    this.efileSearchRequestState.next(searchObject);
  }

  getkcFileSearchData() {
    return this._kcfileSearchCriteria;
  }
  initCBKcFileSearch(searchObj) {
    this._kcfileSearchCriteria = searchObj;
  }
  getKcfileSearchRequest(): Observable<any> {
    return this.kcfileSearchRequestState.asObservable();
  }
  changeKcfileSearchRequest(searchObject) {
    this.appService.changeSearchRequest(searchObject);
    this.kcfileSearchRequestState.next(searchObject);
  }

  getResultSet(): Array<any> {
    return this.resultSetData;
  }
  setResultSet(value: Array<any>) {
    if (value) {
      this.resultSetData = value.map((item) => {
        return item;
      });
    }
  }

  getFilterOn(): boolean {
    return this._isFilterOn;
  }

  setFilterOn(value: boolean) {
    this._isFilterOn = value;
  }

  getSortOn(): boolean {
    return this._isSortOn;
  }

  setSortOn(value: boolean) {
    this._isSortOn = value;
  }
  initializCountZero() {
    this.recordCount.next(0);
  }

  setMySearchesNavigation(value: boolean) {
    this.mySearchesNavigation.next(value);
  }

  getMySearchesNavigation(): Observable<boolean> {
    return this.mySearchesNavigation.asObservable();
  }

  markSearchFavorite(searchItem: any) {
    this._favoriteSearch.next(searchItem);
  }

  getMarkSearchFavorite() {
    return this._favoriteSearch;
  }

  initiateRowDelete(value: any) {
    this.isRowDeleted.next(value);
  }

  implementRowDelete() {
    return this.isRowDeleted.asObservable();
  }

  sortHandler(params, sortOrder) {
    if (sortOrder)
      this._gridApiContext.setSortModel([{ sort: sortOrder, colId: params.column.colId }]);
    else
      this._gridApiContext.setSortModel(null);
  }

  loadCustomerLocations(results) {
    results = results.map(d => {
      let row: any = Object.assign({}, d);
      row.CustomerNumber = d.customerNumber;
      row.CustomerName = d.name;
      row.City = d.city;
      row.State = d.state;
      return row;
    });
    for (let ctr = 0; ctr <= results.length; ctr++) {
      this.recursiveProcessNode(results[ctr], ctr, results)
    }
    let temp = results.filter((n) => {
      return n.tree;
    });
    return temp;
  }

  recursiveProcessNode(CURRENT_NODE, CURRENT_NODE_INDEX, ALL_DATA) {
    if (CURRENT_NODE && CURRENT_NODE['parentCustomer']) {
      let PREV_NODE_INDEX = CURRENT_NODE_INDEX === 0 ? -1 : CURRENT_NODE_INDEX - 1;
      if (PREV_NODE_INDEX >= 0) {
        let PREV_NODE = ALL_DATA[PREV_NODE_INDEX];
        if (PREV_NODE['customerNumber'] === CURRENT_NODE['parentCustomer']) {
          if (!PREV_NODE.children) {
            Object.assign(PREV_NODE, { 'children': [] });
          }
          PREV_NODE.children.push(CURRENT_NODE);
        }
        else {
          this.recursiveProcessNode(CURRENT_NODE, PREV_NODE_INDEX, ALL_DATA);
        }
      }
    }
    else {
      if (CURRENT_NODE && !CURRENT_NODE.hasOwnProperty('tree')) {
        Object.assign(CURRENT_NODE, { 'tree': true });
      }
    }
  }

  setDeleteOrDownloadPayload(req) {
    this.deleteErrorLineRequest = req;
    this.deleteErrorLineRequestSubject.next(req);
  }

  getDeleteOrDownloadPayload() {
    return this.deleteErrorLineRequest;
  }
  getDeleteOrDownloadPayloadObservable(): Observable<any> {
    return this.deleteErrorLineRequestSubject.asObservable();
  }

  setHeaderData(obj: any) {
    this.efileManageErrorHeaderData.next({
      'efileName': obj.efileName,
      'efileId': obj.efileId,
      'uploadedBy': obj.uploadedBy,
      'uploaded': obj.uploaded,
      'validLines': obj.validLines,
      'errorLines': obj.errorLines,
      'eFileClaimTypeId': obj.eFileClaimTypeId
    });
    this.currentEfileHeaderData = obj;
  }

  setHeaderDataKcFile(obj: any) {
    this.kcfileManageErrorHeaderData.next({
      'kcfileName': obj.kcfileName,
      'kcfileId': obj.kcfileId,
      'uploadedBy': obj.uploadedBy,
      'uploaded': obj.uploaded,
      'validLines': obj.validLines,
      'errorLines': obj.errorLines,
      "customerName": obj.customerName,
      'totalDistributorCalc': obj.totalDistributorCalc,
      'totalKCPCalc': obj.totalKCPCalc,
      'monthtoProcess': obj.monthtoProcess
    });
    this.currentKcfileHeaderData = obj;
  }

  getHeaderData(): Observable<any> {
    return this.efileManageErrorHeaderData.asObservable();
  }

  setEfileCorrectedStatus(data) {
    this.efileDataErrorsCorrected.next(data);
  }

  getEfileCorrectedStatus(): Observable<any> {
    return this.efileDataErrorsCorrected.asObservable();
  }

  setEfileValidatedColumsData(data) {
    this.efileValidatedColumnData.next(data);
  }

  getEfileValidatedColumsData(): Observable<any> {
    return this.efileValidatedColumnData.asObservable();
  }

  getHeaderDataKcFile(): Observable<any> {
    return this.kcfileManageErrorHeaderData.asObservable();
  }

  setKcfileCorrectedStatus(data) {
    this.kcfileDataErrorsCorrected.next(data);
  }

  getKcfileCorrectedStatus(): Observable<any> {
    return this.kcfileDataErrorsCorrected.asObservable();
  }

  setKcfileValidatedColumsData(data) {
    this.kcfileValidatedColumnData.next(data);
  }

  getKcfileValidatedColumsData(): Observable<any> {
    return this.kcfileValidatedColumnData.asObservable();
  }

  setRecordCountForMCDownload(count) {
    this.recordcountForMCDownload = count;
  }

  getRecordCountForMCDownload() {
    return this.recordcountForMCDownload;
  }

  set efileSearchQuery(query) {
    this.efileSearchQueryStore = query;
  }

  get efileSearchQuery() {
    return this.efileSearchQueryStore;
  }

  setEfileSearchGridReload(value: boolean) {
    this.reloadEfileSearchGrid.next(value);
  }

  getEfileSearchGridReload() {
    return this.reloadEfileSearchGrid.asObservable();
  }

  set kcfileSearchQuery(query) {
    this.kcfileSearchQueryStore = query;
  }

  get kcfileSearchQuery() {
    return this.kcfileSearchQueryStore;
  }

  setKcfileSearchGridReload(value: boolean) {
    this.reloadKcfileSearchGrid.next(value);
  }

  getKcfileSearchGridReload() {
    return this.reloadKcfileSearchGrid.asObservable();
  }


  setKcfileCustomerIds(CustomerData) {
    this.customerIds = CustomerData;
  }
  getKcfileCustomerIds(): Array<any> {
    return this.customerIds;
  }
  getKcfilesearchData() {
    return this._searchCriteria;
  }
  initKcfileSearch(searchObj) {
    this._searchCriteria = searchObj;
  }
  setLocationForEU(locData) {
    this.locationData = locData;
  }
  getLocationForEU(): Array<any> {
    return this.locationData;
  }

  setMenu() {
    this.globalAppService.getDefaultRegion().subscribe(region => {
      if (this.appService.isInternalUser) {
        this.menu.next(ribbonMenuInternalCustomerCB[region]);
      } else {
        this.menu.next(ribbonMenuExternalCB[region]);
      }
      return this.menu;
    })
  }

  getMenu(): Observable<any> {
    return this.menu;
  }

  setEmailAddress(emailAddress) {
    this.emailAddressString = emailAddress;
    this.emailAdd.next(emailAddress);
  }

  getEmailAddress(): Observable<any> {
    return this.emailAdd.asObservable();
  }
  getEmailAddressString() {
    return this.emailAddressString;
  }

  initiateFileDelete(value: any) {
    this.isFileDeleted.next(value);
  }

  implementFileDelete() {
    return this.isFileDeleted.asObservable();
  }

  initiateFileSubmit(value: any) {
    this.isFileSubmitted.next(value);
  }

  implementFileSubmit() {
    return this.isFileSubmitted.asObservable();
  }

  calculate_KC_EXT_RBT_D_EXT_RBT_DIFF(row) {
    let KC_EXT_RBT: number;
    let D_EXT_RBT: number;
    let DIFF: number;

    let itW_PRC: number = parseFloat(row.data["itW_PRC"]) || 0;
    let pA_PRC: number = parseFloat(row.data["pA_PRC"]);
    let d_ITW_PRC: number = parseFloat(row.data["d_ITW_PRC"]) || 0;
    let d_PA_PRC: number = parseFloat(row.data["d_PA_PRC"]) || 0;
    let cases: number = parseFloat(row.data["cases"]) || 0;
    if (row.data["withouT_SUPPORT"] == "Sin Apoyo") {
      KC_EXT_RBT = 0;
      D_EXT_RBT = 0;
    } else {
      KC_EXT_RBT = (itW_PRC - pA_PRC) * cases;
      D_EXT_RBT = (d_ITW_PRC - d_PA_PRC) * cases;
    }
    DIFF = KC_EXT_RBT - D_EXT_RBT;
    row.data["exT_RBT"] = KC_EXT_RBT.toString();
    row.data["d_EXT_RBT"] = D_EXT_RBT.toString();
    row.data["diff"] = DIFF.toString();
    console.log(KC_EXT_RBT, " : ", D_EXT_RBT, " : ", DIFF);
    return row;
  }
}


