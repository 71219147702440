<div class="ngbmodal-content">
  <div class="ngbmodal-header">
    <h2>{{ title + "" | translate }}</h2>
    <span (click)="close()" class="pi pi-times ngbmodal-close"></span>
  </div>

  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="content-area__white-box download-all download-all--started">
          <div class="download-all--started__content">
            <div>
              <i class="far fa-arrow-alt-circle-down"></i>
              <h3>{{ 'download.downloadActivityInfoSegment1' | translate }}</h3>
              <p>{{ 'download.downloadActivityInfoSegment2' | translate }}</p>
              <p>
                <i class="fas fa-exclamation-circle warning"></i>
                <span
                [innerHTML]="'download.downloadActivityInfoSegment3' | translate: {legalLink: url}"></span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>