/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./efile-edit-error-line.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./efile-edit-error-line.component";
import * as i4 from "@angular/router";
import * as i5 from "../../../../Shared/Services/app.service";
import * as i6 from "../../../services/cp-ag-grid-service";
import * as i7 from "../../../../Shared/Services/global-app.service";
var styles_EfileEditErrorLineComponent = [i0.styles];
var RenderType_EfileEditErrorLineComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EfileEditErrorLineComponent, data: {} });
export { RenderType_EfileEditErrorLineComponent as RenderType_EfileEditErrorLineComponent };
function View_EfileEditErrorLineComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "far fa-edit"]], null, null, null, null, null))], null, null); }
function View_EfileEditErrorLineComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.efileValidationErrors() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EfileEditErrorLineComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.isInternalUser && !(_co.isGlobalUser && ((_co.region == "EMEA") || (_co.region == "LAO")))); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_EfileEditErrorLineComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EfileEditErrorLineComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.params.value != 0); _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.params.value; _ck(_v, 0, 0, currVal_0); }); }
export function View_EfileEditErrorLineComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "efile-edit-error-line", [], null, null, null, View_EfileEditErrorLineComponent_0, RenderType_EfileEditErrorLineComponent)), i1.ɵdid(1, 114688, null, 0, i3.EfileEditErrorLineComponent, [i4.Router, i4.ActivatedRoute, i5.AppService, i6.CpAgGridService, i7.GlobalAppService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EfileEditErrorLineComponentNgFactory = i1.ɵccf("efile-edit-error-line", i3.EfileEditErrorLineComponent, View_EfileEditErrorLineComponent_Host_0, {}, {}, []);
export { EfileEditErrorLineComponentNgFactory as EfileEditErrorLineComponentNgFactory };
