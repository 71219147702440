import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { languageListForAPACPolicy, languageNames } from '@app/conf/dataConfigurations';
import { CbAcceptencePolicyService } from '../../Services/cb-acceptence-policy.service';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { HttpService } from './../../Services/http.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { KCPContent } from '../../DataModels/SharedDataModel';

@Component({
  selector: 'app-apac-pricing-terms-page',
  templateUrl: './apac-pricing-terms-page.component.html',
  styleUrls: ['./apac-pricing-terms-page.component.scss']
})
export class APACPricingTermsPageComponent implements OnInit, OnDestroy {
  currentSelectedLanguage: string;
  userLanguageName: any;
  regionFromQueryParam;
  countryFromQueryParam;
  languageFromQueryParam;
  salesOrgIdFromQueryParam;
  isEnglishActive: boolean = false;
  hideEnglishTab: boolean = false;
  policyContent: SafeHtml;
  language:string;
  languageListForAPACPolicy:any;
  constructor(
    private cbPolicyService: CbAcceptencePolicyService,
    private activatedRoute: ActivatedRoute,
    private appService: AppService,
    private httpServ: HttpService,
    public sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.regionFromQueryParam = this.activatedRoute.snapshot.queryParamMap.get('regionId');
    this.countryFromQueryParam = this.activatedRoute.snapshot.queryParamMap.get('countryId');
    this.languageFromQueryParam = this.activatedRoute.snapshot.queryParamMap.get('languageId');
    //this.salesOrgIdFromQueryParam = this.activatedRoute.snapshot.queryParamMap.get('salesOrgId');
    this.language = (this.languageFromQueryParam.split("-")[0]).toLowerCase();
    this.userLanguageName = languageNames;
    this.languageListForAPACPolicy = languageListForAPACPolicy[this.countryFromQueryParam.toLowerCase()];
    if (this.countryFromQueryParam.toLowerCase() == "id") {
      this.language = "id";
    }
    this.setPolicyContent();
  }

  setPolicyContent() {
    this.currentSelectedLanguage = this.language;
    this.getPolicyContent();
    // this.currentSelectedLanguage = language;
    // /** Set the ACtive Menu */
    // this.currentSelectedLanguage.indexOf('en') > -1 ? this.isEnglishActive = true : this.isEnglishActive = false;
    // /** Hide the English Tab as User Passed/Query passed language is english */
    // this.languageFromQueryParam.indexOf('en') > -1 ? this.hideEnglishTab = true : this.hideEnglishTab = false;
    // /**Set the user Language name in Second tab */    
  } 

  onUserLanguageClick(language) {
    this.language = language;
    this.currentSelectedLanguage = this.language;
    this.setPolicyContent();
  }

  ngOnDestroy(): void {
    localStorage.removeItem('pricing-terms-conditions-url');
  }

  getKCPContentRequest() {
    let contactRequest = new KCPContent();
    let country = this.countryFromQueryParam;
    contactRequest.regionId = this.regionFromQueryParam;
    contactRequest.countryId = country;
    contactRequest.languageCode = this.language + "-" + country;
    contactRequest.contentTypes = this.contentTypeRequest();
    contactRequest.contentSubTypes = [
      {
        "contentSubType": "Popup"
      }
    ];
    //contactRequest.salesOrgId = this.salesOrgIdFromQueryParam;
    return contactRequest;
  }

  contentTypeRequest() {
    let contentTypeArr = [];
    let myObj = Object.assign({});
    myObj.contentType = 'Pricing Policy Consent';
    contentTypeArr.push(myObj);
    return contentTypeArr;
  }

  getPolicyContent() {
    this.appService.setLoadingStatus(true);
    let KCPContentRequest = this.getKCPContentRequest();
    this.httpServ.getKCPContent(KCPContentRequest).subscribe(
      (data) => {
        console.log("DATA :: ", data)
        if (data.result.length > 0) {
          this.policyContent = this.sanitizer.bypassSecurityTrustHtml(JSON.parse(data.result[0].data).Content);
        }
        this.appService.setLoadingStatus(false);
      },
      (error) => {
        this.appService.setLoadingStatus(false);
      },
      () => {
        this.appService.setLoadingStatus(false);
      })
  }

}
