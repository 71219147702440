let endpoints_IN_NEW_DEV_TeamB = {
    GetInvoicePriceStatusByCustomerID_new:{
		url_NA: "https://us1.api.kimclark.com/kcc-sls-kcpinvoiceprice-pxy-api-tb/NA/v1/Invoice/GetInvoicePriceStatusByCustomerID",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpinvoiceprice-pxy-api-tb/EMEA/v1/Invoice/GetInvoicePriceStatusByCustomerID",
		url_LAO: "https://kcc-sls-laokcpinvoiceprice-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/Invoice/GetInvoicePriceStatusByCustomerID",
		url_APAC: "https://app-apac-kcpinvoice-dev-cia-1.azurewebsites.net/APAC/v1/Invoice/GetInvoicePriceStatusByCustomerID",
		method: "POST"
	},
	PriceRefreshRequest_new: {
		url_NA: 'https://us1.api.kimclark.com/kcc-sls-kcpinvoiceprice-pxy-api-tb/NA/v1/Invoice/PriceRefreshRequest',
		url_LAO: "https://kcc-sls-laokcpinvoiceprice-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/Invoice/PriceRefreshRequest",
		url_EMEA: 'https://us1.api.kimclark.com/kcc-sls-eukcpinvoiceprice-pxy-api-tb/EMEA/v1/Invoice/PriceRefreshRequest',
		url_APAC: "https://app-apac-kcpinvoice-dev-cia-1.azurewebsites.net/APAC/v1/Invoice/PriceRefreshRequest",
		method: 'POST'
	},
	IsPriceRequestAllowedforUser_new: {
		url_NA: 'https://us1.api.kimclark.com/kcc-sls-kcpinvoiceprice-pxy-api-tb/NA/v1/Invoice/IsPriceRequestAllowedforUser',
		url_LAO: "https://kcc-sls-laokcpinvoiceprice-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/Invoice/IsPriceRequestAllowedforUser",
		url_EMEA: 'https://us1.api.kimclark.com/kcc-sls-eukcpinvoiceprice-pxy-api-tb/EMEA/v1/Invoice/IsPriceRequestAllowedforUser',
		url_APAC: "https://app-apac-kcpinvoice-dev-cia-1.azurewebsites.net/APAC/v1/Invoice/IsPriceRequestAllowedforUser",
		method: 'POST'
	},
	invoiceSearchResult_new: {
		url_NA: 'https://us1.api.kimclark.com/kcc-sls-kcpinvoiceprice-pxy-api-tb/NA/v1/Invoice/InvoiceCustomerSearch',
		url_LAO: "https://kcc-sls-laokcpinvoiceprice-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/Invoice/InvoiceCustomerSearch",
		url_EMEA: 'https://us1.api.kimclark.com/kcc-sls-eukcpinvoiceprice-pxy-api-tb/EMEA/v1/Invoice/InvoiceCustomerSearch',
		url_APAC: "https://app-apac-kcpinvoice-dev-cia-1.azurewebsites.net/APAC/v1/Invoice/InvoiceCustomerSearch",
		method: "POST"
	},
	exportInvoiceGrid_new: {
		url_NA: 'https://us1.api.kimclark.com/kcc-sls-kcpinvoiceprice-pxy-api-tb/NA/v1/InvoiceExport/InvoiceCustomerSearchExport',
		url_LAO: "https://kcc-sls-laokcpinvoiceprice-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/InvoiceExport/InvoiceCustomerSearchExport",
		url_EMEA: 'https://us1.api.kimclark.com/kcc-sls-eukcpinvoiceprice-pxy-api-tb/EMEA/v1/InvoiceExport/InvoiceCustomerSearchExport',
		url_APAC: "https://app-apac-kcpinvoice-dev-cia-1.azurewebsites.net/APAC/v1/InvoiceExport/InvoiceCustomerSearchExport",
		method: "POST"
	},
	saveFavoriteInvoice_new: {
		url_NA: 'https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-tb/NA/v2/Invoice/SaveFavoriteCustomer',
		url_LAO: 'https://kcc-sls-laokcpuser-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/Invoice/SaveFavoriteCustomer',
		url_EMEA: 'https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-tb/EMEA/v2/Invoice/SaveFavoriteCustomer',
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apackcpuser-pxy-api-tb/APAC/v2/Invoice/SaveFavoriteCustomer",
		method: "POST"
	},
	DownloadInvoicePrice_new: {
		url_NA: 'https://us1.api.kimclark.com/kcc-sls-kcpinvoiceprice-pxy-api-tb/na/v1/DownloadInvoice/DownloadInvoicePrice',
		url_LAO: "https://kcc-sls-laokcpinvoiceprice-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/DownloadInvoice/DownloadInvoicePrice",
		url_EMEA: 'https://us1.api.kimclark.com/kcc-sls-eukcpinvoiceprice-pxy-api-tb/EMEA/v1/DownloadInvoice/DownloadInvoicePrice',
		url_APAC: "https://app-apac-kcpinvoice-dev-cia-1.azurewebsites.net/APAC/v1/DownloadInvoice/DownloadInvoicePrice",
		method: 'POST'
	},
	NotifyInvoicePriceRequestByEmail_new: {
		url_NA: 'https://us1.api.kimclark.com/kcc-sls-kcpinvoiceprice-pxy-api-tb/na/v1/Invoice/NotifyInvoicePriceRequestByEmail',
		url_LAO: "https://kcc-sls-laokcpinvoiceprice-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/Invoice/NotifyInvoicePriceRequestByEmail",
		url_EMEA: 'https://us1.api.kimclark.com/kcc-sls-eukcpinvoiceprice-pxy-api-tb/EMEA/v1/Invoice/NotifyInvoicePriceRequestByEmail',
		url_APAC: "https://app-apac-kcpinvoice-dev-cia-1.azurewebsites.net/APAC/v1/Invoice/NotifyInvoicePriceRequestByEmail",
		method: 'POST'
	},
	GetFavoriteCusotmerInvoice_new: {
		url_NA: 'https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-tb/NA/v2/Invoice/GetFavoriteCustomer',
		url_EMEA: 'https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-tb/EMEA/v2/Invoice/GetFavoriteCustomer',
		url_LAO: 'https://kcc-sls-laokcpuser-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/Invoice/GetFavoriteCustomer',
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apackcpuser-pxy-api-tb/APAC/v2/Invoice/GetFavoriteCustomer",
		method: 'POST'
	},
	invoicePriceGetRecentSearch_new: {
		url_NA: 'https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-tb/NA/v2/UserService/InvoicePriceGetRecentSearch',
		url_LAO: 'https://kcc-sls-laokcpuser-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserService/InvoicePriceGetRecentSearch',
		url_EMEA: 'https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-tb/EMEA/v2/UserService/InvoicePriceGetRecentSearch',
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apackcpuser-pxy-api-tb/APAC/v2/UserService/InvoicePriceGetRecentSearch",
		method: 'GET'

	},
	invoicePriceGetFavoriteSearch_new:{
		url_NA: 'https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-tb/NA/v2/UserService/InvoicePriceGetFavoriteSearch',
		url_LAO: 'https://kcc-sls-laokcpuser-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserService/InvoicePriceGetFavoriteSearch',
		url_EMEA: 'https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-tb/EMEA/v2/UserService/InvoicePriceGetFavoriteSearch',
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apackcpuser-pxy-api-tb/APAC/v2/UserService/InvoicePriceGetFavoriteSearch",
		method: 'GET'
	},
	saveFavoriteSearch_new:{
		url_NA: 'https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-tb/NA/v2/UserService/InvoicePriceSaveFavoriteSearch',
		url_LAO: 'https://kcc-sls-laokcpuser-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserService/InvoicePriceSaveFavoriteSearch',
		url_EMEA: 'https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-tb/EMEA/v2/UserService/InvoicePriceSaveFavoriteSearch',
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apackcpuser-pxy-api-tb/APAC/v2/UserService/InvoicePriceSaveFavoriteSearch",
		method: 'POST'
	},
	removeFavoriteSearch_new:{
		url_NA: 'https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-tb/NA/v2/UserService/RemoveFavoriteSearch',
		url_LAO: 'https://kcc-sls-laokcpuser-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserService/RemoveFavoriteSearch',
		url_EMEA: 'https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-tb/EMEA/v2/UserService/RemoveFavoriteSearch',
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apackcpuser-pxy-api-tb/APAC/v2/UserService/RemoveFavoriteSearch",
		method: 'POST'
	},
	saveSearchName_new:{
		url_NA: 'https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-tb/na/v2/UserService/InvoiceSaveSearchName',		
		url_LAO: 'https://kcc-sls-laokcpuser-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserService/InvoiceSaveSearchName',
		url_EMEA: 'https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-tb/emea/v2/UserService/InvoiceSaveSearchName',
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apackcpuser-pxy-api-tb/APAC/v2/UserService/InvoiceSaveSearchName",
		method: 'POST'
	},
	InvoiceSearchGridFilter_new: {
		url_NA: 'https://us1.api.kimclark.com/kcc-sls-kcpinvoiceprice-pxy-api-tb/NA/v1/Invoice/InvoiceSearchGridFilter',
		url_LAO: "https://kcc-sls-laokcpinvoiceprice-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/Invoice/InvoiceSearchGridFilter",
		url_EMEA: 'https://us1.api.kimclark.com/kcc-sls-eukcpinvoiceprice-pxy-api-tb/EMEA/v1/Invoice/InvoiceSearchGridFilter',
		url_APAC: "https://app-apac-kcpinvoice-dev-cia-1.azurewebsites.net/APAC/v1/Invoice/InvoiceSearchGridFilter",
		method: 'POST'
	},
	InvoicePriceSaveSearch_new: {
		url_NA: 'https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-tb/NA/v2/UserService/InvoicePriceSaveSearch',
		url_LAO: 'https://kcc-sls-laokcpuser-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserService/InvoicePriceSaveSearch',
		url_EMEA: 'https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-tb/EMEA/v2/UserService/InvoicePriceSaveSearch',
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apackcpuser-pxy-api-tb/APAC/v2/UserService/InvoicePriceSaveSearch",
		method: 'POST'
	},
	InvoiceRecentRequests_new: {
		url_NA: 'https://us1.api.kimclark.com/kcc-sls-kcpinvoiceprice-pxy-api-tb/NA/v1/Invoice/RecentRefreshRequest',
		url_LAO: "https://kcc-sls-laokcpinvoiceprice-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/Invoice/RecentRefreshRequest",
		url_EMEA: 'https://us1.api.kimclark.com/kcc-sls-eukcpinvoiceprice-pxy-api-tb/EMEA/v1/Invoice/RecentRefreshRequest',
		url_APAC: "https://app-apac-kcpinvoice-dev-cia-1.azurewebsites.net/APAC/v1/Invoice/RecentRefreshRequest",
		method: 'POST'
	},
	RecentRefreshRequestGridFilter_new: {
		url_NA: 'https://us1.api.kimclark.com/kcc-sls-kcpinvoiceprice-pxy-api-tb/NA/v1/Invoice/RecentRefreshRequestGridFilter',
		url_LAO: "https://kcc-sls-laokcpinvoiceprice-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/Invoice/RecentRefreshRequestGridFilter",
		url_EMEA: 'https://us1.api.kimclark.com/kcc-sls-eukcpinvoiceprice-pxy-api-tb/EMEA/v1/Invoice/RecentRefreshRequestGridFilter',
		url_APAC: "https://app-apac-kcpinvoice-dev-cia-1.azurewebsites.net/APAC/v1/Invoice/RecentRefreshRequestGridFilter",
		method: 'POST'
	},
	RecentRefreshRequestExport_new: {
		url_NA: 'https://us1.api.kimclark.com/kcc-sls-kcpinvoiceprice-pxy-api-tb/NA/v1/InvoiceExport/RecentRefreshRequestExport',
		url_LAO: "https://kcc-sls-laokcpinvoiceprice-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/InvoiceExport/RecentRefreshRequestExport",
		url_EMEA: 'https://us1.api.kimclark.com/kcc-sls-eukcpinvoiceprice-pxy-api-tb/EMEA/v1/InvoiceExport/RecentRefreshRequestExport',
		url_APAC: "https://app-apac-kcpinvoice-dev-cia-1.azurewebsites.net/APAC/v1/InvoiceExport/RecentRefreshRequestExport",
		method: 'POST'
	},
	GetCategoriesByCustomerNumber_new: {
		url_NA: 'https://us1.api.kimclark.com/kcc-sls-kcpinvoiceprice-pxy-api-tb/na/v1/Invoice/GetCategoriesByCustomerNumber',
		url_LAO: "https://kcc-sls-laokcpinvoiceprice-pxy-api-tb.br-s1.cloudhub.io/LAO/v1/Invoice/GetCategoriesByCustomerNumber",
		url_APAC: "https://app-apac-kcpinvoice-dev-cia-1.azurewebsites.net/APAC/v1/Invoice/GetCategoriesByCustomerNumber",
		method: 'POST'
	},
	SaveFavoriteInvoiceCategory_new: {
		url_NA: 'https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-tb/na/v2/Invoice/SaveFavoriteCategory',
		url_LAO: 'https://kcc-sls-laokcpuser-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/Invoice/SaveFavoriteCategory',
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apackcpuser-pxy-api-tb/APAC/v2/Invoice/SaveFavoriteCategory",
		method: 'POST'
	},
	GetFavoriteCategoryInvoice_new: {
		url_NA: 'https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-tb/na/v2/Invoice/GetFavoriteCategory',
		url_LAO: 'https://kcc-sls-laokcpuser-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/Invoice/GetFavoriteCategory',
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apackcpuser-pxy-api-tb/APAC/v2/Invoice/GetFavoriteCategory",
		method: 'POST'
	},
	UserCustomerCountriesByCustomerType: {
		url_EMEA: 'https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-tb/EMEA/v2/UserCustomerMapping/UserCustomerCountriesByCustomerType',
		url_LAO: 'https://kcc-sls-laopriceagreement-pxy-api-tb.br-s1.cloudhub.io/LAO/v2/UserCustomerMapping/UserCustomerCountriesByCustomerType',
		url_APAC: "https://us1.api.kimclark.com/kcc-sls-apacpriceagreement-pxy-api-tb/APAC/v2/UserCustomerMapping/UserCustomerCountriesByCustomerType",
		method: 'GET'
	}
}
export { endpoints_IN_NEW_DEV_TeamB };