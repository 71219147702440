import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { HttpService } from '@app/Modules/Shared/Services/http.service';
import { LocationLanguageService } from '@app/Modules/Shared/Services/location-language.service';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { waysToContactDropdown } from "@app/conf/dataConfigurations";
import { ContactUsRequest } from '@app/Modules/Shared/DataModels/SharedDataModel';
import { GlobalAppService } from '@app/Modules/Shared/Services/global-app.service';
import { LanguageSelectionService } from '@app/Modules/Shared/Services/language-selection.service';
import { MessageService } from 'primeng/api';
import { cookieSIMID } from '@env/environment';
import { CustomerInfomation, OktaProfile, saveUpdateUserProfile } from '@app/Modules/Shared/DataModels/SharedDataModel';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss'],
  providers: [MessageService]
})

@AutoUnsubscribe()
export class ContactusComponent implements OnInit, OnDestroy {

  @ViewChild('commentsText') commentsText: ElementRef;
  searchForm: FormGroup;
  isLoggedIn: boolean = false;
  selectedCountry: string;
  selectedSalesOrg: string;
  defaultRegion: string;
  templateConfig: any = {
    "region": {
      display: true,
      order: "1"
    },
    "country": {
      display: true,
      order: "2"
    },
    "salesOrg": {
      display: true,
      order: "3"
    },
    "language": {
      display: true,
      order: "4"
    }
  };
  regions: Array<any>;
  countries: Array<any>;
  languages: Array<any>;
  locationLanguageSelectorForm: FormGroup;
  selectedRegion: string;
  _selectedLocationLanguage: any;
  selectedLanguage: any;
  dataLoaded: boolean;
  showForm: boolean;
  contactNumber: any;
  emailId: any;
  selectedCountryLabel: any;
  selectedCountryValue: any;
  userProfileData: any;
  oktaProfile: any;
  waysToContactDropdown: any;
  selectedContactWay: any;
  userComments: string;
  ContactUsRequest: ContactUsRequest = new ContactUsRequest();
  contactForm: FormGroup;
  showcontactform: Boolean = false;
  showSuccessMessage: boolean = false;
  showButton: boolean = true;
  region: string;
  language: string;
  legalStatement: string;
  privacyPolicy: string;
  isGlobalUser: boolean = false;
  _username: string;
  _email: string;
  _userId: string;
  _phone: string;
  username: string;
  globalUserName: string;
  selectLabel: string;
  regionNA: any;
  get selectedLocationLanguage(): any {
    return this._selectedLocationLanguage;
  }
  set selectedLocationLanguage(value: any) {
    this._selectedLocationLanguage = value;
    // this.setDefaultLocation();
  }


  constructor(
    private _appService: AppService,
    private httpService: HttpService,
    private locationLanguageService: LocationLanguageService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private _globalAppService: GlobalAppService,
    private languageService: LanguageSelectionService,
    private messageService: MessageService
  ) { }
  ngOnDestroy(): void {
   
  }

  ngOnInit() {
    this.httpService.getUserInfo("").subscribe((profile: OktaProfile) => {
      if(profile){
        this.username = profile.given_name + " " + profile.family_name;
        if (this._appService.isGlobalRoleAssigned) {
          this.getGlobalUserDetails(this._username);
        }
      }
    });
    if (this._appService.getCookie(cookieSIMID) && this._appService.isGlobalUser) {
      this.isGlobalUser = true;
      this._username = this._appService.getCookie(cookieSIMID).substring(this._appService.getCookie(cookieSIMID).indexOf('~^') + 2, this._appService.getCookie(cookieSIMID).indexOf('~*'));
      this._email = this._appService.getCookie(cookieSIMID).substring(this._appService.getCookie(cookieSIMID).indexOf('~*') + 2, this._appService.getCookie(cookieSIMID).indexOf('~&'));
      this._phone = this._appService.getCookie(cookieSIMID).substring(this._appService.getCookie(cookieSIMID).indexOf('~&') + 2);
      this._userId = this._appService.getCookie(cookieSIMID).substring(0, this._appService.getCookie(cookieSIMID).indexOf('~^'));
    }
    this.isGlobalUser = this._appService.isGlobalUser;
    this.waysToContactDropdown = waysToContactDropdown;
    this.region = this._globalAppService.getDefaultRegionString();
    if(this.region == "NA"){
      this.regionNA = true;
    }else{
      this.regionNA= false;
    }
    this.language = this._globalAppService.getDefaultLanguageString();
    this._globalAppService.getDefaultRegion().subscribe(region => {
      if (region) {
        this.region = region;
        this._globalAppService.getDefaultLanguage().subscribe(lang => {
          if (region && lang) {
            this.language = lang;
            this.translateService.setDefaultLang(this.region + '/' + this.languageService.getLanguageCode(this.region,this.language).toLowerCase());
          }
        })
      }
    });
    if (this._appService.AvailableOKtaProfile) {
      this.oktaProfile = this._appService.AvailableOKtaProfile;
      this.isLoggedIn = true;
      if (!this.isLoggedIn) {
        this.loadLocationLanguageData();
      }
      else {
        this.httpService.getUserProfile(this._appService.getuserId(false)).subscribe((data) => {
          if ((data && data['defaultCustomerInfo'] && data['defaultCustomerInfo'].length)) {
            this.userProfileData = data["defaultCustomerInfo"][0];
          }
        });
        if(this.region == 'NA'){
          this.contactForm = this.fb.group({
            comments: ['', Validators.required]
          });
        } else {
          this.contactForm = this.fb.group({
            contactBy: ['', Validators.required],
            comments: ['', Validators.required]
          });
        }
        
        this.showcontactform = true;
        this.translateService.setDefaultLang(this.region + '/' + this.languageService.getLanguageCode(this.region,this.language).toLowerCase());
      }
    }
    else {
      this.loadLocationLanguageData(false, true);
    }

    this.languageService.getlanguage().subscribe(language => {
      this.getLanguageChange(language);
    });
    let selectedCountryLang = this._globalAppService.getSelectedCountryLang();
    let currentLanguage = this._globalAppService.getDefaultLanguageString() || 'en-US';
    if (selectedCountryLang && selectedCountryLang.language) {
      currentLanguage = selectedCountryLang.language;
    }
    this.getLanguageChange(currentLanguage);
    this.translateService.get(['dataConfigData.waysToContactDropdown.select']).subscribe(translations =>{
      this.selectLabel = translations['dataConfigData.waysToContactDropdown.select'];
    })
  }

  getGlobalUserDetails(username) {
    let userId = this._appService.getuserId(true);
    this.globalUserName = username + "( " + userId + " )";
  }

  getLanguageChange(language) {
    if (language)
      this.translateService.use(this.region + '/' + this.languageService.getLanguageCode(this.region,language).toLowerCase());

      this.translateService.get(['dataConfigData.waysToContactDropdown.select']).subscribe(translations =>{
        this.selectLabel = translations['dataConfigData.waysToContactDropdown.select'];
      })
  }

  loadLocationLanguageData(isLoadingOnDrownDownChanges = false, isOnLoad = false) {
    let languageFromParams = this.activatedRoute.snapshot.queryParamMap.get('language');
    if(languageFromParams && isOnLoad){
      this.httpService.getLocationsWithDefaultLanguage(languageFromParams).subscribe((locations) => {
        if (locations && locations.response && locations.response.length) {
          this.locationLanguageService.setLocationLanguageData(locations.response);
          this.populateLocations(isLoadingOnDrownDownChanges);
          this.initPage();
          this.loadContactContent(this.selectedLocationLanguage);
        }
      });
    } else {
      this.httpService.getLocations().subscribe((locations) => {
        if (locations && locations.response && locations.response.length) {
          this.locationLanguageService.setLocationLanguageData(locations.response);
          this.populateLocations(isLoadingOnDrownDownChanges);
          this.initPage();
          this.loadContactContent(this.selectedLocationLanguage);
        }
      });
    }    
  }


  populateLocations(isLoadingOnDrownDownChanges = false) {
    if(!isLoadingOnDrownDownChanges){
      this.setDefaultLocation();
    }
    this._globalAppService.setDefaultRegion(this.selectedLocationLanguage.region);
    this.regions = this.locationLanguageService.getAllRegions();
    this.regions = this.regions && this.regions.length ? this.regions.sort() : null;
    if (!this.selectedLocationLanguage || !this.selectedLocationLanguage.region) {
      this.selectedLocationLanguage = {
        region: this.regions[0].value
      };
    }
    this.countries = this.locationLanguageService.getCountriesForRegion(this.selectedLocationLanguage.region);
    this.countries = this.countries && this.countries.length ? this.countries.sort() : null;
    if (!this.selectedLocationLanguage || !this.selectedLocationLanguage.country) {
      this.selectedLocationLanguage = {
        region: this.regions[0].value,
        country: this.countries[0].value
      };
      this.selectedCountryLabel = this.countries.find(c => {
        return c.value === this.selectedLocationLanguage.country;
      }).label;
      this.selectedCountryValue = this.countries.find(c => {
        return c.value === this.selectedLocationLanguage.country;
      }).value;
    }
    this.languages = this.locationLanguageService.getLanguagesForCountry(this.selectedLocationLanguage.region, this.selectedLocationLanguage.country);
    this.languages = this.languages && this.languages.length ? this.languages.sort() : null;

    if (!this.selectedLocationLanguage || !this.selectedLocationLanguage.language) {
      this.selectedLocationLanguage = {
        region: this.regions[0].value,
        country: this.countries[0].value,
        language: this.languages[0].value
      };
    }
    // this.globalAppService.selectedRegion = this.selectedLocationLanguage.region;
    // this.defaultLocationLanguageChanged.emit(this.selectedLocationLanguage);
    // this.locationLanguageService.setSelectedLocationLanguage(this.selectedLocationLanguage);
    // this.globalAppService.setLocationLanguage(this.selectedLocationLanguage);
    // this.setDefaultLocation();
    // this.autoSelectLocationValues();
    this.dataLoaded = true;
  }

  initPage() {
    this.loadcomponentConfig();
    this.initFormGroup();
    this.showForm = true;
    this.autoSelectLocationValues();
    if (this.defaultRegion) {
      this.presetSelectLocationValues();
    }
  }

  setDefaultLocation() {
    // Check for query params storage first ir not available read from browser storage
    if (this.activatedRoute.snapshot.queryParams && Object.keys(this.activatedRoute.snapshot.queryParams).length) {
      this.readQueryParams();
    }
    else if (typeof (Storage) !== 'undefined' && localStorage.getItem("KCC.CP.selectedLocationLanguage") && localStorage.getItem("KCC.CP.selectedLocationLanguage") !== 'undefined') {
      this.readLocationFromLocalStorage();
    }
    else {
      this.selectedLocationLanguage = {
        region: "LAO",
        country: "AR",
        language: 'es-AR'
      }
      // this.selectedCountryLabel = this.countries.find(c => {
      //   return c.value === this.selectedLocationLanguage.country;
      // }).label;
    }
    this.translateService.setDefaultLang(this.selectedLocationLanguage.region + '/' + this.languageService.getLanguageCode(this.selectedLocationLanguage.region,this.selectedLocationLanguage.language).toLowerCase());
  }

  autoSelectLocationValues() {
    this.selectedRegion = this.locationLanguageService.doesRegionExists(this.selectedLocationLanguage.region, this.regions) ? this.selectedLocationLanguage.region : this.regions[0].value;
    this.selectedCountry = this.locationLanguageService.doesCountryExists(this.selectedLocationLanguage.country, this.countries) ? this.selectedLocationLanguage.country : this.countries[0].value;
    this.selectedLanguage = this.locationLanguageService.doesLangaugeExists(this.selectedLocationLanguage.language, this.languages) ? this.selectedLocationLanguage.language : this.languages[0].value;
    this.locationLanguageSelectorForm.get('region').setValue(this.selectedRegion);
    this.locationLanguageSelectorForm.get('country').setValue(this.selectedCountry);
    this.locationLanguageSelectorForm.get('language').setValue(this.selectedLanguage);
  }

  readLocationFromLocalStorage() {
    let parsedPreference = JSON.parse(localStorage.getItem("KCC.CP.selectedLocationLanguage"));
    this.selectedLocationLanguage = {
      region: parsedPreference.region,
      country: parsedPreference.country,
      language: parsedPreference.language
    }
  }

  readQueryParams() {
    let languageFromParams = this.activatedRoute.snapshot.queryParamMap.get('language');
    const regionFromParams = this.activatedRoute.snapshot.queryParamMap.get('region');
    if(languageFromParams)
    {
      languageFromParams = languageFromParams.substring(0, 3).toLowerCase() + languageFromParams.substring(3).toUpperCase();
      this.selectedLocationLanguage =
      {
        region: this.activatedRoute.snapshot.queryParamMap.get('region').toUpperCase(),
        country: this.activatedRoute.snapshot.queryParamMap.get('country').toUpperCase(),
        language: languageFromParams
      }
    }
    else if(regionFromParams)
    {
      this.onRegionChange({value:regionFromParams});
    }
    
    
  }

  initFormGroup() {
    if (this.selectedRegion === 'APAC') {
      this.locationLanguageSelectorForm = this.fb.group({
        region: [''],
        country: [''],
        salesOrg: [''],
        language: ['']
      })
    }
    else {
      this.locationLanguageSelectorForm = this.fb.group({
        region: [''],
        country: [''],
        language: ['']
      })
    }
    // this.formChangesListener();
  }


  async loadcomponentConfig() {
    let { locationLanguageSelector } = await this._appService.dynamicImport(this.selectedLocationLanguage.region);
    Object.assign(this.templateConfig, locationLanguageSelector);
  }

  presetSelectLocationValues() {
    let inputParameter = { 'value': this.defaultRegion };
    this.locationLanguageSelectorForm.get('region').setValue(this.defaultRegion);
    // this.onRegionChange(inputParameter)
  }

  formChangesListener() {
    let me = this;
    this.locationLanguageSelectorForm.valueChanges.subscribe(changes => {
      me.loadContactContent(changes);
    })

    // this.locationLanguageSelectorForm.get('region').valueChanges.subscribe((region) => {
    //   // this.onRegionChange(region);
    // });

    // this.locationLanguageSelectorForm.get('country').valueChanges.subscribe((country) => {
    //   // this.onCountryChange(country);
    // });

    // this.locationLanguageSelectorForm.get('language').valueChanges.subscribe((language) => {
    //   // this.onLanguageChange(language);
    // });

  }

  onRegionChange(event) {
    let region = event.value;
    /** On region Change make the API call With Default en-US languagecode to get the Region country and location data */
    this.httpService.getLocationsWithDefaultLanguage("en-US").subscribe((locations) => {
      if (locations && locations.response && locations.response.length) {
        this.locationLanguageService.setLocationLanguageData(locations.response);
        this.countries = this.locationLanguageService.getCountriesForRegion(region);
        this.languages = this.locationLanguageService.getLanguagesForCountry(region, this.countries[0].value);
        this.selectedLocationLanguage = {
          region: region,
          country: (region == 'NA' ? this.countries[1].value : this.countries[0].value),
          language: 'en-' + (region == 'NA' ? this.countries[1].value : this.countries[0].value)
        }

        this._globalAppService.setDefaultRegion(this.selectedLocationLanguage.region);
        this.loadContactContent(this.selectedLocationLanguage);
        this.translateService.use(this.selectedLocationLanguage.region + '/' + this.languageService.getLanguageCode(this.selectedLocationLanguage.region,this.selectedLocationLanguage.language).toLowerCase());
        this._globalAppService.setDefaultLanguage(this.selectedLocationLanguage.language);
        this.loadLocationLanguageData(true);
      }
    });
  }

  onCountryChange(event) {
    let country: string = event.value;
    this.languages = this.locationLanguageService.getLanguagesForCountry(this.selectedLocationLanguage.region, country);
    this.selectedLocationLanguage = {
      region: this.selectedLocationLanguage.region,
      country: country,
      language: 'en-' + country //this.languages[0].value
    }
    this._globalAppService.setDefaultRegion(this.selectedLocationLanguage.region);
    this.loadContactContent(this.selectedLocationLanguage);
    this.translateService.use(this.selectedLocationLanguage.region + '/' + this.languageService.getLanguageCode(this.selectedLocationLanguage.region,this.selectedLocationLanguage.language).toLowerCase());
    this._globalAppService.setDefaultLanguage(this.selectedLocationLanguage.language);
    this.loadLocationLanguageData(true);
  }

  onLanguageChange(event) {
    let language: string = event.value;
    this.selectedLocationLanguage = {
      region: this.selectedLocationLanguage.region,
      country: this.selectedLocationLanguage.country,
      language: language
    }
    this.translateService.get(['dataConfigData.waysToContactDropdown.select']).subscribe(translations =>{
      this.selectLabel = translations['dataConfigData.waysToContactDropdown.select'];
    })
    this.loadContactContent(this.selectedLocationLanguage);
    this.translateService.use(this.selectedLocationLanguage.region + '/' + this.languageService.getLanguageCode(this.selectedLocationLanguage.region,this.selectedLocationLanguage.language).toLowerCase());
    this._globalAppService.setDefaultRegion(this.selectedLocationLanguage.region);
    this._globalAppService.setDefaultLanguage(this.selectedLocationLanguage.language);
    this.loadLocationLanguageData(true);
  }

  loadContactContent(selectedLocationLanguage: any) {
    this._appService.setLoadingStatus(true);
    if (this.countries && this.countries.length)
      this.selectedCountryLabel = this.countries.find(c => {
        return c.value === selectedLocationLanguage.country;
      }).label;
      this.selectedCountryValue = this.countries.find(c => {
        return c.value === selectedLocationLanguage.country;
      }).value;
    let request: any = {
      "regionId": selectedLocationLanguage.region,
      "countryId": selectedLocationLanguage.country,
      "languageCode": selectedLocationLanguage.language,
      "contentTypes": [
        {
          "contentType": selectedLocationLanguage.region == 'EMEA' || selectedLocationLanguage.region == 'APAC' ? "Contacts" : 'RegisterContact'
        }
      ]
    }
    this.httpService.getContentService(request).subscribe(
      (response) => {
        this._appService.setLoadingStatus(false);
        if (response && response.result && response.result.length) {
          let parsedData = JSON.parse(response.result[0].data);
          console.log("@@@@@ Contact Data:  ",parsedData);
          if(selectedLocationLanguage.region == 'EMEA' || selectedLocationLanguage.region == 'APAC'){
            if(parsedData && parsedData.contactUs && parsedData.contactUs.customerService){
              if(parsedData.contactUs.customerService.phone && parsedData.contactUs.customerService.phone.length){
                this.contactNumber = parsedData.contactUs.customerService.phone[0];
              }
              if(parsedData.contactUs.chargeBack.email && parsedData.contactUs.chargeBack.email.length){
                this.emailId = parsedData.contactUs.chargeBack.email[0];
              }
            }
          }else if(selectedLocationLanguage.region == 'APAC'){
            this.contactNumber = parsedData.phone_number.toString();
            this.emailId = parsedData.emailId;
          } else {
            this.contactNumber = parsedData.phone_number;
            this.emailId = parsedData.emailId;
            
          }
        }
        else {
          this.contactNumber = null;
          this.emailId = null;
        }
      },
      (error) => {
        this._appService.setLoadingStatus(false);
      });
    let footerRequest: any = {
      "regionId": selectedLocationLanguage.region,
      "countryId": selectedLocationLanguage.country,
      "languageCode": selectedLocationLanguage.language,
      // this.footerRequest.salesOrgId = +this._globalAppService.getSalesOrg();
      // this.footerRequest.salesOrgId = this.salesOrg;
      "contentTypes": [
        {
          "contentType": "GlobalFooter"
        }
      ]
    }
    this.httpService.getKCPContent(footerRequest).subscribe(data => {
      if (data && data.result && data.result.length > 0) {
        let dataResult = JSON.parse(data.result[0].data);
        this.privacyPolicy = dataResult.privacypolicy;
        this.legalStatement = dataResult.legalstatement;
      }
    });
  }

  contactWayChange(e) {
    let contactusObj = new ContactUsRequest();
    contactusObj.contactBy = this.selectedContactWay;
  }

  onSubmit() {
    this._appService.setLoadingStatus(true);
    let contactFromValues = this.contactForm.getRawValue();
    let country = this._globalAppService.getDefaultCountryString();
    let commentsValueHtml = this.commentsText.nativeElement.value;
    if(this.region == 'EMEA' || this.region == 'APAC'){
      let commentsValues = this.commentsText.nativeElement.value.split('\n');
      commentsValueHtml = commentsValues.join(' | ');
    }
    
    let custAddress = this.userProfileData ? this.userProfileData.address1 + ', '+ this.userProfileData.city + ', '+ this.userProfileData.state + ', '+ this.userProfileData.postalCode : '';
    custAddress = custAddress.replace(", null", " ");
    
    let request: any = {
      "customerNumber": this.userProfileData ? this.userProfileData.customerNumber : '',
      "customerCompany": this.userProfileData ? this.userProfileData.name : '',
      "customerCountry": this.userProfileData ? this.userProfileData.countryName : '',
      "customerAddress": custAddress,
      "userContact": this.oktaProfile ? this.oktaProfile.name : '',
      "userPhoneNumber": this.oktaProfile ? this.oktaProfile.phone : '',
      "userCountry": country,
      "contactBy": contactFromValues.contactBy,
      "comments": commentsValueHtml, // Send the <br> for multiline comments.
      "BrowserInformation": this.getBrowserInformation()
    }
    this.httpService.saveUserContact(request).subscribe
      (data => {
        if (data.result === true && data.status === 'OK') {
          this.showSuccessMessage = true;
          this.showButton = false;
        }
        this._appService.setLoadingStatus(false);
      },
        (error: any) => {
          this._appService.setLoadingStatus(false);
          // this.messageService.add({ severity: 'error', summary: 'Error', detail: 'An unexpected error occurred. Please try again.' });
          this.messageService.add({ key: 'downloadError', summary: 'errorLabel', detail: '0000.errorMessage', severity: 'error' });
        });
  }

  reset() {
    this.contactForm.reset();
    this.showButton = true;
    this.showSuccessMessage = false;
  }

  getBrowserInformation() {
    let navUserAgent = navigator.userAgent;
    let browserName = navigator.appName;
    let browserVersion = '' + parseFloat(navigator.appVersion);
    let tempNameOffset, tempVersionOffset, tempVersion;

    if ((tempVersionOffset =  (/MSIE|Trident\//.test(navigator.userAgent)))) {
      browserName = "Microsoft Internet Explorer";
      let versionIndex = navUserAgent.indexOf('rv:');
      browserVersion = navUserAgent.substring(versionIndex + 3);
    } else if((navigator.userAgent.indexOf("Edg") != -1)){
      browserName = "Microsoft Edge";
      let edgIndex = navUserAgent.indexOf('Edg/');
      browserVersion = navUserAgent.substring(edgIndex + 4);
    } else if((tempVersionOffset = navUserAgent.indexOf("Chrome")) != -1) {
      browserName = "Chrome";
      browserVersion = navUserAgent.substring(tempVersionOffset + 7);
    } else if((tempVersionOffset = navUserAgent.indexOf("Safari")) != -1) {
      browserName = "Safari";
      browserVersion = navUserAgent.substring(tempVersionOffset + 7);
      if ((tempVersionOffset = navUserAgent.indexOf("Version")) != -1)
        browserVersion = navUserAgent.substring(tempVersionOffset + 8);
    } else if((tempVersionOffset = navUserAgent.indexOf("Firefox")) != -1) {
      browserName = "Firefox";
      browserVersion = navUserAgent.substring(tempVersionOffset + 8);
    } else if((tempNameOffset = navUserAgent.lastIndexOf(' ') + 1) < (tempVersionOffset = navUserAgent.lastIndexOf('/'))) {
      browserName = navUserAgent.substring(tempNameOffset, tempVersionOffset);
      browserVersion = navUserAgent.substring(tempVersionOffset + 1);
      if (browserName.toLowerCase() == browserName.toUpperCase()) {
        browserName = navigator.appName;
      }
    } else {
      browserName = "Browser Details Not Found";
      browserVersion = "";
    }

    // trim version
    if ((tempVersion = browserVersion.indexOf(";")) != -1)
      browserVersion = browserVersion.substring(0, tempVersion);
    if ((tempVersion = browserVersion.indexOf(" ")) != -1)
      browserVersion = browserVersion.substring(0, tempVersion);
    if ((tempVersion = browserVersion.indexOf(")")) != -1)
      browserVersion = browserVersion.substring(0, tempVersion);

    return (browserName + ( browserVersion ? " - " + browserVersion : "")); 
  }

}
