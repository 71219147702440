<span *ngIf="statusDefault">{{ statusValue | translate }}</span>

<ng-container *ngIf="statusReadyToSubmit">
    <span class="statusReadyToSubmit" [ngClass]="{'statusGeneral': isKcfileSubmitted || isKcfileDeleted, 'disableSubmit': (isGlobalUser && (region == 'EMEA' || region == 'LAO'))}" (click)="submitKcfile()" [innerHTML]="statusValue | translate"> 
  </span>
    <i *ngIf="!isFileSubmittedOrDeleted" class='far fa-arrow-alt-circle-right' [ngClass]="{'disableSubmit': (isGlobalUser  && (region == 'EMEA' || region == 'LAO'))}"></i>
</ng-container>

<ng-container *ngIf="showTitleMessage()">
    <span title="{{statusValue | translate}}" *ngIf="statusError || statusExtensiveError" class="tooltip-hover tolltipHoverClass statusError ellipsis" [ngClass]="{'statusGeneral': isKcfileDeleted}">
  <i *ngIf="!isKcfileDeleted" class="fas fa-exclamation-circle"></i>
  <span *ngIf="statusError"  pTooltip="{{'chargeback.kcFile.errorsFoundTooltip' | translate}}" [escape]="false" tooltipPosition="top">
    <!-- <span class="textunderlined">{{ statusValue | translate }}</span> -->
    <span>{{ statusValue | translate }}</span>
    </span>
    <span *ngIf="statusExtensiveError" pTooltip="{{'chargeback.kcFile.extensiveErrorsFoundTooltip' | translate}}" [escape]="false" tooltipPosition="top">
    <!-- <span class="textunderlined">{{ statusValue | translate }}</span> -->
    <span>{{ statusValue | translate }}</span>
    </span>
    </span>
</ng-container>
<ng-container *ngIf="!showTitleMessage()">
    <span *ngIf="statusError || statusExtensiveError" class="tooltip-hover tolltipHoverClass statusError ellipsis" [ngClass]="{'statusGeneral': isKcfileDeleted}">
    <i *ngIf="!isKcfileDeleted" class="fas fa-exclamation-circle"></i>
    <span *ngIf="statusError"  pTooltip="{{'chargeback.kcFile.errorsFoundTooltip' | translate}}" [escape]="false" tooltipPosition="top">
      <!-- <span class="textunderlined">{{ statusValue | translate }}</span> -->
    <span>{{ statusValue | translate }}</span>
    </span>
    <span *ngIf="statusExtensiveError" pTooltip="{{'chargeback.kcFile.extensiveErrorsFoundTooltip' | translate}}" [escape]="false" tooltipPosition="top">
      <!-- <span class="textunderlined">{{ statusValue | translate }}</span> -->
    <span>{{ statusValue | translate }}</span>
    </span>
    </span>
</ng-container>