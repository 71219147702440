import { Component, OnDestroy, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { HttpService } from '@app/Modules/Shared/Services/http.service';
import { PaService } from '@app/Modules/PriceAgreement/Services/pa.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import { PadetailsService } from '@app/Modules/PADetails/Services/padetails.service';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { OktaProfile } from '@app/Modules/Shared/DataModels/SharedDataModel';
import { ActivatedRoute, Router } from '@angular/router';
import { SPA_LEGACY_MENU_HOST } from '@env/environment';
import { CpAgGridService } from '@app/Modules/cp-grid/services/cp-ag-grid-service';
import { GlobalAppService } from '@app/Modules/Shared/Services/global-app.service';

@AutoUnsubscribe()
@Component({
  selector: 'app-hyperlink-and-favorite',
  templateUrl: './hyperlink-and-favorite.component.html',
  styleUrls: ['./hyperlink-and-favorite.component.scss']
})

export class HyperlinkAndFavoriteComponent implements AgRendererComponent, OnDestroy {
  @ViewChild('favicon') favicon: ElementRef;
  isFavorite: boolean = false;
  alreadyfavorite;
  favoriteCSS: string = "";
  today;
  cvtView: any;
  subscription: Subscription = new Subscription();
  oktaProfile: OktaProfile;
  isGlobalUser: boolean;
  isPageKCFilePA: boolean = false;

  constructor(
    private httpServ: HttpService,
    private appServ: AppService,
    private renderer: Renderer2,
    private gridService: CpAgGridService,
    private _globalAppService: GlobalAppService) { }

  params: any;
  customerId: string = "";

  ngOnInit() {
    this.isGlobalUser = this.appServ.isGlobalUser;
    this.oktaProfile = this.appServ.AvailableOKtaProfile;
    //below code is to hide favorite icon from KCfile PA page grid's PA number column
    if (this.gridService.pageContext && this.gridService.pageContext.pageName && this.gridService.pageContext.pageName == 'kcfilePASearchResults') {
      this.isPageKCFilePA = true;
    }
  }
  agInit(params: any): void {
    let region = this._globalAppService.getDefaultRegionString().toUpperCase();
    this.params = params;
    let contractIdfavPAList = this.appServ.getFavoritePA();
    if (params && params.data) {
      if (contractIdfavPAList)
        this.alreadyfavorite = contractIdfavPAList.find((item: any) => {
          if (region == "APAC") {
            return (item.customerId == params.data.customerNumber) && (item.contractId == params.data.contractNumber);
          } else {
            return (item.customerId == params.data.customerNumber) && (item.contractId == params.data.contractId);
          }

        })

      if (this.alreadyfavorite) {
        // this.favoriteCSS = "fa fa-heart favbtn";
        this.renderer.addClass(this.favicon.nativeElement, 'fa');
        this.renderer.addClass(this.favicon.nativeElement, 'fa-heart');
        this.renderer.addClass(this.favicon.nativeElement, 'favbtn');
        this.isFavorite = true;
      }
      else {
        // this.favoriteCSS = "";
        this.renderer.removeClass(this.favicon.nativeElement, 'fa');
        this.renderer.removeClass(this.favicon.nativeElement, 'fa-heart');
        this.renderer.removeClass(this.favicon.nativeElement, 'favbtn');
        this.isFavorite = false;
      }
    }
  }

  refresh(params: any): boolean {
    return false;
  }

  onLinkClick() {
    let qsParams = this.getQsParams();
    let isCVT: string = "CVTNo";
    if (this._globalAppService.isCVTView()) {
      this.cvtView = this._globalAppService.isCVTView();
      isCVT = "CVTYes"
    }
    else {
      isCVT = "CVTNo"
    }

    if (this.params.data.customerNumber == null) {
      this.params.data.customerNumber = 'All';
    }

    let contractNumber = this.params.data.contractNumber;

    if (this.gridService.pageContext.pageName === 'myNotifications') {
      contractNumber = this.params.data.contractNumberWithZeros;
    }

    if (this.oktaProfile && this.oktaProfile.sAMAccountName) {
      window.open(SPA_LEGACY_MENU_HOST + contractNumber + '/' + this.params.data.customerNumber + '/' + this.params.data.statusId + '/' + isCVT + qsParams);
    }

  }

  getQsParams(): string {
    let qsList: Array<any> = new Array<any>();
    let qsParams: string;
    let qsTileType: string = this.params.tileType ? ('tileType=' + this.params.tileType) : "";
    if (qsTileType)
      qsList.push(qsTileType);

    let fullNameList = [];
    let oCustomerName: any = this.params.data.customerName;
    if (oCustomerName) {
      fullNameList.push(oCustomerName.name1 || '');
      // fullNameList.push(oCustomerName.name2 || '');
      // fullNameList.push(oCustomerName.name3 || '');
      // fullNameList.push(oCustomerName.Fname4 || '');
    }
    let qsCustomerName: string = fullNameList && fullNameList.length ? ('custName=' + fullNameList.join(' ')) : '';
    if (this.gridService.pageContext.pageName === 'myNotifications')
      qsCustomerName = 'custName=' + this.params.data.customerName;
    if (qsCustomerName)
      qsList.push(qsCustomerName);

    let dataRequest: any = this.gridService.getSvcRequest();;
    let dataDurationType: number = dataRequest && dataRequest.query ? dataRequest.query.dataDurationType : 0;
    if (dataDurationType) {
      qsList.push('dataDurationType=' + dataDurationType);
    }
    let lineItemStatus: number = dataRequest && dataRequest.query ? dataRequest.query.display : 0;
    if (lineItemStatus) {
      qsList.push('display=' + lineItemStatus);
    }

    qsParams = qsList && qsList.length > 0 ? '?' + qsList.join('&') : '';

    return qsParams;
  }

  onFavClick(params) {
    this.isFavorite = !this.isFavorite;
    if (this.isFavorite) {
      // make service call to mark fav
      this.today = this.getTodaysDate();
      this.subscription.add(this.httpServ.markFavorite(params.data.contractNumber, params.data.customerNumber, this.today).subscribe((data) => {
      }, (e) => { }, () => { this.appServ.setFavoritePA() }));
    }
    else {
      // make service call to mark unfav
      this.today = this.getTodaysDate();
      this.subscription.add(this.httpServ.markUnFavorite(params.data.contractNumber, params.data.customerNumber, this.today).subscribe((data) => {
      }, (e) => { }, () => { this.appServ.setFavoritePA() }));
    }
  }

  onLinkHover() {
    if (!this.isFavorite) {
      // this.favoriteCSS = "far fa-heart favbtn";
      this.renderer.removeClass(this.favicon.nativeElement, 'fa');
      this.renderer.addClass(this.favicon.nativeElement, 'far');
      this.renderer.addClass(this.favicon.nativeElement, 'fa-heart');
      this.renderer.addClass(this.favicon.nativeElement, 'favbtn');
    }
    else {
      this.renderer.addClass(this.favicon.nativeElement, 'fa');
      this.renderer.addClass(this.favicon.nativeElement, 'fa-heart');
      this.renderer.addClass(this.favicon.nativeElement, 'favbtn');

    }
  }

  onLinkMouseout() {
    this.renderer.removeClass(this.favicon.nativeElement, 'far');
    this.renderer.removeClass(this.favicon.nativeElement, 'fa-heart');
    this.renderer.removeClass(this.favicon.nativeElement, 'favbtn');

    if (this.isFavorite) {
      // this.favoriteCSS = "fa fa-heart favbtn";

      this.renderer.addClass(this.favicon.nativeElement, 'fa');
      this.renderer.addClass(this.favicon.nativeElement, 'fa-heart');
      this.renderer.addClass(this.favicon.nativeElement, 'favbtn');
    }
  }
  getTodaysDate() {
    let today: any = new Date();
    let dd: any = today.getDate();
    let mm: any = today.getMonth() + 1;
    let yyyy: any = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    today = dd + '/' + mm + '/' + yyyy;
    return (today)
  }

  ngOnDestroy() {
    // Required for unsubscribing and destroying the component
  }
}