// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file rephlacements can be found in `angular.json`.

import { endpoints_PA_DEV_TeamB } from './endpoints/endpoints-PA/endpointsPA.devTeamB';
import { endpoints_CB_DEV_TeamB } from './endpoints/endpoints-CB/endpointsCB.devTeamB';
import { endpoints_IN_DEV_TeamB } from './endpoints/endpoints-IN/endpointsIN.devTeamB';
import { endpoints_IN_NEW_DEV_TeamB } from './endpoints/endpoints-IN-new/endpointsIN-new.devTeamB';
import { endpoints_MC_DEV_TeamB } from './endpoints/endpoints-MC/endpointsMC.devTeamB';
import { endpoints_SiteAdmin_DEV_TeamB } from './endpoints/endpoints-SiteAdmin/endpointsSiteAdmin.devTeamB';

const environment = {
	...endpoints_PA_DEV_TeamB,
	...endpoints_CB_DEV_TeamB,
	...endpoints_MC_DEV_TeamB,
	...endpoints_IN_NEW_DEV_TeamB,
	...endpoints_IN_DEV_TeamB,
	...endpoints_SiteAdmin_DEV_TeamB,
	getImpersonatedUserInfo:{
		url: 'https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-dev/NA/v2/Okta/GetOktaUserRoles?ebamUserId=XXX_EBAMID_XXX',
		method: 'GET'
	},	
	magento:{
		magento_access_token: '2l764zk82d4r6omb447sbcey2ubq9klt',
		url: 'https://stage.products.kcprofessional.com',
		storeId: 7
	},
	magentoEMEA:{
		url: 'https://stage.products.kcprofessional.com',
	},
	activeTopHeader:true,
	activeCarousel: false,
	spartaEncryption: false,
	laoEFileVisible: true,
	
	production: false,
	appInsightsConfig: {
		instrumentationKey: "aa540c76-6ffa-48a6-8a15-bc4079a193b3"
	},
	GTMpreviewVersion: 0,
	enablePageViewMetrics: false,
	changePassowrdLink: 'https://ebiz.oktapreview.com/enduser/settings'
};

const SPART_HOST = "http://qa1.sfdcpricing.kcc.com";
const APP_LEGACY_MENU_HOST = "https://dev.portal.kcprofessional.com";
const APP_LEGACY_MENU_HOST_OLD = "https://dev4.na.kccustomerportal.com"
const SPA_LEGACY_MENU_HOST = "/pa/details/";
const contentMangement = "https://dev.nacustomerportal.kcc.com/Customerportal/UI/Site%20Admin%20Center/Home.aspx";


const oktaConfig = {
	// clientId: "0oapy5g0iy8UceBZi0h7",
	clientId: "0oatfqr1cfKYfOky00h7",
	// clientSecret: "PFxE7m5dtKLwFBy_bpP3n0XTwhtVhZHsQob90rvc",
	issuer: 'https://ebiz.oktapreview.com/oauth2/auslle1jli7NKpqm70h7',
	redirectUri: APP_LEGACY_MENU_HOST + '/implicit/callback',
	// responseType: ["token", "id_token"],
	responseType: ["code"],
	scopes: ["openid", "profile", ".kc.samaccountname", "email", "phone"],
	storage: "sessionStorage",
	autoRenew: true,
	pkce: true
};

// const oktaConfig = {
// 	clientId: "0oapy5g0iy8UceBZi0h7",
// 	clientSecret: "PFxE7m5dtKLwFBy_bpP3n0XTwhtVhZHsQob90rvc",
// 	issuer: 'https://ebiz.oktapreview.com/oauth2/auslle1jli7NKpqm70h7',
// 	redirectUri: APP_LEGACY_MENU_HOST + '/implicit/callback',
// 	responseType: ["token", "id_token"],
// 	scopes: ["openid", "profile", ".kc.samaccountname", "email", "phone"],
// 	storage: "sessionStorage",
// 	autoRenew: true,
// 	pkce: false
// };

const IdleTimeOutConfig = {
	setIdle: 6900,
	setTimeout: 300,
	setInterval: 15
}

// const globalUser = "INUSROAP03";
const globalUser = {
	NA: ['K-C_CustomerPortal_Delegated_P','K-C_CustomerPortal_Delegated_QD'],
	LAO: ['K-C_CustomerPortal_Delegated_P_LAO','K-C_CustomerPortal_Delegated_QD_LAO'],
	EMEA: ['K-C_CustomerPortal_Delegated_P_EMEA', 'K-C_CustomerPortal_Delegated_QD_EMEA'],
	APAC: ['K-C_CustomerPortal_Delegated_P_APAC', 'K-C_CustomerPortal_Delegated_QD_APAC']
}
const externalAdminRole = ['admins@bp'];
const efileAccessRole = {
	"EMEA": "EMEAKCP_E-File",
	"NA": "NAKCP_E-File",
	"LAO": "LAOKCP_E-File",
	"APAC": "APACKCP_E-File"
}
const kcfileAccessRole = ["EMEAKCP_K-Cfile", "K-C_CustomerPortal_Users_QD_EMEA", "K-C_CustomerPortal_Users_P_EMEA","LAOKCP_K-Cfile", "K-C_CustomerPortal_Users_P_LAO", "K-C_CustomerPortal_Users_P_APAC", "APACKCP_K-Cfile"];
// const globalUser = ["C_CustomerPortal_Users_QD"];
const cookieSIMID = 'IntegratedUserId';
const cookieLoggedInUserId = 'loggedInUserId';
const CAPTCHA_SITE_KEY = "6Lck0OAUAAAAAP74bH7BSzlRLVvcMLkfOqEStwQ8";
const efileHistoryLegacyDomain = "https://qa.nacustomerportal.kcc.com";
const efileHistoryLegacyDomainEMEA = "http://qa.eucustomerportal.kcc.com";
const manageUserLinkNAEMEA = "https://ebam.kimclark.com/";
const globalUserMagentoURL = "https://integration-5ojmyuq-4hmxqeebcx5e4.us-5.magentosite.cloud/customerprofile/index/externallogin?";
const globalUserMagentoURLEMEA = "https://integration-5ojmyuq-4hmxqeebcx5e4.us-5.magentosite.cloud/customerprofile/index/externallogin?";


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
const blockedModules = {
	"LAO": [],
	"NA": [],
	"EMEA": [],
	"APAC": []
}
export {
	environment, oktaConfig, APP_LEGACY_MENU_HOST, globalUser, cookieSIMID,
	cookieLoggedInUserId, contentMangement, SPART_HOST, IdleTimeOutConfig, SPA_LEGACY_MENU_HOST,
	CAPTCHA_SITE_KEY, externalAdminRole, efileAccessRole, kcfileAccessRole, APP_LEGACY_MENU_HOST_OLD, efileHistoryLegacyDomain,efileHistoryLegacyDomainEMEA, manageUserLinkNAEMEA,
	globalUserMagentoURL, blockedModules, globalUserMagentoURLEMEA
}