<footer>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <ng-container *ngIf="isInternalUser; else externalUserFooter">
                    <p style=" display: inline-block;" *ngIf="region == 'LAO' || region == 'EMEA' || region == 'APAC'" [innerHTML]="'footer.copyrightFull' | translate: {legalStatement: legalStatement, privacyPolicy: privacyPolicy}">
                    </p>
                    <p *ngIf="region == 'NA'" style=" display: inline-block;" [innerHTML]="'footer.copyrightFull' | translate: {legalDisclaimers: legalDisclaimers, privacyPolicy: privacyPolicy}">
                    </p>
                </ng-container>
                <ng-template #externalUserFooter>
                    <p *ngIf="region == 'LAO'; else noCbFooter">
                        <span style="display: inline-block;" [innerHTML]="'footer.copyrightFull' | translate: {legalStatement: legalStatement, privacyPolicy: privacyPolicy}"></span>&nbsp;
                        <!-- <a href="javascript:void(0)"
              (click)="onChargebackLinkClick($event)">{{ "footer.chargeback" | translate}}</a>
              {{ "footer.copyright2" | translate }} -->
                    </p>
                    <ng-template #noCbFooter>
                        <p *ngIf="region != 'EMEA' && region != 'APAC'" style="display: inline-block;" [innerHTML]="'footer.copyrightFull' | translate: {legalDisclaimers: legalDisclaimers, privacyPolicy: privacyPolicy}">
                        </p>
                        <p *ngIf="region == 'EMEA'|| region == 'APAC'" style=" display: inline-block;" [innerHTML]="'footer.copyrightFull' | translate: {legalStatement: legalStatement, privacyPolicy: privacyPolicy}">
                        </p>
                    </ng-template>
                </ng-template>
            </div>
        </div>
    </div>
</footer>